import React from "react";

const DateItem = (props) => {
  const { date, count, cssClass, handleChangeDate } = props;

  return (
    <div className={cssClass || "radio-custom-label"} onClick={() => handleChangeDate(date)}>
      <div className="fs-6">{date}</div>
      <div className="fs-4">{count}</div>
    </div>
  );
};

export default DateItem;
