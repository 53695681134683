import React from "react";
import Styles from "./FilledCheckBox.module.css";

const MultiInlineCheckBox = (props) => {
  const { label, secondaryLabel, isChecked, handleChange, handleAdmitChange, isAdmitChecked } = props;

  return (
    <div className={`${Styles.filledCheckBox}`}>
      <input
        className={`${Styles.checkbox}`}
        checked={isChecked}
        type="checkbox"
        onChange={() => {
          handleChange(!isChecked);
        }}
      />
      <label className={`${Styles.customLabel}`}>
        <div className="d-flex justify-content-between align-items-center">
          <span>{label}</span>
          <span>
            <div className={`${Styles.filledCheckBox}`}>
              {isChecked && (
                <div className={`${Styles.multiCheckBox}`}>
                  <input
                    className={`${Styles.checkbox}`}
                    type="checkbox"
                    checked={isChecked && isAdmitChecked ? isAdmitChecked : false}
                    onChange={() => {
                      handleAdmitChange(!isAdmitChecked);
                    }}
                  />
                  <label className={`${Styles.multiLabel}`}>
                    <span className={`checkbox ${isChecked ? "checkbox--active" : ""}`} aria-hidden="true" />
                    {secondaryLabel}
                  </label>
                </div>
              )}
            </div>
          </span>
        </div>
      </label>
    </div>
  );
};
export default MultiInlineCheckBox;
