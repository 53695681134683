import { CONFIG } from "constant";
import React from "react";

const CreateTestHeader = ({ showSelection, testDone, handelSelectDeselect, isAllSelected }) => {
  return (
    <tr>
      <th className="text-center cursor-pointer toggleButtonGroup" onClick={handelSelectDeselect}>
        {isAllSelected ? "Deselect ALl" : "Select All"}
      </th>
      <th className="text-center cursor-pointer">Test Name</th>
      <th className="">Sources</th>
      {/* <th>Accession No</th> */}
      <th className="text-center">Lab Comments</th>
      {CONFIG.clientID !== "e33ea32c-7cc2-4609-b3b2-35d8309df342" && (
        <>
          <th className="text-center">Eligible</th>
          <th className="text-center">PA Received</th>
        </>
      )}

      <th className="text-center">Print Labels</th>
    </tr>
  );
};

export default CreateTestHeader;
