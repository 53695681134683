import React, { useState, useContext, useRef } from "react";

// react-bootstrap components
import { Button, Card, Table, Row, Col, Form } from "react-bootstrap";
import { useEffect, useMemo } from "react";
import SortIcon from "../components/SortIcon";
import "react-activity/dist/Bounce.css";
import Filter from "../components/Filter";
import {
  formatPhoneNumber,
  capitalizeLetter,
  sortingFilterInLC,
  calculateTdWidth,
  formatDateOfBirthDOB,
  formatTest,
  sortTestList,
  connectedIDInLocalStorage,
} from "../utils";
import MFPagination from "components/Pagination/MFPagination";
import { AppContext } from "../context/app-context";
import { PAGE_LIMIT } from "constant";
import moment from "moment";
import ExportToExcel from "components/ExportToExcel";
import ConfirmationModal from "../components/Modal/ConfirmationModal";
import API from "api";
import { EXPECTED_QUARANTINE_DAYS } from "constant";
import QuarantineDetailsModal from "components/Modal/QuarantineDetailsModal";
import Loader from "components/Loader/Loader";
import Icon from "components/Icon";

const QuarantineNew = (props) => {
  const componentRef = useRef();
  const [width, setWidth] = useState(0);
  const connectedID =  connectedIDInLocalStorage.get();
  const siteID = connectedID && connectedID !== 'undefined' ? connectedID : window.location?.pathname?.split("/").pop();

  
  useEffect(() => {
    setWidth(componentRef.current.offsetWidth);
  }, [componentRef]);

  const handleResize = () => {
    if (componentRef.current) setWidth(componentRef.current.offsetWidth);
  };

  useEffect(() => {
    handleResize();
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  });
  const tdWidth = calculateTdWidth(width - 45, 10);

  const [crew, setCrew] = useState([]);
  const [filteredCrew, setFilteredCrew] = useState([]);
  const [sortBy, setSortBy] = useState("");
  const [sortDescending, setSortDescending] = useState(true);
  const [loading, setLoading] = useState(false);
  const [showFilter, setShowFilter] = useState(false);
  const [filter, setFilter] = useState({});
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [checkboxes, setCheckboxes] = useState([]);
  const [allSelected, setAllSelected] = useState(false);
  const [openConfirmModal, setOpenConfirmModal] = useState(false);
  const [openDetailsQuarantine, setOpenDetailsQuarantine] = useState(false);
  const [selectedQuarantine, setSelectedQuarantine] = useState("");
  const [tests, setTests] = useState([]);
  const [totalRecord, setTotalRecord] = useState(0);

  const searchTerms = ["First Name", "Last Name", "Date of Birth", "Email", "Phone", "Quarantined", "Show"];

  const appContext = useContext(AppContext);

  const sortCrew = () => {
    setFilteredCrew(
      sortDescending
        ? [...crew].sort((a, b) => (a[sortBy] < b[sortBy] ? 1 : b[sortBy] < a[sortBy] ? -1 : 0))
        : [...crew].sort((a, b) => (b[sortBy] < a[sortBy] ? 1 : a[sortBy] < b[sortBy] ? -1 : 0))
    );
  };

  const SetSortIcon = ({ sortName }) => {
    return (
      <SortIcon
        sortName={sortName}
        sortBy={sortBy}
        setSortBy={setSortBy}
        sortDescending={sortDescending}
        setSortDescending={setSortDescending}
      />
    );
  };

  const exportTests = useMemo(() => {
    return filteredCrew.map((test) => {
      return {
        firstName: test.firstName,
        lastName: test.lastName,
        email: test.email,
        phoneNumber: formatPhoneNumber(test.phoneNumber),
        site_name: test.site_name,
        resultDates: test.resultDate
          ? moment(test.resultDate).format("ddd MMM D YYYY")
          : moment(test.resultDate).format("ddd MMM D YYYY"),
        quarantinedStart: test.quarantinedStart,
        isQuarantined: test.isQuarantined,
        daysCount: test.daysCount,
        hippaConsent: true,
      };
    });
  }, [filteredCrew]);

  const nestedFilter = (targetArray, filters) => {
    if (Object.keys(filter).length === 0) return targetArray;
    const filterKeys = Object.keys(filters);
    //filters main array of objects
    const models = targetArray.filter((obj) => {
      //goes through each key being filtered for
      return filterKeys.every((key) => {
        if (!filters[key].length) {
          return true;
        }

        if (key === "First Name") {
          return obj.firstName && obj.firstName.toLowerCase().includes(filters[key].toLowerCase());
        }

        if (key === "Last Name") {
          return obj.lastName && obj.lastName.toLowerCase().includes(filters[key].toLowerCase());
        }
        if (key === "phoneNumber") {
          return obj.phoneNumber && obj.phoneNumber.replace(/\D/g, "").includes(filters[key].toLowerCase());
        }

        return obj[key] && obj[key].toLowerCase().includes(filters[key].toLowerCase());
      });
    });

    return models;
  };

  const handleChangePage = (page) => setCurrentPage(page);

  const formatQuarantine = (test) => {
    let expectedEndDate = moment(test.quarantinedStart).add(EXPECTED_QUARANTINE_DAYS, "days");
    let daysCount = "-";

    if (test.quarantinedEnd) {
      expectedEndDate = moment(test.quarantinedEnd);
    }

    if (test.quarantinedEnd) {
      daysCount = moment(test.quarantinedEnd).diff(moment(test.quarantinedStart), "days");
    } else {
      daysCount = moment().diff(moment(test.quarantinedStart), "days");
    }

    return {
      ...test,
      daysCount: daysCount,
      expectedEndDate: expectedEndDate.format("ddd MMM D YYYY"),
      isQuarantined: !test.quarantinedEnd ? "X" : "-",
    };
  };

  const loadTestFromPG = async (params, page) => {
    setLoading(true);
    const filterParam = params;
    Object.assign(filterParam, {
      limit: 1000,
      page: page,
      quarantine: "Yes",
      siteID: siteID || "E",
    });

    const pgList = await API.getTestListFromPG(filterParam);
    setLoading(false);
    const ttlTests = tests.concat(pgList.rows.map((t) => formatQuarantine(formatTest(t))));
    if (page === 1) {
      setTotalRecord(pgList.total);
    }
    setTests(ttlTests);
    refreshTestList(ttlTests);
  };

  useEffect(() => {
    if (appContext.testFilter && appContext.testFilter.location === "crewlisting") {
      setFilter(appContext.testFilter.filter);
      setShowFilter(true);
    }
    loadPrevSorting();
    loadTestFromPG(Object.keys(filter).length === 0 ? { id: 1 } : filter, 1);
  }, []);

  const refreshTestList = (list) => {
    if (sortBy) {
      setFilteredCrew(
        sortTestList(
          sortBy,
          sortDescending,
          list,
          appContext.getSiteName,
          appContext.getLabName,
          appContext.getClientName,
          appContext.parseResult
        )
      );
    } else {
      setFilteredCrew(list);
    }
  };

  useEffect(() => {
    crew.length > 0 && sortCrew();
    const prevSortingState = sortingFilterInLC.get();
    prevSortingState["quarantine"] = { filter, sortBy, sortDescending };
    sortingFilterInLC.save(prevSortingState);
  }, [sortBy, sortDescending]);

  useEffect(() => {
    if (crew.length > 0) setFilteredCrew(nestedFilter(crew, filter));
  }, [filter]);

  const loadPrevSorting = () => {
    const sortingFilters = sortingFilterInLC.get();
    if (sortingFilters.quarantine.sortBy) {
      setSortDescending(sortingFilters.quarantine.sortDescending);
      setSortBy(sortingFilters.quarantine.sortBy);
    }
  };

  const crewToMap = useMemo(() => {
    const list = filteredCrew ? [...filteredCrew] : [];
    const ttlPages = list.length > PAGE_LIMIT ? Math.ceil(list.length / PAGE_LIMIT) : 1;
    setTotalPages(ttlPages);
    if (list.length < PAGE_LIMIT) {
      setCurrentPage(1);
    }
    if (ttlPages > 1) {
      const indexOfLastUser = currentPage * PAGE_LIMIT;
      const indexOfFirstUser = indexOfLastUser - PAGE_LIMIT;
      return list.slice(indexOfFirstUser, indexOfLastUser);
    }
    return list;
  }, [filteredCrew, currentPage]);

  const handleSaveFilter = () => {
    appContext.setTestFilter({ location: "crewlisting", filter });
  };

  const handleCheckboxChange = (e, id) => {
    const filteredList = checkboxes.filter((c) => c !== id);
    if (e.target.checked) {
      filteredList.push(id);
    }
    setCheckboxes(filteredList);
  };

  const toggleCheckboxes = (val) => setCheckboxes(val ? filteredCrew.map((t) => t.testId) : []);

  const handleEndQuarantine = async (isConfirm) => {
    setOpenConfirmModal(false);
    if (!isConfirm) return;
    setLoading(true);
    try {
      await API.UpdateEndQuarantine(checkboxes);
      appContext.showSuccessMessage("Quarantine End Successfully");
      appContext.resetHSTests();
    } catch (err) {
      appContext.showErrorMessage(err.message);
    }
    setLoading(false);
  };

  const handleOpenConfirmation = () => checkboxes.length > 0 && setOpenConfirmModal(true);

  const TableRow = ({ user }) => {
    return (
      <tr key={user.username}>
        <td className="ellipsis" style={{ textAlign: "left", width: "45px", maxWidth: "45px" }}>
          <Form.Check>
            <Form.Check.Input
              type="checkbox"
              value={user.testId}
              checked={checkboxes.indexOf(user.testId) !== -1}
              onChange={(e) => handleCheckboxChange(e, user.testId)}
            ></Form.Check.Input>
          </Form.Check>
        </td>
        <td className="table-column ellipsis">{capitalizeLetter(user.firstName)}</td>
        <td className="table-column ellipsis">{capitalizeLetter(user.lastName)}</td>
        <td className="ellipsis" title={user.dob}>
          {user.dob}
        </td>
        <td
          className="ellipsis"
          style={{
            textDecoration: "underline",
            color: "#A82632",
          }}
          onMouseOver={(e) => {
            e.target.style.cursor = "pointer";
            e.target.style.textDecoration = "underline";
          }}
          onClick={() => user?.email && window.open(`mailto:${user.email}`)}
        >
          {user.email}
        </td>
        <td
          className="table-column ellipsis"
          style={{ textDecoration: "underline", color: "#A82632" }}
          onMouseOver={(e) => {
            e.target.style.cursor = "pointer";
            e.target.style.textDecoration = "none";
          }}
          onMouseLeave={(e) => {
            e.target.style.textDecoration = "underline";
          }}
          onClick={() => {
            user.phoneNumber && window.open(`tel:+1${user.phoneNumber.replace(/\D/g, "")}`);
          }}
        >
          {user.phoneNumber}
        </td>
        <td className="table-column ellipsis" style={{ textAlign: "center" }}>
          {user.site_name}
        </td>
        <td className="table-column ellipsis" style={{ textAlign: "center" }}>
          {new Date(user.resultDate).toDateString()}
        </td>
        <td className="table-column ellipsis" style={{ textAlign: "center" }}>
          {user.expectedEndDate}
        </td>
        <td className="table-column ellipsis" style={{ textAlign: "center", color: "red" }}>
          {user.isQuarantined}
        </td>
        <td
          className="table-column ellipsis"
          style={{ textAlign: "center", textDecoration: "underline", color: "#A82632" }}
          onMouseOver={(e) => {
            e.target.style.cursor = "pointer";
            e.target.style.textDecoration = "none";
          }}
          onMouseLeave={(e) => {
            e.target.style.textDecoration = "underline";
          }}
          onClick={() => {
            if (!user.quarantinedEnd) {
              setSelectedQuarantine(user);
              setOpenDetailsQuarantine(true);
            }
          }}
        >
          {user.daysCount}
        </td>
        <td className="table-column ellipsis" style={{ textAlign: "center" }}>
          True
        </td>
      </tr>
    );
  };

  return (
    <div style={{ flex: 1 }}>
      {!loading ? (
        <Row>
          <Col md="12">
            <Card className="strpied-tabled-with-hover">
              <Card.Header>
                <Card.Title
                  as="h4"
                  style={{
                    marginBottom: 10,
                    fontWeight: "bold",
                    color: "#565656",
                  }}
                >
                  {`Quarantine (${filteredCrew.length})`}
                </Card.Title>
                <div className="buttonHeader">
                  {/* <Button className="headerButton" onClick={() => setShowFilter(!showFilter)}>
                    Filter
                  </Button> */}
                    <Icon
                        handleClick={() => setShowFilter(!showFilter)}
                        title={"Filter"}
                        label={"Filter"}
                        iconType={"filter"}
                      />
                  {/* <Button
                    className="headerButton"
                    onClick={() => {
                      if (filteredCrew && filteredCrew.length > 0) {
                        setAllSelected(!allSelected);
                        toggleCheckboxes(!allSelected);
                      }
                    }}
                  >
                    {!allSelected ? "Select All" : "Deselect All"}
                  </Button> */}
                     <Icon
                        handleClick={async () => {
                          if (filteredCrew && filteredCrew.length > 0) {
                            setAllSelected(!allSelected);
                            toggleCheckboxes(!allSelected);
                          }
                        }}
                        title={!allSelected ? "Select All" : "Deselect All"}
                        label={!allSelected ? "Select All" : "Deselect All"}
                        iconType={"selectAllIcon"}
                      />
                  {filteredCrew.length > 0 && (
                    <ExportToExcel
                      items={exportTests}
                      appContext={appContext}
                      filter={filter}
                      title="Quarantine Tests"
                      sortBy={sortBy}
                    />
                  )}
    <Icon
                        handleClick={handleOpenConfirmation}
                        title={"End Quarantined"}
                        label={"End Quarantined"}
                        iconType={"qr_Icon"}
                      />
                  {/* <Button className="headerButton" disabled={checkboxes.length === 0} onClick={handleOpenConfirmation}>
                    End Quarantine
                  </Button> */}
                </div>
                {showFilter && <Filter filterTerms={searchTerms} setFilter={setFilter} filter={filter} />}
                <MFPagination
                  currentPage={currentPage}
                  handleChangePage={handleChangePage}
                  totalPages={totalPages}
                  // showSelectedRecord
                  // totalSelected={checkboxes.length}
                />
              </Card.Header>

              <Card.Body className="table-full-width px-0 desktop-noScroll">
                <div className="table-responsive pendingReleaseTable">
                  <Table className="table-hover table-striped">
                    <thead ref={componentRef}>
                      <tr style={{ textAlign: "center" }}>
                        <th></th>
                        <th
                          style={{
                            color: sortBy === "firstName" ? "#A72632" : "grey",
                            fontWeight: "bold",
                            width: tdWidth,
                            maxWidth: tdWidth,
                          }}
                          className="border-0"
                          onClick={() => {
                            setSortBy("firstName");
                            setSortDescending(!sortDescending);
                          }}
                        >
                          <div>
                            <span>First Name</span>
                            <SetSortIcon sortName={"firstName"} />
                          </div>
                        </th>
                        <th
                          style={{
                            color: sortBy === "lastName" ? "#A72632" : "grey",
                            fontWeight: "bold",
                            width: tdWidth,
                            maxWidth: tdWidth,
                          }}
                          className="border-0"
                          onClick={() => {
                            setSortBy("lastName");
                            setSortDescending(!sortDescending);
                          }}
                        >
                          <div>
                            <span>Last Name</span>
                            <SetSortIcon sortName={"lastName"} />
                          </div>
                        </th>
                        <th
                          style={{
                            color: sortBy === "lastName" ? "#A72632" : "grey",
                            fontWeight: "bold",
                            width: tdWidth,
                            maxWidth: tdWidth,
                          }}
                          className="border-0"
                          onClick={() => {
                            setSortBy("dob");
                            setSortDescending(!sortDescending);
                          }}
                        >
                          <div>
                            <span>DoB</span>
                            <SetSortIcon sortName={"dob"} />
                          </div>
                        </th>
                        <th
                          style={{
                            color: sortBy === "email" ? "#A72632" : "grey",
                            fontWeight: "bold",
                            width: tdWidth,
                            maxWidth: tdWidth,
                          }}
                          className="border-0"
                          onClick={() => {
                            setSortBy("email");
                            setSortDescending(!sortDescending);
                          }}
                        >
                          <div>
                            <span>Email</span>
                            <SetSortIcon sortName={"email"} />
                          </div>
                        </th>
                        <th
                          style={{
                            color: sortBy === "phoneNumber" ? "#A72632" : "grey",
                            fontWeight: "bold",
                            width: tdWidth,
                            maxWidth: tdWidth,
                          }}
                          className="border-0"
                          onClick={() => {
                            setSortBy("phoneNumber");
                            setSortDescending(!sortDescending);
                          }}
                        >
                          <div>
                            <span>Phone Number</span>
                            <SetSortIcon sortName={"phoneNumber"} />
                          </div>
                        </th>
                        <th
                          style={{
                            color: sortBy === "site_name" ? "#A72632" : "grey",
                            fontWeight: "bold",
                            width: tdWidth,
                            maxWidth: tdWidth,
                          }}
                          className="centered border-0 ellipsis"
                          onClick={() => {
                            setSortBy("site_name");
                            setSortDescending(!sortDescending);
                          }}
                        >
                          <div>
                            <span>Show</span>
                            <SetSortIcon sortName={"site_name"} />
                          </div>
                        </th>
                        <th
                          className="border-0 text-grey centered ellipsis"
                          style={{ fontWeight: "bold", width: tdWidth, maxWidth: tdWidth }}
                        >
                          Last Postive Test Date
                        </th>
                        <th
                          className="border-0 text-grey centered ellipsis"
                          style={{ fontWeight: "bold", width: tdWidth, maxWidth: tdWidth }}
                        >
                          Expected End Date
                        </th>
                        <th
                          className="centered text-grey ellipsis"
                          style={{ fontWeight: "bold", width: tdWidth, maxWidth: tdWidth }}
                        >
                          <span>Quarantined</span>
                        </th>
                        <th
                          className="centered text-grey ellipsis"
                          style={{ fontWeight: "bold", width: tdWidth, maxWidth: tdWidth }}
                        >
                          <span>Days Count</span>
                        </th>
                        <th
                          className="centered text-grey ellipsis"
                          style={{ fontWeight: "bold", width: tdWidth, maxWidth: tdWidth }}
                        >
                          <span>HIPAA Consent</span>
                        </th>
                      </tr>
                    </thead>
                    <tbody>{crewToMap && crewToMap.map((user) => <TableRow key={user.id} user={user} />)}</tbody>
                  </Table>
                </div>
              </Card.Body>
            </Card>
          </Col>
          {openConfirmModal && (
            <ConfirmationModal
              title="End Qurantine"
              message="Do you want to end Quarantine?"
              handleConfirm={handleEndQuarantine}
            />
          )}
          {openDetailsQuarantine && (
            <QuarantineDetailsModal test={selectedQuarantine} handleClose={() => setOpenDetailsQuarantine(false)} />
          )}
        </Row>
      ) : (
        <Loader />
      )}
    </div>
  );
};

export default QuarantineNew;
