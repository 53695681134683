import Loader from "components/Loader/Loader";
import ErrorMessage from "components/Message/ErrorMessage";
import ConfirmationModal from "components/Modal/ConfirmationModal";
import moment from "moment";
import { useEffect, useState } from "react";
import { Button, Modal } from "react-bootstrap";
import { SYSTEM_TIME_ZONE, getTestDates } from "utils";
import api from "../../api";
import { CONFIG } from "../../constant";
import DiagnosisAccordionView from "views/TestTypeSetting/TestDiagnosisView/DiagnosisAccordionView";
import { customCloneDeep } from "util/customLodash";
import CreateTestItem from "./CreateTestItem";
import CreateTestHeader from "./CreateTestHeader";
import { selectedDeviceSelector } from "store/features/general/generalSelectors";
import { useSelector } from "react-redux";
import { formatSelectedSource } from "utils";

const CreateOrderTest = (props) => {
  const { item, handleCloseCreateTest, appContext, userLabName } = props;
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [order, setOrder] = useState({});
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [testDone, setTestDone] = useState(false);

  const [isAllSelected, setIsAllSelected] = useState(true);
  const selectedDevice = useSelector(selectedDeviceSelector);

  const [ictList, setIctList] = useState(null);
  const [editIndexIct, setEditIndexIct] = useState(null);
  const [testDoneList, setTestDoneList] = useState([]);

  const setOrderList = (selectValue) => {
    let orderItem = { ...item };

    orderItem.testsToDo.test = orderItem?.testsToDo?.test?.map((item) => {
      if (item.isDone) {
        return item;
      }

      let selectedSource = (item.sources.length === 1 && item.sources) || null;

      return { ...item, isSelected: selectValue, selectedSource };
    });
    setOrder(orderItem);
  };

  useEffect(() => {
    getOrdersTests();
    setOrderList(true);
  }, []);

  const getOrdersTests = async () => {
    try {
      if (!item?.id || item.status === "Processed") return;

      const resData = await api.getOrderTestList(item.id);

      setTestDoneList(resData.rows);
    } catch (ex) {
      console.log(ex);
    }
  };

  const handleCreateTest = () => {
    const isAnyTestSelected = getTestListToCreate();

    if (!isAnyTestSelected) {
      appContext.showErrorMessage("Please select Test");
      return;
    }

    const isSourceSelected = order.testsToDo.test
      .filter((t) => t.isSelected)
      .every((t) => t.selectedSource && t.selectedSource?.length > 0);

    if (!isSourceSelected) {
      setError("Please select Test Source");
      return;
    }

    setShowConfirmation(true);
  };

  const handleItemChange = (index, updatedItem) => {
    const updatedTests = order.testsToDo.test.map((item, i) => (i === index ? { ...item, ...updatedItem } : item));

    setOrder({
      ...order,
      testsToDo: { ...order.testsToDo, test: updatedTests },
    });
  };

  const handleConfirmation = async (isConfirm) => {
    setShowConfirmation(false);
    if (!isConfirm) return;

    let cloneOrder = customCloneDeep(order);

    cloneOrder.testsToDo.test = cloneOrder?.testsToDo?.test?.map((item) => {
      const sources = item?.selectedSource && item?.selectedSource.length > 0 ? item?.selectedSource : [];

      // just give source when test is not done
      const uniqueSources = !item?.isDone && formatSelectedSource(sources);

      return {
        ...item,
        selectedSource: uniqueSources,
      };
    });

    await sendOrderForTestCreation({ orderValue: cloneOrder });
  };

  const sendOrderForTestCreation = async ({ orderValue, isPrint }) => {
    setLoading(true);

    try {
      const { eligibilityStatus } = orderValue;

      const { testCreatedAt, testDate } = getTestDates(orderValue);

      const res = await api.createMulipleTests({
        labName: userLabName,
        order: {
          ...orderValue,
          employee_demographics: {
            ...orderValue.employee_demographics,
            dob: moment(orderValue.employee_demographics.dob).format("MMDDYYYY"),
            worker: "",
            possibleexposure: "",
            confirmedexposure: "",
            relatedsymptoms: "",
            symptoms: "",
            othercomment: "",
            comment: "",
            docUploaded: false,
            isReview: false,
            venipuncture: false,
            distance: 0,
            isEligible: eligibilityStatus?.status?.toLowerCase() === "eligibile",
            stat: false,
          },
          labID: CONFIG.whiteLabelID,
          labName: appContext.userLabName,
          createSource: "portal",
          timezone: SYSTEM_TIME_ZONE,
          createdByPhone: appContext?.user?.phone_number,
          testCreatedAt: testCreatedAt,
          testDate: testDate,
          createdBy: appContext?.user?.sub,
          createdByName: appContext?.user?.name,
          tester_name: appContext?.user?.name,
        },
      });

      const {
        testsToDo: { test },
      } = orderValue;

      let printTestArray = [];

      const updatedTests = orderValue.testsToDo.test.map((item, i) => {
        const processedTest = res[item.label];

        if (processedTest) {
          printTestArray.push(processedTest);
          return { ...item, barcode: processedTest.test.barcode, isDone: true, isSelected: false };
        }
        return { ...item, isSelected: item.isDone ? false : true };
      });

      setOrder({
        ...orderValue,
        testsToDo: { ...orderValue.testsToDo, test: updatedTests },
      });

      setLoading(false);

      const successMessage = "Test created successfully";

      if (printTestArray?.length > 0) {
        setIsAllSelected(false);
        if (selectedDevice) {
          printTestArray.forEach((printData) => {
            appContext.printBarcode(printData.test);
          });
        }
      }
      if (!isPrint) {
        handleCloseCreateTest(successMessage);
        setTestDone(true);
      }
      appContext.showSuccessBottomMessage(successMessage);
    } catch (ex) {
      console.log("[handleConfirmation]", ex);
      setLoading(false);
      appContext.showErrorMessage("Fail to create the test");
    }
  };

  const getTestListToCreate = () =>
    order.testsToDo.test
      .filter((t) => t.isSelected)
      .map((t) => t.label || t.name)
      .join(",");

  const handleEditTest = (item, index) => {
    setIctList(item.ictCodes);
    setEditIndexIct(index);
  };

  const totalTest = () => order?.testsToDo?.test.filter((t) => t.isSelected).length;

  const saveDiagnosisCodes = (item) => {
    const copyOrder = customCloneDeep(order);

    if (copyOrder && copyOrder.testsToDo && copyOrder.testsToDo.test && copyOrder.testsToDo.test[editIndexIct]) {
      copyOrder.testsToDo.test[editIndexIct].ictCodes = item;
    }
    setOrder(copyOrder);
    setIctList(null);
    setEditIndexIct(null);
  };

  const handelSelectDeselect = () => {
    setOrderList(!isAllSelected);
    setIsAllSelected(!isAllSelected);
  };

  const handlePrintClick = async (printItem) => {
    if (printItem?.isDone) {
      const obj = {
        employee_demographics: {
          firstName: order.employee_demographics.firstName,
          lastName: order.employee_demographics.lastName,
          swabtype: printItem.selectedSource ? formatSelectedSource(printItem.selectedSource) : printItem.swabtype,
          dob: order.employee_demographics.dob,
        },
        barcode: printItem.barcode,
        createdAt: printItem.createdAt,
      };
      appContext.printBarcode(obj);
      return;
    }
    if (!printItem?.selectedSource || printItem?.selectedSource.length === 0) {
      setError("Please select Test Source");
      return;
    }
    const copyOrder = customCloneDeep(order);

    const {
      testsToDo: { test },
    } = copyOrder;

    const updatedTests = copyOrder.testsToDo.test.map((item, i) => {
      const isSelected = item.id === printItem.id;

      return {
        ...item,
        isSelected,
        selectedSource: isSelected ? formatSelectedSource(item?.selectedSource) : item?.selectedSource,
      };
    });

    const objToProcess = {
      ...copyOrder,
      testsToDo: { ...copyOrder.testsToDo, test: updatedTests },
    };

    await sendOrderForTestCreation({ orderValue: objToProcess, isPrint: true });
  };

  return (
    <>
      {ictList && (
        <DiagnosisAccordionView
          handleClose={() => setIctList(null)}
          handelSaveDiagnosisPanel={saveDiagnosisCodes}
          ictList={ictList}
          setIctList={setIctList}
          isNew={false}
        />
      )}

      <Modal
        show
        animation={true}
        onHide={handleCloseCreateTest}
        size="xl"
        style={{ paddingLeft: "0" }}
        centered
        className="seperateModal"
        backdrop="static"
      >
        <Modal.Header closeButton>
          <Modal.Title className="my-0" id="contained-modal-title-vcenter">
            {order.firstName} {order.lastName} - Date of Birth:{" "}
            {moment(order?.employee_demographics?.dob).format("MM-DD-YYYY")}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body
          style={{
            paddingTop: 0,
          }}
        >
          <table className="custom-separate-border-tr table w-100">
            <thead>
              <CreateTestHeader
                showSelection={true}
                isAllSelected={isAllSelected}
                testDone={testDone}
                handelSelectDeselect={handelSelectDeselect}
              />
            </thead>
            <tbody>
              {order.testsToDo?.test
                ?.filter((item) => item.testStatus !== "Rejected")
                .map((c, index) => (
                  <CreateTestItem
                    key={index}
                    item={c}
                    index={index}
                    showSelection={true}
                    testDoneList={testDoneList}
                    onItemChange={handleItemChange}
                    handleEditTest={handleEditTest}
                    onPrintClick={handlePrintClick}
                    appContext={appContext}
                    order={order}
                  />
                ))}
            </tbody>
          </table>
        </Modal.Body>
        <div className="px-4" style={{ height: "3vh" }}>
          {error && <ErrorMessage error={error} handleChange={() => setError("")} />}
        </div>
        <Modal.Footer>
          <Button
            style={{ marginBottom: 10 }}
            variant="secondary"
            className="modalButtons"
            onClick={() => {
              handleCloseCreateTest();
            }}
          >
            Close
          </Button>
          <div>
            <Button
              style={{ marginBottom: 10 }}
              variant="primary"
              disabled={loading || testDone}
              className="modalButtons mx-2"
              onClick={handleCreateTest}
            >
              Create Test
            </Button>
          </div>
          {loading && <Loader />}
          {showConfirmation && (
            <ConfirmationModal
              show={showConfirmation}
              title="Confirmation"
              message={`Are you sure you want to create the ${getTestListToCreate()} ${
                totalTest() > 1 ? "tests" : "test"
              }`}
              handleConfirm={handleConfirmation}
              cssClass="seperateModal"
            />
          )}
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default CreateOrderTest;
