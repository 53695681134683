import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { TEST_ASSAY, GET_ASSOCIATED_PANEL } from "./testAssayConstant";
import api from "api";

export const getTestAssociatePanel = createAsyncThunk(GET_ASSOCIATED_PANEL, async () => {
  const response = await api.getTestsDetails({ search: "" });
  if (response) return response.rows;
});

const testAssaySlice = createSlice({
  name: TEST_ASSAY,
  initialState: {
    panelsList: [],
  },
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getTestAssociatePanel.fulfilled, (state, action) => {
      state.panelsList = action.payload;
    });
  },
});

export default testAssaySlice.reducer;
