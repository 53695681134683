import React, { useState } from "react";
import { Button, Modal, Card, Row, Col } from "react-bootstrap";
import { printPdfDocuments } from "../../../src/util/printPdf";
import { CONFIG } from "constant";

const ShowPrintOptions = (props) => {
  const { tests, handleClose, printBarcode } = props;
  const [selectedOption, setSelectedOption] = useState("");
  const options = [
    { label: "Label", value: "Label" },
    { label: "Result", value: "Result" },
    { label: "Requisition Form", value: "PDF" },
    { label: "All", value: "All" },
  ];

  const onChangeSelection = (option) => {
    setSelectedOption(option);
  };

  const onHandlePrint = () => {
    const urls = [];
    const env = process.env.REACT_APP_ENV === "dev" ? "test" : "live";
    const { clientID } = CONFIG;

    tests?.forEach((test) => {
      switch (selectedOption.label) {
        case "All":
          urls.push(
            `results/${env}/${clientID}/${test.barcode}.pdf`,
            `req_form/${env}/${clientID}/${test.barcode}.pdf`
            // Add more URLs as needed
          );
          break;
        case "Result":
          urls.push(`results/${env}/${clientID}/${test.barcode}.pdf`);
          break;
        case "Label":
          printBarcode(test);
          break;
        case "Requisition Form":
          urls.push(`req_form/${env}/${clientID}/${test.barcode}.pdf`);
          break;
        default:
          return; // Handle cases where no valid option is selected
      }
    });

    if (urls.length > 0) {
      printPdfDocuments(urls);
      handleClose();
    }
  };

  return (
    <>
      <Modal
        onHide={() => {
          handleClose();
        }}
        show
        animation={true}
        size={"md"}
        className="printoptionsModalWidth  second-modal"
        scrollable
      >
        <Modal.Header closeButton>
          <Modal.Title className="my-0" id="contained-modal-title-vcenter">
            Select Print Options
          </Modal.Title>
        </Modal.Header>

        <Modal.Body className="marginTop0">
          <>
            <div className="shows-screen-parent">
              <Row className="mx-0">
                <Col md="12">
                  <Card className="striped-tabled-with-hover bg-transparent">
                    <Card.Body className="table-full-width px-0 desktop-noScroll mt-0 pt-0">
                      <div className="custom-radio-btn-wrapper">
                        {options.map((option, index) => {
                          return (
                            <div key={index}>
                              <input
                                id={index}
                                className="radio-custom"
                                name="radio-group"
                                type="radio"
                                value={option.value}
                                onChange={() => onChangeSelection(option)}
                              />
                              <label htmlFor={index} className="radio-custom-label">
                                <div className="d-inline-flex flex-column align-items-start">
                                  <span className="fw-bold">{option.label}</span>
                                </div>
                              </label>
                            </div>
                          );
                        })}
                      </div>
                    </Card.Body>
                  </Card>
                </Col>
              </Row>
            </div>
          </>
        </Modal.Body>

        <Modal.Footer className="flex-row-reverse">
          <Button className="modalButtons" variant="primary" onClick={onHandlePrint}>
            Print
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default ShowPrintOptions;
