import api from "api";
import MainTable from "components/Table/MainTable";
import { CREATE_TEST_PANEL_PERSONALIZE } from "constants/personalization";
import { AppContext } from "context/app-context";

import React, { useContext } from "react";
import { Button, Modal } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { setGenericModalData, setGenericPathogenModalData } from "store/features/genericTestsView/genericModalSlice";

const GenericPanelTestView = ({ data }) => {
  const TYPE_TO_SHOW = {
    mainTest: "Test",
    subTest: "Patagen",
    panel: "Panel",
  };

  const context = useContext(AppContext);
  const dispatch = useDispatch();

  const customRenderTD = (item, row, index) => {
    if (item.itemKey === "type") {
      return <td key={`type_${item.id}`}>{TYPE_TO_SHOW[row?.testType || row.type]}</td>;
    }
    if (item.itemKey === "name") {
      return (
        <td key={`name_${item.id}`} onClick={() => showPathogen(row)} style={{ cursor: "pointer" }}>
          {row.name}
        </td>
      );
    }
  };

  const handleClose = () => {
    dispatch(setGenericModalData({ showGenericPanelModal: false }));
  };

  const showPathogen = async (row) => {
    if (row.type === "mainTest") {
      const panelData = await api.getTestTypesByID(row.id);

      if (panelData.isAssociatedPanel) {
        const objToDispatch = {
          showGenericPathogenModal: true,
          genericPathogenData: panelData.targetRanges,
        };
        dispatch(setGenericPathogenModalData(objToDispatch));
      } else {
        context.showErrorMessage("This test has not any pathogen..");
      }
    }
  };

  return (
    <Modal
      show
      // style={{ transform: "translate(0, -30%)" }}
      animation={true}
      onHide={handleClose}
      centered
      size={"xl"}
      backdrop="static"
    >
      <Modal.Header closeButton>
        <Modal.Title className="my-0 h4" id="contained-modal-title-vcenter">
          Panels / Test Details
        </Modal.Title>
      </Modal.Header>
      <Modal.Body style={{ paddingTop: 0, lineHeight: 0 }}></Modal.Body>

      <div style={{ padding: "20px" }}>
        <div style={{ background: "#f2f0f0", paddingLeft: "4px", paddingRight: "4px", marginTop: "10px" }}>
          <MainTable
            columns={CREATE_TEST_PANEL_PERSONALIZE}
            rows={data}
            customColumnCellRenderer={customRenderTD}
            widthToSkip={155}
          />
        </div>
      </div>

      <Modal.Footer>
        <Button variant="secondary" className="modalButtons" onClick={() => handleClose()}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default GenericPanelTestView;
