import React from "react";
import FilledCheckBox from "components/CheckBox/FilledCheckBox/FilledCheckBox";

const CheckBoxInput = (props) => {
  const { options, label, input, handleButtonChange, disabled, cssClass = "" } = props;
  const handleChange = (e, m) => {
    let arr = input?.split(",");
    if (e.target.checked) {
      arr.push(m.value);
    } else {
      arr = arr.filter((f) => f !== m.value);
    }
    arr = arr.filter(Boolean);
    handleButtonChange(arr.length > 0 ? arr.join(",") : "");
  };
  return (
    <>
      <div className="form-group-wrapper mt-3">
        {label && <label className="status">{label}</label>}
        <div className="py-1 checkbox-list">
          {options.map((m, ind) => (
            <FilledCheckBox
              label={m.label}
              disabled={disabled}
              id={m.id}
              isChecked={input?.includes(m.value)}
              handleChange={(e) => handleChange(e, m)}
              cssClass={cssClass}
              key={ind}
            />
          ))}
        </div>
      </div>
    </>
  );
};

export default CheckBoxInput;
