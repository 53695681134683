import React from "react";

const SwitchToggle = (props) => {
  const { id, name, handleToogleChange, handleChange, checked, disabled } = props;
  return (
    <label className="switch">
      <input
        type="checkbox"
        id={id}
        name={name}
        onChange={handleToogleChange ?? handleChange}
        checked={checked}
        disabled={disabled}
      />
      <span className={`slider round ${disabled ? "disabled" : ""}`} />
    </label>
  );
};
export default SwitchToggle;
