import api from "api";
import "bootstrap-daterangepicker/daterangepicker.css";
import ErrorMessage from "components/Message/ErrorMessage";
import Loader from "components/Loader/Loader";
import { NOTES_MAX_CHARACTER_LENGTH } from "constant";
import moment from "moment";
import { useState } from "react";
import { Button, Modal } from "react-bootstrap";
import PreviousNotes from "./PreviousNotes";

const PendingOrderNoteModal = (props) => {
  const { data, handleClose, user } = props;

  // Initialize notes for all data objects
  const initialNotes = data.reduce((acc, item) => {
    acc[item.id] = item?.employee_demographics?.comment || "";
    return acc;
  }, {});

  const [notes, setNotes] = useState(initialNotes);
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);

  const onHandleSave = async () => {
    setLoading(true);
    const promises = data.map(async (item) => {
      const obj = {
        id: item.id,
        note: notes[item.id],
        updatedBy: user.sub,
        updatedByName: user.name,
      };

      try {
        const { employee_demographics } = item;
        const demos =
          typeof employee_demographics === "string" ? JSON.parse(employee_demographics) : employee_demographics;
        demos.comment = notes[item.id];
        const objToUpdate = { employee_demographics: JSON.stringify(demos) };

        await api.updateTest(obj.id, objToUpdate);
      } catch (err) {
        console.log("Error updating note for item ID:", item.id, err);
      }
    });

    try {
      await Promise.all(promises);
      setLoading(false);
      handleClose();
    } catch (err) {
      console.log("Error:-", err);
      setError("An error occurred while saving notes.");
      setLoading(false);
    }
  };

  const handleNoteChange = (id, value) => {
    setNotes((prevNotes) => ({
      ...prevNotes,
      [id]: value,
    }));
  };

  return (
    <Modal show backdrop="static" className="second-modal" onHide={handleClose} animation={true} size={"lg"} scrollable>
      <Modal.Header closeButton>
        <Modal.Title className="my-0">Comment</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {data.map((item) => (
          <div key={item.id} className="row textAreaMessageInput">
            <div className="col-12 mb-3">
              <textarea
                placeholder={`Write a comment for ${item.firstName} ${item.lastName}`}
                className="MessageInput"
                value={notes[item.id]}
                onChange={(e) => handleNoteChange(item.id, e.target.value)}
                maxLength={NOTES_MAX_CHARACTER_LENGTH}
              />
            </div>
            <PreviousNotes id={item.id} user={user} />
          </div>
        ))}
        <div style={{ minHeight: "3vh" }}>
          {error && <ErrorMessage error={error} handleChange={() => setError("")} />}
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button
          style={{ marginBottom: 10 }}
          variant="primary"
          className="modalButtons headerButton btn-fill"
          onClick={handleClose}
        >
          Close
        </Button>
        <div className="d-flex">
          <Button
            style={{ marginBottom: 10 }}
            variant="secondary"
            className="modalButtons headerButton btn-fill"
            onClick={onHandleSave}
          >
            Save
          </Button>
        </div>
        {loading && <Loader />}
      </Modal.Footer>
    </Modal>
  );
};

export default PendingOrderNoteModal;
