import React from "react";
import { Bounce } from "react-activity";
import "react-activity/dist/Bounce.css";

const Loader = ({ cssClass }) => {
  return (
    <>
      <div
        style={{
          width: "100vw",
          height: "100vh",
          position: "fixed",
          top: "0",
          left: "0",
          zIndex: "11000",
        }}
      />
      <Bounce
        style={{ flex: 1, alignItems: "center", justifyContent: "center", zIndex: 20 }}
        size={35}
        className={`${cssClass} loader`}
      />
    </>
  );
};
export default Loader;
