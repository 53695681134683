import React, { useState } from "react";
import { Form, Container, Col, Row, Card, Badge } from "react-bootstrap";

const QuestionAndAnswerForm = (props) => {
  const { data, handleSaveAns } = props;
  const { question, options, id, a, expectedAns, isNotValid } = data;

  const handleOptionChange = (index) => {
    handleSaveAns({ id, a: index, options, question, expectedAns });
  };

  return (
    <Card>
      <Card.Body>
        <h5>{`Q${id}: ${question}`}</h5>
        <Form>
          <Row>
            {options.map((option, index) => (
              <div key={index} onClick={() => handleOptionChange(index + 1)} className={(a === index+1) && isNotValid ? 'hipaa-answer-wrong' : ''}>
                {" "}
                {/* Adjust the column widths for different breakpoints */}
                <Form.Check type="radio" label={option} checked={a === index + 1} />
              </div>
            ))}
          </Row>
        </Form>
      </Card.Body>
    </Card>
  );
};

export default QuestionAndAnswerForm;
