import { createSlice } from "@reduxjs/toolkit";
import { CLIENT_PCS } from "./clientPcsConstant";
import { customKeyBy } from "util/customLodash";
import { addSelectedPcs, removeSelectedPcs, showHideClientPcsModal, resetClientPcsState } from "./clientPcsAction";

const initialState = {
  clientPcsList: [],
  clientId: "",
  showClientPcsModal: false,
};

const clientPcsSlice = createSlice({
  name: CLIENT_PCS,
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(addSelectedPcs.fulfilled, (state, action) => {
      const payloadArray = Array.isArray(action.payload) ? action.payload : [action.payload];

      const combinedList = [...state.clientPcsList, ...payloadArray];

      const uniqueByKey = customKeyBy(combinedList, "id");

      state.clientPcsList = Object.values(uniqueByKey);
    });

    builder.addCase(removeSelectedPcs.fulfilled, (state, action) => {
      const pcsId = action.payload.id;
      state.clientPcsList = state.clientPcsList.filter((item) => item.id !== pcsId);
    });
    builder.addCase(showHideClientPcsModal.fulfilled, (state, action) => {
      const { clientId = "", show, pcsData } = action.payload;
      state.showClientPcsModal = show;
      state.clientId = clientId;
      state.clientPcsList = pcsData ?? state.clientPcsList;
    });
    builder.addCase(resetClientPcsState.fulfilled, () => {
      console.log("showClientPcsModal here");
      return initialState;
    });
  },
});

export default clientPcsSlice.reducer;
