import { CPT_CODE_SEARCH_TYPE } from "constant";
import React from "react";

const TestTypeInput = ({
  onChange,
  tabIndex = 0,
  placeholder = "",
  value = "",
  label = null,
  required = false,
  disabled = false,
  searchType,
  handleShowCPT,
  readOnly,
  ...rest
}) => {
  return (
    <div className="test-component-element position-relative">
      {label && (
        <p className={`test-type-input-label ${searchType && "d-flex align-items-center"}`}>
          {label}
          {required && <span style={{ color: "red" }}>*</span>}
          {searchType && searchType === CPT_CODE_SEARCH_TYPE.CPT && (
            <label className="manageText" onClick={handleShowCPT}>
              Manage
            </label>
          )}
        </p>
      )}
      <input
        className="modalInput"
        value={value}
        placeholder={placeholder}
        style={{ width: "100%" }}
        onChange={onChange}
        tabIndex={tabIndex}
        disabled={disabled}
        readOnly={readOnly}
        {...rest}
      />
    </div>
  );
};

export default TestTypeInput;
