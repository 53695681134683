import React, { useState, useEffect } from "react";
import Login from "components/Login/Login";
import { Bounce } from "react-activity";
import API from "api";
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";
import AdminNew from "layouts/AdminNew";
import PreRegister from "views/PreRegister";
import RegisterCrew from "views/RegisterCrew";
import QRLinkView from "components/QRLinkView";
import Loader from "./components/Loader/Loader";
import { CONFIG } from "constant";
import LabNew from "layouts/LabNew";
import SiteNew from "layouts/SiteNew";
import HipaaTest from "views/Hipaa/HipaaTest";
import { Provider } from "react-redux";
import store from "./store/store";
import PreRegisterWhitelabel from "views/PreRegisterWhitelabel";

export default function App() {
  const [loading, setLoading] = useState(true);
  const [user, setUser] = useState(null);

  useEffect(() => {
    const getUSer = async () => {
      const user = await API.getCurrentUser();
      if (user) {
        setUser(user);
        setLoading(false);
      } else {
        setLoading(false);
      }
    };
    getUSer();
  }, []);

  const version = process.env.REACT_APP_VERSION;

  useEffect(() => {
    if (version) {
      // Dynamically add script with versioning
      const script = document.createElement("script");
      script.src = `/static/js/main.js?v=${version}`;
      document.body.appendChild(script);

      // Dynamically add stylesheet with versioning
      const link = document.createElement("link");
      link.rel = "stylesheet";
      link.href = `/static/css/main.css?v=${version}`;
      document.head.appendChild(link);

      return () => {
        // Clean up the dynamically added elements if needed
        document.body.removeChild(script);
        document.head.removeChild(link);
      };
    }
  }, [version]);

  if (loading) {
    return <Loader />;
  }

  sessionStorage.setItem("connectedID", localStorage.getItem("state"));

  if (CONFIG.isWhiteLabel) {
    return (
      <Provider store={store}>
        <BrowserRouter>
          <Switch>
            <Route path="/hipaatest" render={(props) => <HipaaTest {...props} />} />
            <Route path="/hipaatest/:id" render={(props) => <HipaaTest {...props} />} />
            <Route path="/pre-register" render={(props) => <PreRegisterWhitelabel />} />
            <Route path="/admin" render={(props) => <AdminNew {...props} />} />
            <Route path="/lab" render={(props) => <LabNew {...props} />} />
            <Route path="/site" render={(props) => <SiteNew {...props} />} />
            <Route path="/login" render={(props) => <Login />} />
            <Redirect from="/" to="/login" />
          </Switch>
        </BrowserRouter>
      </Provider>
    );
  }

  return (
    <Provider store={store}>
      <BrowserRouter>
        <Switch>
          <Route path="/pre-register" render={(props) => <PreRegister />} />
          <Route path="/register/:id" render={(props) => <RegisterCrew {...props} />} />
          <Route path="/register" render={(props) => <RegisterCrew {...props} />} />
          <Route path="/preregister/:testId" render={(props) => <QRLinkView {...props} />} />
          <Route path="/admin" render={(props) => <AdminNew {...props} />} />
          <Route path="/lab" render={(props) => <LabNew {...props} />} />
          <Route path="/site" render={(props) => <SiteNew {...props} />} />
          <Route path="/login" render={(props) => <Login />} />
          <Redirect from="/" to="/login" />
        </Switch>
      </BrowserRouter>
    </Provider>
  );
}
