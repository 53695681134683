import React, { useState, useEffect, useContext, useMemo, memo } from 'react';
import { Button, Modal, Card, Row, Col } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import {
  showBarcodeSettingsModalSelectors,
  locationsSelectors,
} from 'store/features/locations/locationsSelectors';
import { setBarcodeSettingsModal, updateLabClient } from 'store/features/locations/locationsSlice';
import Icon from 'components/Icon';
import InputField from 'components/InputField/InputField';
import { customCloneDeep } from 'util/customLodash';
import { isBarcodeRangeAssigned, viewBarcodeRange } from 'utils';
import { AppContext } from 'context/app-context';
import MainTable from 'components/Table/MainTable';
import { BARCODE_SETTINGS_COLUMN } from 'constants/personalization';
import Loader from 'components/Loader/Loader';

const BarcodeSettingModal = ({ labClient, setLabClient }) => {
  const dispatch = useDispatch();

  const labLocations = useSelector(locationsSelectors);

  const [loading, setLoading] = useState(false);

  const showBarcodeSettings = useSelector(showBarcodeSettingsModalSelectors);

  const appContext = useContext(AppContext);

  const [barcodeRange, setBarcodeRange] = useState(null);

  useEffect(() => {
    if (labClient?.setting?.barcodeRange) {
      setBarcodeRange(labClient.setting.barcodeRange);
    } else {
      setBarcodeRange(null);
    }
  }, [labClient]);

  const clientRanges = useMemo(() => {
    return labLocations
      .filter((location) => location.id !== labClient.id && location?.setting?.barcodeRange)
      .map((location) => ({
        name: location.name || '',
        id: location.id,
        barcodeRange: location?.setting?.barcodeRange || '',
        barcodeRangeToShow: viewBarcodeRange(location?.setting?.barcodeRange),
      }));
  }, [labLocations, labClient?.id]);

  const handleClose = () => {
    dispatch(setBarcodeSettingsModal(false));
  };

  const handleRangeChange = ({ fieldName, value }) => {
    if (value.length === 5) return;
    const numericValue = Number(value);
    if (numericValue >= 0) {
      setBarcodeRange((prevRange) => ({ ...prevRange, [fieldName]: numericValue }));
    }
  };

  const handleAddBarcodeRange = async () => {
    setLoading(true);

    if (!barcodeRange?.startRange || !barcodeRange?.endRange) {
      appContext.showErrorMessage('Please fill both start and end ranges.');
      return;
    }

    const { startRange, endRange } = barcodeRange;
    if (startRange >= endRange) {
      appContext.showErrorMessage('Start range must be less than end range.');
      return;
    }

    const isAlreadyAssigned = isBarcodeRangeAssigned({
      newRange: barcodeRange,
      clients: clientRanges,
    });

    if (isAlreadyAssigned.error) {
      appContext.showErrorMessage(isAlreadyAssigned.message);
      return;
    }

    const updatedClient = customCloneDeep(labClient);
    updatedClient.setting = updatedClient.setting || {};
    updatedClient.setting.barcodeRange = barcodeRange;

    await dispatch(updateLabClient(updatedClient));
    setLabClient(updatedClient);
    setLoading(false);

    appContext.showSuccessMessage('Accession# updated successfully.');
    handleClose()
  };

  const handleBarcodeRemove = async (row) => {
    const labClient = labLocations.find((item) => item.id === row.id);

    if (labClient) {
      setLoading(true);
      const updatedLabClient = {
        ...labClient,
        setting: {
          ...labClient.setting,
          barcodeRange: null,
        },
      };
      await dispatch(updateLabClient(updatedLabClient));
      setLoading(false);
    }
  };

  const customRenderTD = (item, row, index) => {
    if (item.itemKey === 'action') {
      return (
        <td key={`bin_${item.id}`} style={{ textAlign: 'right' }}>
          <Icon
            iconType={'binIcon'}
            containerClass={'ms-2 cursor-pointer'}
            innerClass={''}
            imageClass={'testTabelDeleteIcon'}
            handleClick={() => handleBarcodeRemove(row)}
          />
        </td>
      );
    }
    return <td key={item.id}>{row?.title || row?.label}</td>;
  };

  return (
    <Modal
      show={showBarcodeSettings}
      backdrop="static"
      animation={true}
      onHide={handleClose}
      centered
      size="lg"
      className="second-modal"
    >
      <Modal.Header closeButton>
        <Modal.Title className="my-0" id="contained-modal-title-vcenter">
          Accession# Range
        </Modal.Title>
      </Modal.Header>

      <Modal.Body style={{ paddingTop: 0 }}>
        {loading && <Loader />}
        <Row>
          <Col md="12">
            <Card>
              <Card.Body className="table-full-width desktop-noScroll">
                <div className="d-flex justify-content-start align-items-center tabSettings-wrapper gap-3">
                  <div className="w-100">
                    <InputField
                      type="number"
                      min={0}
                      inputStyle="modalInput"
                      groupWrapper="w-100"
                      label="Start Range"
                      labelStyle="mt-0 modalLineHeaders text-capitalize"
                      placeholder="Enter Start Range"
                      handleChange={(e) =>
                        handleRangeChange({ fieldName: 'startRange', value: e.target.value })
                      }
                      value={barcodeRange?.startRange || ''}
                    />
                  </div>
                  <div className="w-100">
                    <InputField
                      type="number"
                      min={0}
                      groupWrapper="w-100"
                      inputStyle="modalInput"
                      labelStyle="mt-0 modalLineHeaders text-capitalize"
                      label="End Range"
                      placeholder="Enter End Range"
                      handleChange={(e) =>
                        handleRangeChange({ fieldName: 'endRange', value: e.target.value })
                      }
                      value={barcodeRange?.endRange || ''}
                    />
                  </div>

                  <Icon
                    iconType="saveIcon"
                    containerClass="mt-4"
                    innerClass=""
                    imageClass="cursor-pointer"
                    handleClick={handleAddBarcodeRange}
                  />
                </div>
              </Card.Body>
            </Card>
          </Col>
        </Row>
        {!!clientRanges.length && (
          <Row style={{ height: '20vh', overflowY: 'auto' }}>
            <Col md="12">
              <Card>
                <Card.Body>
                  <MainTable
                    columns={BARCODE_SETTINGS_COLUMN}
                    rows={clientRanges || []}
                    widthToSkip={160}
                    cssClass="table-noresponsive"
                    trDataWrapperHeader={'trDataWrapperHeader'}
                    strippedTable={true}
                    customColumnCellRenderer={customRenderTD}
                  />
                </Card.Body>
              </Card>
            </Col>
          </Row>
        )}
      </Modal.Body>

      <Modal.Footer className="d-flex justify-content-between">
        <Button variant="primary" className="headerButton btn-fill" onClick={handleClose}>
          Cancel
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default memo(BarcodeSettingModal);
