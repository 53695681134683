import React, { useState, useEffect, useContext } from "react";
import { Modal, Button, Table, Row, Col } from "react-bootstrap";
import API from "api";
import { Bounce } from "react-activity";
import Select from "react-select";
import { isValidPhoneWithCode } from "utils";
import { AppContext } from "context/app-context";
import { CSVReader } from "react-papaparse";
import Loader from "../Loader/Loader";

const ImportCrewPhoneModal = (props) => {
  const { user, sites, handleClose } = props;
  const appContext = useContext(AppContext);
  const getSelectedSite = () => {
    if (sites.length === 1) return { label: sites[0].name, value: sites[0].id, id: sites[0].id, name: sites[0].name };
    return {};
  };
  const [loading, setLoading] = useState(false);
  const [selectedShow, setSelectedShow] = useState(getSelectedSite());

  const handleOnDrop = async (crewData) => {
    console.log("Imported Data ", crewData, user);
    setLoading(true);
    let ttlInvitations = 0;
    const link = `${CONFIG.baseurl}pre-register`;
    const emailMsg = `<p>Hello, ${user.name} has invited You to setup an account with ${CONFIG.basename}. Please click on the below link to create an account and receive your QR Code.<br><br>${link}<br><br>Best Regards, <br>${CONFIG.basename} Team</p>`;
    const msg = `Hello, ${user.name} has invited You to setup an account with ${CONFIG.basename}. Please click on the below link to create an account and receive your QR Code.\n\n${link}`;
    for (let i = 0; i < crewData.length; i++) {
      let  val = crewData[i].data;
      const key = Object.keys(val)
      let phone= val[key[0]];
      if (phone && !new RegExp(/[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,15}/g).test(phone)  && phone.substring(0, 1) !== "+") {
        phone = `+${phone.replace(/\D/g, "")}`;
      }
      console.log("phone", phone, isValidPhoneWithCode(phone));

      if (isValidPhoneWithCode(phone)) {
        await API.sendSMSNotification(phone, msg);
        ttlInvitations += 1;
      } else {
        const emailAddress = phone;
        console.log("success",emailAddress);
        if (new RegExp(/[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,15}/g).test(emailAddress)) {
          await API.sendInvitationEmail([
            { email: emailAddress, subject: "Pre-Registration Invitation", msg: emailMsg },
          ]);
          ttlInvitations += 1;
        }
      }
    }
    if(ttlInvitations >0){
    appContext.showSuccessMessage(`Success: Invitation has been sent to ${ttlInvitations} users.`);
    }else{
      appContext.showErrorMessage(`Failed data Load`);
    }
    setLoading(false);
    handleClose();
  };

  const sendEmail = async (data) => {
    console.log("Imported Data ", data, user);
    setLoading(true);
    let ttlInvitations = 0;
    const link = `${CONFIG.baseurl}pre-register`;
    const emailMsg = `<p>Hello,<br><br>you have been invited to ${selectedShow.label}. Please click on the below link to preregister to get your QR code.\n${link}<br><br>Best Regards, <br>${CONFIG.basename} Team</p>`;
    for (let i = 0; i < data.length; i++) {
      if (new RegExp(/[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,15}/g).test(phone)) {
        console.log("Valid Email ", email);
        await API.sendInvitationEmail([{ email: phone, msg: emailMsg }]);
      }
    }
    alert(`Success: Invitation has been sent to ${ttlInvitations} users.`);
    setLoading(false);
    handleClose();
  };
  const handleOnError = (err) => {
    appContext.showErrorMessage(err.message);
  };

  const handleOnRemoveFile = (data) => {
    console.log("Remove Data", data);
  };

  return loading ? (
      <Loader />
  ) : (
    <Modal show animation={true} onHide={() => handleClose()} centered size={"md"}>
      <Modal.Header closeButton>
        <Modal.Title className="my-0" id="contained-modal-title-vcenter">
          Pre-Load Crew
        </Modal.Title>
      </Modal.Header>

      <Modal.Body style={{ paddingTop: 0 }}>
        {sites.length > 1 && (
          <div className="form-group-wrapper">
            <label className="modalLineHeaders">Select Show:</label>
            <Select
              options={sites.map((site) => {
                return { label: site.name, value: site.id, id: site.id, name: site.name };
              })}
              blurInputOnSelect={true}
              defaultValue={null}
              menuPlacement="auto"
              placeholder="Select a Show"
              value={selectedShow}
              onChange={(e) => setSelectedShow(e)}
              className="w-100 roleSelector"
            />
          </div>
        )}
        <div className="form-group-wrapper d-block">
          {Object.keys(selectedShow).length > 0 && (
            <CSVReader
            onDrop={handleOnDrop}
            onError={handleOnError}
            addRemoveButton
            config={{
              header: true,
              skipEmptyLines: true
            }}
            onRemoveFile={handleOnRemoveFile}
          >
            <span>Drop CSV file here or click to upload.</span>
          </CSVReader>
          )}
        </div>
      </Modal.Body>

      <Modal.Footer>
        <Button variant="primary" onClick={() => handleClose()}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default ImportCrewPhoneModal;
