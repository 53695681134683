import React, { useEffect, useState } from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  BarElement,
  ArcElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import API from "api";
import { Bar, Line, Pie } from "react-chartjs-2";
import { SUMMARY_STATS_TYPE } from "constant";
import moment from "moment";
import { SUMMARY_TABLES_KEY_LABELS } from "constant";
import { GRAPH_COLOR_VALUES } from "constant";
import { GRAPH_WEEKDAYS_LABELS } from "constant";
// import Slider from "../../components/Slider/Slider";

ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, BarElement, ArcElement, Title, Tooltip, Legend);

const DashboardLineChart = (props) => {

  const { statsType, connectedID, statsTitle, dateTimeType , orderedClientNamesData , orderedLabNamesData , orderedSiteNamesData} = props;

  let summaryDataFromLocalStorage =     JSON.parse(localStorage.getItem(statsType + "Data"));
  const [summaryResultData, setSummaryResultData] = useState( summaryDataFromLocalStorage || []);
  const [isWeeklyData , setIsWeeklyData] = useState(false);
  const [dateType, setDateType] = useState("month");
  const [labels, setGraphLabel] = useState([]);
  const [colors, setColors] = useState(GRAPH_COLOR_VALUES);
  const [legendcolor, setLegend] = useState([]);


  const loadGraphStats = async (statsSummary) => {
    try {

      let startDate = moment().startOf(dateType).format('YYYY-MM-DD');
      let endDate = "";
      let dates = [];
      let formatedDates = [];
      let graphlabels = [];
      if (dateType == "isoWeek") {
        endDate = moment().endOf(dateType).format('YYYY-MM-DD');
        for (var i = 0; i <= 6; i++) {
          dates.push(moment(startDate).add(i, 'days').format("DD/MM/YYYY"));
        };
        graphlabels = GRAPH_WEEKDAYS_LABELS;
      } else {
        endDate = moment().endOf(dateType).format('YYYY-MM-DD');
        var day = moment().format('D');
        for (var i = 0; i < Number(day); i++) {
          dates.push(moment(startDate).add(i, 'days').format("DD/MM/YYYY"));
          formatedDates.push(moment(startDate).add(i, 'days').format('DD'));
        };
        graphlabels = formatedDates;
      }

      const summaryStatsData = await API.getSummaryFromPG({ summary: statsSummary, startDate, endDate, id: connectedID });
      

      //get Clients-wise Data
      const nameArray = [];
      summaryStatsData?.rows?.map(test => {
        if (!(nameArray.includes(test.name))) {
          nameArray.push(test.name);
        }

      });

      let seriesDataArr = [];
      nameArray.sort().forEach((nameElement) => {
        let tempSeriesArray = [];
        dates.forEach((dateElement, index) => {
          tempSeriesArray.push(parseInt(summaryStatsData?.rows?.find((test) => test.date === dateElement && (test.name === nameElement))?.count) || 0);

        });
        seriesDataArr.push({ data: tempSeriesArray, datalabel: nameElement || '' });

      });

      let colorsArr = [];
      seriesDataArr?.map((data, index) => {
        colorsArr.push({ color: colors[index], label: data.datalabel });
      });

      setLegend(colorsArr);
      setGraphLabel(graphlabels);
      setSummaryResultData(seriesDataArr);
      localStorage.setItem(statsType + "Data" , JSON.stringify(seriesDataArr));
      if(orderedClientNamesData){
        orderedClientNamesData(nameArray);
      } else if(orderedLabNamesData){
        orderedLabNamesData(nameArray);
      } else if(orderedSiteNamesData){
        orderedSiteNamesData(nameArray);
      }
    


    } catch (err) {
      console.log("Error ", err);
    }

  };

  useEffect(async () => {
    loadGraphStats(statsType);
  }, [dateType]);

  const handleDatewiseData = () => {
    //setCurrentValue(value);
    if(isWeeklyData){
      setDateType("month");
    } else {
      setDateType("isoWeek");
    }
    setIsWeeklyData(!isWeeklyData)
  };

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        display: false,
      },
      title: {
        display: false,
        text: SUMMARY_STATS_TYPE[statsType],

      },
      legend: {
        display: false,
        labels: {
          usePointStyle: true,
          boxWidth: 8,

        }
      },
    },
  };



  const data = {
    labels,
    datasets: summaryResultData.map((data, index) => {
      return {
        label: data.datalabel || '',
        data: data.data,
        borderColor: colors[index],
        backgroundColor: colors[index],
        borderWidth: 1,
      }

    }),
  };


  return (
    <>
      <div id={statsType} name={statsType} className="position-absolute right-3 top-1 fs-7 d-flex">
        <a className="mb-0 cursor-pointer fs-7 text-black text-decoration-none bg-white" id={statsType} name={statsType} onClick={() => handleDatewiseData()}>Switch to {isWeeklyData ? 'Month' : 'Week'} </a>
      </div>
      <Line options={options} data={data} className="mt-3" />

      <div className="d-flex justify-content-start mt-3 px-2">
        <p className="mb-1 text-center pe-2 stats-title">{statsTitle}</p>
        <div className="stats-title-border d-flex align-items-center flex-wrap">
            {
              legendcolor.map((element) => {
                let color = element.color;
                return <span className="row" title={element.label} style={{
                  margin: "5px",
                  height: "15px",
                  width: "15px",
                  backgroundColor: color,
                  borderRadius: "50%",
                  display: "inline-block",
                  alignContent: 'center',

                }} value={element.label} >

            </span>
              })
            }
        </div>
      </div>
      
    </>
  );
};

export default DashboardLineChart;

