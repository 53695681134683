import { createAsyncThunk } from "@reduxjs/toolkit";
import { toTitleCase } from "utils";
import CONSTANTS from "./generalConstant";
import { showMessage } from "./generalSlice";

const { SHOW_CONFIRM_MODAL } = CONSTANTS;

export const setMessage =
  (text = "", mode = "", title= "") =>
  (dispatch) => {
    dispatch(showMessage({ text : toTitleCase(text), mode, title }));
  };

export const showConfirmationModal = createAsyncThunk(SHOW_CONFIRM_MODAL, (confirmationModalData) => {
  return confirmationModalData;
});
