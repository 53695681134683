import React from "react";

const DiagnosisAccordionButton = ({ children, isActive, onClick }) => {
  return (
    <button
      type="button"
      onClick={onClick}
      style={{
        backgroundColor: "transparent",
        border: "none",
        boxShadow: "none",
        display: "flex",
        flexDirection: "column",
      }}
      aria-expanded={isActive}
      className="px-0"
    >
      <p style={{ lineHeight: 1, fontSize:"18px" }}>
        {isActive ? "-" : "+"} {children[0]}
      </p>
      <p style={{ lineHeight: 0, marginLeft:"15px" }}> {children[1]}</p>
    </button>
  );
};

export default DiagnosisAccordionButton;
