import api from "api";
import Loader from "components/Loader/Loader";
import ConfirmationModal from "components/Modal/ConfirmationModal";
import NotesModal from "components/Notes/NotesModal";
import OrderImportModal from "components/Modal/Order/OrderImportModal";
import PersonalizationModal from "components/Modal/personalizationModal";
import ProviderModal from "components/Modal/ProviderModal";
import MainTable from "components/Table/MainTable";
import UserPasswordModal from "components/Users/UserPasswordModal";
import { PROVIDER_PERSONALIZE } from "constants/personalization";
import { useEffect, useState, useContext, useMemo } from "react";
import { Card, Col, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { selectedCompanySetting } from "store/features/companySetting/companySettingSelectors";
import { setMessage } from "store/features/general/generalAction";
import { createProvider, deleteProvider, updateProvider } from "store/features/providers/providersActions";
import { newUserProviderSelector } from "store/features/providers/providersSelectors";
import { openCreatorProviderSelector } from "store/features/providers/providersSelectors";
import { selectedProvider } from "store/features/providers/providersSelectors";
import {
  setOpenCreatorProviderModal as setOpenCreator,
  setNewUserProvider as setNewUser,
  setFilteredProviders,
  fetchAllProviders,
} from "store/features/providers/providersSlice";
import "../../src/tableStyling.css";
import Filter from "../components/Filter";
import moment from "moment";
import Icon from "../components/Icon";
import {
  MESSAGE_MODES,
  NEW_PROVIDER_OBJECT,
  PAGE_LIMIT,
  PERSONALISATION_KEY,
  TABLE_QUICK_TOOLS,
  USER_ROLE_TITLE,
  PROVIDER_MANAGEMENT,
  PROVIDER_DROPDOWN_OPTIONS,
  TEST_SETTING_TYPES,
  TEST_CATEGORY_OPTIONS,
  TEST_SOURCE_OPTIONS,
  TESTS_UNITS_SET,
  CANCEL_REASON_OPTIONS
} from "../constant";
import { t } from "../stringConstants";
import {
  formatNumber,
  personalisationInLC,
  personalizationLocalStorage,
  userCompanyID,
  downloadDataAsCSV,
  draggablePersonalizationLocalStorage,
} from "../utils";
import { setLoader } from "store/features/general/generalSlice";
import { AppContext } from "context/app-context";
import { userGivenPermission } from "store/features/authentication/authenticationSelectors";

const Provider = (props) => {
  const [sortBy, setSortBy] = useState("");
  const [sortDescending, setSortDescending] = useState(true);
  const [openPasswordModal, setOpenPasswordModal] = useState(false);
  const [openDeleteConfirmation, setOpenDeleteConfirmation] = useState(false);
  const [userToDelete, setUserToDelete] = useState("");
  const [timeFilter, setTimeFilter] = useState("All Users");
  const [showFilter, setShowFilter] = useState(false);
  const [noteModal, setNoteModal] = useState(null);
  const [filter, setFilter] = useState({});
  const dispatch = useDispatch();
  const [openImportModal, setOpenImportModal] = useState(false);
  const [personalize, setPersonalize] = useState([]);
  const [openPersonalizationModal, setOpenPersonalizationModal] = useState(false);
  const [checkboxes, setCheckboxes] = useState([]);
  const appContext = useContext(AppContext);
  const user = appContext?.user;
  const company = useSelector(selectedCompanySetting);
  const permission = useSelector(userGivenPermission);

  const quickTools = [
    TABLE_QUICK_TOOLS.checkbox,
    permission[PROVIDER_MANAGEMENT]?.write && TABLE_QUICK_TOOLS.edit,
    permission[PROVIDER_MANAGEMENT]?.write && TABLE_QUICK_TOOLS.notes,
    // permission[PROVIDER_MANAGEMENT]?.delete && TABLE_QUICK_TOOLS.delete,
  ];

  const PROVIDER_DROPDOWN = useMemo(() => {
    const hasDeletePermission = permission[PROVIDER_MANAGEMENT]?.delete;
    const deleteOption = { value: "delete", title: "Archive" };

    const newDropdownOptions = PROVIDER_DROPDOWN_OPTIONS.filter((option) => option.value !== "delete");

    if (hasDeletePermission) {
      newDropdownOptions.push(deleteOption);
    }

    return newDropdownOptions;
  }, [permission[PROVIDER_MANAGEMENT]?.delete]);

  const openCreator = useSelector(openCreatorProviderSelector);
  const newUser = useSelector(newUserProviderSelector);
  const { providers, filteredProviders } = useSelector(selectedProvider);

  const searchTerms = ["Name", "NPI"];

  const [currentPage, setCurrentPage] = useState(1);
  const [usersPerPage, setUsersPerPage] = useState(PAGE_LIMIT);

  const sortUsers = (users, sortParam) => {
    if (sortDescending) {
      return [...users].sort((a, b) => (a[sortParam] < b[sortParam] ? 1 : b[sortParam] < a[sortParam] ? -1 : 0));
    } else {
      return [...users].sort((a, b) => (b[sortParam] < a[sortParam] ? 1 : a[sortParam] < b[sortParam] ? -1 : 0));
    }
  };

  const handleCheckboxChange = (e, row) => {
    const filteredList = checkboxes.filter((c) => c !== row.id);
    if (e.target.checked) {
      filteredList.push(row.id);
    }
    setCheckboxes(filteredList);
  };

  const nestedFilter = (targetArray, filters) => {
    if (Object.keys(filter).length === 0) return targetArray;
    const filterKeys = Object.keys(filters);
    //filters main array of objects
    const models = targetArray.filter((obj) => {
      //goes through each key being filtered for
      return filterKeys.every((key) => {
        if (!filters[key].length) {
          return true;
        }

        if (key === "First Name") {
          return obj.firstName && obj.firstName.toLowerCase().includes(filters[key].toLowerCase());
        }

        if (key === "Last Name") {
          return obj.lastName && obj.lastName.toLowerCase().includes(filters[key].toLowerCase());
        }
        if (key === "userRole") {
          return obj.userRole && USER_ROLE_TITLE[obj.userRole].toLowerCase().includes(filters[key].toLowerCase());
        }
        return obj[key] && obj[key].toLowerCase().includes(filters[key]);
      });
    });
    return models;
  };

  const createProviderInfo = async (user, defaultSetting) => {
    try {
      if (user.isNew) {
        dispatch(createProvider(user, defaultSetting));
        dispatch(setMessage(t("providerCreatedSuccessfully"), MESSAGE_MODES.success));
      } else {
        dispatch(updateProvider(user, defaultSetting));
        dispatch(setMessage(t("providerUpdatedSuccessfully"), MESSAGE_MODES.success));
      }
    } catch (error) {
      console.log("ERROR:User-", error);
      dispatch(setMessage(error.message, MESSAGE_MODES.error));
      dispatch(setOpenCreator(true));
    }
  };

  const handleClose = () => {
    dispatch(setOpenCreator(false));
    setOpenDeleteConfirmation(false);
  };

  const handlePasswordClose = () => {
    setOpenPasswordModal(false);
  };

  const handleChangePage = (number) => {
    setCurrentPage(number);
  };

  const indexOfLastUser = currentPage * usersPerPage;
  const indexOfFirstUser = indexOfLastUser - usersPerPage;
  const currentUsers = (users) => {
    if (!users) return [];
    return users.slice(indexOfFirstUser, indexOfLastUser);
  };

  let usersToMap = currentUsers(filteredProviders);

  const getPageNumbers = (users) => {
    const pageNumbers = users.length > PAGE_LIMIT ? Math.ceil(users.length / PAGE_LIMIT) : 1;
    return pageNumbers;
  };

  let pageNumbers = getPageNumbers(providers);

  useEffect(() => {
    if (filteredProviders.length > 0) dispatch(setFilteredProviders(sortUsers(filteredProviders, sortBy)));
  }, [sortBy, sortDescending]);

  useEffect(() => {
    if (providers.length > 0) {
      dispatch(setFilteredProviders(nestedFilter(providers, filter)));
      setCurrentPage(1);
    }
  }, [filter]);

  const getAllProviders = async () => {
    try {
      await dispatch(fetchAllProviders());
    } catch (err) {
      console.log("Error:-", err);
    }
  };

  useEffect(() => {
    getAllProviders();
    const response = draggablePersonalizationLocalStorage.get(user, PERSONALISATION_KEY.PROVIDER, PROVIDER_PERSONALIZE);
    setPersonalize(response);
  }, []);

  if (filteredProviders.length > 0) {
    pageNumbers = getPageNumbers(filteredProviders);
  }

  const flipSort = (by) => {
    setSortDescending(sortBy === by ? !sortDescending : true);
    setSortBy(by);
    handleChangePage(1);
  };

  const handleImport = async (providerData) => {
    setOpenImportModal(false);
    try {
      if (providerData.length === 0) return;
      dispatch(setLoader(true));
      const arr = [];
      const notNpiFound = [];
      const ttlLength = providerData.length;
      for (let index = 0; index < ttlLength; index++) {
        const { data } = providerData[index];
        const provider = { ...data };

        if (!provider.npi || !provider.npi.trim()) {
          notNpiFound.push(provider);
          continue;
        }

        if (providers.find((f) => f.npi === provider.npi)) {
          notNpiFound.push(provider);
          continue;
        }

        arr.push({
          ...provider,
          npi: provider.npi.replace(/[^A-Z0-9]/gi, ""),
          clientID: userCompanyID.get(),
          requestedBy: user.sub,
          requestedByName: user.name,
        });
      }

      const fileName = `provider/provider_import${moment().format("DD_MM_YYYY_HH_mm_ss.SSS")}.csv`;
      console.log("import Array", arr);
      await api.saveFileOnBucket(arr, fileName);
      dispatch(setLoader(false));
      if (notNpiFound.length > 0) {
        dispatch(
          setMessage(`${notNpiFound.length} Provider(s) is Invalid  Not Found NPI or Already Exist`, MESSAGE_MODES.info)
        );
      }
    } catch (err) {
      dispatch(setMessage(err, MESSAGE_MODES.error));
      dispatch(setLoader(false));
    }
  };

  const handlePersonalization = async (data) => {
    setPersonalize([...data]);
    setOpenPersonalizationModal(false);
    const personalisationData = personalisationInLC.saveAs(data, "provider");
    await api.saveUserPersonalisation(appContext.user.sub, personalisationData);
  };

  const handleOpenCreateModal = () => {
    const obj = NEW_PROVIDER_OBJECT;
    dispatch(setNewUser({ ...obj, isNew: true }));
    dispatch(setOpenCreator(true));
  };

  const handleEditUser = (user) => {
    const obj = {
      ...user,
      isNew: false,
    };
    dispatch(setNewUser(obj));
    dispatch(setOpenCreator(true));
  };

  const handleConfirm = async (isConfirm) => {
    setOpenDeleteConfirmation(false);

    if (!isConfirm) {
      setUserToDelete(null);
      return;
    }
    try {
      setUserToDelete(null);
      await dispatch(deleteProvider(userToDelete.id));
      dispatch(setMessage("Provider Archived Successfully", MESSAGE_MODES.success));
    } catch (error) {
      dispatch(setMessage(error.message, MESSAGE_MODES.error));
    }
  };

  const handleCellClick = (key, row, event) => {
    switch (key) {
      case TABLE_QUICK_TOOLS.checkbox:
        handleCheckboxChange(event, row);
        break;

      case TABLE_QUICK_TOOLS.edit:
        handleEditUser(row);
        break;

      case TABLE_QUICK_TOOLS.delete:
        setOpenDeleteConfirmation(true);
        setUserToDelete(row);
        break;

      case TABLE_QUICK_TOOLS.notes:
        setNoteModal(row);
        break;

      default:
        break;
    }
  };

  const customRenderTD = (item, row, index) => {
    if (item.itemKey === "phoneNumber") {
      return (
        <td key={`phone${item.id}`} style={{ textAlign: "center" }}>
          {row?.phoneNumber || ""}
        </td>
      );
    }
    if (item.itemKey === "orders") {
      return (
        <td key={`order_${item.id}`} style={{ textAlign: "center" }}>
          {row?.orders || "0"}
        </td>
      );
    }
  };

  const onHandleDownloadSample = async (type = 2) => {
    // if template info
    if (type === 1) {
      const data = [
        { "Filed Name": "npi", STATUS: "( required )", VALUE: "1093937708" },
        { "Filed Name": "code", STATUS: "( required )", VALUE: "1234" },
      ];

      await downloadDataAsCSV(data, "Import Provider Template Info");
    } else {
      const data = [
        { npi: "1093937708", code: 1234 },
        { npi: "3434341111", code: 1235 },
      ];

      await downloadDataAsCSV(data, "Import Provider Sample Info");
    }
  };

  const processDefaultDictionayData = async ({ list, type }) => {
    for (let i = 0; i < list.length; i++) {
      await api.createOption(type, list[i].label);
    }
  };

  const addDefaultData = () => {
    processDefaultDictionayData({ list: TESTS_UNITS_SET, type: TEST_SETTING_TYPES.UNIT });
    processDefaultDictionayData({ list: TEST_SOURCE_OPTIONS, type: TEST_SETTING_TYPES.SOURCE });
    processDefaultDictionayData({ list: TEST_CATEGORY_OPTIONS, type: TEST_SETTING_TYPES.DEPARTMENT });
    processDefaultDictionayData({ list: CANCEL_REASON_OPTIONS, type: TEST_SETTING_TYPES.CANCEL_REASON });
    
  };

  return (
    <div style={{ flex: 1 }}>
      <Row>
        <Col>
          <Card
            className={`strpied-tabled-with-hover ${
              usersToMap?.length === 1 || usersToMap?.length === 2 ? "test-details-table" : ""
            }`}
          >
            <Card.Header>
              <Card.Title
                as="h4"
                style={{
                  marginBottom: 10,
                  fontWeight: "bold",
                }}
              >
                {t("provider")} ({filteredProviders ? formatNumber(filteredProviders.length) : providers.length})
              </Card.Title>
              <div className="buttonHeader">
                <Icon
                  handleClick={() => setShowFilter(!showFilter)}
                  title={"Filter"}
                  label={"Filter"}
                  iconType={"filter"}
                />

                <Icon
                  handleClick={() => setOpenPersonalizationModal(true)}
                  title={t("personalize")}
                  label={t("personalize")}
                  iconType={"personalizeIcon"}
                />
                {permission[PROVIDER_MANAGEMENT]?.write && (
                  <Icon
                    handleClick={() => handleOpenCreateModal()}
                    title={"Create Provider"}
                    label={"Create"}
                    iconType={"createIcon"}
                  />
                )}
                {permission[PROVIDER_MANAGEMENT]?.write && (
                  <Icon
                    handleClick={() => {
                      setOpenImportModal(true);
                    }}
                    title={t("loadProviderData")}
                    iconType="loadCrewIcon"
                    label={t("loadClient")}
                  />
                )}
              </div>

              {showFilter && (
                <Filter filterTerms={searchTerms} setFilter={setFilter} filter={filter} isUsersTable={true} />
              )}

              <Row className="pull-right" style={{ borderWidth: 1 }}>
                <Col></Col>
                <Col style={{ textAlign: "right" }} md={{ span: 3, offset: 1 }} xl={{ span: 3, offset: 1 }}>
                  Page
                  <ul className="createButtons pr-0" style={{ display: "inline" }}>
                    <i
                      className="fas fa-angle-left"
                      style={{ color: "#585858" }}
                      onMouseOver={(e) => {
                        e.target.style.cursor = "pointer";
                        e.target.style.color = "black";
                      }}
                      onMouseLeave={(e) => (e.target.style.color = "#585858")}
                      onClick={() => {
                        currentPage !== 1 && handleChangePage(currentPage - 1);
                      }}
                    />
                    {/* {currentPage !== 1 && "..."} */}
                    {currentPage} of {pageNumbers}
                    {/* {pageNumbers.length >= currentPage && "..."} */}
                    <i
                      className="fas fa-angle-right"
                      style={{ color: "#585858" }}
                      onMouseOver={(e) => {
                        e.target.style.cursor = "pointer";
                        e.target.style.color = "black";
                      }}
                      onMouseLeave={(e) => (e.target.style.color = "#585858")}
                      onClick={() => {
                        currentPage !== pageNumbers && handleChangePage(currentPage + 1);
                      }}
                    />
                  </ul>
                </Col>
              </Row>
            </Card.Header>
            <Card.Body className="table-full-width desktop-noScroll">
              <MainTable
                columns={personalize}
                rows={usersToMap}
                flipSort={flipSort}
                sortBy={sortBy}
                sortDescending={sortDescending}
                widthToSkip={160}
                draggable
                resizable
                personalisationKey={PERSONALISATION_KEY.PROVIDER}
                handleSaveDragAndResize={(personalizeArr) => setPersonalize(personalizeArr)}
                selectedRows={checkboxes}
                tools={quickTools}
                handleCellClick={handleCellClick}
                customColumnCellRenderer={customRenderTD}
                dropDownOptions={PROVIDER_DROPDOWN}
                handleDropDownClick={(type, row) => {
                  if (type === "Audit Trail") {
                    appContext.showLogs({ ...row, recordType: "logs", title: "Provider Logs" });
                  }
                  if (type === "Archive") {
                    setOpenDeleteConfirmation(true);
                    setUserToDelete(row);
                  }
                }}
              />
            </Card.Body>
          </Card>
        </Col>
      </Row>
      
      {openCreator && (
        <ProviderModal
          show={openCreator}
          user={newUser}
          handleClose={handleClose}
          handleSave={createProviderInfo}
          providers={providers}
          setting={{}}
        />
      )}
      {openPasswordModal && (
        <UserPasswordModal user={newUser} handleClose={handlePasswordClose} handleSave={handleResetPassword} />
      )}
      {openPersonalizationModal && (
        <PersonalizationModal
          data={personalize}
          handleChange={handlePersonalization}
          show={openPersonalizationModal}
          handleClose={() => setOpenPersonalizationModal(false)}
        />
      )}
      {noteModal && (
        <NotesModal
          handleClose={(obj) => {
            setNoteModal(null);
          }}
          provider
          user={user}
          data={noteModal}
        />
      )}
      {openImportModal && (
        <OrderImportModal
          handleClose={() => setOpenImportModal(false)}
          handleImport={handleImport}
          title="Import Provider Data"
          noSelectDate={true}
          handleDownloadSample={() => onHandleDownloadSample()}
        />
      )}
      {openDeleteConfirmation && (
        <ConfirmationModal
          show={openDeleteConfirmation}
          title="Archive Provider"
          message="Are you sure, you want to Archive Provider?"
          handleConfirm={handleConfirm}
        />
      )}
    </div>
  );
};

export default Provider;
