import { createRef, useEffect, useRef, useState } from "react";
import PhoneNoInput from "components/Phone/PhoneNoInput";

const SendOTPUi = ({ handleSendCode, setPhone, phone }) => {
  const [disableOTPbtn, setDisableOTPBtn] = useState(true);

  useEffect(() => {
    if (phone && phone.length > 6) {
      setDisableOTPBtn(false);
    } else {
      setDisableOTPBtn(true);
    }
  }, [phone]);

  return (
    <div className="login-right-side max-w-100">
      <h3 className="w-100 text-center mt-2">
        Forgot Your Password?
      </h3>
      <span className="tag-line mt-4 text-center d-block">Enter the phone number associated with your account and we will send a code to your email address to reset your password.</span>
      <div className="login-inner-right-side mt-4">
        <label className="login-input-label mb-2">Phone Number</label>
        <div className="form-group phoneInput">
          <PhoneNoInput handleChange={(e) => setPhone(e)} value={phone} />
        </div>
        <div className="mt-4">
        <button
          type="submit"
          disabled={disableOTPbtn}
          onKeyDown={() => handleSendCode()}
          className="text-uppercase btn signin-btn"
          onClick={() => handleSendCode()}
        >
          Send OTP
        </button>
      </div>
      </div>

      
    </div>
  );
};

export default SendOTPUi;
