import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { PROCESS_PAYMENT } from "./processPaymentConstant";

const processPaymentSlice = createSlice({
  name: PROCESS_PAYMENT,
  initialState: {
    alreadyPostedClaim: [],
    openPaymentProcess: false,
    claimsIDs: [],
  },
  reducers: {
    setAlreadyPostedClaim: (state, action) => {
      state.alreadyPostedClaim = action.payload;
    },
    setOpenPaymentProcess: (state, action) => {
      state.openPaymentProcess = action.payload;
    },
    setClaimsIDs: (state, action) => {
      state.claimsIDs = action.payload;
    },
  },
});

export const { setAlreadyPostedClaim, setOpenPaymentProcess, setClaimsIDs } = processPaymentSlice.actions;
export default processPaymentSlice.reducer;
