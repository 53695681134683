import React, { useRef, useEffect, useState } from "react";
import logoImage from "assets/img/safecamp_BRAND.1-nobg.png";
import html2canvas from "html2canvas";
import QRCode from "qrcode.react";
import Default_Profile from "assets/img/default_profile.png";
import { Storage } from "aws-amplify";
import { Button } from "react-bootstrap";
import Loader from "components/Loader/Loader";
import API from "api";
import QrCodeDownloadIcon from "../assets/img/qr-download.svg";
import { qrResponseJOSN } from "utils";

const QRLinkView = (props) => {
  const [testId, setTestId] = useState("");
  const [phone, setPhone] = useState("");
  const [newUser, setNewUser] = useState({});
  const [qrRes, setQrRes] = useState("");
  const [profileImage, setProfileImage] = useState(Default_Profile);
  const [loading, setLoading] = useState(false);
  console.log("props", props);
  useEffect(async () => {
    if (props) {
      setLoading(true);
      const {
        match: { params },
      } = props;
      const arr = params.testId.split("&phone=");
      console.log("arr", arr);
      setTestId(arr[0]);
      setPhone(arr[1]);
      await getFileFromStorage(`${arr[1]}-profile-image.png`);
    }
  }, [props]);
  console.log("newUser", newUser);
  useEffect(async () => {
    if (testId && phone) {
      setLoading(true);
      try {
        const respose = await API.getPRRecordById(testId, phone);
        console.log("testId", respose);
        setNewUser(respose);
        setQrRes(qrResponseJOSN(respose, respose.id, "PR"));
        setLoading(false);
      } catch (err) {
        console.log(err.message);
        setLoading(false);
      }
    }
  }, [testId, phone]);
  console.log("profile Image", profileImage);
  const getFileFromStorage = async (file) => {
    try {
      const path = await Storage.get(file);
      setProfileImage(path);
      console.log("path", path);
    } catch {
      console.log("Image loading fail");
    }
  };

  const downloadRef = useRef();
  const downloadQRCode = async () => {
    const element = downloadRef.current;
    await html2canvas(element, { allowTaint: true, useCORS: true, logging: true }).then((canvas) => {
      const imgData = canvas.toDataURL("image/png");
      const link = document.createElement("a");
      if (typeof link.download === "string") {
        link.href = imgData;
        link.download = "image.jpg";
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      } else {
        window.open(imgData);
      }
    });
  };

  console.log("res", qrRes);

  return (
    <>
      {!loading ? (
        <div className="codeScannerWrapper">
          <div className="registered-successful">
            <p className="mb-0">Please present this QR code at Event Check In</p>
            <div className="registered-inner" ref={downloadRef}>
              <h4 className="my-0 card-name">{`${newUser?.first} ${newUser?.last}`}</h4>
              <div className="justify-content-center align-items-center">
                <div className="qrWrapper">
                  <img
                    crossOrigin="true"
                    src={profileImage instanceof Object ? URL.createObjectURL(profileImage) : profileImage}
                    onError={(event) => {
                      event.target.src = Default_Profile;
                    }}
                    alt="Profile"
                    className="userIcon"
                  />
                  <QRCode
                    className="qrCode"
                    id="qr-gen"
                    value={JSON.stringify(qrRes)}
                    size={270}
                    level={"L"}
                    fgColor="#008000"
                    includeMargin={true}
                  />
                </div>
              </div>
            </div>
            <div className="d-flex align-items-center justify-content-between">
              {/* <Button
                className="w-50 mx-auto rounded-pill btn btn-secondary"
                variant="secondary"
                onClick={downloadQRCode}
              >
                {" "}
                Download QR Code
              </Button> */}
              <img src={QrCodeDownloadIcon} onClick={downloadQRCode} alt="qr-code-download" width="25" />
              {/* <Button
            className="w-25 me-2 rounded-pill btn btn-secondary"
            href={`safecampmd://app/${newUser.first || ""}/${newUser.last || ""}/${newUser.email || ""}/${
              newUser.phone_number || ""
            }`}
            variant="secondary"
          >
            Add to Wallet
          </Button> */}
            </div>
          </div>
        </div>
      ) : (
        <Loader />
      )}
    </>
  );
};

export default QRLinkView;
