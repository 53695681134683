import SwitchToggle from "components/SwitchToggle/SwitchToggle";
import { CLI_ACTIONS } from "constant";
import { useState } from "react";
import { Button, Form, Modal } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { t } from "stringConstants";
import { createCPTCodeAsync, updateCPTCodeAsync } from "../../store/features/cptCodes/cptCodeSlice";
import { RemoveSpaceAndChar, formatCurrency, parseCurrency, parseStringIntoFloat } from "../../utils";
import InputField from "components/InputField/InputField";
import moment from "moment";
import CurrencyInput from "components/CurrencyInput";

const AddProcedureCodeSettingsModal = (props) => {
  const { handleClose, selectedItem, handleSave } = props;
  const [newItem, setNewItem] = useState(
    selectedItem ? { ...selectedItem, action: CLI_ACTIONS.update } : { action: CLI_ACTIONS.add }
  );
  const [error, setError] = useState("");
  const dispatch = useDispatch();

  const onHandleSave = async (obj) => {
    if (!obj.code || !obj.code.trim()) {
      setError("Please fill Procedure Code field!");
      return;
    }
    if (!obj.description || !obj.description.trim()) {
      setError("CPT code title is required");
      return;
    }

    if (!obj.charges || !obj.charges.toString().trim()) {
      setError("Charge is required");
      return;
    }

    if (!obj.internalCode || !obj.internalCode.toString().trim()) {
      Object.assign(obj, { internalCode: obj.code });
    }

    try {
      if (newItem.action === CLI_ACTIONS.add) {
        dispatch(createCPTCodeAsync(obj));
      } else if (newItem.action === CLI_ACTIONS.update) {
        dispatch(updateCPTCodeAsync(obj));
      }
      handleClose();
    } catch (err) {
      console.log("Error in CPT Modal", err);
    }
  };

  return (
    <>
      <Modal show backdrop="static" animation={true} onHide={() => handleClose()} centered size={"xl"}>
        <Modal.Header closeButton>
          <Modal.Title className="my-0" id="contained-modal-title-vcenter">
            {newItem.action === CLI_ACTIONS.add ? `${t("createCPT")}` : `${t("updateCPT")}`}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ paddingTop: 0 }}>
          <div style={{ display: "flex", gap: "16px" }}>
            <div style={{ flex: 1 }}>
              <div className="w-100 createClientsWrapper">
                <Form.Group className="form-group-wrapper w-100">
                  <Form.Label className="mt-0 text-capitalize">Procedure Code:</Form.Label>
                  <Form.Control
                    className={`modalInput`}
                    minLength={5}
                    onChange={(e) => setNewItem({ ...newItem, code: RemoveSpaceAndChar(e.target.value).toUpperCase() })}
                    value={newItem?.code}
                    placeholder="Enter Procedure Code"
                    type="text"
                  />
                </Form.Group>
              </div>
              <div className="w-100 createClientsWrapper">
                <Form.Group className="form-group-wrapper w-100">
                  <Form.Label className="mt-0 text-capitalize">Alias:</Form.Label>
                  <Form.Control
                    className={`modalInput`}
                    minLength={5}
                    onChange={(e) => setNewItem({ ...newItem, internalCode: e.target.value.toUpperCase() })}
                    value={newItem?.internalCode}
                    placeholder="Enter Alias"
                    type="text"
                  />
                </Form.Group>
              </div>
              <div className="w-100 createClientsWrapper">
                <Form.Group className="form-group-wrapper w-100">
                  <Form.Label className="mt-0 text-capitalize">Description</Form.Label>
                  <Form.Control
                    className={`modalInput`}
                    maxLength={65}
                    onChange={(e) => setNewItem({ ...newItem, description: e.target.value })}
                    value={newItem?.description}
                    placeholder="Enter CPT Description"
                    type="text"
                  />
                </Form.Group>
              </div>

              <div className="w-100 createClientsWrapper">
                <Form.Group className=" w-100">
                  <InputField
                    type="datePicker"
                    inputStyle="w-100 orderTestDob"
                    labelStyle="modalLineHeaders mt-0 text-capitalize"
                    label="Retired Date"
                    value={
                      newItem?.retiredDate && new Date(newItem?.retiredDate) != "Invalid Date"
                        ? moment(newItem?.retiredDate, "YYYY-MM-DD").toDate()
                        : null
                    }
                    isFutureDateAllowed
                    placeholder="Retired Date"
                    handleChange={(e) =>
                      setNewItem({
                        ...newItem,
                        retiredDate: moment(e).format("YYYY-MM-DD") != "Invalid date" ? moment(e).toISOString() : "",
                      })
                    }
                  />
                </Form.Group>
              </div>

              <div className="w-100 createClientsWrapper">
                <Form.Group className="w-100">
                  <InputField
                    isFutureDateAllowed
                    type="datePicker"
                    inputStyle="w-100 orderTestDob"
                    labelStyle="modalLineHeaders mt-0 text-capitalize"
                    label="Effective Date"
                    value={
                      newItem?.effectiveDate && new Date(newItem?.effectiveDate) != "Invalid Date"
                        ? moment(newItem?.effectiveDate, "YYYY-MM-DD").toDate()
                        : null
                    }
                    placeholder="Effective Date"
                    handleChange={(e) =>
                      setNewItem({
                        ...newItem,
                        effectiveDate: moment(e).format("YYYY-MM-DD") != "Invalid date" ? moment(e).toISOString() : "",
                      })
                    }
                  />
                </Form.Group>
              </div>
            </div>

            <div style={{ flex: 1 }}>
              {/* 2nd column */}

              <div className="w-100 createClientsWrapper">
                <CurrencyInput
                  value={newItem.charges}
                  onChange={(e) => {
                    setNewItem({ ...newItem, charges: e });
                  }}
                  label="Internal Charges"
                  placeholder="Enter Internal Charges"
                />
              </div>

              <div className="w-100 createClientsWrapper">
                <CurrencyInput
                  value={newItem.referenceCharge}
                  onChange={(e) => {
                    setNewItem({ ...newItem, referenceCharge: e });
                  }}
                  label="Reference Charge"
                  placeholder="Enter Reference Charge"
                />
              </div>
              <div className="w-100 createClientsWrapper">
                <CurrencyInput
                  value={newItem.cashCharge}
                  onChange={(e) => {
                    setNewItem({ ...newItem, cashCharge: e });
                  }}
                  label="Cash Charge"
                  placeholder="Enter Cash Charge"
                />
              </div>
              <div className="w-100 createClientsWrapper">
                <CurrencyInput
                  value={newItem.inNeworkCharge}
                  onChange={(e) => {
                    setNewItem({ ...newItem, inNeworkCharge: e });
                  }}
                  label="In Network Charge"
                  placeholder="Enter In Network Charge"
                />
              </div>
              <div className="w-100 createClientsWrapper">
                <CurrencyInput
                  value={newItem.outNetworkCharge}
                  onChange={(e) => {
                    setNewItem({ ...newItem, outNetworkCharge: e });
                  }}
                  label="Out Network Charge"
                  placeholder="Enter Out Network Charge"
                />
              </div>
            </div>
          </div>

          <div
            className="d-flex align-items-center column-gap-10 profile-
                          detail mb-3"
          >
            <span className="section-title">Active: </span>
            <SwitchToggle
              checked={newItem.isActive}
              handleToogleChange={(e) =>
                setNewItem({
                  ...newItem,
                  isActive: e.target.checked,
                })
              }
            />
          </div>
        </Modal.Body>
        <div className="px-4 my-2" style={{ height: "2vh" }}>
          {error && <strong style={{ color: "Red" }}>{error}</strong>}
        </div>
        <Modal.Footer>
          <Button
            style={{ marginBottom: 10 }}
            variant="primary"
            className="modalButtons headerButton btn-fill"
            onClick={() => {
              handleClose();
            }}
          >
            Close
          </Button>
          <Button
            style={{ marginBottom: 10 }}
            variant="secondary"
            className="modalButtons headerButton btn-fill"
            onClick={() => onHandleSave(newItem)}
          >
            {newItem.action === CLI_ACTIONS.add ? "Create" : "Update"}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default AddProcedureCodeSettingsModal;
