import React from "react";
import { Modal, Button } from "react-bootstrap";
import { formatPhoneNumber } from "utils";

const ContactInfo = (props) => {
  const { show, moreInfo, handleClose } = props;

  return (
    <Modal
      show={show}
      // style={{ transform: "translate(0, -30%)" }}
      animation={true}
      onHide={() => handleClose()}
      centered
      size={"md"}
    >
      <Modal.Header closeButton>
        <Modal.Title className="my-0" id="contained-modal-title-vcenter">
          {moreInfo.name}'s Contact Info
        </Modal.Title>
      </Modal.Header>

      <Modal.Body style={{ paddingTop: 0, marginRight: "auto", marginLeft: "auto" }}>
        <p>Contact Name: {moreInfo.contact}</p>
        <p>
          Contact Phone: <a href={`tel:${moreInfo.phone}`}>{formatPhoneNumber(moreInfo.phone, "", true)}</a>
        </p>
        <p>
          Contact Email: <a href={`mailto:${moreInfo.email}`}>{moreInfo.email}</a>
        </p>
      </Modal.Body>

      <Modal.Footer>
        <Button variant="secondary" onClick={() => handleClose()}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default ContactInfo;
