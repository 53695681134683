import { createSlice } from "@reduxjs/toolkit";
import {
  createNewMessageTemplate,
  deleteMessageTemplate,
  fetchMessageTemplateLists,
  updateMessageTemplate,
} from "./messageTemplateAction";
import { MESSAGE_TEMPLATE } from "./messageTemplateConstant";

const generalSlice = createSlice({
  name: MESSAGE_TEMPLATE,
  initialState: {
    messageTemplates: [],
    selectedMessageTemplate: null,
    showModal: false,
    loader: false,
    error: null,
  },
  reducers: {
    setSelectedMessageTemplate: (state, action) => {
      state.selectedMessageTemplate = action.payload || null;
    },
    setShowMessageTemplateModal: (state, action) => {
      state.showModal = !!action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchMessageTemplateLists.fulfilled, (state, action) => {
      state.messageTemplates = action.payload || [];
    });
    builder.addCase(createNewMessageTemplate.fulfilled, (state, action) => {
      state.messageTemplates = [...state.messageTemplates, action.payload];
    });
    builder.addCase(updateMessageTemplate.fulfilled, (state, action) => {
      const updatedTemplate = action.payload.updatedTemplate || null;
      const prevTemplateId = action.payload.prevTemplateId || null;
      if (updatedTemplate) {
        state.messageTemplates = state.messageTemplates.map((messageTemplate) =>
          messageTemplate.id === prevTemplateId ? updatedTemplate : messageTemplate
        );
      }
    });
    builder.addCase(deleteMessageTemplate.fulfilled, (state, action) => {
      const deletedTemplateId = action.payload || null;
      if (deletedTemplateId) {
        state.messageTemplates = state.messageTemplates.filter(
          (messageTemplate) => messageTemplate.id !== deletedTemplateId
        );
      }
    });
  },
});

export const { setLoader, setError, setSelectedMessageTemplate, setShowMessageTemplateModal } = generalSlice.actions;

export default generalSlice.reducer;
