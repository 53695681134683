import api from "api";
import Loader from "components/Loader/Loader";
import MainTable from "components/Table/MainTable";
import { RESULT_SETTINGS_OPT_QUALITATIVE, CONFIG, UN_RESULT } from "constant";
import { SHOW_TEST_PERSONALIZE } from "constants/personalization";
import { AppContext } from "context/app-context";
import React, { useEffect, useState, useCallback, useRef, memo } from "react";
import { useContext } from "react";
import { Button, Modal } from "react-bootstrap";
import Select from "react-select";
import { customIsJsonString } from "util/customLodash";
import { customIsObject } from "util/customLodash";
import { generateRefInterval } from "utils";
import { parseTestUnit } from "utils";
import { parseTestResult, sortByAlphaOrder } from "utils";

const TestResultInput = memo(({ row, onUpdateTestResult }) => {
  const inputRef = useRef(null);

  const handleBlur = (event) => {
    onUpdateTestResult(event.target.value, row.id);
  };
  return (
    <td>
      {row?.possibleValues && row?.possibleValues === "Qualitative" ? (
        <Select
          className="w-100 siteSelector"
          options={RESULT_SETTINGS_OPT_QUALITATIVE}
          // options={resultTypes || RESULT_SETTINGS_OPT_QUALITATIVE}
          blurInputOnSelect={true}
          defaultValue={null}
          menuPlacement="auto"
          value={customIsJsonString(row?.testResult) && JSON.parse(row?.testResult)}
          placeholder="Select Result"
          onChange={(e) => onUpdateTestResult(e, row.id)}
        />
      ) : (
        <input
          className="w-100"
          ref={inputRef}
          defaultValue={row?.testResult || ""}
          onBlur={handleBlur}
          onFocus={() => inputRef.current && inputRef.current.select()}
          key={row?.id}
        />
      )}
    </td>
  );
});

const ResultMarkInput = memo(({ row, onUpdateResultMark, disabled }) => {
  const inputRef = useRef(null);

  const handleBlur = (event) => {
    onUpdateResultMark(event.target.value, row.id);
  };

  return (
    <td>
      <input
        className="w-100 modalInput"
        ref={inputRef}
        defaultValue={row?.resultValue || ""}
        onBlur={handleBlur}
        onFocus={() => inputRef.current && inputRef.current.select()}
        key={row?.id}
        disabled={disabled}
        type="number"
        // placeholder="CT Value"
      />
    </td>
  );
});

const AssignResultsTestModal = ({ handleClose, selectedTest, testTypeResult }) => {
  const [resultsData, setResultsData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [resultTypes, setResultTypes] = useState([]);
  const [personalisation, setPersonalization] = useState(SHOW_TEST_PERSONALIZE);

  const updatePersonalization = () => {
    const testType = selectedTest?.typeOfTest || "";

    if (testType === "Quantitative") {
      const updatedPersonalization = SHOW_TEST_PERSONALIZE.filter((item) => item.itemKey !== "testResultMarks");

      const updatedPersonalizationWithCtScore = updatedPersonalization.map((item) => {
        if (item.itemKey === "testResult") {
          return { ...item, title: "Ct Value" };
        }
        return item;
      });
      setPersonalization(updatedPersonalizationWithCtScore);
      return;
    }
    setPersonalization(SHOW_TEST_PERSONALIZE);
    return;
  };

  useEffect(() => {
    updatePersonalization();
  }, [selectedTest]);

  const appContext = useContext(AppContext);

  const getResultTypes = () => {
    if (appContext?.clients?.length > 0) {
      const client = appContext.clients.find((f) => f.id === CONFIG.clientID);
      if (client && client.resultTypes) {
        const results = sortByAlphaOrder(
          client.resultTypes.results.map((m) => ({ value: m, label: m })),
          "label"
        );
        // if (processed) {
        //   results.unshift({ value: UN_RESULT, label: UN_RESULT });
        // }

        setResultTypes(results);
      }
    }
  };

  useEffect(() => {
    getResultTypes();
  }, [appContext.clients]);

  const compareTestResults = (array1, array2) => {
    if (!array1 || !array2) return true;
    return (
      array1.length !== array2.length ||
      array1.some(
        (item, index) =>
          item?.testResult !== array2[index]?.testResult || item?.resultValue !== array2[index]?.resultValue
      )
    );
  };

  const formatSelectedTestResultDetails = (selectedTest) => {
    const resDetail = selectedTest.resultDetails;

    return resDetail.map((item) => {
      const refInterval = generateRefInterval({ ranges: item?.ranges?.[0] ?? null });
      return {
        ...item,
        refInterval,
      };
    });
  };

  useEffect(() => {
    const resultRows = selectedTest?.resultDetails ? formatSelectedTestResultDetails(selectedTest) : testTypeResult;
    // const resultRows = selectedTest?.resultDetails ? selectedTest?.resultDetails : testTypeResult;

    setResultsData(resultRows.filter(Boolean));
  }, [testTypeResult, selectedTest]);

  const handleUpdateTestResult = useCallback((value, id) => {
    setResultsData((currentResultsData) => {
      const index = currentResultsData.findIndex((item) => item.id === id);
      if (index !== -1) {
        const newData = [...currentResultsData];
        const resultTest = parseTestResult(newData[index].testResult);

        newData[index] = {
          ...newData[index],
          resultValue: value.value === "Detected" ? newData[index]?.resultValue : "",
          testResult: customIsObject(value) ? JSON.stringify(value) : value,
        };

        return newData;
      }
      return currentResultsData;
    });
  }, []);

  const handleUpdateResultMark = useCallback((value, id) => {
    setResultsData((currentResultsData) => {
      const index = currentResultsData.findIndex((item) => item.id === id);
      if (index !== -1) {
        const newData = [...currentResultsData];
        newData[index] = { ...newData[index], resultValue: value };
        return newData;
      }
      return currentResultsData;
    });
  }, []);

  const handleSave = async () => {
    setIsLoading(true);
    try {
      const isEdit = compareTestResults(resultsData, selectedTest.resultDetails);
      if (isEdit) {
        const updatedObj = { id: selectedTest.id, resultDetails: resultsData };
        await api.assingTestResult(updatedObj);
      }
      handleClose();
      appContext.showSuccessMessage(`${selectedTest.name} result marked.`);
    } catch (error) {
      console.log("Error", error);
      appContext.showErrorMessage(`Error in ${selectedTest.name} result.`);
    } finally {
      setIsLoading(false);
    }
  };

  const customRenderTD = (item, row, index) => {
    if (item.itemKey === "testResult") {
      return <TestResultInput row={row} onUpdateTestResult={handleUpdateTestResult} resultTypes={resultTypes} />;
    }
    if (item.itemKey === "unit") {
      return (
        <td key={`unit_${item.id}`} style={{ textAlign: "center" }}>
          {parseTestUnit(row.units)}
        </td>
      );
    }
    if (item.itemKey === "testResultMarks") {
      const resultTest = parseTestResult(row.testResult);

      return (
        <ResultMarkInput row={row} onUpdateResultMark={handleUpdateResultMark} disabled={resultTest !== "Detected"} />
      );

      return <td key={`resultTest_${item.id}`}></td>;
    }
  };

  return (
    <Modal
      show
      animation={true}
      onHide={() => {
        handleClose();
      }}
      style={{ paddingLeft: "0" }}
      centered
      size="2xl"
    >
      <Modal.Header closeButton>
        <Modal.Title className="my-0" id="contained-modal-title-vcenter">
          Result for: {selectedTest.test_type}-{selectedTest.barcode}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body
        style={{
          paddingTop: 0,
        }}
      >
        {isLoading && <Loader />}

        <div style={{ background: "#f2f0f0", paddingLeft: "4px", paddingRight: "4px" }}>
          <MainTable
            cssClass="table-noresponsive"
            trDataWrapperHeader={"trDataWrapperHeader"}
            customColumnCellRenderer={customRenderTD}
            columns={personalisation}
            rows={resultsData}
          />
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button
          style={{ marginBottom: 10 }}
          variant="secondary"
          className="modalButtons"
          onClick={() => {
            handleClose();
          }}
        >
          Close
        </Button>
        <Button style={{ marginBottom: 10 }} variant="primary" className="modalButtons" onClick={handleSave}>
          Save
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default AssignResultsTestModal;
