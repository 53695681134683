import React from "react";
import { Document, Page, Image, StyleSheet, Text, View } from "@react-pdf/renderer";
import moment from "moment";
import { formatPhoneNumber, getOrderDetail } from "utils";
import { PRE_REGISTRATION_STATUS } from "constant";
import { parseBooleanValue } from "utils";
import { stringConstant } from "constant";

const AllTestPDF = (props) => {
  const { tests, title, filter, sortBy, timeFilter } = props;
  console.log("sortBy", sortBy);

  const colLength = Object.keys(tests[0]).length;
  const rowLength = Object.keys(tests).length;
  const filters = {
    id: { data: filter.id, label: "id" },
    first: { data: filter.first, label: "First Name" },
    last: { data: filter.last, label: "Last Name" },
    firstName: { data: filter.firstName, label: "First Name" },
    lastName: { data: filter.lastName, label: "Last Name" },
    email: { data: filter.email, label: "Email" },
    dob: { data: filter.dob, label: "DateOfBirth" },
    phoneNumber: { data: filter.phoneNumber, label: "Phone" },
    clientID: { data: filter.Client, label: "Client" },
    siteID: { data: filter.Show, label: "Show" },
    site_name: { data: filter.site_name, label: "Show" },
    labID: { data: filter.Lab, label: "Lab" },
    status: { data: filter.status, label: "Status" },
    result: { data: filter.result, label: "Result" },
    test_type: { data: filter.test_type, label: "Test Type" },
    reason: { data: filter.reason, label: "Reason" },
    sequenceNo: { data: filter.sequenceNo, label: stringConstant.sequenceNo },
    tester_name: { data: filter.tester_name, label: "Tester" },
    emailSend: { data: filter.emailSend, label: "Result Sent" },
    updatedAt: { data: undefined, label: "Date Processed" },
    createdAt: { data: undefined, label: "Date Collected" },
    resultDate: { data: undefined, label: "Date Processed" },
    batch: { data: undefined, label: "Date Tested" },
    validUntil: { data: undefined, label: "Valid Until" },
    isQuarantined: { data: filter.isQuarantined, label: "Quarantined" },
    show: { data: undefined, label: "Show" },
    resultDates: { data: undefined, label: "Last Positive Test Date" },
    quarantinedStart: { data: undefined, label: "Expected End Date" },
    daysCount: { data: undefined, label: "Days Count" },
    hippaConsent: { data: undefined, label: "Hippa Consent" },
    referenceID: { data: filter.referenceID, label: "ReferenceID" },
    accession_no: { data: filter.accession_no, label: "Accession No" },
    phone_number: { data: filter.phone_number, label: "Phone" },
    sex: { data: filter.sex, label: "Gender" },
    id_number: { data: filter.id_number, label: "ID Number" },
    street: { data: filter.street, label: "Street" },
    city: { data: filter.city, label: "City" },
    showName: { data: filter.showName, label: "Show Name" },
    testSendQty: { data: filter.testSendQty, label: "Send Quantity" },
    isVaccinated: { data: parseBooleanValue(filter.isVaccinated), label: "Vaccinated" },
    vaccinationType: { data: filter.vaccinationType, label: "Vaccination Type" },
    vaccinationDate: { data: filter.vaccinationDate, label: "Vaccination Date" },
    isFalsePositive: { data: filter.isFalsePositive, label: "isFalsePositive" },
    isExternalTest: { data: parseBooleanValue(filter.isExternalTest), label: "External Test" },
    externalTestType: { data: filter.externalTestType, label: "External Test Type" },
    externalTestDate: { data: filter.externalTestDate, label: "External Test Date" },
    externalTestResult: { data: filter.externalTestResult, label: "External Test Resut" },
    step: { data: PRE_REGISTRATION_STATUS[filter.step], label: "Status" },
    orderStatus: { data: filter.orderStatus, label: "Order Status" },
    barcode: { data: filter.barcode, label: "Accession/Barcode" },
  };
  const header = Object.keys(tests[0]).map((c) => filters[c]?.label);
  const { start, end } = timeFilter;
  const sortedVal = filters[sortBy];
  const gapFilteredVal = (filters) => {
    var str = "";
    for (const [key, value] of Object.entries(filters)) {
      if (key === "emailSend" && value.data !== undefined && value.data.length > 0) {
        const val = value.data == "1" ? "true" : "Empty";
        str += `${value?.label} - ${val}, `;
        continue;
      }
      if (value.data !== undefined && value.data.length > 0) {
        str += `${value?.label} - ${value.data}, `;
      }
    }
    return str;
  };
  const styles = StyleSheet.create({
    body: {
      paddingTop: 35,
      paddingBottom: 35,
      paddingHorizontal: 20,
    },
    table: {
      width: "100%",
      marginBottom: "20px",
    },
    row: {
      display: "flex",
      flexDirection: "row",
      paddingBottom: "10px",
    },
    test: {
      fontSize: 6,
      width: "9%",
      textAlign: "center",
    },
    infoText: {
      fontSize: 13,
    },
    infoTxt: {
      fontSize: 20,
    },
    head: {
      fontSize: 9,
      fontWeight: "bold",
      textDecoration: "underline",
      width: "9%",
      fontFamily: "Times-Bold",
      textAlign: "center",
      paddingTop: "10px",
    },
    pageNumbers: {
      position: "absolute",
      fontSize: 8,
      bottom: 20,
      left: 0,
      right: 0,
      textAlign: "center",
    },
  });

  return (
    <>
      <Document>
        <Page style={styles.body}>
          <View style={styles.table}>
            <View style={{ flexDirection: "row", justifyContent: "space-around", marginBottom: "10px" }}>
              <Image
                style={{ width: "100px", height: "50px" }}
                src={require("assets/img/safecamp_BRAND.1-nobg.png").default}
              />

              <View
                style={{
                  alignItems: "center",
                  padding: "10px",
                  paddingLeft: "10px",
                }}
              >
                <Text style={{ ...styles.infoTxt, fontFamily: "Times-Bold" }}>Completed Tests Report</Text>
                {Object.keys(timeFilter).length !== 0 && start !== moment("20210301") ? (
                  <Text style={{ ...styles.infoText, fontFamily: "Times-Bold" }}>{`Date Range: ${moment(start).format(
                    "MMM DD, YYYY"
                  )} to ${moment(end).format("MMM DD, YYYY")}`}</Text>
                ) : undefined}
                {Object.keys(filter).length > 0 ? (
                  <Text style={{ ...styles.infoText, fontFamily: "Times-Bold" }}>{`Filtered By: ${gapFilteredVal(
                    filters
                  )}`}</Text>
                ) : undefined}
                {sortBy ? (
                  <Text style={{ ...styles.infoText, fontFamily: "Times-Bold" }}>
                    {" "}
                    {`Sorted By: ${sortedVal?.label}`}
                  </Text>
                ) : undefined}
              </View>
            </View>
            <View style={styles.row}>
              {header.map((head) => (
                <Text style={styles.head}>{head}</Text>
              ))}
            </View>
            {tests.map((test, i) => (
              <View key={i} style={styles.row} wrap={true}>
                {Object.keys(tests[0]).map((c) => (
                  <Text style={styles.test}>{test[c]}</Text>
                ))}
              </View>
            ))}
          </View>
          <Text
            style={styles.pageNumbers}
            render={({ pageNumber, totalPages }) => `Page ${pageNumber} of ${totalPages}`}
            fixed
          />
        </Page>
      </Document>
    </>
  );
};

export default AllTestPDF;
