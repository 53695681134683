import React, { useState, useEffect } from "react";
import { Modal, Button, Table, Row, Col } from "react-bootstrap";
import { CSVReader } from "react-papaparse";
import ErrorMessage from "components/Message/ErrorMessage"


const ImportTestDoneModal = (props) => {
  const [error, setError] = useState("");
  const { handleClose, handleImport,errorMessage } = props;
  useEffect(()=>{
    setError(errorMessage);
  },[errorMessage])
  const handleOnDrop = (data) => {
    console.log("Data Drop",data);
     handleImport(data);
  }
  const handleOnError = (err) => {
    console.log(err);
  };

  const handleOnRemoveFile = (data) => {
    console.log("Remove Data",data);
  };

  return (
    <Modal show animation={true} onHide={() => handleClose()} centered size={"lg"}>
      <Modal.Header closeButton>
        <Modal.Title className="my-0" id="contained-modal-title-vcenter">
          Import Employee Data
        </Modal.Title>
      </Modal.Header>

      <Modal.Body style={{ paddingTop: 0 }}>
        <div className="form-group-wrapper d-block">
        <CSVReader
        onDrop={handleOnDrop}
        onError={handleOnError}
        addRemoveButton
        config={{
          header: true,
          skipEmptyLines: true
        }}
        onRemoveFile={handleOnRemoveFile}
      >
        <span>Drop CSV file here or click to upload.</span>
      </CSVReader>
      <div style={{marginTop:"10px"}}>
        {error && (
          <ErrorMessage error={error} handleChange={()=>setError('')}/>
        )}
        </div>
        </div>
      </Modal.Body>

      <Modal.Footer className="flex-row-reverse">
        <Button className="modalButtons" variant="primary" onClick={() => handleClose()}>
          OK
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default ImportTestDoneModal;
