import React, { useContext, useState, useMemo, useRef } from "react";
import { Button, Card, Table, Row, Col, Modal, Form, OverlayTrigger, Alert } from "react-bootstrap";
import { useEffect } from "react";
import { Link } from "react-router-dom";
import API from "api";
import SearchBar from "components/SearchBar";
import { Auth, Storage } from "aws-amplify";
import { Bounce } from "react-activity";
import "react-activity/dist/Bounce.css";
import Filter from "components/Filter";
import TimeFilter from "components/TimeFilter";
import $ from "jquery";
import ViewOnlyCheckBox from "components/CheckBox/ViewOnlyCheckBox";

import ErrorMessage from "components/Message/ErrorMessage";
import {
  changeSort,
  formatPhoneNumber,
  isValidPhone,
  phoneFormatter,
  parseBooleanValue,
  isValidFile,
  calculateTdWidth,
  sortingFilterInLC,
} from "utils";
import HeaderItem from "components/Table/HeaderItem";
import MFPagination from "components/Pagination/MFPagination";
import { PAGE_LIMIT } from "constant";
import LabBarCode from "components/Modal/LabBarCode";
import BarCodeAlert from "components/Labs/BarCodeAlert";
import TextEditor from "views/TextEditor";

import { AppContext } from "context/app-context";
import { DISCLAIMER_TYPE } from "constant";
import { DISCLAIMER_KEYS } from "constant";
import PhoneNoInput from "components/Phone/PhoneNoInput";
import Loader from "../../components/Loader/Loader";
import Icon from "components/Icon";
import editIcon from "../../assets/img/edit-icon.png";
import Status from "components/Status/Status";
import DropdownMenu from "components/DropdownMenu/DropdownMenu";
import LabModal from "components/Labs/LabModal";

const options = [
  {
    title: "Edit",
  },
  {
    title: "Delete",
  },
  {
    title: "Launch Portal",
  },
];

const LabsView = () => {
  const mainRef = useRef();
  const [width, setWidth] = useState(mainRef.current ? mainRef.current.offsetWidth : 0);

  const handleResize = () => {
    if (mainRef.current) setWidth(mainRef.current.offsetWidth);
  };
  useEffect(() => handleResize(), [mainRef, mainRef.current]);
  useEffect(() => {
    handleResize();
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  });
  const tdWidth = calculateTdWidth(width - 110, 11);

  const appContext = useContext(AppContext);
  const [labTests, setLabTests] = useState([]);
  const [labs, setLabs] = useState(appContext.labs ?? []);
  const [filteredLabs, setFilteredLabs] = useState([]);
  const [openCreator, setOpenCreator] = useState(false);
  const [openDeleteConfirmation, setOpenDeleteConfirmation] = useState(false);
  const [labToDelete, setLabToDelete] = useState("");
  const [newLab, setNewLab] = useState({});
  const [sortBy, setSortBy] = useState("");
  const [sortDescending, setSortDescending] = useState(true);
  const [loading, setLoading] = useState(false);
  const [timeFilter, setTimeFilter] = useState({});
  const [showFilter, setShowFilter] = useState(false);
  const [filter, setFilter] = useState({});
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [logo, setLogo] = useState(null);
  const [showCSVImporter, setShowCSVImporter] = useState(false);
  const [selectedImage, setSelectedImage] = useState("");

  const { filterDates } = TimeFilter;

  const searchTerms = [
    "Name",
    "Contact Name",
    "Contact Email",
    "Contact Phone",
    "Print Labels",
    "Antigen",
    "Molecular",
    "Accula",
    "Send Insurance",
    "BarCodes",
    "White Label",
  ];
  const [isShown, setIsShown] = useState(false);

  const handleClose = () => {
    setOpenCreator(false);
    setOpenDeleteConfirmation(false);
  };

  useEffect(() => {
    const sortingFilters = sortingFilterInLC.get();
    if (sortingFilters.labs.sortBy) {
      setSortDescending(sortingFilters.labs.sortDescending);
      setSortBy(sortingFilters.labs.sortBy);
    }
    loadSummary();
  }, []);

  useEffect(() => {
    setLabs(appContext.labs);
    setFilteredLabs(appContext.labs);
  }, [appContext.labs]);

  useEffect(() => {
    const prevSortingState = sortingFilterInLC.get();
    prevSortingState["labs"] = { filter, timeFilter, sortBy, sortDescending };
    sortingFilterInLC.save(prevSortingState);
    changeSort(
      sortBy,
      sortDescending,
      filteredLabs,
      setFilteredLabs,
      appContext.getSiteName,
      appContext.getLabName,
      appContext.getClientName,
      appContext.getLabTests
    );
  }, [sortBy, sortDescending]);

  useEffect(() => {
    refreshSummary(labs, labTests);
  }, [labTests]);

  const loadSummary = async () => {
    const summary = await API.getSummaryFromPG({ summary: "lab" });
    setLabTests(summary.rows);
  };

  const openLab = (labID) => {
    let url = `${window.location.origin}/lab/tests/${labID}`;
    window.open(url);
  };
  const refreshSummary = (data, summary) => {
    if (data.length === 0) return;
    if (summary.length === 0) {
      setFilteredLabs(data);
      return;
    }
    const newList = data.map((c) => {
      const obj = summary.find((x) => x.id === c.id);
      return { ...c, totalTest: obj ? obj.totalTest : 0 };
    });
    setFilteredLabs(newList);
  };

  const deleteLab = async (id) => {
    try {
      await API.deleteLab(id);
      appContext.resetLabs();
      appContext.showSuccessMessage("Lab delete successfully");
    } catch (error) {
      appContext.showErrorMessage(error.message);
    }
  };

  const uploadLogo = async (id) => {
    if (logo) {
      try {
        // const response = await Storage.put(`${id}-logo.png`, logo);
        let fileName = `labs/${id}-logo.png`;
        await Storage.put(fileName, logo, { bucket: "medflow-images" });
        return true;
      } catch (error) {
        return false;
      }
    }
    return false;
  };

  const filterLabs = (term, input) => {
    setFilteredLabs(labs.filter((lab) => lab[term].toLowerCase().includes(input.toLowerCase())));
  };

  const labsToMap = useMemo(() => {
    const list = filterDates(filteredLabs, timeFilter);
    const ttlPages = list.length > PAGE_LIMIT ? Math.ceil(list.length / PAGE_LIMIT) : 1;
    setTotalPages(ttlPages);
    if (list.length < PAGE_LIMIT || currentPage > ttlPages) {
      setCurrentPage(1);
    }
    if (ttlPages > 1) {
      const indexOfLastUser = currentPage * PAGE_LIMIT;
      const indexOfFirstUser = indexOfLastUser - PAGE_LIMIT;
      return list.slice(indexOfFirstUser, indexOfLastUser);
    }
    return list;
  }, [filteredLabs, currentPage, timeFilter]);

  const nestedFilter = (targetArray, filters) => {
    if (Object.keys(filter).length === 0) return targetArray;
    const filterKeys = Object.keys(filters);
    //filters main array of objects
    const models = targetArray.filter((obj) => {
      //goes through each key being filtered for
      return filterKeys.every((key) => {
        if (!filters[key].length) {
          return true;
        }
        if (key === "tubes_provided") {
          return parseBooleanValue(obj[key]).toLowerCase().includes(filters[key]?.toLowerCase());
        }
        if (key === "default_antigen") {
          return parseBooleanValue(obj[key]).toLowerCase().includes(filters[key]?.toLowerCase());
        }
        if (key === "default_molecular") {
          return parseBooleanValue(obj[key]).toLowerCase().includes(filters[key]?.toLowerCase());
        }
        if (key === "default_other") {
          return parseBooleanValue(obj[key]).toLowerCase().includes(filters[key]?.toLowerCase());
        }
        if (key === "default_rapidflu") {
          return parseBooleanValue(obj[key]).toLowerCase().includes(filters[key]?.toLowerCase());
        }
        if (key === "default_lucira") {
          return parseBooleanValue(obj[key]).toLowerCase().includes(filters[key]?.toLowerCase());
        }
        if (key === "sendInsurance") {
          return parseBooleanValue(obj[key]).toLowerCase().includes(filters[key]?.toLowerCase());
        }
        if (key === "barCodeProvided") {
          return parseBooleanValue(obj[key]).toLowerCase().includes(filters[key]?.toLowerCase());
        }
        if (key === "whiteLabel") {
          return parseBooleanValue(obj[key]).toLowerCase().includes(filters[key]?.toLowerCase());
        }

        return obj[key] && obj[key].toLowerCase().includes(filters[key]);
      });
    });
    // console.log("FROM NESTED FILTER", models);
    return models;
  };

  useEffect(() => {
    setFilteredLabs(nestedFilter(labs, filter));
  }, [filter]);

  const handleFilterChange = (event, picker) => setTimeFilter({ start: picker.startDate, end: picker.endDate });
  const handleFilterCancel = (event, picker) => setTimeFilter({});
  const handleChangePage = (page) => setCurrentPage(page);

  const flipSort = (by) => {
    setSortBy(by);
    setSortDescending(sortBy === by ? !sortDescending : true);
  };

  const handleEditLab = async (lab) => {
    setNewLab({
      isNew: false,
      id: lab.id,
      name: lab.name,
      contact: lab.contact_name,
      phone: lab.contact_phone || lab.phone_number,
      email: lab.contact_email,
      fax_number: lab.fax_number,
      street: lab.street,
      city_state_zip: lab.city_state_zip,
      lab_director: lab.lab_director,
      clia_number: lab.clia_number,
      referredDoctor: lab?.referredDoctor || null,
      testing_notes: lab.testing_notes,
      tubes_provided: lab.tubes_provided,
      default_antigen: lab.default_antigen,
      default_molecular: lab.default_molecular,
      default_rapidflu: lab.default_rapidflu,
      default_lucira: lab.default_lucira,
      sendInsurance: lab.sendInsurance,
      logo: lab.logo,
      barCodeProvided: lab.barCodeProvided,
      barCodeAlertLimit: lab.barCodeAlertLimit,
      antigen_notes: lab.antigen_notes,
      molecular_notes: lab.molecular_notes,
      showOnSummaryScreen: lab.showOnSummaryScreen || false,
      whiteLabel: lab.whiteLabel,
      whiteLabelPackage: lab.whiteLabelPackage,
      default_other: lab.default_other,
      other_notes: lab.other_notes,
      custom_notes: lab.custom_notes,
      invalid_notes: lab.invalid_notes,
      indeterminate_notes: lab.indeterminate_notes,
      isArchive: lab.isArchive,
      lucira_notes: lab.lucira_notes,
      rapidflu_notes: lab.rapidflu_notes,
      pendingRelease: parseInt(lab.pendingRelease) || 0,
    });
    if (lab.logo) {
      try {
        let imagePath = `${lab.logo}`;
        const path = await Storage.get(imagePath, { bucket: "medflow-images" });
        setSelectedImage(path);
      } catch (error) {
        console.log("Fail to load the logo");
      }
    } else {
      setSelectedImage(null);
    }
    setOpenCreator(true);
  };

  const getTotalTest = (lab) => {
    if (!lab.totalTest || lab.totalTest === 0) {
      return <>0</>;
    }
    return (
      <Link
        className="cursor-pointer text-primary-black text-decoration-none"
        to={{
          pathname: `/admin/totaltests`,
          state: { name: lab.name, term: "Lab", models: labs },
        }}
      >
        {lab.totalTest}
      </Link>
    );
  };

  const getDefaultLabName = (data) => {
    let defaultLab = [];

    if (data.default_antigen) {
      defaultLab.push("Antigen");
    }
    if (data.default_lucira) {
      defaultLab.push("Lucira");
    }
    if (data.default_molecular) {
      defaultLab.push("Molecular");
    }
    if (data.default_other) {
      defaultLab.push("Rapid PCR");
    }
    if (data.default_rapidflu) {
      defaultLab.push("Rapid Flu");
    }

    return defaultLab;
  };

  const handleDropDownOptions = (lab, value) => {
    switch (value) {
      case "Edit":
        handleEditLab(lab);
        break;
      case "Delete":
        setOpenDeleteConfirmation(true);
        setLabToDelete(lab.id);
        break;
      case "Launch Portal":
        openLab(lab.id);
      default:
        break;
    }
  };

  const TableRow = ({ lab }) => {
    let defaultLabRoles = getDefaultLabName(lab);

    return (
      <tr key={lab.id} className="trDataWrapper">
        <td className="icon" style={{ width: "50px", minWidth: "50px", maxWidth: "50px" }}>
          <ViewOnlyCheckBox bgColor="red" id={lab.id} />
        </td>
        <td className="ellipsis" title={lab.name}>
          {lab.name}
        </td>
        <td className="ellipsis" title={lab.contact_name}>
          {lab.contact_name}
        </td>
        <td
          className="ellipsis text-dark-danger cursor-pointer"
          title={lab.contact_email}
          onClick={() => lab.contact_email && window.open(`mailto:${lab.contact_email}`)}
        >
          {lab.contact_email}
        </td>
        <td
          className="ellipsis text-dark-danger cursor-pointer"
          onClick={() => {
            if (lab.contact_phone) {
              window.open(`tel:+${lab.contact_phone.replace(/\D/g, "")}`);
            }
            if (lab.phone_number) {
              window.open(`tel:+${lab.phone_number.replace(/\D/g, "")}`);
            }
          }}
          title={formatPhoneNumber(lab.contact_phone || lab.phone_number)}
        >
          {formatPhoneNumber(lab.contact_phone || lab.phone_number)}
        </td>
        <td className="ellipsis" style={{ textAlign: "center" }}>
          {getTotalTest(lab)}
        </td>
        <td className="ellipsis" style={{ textAlign: "center" }}>
          <div className="star-icon-wrapper">
            <Status
              type="circle"
              size="md"
              color={lab.tubes_provided ? "green" : "maroon"}
              tickIcon={lab.tubes_provided}
              crossIcon={!lab.tubes_provided}
            />
          </div>
        </td>
        <td
          className="ellipsis"
          style={{ textAlign: "center", textOverflow: "visible" }}
          title={defaultLabRoles.length > 1 ? defaultLabRoles.join(", ") : defaultLabRoles}
        >
          {/* <span title={parseBooleanValue(lab.default_antigen)}>{parseBooleanValue(lab.default_antigen)}</span> */}
          {/* <div className="star-icon-wrapper">
            <Status
              type="circle"
              size="md"
              color={lab.default_antigen ? "green" : "maroon"}
              tickIcon={lab.default_antigen}
              crossIcon={!lab.default_antigen}
            />
          </div> */}

          {defaultLabRoles && defaultLabRoles.length > 1
            ? "Multi"
            : defaultLabRoles.length == 1
            ? defaultLabRoles
            : "-"}
        </td>
        {/* <td className="ellipsis" style={{ textAlign: "center" }}>
          <span title={parseBooleanValue(lab.default_antigen)}>{parseBooleanValue(lab.default_antigen)}</span>
        </td>
        <td className="ellipsis" style={{ textAlign: "center" }}>
          <span title={parseBooleanValue(lab.default_molecular)}>{parseBooleanValue(lab.default_molecular)}</span>
        </td>
        <td className="ellipsis" style={{ textAlign: "center" }}>
          <span title={parseBooleanValue(lab.default_other)}>{parseBooleanValue(lab.default_other)}</span>
        </td>
        <td className="ellipsis" style={{ textAlign: "center" }}>
          <span title={parseBooleanValue(lab.default_rapidflu)}>{parseBooleanValue(lab.default_rapidflu)}</span>
        </td>
        <td className="ellipsis" style={{ textAlign: "center" }}>
          <span title={parseBooleanValue(lab.default_lucira)}>{parseBooleanValue(lab.default_lucira)}</span>
        </td> */}
        <td className="ellipsis" style={{ textAlign: "center" }}>
          <span title={parseBooleanValue(lab.sendInsurance)}>
            <div className="star-icon-wrapper">
              <Status
                type="circle"
                size="md"
                color={lab.sendInsurance ? "green" : "maroon"}
                tickIcon={lab.sendInsurance}
                crossIcon={!lab.sendInsurance}
              />
            </div>
          </span>
        </td>
        <td className="ellipsis" style={{ textAlign: "center" }}>
          <span>
            {parseBooleanValue(lab.barCodeProvided) === "Yes" ? (
              <div
                onClick={() => {
                  setShowCSVImporter(lab.id);
                }}
              >
                <span style={{ textDecoration: "underline", color: "#42cef5", cursor: "pointer" }}>View</span> &nbsp;
                <BarCodeAlert id={lab.id} labData={lab} title="Available barcode is below the limit" />
              </div>
            ) : (
              <div className="star-icon-wrapper">
                <Status type="circle" size="md" color="maroon" crossIcon />
              </div>
            )}
          </span>
        </td>
        <td className="ellipsis" style={{ textAlign: "center" }}>
          <span title={parseBooleanValue(lab.whiteLabel)} className="star-icon-wrapper">
            {/* {parseBooleanValue(lab.whiteLabel)} */}
            <Status
              type="circle"
              size="md"
              color={lab.sendInsurance ? "green" : "maroon"}
              tickIcon={lab.sendInsurance}
              crossIcon={!lab.sendInsurance}
            />
          </span>
        </td>

        {/* <td className="ellipsis">
          <Link
            className="ellipsis text-dark-danger text-decoration-none text-dark-danger-hover"
            onClick={() => {
              openLab(lab.id);
            }}
          >
            Launch Portal
          </Link>
        </td> */}

        <td className="icon text-center" style={{ width: "50px", minWidth: "50px", maxWidth: "50px" }}>
          <DropdownMenu options={options} handleDropDownOptions={(e) => handleDropDownOptions(lab, e)} />
        </td>
      </tr>
    );
  };

  return (
    <div className="container-fluid">
      {!loading ? (
        <div className="shows-screen-parent">
          <Row className="mx-0">
            <Col md="12">
              <Card className="border-0 mb-1">
                <Card.Body className="p-0">
                  <div className="shows-filter-wrapper">
                    <div className="shows-filter-inner">
                      <Icon
                        handleClick={() => setShowFilter(!showFilter)}
                        title={"Filter"}
                        label={"Filter"}
                        iconType={"filter"}
                      />
                    </div>
                    <Icon
                      handleClick={() => (
                        setOpenCreator(true),
                        setNewLab({
                          isNew: true,
                          default_antigen: false,
                          default_molecular: false,
                          default_other: false,
                          default_rapidflu: false,
                          default_lucira: false,
                          sendInsurance: false,
                          barCodeProvided: false,
                          whiteLabel: false,
                          isArchive: false,
                          pendingRelease: 0,
                        }),
                        setSelectedImage(null),
                        setLogo(null)
                      )}
                      title={"Create Lab"}
                      label={"Create"}
                      iconType={"createIcon"}
                    />
                  </div>
                  <div>{showFilter && <Filter filterTerms={searchTerms} setFilter={setFilter} filter={filter} />}</div>
                </Card.Body>
              </Card>
            </Col>
          </Row>
          <Row className="mx-0">
            <Col md="12">
              <Card className="striped-tabled-with-hover bg-transparent mb-0">
                <Card.Header className="shows-screen-wrapper">
                  <Row className="pb-4">
                    <Col md="8">
                      <Card.Title>
                        <div className="">
                          Total Labs:
                          <span className="ps-2"> ({filteredLabs.length})</span>
                        </div>
                      </Card.Title>
                    </Col>
                    <Col md="4">
                      <MFPagination
                        currentPage={currentPage}
                        handleChangePage={handleChangePage}
                        totalPages={totalPages}
                      />
                    </Col>
                  </Row>
                </Card.Header>
              </Card>
              <Card className="striped-tabled-with-hover bg-transparent">
                {/* <Card.Header>
                  <Row>
                    <div className="clientsButtonHeader buttonHeader justify-content-between mb-1">
                      <div className="clientsButtonHeader buttonHeader">
                        <Button className="headerButton mb-0" onClick={() => setShowFilter(!showFilter)}>
                          Filter
                        </Button>
                      </div>
                      <Button
                        className="createButtons"
                        onClick={() => (
                          setOpenCreator(true),
                          setNewLab({
                            isNew: true,
                            default_antigen: false,
                            default_molecular: false,
                            default_other: false,
                            default_rapidflu: false,
                            default_lucira: false,
                            sendInsurance: false,
                            barCodeProvided: false,
                            whiteLabel: false,
                            isArchive: false,
                            pendingRelease: 0,
                          }),
                          setSelectedImage(null),
                          setLogo(null)
                        )}
                      >
                        Create a Lab
                      </Button>
                    </div>
                  </Row>
                </Card.Header> */}

                <Card.Body className="table-full-width px-0 desktop-noScroll">
                  <div className="table-responsive pendingReleaseTable" ref={mainRef}>
                    <Table className="separate-border-tr">
                      <thead>
                        <tr>
                          <th></th>

                          <HeaderItem
                            width={tdWidth}
                            ItemKey="name"
                            title="Name"
                            flipSort={flipSort}
                            sortBy={sortBy}
                            sortDescending={sortDescending}
                          />
                          <HeaderItem
                            width={tdWidth}
                            ItemKey="contact_name"
                            title="Contacts Name"
                            flipSort={flipSort}
                            sortBy={sortBy}
                            sortDescending={sortDescending}
                          />
                          <HeaderItem
                            width={tdWidth}
                            ItemKey="contact_email"
                            title="Contacts's Email"
                            flipSort={flipSort}
                            sortBy={sortBy}
                            sortDescending={sortDescending}
                          />
                          <HeaderItem
                            width={tdWidth}
                            ItemKey="contact_phone"
                            title="Contacts's Phone"
                            flipSort={flipSort}
                            sortBy={sortBy}
                            sortDescending={sortDescending}
                          />

                          <HeaderItem
                            width={tdWidth}
                            ItemKey="totalTests"
                            title="Total Tests (YTD)"
                            flipSort={flipSort}
                            sortBy={sortBy}
                            sortDescending={sortDescending}
                            aligned="centered"
                          />
                          <HeaderItem
                            width={tdWidth}
                            ItemKey="tubes_provided"
                            title="Print Labels"
                            flipSort={flipSort}
                            sortBy={sortBy}
                            sortDescending={sortDescending}
                            aligned="centered"
                          />
                          <HeaderItem
                            width={tdWidth}
                            ItemKey="default_antigen"
                            title="Default Lab"
                            flipSort={flipSort}
                            sortBy={sortBy}
                            sortDescending={sortDescending}
                            aligned="centered"
                          />
                          {/* <HeaderItem
                            width={tdWidth}
                            ItemKey="default_antigen"
                            title="Antigen"
                            flipSort={flipSort}
                            sortBy={sortBy}
                            sortDescending={sortDescending}
                            aligned="centered"
                          />
                          <HeaderItem
                            width={tdWidth}
                            ItemKey="default_molecular"
                            title="Molecular"
                            flipSort={flipSort}
                            sortBy={sortBy}
                            sortDescending={sortDescending}
                            aligned="centered"
                          />
                          <HeaderItem
                            width={tdWidth}
                            ItemKey="default_other"
                            title="Accula"
                            flipSort={flipSort}
                            sortBy={sortBy}
                            sortDescending={sortDescending}
                            aligned="centered"
                          />
                          <HeaderItem
                            width={tdWidth}
                            ItemKey="default_rapidflu"
                            title="Radif Flu"
                            flipSort={flipSort}
                            sortBy={sortBy}
                            sortDescending={sortDescending}
                            aligned="centered"
                          />
                          <HeaderItem
                            width={tdWidth}
                            ItemKey="default_lucira"
                            title="Lucira"
                            flipSort={flipSort}
                            sortBy={sortBy}
                            sortDescending={sortDescending}
                            aligned="centered"
                          /> */}
                          <HeaderItem
                            width={tdWidth}
                            ItemKey="sendInsurance"
                            title="Send Insurance"
                            flipSort={flipSort}
                            sortBy={sortBy}
                            sortDescending={sortDescending}
                            aligned="centered"
                          />
                          <HeaderItem
                            width={tdWidth}
                            ItemKey="barCodeProvided"
                            title="BarCodes"
                            flipSort={flipSort}
                            sortBy={sortBy}
                            sortDescending={sortDescending}
                            aligned="centered"
                          />
                          <HeaderItem
                            width={tdWidth}
                            ItemKey="whiteLabel"
                            title="White Label"
                            flipSort={flipSort}
                            sortBy={sortBy}
                            sortDescending={sortDescending}
                            aligned="centered"
                          />
                          <th
                            className="border-0 text-center text-grey"
                            style={{ fontWeight: "bold", width: tdWidth, minWidth: tdWidth, maxWidth: tdWidth }}
                          >
                            Actions
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {labsToMap.map((lab) => {
                          return <TableRow key={lab.id} lab={lab} />;
                        })}
                      </tbody>
                    </Table>
                  </div>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </div>
      ) : (
        <Loader />
      )}

      {openCreator && <LabModal lab={newLab} handleClose={handleClose} image={selectedImage} appContext={appContext} />}

      <Modal
        show={openDeleteConfirmation}
        // style={{ transform: "translate(0, -30%)" }}
        animation={true}
        onHide={() => {
          handleClose();
        }}
        centered
        size={"md"}
      >
        <Modal.Header closeButton>
          <Modal.Title className="my-0" id="contained-modal-title-vcenter">
            Delete LAB
          </Modal.Title>
        </Modal.Header>

        <Modal.Body style={{ paddingTop: 0, marginRight: "auto", marginLeft: "auto" }}>
          <Modal.Title id="contained-modal-title-vcenter">Are you sure you want to delete this LAB?</Modal.Title>
        </Modal.Body>

        <Modal.Footer>
          <Button
            className="modalButtons"
            variant="primary"
            onClick={() => {
              handleClose();
            }}
          >
            Go back
          </Button>
          <Button
            className="modalButtons"
            variant="danger"
            onClick={() => {
              deleteLab(labToDelete);
              handleClose();
            }}
          >
            Confirm
          </Button>
        </Modal.Footer>
      </Modal>
      {showCSVImporter && <LabBarCode id={showCSVImporter} handleClose={() => setShowCSVImporter(null)} />}
    </div>
  );
};

export default LabsView;
