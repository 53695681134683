import Icon from "components/Icon";
import moment from "moment";
import { useRef } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

moment;
const TestTypeInputDate = ({
  onChange,
  disabled = false,
  isFutureDateAllowed = true,
  error = false,
  value,
  label,
  icon,
}) => {
  const datePickerRef = useRef(null);

  const openCalendar = () => {
    if (datePickerRef.current) {
      datePickerRef.current.setOpen(true);
    }
  };

  return (
    <>
      {label && (
        <p className={`test-type-input-label ${icon && "d-flex align-items-center justify-content-between"}`}>
          {label}

          {icon && (
            <Icon handleClick={openCalendar} iconType={"calendarDateIcon"} containerClass={"ms-2 cursor-pointer"} />
          )}
        </p>
      )}
      <DatePicker
        ref={datePickerRef}
        className="modalInput w-100"
        openCalendarOnFocus={false}
        dayPlaceholder="dd"
        monthPlaceholder="mm"
        yearPlaceholder="yyyy"
        dateFormat="MM/dd/y"
        selected={value && new Date(value) != "Invalid Date" ? moment(value, "YYYY-MM-DD").toDate() : null}
        onChange={onChange}
        disabled={disabled}
        maxDate={!isFutureDateAllowed ? new Date() : undefined}
        minDate={new Date("1900-01-01")}
        isClearable
        placeholderText="Select a date"
      />
    </>
  );
};

export default TestTypeInputDate;
