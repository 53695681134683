import api from "api";
import "bootstrap-daterangepicker/daterangepicker.css";
import ErrorMessage from "components/Message/ErrorMessage";
import RadioButton from "components/RadioButton/RadioButton";
import Loader from "components/Loader/Loader";
import { NOTES_MAX_CHARACTER_LENGTH } from "constant";
import moment from "moment";
import { useState, useContext } from "react";
import { Button, Card, Modal } from "react-bootstrap";
import PreviousNotes from "./PreviousNotes";
import { useDispatch } from "react-redux";
import { updateProviderNoteAsync } from "store/features/providers/providersSlice";
import { updateLabClientNotes } from "store/features/locations/locationsSlice";
import { AppContext } from "context/app-context";

const NotesModal = (props) => {
  const { data, handleClose, user, emp, order, claim, provider, labClient } = props;

  const [notes, setNotes] = useState((emp || claim || provider || labClient ? data.note : data.note?.message) || "");
  const [error, setError] = useState("");
  const [status, setStatus] = useState(data.status);
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const appContext = useContext(AppContext);

  const onHandleSave = async () => {
    setLoading(true);
    const obj = {
      id: data.mdID,
      ...((emp || claim) && { note: notes.trim(), status }),
      updatedBy: appContext.user?.sub,
      updatedByName: appContext.user?.name,
    };

    try {
      if (emp) {
        const res = await api.updateEmployeeRecord(obj);
        if (res) await api.patientUpdateAPI(res);
      } else if (provider) {
        dispatch(updateProviderNoteAsync({ ...data, note: notes.trim() }));
      } else if (labClient) await dispatch(updateLabClientNotes({ ...data, note: notes.trim() }));

      setLoading(false);
      handleClose(obj);
    } catch (err) {
      console.log("Error:-", err);
      setLoading(false);
    }
  };

  return (
    <>
      <Modal show backdrop="static" onHide={handleClose} animation={true} size={"lg"} scrollable>
        <Modal.Header closeButton>
          <Modal.Title className="my-0">
            Notes{" "}
            {data &&
              `for ${data[claim ? "pat_name_f" : labClient ? "name" : "firstName"]} ${
                !labClient ? data[claim ? "pat_name_l" : labClient ? "" : "lastName"] : ""
              }`}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {emp && (
            <Card>
              <div className="form-group-wrapper mb-0 p-2">
                <label className="modalLineHeaders mt-0 text-capitalize">Status:</label>
                <div className="d-flex justify-content-between w-50 flex-wrap">
                  <RadioButton
                    name="status"
                    id="active"
                    label="Active"
                    checked={status === "active" ? true : false}
                    handleRadioButtonChange={(e) => setStatus(e.target.checked ? "active" : "")}
                  />
                  <RadioButton
                    name="status"
                    id="inactive"
                    checked={status !== "active" ? true : false}
                    label="InActive"
                    handleRadioButtonChange={(e) => {
                      setStatus(e.target.checked ? "inactive" : "");
                    }}
                  />
                </div>
              </div>
            </Card>
          )}

          <div className="row textAreaMessageInput">
            <div className="col-12 mb-3">
              <textarea
                placeholder={"Note!"}
                className="MessageInput"
                value={notes}
                onChange={(e) => setNotes(e.target.value)}
                maxLength={NOTES_MAX_CHARACTER_LENGTH}
              />
            </div>
          </div>
          <PreviousNotes id={order || claim ? data.employeeID : data.id} user={user} />
          <div style={{ minHeight: "3vh" }}>
            {error && <ErrorMessage error={error} handleChange={() => setError("")} />}
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button
            style={{ marginBottom: 10 }}
            variant="primary"
            className="modalButtons headerButton btn-fill"
            onClick={() => {
              handleClose();
            }}
          >
            Close
          </Button>
          <div className="d-flex">
            <Button
              style={{ marginBottom: 10 }}
              variant="secondary"
              className="modalButtons headerButton btn-fill"
              onClick={onHandleSave}
            >
              Save
            </Button>
          </div>
          {loading && <Loader />}
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default NotesModal;
