import React from "react";
import EditIcon from "assets/img/edit-icon.png";

const InputGrid = (props) => {
  const { headerLabel, list, handleEdit, cssClass } = props;
  return (
    <>
      <div className={`order-select-edited mb-4 ${cssClass}`}>
        <div className="column-gap-15 form-group-wrapper align-items-center mb-0 m-0 secondary-insurance-head">
          <label className="w-94 heading-insurance">{headerLabel}</label>
          {handleEdit && (
            <img
              src={EditIcon}
              width="18"
              alt="edit icon"
              onMouseLeave={(e) => (e.target.style.color = "black")}
              onMouseOver={(e) => {
                e.target.style.cursor = "pointer";
              }}
              aria-hidden="true"
              onClick={(e) => handleEdit(e)}
            />
          )}
        </div>
        {list &&
          list.map((item, i) => {
            return (
              item && (
                <div className="d-flex secondary-insurance-change list-item-ins" key={i}>
                  <label className="mxw-50">{item.label}</label>
                  <input
                    className={`height-32 modalInput hidebordar ellipsis secondary-edit-input text-right`}
                    value={item.value || ""}
                    title={item.value || ""}
                    type="text"
                    readOnly={true}
                  />
                </div>
              )
            );
          })}
      </div>
    </>
  );
};

export default InputGrid;
