import API from "api";

export const printPdfDocuments = async (pdfUrls) => {
  try {
    // Chain promises to print each PDF sequentially
    await pdfUrls.reduce(async (previousPromise, url) => {
      await previousPromise; // Wait for the previous PDF to be printed
      // Fetch PDF blob
      const eligData = await API.getFileExternalBlob(url);
      const responseUrl = URL.createObjectURL(eligData);
      // Print PDF document
      await printPdf(responseUrl);
      // Clean up
      URL.revokeObjectURL(responseUrl);
    }, Promise.resolve());
  } catch (error) {
    console.error("Error printing PDF documents:", error);
  }
};

export const printPdf = (url) => {
  return new Promise((resolve) => {
    const iframe = document.createElement("iframe");
    iframe.style.position = "absolute";
    iframe.style.width = "0px";
    iframe.style.height = "0px";
    iframe.style.border = "none";
    iframe.src = url;

    iframe.onload = () => {
      try {
        iframe.contentWindow.focus();
        iframe.contentWindow.print();
        resolve();
      } catch (error) {
        console.error("Error during print:", error);
      }
    };

    document.body.appendChild(iframe);
  });
};
