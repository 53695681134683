import { API_ACTIONS, MESSAGE_MODES } from "constant";
import { AUTH_CURRENT_USER } from "store/features/authentication/authenticationConstant";
import { showMessage } from "../features/general/generalSlice";

const errorMiddleware = (store) => (next) => (action) => {
  const { dispatch } = store;
  if (action.type.endsWith(API_ACTIONS.rejected)) {
    const notToCheck = [AUTH_CURRENT_USER].map((key) => key + "/" + API_ACTIONS.rejected);
    if (!notToCheck.includes(action.type)) {
      dispatch(showMessage({ text: action.error.message, mode: MESSAGE_MODES.error })); // Dispatch setError(false) after async action completes or throws an error
    }
  }
  return next(action);
};

export default errorMiddleware;
