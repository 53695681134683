import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import adminRoutes from "routes/adminRoutes";
import labRoutes from "routes/labRoutes";
// import patientRoutes from "routes/patientRoutes";
// import siteRoutes from "routes/siteRoutes";
// import subAgentRoutes from "routes/subAgentRoutes";
import api from "../../../api";
import { fetchCompanySetting } from "../companySetting/companySettingSlice";
import { fetchAllClients, fetchUserClients } from "../clients/clientsSlice";
import { fetchAllEligibilityFiles } from "../eligibilityFile/eligibilityFileSlice";
import { fetchAllEmployees, fetchEmployeeRecord } from "../employees/employeesSlice";
import { fetchAllLocations, fetchUserLocation } from "../locations/locationsSlice";
import { fetchAllProviders } from "../providers/providersSlice";
import { fetchAllSubAgents, fetchUserSubAgent } from "../subAgents/subAgentsSlice";
import { fetchUsers } from "../users/userAction";
import {
  AUTH,
  AUTH_COMPANIES,
  AUTH_CURRENT_USER,
  AUTH_LISTENER,
  AUTH_SIGN_IN,
  AUTH_ERROR_EMAIL,
} from "./authenticationConstant";

import { Auth } from "aws-amplify";
import { ADMIN_USERS, CONFIG, DEFAULT_WHITE_LABEL_USRERS_PERMISSIONS } from "../../../constant";
import { mergeUserPermissions, userCompanyID } from "../../../utils";

//   Custom Routes Functionality external function

const customRoutes = (routes, response, user) => {
  if (!response) return [];
  if (ADMIN_USERS.includes(response.phone_number)) {
    return routes;
  }

  if (CONFIG.isLabType) {
    let BRMRoutes = routes;
    if (user?.isClient()) {
      BRMRoutes = BRMRoutes.filter((f) => f.path !== "/testtypes");
    }
    return BRMRoutes;
  }

  return routes.filter((f) => f.path !== "/billing");
};

// Send Error email to Dev Service

export const sendEmailToDevAsync = createAsyncThunk(AUTH_ERROR_EMAIL, async (param, { getState }) => {
  const userCompany = getState().clients.clients;
  const loginUser = getState().auth.user;
  const message = `${param} \n\n 
  User: ${loginUser.name} \n 
  phone: ${loginUser.phone_number}`;
  const res = await api.sendEmail([
    { email: "pybertest@gmail.com", subject: `Error on ${userCompany[0].name}`, msg: message },
  ]);
});

// Async thunk to fetch all Companies
export const fetchAllCompanies = createAsyncThunk(AUTH_COMPANIES, async () => {
  const response = await api.fetchAllCompanies();
  return response?.data || [];
});

// Async thunk to Sign in
export const sigInUserAsync = createAsyncThunk(AUTH_SIGN_IN, async ({ username, password }) => {
  const user = await Auth.signIn(username, password);
  const response = await api.getEmployeeRoles(username);
  return { response, user };
});

// Async thunk to get Current User
export const getCurrentUserAsync = createAsyncThunk(AUTH_CURRENT_USER, async () => {
  const user = await api.getCurrentUser(); // Your API call logic here
  let response = null;

  if (user) {
    response = await api.getEmployeeRoles(
      user?.preferred_username?.includes("+") ? user?.preferred_username : user.phone_number
    );
  } else {
    return Promise.reject("User is not defined");
  }

  return { response, user: user };
});

// Async thunk to get Current User Listener

export const getListenerDataAsync = createAsyncThunk(AUTH_LISTENER, async (user, { dispatch, getState }) => {
  const response = getState().auth.data?.response;
  if (user?.isAdmin()) {
    dispatch(
      authenticationSlice.actions.routes(customRoutes(CONFIG.isLabType ? labRoutes : adminRoutes, response, user))
    );
    await dispatch(fetchCompanySetting());
    await dispatch(fetchAllClients());
    await dispatch(fetchAllProviders());
    await dispatch(fetchAllSubAgents());
    await dispatch(fetchAllLocations());
    await dispatch(fetchAllEligibilityFiles());
    await dispatch(fetchAllEmployees());
    dispatch(fetchUsers(null, user));
  }
  if (user?.isClient()) {
    dispatch(
      authenticationSlice.actions.routes(customRoutes(CONFIG.isLabType ? labRoutes : adminRoutes, response, user))
    );
    await dispatch(fetchCompanySetting());
    await dispatch(fetchUserClients());
    await dispatch(fetchAllProviders());
    await dispatch(fetchAllSubAgents());
    await dispatch(fetchAllLocations(true));
    await dispatch(fetchAllEligibilityFiles());
    await dispatch(fetchAllEmployees());
    dispatch(fetchUsers(null, user));
  }
  if (user?.isSite()) {
    await dispatch(fetchCompanySetting());
    await dispatch(fetchUserLocation());
    await dispatch(fetchAllEmployees());
    await dispatch(fetchAllProviders());
    await dispatch(fetchAllClients());
    await dispatch(fetchAllSubAgents());
    await dispatch(fetchAllEligibilityFiles());
    dispatch(authenticationSlice.actions.routes(siteRoutes));
  }
  if (user?.isSubAgent()) {
    await dispatch(fetchCompanySetting());
    await dispatch(fetchUserSubAgent());
    await dispatch(fetchAllEmployees());
    await dispatch(fetchAllProviders());
    await dispatch(fetchAllClients());
    await dispatch(fetchAllLocations());
    await dispatch(fetchAllEligibilityFiles());
    dispatch(authenticationSlice.actions.routes(subAgentRoutes));
  }
  if (user?.isUser()) {
    await dispatch(fetchEmployeeRecord());
    await dispatch(fetchUserLocation());
    await dispatch(fetchUserSubAgent());
    dispatch(authenticationSlice.actions.routes(patientRoutes));
  }
});

const authenticationSlice = createSlice({
  name: AUTH,
  initialState: {
    companies: [],
    userRoutes: [],
    userSessionEnd: false,
    permission: {},
    data: null,
    user: null,
    lab: null,
    countryCode: null,
    clientData: null,
  },
  reducers: {
    routes: (state, action) => {
      state.userRoutes = action.payload;
    },
    logOut: (state, action) => {
      state.data = null;
      state.user = null;
      state.permission = {};
      state.userRoutes = [];
      state.companies = [];
    },
    setPermission: (state, action) => {
      const permission = action.payload ? mergeUserPermissions(JSON.parse(action.payload)) : null;
      state.permission = permission || DEFAULT_WHITE_LABEL_USRERS_PERMISSIONS;
    },
    setGlobalUser: (state, action) => {
      state.user = action.payload;
    },
    setGlobalLab: (state, action) => {
      state.lab = action.payload;
    },
    setCountryCode: (state, action) => {
      state.countryCode = action.payload;
    },
    setClientData: (state, action) => {
      state.clientData = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchAllCompanies.fulfilled, (state, action) => {
        state.companies = action.payload;
      })
      .addCase(sigInUserAsync.pending, (state) => {
        state.userSessionEnd = false;
      })
      .addCase(sigInUserAsync.fulfilled, (state, action) => {
        state.data = action.payload;
        state.userSessionEnd = false;
      })
      .addCase(getCurrentUserAsync.pending, (state) => {
        state.userSessionEnd = false;
      })
      .addCase(getCurrentUserAsync.fulfilled, (state, action) => {
        state.user = action.payload.user;
        state.data = action.payload;
        const response = action.payload.response;
        if (CONFIG.isLabType && response.clientID && !ADMIN_USERS.includes(response.phone_number)) {
          userCompanyID.save(response.clientID);
        }
        state.userSessionEnd = false;
      })
      .addCase(getCurrentUserAsync.rejected, (state, action) => {
        state.userSessionEnd = true;
      });
  },
});

export const { routes, logOut, setPermission, setGlobalUser, setGlobalLab, setCountryCode, setClientData } =
  authenticationSlice.actions;

export default authenticationSlice.reducer;
