import moment from "moment";
import { formatDateOfBirthDOB } from "utils";
import { default as api, default as localApi } from "api";
import {dymoMediumLabel, dymoSmallLabel} from "./dymoXmls"



export const getPatientName = (test) => {
  const getDemographics = (demographics) => {
    if (typeof demographics === "string") {
      try {
        return JSON.parse(demographics);
      } catch (e) {
        return {};
      }
    }
    return demographics || {};
  };

  const employeeDemographics = getDemographics(test?.employee_demographics);
  const orderEmployeeDemographics = getDemographics(test?.order?.employee_demographics);

  const firstName = test?.firstName ?? employeeDemographics.firstName ?? orderEmployeeDemographics.firstName;
  const lastName = test?.lastName ?? employeeDemographics.lastName ?? orderEmployeeDemographics.lastName;

  return `${lastName || ""}, ${firstName || ""}`.trim();
};

export const parseOrderLableXmls = async ({test,selectedSize}) => {
  const { barcode } = test;

  let data;
  if (typeof test?.employee_demographics === "string") {
    data = JSON.parse(test?.employee_demographics);
  } else if (typeof test?.employee_demographics === "object") {
    data = test?.employee_demographics;
  }

  const dob = formatDateOfBirthDOB(data?.dob);

  const swabTypeArray = data?.swabtype ? data?.swabtype?.split(",").map((type) => type.trim()) : [];

  const swabtypeStr = swabTypeArray.join(", ");

  const name = getPatientName(test);

  const collectionDate = moment(test?.createdAt).format("MM/DD/YY HH:mm");

  const testTypeResult = await api.getTestTypesByID(data?.testTypeID);

  const code = testTypeResult?.code || "";

  
  const dymoXmlObj ={
    DymoSmall:dymoSmallLabel({name, dob, collectionDate, swabtypeStr, barcode, code}),
    DymoMedium:dymoMediumLabel({name, dob, collectionDate, swabtypeStr, barcode, code})
  }

  return dymoXmlObj[selectedSize?.labelSize] || dymoXmlObj["DymoMedium"]



};
