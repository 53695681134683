import React, { useEffect, useState, useContext } from "react";
import { formatPhoneNumberIntl } from "react-phone-number-input";
import editIcon from "../assets/img/edit-icon.svg";
import saveIcon from "../assets/img/save-icon.png";
import cancelIcon from "../assets/img/cancel-icon.png";
import Default_Profile from "../assets/img/default_profile.png";
import "bootstrap-daterangepicker/daterangepicker.css";
import { useDispatch, useSelector } from "react-redux";
// react-bootstrap components
import { Button, Card, Form, Container, Row, Col, Modal, Table, CloseButton } from "react-bootstrap";
import { useLocation, useHistory } from "react-router-dom";
import GQAPI from "views/GraphQL/gqApi";
import API from "api";
import SweetAlert from "react-bootstrap-sweetalert";
import { AppContext } from "../context/app-context";
import ErrorMessage from "components/Message/ErrorMessage";
import { TEST_TYPE_VALUE, countryListAllIsoData, IMAGE_UPLOAD_TYPE } from "constant";
import PDFLayout from "components/PDF/PDFLayout";
import userProfileImage from "../assets/img/user-profile.png";
import SwitchToggle from "../components/SwitchToggle/SwitchToggle";
import RadioButton from "../components/RadioButton/RadioButton";

import {
  getDemoGraphics,
  formatZip,
  formatTest,
  isValidPhone,
  setPhoneNo,
  formatPhoneNumber,
  formatDateOfBirthDOB,
  phoneFormatter,
  formatQuaratineDisplayDate,
  parseTestType,
  FormatAWSDate,
  isValidName,
  isValidPhoneWithCode,
  isValidIdNum,
  getValidName,
  convertToLower,
  isValidZipCode,
  validSpace,
  convertToUpper,
  getPhoneNo,
  isValidEmail,
  isValidUrl,
  getConnectedToValue,
  getValidDep,
  getValidSpaces,
  parseInsDetails,
  isValidIDNumber,
} from "../utils";
import "react-activity/dist/Bounce.css";
import { Storage } from "aws-amplify";
import ImageView from "components/Image/ImageView";
import ReactPDF from "@react-pdf/renderer";
import PDF from "components/PDF";
import moment from "moment";
import QuarantineModal from "components/Modal/QuarantineModal";
import ConfirmationModal from "components/Modal/ConfirmationModal";
import SingleDatePicker from "Date/SingleDatePicker";
import PhoneNoInput from "components/Phone/PhoneNoInput";
import TestView from "components/TestView";
import EmployeeLogs from "components/EmployeeLogs";
import ImageInput from "components/Input/ImageInput";
import "react-tabs/style/react-tabs.css";
import Loader from "../components/Loader/Loader";
import AutoCompleteAddress from "components/AutoCompleteAddress";
import { newRegisterObj } from "constant";
import { PROFILE_IMAGE_PATH } from "constant";
import ProfileRelationModal from "views/Patient/ProfileRelationModalOnModal";
import api from "api";
import RelationView from "components/Profile/RelationView";
import DocumentList from "components/Document/DocumentList";
import DocumentForm from "components/Document/DocumentForm";
import { INSURANCE_PROVIDER } from "constant";
import InputField from "components/InputField/InputField";
import { EMP_INS_OBJECT } from "constant";
import InsuranceViewCard from "components/Patient/InsuranceViewCard";
import InsuranceDetailsModal from "components/Patient/InsuranceDetailsModal";
import { CUSTOM_INS } from "constant";
import { NEW_PROVIDER_OBJECT } from "constant";
import ProviderModal from "components/Provider/ProviderModal";
import { fetchAllProviders } from "store/features/providers/providersSlice";
let actualProfilePath = "";

const PatientProfile = (props) => {
  const location = useLocation();
  const history = useHistory();
  const { state } = location;
  const [test, setTest] = useState();
  const [site, setSite] = useState();
  const [lab, setLab] = useState();
  const [siteContactPhone, setSiteContactPhone] = useState();
  const [demographics, setDemographics] = useState();
  const [showAlert, setShowAlert] = useState(false);
  const [modalShow, setModalShow] = useState(false);
  const [releaseTo, setReleaseTo] = useState([]);
  const [testsLab, setTestsLab] = useState();
  const [testPdf, setTestPdf] = useState();
  const [showPdf, setShowPdf] = useState(false);
  const [signature, setSignature] = useState();
  const [loading, setLoading] = useState(false);
  const [hippaConsent, setHippaConsent] = useState(false);
  const [showImages, setShowImages] = useState(null);
  const [showSubmitAlert, setShowSubmitAlert] = useState(false);
  const [editButton, setEditButton] = useState(false);
  const [editInfo, setEditInfo] = useState();
  const [Employee, setEmployee] = useState();
  const [error, setError] = useState("");
  const [openQuarantined, setOpenQuaratined] = useState(false);
  const [allowToEditQuarantine, setAllowToEditQuarantine] = useState(false);
  const [openDataChangeConfirmation, setOpenDataChangeConfirmation] = useState(false);
  const [patternConsent, setPatternConsent] = useState(false);
  const [phoneError, setPhoneError] = useState("");
  const [testViews, setTestViews] = useState([]);
  const [employeeLogs, setEmployeeLogs] = useState([]);
  const [loadingTestView, setLoadingTestView] = useState(false);
  const [vaccinationImage, setVaccinationImage] = useState("");
  const [externalTestImage, setExternalTestImage] = useState("");
  const [changeData, setchangeData] = useState({});
  const [changeDataCheck, setchangeDataCheck] = useState(true);
  const appContext = useContext(AppContext);
  const [profileImage, setProfileImage] = useState(Default_Profile);
  const [showProfileModal, setProfileModal] = useState(false);
  const [openDocumentModal, setOpenDocumentModal] = useState(null);
  const [selectedDocument, setSelectedDocument] = useState({ isNew: true });
  const [documents, setDocuments] = useState([]);
  const [openInsuranceModal, setOpenInsuranceModal] = useState(null);
  const [providers, setProviders] = useState([]);
  const [newProviderInfo, setNewUserProvider] = useState(null);

  const dispatch = useDispatch();

  const hideAlert = () => {
    props.history.push("/admin/pendingrelease");
    setShowAlert(false);
  };

  const handleUpdateAddress = (val, flag) => {
    if (!flag) {
      setEmployee({ ...Employee, street: val });
    } else {
      const addressObj = {
        street: val.street,
        country: val.country,
        city: val.city,
        zip: val.zipcode,
        state: val.state,
        isoCode: val.isoCode,
      };
      setEmployee({ ...Employee, ...addressObj });
    }
  };

  const triggerNewData = (data) => {
    const {
      match: { params },
    } = props;
    if (data.id !== params.patientId) return;
    if (!editButton) {
      setchangeData(data);
      setOpenDataChangeConfirmation(true);
    } else {
      setEditInfo(data);
      setDemographics(getDemoGraphics(data));
      setEmployee(getDemoGraphics(data));
      setTest(data);
    }
  };

  // SETTING UP LISTNER
  useEffect(() => {
    if (changeDataCheck && appContext?.testObject && appContext.testObject?.opType === "UPDATE") {
      triggerNewData(appContext.testObject.element);
    }
  }, [appContext?.testObject]);

  // SETTING UP LISTNER
  useEffect(() => {
    if (changeDataCheck && appContext?.eventObject && appContext.eventObject?.opType === "UPDATE") {
      triggerNewData(appContext.eventObject.element);
    }
  }, [appContext?.eventObject]);

  const getProviders = async (test) => {
    try {
      dispatch(fetchAllProviders());
      const response = await api.fetchProvidersAPI(test);
      if (response && response.length > 0) {
        let arr = [];
        for (let val of response) {
          arr.push({
            ...val,
            label: getValidDep(val.name),
            value: val.id,
          });
        }
        setProviders(arr);
      }
    } catch (err) {
      console.log("getProviders Error:-", err);
    }
  };

  const handleDataChangeConfirm = async (isConfirm) => {
    if (isConfirm) {
      setOpenDataChangeConfirmation(false);
      setEditInfo(changeData);
      setDemographics(getDemoGraphics(changeData));
      setEmployee(getDemoGraphics(changeData));
      setTest(changeData);
    } else {
      setOpenDataChangeConfirmation(false);
    }
  };

  const getTest = async (id) => {
    try {
      const model = await API.getRecordById(id, "employee");

      if (model.length > 0) {
        model[0]["employee_demographics"] = model[0];

        if (model[0]?.id) {
          let models = await API.getRecordById(model[0].id, "employee");
          let vacinatedFilePath = models[0]?.vaccinationCardImage;
          let imageProfileNew = models[0]?.profileImage;
          let externalTestFilePath = models[0]?.externalTestImage;
          let autoRelease = models[0]?.autoRelease || models[0]?.autoRelease == "1" ? true : false;
          let sendSMS = hasKeyOfSMS(model[0]);
          let sendEmail = hasKeyOfEmail(model[0]);
          //console.log("autoRelease sendSMS sendEmail", autoRelease + " " + sendSMS + " " + sendEmail);
          setEmployee({
            ...Employee,
            vaccinationCardImage: vacinatedFilePath,
            externalTestImage: externalTestFilePath,
            autoRelease,
            sendSMS,
            sendEmail,
          });
          model[0].sendSMS = sendSMS;
          model[0].sendEmail = sendEmail;
          if (externalTestFilePath) {
            let getExternalTestImage = await API.getFileExternalStorage(externalTestFilePath);
            setExternalTestImage(getExternalTestImage);
          }
          if (vacinatedFilePath) {
            let getVacinationCardImage = await API.getFileExternalStorage(vacinatedFilePath);
            setVaccinationImage(getVacinationCardImage);
          }
          if (imageProfileNew) {
            let path = `${model[0]?.phone_number}-profile-image.png`;
            if (imageProfileNew.includes("_#datePattern#_")) {
              path = imageProfileNew;
            }

            actualProfilePath = path;
            setProfileImage(`${PROFILE_IMAGE_PATH}${encodeURIComponent(path)}`);
          }
        }

        setEditInfo(getDemoGraphics(model[0]));
        setDemographics(getDemoGraphics(model[0]));
        setEmployee(getDemoGraphics(model[0]));
        setTest(model[0]);
      }
    } catch (err) {
      console.log("Error:-", err);
    }
  };

  const {
    match: { params },
  } = props;

  useEffect(() => {
    setLoading(true);
    const {
      match: { params },
    } = props;
    getTest(params.patientId);
  }, []);

  useEffect(() => {
    if (appContext.user) setAllowToEditQuarantine(appContext.user.isSite() || appContext.user.isClient());
  }, [appContext.user]);

  useEffect(async () => {
    if (test && Object.keys(test).length > 0) {
      handleViewTest(test.id);
      handleEmployeeLogs(test.id);
      try {
        const res = await api.fetchDocumentAPI({ employeeID: Employee.id });
        setDocuments(res);
        await getProviders(test);
      } catch (err) {
        console.log("Error:-", err);
      }
    }
  }, [test]);

  const hasKeyOfSMS = (model) => {
    if (model.hasOwnProperty("sendSMS")) {
      let sendSMS = model?.sendSMS || model.sendSMS == "1" ? true : false;
      return sendSMS;
    }
    return true;
  };

  const hasKeyOfEmail = (model) => {
    if (model.hasOwnProperty("sendEmail")) {
      let sendEmail = model?.sendEmail || model.sendEmail == "1" ? true : false;
      return sendEmail;
    }
    return true;
  };

  // const getFileFromStorage = async (file, setImage) => {
  //   if (file) {
  //     try {
  //       const path = await Storage.get(file);
  //       setImage(path);
  //       console.log("path", path);
  //       return true;
  //     } catch {
  //       console.log("Image loading fail");
  //     }
  //   } else {
  //     return false;
  //   }
  // };

  // useEffect(async () => {

  // }, []);

  const handlePhoneNo = (e) => {
    setEmployee({
      ...Employee,
      phone_number: e,
    });
  };

  const handleViewTest = async (id) => {
    setLoadingTestView(true);
    const tests = await API.getAdminTestList(id);
    setTestViews(tests.filter((t) => !t._deleted));
    setLoadingTestView(false);
  };

  // handle employee logs
  const handleEmployeeLogs = async (id) => {
    let employeeLogs = await API.getProfileUpdateLogs(id);
    employeeLogs = employeeLogs.sort((a, b) => (b.createdAt > a.createdAt ? 1 : a.createdAt > b.createdAt ? -1 : 0));
    setEmployeeLogs(employeeLogs);
  };

  useEffect(() => {
    if (editInfo && editInfo.employee_demographics) {
      const emp = getDemoGraphics(editInfo);
      const list = countryListAllIsoData.find((f) => f.name === emp.country) || { code: "US" };
      setEmployee(emp);
      setDemographics({ ...emp, isoCode: list.code });
    }
  }, [editInfo]);

  useEffect(() => {
    const getSite = async (id) => {
      const model = await API.findSiteByID(id);
      model.length > 0 && setSite(model[0]);
    };

    const getSignature = async (test) => {
      const imgObject = [];

      try {
        const siteID = test.baseSiteID || test.siteID;
        const fileName = `${siteID}&&${test.id}&&sig.png`;
        const sigt = await Storage.get(fileName);
        setSignature(sigt);
        imgObject.push({ title: "HIPPA Consent", isHIPPA: true, imgSrc: sigt });

        const sig = await Storage.get(`${test.id}&&${siteID}&&ins-f.jpeg`);
        const isValidInsF = await isValidUrl(sig);
        if (sig) imgObject.push({ title: "Insurance Card Front", isHIPPA: false, imgSrc: sig, isExist: isValidInsF });
        const sig1 = await Storage.get(`${test.id}&&${siteID}&&ins-b.jpeg`);
        const isValidInsB = await isValidUrl(sig1);
        if (sig1) imgObject.push({ title: "Insurance Card Back", isHIPPA: false, imgSrc: sig1, isExist: isValidInsB });
      } catch (error) {
        console.log("Image Error", error);
      }

      if (imgObject.length > 0) {
        setShowImages(imgObject);
      }
      setHippaConsent(true);
    };

    // if (test) {
    //   setSite(getSite(test.siteID));
    //   appContext.getLab(test).then((resp) => setLab(resp));
    //   getSignature(test);
    // }

    setLoading(false);
  }, [test]);

  useEffect(() => {
    site && setSiteContactPhone(site.contact_phone);
  }, [site]);

  function calculate_age(dob) {
    const val = formatDateOfBirthDOB(dob);
    const years = moment().diff(val, "years", false);
    return years;
  }

  const captalizeFirst = (string) => {
    return string.charAt(0).toUpperCase() + string.slice(1);
  };

  const formatIDNo = () => {
    if (calculate_age(demographics?.dob) < 18) {
      const id = demographics?.id_number;
      if (id && id.length > 5) {
        return `${id.substring(0, 3)}-${id.substring(3, 5)}-${id.substring(5)}`;
      }
      if (id && id.length > 3) {
        return `${id.substring(0, 3)}-${id.substring(3)}`;
      }
      return demographics?.id_number;
    }
    return demographics?.id_number;
  };

  const showSubmitButton = () => {
    if (!test) return true;
    const st = test.status.toLowerCase();
    if (st === "new") return true;
    if (st === "pending" && !test.result) return true;
    return false;
  };
  const ImageUpload = async (fileName, image) => {
    if (image instanceof Object) {
      await Storage.put(fileName, image);
      return fileName;
    } else {
      return "";
    }
  };

  const handleSave = async (Employee) => {
    let phone_number = "";
    let countryCode = "";
    if (!Employee) return;

    if (Employee.insuranceDetails?.length > 0) {
      const ins = Employee.insuranceDetails[0];
      Object.assign(Employee, {
        ...Employee,
        insurance_name: ins.insuranceCompanyCode,
        medicalNo: ins.medicareNo,
        insurance_number: ins.medicalNo,
        insuranceCompanyCode: ins.insuranceCompany,
      });
    }

    if (!Employee.first) {
      setError("First name is required");
      return;
    }
    if (!isValidName(Employee.first)) {
      setError("First name is invalid must contains at least 2 letters and Name should be realistic");
      return;
    }
    if (!Employee.last) {
      setError("Last name is required");
      return;
    }
    if (!isValidName(Employee.last)) {
      setError("Last name is invalid must contains at least 2 letters and Name should be realistic");
      return;
    }

    if (Employee.insuranceCompanyCode === "00001") {
      if (!Employee.email || !Employee.email.trim()) {
        setError("Email Address is required");
        return;
      }

      if (!isValidEmail(Employee.email)) {
        setError("Invalid Email Address");
        return;
      }

      if (!Employee.phoneNumber || Employee.phoneNumber === " ") {
        setError("Phone number is required");
        return;
      }
    }

    if (!Employee.dob) {
      setError("Date of Birth is required");
      return;
    }

    if (!Employee.sex) {
      setError("Required gender");
      return;
    }

    if (!Employee.insuranceCompanyCode) {
      setError("Insurance Provider is required");
      return;
    }

    if (!CUSTOM_INS.includes(Employee.insuranceCompanyCode)) {
      if (!Employee.insurance_number || !Employee.insurance_number.trim()) {
        setError(`Insurance Number is required`);
        return;
      }

      if (!isValidIDNumber(Employee.insurance_number)) {
        setError(`Insurance Number is invalid`);
        return;
      }

      if (Employee.isMedicare === "Y" && !Employee.medicalNo) {
        setError("Medicare Number Not be Empty");
        return;
      }
      if (Employee.isMedicare === "Y" && !isValidIDNumber(Employee.medicalNo)) {
        setError("Medicare Number is invalid");
        return;
      }
    }
    // FOR PHONE CHECK COUTRY CODE FOR INTERNATIONAL RECORD VERIFICATION
    const phone = formatPhoneNumberIntl(Employee.phone_number);
    const phoneArr = phone.split(" ");
    // check Country code exist

    if (phoneArr[0].includes("+")) {
      let [cc, pNo] = getPhoneNo(Employee.phone_number, phoneArr[0]);
      phone_number = pNo;
      countryCode = cc;
    } else {
      phone_number = Employee.phone_number;
      countryCode = Employee.countryCode ? Employee.countryCode : "+1";
    }

    if (phoneError || !isValidPhoneWithCode(`${countryCode}${phone_number}`)) {
      setError("Invalid Phone Number");
      return;
    }
    if (!Employee.dob) {
      setError("DOB is required");
      return;
    }
    if (Employee.dob === "Invalid date") {
      setError("Invalid Date of Birth");
      return;
    }
    if (!isValidZipCode(demographics?.isCode || "US", Employee.zip)) {
      setError("Invalid Postal Code");
      return;
    }
    if (!Employee.id_number) {
      setError(`${calculate_age(Employee.dob) < 18 ? "SSN" : "ID Number"} is required`);
      return;
    }
    if (!isValidIdNum(Employee.id_number)) {
      setError(`Invalid ${calculate_age(Employee.dob) < 18 ? "SSN" : "ID Number"} must contains at least 5 characters`);
      return;
    }

    setLoading(true);
    setchangeDataCheck(false);

    const models = await API.getRecordById(test.id, "employee");
    const versionNo = models[0]?._version || null;
    //const filename = await API.getFileProfileStorage(imageName)
    // Vacination Image
    let vaccinationFileName = "";
    if (typeof vaccinationImage === "object") {
      vaccinationFileName = await API.saveFileExternalStorage(
        phone_number,
        test.id,
        IMAGE_UPLOAD_TYPE.VacinationCard,
        vaccinationImage
      );
    }
    // External Test Image
    let externalImageFileName = "";
    if (typeof externalTestImage === "object") {
      externalImageFileName = await API.saveFileExternalStorage(
        phone_number,
        test.id,
        IMAGE_UPLOAD_TYPE.ExternalTest,
        externalTestImage
      );
    }

    if (profileImage && typeof profileImage === "object") {
      let imageFormatWithUtcTime = `_#datePattern#_${moment().utc().format("YYYY-MM-DD HH:mm")}`;
      const imageName = `${Employee?.phone_number}-profile-image${imageFormatWithUtcTime}.png`;
      await Storage.put(`profile/${imageName}`, profileImage, { bucket: "medflow-images" });
      Employee.profileImage = imageName;
      setProfileImage(`${PROFILE_IMAGE_PATH}${encodeURIComponent(imageName)}`);
    } else if (profileImage && profileImage !== Default_Profile && Employee?.profileImage) {
      Employee.profileImage = actualProfilePath;
    } else {
      Employee.profileImage = "";
    }
    // return;

    const emp = {
      ...Employee,
      countryCode: countryCode,
      vaccinationCardImage: vaccinationFileName ? vaccinationFileName : Employee.vaccinationCardImage,
      externalTestImage: externalImageFileName ? externalImageFileName : Employee.externalTestImage,
      _version: versionNo,
      updatedBy: appContext.user.sub,
      updatedByName: appContext.user.name,
      phone_number: phone_number,
    };

    try {
      const updateEmployee = await API.updateEmployeeProfile(emp);
      if (updateEmployee) await API.patientUpdateAPI(updateEmployee);
      setEditInfo(updateEmployee);
      setTest(updateEmployee);
      setEmployee(getDemoGraphics({ employee_demographics: updateEmployee }));
      setDemographics(getDemoGraphics({ employee_demographics: updateEmployee }));
      switch (location?.state?.type) {
        case "pendingRelease":
          appContext.resetPendingTests();
          break;
        case "inconclusiveTest":
          appContext.resetInvalidTests();
          break;
      }

      appContext.showSuccessMessage("Employee updated Successfully");
      const {
        match: { params },
      } = props;

      getTest(params.patientId);
      setLoading(false);
    } catch (err) {
      console.log("Error in crew save", err);
      appContext.showErrorMessage(err.message);
      setLoading(false);
    }
    setTimeout(() => {
      setchangeDataCheck(true);
    }, 60000);
  };
  const handelCancelButton = async () => {
    setEditButton(true);

    if (!Employee) return;
    if (!editInfo?.profileImage) {
      setProfileImage(Default_Profile);
    }
    if (!editInfo?.externalTestImage) {
      setExternalTestImage("");
    }
    if (!editInfo?.vaccinationCardImage) {
      setVaccinationImage("");
    }
    setEmployee(getDemoGraphics(editInfo));

    // const {
    //   match: { params },
    // } = props;
    // getTest(params.employeeId);

    let getExternalTestImage = await API.getFileExternalStorage(Employee?.externalTestImage);
    if (!getExternalTestImage) setExternalTestImage("");
    let getVacinationCardImage = await API.getFileExternalStorage(Employee?.vaccinationImage);
    if (!getVacinationCardImage) setVaccinationImage("");
  };
  const emailTest = (t) => {
    setLoading(true);
    const test = formatTest(t);
    appContext.getLab(test).then(async (resp) => {
      ReactPDF.pdf(<PDF test={test} testsLab={resp} parseTestResultForPDF={appContext.parseTestResultForPDF} />)
        .toBlob()
        .then(async (data) => {
          try {
            const fileName = `${test.id}-result.pdf`;
            await Storage.put(fileName, data);
            await API.sendEmailToCrew([{ email: test.email, fileName: `public/${fileName}` }]);
            await API.updateEmailStatus(test.id);
            // send sms as well if email is sending first time
            if (!test.emailSend && test.phone_number) {
              let smsMessage = `Hello, your test results are in for your test with ${test.site_name}, please log into Med Flow to review.`;
              const siteObj = appContext.sites.find((s) => s.id === test.siteID);
              if (siteObj && siteObj.messageResulted && result === "negative") {
                smsMessage = siteObj.messageResulted;
              } else if (siteObj && siteObj.messagePositive && result === "positive") {
                smsMessage = siteObj.messagePositive;
              }
              await API.sendSMSNotification(setPhoneNo(test.phone_number), smsMessage);
            }
            setLoading(false);
            appContext.showSuccessMessage("Email has been sent successfully");
          } catch (error) {
            console.log("Error");
            setLoading(false);
          }
        });
    });
  };

  const handleSaveQuarantine = async (obj) => {
    try {
      await API.updateQuarantineData(test.id, obj.startDate, obj.endDate);
      getTest(test.id);
      window.location.reload();
      appContext.showSuccessMessage("Quarantine dates changed successfully");
    } catch (err) {
      appContext.showErrorMessage(err.message);
    }
  };

  const isPatternTest = () =>
    test && test.test_type === "PCR" && test.patternTestAnswer && test.patternTestAnswer.length > 5;

  const downloadBlob = (blob, filename) => {
    const url = URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.href = url;
    a.download = filename || "download";
    const clickHandler = () => {
      setTimeout(() => {
        URL.revokeObjectURL(url);
        a.removeEventListener("click", clickHandler);
      }, 150);
    };
    a.addEventListener("click", clickHandler, false);
    a.click();
    return a;
  };
  const patternConsentFile = async () => {
    if (!test) return "";
    const siteID = test.baseSiteID || test.siteID;
    const fileName = `${siteID}-${test.id}-pattern.pdf`;
    const result = await Storage.get(fileName, { download: true });
    downloadBlob(result.Body, `${test.barcode}-pattern.pdf`);
  };

  const dataOfBirthParse = (dob) => {
    if (dob && dob !== "Invalid date") {
      return formatDateOfBirthDOB(dob);
    }
    return moment().format("MM/DD/YYYY");
  };

  const showResultPDF = async (test) => {
    const empID = await appContext.getEmployeeID(test.employeeID);
    const t = test;
    t.empID = empID;
    appContext.getLab(test).then(async (resp) => {
      setTestsLab(resp);
      setShowPdf(true);
    });
    setTestPdf(formatTest(test));
  };

  const handleRadioButtonChange = (e) => {
    if (e.target.name === "isVaccinated") {
      if (e.target.value === "yes") {
        setEmployee({ ...Employee, isVaccinated: e.target.checked && true });
      } else {
        setEmployee({ ...Employee, isVaccinated: e.target.checked && false });
      }
    } else {
      if (e.target.value === "yes") {
        setEmployee({ ...Employee, isBooster: e.target.checked && true });
      } else {
        setEmployee({ ...Employee, isBooster: e.target.checked && false });
      }
    }
  };

  const handleToogleChange = (e) => {
    if (e.target.name === "sendSMS") {
      if (Employee?.sendSMS == "1") {
        setEmployee({ ...Employee, sendSMS: Employee?.sendSMS == "1" ? false : true });
      } else {
        setEmployee({ ...Employee, sendSMS: Employee?.sendSMS == "0" ? true : false });
      }
    }
    if (e.target.name === "sendEmail") {
      if (Employee?.sendEmail == "1") {
        setEmployee({ ...Employee, sendEmail: Employee?.sendEmail == "1" ? false : true });
      } else {
        setEmployee({ ...Employee, sendEmail: Employee?.sendEmail == "0" ? true : false });
      }
    }
    if (e.target.name === "autoRelease") {
      if (Employee?.autoRelease == "1") {
        setEmployee({ ...Employee, autoRelease: Employee?.autoRelease == "1" ? false : true });
      } else {
        setEmployee({ ...Employee, autoRelease: Employee?.autoRelease == "0" ? true : false });
      }
    }
  };

  const makeRelationModal = (e) => {
    console.log("clicked::", e);
    e.preventDefault();
    e.stopPropagation();
    setProfileModal(!showProfileModal);
  };

  return showPdf ? (
    <PDFLayout setShowPdf={setShowPdf} testPdf={testPdf} testsLab={testsLab} />
  ) : !loading ? (
    <>
      <div className="parent-wrapper">
        <SweetAlert show={showAlert} success title="Success" onConfirm={hideAlert}>
          Test Released
        </SweetAlert>
        <SweetAlert
          show={showSubmitAlert}
          success
          title="Success"
          onConfirm={() => {
            window.history.back();
            setShowSubmitAlert(false);
          }}
        >
          Test Submitted Successfully.
        </SweetAlert>
        <div className="crew-member-profile">
          <div className="crew-member-profile-inner">
            <div className="d-md-flex  justify-content-between">
              <Col md="3" className="wrapper-profile">
                <div className="card-body px-3 pt-3">
                  <div className="d-flex align-items-center justify-content-between">
                    <h4 className="profile-title my-0 text-black">
                      {" "}
                      Patient Profile
                      {calculate_age(demographics?.dob) < 18 ? <span className="crew-minor-text">- Minor</span> : null}
                    </h4>
                    {/* {
                        editButton &&
                        <img src={editIcon} alt="edit icon" className="d-inline-block edit-icon"  onClick={() => setEditButton(false)} />
                      }
                      {
                    
                        !editButton && (
                            <div>    
                              <img src={saveIcon} alt="save icon" className="d-inline-block edit-icon cursor-pointer me-2"  onClick={handleSave} />
                              <img src={cancelIcon} alt="cancel icon" className="d-inline-block edit-icon cursor-pointer"  onClick={handelCancelButton} />
                            </div>
                        )
                      } */}
                  </div>
                  <div className="profile-image mt-4 position-relative text-center">
                    <div className="crew-image-wrapper">
                      <figure className="profile-image-wrapper">
                        {!editButton ? (
                          <ImageInput
                            type="profile"
                            setSelectedImage={setProfileImage}
                            selectedImage={profileImage}
                            cameraIcon
                          />
                        ) : (
                          <div className="">
                            <img src={profileImage} alt="profile-image" />
                          </div>
                        )}
                      </figure>
                    </div>
                    <p className="text-center mt-3 text-black mb-0">{`${Employee?.first} ${Employee?.last}`}</p>
                    <small className="mb-0 text-center text-grey">ID Number: {Employee?.id_number}</small>
                    <br />
                    <small className="mb-0 text-center text-primary-color">{Employee?.email}</small>
                  </div>
                  <hr className="horizontal-line" />
                  <div className="settings-profile">
                    <h4 className="profile-title">Settings</h4>
                    <div className="d-flex align-items-center justify-content-between profile-detail mb-3">
                      <span>Send SMS</span>
                      <SwitchToggle
                        id="sendSMS"
                        name="sendSMS"
                        handleToogleChange={handleToogleChange}
                        checked={Employee?.sendSMS == "1" ? true : false}
                        disabled={editButton}
                      />
                    </div>
                    <div
                      className="d-flex align-items-center justify-content-between profile-
                          detail mb-3"
                    >
                      <span>Send Email</span>
                      <SwitchToggle
                        id="sendEmail"
                        name="sendEmail"
                        handleToogleChange={handleToogleChange}
                        checked={Employee?.sendEmail == "1" ? true : false}
                        disabled={editButton}
                      />
                    </div>
                    <div
                      className="d-flex align-items-center justify-content-between profile-
                          detail mb-3"
                    >
                      <span>Auto Release</span>
                      <SwitchToggle
                        id="autoRelease"
                        name="autoRelease"
                        handleToogleChange={handleToogleChange}
                        checked={Employee?.autoRelease == "1" ? true : false}
                        disabled={editButton}
                      />
                    </div>
                    <h4 className="profile-title">Notes</h4>
                    <div className="mb-3">
                      <textarea
                        readOnly={editButton}
                        className="form-control"
                        onChange={(e) => setEmployee({ ...Employee, note: e.target.value })}
                        value={Employee?.note}
                        defaultValue={demographics?.note}
                        placeholder={`${!editButton ? "Enter Notes" : ""}`}
                        type="text"
                      ></textarea>
                    </div>
                  </div>
                </div>
              </Col>
              <Col md="9" className="border-left-1">
                <CloseButton
                  onClick={() => history.push("/admin/patient")}
                  variant="black"
                  className="text-right p-2 d-flex ms-auto mx-2"
                />
                <div className="personal-info-wrapper pt-3 px-3">
                  <div className="row">
                    <div className="col-md-4">
                      <h4 className="text-decoration-underline text-black mt-0">Personal Info</h4>
                    </div>
                    {/* <div className="col-md-4">
                      {error && <ErrorMessage error={error} handleChange={() => setError("")} />}
                    </div> */}
                    <div className="col-md-4"></div>
                  </div>
                  <div className="testAdmin createClientsWrapper">
                    <div className="crew-profile-info">
                      <InputField
                        type="text"
                        inputStyle="modalInput"
                        labelStyle="mt-0 modalLineHeaders text-capitalize"
                        label="First Name"
                        value={Employee?.first || ""}
                        index="first"
                        maxLength={100}
                        placeholder="Enter First Name"
                        disabled={editButton}
                        handleChange={(e) =>
                          setEmployee({
                            ...Employee,
                            first: getValidDep(e.target.value),
                          })
                        }
                        required
                      />
                      <InputField
                        type="text"
                        inputStyle="modalInput"
                        labelStyle="mt-0 modalLineHeaders text-capitalize"
                        label="Middle Name"
                        value={Employee?.middle || ""}
                        index="middle"
                        maxLength={100}
                        placeholder="Enter Middle Name"
                        disabled={editButton}
                        handleChange={(e) =>
                          setEmployee({
                            ...Employee,
                            middle: getValidDep(e.target.value),
                          })
                        }
                      />
                      <InputField
                        type="text"
                        inputStyle="modalInput"
                        labelStyle="mt-0 modalLineHeaders text-capitalize"
                        label="Last Name"
                        value={Employee?.last || ""}
                        index="last"
                        maxLength={100}
                        placeholder="Enter Last Name"
                        disabled={editButton}
                        handleChange={(e) =>
                          setEmployee({
                            ...Employee,
                            last: getValidDep(e.target.value),
                          })
                        }
                        required
                      />

                      <InputField
                        type="email"
                        inputStyle="modalInput"
                        labelStyle="mt-0 modalLineHeaders text-capitalize"
                        label="Email"
                        value={Employee?.email || ""}
                        index="email"
                        maxLength={25}
                        placeholder="Enter Email"
                        disabled={editButton}
                        handleChange={(e) =>
                          setEmployee({
                            ...Employee,
                            email: convertToLower(e.target.value),
                          })
                        }
                      />
                    </div>
                    <div className="crew-profile-row2 align-items-center">
                      <InputField
                        type="phone"
                        inputStyle={`employee-phone-input ${Employee?.isNew && "pd-right-30"}`}
                        labelStyle="modalLineHeaders mt-0 text-capitalize"
                        label="Phone Number"
                        value={formatPhoneNumber(Employee?.phone_number, Employee?.countryCode || "+1")}
                        index="phone_number"
                        maxLength={65}
                        placeholder="Enter Phone Number"
                        disabled={editButton}
                        handleChange={handlePhoneNo}
                      />
                      <div className="form-group-wrapper my-0 flex-2">
                        <label className="my-0 modalLineHeaders">
                          Gender <span className="fw-bold fs-6 maroon">*</span>
                        </label>
                        <div className="d-flex justify-content-between w-100 flex-wrap">
                          <RadioButton
                            name="sex"
                            id={`male${Math.random()}`}
                            label="Male"
                            checked={Employee?.sex === "M" ? true : false}
                            handleRadioButtonChange={(e) =>
                              setEmployee({
                                ...Employee,
                                sex: e.target.checked ? "M" : "",
                              })
                            }
                            disabled={editButton}
                          />
                          <RadioButton
                            name="sex"
                            id={`female${Math.random()}`}
                            checked={Employee?.sex === "F" ? true : false}
                            label="Female"
                            handleRadioButtonChange={(e) =>
                              setEmployee({
                                ...Employee,
                                sex: e.target.checked ? "F" : "",
                              })
                            }
                            disabled={editButton}
                          />
                          <RadioButton
                            id={`other${Math.random()}`}
                            checked={Employee?.sex === "X" ? true : false}
                            name="sex"
                            label="Not Specified"
                            handleRadioButtonChange={(e) =>
                              setEmployee({
                                ...Employee,
                                sex: e.target.checked ? "X" : "",
                              })
                            }
                            disabled={editButton}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="crew-profile-row2">
                      <InputField
                        type="address"
                        groupWrapper="form-group-wrapper w-100"
                        inputStyle="modalInput"
                        labelStyle="modalLineHeaders text-capitalize"
                        label="Address"
                        value={Employee?.street || ""}
                        index="street"
                        maxLength={255}
                        placeholder="Enter Address"
                        disabled={editButton}
                        handleChange={(val, flag) => handleUpdateAddress(val, flag)}
                      />
                      <InputField
                        type="text"
                        groupWrapper="form-group-wrapper w-100"
                        inputStyle="modalInput"
                        labelStyle="mt-0 modalLineHeaders text-capitalize"
                        label="Apt/Unit#"
                        value={Employee?.street2 || ""}
                        index="street2"
                        maxLength={255}
                        placeholder="Enter Apt/Unit"
                        disabled={editButton}
                        handleChange={(e) => setEmployee({ ...Employee, street2: e.target.value })}
                      />
                      <InputField
                        type="text"
                        groupWrapper="form-group-wrapper w-100"
                        inputStyle="modalInput"
                        labelStyle="mt-0 modalLineHeaders text-capitalize"
                        label="City"
                        value={Employee?.city || ""}
                        index="city"
                        maxLength={65}
                        placeholder="Enter City"
                        disabled={editButton}
                        handleChange={(e) => setEmployee({ ...Employee, city: e.target.value })}
                      />
                    </div>
                    <div className="crew-profile-row2">
                      <InputField
                        type="text"
                        groupWrapper="form-group-wrapper w-100"
                        inputStyle="modalInput"
                        labelStyle="mt-0 modalLineHeaders text-capitalize"
                        label="State"
                        value={Employee?.state || ""}
                        index="state"
                        maxLength={2}
                        placeholder="Enter State"
                        disabled={editButton}
                        handleChange={(e) => {
                          let val = e.target.value;
                          if (val) val = val.toUpperCase();
                          setEmployee({
                            ...Employee,
                            state: getValidSpaces(val),
                          });
                        }}
                      />
                      <InputField
                        type="text"
                        groupWrapper="form-group-wrapper w-100"
                        inputStyle="modalInput"
                        labelStyle="mt-0 modalLineHeaders text-capitalize"
                        label="Zip Code"
                        value={Employee?.zip || ""}
                        index="zip"
                        maxLength={10}
                        placeholder="Enter Zip Code"
                        disabled={editButton}
                        handleChange={(e) => {
                          let val = e.target.value;
                          if (val) val = val.toUpperCase();
                          setEmployee({
                            ...Employee,
                            zip: Employee.isoCode === "US" ? val : getValidSpaces(val),
                          });
                        }}
                      />

                      <InputField
                        type="datePicker"
                        inputStyle="w-100 orderTestDob"
                        labelStyle="modalLineHeaders mt-0 text-capitalize"
                        label="Date of Birth"
                        value={
                          Employee?.dob && new Date(Employee.dob) != "Invalid Date"
                            ? moment(Employee.dob, "YYYY-MM-DD").toDate()
                            : null
                        }
                        index="dob"
                        placeholder="Date of Birth"
                        disabled={editButton}
                        handleChange={(e) =>
                          setEmployee({
                            ...Employee,
                            dob: moment(e).format("YYYY-MM-DD") != "Invalid date" ? moment(e).format("YYYY-MM-DD") : "",
                          })
                        }
                        required
                      />
                      {/* 
                      <Form.Group className="form-group-wrapper" readOnly={editButton} plaintext>
                        {calculate_age(demographics?.dob) > 18 ? (
                          <label className="modalLineHeaders mt-0 text-capitalize">ID Number</label>
                        ) : (
                          <label>SSN</label>
                        )}
                        <Form.Control
                          readOnly={editButton}
                          className={`${!editButton ? "modalInput" : ""}`}
                          value={Employee?.id_number}
                          onChange={(e) => setEmployee({ ...Employee, id_number: e.target.value })}
                          defaultValue={formatIDNo()}
                          placeholder="ID Number"
                          type="text"
                        />
                      </Form.Group> */}
                    </div>
                    <div className="crew-profile-row2">
                      <InputField
                        type="dropDown"
                        inputStyle="w-100"
                        labelStyle="mt-0 modalLineHeaders text-capitalize"
                        label="Rendering Provider"
                        options={providers}
                        value={
                          Employee?.renderingProvider
                            ? providers.find((item) => item.value === Employee.renderingProvider)
                            : null
                        }
                        index="renderingProvider"
                        isClearable={true}
                        placeholder="Select Rendering Provider"
                        disabled={editButton}
                        handleChange={(e) => {
                          setEmployee({
                            ...Employee,
                            renderingProvider: e?.value || "",
                          });
                        }}
                      />
                      <InputField
                        type="dropDown"
                        inputStyle="referring-provider-select w-100"
                        labelStyle="mt-0 modalLineHeaders text-capitalize"
                        label="Referring Physician"
                        options={providers}
                        value={
                          Employee?.referringProvider
                            ? providers.find((item) => item.value === Employee.referringProvider)
                            : null
                        }
                        index="referringProvider"
                        placeholder="Select Referring Physician"
                        disabled={editButton}
                        isClearable={true}
                        handleChange={(e) => {
                          setEmployee({
                            ...Employee,
                            referringProvider: e?.value || "",
                          });
                        }}
                      />
                      <InputField
                        type="dropDown"
                        inputStyle="w-100"
                        labelStyle="mt-0 modalLineHeaders text-capitalize"
                        label="Ordering Provider"
                        options={providers}
                        value={
                          Employee?.orderingProvider
                            ? providers.find((item) => item.value === Employee.orderingProvider)
                            : null
                        }
                        index="orderingProvider"
                        placeholder="Select Ordering Provider"
                        disabled={editButton}
                        isClearable={true}
                        handleChange={(e) => {
                          setEmployee({
                            ...Employee,
                            orderingProvider: e?.value || "",
                          });
                        }}
                      />
                    </div>
                    <div className="crew-profile-row2 mb-3">
                      <InputField
                        type="dropDown"
                        inputStyle="w-100"
                        labelStyle="mt-0 modalLineHeaders text-capitalize"
                        label="Primary Care Physician"
                        options={providers}
                        value={
                          Employee?.primaryCarePhysician
                            ? providers.find((item) => item.label === Employee.primaryCarePhysician)
                            : null
                        }
                        index="primaryCarePhysician"
                        placeholder="Select Primary Care Physician"
                        disabled={editButton}
                        isClearable={true}
                        handleChange={(e) => {
                          setEmployee({
                            ...Employee,
                            primaryCarePhysician: e?.label || "",
                          });
                        }}
                      />
                      <InputField
                        type="dropDown"
                        inputStyle="w-100"
                        labelStyle="mt-0 modalLineHeaders text-capitalize"
                        label="Referring Physician"
                        options={providers}
                        value={
                          Employee?.referringPyhsician
                            ? providers.find((item) => item.label === Employee.referringPyhsician)
                            : null
                        }
                        index="referringPyhsician"
                        placeholder="Select Referring Physician"
                        disabled={editButton}
                        isClearable={true}
                        handleChange={(e) => {
                          setEmployee({
                            ...Employee,
                            referringPyhsician: e?.label || "",
                          });
                        }}
                      />
                    </div>
                  </div>
                </div>

                <div className="p-3">
                  <Row className="additional-info w-100">
                    <Col md="5" className="border-right-1 additional-info-radios">
                      <div className="justify-content-between d-flex  w-100 mb-0 m-0 secondary-insurance-head">
                        <h4 className="text-decoration-underline mt-0 section-title">
                          {" "}
                          Payer Information <span className="fw-bold fs-6 maroon">*</span>
                        </h4>
                        <div className="plus-icon mt-1">
                          <i
                            className="fas fa-plus-circle fa-lg cursor-pointer w-auto"
                            onClick={() => setOpenInsuranceModal(EMP_INS_OBJECT)}
                          />
                        </div>
                      </div>
                      <div className="scrollable-div w-100 p-2">
                        {Employee?.insuranceDetails && Employee.insuranceDetails.length > 0
                          ? Employee.insuranceDetails.map((ins, i) => (
                              <InsuranceViewCard
                                insurance={{ ...ins }}
                                index={i + 1}
                                key={i}
                                handleEdit={(obj) => {
                                  setOpenInsuranceModal({ ...obj, isNew: false });
                                }}
                                handleUpdateIns={(obj) => {
                                  const findIndex = Employee.insuranceDetails.findIndex((f) => f.id === obj.id);
                                  if (findIndex !== -1) {
                                    const stateUser = { ...Employee };
                                    const stateInsDetails = [...stateUser.insuranceDetails];
                                    stateInsDetails[findIndex] = obj;
                                    setEmployee({ ...stateUser, insuranceDetails: stateInsDetails });
                                  }
                                }}
                                handleRemove={(obj) => {
                                  const stateInsDetails = Employee.insuranceDetails.filter((f) => f.id !== obj.id);
                                  setEmployee({ ...Employee, insuranceDetails: stateInsDetails });
                                }}
                                cssClass={"insCard-background-blue "}
                              />
                            ))
                          : null}
                      </div>
                      {/* <InputField
                        type="dropDown"
                        inputStyle="w-100"
                        labelStyle="mt-0 modalLineHeaders text-capitalize"
                        label="Insurance Carrier"
                        options={INSURANCE_PROVIDER}
                        value={
                          Employee?.insurance_name
                            ? INSURANCE_PROVIDER.find((f) => f.label === Employee?.insurance_name)
                            : null
                        }
                        disabled={editButton}
                        index="insurance_name"
                        placeholder="Select Insurance"
                        handleChange={(e) => {
                          setEmployee({ ...Employee, insurance_name: e.label });
                        }}
                      />

                      <Form.Group className="form-group-wrapper">
                        <label className="modalLineHeaders mt-0 text-capitalize">Insurance ID</label>
                        <Form.Control
                          className={`height-32 ${!editButton ? "modalInput" : ""}`}
                          readOnly={editButton}
                          onChange={(e) => setEmployee({ ...Employee, insurance_number: e.target.value })}
                          value={Employee?.insurance_number}
                          defaultValue={demographics?.insurance_number}
                          placeholder={`${!editButton ? "Enter Insurance Id" : ""}`}
                          type="text"
                        />
                      </Form.Group> */}
                      <div className="w-100">
                        {showImages && (
                          <div className="d-block">
                            <div className="cards-heading">
                              <span>Insurance</span> Card Images
                            </div>
                            <div className="test-details-wrapper rounded-2">
                              <div className="imageWrapper">
                                {showImages.filter((i) => i.isHIPPA === false && i.isExist).length > 0 ? (
                                  showImages
                                    .filter((i) => i.isHIPPA === false && i.isExist)
                                    .map((showImg, index) => {
                                      return (
                                        <ImageView
                                          key={index}
                                          cssClass="border-0 p-3"
                                          imgSrc={showImg.imgSrc}
                                          onClick={() => handleImageClick(showImg.imgSrc)}
                                        />
                                      );
                                    })
                                ) : (
                                  <div className="d-flex align-items-center justify-content-between w-100">
                                    <ImageView key={0} cssClass="border-0 p-3" imgSrc={""} />
                                  </div>
                                )}
                              </div>
                            </div>
                          </div>
                        )}
                      </div>
                    </Col>
                    <Col md="7" className="additional-info-radios border-right-1">
                      <div className="justify-content-between d-flex align-items-center mb-0 m-0 secondary-insurance-head">
                        <h4 className="text-decoration-underline mt-0 section-title">Documents</h4>
                        <div className="plus-icon mx-2">
                          <i
                            className={`fas fa-plus-circle fa-lg cursor-pointer w-auto ${
                              editButton && "disable-cursor"
                            }`}
                            onClick={() =>
                              !editButton && (setSelectedDocument({ isNew: true }), setOpenDocumentModal(Employee))
                            }
                          />
                        </div>
                      </div>

                      <DocumentList
                        list={documents}
                        disabled={editButton}
                        setDocuments={setDocuments}
                        dropDownOptions={[{ title: "Download" }, { title: "Edit" }, { title: "Delete" }]}
                        appContext={appContext}
                        setLoading={setLoading}
                        handleEdit={(item) =>
                          !editButton &&
                          (setSelectedDocument({ ...item, isNew: false }), setOpenDocumentModal(Employee))
                        }
                      />
                    </Col>
                  </Row>
                  <div className="text-right w-100">
                    {error && <ErrorMessage error={error} handleChange={() => setError("")} />}

                    {!editButton && (
                      <Button
                        variant="secondary"
                        className="profileButton btn-fill createButtons"
                        style={{
                          marginRight: 10,
                          marginTop: 10,
                        }}
                        disabled={editButton}
                        onClick={() => {
                          const obj = NEW_PROVIDER_OBJECT;
                          setNewUserProvider({ ...obj, isNew: true });
                        }}
                      >
                        Add Provider
                      </Button>
                    )}

                    {editButton && (
                      <Button
                        className="profileButton btn-fill createButtons"
                        style={{
                          marginRight: 10,
                          marginTop: 10,
                        }}
                        variant="primary"
                        onClick={() => setEditButton(false)}
                      >
                        Edit Info
                      </Button>
                    )}

                    {!editButton && (
                      <Button
                        className="profileButton btn-fill createButtons"
                        style={{
                          marginRight: 10,
                          marginTop: 10,
                        }}
                        variant="secondary"
                        onClick={() => handleSave({ ...Employee })}
                      >
                        {" "}
                        Save{" "}
                      </Button>
                    )}

                    {!editButton && (
                      <Button
                        className="profileButton btn-fill createButtons"
                        style={{
                          marginRight: 10,
                          marginTop: 10,
                        }}
                        variant="primary"
                        onClick={handelCancelButton}
                      >
                        Cancel
                      </Button>
                    )}
                  </div>
                </div>
              </Col>
            </div>
          </div>
        </div>

        <Row className="mx-0">
          <Col md="12">
            <div className="system-settings-wrapper">
              {testViews && <TestView testData={testViews} loader={loadingTestView} handleResultPDF={showResultPDF} />}
            </div>
            <div className="system-settings-wrapper">
              {employeeLogs && <EmployeeLogs employeeData={employeeLogs} />}
            </div>
          </Col>
        </Row>

        <RelationView employee={demographics} />
      </div>

      <Modal
        show={modalShow}
        // style={{ transform: "translate(0, -30%)" }}
        animation={true}
        onHide={() => setModalShow(false)}
        centered
        size={"md"}
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">Release Test Results</Modal.Title>
        </Modal.Header>

        <Modal.Body style={{ paddingTop: 0, marginRight: "auto", marginLeft: "auto" }}>
          <p>Select all that apply: </p>
          <div className="checkboxRow">
            <label className="checkboxLabel">
              <input
                disabled={!siteContactPhone}
                value="site"
                onChange={(e) => {
                  if (e.target.checked) {
                    setReleaseTo({
                      ...releaseTo,
                      [e.target.value]: siteContactPhone,
                    });
                  } else {
                    const newReleaseTo = releaseTo;
                    delete newReleaseTo[e.target.value];
                    setReleaseTo(newReleaseTo);
                  }
                }}
                className="releaseCheckbox"
                type="checkbox"
              />
              Health and Safety Officer
            </label>
            <label>
              <input
                value="crew"
                onChange={(e) => {
                  if (e.target.checked) {
                    setReleaseTo({
                      ...releaseTo,
                      [e.target.value]: setPhoneNo(test?.phone_number),
                    });
                  } else {
                    const newReleaseTo = releaseTo;
                    delete newReleaseTo[e.target.value];
                    setReleaseTo(newReleaseTo);
                  }
                }}
                className="releaseCheckbox"
                type="checkbox"
              />
              Crew Member
            </label>
          </div>
        </Modal.Body>

        <Modal.Footer>
          <Button className="modalButtons" variant="secondary" onClick={() => setModalShow(false)}>
            Cancel
          </Button>
          <Button
            className="modalButtons"
            variant="primary"
            onClick={async () => {
              if (releaseTo.length === 0) return;
              setLoading(true);
              const params = [];
              params.push({
                id: test.id,
                status: "Processed",
                timerStatus: "Processed",
                sequenceNo: test.sequenceNo,
                invalidResultStatus: 1,
              });
              const ids = [{ id: test.id, sequenceNo: test.sequenceNo }];
              await GQAPI.releaseTest(params);
              if (releaseTo.crew) {
                await GQAPI.sendEmailToPendingRelease(params);
              }

              const logs = {
                ids: ids,
                userID: appContext.user?.sub,
                userName: appContext.user?.name,
                slug: test.result.toLowerCase() !== "positive" ? "Inconclusive Release" : "Pending Release",
              };

              await GQAPI.addTestLogs(logs);

              releaseTo.site &&
                (await API.sendSMSNotification(
                  releaseTo.site,
                  `Hello, there was a positive (${TEST_TYPE_VALUE[test.test_type]}) test result for ${
                    site.name
                  }. please login to your Health & Safety Portal to review.`
                ));

              await appContext.resetPendingTests([test.id]);
              props.history.push("/admin/pendingrelease");
              appContext.showSuccessMessage(`Success! You have released test`);
              setLoading(false);
            }}
          >
            Release Results
          </Button>
        </Modal.Footer>
      </Modal>
      {openQuarantined && (
        <QuarantineModal test={test} handleSave={handleSaveQuarantine} handleClose={() => setOpenQuaratined(false)} />
      )}

      {openDataChangeConfirmation && (
        <ConfirmationModal
          show={openDataChangeConfirmation}
          title="Test Update Alert"
          message={`New Changes occures, are you want to update your edit info!`}
          handleConfirm={handleDataChangeConfirm}
        />
      )}

      {openInsuranceModal && (
        <InsuranceDetailsModal
          type=""
          title={openInsuranceModal.isNew ? "Add Payer Information" : "Update Payer information"}
          insuranceDetails={{ ...openInsuranceModal }}
          insuranceList={Employee.insuranceDetails}
          user={Employee}
          hanndleAddIns={(obj) => {
            setOpenInsuranceModal(null);
            setEmployee({
              ...Employee,
              insuranceDetails: parseInsDetails([...Employee.insuranceDetails, obj], Employee),
            });
          }}
          handleUpdateIns={(obj) => {
            setOpenInsuranceModal(null);
            if (!obj.isNew) {
              const findIndex = Employee.insuranceDetails.findIndex((f) => f.id === obj.id);
              if (findIndex !== -1) {
                const stateUser = { ...Employee };
                const stateInsDetails = [...stateUser.insuranceDetails];
                stateInsDetails[findIndex] = obj;
                setEmployee({ ...stateUser, insuranceDetails: stateInsDetails });
              }
            } else {
              setEmployee({ ...Employee, insuranceDetails: [...Employee.insuranceDetails, obj] });
            }
          }}
          handleClose={(key) => {
            setOpenInsuranceModal(null);
          }}
        />
      )}

      {openDocumentModal && (
        <DocumentForm
          emp={openDocumentModal}
          document={selectedDocument}
          handleClose={() => {
            setOpenDocumentModal(null);
          }}
          setLoading={setLoading}
          appContext={appContext}
          setDocuments={setDocuments}
          cssClass="seperateModal"
        />
      )}

      {newProviderInfo && (
        <ProviderModal
          cssClass="seperateModal"
          show={true}
          user={newProviderInfo}
          handleClose={(obj) => {
            setNewUserProvider(null);
            if (obj && obj.id) {
              setProviders((prev) => [...prev, { ...obj, label: obj.name, value: obj.id }]);
            }
          }}
          providers={providers}
          appContext={appContext}
        />
      )}

      {showProfileModal && (
        <ProfileRelationModal
          filterEmployees={appContext?.users?.filter(
            (f) => f.id !== Employee.id && connected.findIndex((i) => i.id === f.id) === -1
          )}
          employeesId={Employee.id}
          getConnectedProfiles={getConnectedProfiles}
          connected={connected}
          handleClose={() => {
            setProfileModal(!showProfileModal);
          }}
        />
      )}
    </>
  ) : (
    <Loader />
  );
};

export default PatientProfile;
