import { createRef, useRef, useState } from "react";
import { Auth } from "aws-amplify";
import { callPublicAPI } from "views/GraphQL/gqApi";

import viewIcon from "../../assets/img/eye-open-icon.png";
import hideIcon from "../../assets/img/eye-not-open-icon.png";

const ForgotpassUi = ({
  setLoading,
  setShowForgotUi,
  phoneNumber,
  setPassword,
  setPhoneError,
  handleSubmit,
  handleResendCode,
}) => {
  const [digits, setDigits] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  const [passwordData, setPasswordData] = useState({
    password: "",
    resetPassword: "",
  });
  const { password, resetPassword } = passwordData;

  const handleChange = (event) => {
    const value = event.target.value;
    setDigits(value);
  };

  const handelOtpVerification = async () => {
    const code = digits;
    setLoading(true);

    if (password !== resetPassword) {
      setLoading(false);

      setPhoneError({ message: "Password not match", error: true });
      return;
    }

    try {
      // await Auth.forgotPasswordSubmit(phoneNumber, code, password);

      const path = "/user/verifyCode";

      const payload = {
        phone: phoneNumber,
        code,
        password,
      };

      const { verified } = await callPublicAPI({ path, payload });

      if (verified) {
        setPhoneError({ message: "Your password updated.", success: true, title: "Success" });

        setLoading(false);
        setShowForgotUi(false);
      }

      // if (send) {
      //   setShowForgotUi(true);
      // }

      // setPassword(password);
      // setLoading(false);

      // setShowForgotUi(false);
    } catch (error) {
      console.log("error", { error });
      const { response } = error;
      if (response?.data?.error) {
        setPhoneError({ message: response?.data?.error || "Invalid Code.", error: true });
      }
      setLoading(false);
    }
  };
  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const toggleConfirmPasswordVisibility = () => {
    setShowConfirmPassword(!showConfirmPassword);
  };

  return (
    <div className="login-right-side max-w-100">
      <h3 className="w-100 text-center mt-2 mb-4">Enter New Password</h3>
      <span className="tag-line d-block text-center">
        To change your password, please fill in the field below. <br />
        Your password must contain at least 8 charactors, it must also include at least <br />
        one upper case letter, one lower case letter, one number and one special charater
      </span>
      <div className="login-inner-right-side mt-4">
        <div className="mb-3">
          <label className="login-input-label mb-2">
            OTP <span className="text-danger">*</span>
          </label>
          <input
            className="w-100"
            type="text"
            placeholder="123456"
            maxLength="6"
            value={digits}
            onChange={handleChange}
          />
        </div>
        <div className="d-flex flex-column mb-3">
          <label className="login-input-label mb-2">
            New Password <span className="text-danger">*</span>
          </label>
          <div className="position-relative">
            <input
              placeholder="Enter New Password"
              minLength={8}
              value={password}
              type={showPassword ? "text" : "password"}
              className="w-100"
              onChange={(e) => setPasswordData({ ...passwordData, password: e.target.value })}
            />
            <span
              onClick={togglePasswordVisibility}
              style={{
                position: "absolute",
                right: "10px",
                top: "50%",
                transform: "translateY(-50%)",
                cursor: "pointer",
              }}
            >
              {showPassword ? (
                <img
                  className="header-btn-img object-fit-contain"
                  width={20}
                  height={20}
                  src={viewIcon}
                  alt={`viewIcon`}
                />
              ) : (
                <img
                  className="header-btn-img object-fit-contain"
                  width={20}
                  height={20}
                  src={hideIcon}
                  alt={`hideIcon`}
                />
              )}
            </span>
          </div>
        </div>

        <div className="d-flex flex-column">
          <label className="login-input-label mb-2">
            Confirm New Password <span className="text-danger">*</span>
          </label>
          <div className="position-relative">
            <input
              placeholder="Re Enter New Password"
              minLength={8}
              className="w-100"
              value={resetPassword}
              type={showConfirmPassword ? "text" : "password"}
              onChange={(e) => setPasswordData({ ...passwordData, resetPassword: e.target.value })}
            />
            <span
              onClick={toggleConfirmPasswordVisibility}
              style={{
                position: "absolute",
                right: "10px",
                top: "50%",
                transform: "translateY(-50%)",
                cursor: "pointer",
              }}
            >
              {showConfirmPassword ? (
                <img
                  className="header-btn-img object-fit-contain"
                  width={20}
                  height={20}
                  src={viewIcon}
                  alt={`viewIcon`}
                />
              ) : (
                <img
                  className="header-btn-img object-fit-contain"
                  width={20}
                  height={20}
                  src={hideIcon}
                  alt={`hideIcon`}
                />
              )}
            </span>
          </div>
        </div>
        <div className="mt-4 d-flex justify-content-center">
          <button
            type="submit"
            disabled={digits.length !== 6 || resetPassword === "" || password === ""}
            className="text-uppercase btn signin-btn"
            onClick={handelOtpVerification}
          >
            Change Password
          </button>
        </div>
        <div
          style={{ display: "flex", justifyContent: "flex-end", width: "100%", marginTop: "5px" }}
          onClick={handleResendCode}
        >
          <p style={{ cursor: "pointer", fontSize: "14px" }}>Resend</p>
        </div>
      </div>
    </div>
  );
};

export default ForgotpassUi;
