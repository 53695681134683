import React, { useState, useEffect, useMemo, useCallback } from "react";
import {
  medFlowInLocalStorage,
  formatZip,
  toTitleCase,
  convertToLower,
  formateDateLL,
  checkValidity,
  getValidName,
  isValidName,
  isValidIdNum,
  isValidPhoneWithCode,
  isValidZipCode,
} from "utils";
import { useHistory } from "react-router-dom";
import { newRegisterObj, externalTypesOptions, countryListAllIsoData, IMAGE_UPLOAD_TYPE, CONFIG } from "constant";
import { Badge, Button, Card } from "react-bootstrap";
import API from "api";
import moment from "moment";
import Loader from "components/Loader/Loader";
import DateRangePicker from "react-bootstrap-daterangepicker";
import "bootstrap-daterangepicker/daterangepicker.css";
import ImageInput from "components/Input/ImageInput";
import Select from "react-select";
import { Storage } from "aws-amplify";
import { debounce } from "debounce";
import PhoneNoInput from "components/Phone/PhoneNoInput";
import Default_Profile from "assets/img/default_profile.png";
import { formatPhoneNumberIntl } from "react-phone-number-input";
import countriesOption from "countries";
import logoImage from "assets/img/co_lab-1.png";
import ImageCropModal from "components/Input/ImageCropModal";
import QRCodeView from "components/PreRegister/QRCodeView";
import SweetAlert from "react-bootstrap-sweetalert";
import ShowSelection from "components/PreRegister/ShowSelection";
import SingleDatePicker from "Date/SingleDatePicker";
import ConfirmationModal from "components/Modal/ConfirmationModal";
import { validSpace } from "utils";
import AutoCompleteAddress from "components/AutoCompleteAddress";
import { isValidEmail } from "utils";
import { formatPhoneNumber } from "utils";
import HipaaRegisterStepOne from "components/Hipaa/component/HipaaRegisterStepOne";
import { HIPPA_QUESTIONS } from "constants/hippaquestion";
import QuestionAndAnswerForm from "components/Hipaa/component/QuestionAndAnswerForm";
import Certificate from "components/Hipaa/component/HipaaCertficate";
import LogoText from "components/LogoText";
import VideoPlayer from "components/Hipaa/component/VideoPlayer";

const HipaaTest = (props) => {
  // const {
  //   match: { params },
  // } = props;
  const isMatch = props.hasOwnProperty("match");
  const isId = props.hasOwnProperty("id");

  const [newUser, setNewUser] = useState(newRegisterObj);
  const [registrationDone, setRegistrationDone] = useState(false);
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(true);
  const [testKey, setTesKey] = useState("");
  const [profileImage, setProfileImage] = useState(Default_Profile);
  const [copyImage, setCopyImage] = useState(Default_Profile);
  const [vaccinationImage, setVaccinationImage] = useState("");
  const [externalTestImage, setExternalTestImage] = useState("");
  const [showImageCroper, setShowImageCropper] = useState(false);
  const [client, setClient] = useState("");
  const [showName, setShowName] = useState("");
  const [pageExpire, setPageExpire] = useState(false);
  const [step, setStep] = useState(1);
  const [errorMessageText, setErrorMessageText] = useState("");
  const [showListing, setShowListing] = useState([]);
  const [orderKitClose, setOrderKitClose] = useState(1);
  const [openConfirmationModal, setOpenConfirmationModal] = useState(false);
  const [message, setMessage] = useState("");
  const [maxQty, setMaxQty] = useState(2);
  const [recordData, setRecordData] = useState("");
  const [recordQuesAnswer, setRecordQuesAnswers] = useState(
    HIPPA_QUESTIONS.map((m) => ({ id: m.id, a: "", question: m.question, options: m.options, expectedAns: m.answer }))
  );

  const currentEnv = CONFIG.isLive ? "LIVE" : "DEV";
  const getFileFromStorage = async (file) => {
    try {
      const path = await Storage.get(file);
      setProfileImage(path);
      console.log("path", path);
    } catch {
      console.log("Image loading fail");
    }
  };

  useEffect(() => {
    if (isMatch) {
      const {
        match: { params },
      } = props;
      params && params.id ? setTesKey(params.id) : "";
    }
    if (isId) {
      setTesKey(props.id);
    } else {
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    if (recordData.answers) {
      setRecordQuesAnswers(
        recordQuesAnswer.map((m) => {
          const ansFind = recordData.answers.find((f) => f.id == m.id);
          if (ansFind) {
            return { ...ansFind, question: m.question, options: m.options, expectedAns: m.expectedAns };
          }
          return m;
        })
      );
    }
  }, [recordData]);

  const history = useHistory();

  const redirectToAdmin = () => {
    if (history) history.push("/admin");
  };

  const isLoggedIn = medFlowInLocalStorage.get();
  if (isLoggedIn && props.hasOwnProperty("match")) redirectToAdmin();

  const showErrorMessage = (message) => {
    setError(message);
    setTimeout(() => setError(""), 2000);
  };
  const isVaccinationInfoEnable = useMemo(() => {
    return newUser.isVaccinated && newUser.showId && newUser.showId.vaccinationCard;
  }, [newUser.isVaccinated, newUser.showId]);

  const isExternalTestAllow = useMemo(() => {
    return newUser.showId && newUser.showId.externalTest;
  }, [newUser.showId]);

  const handleUpdateAddress = (val, flag) => {
    if (!flag) {
      setNewUser({ ...newUser, street: val });
    } else {
      const addressObj = {
        street: val.street,
        country: val.country,
        city: val.city,
        zipcode: val.zipcode,
        zip: val.zipcode,
        state: val.state,
        isoCode: val.isoCode,
      };
      setNewUser({ ...newUser, ...addressObj });
    }
  };

  const saveHipaaData = async (data, testKey, email, firstName, lastName) => {
    try {
      console.log("Data", testKey, email, firstName, lastName);
      const res = await API.UpdateHipaaTestAns(data, testKey, email, firstName, lastName);
    } catch (err) {
      console.log("err", err);
    }
  };

  const savechangeAns = useCallback(
    debounce((answers, testKey, email, firstName, lastName) => {
      saveHipaaData(answers, testKey, email, firstName, lastName);
    }, 800),
    []
  );

  const handleSaveAns = async (ansObj) => {
    const cloneData = _.cloneDeep(recordQuesAnswer);
    const findDataIndex = cloneData.findIndex((f) => f.id === ansObj.id);
    cloneData.splice(findDataIndex, 1, ansObj);
    setRecordQuesAnswers(cloneData);
    savechangeAns(
      cloneData.filter((f) => f.a).map((obj) => ({ id: obj.id, a: obj.a })),
      testKey,
      recordData.email,
      recordData.firstName,
      recordData.lastName
    );
  };

  const handleSubmit = async () => {
    if (!recordData.firstName?.trim()) {
      showErrorMessage("First name is required");
      return;
    }

    if (!recordData.lastName?.trim()) {
      showErrorMessage("Last name is required");
      return;
    }
    if (!recordData.email?.trim()) {
      showErrorMessage("Email name is required");
      return;
    }
    if (recordQuesAnswer.some((f) => !f.a)) {
      showErrorMessage("Please Attempt All questions");
      return;
    }

    const reviewAns = recordQuesAnswer.map((question) => {
      return { ...question, isNotValid: question.a && question.a != question.expectedAns };
    });

    if (reviewAns.filter((e) => e.isNotValid).length === 0) {
      setLoading(true);
      await API.UpdateHipaaTestComplete({
        id: recordData.id,
        email: recordData.email,
        firstName: recordData.firstName,
        lastName: recordData.lastName,
        date: moment().format("MMMM D, YYYY"),
      });
      setLoading(false);
      setRegistrationDone(true);
    } else {
      setRecordQuesAnswers(reviewAns);
      setError("Please review incorrect answers");
    }

    // setStep(3);
  };
  console.log("reviewAns", recordQuesAnswer);

  const handleCompleteRegistration = async () => {
    if (recordQuesAnswer.some((f) => f.a != f.expectedAns)) {
      showErrorMessage("All answers should be correct");
      return;
    }
    setRegistrationDone(true);
    await API.UpdateHipaaTestComplete(recordData.email);
  };

  const handleCropDone = (isConfirm, fileData) => {
    //console.log("registerCrew", isConfirm, fileData);
    setShowImageCropper(false);
    if (isConfirm && fileData) {
      setProfileImage(new File([fileData], "profileImage.jpeg"));
    } else {
      setProfileImage(profileImage);
    }
  };

  const formatFetchData = (data) => {
    const obj = _.cloneDeep(data);
    if (obj.firstName === "undefined") {
      obj.firstName = "";
    }
    if (obj.lastName === "undefined") {
      obj.lastName = "";
    }
    return obj;
  };

  const handleProcced = async (obj) => {
    try {
      setLoading(true);
      const response = await API.hipaaTestSearch(obj, testKey);
      if (response && response.length > 0) {
        const obj = formatFetchData(response[0]);
        setRecordData(obj);

        if (obj.isCompleted) {
          setRegistrationDone(true);
        } else {
          setStep(2);
        }
      } else {
        setErrorMessageText("Record Not Found");
      }
      setLoading(false);
    } catch (err) {
      setLoading(false);
      setStep(1);
      setErrorMessageText(err);
    }
  };

  const fillUserData = (data, formattedPhone) => {
    const country = countryListAllIsoData.find((f) => f.name === data.country) || { code: "US" };
    const obj = {
      ...newUser,
      first: data.first,
      last: data.last,
      email: data.email,
      phone_number: formattedPhone,
      state: data.state,
      zipcode: data.zipcode,
      zip: data.zipcode,
      id: data.id,
      country: data.country,
      isoCode: country.code,
      street: data.street,
      street2: data.street2 === "undefined" ? "" : data.street2,
      city: data.city,
      designation: "",
    };
    try {
      Object.assign(obj, {
        sex: data.sex,
        dob: data.dob,
        id_number: data.id_number || "",
        isVaccinated: data.isVaccinated && data.isVaccinated === "true" ? true : false,
      });
    } catch (err) {
      console.log("Error", err);
    }
    setNewUser(obj);
  };

  const handleConfirmationRes = (value) => {
    setOpenConfirmationModal(false);
    setMessage("");
    if (value) {
      setStep(2);
    } else {
      setStep(4);
      setOrderKitClose(5);
    }
  };

  if (pageExpire) return <p>Page Expired</p>;

  if (loading) return <Loader />;

  if (registrationDone) {
    return <Certificate data={recordData} />;
  }

  if (step === 1) {
    return (
      <>
        <HipaaRegisterStepOne show={newUser.showId} handleProcced={handleProcced} errorMessageText={errorMessageText} />
        {openConfirmationModal && (
          <ConfirmationModal
            isPreRegister
            title="Pre Registration"
            message={message}
            handleConfirm={handleConfirmationRes}
          />
        )}
      </>
    );
  }

  if (step === 3)
    return (
      <div className="registerFormWrapper align-items-start">
        <div className="registerWrapper container-fluid">
          <div className="loginInner">
            <div className="text-center">
              <img src={logoImage} alt="logo" className="logo-width" />

              <h4 className="mt-4">HIPAA Certification Response Summary</h4>
            </div>

            <table className="table">
              <thead>
                <tr>
                  <th>Question</th>
                  <th>Response</th>
                </tr>
              </thead>
              <tbody>
                {recordQuesAnswer.map((question, questionIndex) => (
                  <tr key={questionIndex}>
                    <td>{`Q${question.id}: ${question.question}`}</td>
                    <td>
                      <span className={question.a === question.expectedAns ? "green fw-bold" : "red fw-bold"}>
                        {question.a === question.expectedAns ? (
                          "Correct"
                        ) : (
                          <span onClick={() => setStep(2)} className="cursor-pointer">
                            {" "}
                            Wrong
                          </span>
                        )}
                      </span>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
            {error && (
              <div className="error-message">
                <h6>{error}</h6>
              </div>
            )}
            <div className="mt-4">
              <button
                type="submit"
                className="text-uppercase w-100 btn signup-btn"
                onClick={handleCompleteRegistration}
              >
                Submit Response
              </button>
            </div>
          </div>
        </div>
      </div>
    );

  return (
    <>
      <div className="registerFormWrapper">
        <div className="registerWrapper container-fluid">
          <div className="loginInner">
            <div className="text-center mb-4">
              {" "}
              <img src={logoImage} alt="logo" className="logo-width" />
            </div>
            <h5 className="w-100  mt-5 text-alignjustify">
              Thank you for visiting the HIPAA video and test link. It is a requirement that all employees at
              Collaboration, Inc. complete and pass this quiz to demonstrate competency in our HIPAA guidelines. You can
              play the 20 minute video and answer the quiz questions simultaneously. It is our duty to protect the best
              interests of our patients which includes protecting their personal health information. Please contact our
              human resource manager at{" "}
              <span
                onClick={() => window.open(`tel:+1661-210-9794`)}
                className={`ellipsis text-dark-danger text-dark-danger-hover cursor-pointer text-decoration-none`}
              >
                661-210-9794
              </span>{" "}
              with any questions or to get more information on HIPAA related law.
            </h5>
            <VideoPlayer videoId="AUnTGNVDWZU" />
            <div className="form-group-wrapper mt-5">
              <label className="mb-1">First Name:</label>
              <input
                value={recordData.firstName || ""}
                type="text"
                className="form-control"
                placeholder="First Name"
                onChange={(e) => setRecordData({ ...recordData, firstName: getValidName(e.target.value) })}
              />
            </div>
            <div className="form-group-wrapper">
              <label className="mb-1">last Name:</label>
              <input
                value={recordData.lastName || ""}
                type="text"
                className="form-control"
                placeholder="First Name"
                onChange={(e) => setRecordData({ ...recordData, lastName: getValidName(e.target.value) })}
              />
            </div>
            <div className="form-group-wrapper">
              <label className="mb-1">Email:</label>
              <input
                value={recordData.email}
                type="text"
                className="form-control"
                placeholder="Email"
                onChange={(e) => setRecordData({ ...recordData, email: convertToLower(e.target.value) })}
              />
            </div>

            {recordQuesAnswer.map((m) => (
              <QuestionAndAnswerForm data={m} handleSaveAns={handleSaveAns} />
            ))}

            {error && (
              <div className="error-message">
                <h6>{error}</h6>
              </div>
            )}
            {step === 2 && (
              <div className="mt-2">
                <button type="submit" className="text-uppercase w-100 btn signup-btn" onClick={handleSubmit}>
                  Submit Answers
                </button>
              </div>
            )}
          </div>
        </div>
      </div>

      {showImageCroper && <ImageCropModal selectedImage={copyImage} handleConfirm={handleCropDone}></ImageCropModal>}
      {openConfirmationModal && (
        <ConfirmationModal
          isPreRegister
          title="Order the Test"
          message={message}
          handleConfirm={handleConfirmationRes}
        />
      )}
    </>
  );
};

export default HipaaTest;
