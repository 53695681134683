import React, { useState, useEffect, useContext } from "react";
import API from "api";
// react-bootstrap components
import DashboardLineChart from "./DashboardLineChart";
import DashboardPieChart from "./DashboardPieChart";
import DashboardBarChart from "./DashboardBarChart";
import DashboardChartTotalTestsDataCard from "./DashboardChartTotalTestsData";

import { AppContext } from "context/app-context";
import { connectedIDInLocalStorage, formateDateLL } from "utils";
import ClientsDashboard from "./ClientsDashboard";



const HSDashboard = (props) => {

    const { site } = props;

    const [totalTestCounts, setTotalCounts] = useState('');

    const [summaryData, setSummaryData] = useState({ positiveTests: 0, negativeTests: 0, totalTests: 0, inconclusiveTests: 0, positiveTest: null });
    const appContext = useContext(AppContext);
    const connectedIDFromRoute = window.location?.pathname?.split("/").pop();
    const [routingPathBasedMedFlow, setRoutingPathBasedMedFlow] = useState({
        userType: "E",
        siteID:
            connectedIDFromRoute.toLowerCase() !== "admin" ? connectedIDFromRoute : connectedIDInLocalStorage.get() || "A",
    });

    useEffect(() => {
        loadSummaryData();
    }, []);

    const loadSummaryData = async () => {
        const connectedID = routingPathBasedMedFlow.siteID || connectedIDInLocalStorage.get() || "A";

        const data = await API.getSummaryFromPG({ siteID: connectedID });
        let newObj = {};
        if (data.rows.length > 0) {

            // newObj = { ...summaryData };
            newObj = data.rows.reduce((obj, item) => {
                if (item["result"] === "Negative" || item["result"] === "negative"  ) {
                    obj.negativeTests = parseInt(item["count"]);
                }
                if (item["result"] === "Inconclusive" || item["result"] ==="inconclusive") {
                    obj.inconclusiveTests = parseInt(item["count"]);
                }
                if (item["result"] === "" || item["result"] === "pending") {
                    obj.pendingTests = parseInt(item["count"]);
                }
                obj.totalTests =  obj.pendingTests + obj.inconclusiveTests +  obj.negativeTests ;
       
                return obj;
            }, summaryData);
            
        }

        if (data.positiveTest.length > 0) {
            const positiveTestsData = data.positiveTest;
            const realPositive = positiveTestsData.filter((t) => t.status !== "Pending");
            if (realPositive.length > 0) {
                newObj.positiveTest = realPositive[0]  ;
                newObj.positiveTests = realPositive.length || realPositive[0];
            }
            newObj.totalTests = (newObj.totalTests || 0) + parseInt(positiveTestsData.length);
        }
        setSummaryData({ ...newObj });
       totalCounts(newObj)
       
    };

    const showStartDate = (startDate) => {
        if (startDate) {
            return new Date(startDate).toDateString();
        }
        return "N/A";
    };

    const getShowRunningDays = (startDate) => {
        if (startDate) {
            return Math.ceil((new Date() - new Date(startDate).getTime()) / (1000 * 3600 * 24));
        }
        return "N/A";
    };

    const lastPositiveTestDate = (testDate) => {
        if (!testDate) return "N/A";
        const date = new Date(testDate);
        const currentDate = new Date();
        const dayLastPositive = Math.floor((currentDate.getTime() - date.getTime()) / (1000 * 3600 * 24));
        return `${date.toDateString()} (${dayLastPositive} days ago)`;
    };

    const totalTestOfShow = (tests) => {
        const ids = tests
            .filter((test) => test.result?.toLowerCase() === "positive" && test.status.toLowerCase() === "pending")
            .map((t) => t.id);
        return tests.filter((t) => !ids.includes(t.id)).length;
    };


    const totalCounts = (testCountsObj) => {
        if (site) {
            testCountsObj.siteName = site?.name;
            testCountsObj.startDate = showStartDate(site?.startDate);
            testCountsObj.showRunningDays = getShowRunningDays(site?.startDate);
            testCountsObj.lastPositive = formateDateLL(summaryData?.positiveTest?.createdAt);
        }

        setTotalCounts(testCountsObj);
    };

    return (

        <div className="dashboard-wrapper bg-transparent">
            <div className="container-fluid mb-3">
                <h4 className="mt-0">Show Summary</h4>
                <div className="row hs-dashboard-wrapper">
                    <div className="col-12 col-sm-12 col-md-6 col-xl-4">
                        <div className="card bg-start-date">
                            <div className="card-body p-0">
                                <span>Show Start Date</span>
                                <p className="mb-0">{totalTestCounts?.startDate || "-"}</p>
                            </div>
                        </div>
                    </div>
                    <div className="col-12 col-sm-12 col-md-6 col-xl-4">
                        <div className="card bg-start-date bg-running-days">
                            <div className="card-body p-0">
                                <span>Show Running Days</span>
                                <p className="mb-0">{totalTestCounts?.showRunningDays || 0}</p>
                            </div>
                        </div>
                    </div>
                    <div className="col-12 col-sm-12 col-md-6 col-xl-4">
                        <div className="card bg-start-date last-p-test">
                            <div className="card-body p-0">
                                <span>Last Positive Test</span>
                                <p className="mb-0">{totalTestCounts?.lastPositive || "No Positive Tests"}</p>
                            </div>
                        </div>
                    </div>
                </div>
                <DashboardChartTotalTestsDataCard totalTestCounts={totalTestCounts} />
            </div>
            <div className="dashboard-wrapper">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-12 col-md-6 col-lg-6 col-sm-12 col-xl-4">
                            <div className="bg-white rounded-3 pt-3 position-relative box-height">
                                <DashboardBarChart statsType={'sitestats'} statsTitle="Date Wise Stats" connectedID={routingPathBasedMedFlow.siteID} />
                            </div>
                        </div>

                        <div className="col-12 col-md-6 col-lg-6 col-sm-12 col-xl-4">
                            <div className="bg-white rounded-3 pt-3 position-relative box-height">
                                <div className="box-inner-height">
                                    <DashboardLineChart statsType={'sitestats'} statsTitle="Test-Type Wise Stats" connectedID={routingPathBasedMedFlow.siteID} />
                                </div>
                            </div>
                        </div>

                        <div className="col-12 col-md-6 col-lg-6 col-sm-12 col-xl-4">
                            <div className="bg-white rounded-3 pt-3 position-relative box-height">
                                <DashboardPieChart statsType={'sitestats'} statsTitle="Site Stats" chartData={totalTestCounts} connectedID={routingPathBasedMedFlow.siteID} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* <ClientsDashboard /> */}
        </div>

    );
};



export default HSDashboard;





