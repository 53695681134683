import React, { useEffect, useRef, useState } from "react";
import HeaderItem from "./HeaderItem";
import { stringConstant, TEST_HEADER_TYPE, CONFIG } from "../../constant";
import { useLocation } from "react-router";
import { calculateTdWidth } from "../../utils";
import ViewOnlyCheckBox from "components/CheckBox/ViewOnlyCheckBox";

const Header = (props) => {
  const { type, flipSort, sortBy, sortDescending, cssClass, widthCol, handlePageChecked, page, checkedPage } = props;
  const location = useLocation();

  const componentRef = useRef();
  const [width, setWidth] = useState(0);
  useEffect(() => {
    setWidth(componentRef.current.offsetWidth);
  }, [componentRef]);

  const handleResize = () => {
    setWidth(componentRef.current.offsetWidth);
  };

  useEffect(() => {
    handleResize();
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  });

  const calculateColumns = () => {
    let col =
      location.pathname === "/admin/alltests" ||
      location.pathname === "/admin/emailstatus" ||
      location.pathname === "/admin/totaltests"
        ? 14
        : 12;
    if (type !== TEST_HEADER_TYPE.pending) col += 2;
    if (type !== TEST_HEADER_TYPE.unprocessed) col += 2;

    return col;
  };

  const tdWidth = calculateTdWidth(width - 60, calculateColumns());

  return (
    <thead ref={componentRef}>
      <tr className={cssClass}>
        <th style={{ width: `${widthCol || 50}px`, maxWidth: `${widthCol || 50}px` }}>
          {handlePageChecked && (
            <ViewOnlyCheckBox
              bgColor="red"
              id={page}
              checked={checkedPage.includes(page)}
              handleChange={handlePageChecked}
            />
          )}
        </th>
        {type === TEST_HEADER_TYPE.pending && (
          <HeaderItem
            width={tdWidth}
            ItemKey="resultDate"
            title="Processed"
            flipSort={flipSort}
            sortBy={sortBy}
            sortDescending={sortDescending}
          />
        )}
        {(type === TEST_HEADER_TYPE.completed || type === TEST_HEADER_TYPE.alltests) && (
          <HeaderItem
            width={tdWidth}
            ItemKey="resultDate"
            title="Processed"
            flipSort={flipSort}
            sortBy={sortBy}
            sortDescending={sortDescending}
          />
        )}
        {type === TEST_HEADER_TYPE.unprocessed && (
          <HeaderItem
            width={tdWidth}
            ItemKey="createdAt"
            title="Collected"
            flipSort={flipSort}
            sortBy={sortBy}
            sortDescending={sortDescending}
          />
        )}
        {type === TEST_HEADER_TYPE.expired && (
          <HeaderItem
            width={tdWidth}
            ItemKey="resultDate"
            title="Processed"
            flipSort={flipSort}
            sortBy={sortBy}
            sortDescending={sortDescending}
          />
        )}
        <HeaderItem
          width={tdWidth - 20}
          ItemKey="firstName"
          title="First Name"
          flipSort={flipSort}
          sortBy={sortBy}
          sortDescending={sortDescending}
        />
        <HeaderItem
          width={tdWidth - 20}
          ItemKey="lastName"
          title="Last Name"
          flipSort={flipSort}
          sortBy={sortBy}
          sortDescending={sortDescending}
        />
        <HeaderItem
          width={tdWidth}
          ItemKey="dob"
          title="DoB"
          flipSort={flipSort}
          sortBy={sortBy}
          sortDescending={sortDescending}
        />
        <HeaderItem
          width={tdWidth + 40}
          ItemKey="email"
          title="Email"
          flipSort={flipSort}
          sortBy={sortBy}
          sortDescending={sortDescending}
        />
        <HeaderItem
          width={tdWidth + 20}
          ItemKey="phoneNumber"
          title="Phone"
          flipSort={flipSort}
          sortBy={sortBy}
          sortDescending={sortDescending}
          aligned="centered"
        />
        {!CONFIG.isWhiteLabel && (
          <HeaderItem
            width={tdWidth}
            ItemKey="clientID"
            title="Client"
            flipSort={flipSort}
            sortBy={sortBy}
            sortDescending={sortDescending}
            aligned="centered"
          />
        )}

        {!CONFIG.isWhiteLabel && (
          <HeaderItem
            width={tdWidth}
            ItemKey="labID"
            title="Lab"
            flipSort={flipSort}
            sortBy={sortBy}
            sortDescending={sortDescending}
            aligned="centered"
          />
        )}

        {type !== TEST_HEADER_TYPE.unprocessed && type !== TEST_HEADER_TYPE.expired && (
          <HeaderItem
            width={tdWidth}
            ItemKey="result"
            title="Result"
            flipSort={flipSort}
            sortBy={sortBy}
            sortDescending={sortDescending}
            aligned="centered"
          />
        )}
        <HeaderItem
          ItemKey="test_type"
          width={tdWidth}
          title="Test"
          flipSort={flipSort}
          sortBy={sortBy}
          sortDescending={sortDescending}
          aligned="centered"
        />

        <HeaderItem
          width={tdWidth}
          ItemKey="sequenceNo"
          title={stringConstant.sequenceNo}
          flipSort={flipSort}
          sortBy={sortBy}
          sortDescending={sortDescending}
          aligned="centered"
        />
        <HeaderItem
          width={tdWidth}
          ItemKey="siteID"
          title={stringConstant.show}
          flipSort={flipSort}
          sortBy={sortBy}
          sortDescending={sortDescending}
          aligned="centered"
        />
        {((type !== TEST_HEADER_TYPE.pending && type === TEST_HEADER_TYPE.alltests) ||
          type === TEST_HEADER_TYPE.expired ||
          type === TEST_HEADER_TYPE.unprocessed) && (
          <HeaderItem
            width={tdWidth}
            ItemKey="status"
            title="Test Status"
            flipSort={flipSort}
            sortBy={sortBy}
            sortDescending={sortDescending}
            aligned="centered"
          />
        )}
        {type === TEST_HEADER_TYPE.unprocessed && (
          <HeaderItem
            width={tdWidth}
            ItemKey="transitionStatus"
            title="Cust Status"
            flipSort={flipSort}
            sortBy={sortBy}
            sortDescending={sortDescending}
            aligned="centered"
          />
        )}
        <HeaderItem
          width={tdWidth}
          ItemKey="tester_name"
          title="Tester"
          flipSort={flipSort}
          sortBy={sortBy}
          sortDescending={sortDescending}
          aligned="centered"
        />
        {TEST_HEADER_TYPE.completed === type && CONFIG.isWhiteLabel && (
          <HeaderItem
            width={tdWidth}
            ItemKey="batch"
            title="H&S"
            flipSort={flipSort}
            sortBy={sortBy}
            sortDescending={sortDescending}
            aligned="centered"
          />
        )}
        {(TEST_HEADER_TYPE.completed === type || location.pathname === "/admin/emailstatus") && (
          <HeaderItem
            width={tdWidth}
            ItemKey="emailSend"
            title="Res Sent"
            flipSort={flipSort}
            sortBy={sortBy}
            sortDescending={sortDescending}
            aligned="centered"
          />
        )}
        <th
          className="ellipsis border-0 centered text-grey"
          style={{ fontWeight: "bold", width: tdWidth, maxWidth: tdWidth }}
        >
          Details
        </th>
      </tr>
    </thead>
  );
};

export default Header;
