import React from "react";
import { Link } from "react-router-dom";

const RedirectLink = ({ pathname, value, children }) => {

  
  const redirectTo = window.location.pathname.split('/').length === 2 ? `admin/${pathname}` : pathname;

  return (
    <Link
      style={{ textDecoration: "none", color: "var(--text-black)", cursor: "pointer" }}
      to={{
        pathname: redirectTo,
        state: value,
      }}
    >
      {children}
    </Link>
  );
};

export default RedirectLink;
