export const CPT_CODE = "cptCode";

export const CPT_CODE_FETCH = "cptCodeFetch";

export const CPT_CODE_CREATE = "cptCodeCreate";

export const CPT_CODE_UPDATE = "cptCodeUpdate";

export const CPT_CODE_DELETE = "cptCodeDelete";

export const ICT_CODE_FETCH = "ICTCodeFectch";

export const ICT_CODE_CREATE = "ICTCodeCreate";

export const ICT_CODE_UPDATE = "ICTCodeUpdate";
export const ICT_CODE_DELETE = "ICTCodeDelete";
