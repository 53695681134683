import ViewCheckBox from "components/CheckBox/ViewCheckBox";
import Filter from "components/Filter";
import Icon from "components/Icon";
import HeaderItem from "components/Table/HeaderItem";
import { debounce } from "debounce";
import { useCallback, useEffect, useRef, useState, useMemo, useContext } from "react";
import { Card, Col, Row, Table } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import "../../../src/tableStyling.css";
import EditIcon from "../../assets/img/edit-icon.png";
import AddDiagnosisCodeSettingsModal from "../../components/GeneralSettings/AddDiagnosisCodeSettingsModal";
import {
  ICTCodeSelector,
  openCreateICTModalSelector,
  selectedICTItemSelector,
  totalIctRecordsSelector,
} from "../../store/features/cptCodes/cptCodeSelectors";
import {
  fetchICTCodeAsync,
  setOpenCreateICTModal,
  setSelectedICTItem,
} from "../../store/features/cptCodes/cptCodeSlice";
import { t } from "../../stringConstants";
import { DIAGNOSIS_CODE_PERSONALIZE } from "constants/personalization";
import { MESSAGE_MODES, TABLE_QUICK_TOOLS, DIAGNOSIS_CODES, PAGE_LIMIT } from "../../constant";
import MainTable from "components/Table/MainTable";
import Status from "components/Status";
import { userGivenPermission } from "store/features/authentication/authenticationSelectors";
import PGPagination from "components/Pagination/PGPagination";
import { AppContext } from "context/app-context";
import { draggablePersonalizationLocalStorage, userCompanyID, numberWithCommas } from "utils";
import PersonalizationModal from "components/Modal/personalizationModal";
import { selectedCompanySetting } from "../../store/features/companySetting/companySettingSelectors";

import api from "../../api";

const DiagnosisCodeSettings = (props) => {
  const [sortBy, setSortBy] = useState("");
  const [personalize, setPersonalize] = useState([]);

  const diagnosisList = useSelector(ICTCodeSelector);
  const totalIptRecords = useSelector(totalIctRecordsSelector);
  const [sortDescending, setSortDescending] = useState(true);
  const [showFilter, setShowFilter] = useState(false);
  const [filter, setFilter] = useState({});
  const mainWidth = useRef();
  const dispatch = useDispatch();
  const selectedICTItem = useSelector(selectedICTItemSelector);
  const setting = useSelector(selectedCompanySetting);

  const openCreateICTModal = useSelector(openCreateICTModalSelector);
  const permission = useSelector(userGivenPermission);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const quickTools = [permission[DIAGNOSIS_CODES]?.write && TABLE_QUICK_TOOLS.edit];
  const [pageNo, setPageNo] = useState(1);
  const [checkboxes, setCheckboxes] = useState([]);
  const [allSelected, setAllSelected] = useState(false);
  const appContext = useContext(AppContext);
  const [filterDiagnosisList, setFilterDiagnosisList] = useState([]);
  const [openPersonalizationModal, setOpenPersonalizationModal] = useState(false);

  const searchTerms = ["Diagnosis Code", "Alias", "Diagnosis Code Desc", "Status"];

  const sortUsers = (users, sortParam) => {
    if (sortDescending) {
      return [...users].sort((a, b) => (a[sortParam] < b[sortParam] ? 1 : b[sortParam] < a[sortParam] ? -1 : 0));
    } else {
      return [...users].sort((a, b) => (b[sortParam] < a[sortParam] ? 1 : a[sortParam] < b[sortParam] ? -1 : 0));
    }
  };

  const fetchFromApi = useCallback(
    debounce(async (filter, sortBy, sortDescending, page) => {
      dispatch(
        fetchICTCodeAsync({
          ...(Object.keys(filter).length > 0 ? filter : { code: "a" }),
          orderBy: sortBy,
          orderByDirection: sortDescending,
          page: page || 1,
        })
      );
    }, 1000),
    []
  );

  const handleChangePage = (page, direction) => {
    setCurrentPage(page);
    const currentRecord = filterDiagnosisList.length;
    if (direction === "next" && page === totalPages && totalIptRecords > 0 && currentRecord < totalIptRecords) {
      setPageNo(pageNo + 1);
    }
  };

  useEffect(() => {
    if (pageNo > 1) {
      const concatResult = filterDiagnosisList.concat(diagnosisList);
      setFilterDiagnosisList(concatResult);
    } else {
      setFilterDiagnosisList(diagnosisList);
    }
  }, [diagnosisList]);

  const diagnosisCodesToMap = useMemo(() => {
    const list = filterDiagnosisList ? [...filterDiagnosisList] : [];
    const ttlPages = list.length > PAGE_LIMIT ? Math.ceil(list.length / PAGE_LIMIT) : 1;
    setTotalPages(ttlPages);
    if (list.length < PAGE_LIMIT || currentPage > ttlPages) {
      setCurrentPage(1);
    }
    if (ttlPages > 1) {
      const indexOfLastUser = currentPage * PAGE_LIMIT;
      const indexOfFirstUser = indexOfLastUser - PAGE_LIMIT;
      return list.slice(indexOfFirstUser, indexOfLastUser);
    }
    return list;
  }, [filterDiagnosisList, currentPage]);

  useEffect(() => {
    if (pageNo > 1) {
      fetchFromApi(filter, sortBy, sortDescending, pageNo);
    }
  }, [pageNo]);

  useEffect(() => {
    setPageNo(1);
    fetchFromApi({ ...filter }, sortBy, sortDescending, 1);
  }, [filter, sortBy, sortDescending]);

  const flipSort = (by) => {
    setSortDescending(sortBy === by ? !sortDescending : true);
    setSortBy(by);
  };

  const handleCheckboxChange = (e, user) => {
    const filteredList = checkboxes.filter((c) => c !== user.id);
    if (e.target.checked) {
      filteredList.push(user.id);
    }
    setCheckboxes(filteredList);
  };

  const tdFormat = (item, row) => {
    if (item === "isActive")
      return row?.isActive ? (
        <div className="star-icon-wrapper">
          <Status type="circle" size="md" color="green" tickIcon />
        </div>
      ) : (
        <div className="star-icon-wrapper">
          <Status type="circle" size="md" color="maroon" crossIcon />
        </div>
      );
    return row[item];
  };

  const renderTd = (item, row) => {
    return (
      <td
        key={item.id}
        className="ellipsis"
        style={{
          textAlign: item.textAlign,
          textOverflow: item.textOverflow,
        }}
      >
        {tdFormat(item.itemKey, row)}
      </td>
    );
  };

  const handleCellClick = (key, row, event) => {
    switch (key) {
      case TABLE_QUICK_TOOLS.edit:
        dispatch(setSelectedICTItem({ item: row }));
        dispatch(setOpenCreateICTModal(true));
        break;
      case TABLE_QUICK_TOOLS.checkbox:
        handleCheckboxChange(event, row);
        break;

      default:
        break;
    }
  };
  const loginUser = appContext.user;

  const handlePersonalization = async (data) => {
    setPersonalize([...data]);
    const personalisationData = draggablePersonalizationLocalStorage.saveAs(data, "diagnosis");
    setOpenPersonalizationModal(false);
    draggablePersonalizationLocalStorage.save(JSON.stringify(personalisationData));
    await api.saveUserPersonalisation(loginUser.sub, personalisationData);
  };

  useEffect(() => {
    const response = draggablePersonalizationLocalStorage.get(loginUser, "diagnosis", DIAGNOSIS_CODE_PERSONALIZE);
    const uniqueResponse = response.reduce((acc, current) => {
      if (!acc.some((item) => item.title === current.title)) {
        acc.push(current);
      }
      return acc;
    }, []);
    setPersonalize(uniqueResponse);
  }, []);

  return (
    <div style={{ flex: 1 }}>
      <>
        <Row>
          <Col>
            <Card className="strpied-tabled-with-hover">
              <Card.Header>
                <Card.Title
                  as="h4"
                  style={{
                    marginBottom: 10,
                    fontWeight: "bold",
                  }}
                >
                  {`${t("diagnosisCodes")} (${numberWithCommas(totalIptRecords) || 0})`}
                </Card.Title>
                <div className="buttonHeader">
                  <Icon
                    handleClick={() => setShowFilter(!showFilter)}
                    title={"Filter"}
                    label={"Filter"}
                    iconType={"filter"}
                  />
                  <Icon
                    handleClick={() => setOpenPersonalizationModal(true)}
                    title={t("personalize")}
                    label={t("personalize")}
                    iconType={"personalizeIcon"}
                  />
                  {permission[DIAGNOSIS_CODES]?.write && (
                    <Icon
                      handleClick={() => dispatch(setOpenCreateICTModal(true))}
                      title={"Create Diagnosis"}
                      label={"Create"}
                      iconType={"createIcon"}
                    />
                  )}
                </div>
                {showFilter && (
                  <Filter
                    filterTerms={searchTerms}
                    setFilter={setFilter}
                    filter={filter}
                    isUsersTable={true}
                    personalisationData={DIAGNOSIS_CODE_PERSONALIZE}
                  />
                )}
              </Card.Header>
              <Card.Body className="table-full-width desktop-noScroll">
                <div className="table-responsive pendingReleaseTable">
                  <Card.Header className="shows-screen-wrapper">
                    <Row className="pb-4">
                      <Col md-="6">
                        {/* <Card.Title>
                          <div className="">
                            Diagnosis codes:
                            <span className="ps-2"> {numberWithCommas(totalIptRecords) || 0}</span>
                          </div>
                        </Card.Title> */}
                      </Col>
                      <Col md="6">
                        <PGPagination
                          currentPage={currentPage}
                          handleChangePage={handleChangePage}
                          totalPages={totalPages}
                          totalRecord={totalIptRecords}
                          currentRecord={filterDiagnosisList?.length}
                          totalSelected={checkboxes?.length}
                        />
                      </Col>
                    </Row>
                  </Card.Header>
                  <MainTable
                    columns={personalize}
                    rows={diagnosisCodesToMap}
                    flipSort={flipSort}
                    sortBy={sortBy}
                    sortDescending={sortDescending}
                    widthToSkip={155}
                    tools={quickTools}
                    handleCellClick={handleCellClick}
                    customColumnCellRenderer={renderTd}
                    dropDownOptions={[{ value: "Logs", title: "Audit Trail" }]}
                    selectedRows={checkboxes}
                    handleDropDownClick={(type, row) => {
                      if (type === "Audit Trail") {
                        appContext.showLogs({ ...row, title: "Diagnoses Logs", recordType: "ict" });
                      }
                    }}
                  />
                </div>
              </Card.Body>
            </Card>
          </Col>

          {openCreateICTModal && (
            <AddDiagnosisCodeSettingsModal
              handleClose={() => {
                dispatch(setOpenCreateICTModal(false));
                dispatch(setSelectedICTItem(null));
              }}
              selectedItem={selectedICTItem?.item || null}
            />
          )}
        </Row>
        {openPersonalizationModal && (
          <PersonalizationModal
            data={personalize}
            handleChange={handlePersonalization}
            show={openPersonalizationModal}
            handleClose={() => setOpenPersonalizationModal(false)}
          />
        )}
      </>
    </div>
  );
};

export default DiagnosisCodeSettings;
