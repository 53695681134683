import TabSettings from "components/TabSettings/TabSettings";
import { Button, Modal } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { showPrinterSettingsModalSelectors } from "store/features/locations/locationsSelectors";
import { setPrinterSettingsModal } from "store/features/locations/locationsSlice";

const PrinterSettingsModal = ({ labClient, setLabClient }) => {
  //   const { handleTest, rowData } = props;
  const dispatch = useDispatch();

  const showPrinterSettings = useSelector(showPrinterSettingsModalSelectors);

  const handleClose = () => {
    dispatch(setPrinterSettingsModal(false));
  };

  return (
    <>
      <Modal
        show={showPrinterSettings}
        backdrop="static"
        animation={true}
        onHide={() => handleClose()}
        centered
        size={"lg"}
        className="second-modal"
      >
        <Modal.Header closeButton>
          <Modal.Title className="my-0" id="contained-modal-title-vcenter">
            Printer Settings
          </Modal.Title>
        </Modal.Header>

        <Modal.Body style={{ paddingTop: 0 }}>
          <TabSettings
            strippedTable={true}
            widthClass="w-40"
            callFor="labClient"
            labClient={labClient}
            setLabClient={setLabClient}
          />
        </Modal.Body>
        <Modal.Footer className="d-flex justify-content-between">
          <Button variant="primary" className="headerButton btn-fill" onClick={() => handleClose()}>
            Cancel
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default PrinterSettingsModal;
