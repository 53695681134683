/*!

=========================================================
* Light Bootstrap Dashboard React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/light-bootstrap-dashboard-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/light-bootstrap-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/

import Test from "views/Test";
import TableListApiNew from "views/GraphQL/TableListApiNew";
import ProcessedTestListApi from "views/GraphQL/ProcessedTestListApi";
import Typography from "views/Typography.js";
import Icons from "views/Icons.js";
import Maps from "views/Maps.js";
import Notifications from "views/Notifications.js";
import Upgrade from "views/Upgrade.js";
import Settings from "views/Settings.js";
import ProcessedTestListApiNew from "views/GraphQL/ProcessedTestListApiNew";

const labRoutesNew = [
  {
    path: "/newtests/:labID",
    name: "New Tests",
    icon: "nc-icon nc-notes",
    component: TableListApiNew,
    layout: "/lab",
  },
  {
    path: "/processing/:labID",
    name: "Tests Processing",
    icon: "nc-icon nc-notes",
    component: TableListApiNew,
    layout: "/lab",
  },
  {
    path: "/processedtests/:labID",
    name: "Processed Tests",
    icon: "nc-icon nc-check-2",
    component: ProcessedTestListApiNew,
    layout: "/lab",
  },
  
  {
    path: "/test/:labID",
    name: "Test",
    icon: "nc-icon nc-circle-09",
    component: Test,
    layout: "/lab",
    redirect: true,
  },
  
];

export default labRoutesNew;
