import { createAsyncThunk } from "@reduxjs/toolkit";
import { ADD_SELECTED_INVOICES, RESET_SELECTED_INVOICES } from "./invoicePreviewConstant";

export const addSelectedInvoice = createAsyncThunk(ADD_SELECTED_INVOICES, async (invoice) => {
  return invoice;
});

export const resetInvoice = createAsyncThunk(RESET_SELECTED_INVOICES, async () => {
  return;
});
