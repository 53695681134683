import React from "react";

const StatsView = (props) => {
  const { label, value, type, handleClick, numberStyle, cssClass } = props;

  return (
    <div className={`preRegistrationStat cursor-pointer ${cssClass}`} onClick={() => handleClick && handleClick(type)}>
      <div>{label}</div>
      <div>
        <a style={{ color: "#42cef5", ...numberStyle }}>{value ?? 0}</a>
      </div>
    </div>
  );
};

export default StatsView;
