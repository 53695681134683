import { API, Auth } from "aws-amplify";
import { userCompanyID } from "utils";
import { DataStore } from "@aws-amplify/datastore";
// const apiName = "apif79b844d";
// const publicApiName = "api4af2df69";

const apiName = "AdminQueries";
const publicApiName = "usersApi";

export const AWS_API_METHODS = {
  delete: "del",
  get: "get",
  post: "post",
  put: "put",
};

export const callAmplifyAPI = async (path, payload, method = AWS_API_METHODS.post) => {
  try {
    const params = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `${(await Auth.currentSession()).getAccessToken().getJwtToken()}`,
      },
      body: { ...payload, clientID: userCompanyID.get() },
    };

    let apiRes = null;

    apiRes = await API[method](apiName, path, params);

    return apiRes;
  } catch (err) {
    throw Error(err);
  }
};

export const callDataStore = async (model, condition) => {
  try {
    const models = await DataStore.query(model);

    return models;
  } catch (err) {
    throw Error(err);
  }
};
