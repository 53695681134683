import ViewOnlyCheckBox from "../CheckBox/ViewOnlyCheckBox";
import TDNameWithDetail from "./TDNameWithDetail";
import TDPreRegisteration from "./TDPreRegisteration";
import editIcon from "../../assets/img/edit-icon.png";
import { TEST_TYPE_VALUE } from "constant";
import { formatPhoneNumber, openSite } from "utils";
import { siteStatus } from "utils";
import { Link } from "react-router-dom";
import DropdownMenu from "components/DropdownMenu/DropdownMenu";
import { remove } from "lodash";
const options = [
  {
    title: "Edit",
  },
  {
    title: "Delete",
  },
  {
    title: "Launch Portal",
  },
];

const TRData = (props) => {
  const {
    cssClass,
    site,
    item,
    getName,
    checkboxescehcked,
    handleCheckBoxChange,
    getMoreInfo,
    getTotalTest,
    handleDelete,
    handleLaunchPortal,
    handleEdit,
    handleShowSettingsModal,
    handleCopyPRLink,
    downloadQRCode,
    currentURL,
  } = props;
  let client = getMoreInfo("client", item.clientID);
  const handleDropDownOptions = (item, value) => {
    switch (value) {
      case "Edit":
        handleEdit(item);
        break;
      case "Delete":
        handleDelete(item.id);
        break;
      case "Launch Portal":
        handleLaunchPortal(item.id);
      default:
        break;
      // code block
    }
  };

  return (
    <tr className="trDataWrapper">
      <td>
        <ViewOnlyCheckBox
          bgColor="red"
          checked={checkboxescehcked.indexOf(item.id) !== -1}
          id={item.id}
          item={item}
          handleChange={(e) => handleCheckBoxChange(e, item.id)}
        />
      </td>

      <TDNameWithDetail title={item.clientID && getName(item.clientID)} label={item.name} cssClass="text-uppercase" />
      <TDNameWithDetail title={client.name} label={client.email} />
      <TDNameWithDetail
        title={item.contact_name}
        label={formatPhoneNumber(item.contact_phone || item.phone_number)}
        cssClass="text-uppercase"
      />
      <td className="fs-6 text-primary-black text-center">{getTotalTest(item)}</td>
      <TDPreRegisteration
        item={item}
        handleCopyPRLink={handleCopyPRLink}
        downloadQRCode={downloadQRCode}
        currentURL={currentURL}
      />
      <td className={`fs-6 text-primary-black text-captilize text-center`}>
        {TEST_TYPE_VALUE[item.orderKitType] || <span className="secondary-gray">No Details</span>}
      </td>
      <td className="text-center">
        <span
          className="badge bg-purple text-blue fs-14 rounded-1 cursor-pointer"
          onClick={() => handleShowSettingsModal(item)}
        >
          Settings
        </span>
      </td>
      <td className="fs-14 text-dark-yellow text-center">{siteStatus(item.status)}</td>
      {/* <td className="text-center">
        <i className="fas fa-download cursor-pointer download-icon" />
      </td> */}
      {/* <td className="fs-14 text-dark-danger text-center">
        <Link
          className="ellipsis text-dark-danger text-decoration-none linkHoverDangerText"
          onClick={() => {
            openSite(item?.id);
          }}
        >
          Launch Portal
        </Link>
      </td> */}
      <td className="cursor-pointer text-center">
        {/* <i className="fa fa-trash secondary-gray"></i> */}
        <DropdownMenu options={options} handleDropDownOptions={(e) => handleDropDownOptions(item, e)} />
      </td>
    </tr>
  );
};
export default TRData;
