/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "eu-west-1",
    "aws_cloud_logic_custom": [
        {
            "name": "AdminQueries",
            "endpoint": "https://672ybdebdf.execute-api.eu-west-1.amazonaws.com/devtest",
            "region": "eu-west-1"
        },
        {
            "name": "usersApi",
            "endpoint": "https://8i0rs54sqk.execute-api.eu-west-1.amazonaws.com/devtest",
            "region": "eu-west-1"
        }
    ],
    "aws_appsync_graphqlEndpoint": "https://bu3wd4ceovezxddfwv7sslq6hu.appsync-api.eu-west-1.amazonaws.com/graphql",
    "aws_appsync_region": "eu-west-1",
    "aws_appsync_authenticationType": "AMAZON_COGNITO_USER_POOLS",
    "aws_appsync_apiKey": "da2-u3p6zssuqvdnldzha7szute66m",
    "aws_cognito_identity_pool_id": "eu-west-1:8c37001b-e0a7-4268-97dc-fe45b5f441ab",
    "aws_cognito_region": "eu-west-1",
    "aws_user_pools_id": "eu-west-1_4LYZ1z50c",
    "aws_user_pools_web_client_id": "6a37fm0pt763g4skvhkcm16f8o",
    "oauth": {},
    "aws_cognito_username_attributes": [
        "PHONE_NUMBER"
    ],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [
        "EMAIL"
    ],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": []
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ],
    "aws_user_files_s3_bucket": "crnaeb3085eb7b5f48fd9fc30dfb9bd7ef3c222534-devtest",
    "aws_user_files_s3_bucket_region": "eu-west-1"
};


export default awsmobile;
