import PhoneNoInput from "components/Phone/PhoneNoInput";
import viewIcon from "../../assets/img/eye-open-icon.png";
import hideIcon from "../../assets/img/eye-not-open-icon.png";
import { useState } from "react";
const LoginUi = ({
  phone,
  setPhone,
  password,
  setPassword,
  handleKeyDownEvent,
  disableBtn,
  handleSubmit,
  handleSendOTPUi,
}) => {
  const [showPassword, setShowPassword] = useState(false);

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  return (
    <div className="login-right-side">
      <h3 className="mt-0 mb-4">Login</h3>
      {/* <p className="tag-line">Sign in to your account</p> */}

      <div className="form-group mb-3 phoneInput">
        <label className="login-input-label mb-2">Phone Number</label>
        <PhoneNoInput handleChange={(e) => setPhone(e)} value={phone} />
      </div>
      <label className="login-input-label mb-2">Password</label>
      <div className="position-relative">
        <input
          className="w-100"
          placeholder="Enter your  password"
          minLength={8}
          onKeyDown={handleKeyDownEvent}
          value={password}
          type={showPassword ? "text" : "password"}
          onChange={(e) => setPassword(e.target.value)}
        />
        <span
          onClick={togglePasswordVisibility}
          style={{
            position: "absolute",
            right: "10px",
            top: "50%",
            transform: "translateY(-50%)",
            cursor: "pointer",
          }}
        >
          {showPassword ? (
            <img className="header-btn-img object-fit-contain" width={20} height={20} src={viewIcon} alt={`viewIcon`} />
          ) : (
            <img className="header-btn-img object-fit-contain" width={20} height={20} src={hideIcon} alt={`hideIcon`} />
          )}
        </span>
      </div>
      <div style={{ marginTop: "5px", textAlign: "right", cursor: "pointer" }} onClick={handleSendOTPUi}>
        <p style={{ fontSize: "14px" }} className="linkedText">
          Forgot Password?
        </p>
      </div>

      <p className="mt-2">
        I have read and agree to the
        <a
          className="text-decoration-none"
          target="_blank"
          href="https://medflow-images.s3.eu-west-1.amazonaws.com/PrivacyPolicy-MedFlow.pdf"
        >
          &nbsp; Privacy Policy
        </a>
        &nbsp; and &nbsp;
        <a
          className="text-decoration-none"
          href="https://medflow-images.s3.eu-west-1.amazonaws.com/TermsofUse-MedFlow.pdf"
          target="_blank"
        >
          Terms and Use &nbsp;
        </a>
      </p>
      <div className="text-right w-100">
        <button
          type="submit"
          disabled={disableBtn}
          className="text-uppercase w-100 btn signin-btn"
          onClick={handleSubmit}
        >
          Sign In
        </button>
      </div>
    </div>
  );
};

export default LoginUi;
