import React, { memo, useRef } from "react";

const MainTableInputBox = memo(({ value, placeholder, type, disabled = false , handleBlur}) => {
  const inputRef = useRef(null);

//   const handleBlur = (event) => {
//     onUpdateResultMark(event.target.value, row.uniqueId);
//   };

  return (
    <td>
      <input
        className="w-100 modalInput"
        ref={inputRef}
        defaultValue={value || ""}
        onBlur={handleBlur}
        onFocus={() => inputRef.current && inputRef.current.select()}
        disabled={disabled}
        type={type || "text"}
        placeholder={placeholder || ""}
      />
    </td>
  );
});

export default MainTableInputBox;
