import { createAsyncThunk } from "@reduxjs/toolkit";
import saleApi from "api/saleApi";
import {
  DELETE_SALE_PERSON,
  FETCH_SALES_PERSONS,
  SELECTED_CLIENT_ID,
  SELECTED_SALE_PERSON,
  SET_SHOW_SALES_PERSON_ASSIGN_MODAL,
  SET_SHOW_SALES_PERSON_MODAL,
  UNLINK_SALES_PERSON,
  UPDATE_SALES_PERSON_IN_LIST,
  SALE_REPORT_FILTERS,
} from "./salePersonConstant";
import { MESSAGE_MODES } from "constant";
import { setMessage } from "../general/generalAction";

export const setSelectedSalePerson = createAsyncThunk(SELECTED_SALE_PERSON, async (selectedPerson) => {
  return selectedPerson;
});

export const setSelectedClientId = createAsyncThunk(SELECTED_CLIENT_ID, async (selectedClientId) => {
  const salesPersonsRes = await saleApi.listSalePerson({ siteID: selectedClientId });
  return { selectedClientId, salesPersonsList: salesPersonsRes.rows, totalSalesPerson: +salesPersonsRes.total || 0 };
});

export const fetchSalePersons = createAsyncThunk(FETCH_SALES_PERSONS, async (obj) => {
  const salesPersonsRes = await saleApi.listSalePerson(obj);
  return { salesPersonsList: salesPersonsRes.rows, totalSalesPerson: +salesPersonsRes.total || 0 };
});

export const fetchSaleFilter = createAsyncThunk(SALE_REPORT_FILTERS, async (obj) => {
  const saleReportFilterRes = await saleApi.saleReportFilter(obj);
  return { ...saleReportFilterRes };
});

export const setShowSalesPersonModal = createAsyncThunk(SET_SHOW_SALES_PERSON_MODAL, async (showSalesPersonModal) => {
  return showSalesPersonModal;
});

export const updateSalesPersonInList = createAsyncThunk(UPDATE_SALES_PERSON_IN_LIST, async (updatedSalesPerson) => {
  return updatedSalesPerson;
});

export const deleteSalesPerson = createAsyncThunk(DELETE_SALE_PERSON, async (deletedSalesPerson) => {
  await saleApi.deleteSalePerson({ id: deletedSalesPerson.id });
  return deletedSalesPerson;
});

export const setSalesPersonAssignDetails = createAsyncThunk(
  SET_SHOW_SALES_PERSON_ASSIGN_MODAL,
  async (salesPersonAssignDetails) => {
    return salesPersonAssignDetails;
  }
);

export const unLinkSalesPerson = createAsyncThunk(UNLINK_SALES_PERSON, async (linkSalesPersonData, thunkApi) => {
  const { dispatch } = thunkApi;
  const { siteId, salePersonId } = linkSalesPersonData;
  const res = await saleApi.unlinkSalesPerson({ siteID: siteId, salePersonID: salePersonId });
  if (!res) dispatch(setMessage("Failed unlinking", MESSAGE_MODES.error));
  return { ...linkSalesPersonData, confirmed: !!res };
});
