import React, { useState, useEffect, useContext } from "react";
import API from "api";
// react-bootstrap components
import DashboardLineChart from "./DashboardLineChart";
import DashboardPieChart from "./DashboardPieChart";
import DashboardBarChart from "./DashboardBarChart";
import DashboardChartTotalTestsDataCard from "./DashboardChartTotalTestsData";

import { AppContext } from "context/app-context";
import { connectedIDInLocalStorage, formateDateLL } from "utils";



const LabDashboard = (props) => {

    const { lab } = props;

    const [totalTestCounts, setTotalCounts] = useState('');

    const [summaryData, setSummaryData] = useState({ positiveTests: 0, negativeTests: 0, totalTests: 0, inconclusiveTests: 0, positiveTest: null });
    const appContext = useContext(AppContext);
    const connectedIDFromRoute = window.location?.pathname?.split("/").pop();
    const [routingPathBasedMedFlow, setRoutingPathBasedMedFlow] = useState({
        userType: "E",
        labID: connectedIDFromRoute.toLowerCase() !== "admin" ? connectedIDFromRoute : connectedIDInLocalStorage.get() || "A",
    });

    useEffect(() => {
        loadSummaryData();
    }, []);

    const loadSummaryData = async () => {
        const connectedID = routingPathBasedMedFlow.labID || connectedIDInLocalStorage.get() || "A";

        const data = await API.getSummaryFromPG({ labID: connectedID });
        let newObj = {};
        if (data.rows.length > 0) {

            newObj = data.rows.reduce((obj, item) => {
                if (item["result"] === "Negative" || item["result"] === "negative"  ) {
                    obj.negativeTests = parseInt(item["count"]);
                }
                if (item["result"] === "Inconclusive" || item["result"] ==="inconclusive") {
                    obj.inconclusiveTests = parseInt(item["count"]);
                }
                if (item["result"] === "" || item["result"] === "pending") {
                    obj.pendingTests = parseInt(item["count"]);
                }
                obj.totalTests =  obj.pendingTests + obj.inconclusiveTests +  obj.negativeTests ;
       
                return obj;
            }, summaryData);

            setTotalCounts(newObj);
            
        }

       
    };

    return (

        <div className="dashboard-wrapper bg-transparent">
            <div className="container-fluid mb-3">
                <h4 className="mt-0">Lab Summary</h4>
                <DashboardChartTotalTestsDataCard totalTestCounts={totalTestCounts} />
            </div>
            <div className="dashboard-wrapper">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-12 col-md-6 col-lg-6 col-sm-12 col-xl-4">
                            <div className="bg-white rounded-3 pt-3 position-relative box-height">
                                <DashboardBarChart statsType={'labstats'} statsTitle="Date Wise Stats" connectedID={routingPathBasedMedFlow.labID} />
                            </div>
                        </div>

                        <div className="col-12 col-md-6 col-lg-6 col-sm-12 col-xl-4">
                            <div className="bg-white rounded-3 pt-3 position-relative box-height">
                                <div className="box-inner-height">
                                    <DashboardLineChart statsType={'labstats'} statsTitle="Test-Type Wise Stats"  connectedID={routingPathBasedMedFlow.labID} />
                                </div>
                            </div>
                        </div>

                        <div className="col-12 col-md-6 col-lg-6 col-sm-12 col-xl-4">
                            <div className="bg-white rounded-3 pt-3 position-relative box-height">
                                <DashboardPieChart statsType={'labstats'} statsTitle="Lab Stats" chartData={totalTestCounts} connectedID={routingPathBasedMedFlow.labID} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
           
        </div>

    );
};



export default LabDashboard;





