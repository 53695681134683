import { CONFIG } from "constant";
import { useEffect, useState } from "react";
import PhoneInput, { isPossiblePhoneNumber, parsePhoneNumber } from "react-phone-number-input";
import "react-phone-number-input/style.css";
import { useSelector } from "react-redux";
import { selectCountryCode } from "store/features/authentication/authenticationSelectors";

const getDefaultCountryCode = (labCountryCode) => {
  if (CONFIG.clientID === "e33ea32c-7cc2-4609-b3b2-35d8309df342") {
    return "CA";
  }
  return labCountryCode || "US";
};

const PhoneNoInput = (props) => {
  const { handleChange, value, error, disabled = false, cssCode, cssClass } = props;

  const labCountryCode = useSelector(selectCountryCode);

  const [defaultCountryCode, setDefaultCountryCode] = useState(getDefaultCountryCode(labCountryCode));

  useEffect(() => {
    if (error) error(validatePhone());
  });

  useEffect(() => {
    if (value && isPossiblePhoneNumber(value)) {
      let countryCode = parsePhoneNumber(value)?.country;
      setDefaultCountryCode(countryCode || getDefaultCountryCode());
    }
  }, [value]);

  const validatePhone = () => {
    if (value) {
      if (isPossiblePhoneNumber(value)) {
        return "";
      }
      return "Invalid phone number";
    }
    return "";
  };

  return (
    <PhoneInput
      key={defaultCountryCode}
      international
      countryCallingCodeEditable={true}
      className={`modalPhoneInput ${cssClass}`}
      defaultCountry={defaultCountryCode || labCountryCode}
      placeholder="Phone"
      value={value}
      disabled={disabled}
      onChange={handleChange}
      error={validatePhone()}
    />
  );
};

export default PhoneNoInput;
