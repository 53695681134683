import { PDFViewer } from "@react-pdf/renderer";
import API from "api";
import ViewOnlyCheckBox from "components/CheckBox/ViewOnlyCheckBox";
import ExportToExcel from "components/ExportToExcel";
import Filter from "components/Filter";
import Icon from "components/Icon";
import Message from "components/Message/Message";
import ConfirmationModal from "components/Modal/ConfirmationModal";
import ContactInfo from "components/Modal/ContactInfo";
import TestSubmitModal from "components/Modal/TestSubmitModal";
import MFPagination from "components/Pagination/MFPagination";
import PDF from "components/PDF";
import TestPDFLayout from "components/PDF/TestPDFLayout";
import SequenceNo from "components/SequenceNo";
import Header from "components/Table/Header";
import TimeFilter from "components/TimeFilter";
import { CONFIG, PAGE_LIMIT, RESULT_VALUE, stringConstant, TEST_HEADER_TYPE } from "constant";
import { AppContext } from "context/app-context";
import moment from "moment";
import React, { useContext, useEffect, useMemo, useState } from "react";
import "react-activity/dist/Bounce.css";
// react-bootstrap components
import { Button, Card, Col, Container, Row, Table } from "react-bootstrap";
import { Link, useLocation } from "react-router-dom";
import {
  changeSort,
  connectedIDInLocalStorage,
  formatPhoneNumber,
  formatTest,
  formatUTCDate,
  getValidName,
  sortingFilterInLC,
  sortTestList,
} from "utils";
import GQAPI from "views/GraphQL/gqApi";
import Loader from "../../components/Loader/Loader";

const DeleteTestApi = () => {
  const [tests, setTests] = useState([]);
  const [filteredTests, setFilteredTests] = useState([]);
  const [labs, setLabs] = useState([]);
  const [sites, setSites] = useState([]);
  const [moreInfo, setMoreInfo] = useState({});
  const [modalShow, setModalShow] = useState(false);
  const [sortBy, setSortBy] = useState("");
  const [sortDescending, setSortDescending] = useState(true);
  const [testsLab, setTestsLab] = useState();
  const [testPdf, setTestPdf] = useState();
  const [showPdf, setShowPdf] = useState(false);
  const [timeFilter, setTimeFilter] = useState({});
  const [showFilter, setShowFilter] = useState(false);
  const [filter, setFilter] = useState({});
  const [triggerFilter, setTriggerFilter] = useState();
  const [checkboxes, setCheckboxes] = useState([]);
  const [allSelected, setAllSelected] = useState(false);
  const [loading, setLoading] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const [importStatus, setImportStatus] = useState();
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [showErrorMessage, setShowErrorMessage] = useState(false);
  const [openConfirmation, setOpenConfirmation] = useState(false);
  const [openRestoredConfirmation, setOpenRestoredConfirmation] = useState(false);
  const { filterDates } = TimeFilter;

  const location = useLocation();
  const appContext = useContext(AppContext);

  const searchTerms = [
    "First Name",
    "Last Name",
    "Date of Birth",
    "Email",
    "Phone",
    "Client",
    `${stringConstant.show}`,
    "Lab",
    `${stringConstant.sequenceNo}`,
    "Test Type",
    "Tester",
    "isVaccinated",
    "White Glove",
    "Pattern",
    "Capacity",
  ];

  const selectedItem = CONFIG.isWhiteLabel
    ? [
        "createdAt",
        "firstName",
        "lastName",
        "dob",
        "email",
        "phoneNumber",
        "siteID",
        "status",
        "test_type",
        `${stringConstant.accession_no}`,
      ]
    : [
        "createdAt",
        "firstName",
        "lastName",
        "dob",
        "email",
        "phoneNumber",
        "clientID",
        "siteID",
        "labID",
        "status",
        "test_type",
        "sequenceNo",
        "tester_name",
        "isVaccinated",
        "employeeType",
        "whiteGlove",
        "patternTestAnswer",
      ];

  const displayOwner = (test) => {
    let displayedId = test.owner.substr(-12);
    return displayedId;
  };

  const createMoreInfo = (type, id) => {
    let infoObj = null;
    if (type === "site") infoObj = appContext.sites.find((site) => site.id === id);
    else if (type === "client") infoObj = appContext.clients.find((client) => client.id === id);
    else infoObj = labs.find((lab) => lab.id === id);

    if (infoObj) {
      setMoreInfo({
        type,
        name: infoObj.name,
        contact: infoObj.contact_name,
        phone: infoObj.contact_phone || infoObj.phone_number,
        email: infoObj.contact_email,
      });
      setModalShow(true);
    }
  };

  const nestedFilter = (targetArray, filters) => {
    if (Object.keys(filter).length === 0) return targetArray;
    const filterKeys = Object.keys(filters);
    //filters main array of objects
    const models = targetArray.filter((obj) => {
      //goes through each key being filtered for
      return filterKeys.every((key) => {
        if (!filters[key].length) {
          return true;
        }
        if (key === "phoneNumber") {
          return obj[key] && obj[key].replace(/\D/g, "").includes(filters[key].replace(/\D/g, ""));
        }
        if (key === "Lab") {
          return obj.labID && appContext.getLabName(obj.labID).toLowerCase().includes(filters[key]);
        }
        if (key === "Client") {
          return (
            obj.clientID && appContext.getClientName(obj.clientID).toLowerCase().includes(filters[key].toLowerCase())
          );
        }
        if (key === "Tester") {
          return obj.tester_name && obj.tester_name.toLowerCase().includes(filters[key].toLowerCase());
        }

        if (key === "Show") {
          const siteIds = appContext.sites
            .filter((s) => s.name.toLowerCase().includes(filters[key].toLowerCase()))
            .map((s) => s.id);
          return siteIds.includes(obj.siteID);
        }
        if (key === "sequenceNo") {
          return obj[key] && obj["sequenceNo"].toString().includes(filters[key]);
        }
        if (key === "dob") {
          return obj[key] && obj[key] === filters[key];
        }

        if (key === "test_type" && filters[key] && filters[key].length > 0) {
          return obj[key] && filters[key].indexOf(obj[key]) !== -1;
        }
        if (key === "vaccinated") {
          return (obj["isVaccinated"] ? 1 : 0).toString() === filters[key];
        }

        if (key === "whiteGlove") {
          return (obj["whiteGlove"] ? 1 : 0).toString() === filters[key];
        }

        if (key === "capacity") {
          return obj["employeeType"] && obj["employeeType"].toLowerCase().includes(filters[key].toLowerCase());
        }

        if (key === "pattern") {
          if (filters[key] === "1") return obj["patternTestAnswer"] && obj["patternTestAnswer"] !== "undefined";

          if (filters[key] === "0") return obj["patternTestAnswer"] == "undefined" || obj["patternTestAnswer"] == "";
        }

        return obj[key] && obj[key].toLowerCase().includes(filters[key].toLowerCase());
      });
    });
    return models;
  };

  const displayPdf = (test) => {
    appContext.getLab(test).then(async (resp) => {
      setTestsLab(resp);
      setShowPdf(true);
    });
    setTestPdf(formatTest(test));
  };

  const flipSort = (by) => {
    setSortDescending(sortBy === by ? !sortDescending : true);
    setSortBy(by);
  };

  const handleCheckboxChange = (e, id) => {
    const filteredList = checkboxes.filter((c) => c !== id);
    if (e.target.checked) {
      filteredList.push(id);
    }
    setCheckboxes(filteredList);
  };

  const toggleCheckboxes = (val) => setCheckboxes(val ? filteredTests.map((t) => t.id) : []);

  useEffect(() => {
    setLoading(true);
    loadPrevSorting();
    fetchDeletedTests();
  }, []);

  useEffect(() => {
    setLoading(true);
    setLabs(appContext.labs);
    setSites(appContext.sites);
  }, [appContext.labs, appContext.sites]);

  useEffect(() => {
    const prevSortingState = sortingFilterInLC.get();
    prevSortingState["expiredtests"] = { filter, timeFilter, sortBy, sortDescending };
    sortingFilterInLC.save(prevSortingState);
    changeSort(
      sortBy,
      sortDescending,
      filteredTests,
      setFilteredTests,
      appContext.getSiteName,
      appContext.getLabName,
      appContext.getClientName
    );
  }, [sortBy, sortDescending]);

  useEffect(() => {
    if (location.state) {
      setShowFilter(true);
      setFilter({ ...filter, [location.state.term]: location.state.name });
      setTriggerFilter(true);
    }
  }, [location.state]);
  useEffect(() => {
    setFilteredTests(filterDates(nestedFilter(tests, filter), timeFilter));
  }, [filter, triggerFilter, timeFilter]);

  const fetchDeletedTests = async () => {
    const eTests = await GQAPI.getDeletedTestList(CONFIG.isWhiteLabel ? connectedIDInLocalStorage.get() : "123");
    const tests = eTests.map((test) => {
      return formatTest(test);
    });
    setTests(tests);
    refreshTestList(tests);
    setLoading(false);
  };

  const refreshTestList = (list) => {
    if (sortBy) {
      setFilteredTests(
        sortTestList(
          sortBy,
          sortDescending,
          list,
          appContext.getSiteName,
          appContext.getLabName,
          appContext.getClientName,
          appContext.parseResult
        )
      );
    } else {
      setFilteredTests(list);
    }
  };

  const loadPrevSorting = () => {
    const sortingFilters = sortingFilterInLC.get();
    if (sortingFilters.expiredtests.sortBy) {
      setSortDescending(sortingFilters.expiredtests.sortDescending);
      setSortBy(sortingFilters.expiredtests.sortBy);
    }
  };

  const testsToMap = useMemo(() => {
    const list = filteredTests ? [...filteredTests] : [];
    const ttlPages = list.length > PAGE_LIMIT ? Math.ceil(list.length / PAGE_LIMIT) : 1;
    setTotalPages(ttlPages);
    if (list.length < PAGE_LIMIT || currentPage > ttlPages) {
      setCurrentPage(1);
    }
    if (ttlPages > 1) {
      const indexOfLastUser = currentPage * PAGE_LIMIT;
      const indexOfFirstUser = indexOfLastUser - PAGE_LIMIT;
      return list.slice(indexOfFirstUser, indexOfLastUser);
    }
    return list;
  }, [filteredTests, currentPage]);

  const handleSaveFilter = () => {
    appContext.setTestFilter({ location: "unprocessedtest", filter, timeFilter });
  };

  const deleteTest = async (test) => {
    setLoading(true);
    await API.deleteWrongTest(test.id);
    fetchDeletedTests();
  };

  const TableRow = ({ test }) => {
    return (
      <tr className="trDataWrapper">
        <td className="ellipsis" style={{ textAlign: "left" }}>
          {/* <Form.Check>
            <Form.Check.Input
              type="checkbox"
              value={test.id}
              checked={checkboxes.indexOf(test.id) !== -1}
              onChange={(e) => handleCheckboxChange(e, test.id)}
            ></Form.Check.Input>
          </Form.Check> */}

          <ViewOnlyCheckBox
            bgColor="red"
            id={test.id}
            checked={checkboxes.indexOf(test.id) !== -1}
            item={test}
            handleChange={(e) => handleCheckboxChange(e, test.id)}
          />
        </td>
        <td className="ellipsis" title={formatUTCDate(test.createdAt)}>
          {formatUTCDate(test.createdAt)}
        </td>
        <td className="table-column ellipsis" title={getValidName(test.firstName)}>
          {getValidName(test.firstName)}
        </td>
        <td className="table-column ellipsis" title={getValidName(test.lastName)}>
          {getValidName(test.lastName)}
        </td>
        <td className="ellipsis" title={test.dob}>
          {test.dob}
        </td>
        <td
          className="table-column ellipsis text-dark-danger text-dark-danger-hover cursor-pointer text-decoration-none"
          onClick={() => test?.email && window.open(`mailto:${test.email}`)}
          title={test.email}
        >
          {test.email}
        </td>
        <td
          className="table-column ellipsis text-dark-danger text-dark-danger-hover cursor-pointer text-decoration-none"
          onClick={() => {
            test.phoneNumber && window.open(`tel:+1${test.phoneNumber}`);
          }}
          title={test.phoneNumber && formatPhoneNumber(test.phoneNumber)}
        >
          {test.phoneNumber && formatPhoneNumber(test.phoneNumber)}
        </td>
        {!CONFIG.isWhiteLabel && (
          <td className="ellipsis" style={{ textAlign: "center" }}>
            <a
              style={{ color: "#42cef5" }}
              onClick={() => createMoreInfo("client", test.clientID)}
              onMouseOver={(e) => (e.target.style.cursor = "pointer")}
              title={appContext.getClientName(test.clientID)}
            >
              {appContext.getClientName(test.clientID)}
            </a>
          </td>
        )}
        <td className="ellipsis" style={{ textAlign: "center" }} title={test.test_type || "-"}>
          {test.test_type || "-"}
        </td>
        <SequenceNo
          num={CONFIG.isWhiteLabel ? test.barcode : test.sequenceNo}
          test={test}
          showLogs={appContext.showLogs}
        />
        <td className="ellipsis" style={{ textAlign: "center" }}>
          <a
            style={{ color: "#42cef5" }}
            onClick={() => createMoreInfo("site", test.siteID)}
            onMouseOver={(e) => (e.target.style.cursor = "pointer")}
            title={appContext.getSiteName(test.siteID)}
          >
            {appContext.getSiteName(test.siteID)}
          </a>
        </td>
        {!CONFIG.isWhiteLabel && (
          <td className="ellipsis" style={{ textAlign: "center" }}>
            <a
              style={{ color: "#42cef5" }}
              onClick={() => createMoreInfo("lab", test.labID)}
              onMouseOver={(e) => (e.target.style.cursor = "pointer")}
              title={appContext.getLabName(test.labID)}
            >
              {appContext.getLabName(test.labID)}
            </a>
          </td>
        )}
        <td
          className="ellipsis"
          style={{
            textAlign: "center",
            color: test.status === "Pending" ? "orange" : "black",
          }}
          title={test.status}
        >
          {test.status}
        </td>

        <td className="ellipsis" style={{ textAlign: "center" }} title={test.tester_name || "NA"}>
          {test.tester_name}
        </td>
        <td className="ellipsis" style={{ textAlign: "center" }}>
          <Link
            className="text-dark-danger text-dark-danger-hover cursor-pointer text-decoration-none"
            to={{
              pathname: "/admin/test/" + test.id,
              state: { unprocessed: true },
            }}
            onClick={handleSaveFilter}
          >
            View
          </Link>

          {/*  <i
              className="fa fa-trash"
              style={{ marginLeft: "5px" }}
              onMouseLeave={(e) => (e.target.style.color = "black")}
              onMouseOver={(e) => {
                e.target.style.color = "#8B0000";
                e.target.style.cursor = "pointer";
              }}
              aria-hidden="true"
              id={test.id}
              onClick={(e) => deleteTest(test)}
            /> */}
        </td>
      </tr>
    );
  };

  const handleChangePage = (page) => setCurrentPage(page);
  const handleCloseContactInfo = () => {
    setMoreInfo({});
    setModalShow(false);
  };
  const updateTestResult = async (result) => {
    try {
      const updateSelectedTestResult = filteredTests.filter((test) => checkboxes.includes(test.id));
      if (!updateSelectedTestResult.length) {
        return;
      }
      setLoading(true);
      const ttl = updateSelectedTestResult.length;

      const params = updateSelectedTestResult.map((t) => {
        return {
          id: t.id,
          result: RESULT_VALUE[result.toLowerCase()],
          status: result === "positive" ? "Pending" : "Processed",
          timerStatus: result === "positive" ? "Pending" : "Processed",
          resultDateTime: moment().utc().toISOString(),
          resultDate: moment().utc().format("YYYY-MM-DD"),
          StampBy: appContext.user.sub,
          StampByName: appContext.user.name,
          emailSend: true,
          expired: false,
          sequenceNo: t.sequenceNo,
        };
      });
      await GQAPI.updateTestResult(params);
      setLoading(false);
      appContext.showSuccessMessage(`Status has been successfully updated of ${ttl} tests`);
      const newList = filteredTests.filter((test) => !checkboxes.includes(test.id));
      setTests(newList);
      setFilteredTests(newList);
    } catch (error) {
      appContext.showErrorMessage(`Fail to update the test Result`);
    }
    setCheckboxes([]);
  };
  const handleConfirmRestore = async (isConfirm) => {
    setOpenRestoredConfirmation(false);
    if (!isConfirm) {
      return;
    }
    setLoading(true);
    try {
      const testList = filteredTests.filter((test) => checkboxes.includes(test.id));
      const logs = testList.map((e) => {
        return {
          id: e.id,
          slug: "Restored",
          sequenceNo: e.sequenceNo,
          userName: appContext.user.name,
          userID: appContext.user?.sub,
        };
      });
      await GQAPI.addDeleteLogs(logs);
      const objData = filteredTests.filter((f) => !checkboxes.includes(f.id));
      setFilteredTests(objData);
      setCheckboxes([]);
      setAllSelected(false);
      appContext.showSuccessMessage(
        `Successfully Restore ${checkboxes.length} ${checkboxes.length > 1 ? "Tests" : "Test"}`
      );
      setLoading(false);
    } catch (err) {
      appContext.showErrorMessage(err.message);
      console.log(err.message);
      setLoading(false);
    }
  };
  const handleConfirm = async (isConfirm) => {
    setOpenConfirmation(false);
    if (!isConfirm) {
      return;
    }
    updateTestResult("positive");
  };
  return showPdf ? (
    <Container fluid>
      <Row>
        <Col md="12">
          <Card className="strpied-tabled-with-hover">
            <Card.Header>
              <Row style={{ alignItems: "center" }}>
                <Card.Title as="h4">
                  Showing Test PDF for{" "}
                  {typeof testPdf.employee_demographics === "string"
                    ? JSON.parse(testPdf.employee_demographics).firstName
                    : testPdf.employee_demographics.firstName}
                </Card.Title>
                <Button
                  style={{
                    marginTop: 10,
                  }}
                  className="createButtons"
                  onClick={() => setShowPdf(false)}
                >
                  Close
                </Button>
              </Row>
            </Card.Header>
            <Card.Body className="pdfContainer table-full-width table-responsive px-0">
              <PDFViewer className="pdfView">
                <PDF test={testPdf} testsLab={testsLab} parseTestResultForPDF={appContext.parseTestResultForPDF} />
              </PDFViewer>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  ) : !loading ? (
    <>
      <Container fluid>
        {showAlert && <TestSubmitModal data={importStatus} handleClose={setShowAlert} />}

        <Message
          title="No Test is selected"
          type="info"
          showAlert={showErrorMessage}
          handleClose={setShowErrorMessage}
        />
        {openConfirmation && (
          <ConfirmationModal
            show={openConfirmation}
            title="Result Positive"
            message="Are you sure, to result positive ?"
            handleConfirm={handleConfirm}
          />
        )}
        {openRestoredConfirmation && (
          <ConfirmationModal
            show={openRestoredConfirmation}
            title="Restore Test"
            message={`Are you sure, to restore this ${checkboxes.length} ${checkboxes.length > 1 ? "tests" : "test"} ?`}
            handleConfirm={handleConfirmRestore}
          />
        )}
        <div className="shows-screen-parent">
          <Row className="mx-0">
            <Col md="12">
              <Card className="border-0 mb-1">
                <Card.Body className="p-0">
                  <div className="shows-filter-wrapper">
                    <div className="shows-filter-inner">
                      <Icon
                        handleClick={() => setShowFilter(!showFilter)}
                        title={"Filter"}
                        label={"Filter"}
                        iconType={"filter"}
                      />
                      <Icon
                        handleClick={() => {
                          if (filteredTests && filteredTests.length > 0) {
                            setAllSelected(!allSelected);
                            toggleCheckboxes(!allSelected);
                          }
                        }}
                        title={!allSelected ? "Select All" : "Deselect All"}
                        label={!allSelected ? "Select All" : "Deselect All"}
                        iconType={"selectAllIcon"}
                      />
                      <Icon
                        disabled={checkboxes.length === 0}
                        handleClick={() => setOpenRestoredConfirmation(true)}
                        title={"Restore Test"}
                        label={"Restore Test"}
                        iconType={"restoreIcon"}
                      />

                      {filteredTests.length > 0 && (
                        <>
                          <ExportToExcel
                            items={filteredTests}
                            selectedItem={selectedItem}
                            appContext={appContext}
                            filter={filter}
                            timeFilter={timeFilter}
                            title="Expired Tests"
                            sortBy={sortBy}
                          />

                          {/* {Object.keys(filter).length > 0 && (
                            <>
                              <TestPDFLayout
                                tests={filteredTests}
                                selectedItem={selectedItem}
                                filter={filter}
                                timeFilter={timeFilter}
                                title="Expired Tests"
                                appContext={appContext}
                                sortBy={sortBy}
                              />
                            </>
                          )} */}
                        </>
                      )}
                    </div>
                  </div>
                  {showFilter && (
                    <Filter
                      filterTerms={searchTerms}
                      setFilter={setFilter}
                      filter={filter}
                      triggerFilter={triggerFilter}
                      setTriggerFilter={setTriggerFilter}
                    />
                  )}
                </Card.Body>
              </Card>
            </Col>
            <Col md="12">
              <Card className="strpied-tabled-with-hover bg-transparent border-0">
                <Card.Header className="shows-screen-wrapper">
                  <Row className="pb-4">
                    <Col md="6">
                      <Card.Title>
                        <div className="">
                          Deleted Tests:
                          <span className="ps-2">{filteredTests ? filteredTests.length : 0}</span>
                        </div>
                      </Card.Title>
                    </Col>
                    <Col md="6">
                      <MFPagination
                        currentPage={currentPage}
                        handleChangePage={handleChangePage}
                        totalPages={totalPages}
                        showSelectedRecord
                        totalSelected={checkboxes.length}
                      />
                    </Col>
                  </Row>
                </Card.Header>

                <Card.Body className="table-full-width px-0 desktop-noScroll pt-0 mt-0">
                  <div className="table-responsive pendingReleaseTable">
                    <Table className="separate-border-tr">
                      <Header
                        type={TEST_HEADER_TYPE.expired}
                        flipSort={flipSort}
                        sortBy={sortBy}
                        sortDescending={sortDescending}
                        cssClass="pendingReleaseTable"
                      />
                      <tbody>
                        {testsToMap &&
                          testsToMap.map((test) => {
                            return <TableRow key={test.id} test={test} />;
                          })}
                      </tbody>
                    </Table>
                  </div>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </div>
      </Container>
      <ContactInfo show={modalShow} moreInfo={moreInfo} handleClose={handleCloseContactInfo} />
    </>
  ) : (
    <Loader />
  );
};

export default DeleteTestApi;
