import {
  createProviderAsync,
  updateProviderAsync,
  deleteProviderAsync,
  updateProviderClientAsync,
  createPortalUserAsync,
  renderingProviderAsync,
} from "./providersSlice";

export const createProvider = (provider, defaultSetting) => async (dispatch) => {
  await dispatch(createProviderAsync({ provider, defaultSetting }));
};

export const updateProvider = (provider, defaultSetting) => async (dispatch) => {
  await dispatch(updateProviderAsync({ provider, defaultSetting }));
};

export const deleteProvider = (providerId) => async (dispatch) => {
  await dispatch(deleteProviderAsync(providerId));
};
export const udpateProviderClient = (data) => async (dispatch) => {
  await dispatch(updateProviderClientAsync(data));
};

export const createPortalNewUser = (data) => async (dispatch) => {
  await dispatch(createPortalUserAsync(data));
};

export const fetchRenderingProviders = (data) => async (dispatch) => {
  await dispatch(renderingProviderAsync(data));
};
