import React, { useState, useContext, useMemo, useRef, useCallback } from "react";

// react-bootstrap components
import {
  Badge,
  Button,
  Card,
  Navbar,
  Nav,
  Table,
  Container,
  Row,
  Col,
  Form,
  DropdownButton,
  Dropdown,
  Modal,
  InputGroup,
  FormControl,
} from "react-bootstrap";
import { useEffect } from "react";
import API from "api";
import GQAPI from "views/GraphQL/gqApi";
import { CSVLink } from "react-csv";
import { Importer, ImporterField } from "react-csv-importer";
import { Link } from "react-router-dom";
import { PDFViewer } from "@react-pdf/renderer";
import PDF from "components/PDF";
import TimeFilter from "components/TimeFilter";
import FilterSelect from "components/FilterSelect";
import Select from "react-select";
import { useLocation } from "react-router-dom";
import { useHistory } from "react-router-dom";
import HeaderItem from "components/Table/HeaderItem";
import PGPagination from "components/Pagination/PGPagination";
import _ from "lodash";
import {
  PAGE_LIMIT,
  RESULT_COLOR,
  TEST_HEADER_TYPE,
  newRoleOptions,
  SUPER_ADMIN_ID,
  RESULT_VALUE,
  TEST_EMPTY_OBJECT,
} from "constant";
import {
  changeSort,
  formatPhoneNumber,
  formatTest,
  sortTestList,
  sortingFilterInLC,
  formatDateOfBirthDOB,
  getDemoGraphics,
  formatDOB,
} from "utils";
import { AppContext } from "context/app-context";
import PDFLayout from "components/PDF/PDFLayout";
import ExportToExcelData from "components/ExportToExcelData";
import EmployeePDFLayout from "components/PDF/EmployeePDFLayout";
import moment from "moment";
import Loader from "components/Loader/Loader";
import AppTestLogsModal from "components/Modal/AppTestLogsModal";
import TestLogsModal from "components/Modal/TestLogsModal";
import ChangeTestConfigModal from "components/Modal/ChangeTestConfigModal";
import { debounce } from "debounce";
import { useInitialRender } from "components/useInitialRender";
import ProfileHeader from "components/Table/ProfileHeader";
import Filter from "components/Filter";
import EmployeeCrewProfileLogsModal from "components/Modal/EmployeeCrewProfileLogsModal";
import EmployeeCrewProfileMergedLogsModal from "components/Modal/EmployeeCrewProfileMergedLogsModal";
import { async } from "@firebase/util";
import Icon from "components/Icon";
import ViewOnlyCheckBox from "components/CheckBox/ViewOnlyCheckBox";

const ProfilesView = () => {
  const mainRef = useRef();
  const [width, setWidth] = useState(0);
  const history = useHistory();

  useEffect(() => {
    setWidth(mainRef.current.offsetWidth);
  }, [mainRef]);

  const handleResize = () => {
    setWidth(mainRef.current.offsetWidth);
  };

  useEffect(() => {
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  });

  const importResult = [];
  const [totalRecord, setTotalRecord] = useState(0);
  const [tests, setTests] = useState([]);
  const [filteredTests, setFilteredTests] = useState([]);
  const [moreInfo, setMoreInfo] = useState({});
  const [modalShow, setModalShow] = useState(false);
  const [removeDuplictaeModalShow, setRemoveDuplictaeModalShow] = useState(false);
  const [sortBy, setSortBy] = useState("");
  const [sortDescending, setSortDescending] = useState(true);
  const [testsLab, setTestsLab] = useState();
  const [testPdf, setTestPdf] = useState();
  const [showPdf, setShowPdf] = useState(false);
  const [timeFilter, setTimeFilter] = useState({});
  const [showFilter, setShowFilter] = useState(false);
  const [filter, setFilter] = useState({});
  const [triggerFilter, setTriggerFilter] = useState();
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [checkboxes, setCheckboxes] = useState([]);
  const [allSelected, setAllSelected] = useState(false);
  const [openDeleteConfirmation, setOpenDeleteConfirmation] = useState(false);
  const [loading, setLoading] = useState(false);
  const [testAppLogs, setTestAppLogs] = useState([]);
  const [showAppLog, setShowAppLog] = useState(false);
  const [showLog, setShowLog] = useState(false);
  const [testLogs, setTestLogs] = useState([]);
  const [pageNo, setPageNo] = useState(1);
  const [openCreator, setOpenCreator] = useState(false);
  const [selectedTest, setSelectedTest] = useState([]);
  const [showMergedProfileLogs, setShowMergedProfileLogs] = useState(false);
  const [savedEmployeeTests, setEmployeeTests] = useState([]);
  const [employeeTotalTestsCount, setEmployeeTotalTestsCounts] = useState(0);
  const [mergedProfilesTestsData, setMergedProfilesTestsData] = useState("");
  const { filterDates } = TimeFilter;

  const location = useLocation();
  const initialRender = useInitialRender();
  const appContext = useContext(AppContext);

  const searchTerms = ["First Name", "Last Name", "Date of Birth", "Email", "Phone", "ID Number", "Show"];

  const selectedItem = [
    "firstName",
    "lastName",
    "dob",
    "email",
    "phoneNumber",
    //"isVaccinated",
    "street",
    "ID Number",
    "site_name",
    "lastUsed",
  ];

  if (!appContext) {
    history.push("/login");
  }

  const displayPdf = async (test) => {
    handleSaveFilter();
    const empID = await appContext.getEmployeeID(test.employeeID);
    const t = test;
    t.empID = empID;
    appContext.getLab(test).then(async (resp) => {
      setTestsLab(resp);
      setShowPdf(true);
    });
    setTestPdf(formatTest(test));
  };

  const flipSort = (by) => {
    setSortBy(by);
    setSortDescending(sortBy === by ? !sortDescending : true);
  };

  const testsToMap = useMemo(() => {
    const list = filteredTests ? [...filteredTests] : [];
    const ttlPages = list.length > PAGE_LIMIT ? Math.ceil(list.length / PAGE_LIMIT) : 1;
    setTotalPages(ttlPages);
    if (list.length < PAGE_LIMIT || currentPage > ttlPages) {
      setCurrentPage(1);
    }
    if (ttlPages > 1) {
      const indexOfLastUser = currentPage * PAGE_LIMIT;
      const indexOfFirstUser = indexOfLastUser - PAGE_LIMIT;
      return list.slice(indexOfFirstUser, indexOfLastUser);
    }
    return list;
  }, [filteredTests, currentPage]);

  const loadEmployees = async (params, dateFilter, page, orderBy, orderByDirection) => {
    setLoading(true);
    const filterParam = params;
    try {
      Object.assign(filterParam, { page: page });
      if (dateFilter) {
        const { start, end } = dateFilter;
        if (start)
          Object.assign(filterParam, { startDate: moment(start.format("YYYY-MM-DD")).startOf("day").toISOString() });
        if (end) Object.assign(filterParam, { endDate: moment(end.format("YYYY-MM-DD")).endOf("day").toISOString() });
      }
      if (orderBy) {
        Object.assign(filterParam, { orderBy, orderByDirection });
      }

      const pgList = await API.getEmployeeListFromPG(filterParam);
      setLoading(false);

      let ttlTests = tests.concat(pgList.rows);
      if (pageNo === 1) {
        setTotalRecord(pgList.total);
        ttlTests = pgList.rows;
      }
      setAllSelected(false);
      setTests(ttlTests);
      refreshTestList(ttlTests);
    } catch (err) {
      setLoading(false);
    }
  };

  useEffect(() => {
    const savedFilters = appContext.testFilter;

    const sortingFilters = sortingFilterInLC.get();
    if (sortingFilters?.crewprofile?.sortBy) {
      setSortDescending(sortingFilters.crewprofile.sortDescending);
      setSortBy(sortingFilters.crewprofile.sortBy);
    }
    if (savedFilters && savedFilters.location === "profiles") {
      if (Object.keys(savedFilters.filter).length > 0) {
        setFilter(savedFilters.filter);
        setShowFilter(true);
      }
      if (Object.keys(savedFilters.timeFilter).length > 0) {
        setTimeFilter(savedFilters.timeFilter);
      }
    }
  }, []);

  useEffect(() => {
    const prevSortingState = sortingFilterInLC.get();
    prevSortingState["crewprofile"] = { filter, timeFilter, sortBy, sortDescending };
    sortingFilterInLC.save(prevSortingState);
    if (filteredTests.length === 0) return;
  }, [sortBy, sortDescending]);

  useEffect(() => {
    setPageNo(1);
    fetchFromApi({ ...filter }, { ...timeFilter }, 1, sortBy, sortDescending);
  }, [filter, timeFilter, sortBy, sortDescending]);

  useEffect(() => {
    if (pageNo > 1) {
      loadEmployees(Object.keys(filter).length === 0 ? { id: 1 } : filter, timeFilter, pageNo, sortBy, sortDescending);
    }
  }, [pageNo]);

  const fetchFromApi = useCallback(
    debounce((filter, timeFilter, page, sortBy, sortDescending) => {
      loadEmployees(filter, timeFilter, page, sortBy, sortDescending);
    }, 800),
    []
  );

  const refreshTestList = (list) => {
    if (sortBy) {
      setFilteredTests(
        sortTestList(
          sortBy,
          sortDescending,
          list,
          appContext.getSiteName,
          appContext.getLabName,
          appContext.getClientName,
          appContext.parseResult
        )
      );
    } else {
      setFilteredTests(list);
    }
  };

  const handleChangePage = (page, direction) => {
    setCurrentPage(page);
    const currentRecord = filteredTests.length;
    if (direction === "next" && page === totalPages && totalRecord > 0 && currentRecord < totalRecord) {
      setPageNo(pageNo + 1);
    }
  };

  const handleSaveFilter = () => {
    appContext.setTestFilter({ location: "", filter, timeFilter });
  };

  const handleCheckboxChange = (e, id, test) => {
    const filteredList = checkboxes.filter((c) => c !== id);
    const prevFilteredTests = selectedTest.filter((t) => t.id !== id);
    if (e.target.checked) {
      filteredList.push(id);
      setSelectedTest([...prevFilteredTests, test]);
    } else {
      setSelectedTest([...prevFilteredTests]);
    }
    setCheckboxes(filteredList);
  };

  const toggleCheckboxes = (val) => {
    if (val) {
      const newIds = [...checkboxes];
      const newTests = [...selectedTest];
      filteredTests.forEach((t) => {
        if (newIds.indexOf(t.id) === -1) {
          newIds.push(t.id);
          newTests.push(t);
        }
      });
      setCheckboxes(newIds);
      setSelectedTest(newTests);
    } else {
      const newIds = checkboxes.filter((c) => filteredTests.findIndex((t) => t.id === c) === -1);
      const newTests = selectedTest.filter((test) => newIds.includes(test.id));
      setCheckboxes(newIds);
      setSelectedTest(newTests);
    }
  };

  const handleDuplicateTest = () => {
    setRemoveDuplictaeModalShow(true);
    setEmployeeTotalTestsCounts(selectedTest.reduce((totalTests, obj) => totalTests + obj.totalTest, 0));
    setCheckboxes([...checkboxes]);

    // code commentted as we now show only test counts
    // const newIds = [...checkboxes];
    // const newTests = [...selectedTest];
    // let mdID = [];

    // if(newTests)
    // {
    //   newTests?.forEach(element => {
    //       mdID.push(element.mdID)
    //     });
    // }
    // if(mdID) {
    //   getEmployeeTests(mdID)
    // }
    // setCheckboxes(newIds);
  };

  const getEmployeeTests = (mdID) => {
    let employeeTests;
    let employeeTotalTestsCounts = 0;

    setTimeout(async () => {
      employeeTests = await API.getEmployeesTests(mdID);
      if (employeeTests) {
        employeeTests?.map((emp, index) => {
          if (selectedTest[index].mdID === emp.id) {
            selectedTest[index].testCount = emp.tests.length;
          }
          employeeTotalTestsCounts = employeeTotalTestsCounts + emp.tests.length;
        });

        setEmployeeTests(employeeTests);
        setEmployeeTotalTestsCounts(employeeTotalTestsCounts);
        setRemoveDuplictaeModalShow(true);
        setSelectedTest(selectedTest);
      }
    }, 200);
  };

  const TableRow = ({ test }) => {
    return (
      <tr className="trDataWrapper">
        <td className={test.preRegTest ? "ellipsis table-first-td" : "ellipsis"} style={{ textAlign: "left" }}>
          {/* <div className="d-flex align-items-center">
            <Form.Check className="me-2">
              <Form.Check.Input
                type="checkbox"
                value={test.id}
                checked={checkboxes.indexOf(test.id) !== -1}
                onChange={(e) => handleCheckboxChange(e, test.id, test)}
              ></Form.Check.Input>
            </Form.Check>
          </div> */}

          <ViewOnlyCheckBox
            bgColor="red"
            id={test.id}
            checked={checkboxes.indexOf(test.id) !== -1}
            item={test}
            handleChange={(e) => handleCheckboxChange(e, test.id, test)}
          />
        </td>

        <td className="ellipsis" title={test.firstName}>
          {test.firstName == "undefined" ? "-" : test.firstName}
        </td>
        <td className="table-column ellipsis" title={test.lastName}>
          {test.lastName == "undefined" ? "-" : test.lastName}
        </td>

        <td
          className="table-column ellipsis text-dark-danger cursor-pointer"
          onClick={() => test?.email && window.open(`mailto:${test.email}`)}
          title={test.email}
        >
          {test.email}
        </td>
        <td
          className="table-column ellipsis text-dark-danger cursor-pointer"
          onClick={() => {
            test.phoneNumber && window.open(`tel:${test.phoneNumber}`);
          }}
          title={test.phoneNumber && formatPhoneNumber(test.phoneNumber, test.countryCode)}
        >
          {test.phoneNumber && formatPhoneNumber(test.phoneNumber, test.countryCode)}
        </td>
        <td className="ellipsis" style={{ textAlign: "center" }} title={formatDOB(test.dob)}>
          {test.dob == "undefined" ? "-" : formatDOB(test.dob)}
        </td>
        {/* <td className="ellipsis" style={{ textAlign: "center" }} title={test.isVaccinated}>
          {test?.isVaccinated ? "true" : "false"}
        </td> */}
        <td className="ellipsis" style={{ textAlign: "center" }} title={test.street}>
          {test.street == "undefined" ? "-" : test.street}
        </td>
        <td className="ellipsis" style={{ textAlign: "center" }} title={test.id_number}>
          {test.id_number == "undefined" || "" ? "-" : test.id_number}
        </td>
        <td className="ellipsis" style={{ textAlign: "center" }}>
          {test?.site_name && test?.site_name !== "null" ? test?.site_name : "-"}
        </td>
        <td className="ellipsis" style={{ textAlign: "center" }}>
          {test?.lastUsed && test?.lastUsed !== "null" ? moment(test?.lastUsed).format("MM-DD-YYYY HH:mm") : "-"}
        </td>
        <td className="ellipsis" style={{ textAlign: "center" }}>
          {test?.totalTest || "0"}
        </td>
        <td className="ellipsis" style={{ textAlign: "center" }}>
          <Link
            className="text-dark-danger text-decoration-none text-dark-danger-hover"
            onClick={handleSaveFilter}
            to={{ pathname: "/admin/employee/" + test.mdID, state: { admin: true, type: "profile", test: test } }}
          >
            View
          </Link>
        </td>
      </tr>
    );
  };

  return showPdf ? (
    <PDFLayout setShowPdf={setShowPdf} testPdf={testPdf} testsLab={testsLab} />
  ) : (
    <>
      <Container fluid>
        <div className="shows-screen-parent">
          <Row className="mx-0">
            <Col md="12">
              <Card className="border-0 mb-1">
                <Card.Body className="p-0">
                  <div className="shows-filter-wrapper">
                    <div className="shows-filter-inner">
                      <Icon
                        handleClick={() => setShowFilter(!showFilter)}
                        title={"Filter"}
                        label={"Filter"}
                        iconType={"filter"}
                      />
                      {/* Date Range */}
                      <FilterSelect setTimeFilter={setTimeFilter} />
                      <Icon
                        handleClick={async () => {
                          if (filteredTests && filteredTests.length > 0) {
                            setAllSelected(!allSelected);
                            toggleCheckboxes(!allSelected);
                          }
                        }}
                        title={!allSelected ? "Select All" : "Deselect All"}
                        label={!allSelected ? "Select All" : "Deselect All"}
                        iconType={"selectAllIcon"}
                      />
                      {/* <Icon
                        disabled={checkboxes.length < 2}
                        handleClick={async () => {
                          if (filteredTests && filteredTests.length > 0) {
                            handleDuplicateTest();
                          }
                        }}
                        title={"Combine Profile"}
                        label={"Profile"}
                        iconType={"loadCrewIcon"}
                      /> */}
                      {filteredTests.length > 0 && (
                        <>
                          {Object.keys(filter).length > 0 && (
                            <>
                              <ExportToExcelData
                                items={filteredTests}
                                selectedItem={selectedItem}
                                appContext={appContext}
                                filter={filter}
                                timeFilter={timeFilter}
                                title="Profile Tests"
                                sortBy={sortBy}
                                setLoading={setLoading}
                                profileView={true}
                              />
                              <EmployeePDFLayout
                                tests={filteredTests}
                                selectedItem={selectedItem}
                                filter={filter}
                                timeFilter={timeFilter}
                                title="Profile Tests"
                                appContext={appContext}
                                sortBy={sortBy}
                              />
                            </>
                          )}
                        </>
                      )}
                      <Icon
                        handleClick={async () => {
                          if (filteredTests && filteredTests.length > 0) {
                            handleDuplicateTest();
                          }
                        }}
                        title={"Combine Profiles"}
                        label={"Combine"}
                        iconType={"groupByZoneIcon"}
                        disabled={checkboxes.length < 2}
                      />
                    </div>
                  </div>
                  {showFilter && (
                    <Filter
                      filterTerms={searchTerms}
                      setFilter={setFilter}
                      filter={filter}
                      triggerFilter={triggerFilter}
                      setTriggerFilter={setTriggerFilter}
                    />
                  )}
                </Card.Body>
              </Card>
            </Col>
          </Row>
          <Row className="mx-0">
            <Col md="12">
              <Card className="striped-tabled-with-hover bg-transparent">
                <Card.Header className="shows-screen-wrapper">
                  <Row className="pb-4">
                    <Col md-="6">
                      <Card.Title>
                        <div className="">
                          Crew Profiles:
                          <span className="ps-2">{totalRecord}</span>
                        </div>
                      </Card.Title>
                    </Col>
                    <Col md="6">
                      <PGPagination
                        timeFilter={timeFilter}
                        currentPage={currentPage}
                        handleChangePage={handleChangePage}
                        totalPages={totalPages}
                        totalRecord={totalRecord}
                        currentRecord={filteredTests.length}
                        showSelectedRecord
                        totalSelected={checkboxes.length}
                      />
                    </Col>
                  </Row>
                  {/* <div className="buttonHeader">
                    <Button className="headerButton" onClick={() => setShowFilter(!showFilter)}>
                      Filter
                    </Button>
                    <FilterSelect setTimeFilter={setTimeFilter} />
                    <Button
                      className="headerButton"
                      onClick={async () => {
                        if (filteredTests && filteredTests.length > 0) {
                          setAllSelected(!allSelected);
                          toggleCheckboxes(!allSelected);
                        }
                      }}
                    >
                      {!allSelected ? "Select All" : "Deselect All"}
                    </Button>
                    {filteredTests.length > 0 && (
                      <>
                        {Object.keys(filter).length > 0 && (
                          <>
                            <ExportToExcelData
                              items={filteredTests}
                              selectedItem={selectedItem}
                              appContext={appContext}
                              filter={filter}
                              timeFilter={timeFilter}
                              title="Profile Tests"
                              sortBy={sortBy}
                              setLoading={setLoading}
                              profileView={true}
                            />
                            <EmployeePDFLayout
                              tests={filteredTests}
                              selectedItem={selectedItem}
                              filter={filter}
                              timeFilter={timeFilter}
                              title="Profile Tests"
                              appContext={appContext}
                              sortBy={sortBy}
                            />
                          </>
                        )}
                      </>
                    )}
                    <Button
                      className="headerButton"
                      disabled={checkboxes.length < 2}
                      onClick={async () => {
                        if (filteredTests && filteredTests.length > 0) {
                          handleDuplicateTest();
                        }
                      }}
                    >
                      Combine Profiles
                    </Button>
                  </div> */}
                </Card.Header>

                <Card.Body className="table-full-width px-0 pt-0 desktop-noScroll mt-0">
                  <div className="table-responsive pendingReleaseTable">
                    <Table className="separate-border-tr" ref={mainRef}>
                      <ProfileHeader
                        type={TEST_HEADER_TYPE.completed}
                        flipSort={flipSort}
                        sortBy={sortBy}
                        sortDescending={sortDescending}
                        widthCol={60}
                      />
                      <tbody>
                        {testsToMap &&
                          testsToMap.map((test) => {
                            return <TableRow key={test.id} test={test} />;
                          })}
                      </tbody>
                    </Table>
                  </div>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </div>
      </Container>
      {removeDuplictaeModalShow && (
        <EmployeeCrewProfileLogsModal
          data={selectedTest}
          totalTestsCount={employeeTotalTestsCount}
          handleClose={(isRefresh) => {
            if (isRefresh) {
              setTimeout(
                () =>
                  loadEmployees(
                    Object.keys(filter).length === 0 ? { id: 1 } : filter,
                    timeFilter,
                    1,
                    sortBy,
                    sortDescending
                  ),
                1000
              );
            }
            setCheckboxes([]);
            setSelectedTest([]);
            setRemoveDuplictaeModalShow(false);
          }}
          handleShowMergedProfileLogs={(data) => {
            if (data) {
              setMergedProfilesTestsData(data);
              setShowMergedProfileLogs(true);
            }
          }}
        />
      )}

      {showMergedProfileLogs && (
        <EmployeeCrewProfileMergedLogsModal
          data={mergedProfilesTestsData}
          handleCloseMergedProfileLogs={(isRefresh) => {
            // if (isRefresh) {
            //   setTimeout(
            //     () =>
            // loadEmployees(
            //   Object.keys(filter).length === 0 ? { id: 1 } : filter,
            //   timeFilter,
            //   1,
            //   sortBy,
            //   sortDescending
            // ),
            //       1000
            //     );
            //  }
            setCheckboxes([]);
            setSelectedTest([]);
            setShowMergedProfileLogs(false);
          }}
        />
      )}

      {showLog && (
        <TestLogsModal
          data={testLogs}
          handleClose={() => {
            setShowLog(false);
            setTestLogs([]);
          }}
        ></TestLogsModal>
      )}

      {showAppLog && (
        <AppTestLogsModal
          data={testAppLogs}
          handleClose={() => {
            setShowAppLog(false);
            setTestAppLogs([]);
          }}
        ></AppTestLogsModal>
      )}

      {loading && <Loader />}
    </>
  );
};

export default ProfilesView;
