import api from "api";
import React, { useEffect, useState, useCallback } from "react";
import Select from "react-select";

const OrderTestSelector = ({ onChange, value = null, dataIds, labClientTest }) => {
  const [options, setOptions] = useState([]);

  const formatOptions = (items = []) =>
    items.map(({ id, name, ...rest }) => ({
      ...rest,
      id,
      name,
      label: name,
      value: id,
    }));

  const getTestOptions = useCallback(async () => {
    try {
      let mergedOption = formatOptions(await api.getTestTypes());

      if (labClientTest && labClientTest.length > 0) {
        mergedOption = mergedOption.filter((item) => labClientTest.includes(item.id));
      }

      setOptions(mergedOption.filter((t) => t.isActive).sort((a, b) => a?.name?.localeCompare(b?.name)));
    } catch (error) {
      console.error("Error fetching test options:", error);
    }
  }, []);

  useEffect(() => {
    getTestOptions();
  }, [getTestOptions, dataIds]);

  return (
    <Select
      options={options}
      blurInputOnSelect
      menuPlacement="auto"
      className="w-100 siteSelector"
      placeholder="Select Test"
      value={value}
      onChange={onChange}
    />
  );
};

export default OrderTestSelector;
