import React from "react";
import { Button, Modal, Table } from "react-bootstrap";
import { formatLogDate } from "utils";

const AppTestLogsModal = (props) => {
  const { data, handleClose } = props;
  const parseUser = (user) =>{
    const u = JSON.parse(user);
    return `${u['custom:firstName']} ${u['custom:lastName']}`
  }
  const parseUserPhone = (user) =>{
    const u = JSON.parse(user);
    return `${u.phone_number}`
  }
  const parseUserRole = (user) =>{
    const u = JSON.parse(user);
    return `${u['custom:role']}`
  }
  return (
    <Modal show animation={true} onHide={() => handleClose()} centered size="xl">
      <Modal.Header closeButton>
        <Modal.Title className="my-0" id="contained-modal-title-vcenter">
          Test Logs
        </Modal.Title>
      </Modal.Header>

      <Modal.Body style={{ paddingTop: 0 }}>
        <div className="form-group-wrapper d-block">
          <div className="table-responsive">
            <Table className="table-hover table-striped">
              <thead>
                <tr>
                  <th
                    className={`border-0 centered`}
                    style={{
                      color: "grey",
                      fontWeight: "bold",
                    }}
                  >
                    <div className="d-flex justify-content-start align-items-center">
                      <span>Date</span>
                    </div>
                  </th>
                  <th
                    className={`border-0 centered`}
                    style={{
                      color: "grey",
                      fontWeight: "bold",
                    }}
                  >
                    <div className="d-flex justify-content-start align-items-center">
                      <span>Action</span>
                    </div>
                  </th>
                  
                  <th
                    className={`border-0 centered`}
                    style={{
                      color: "grey",
                      fontWeight: "bold",
                    }}
                  >
                    <div>
                      <span>momentDate</span>
                    </div>
                  </th>
                  <th
                    className={`border-0 centered`}
                    style={{
                      color: "grey",
                      fontWeight: "bold",
                    }}
                  >
                    <div>
                      <span>nowIOS</span>
                    </div>
                  </th>
                  <th
                    className={`border-0 centered`}
                    style={{
                      color: "grey",
                      fontWeight: "bold",
                    }}
                  >
                    <div>
                      <span>timeZone</span>
                    </div>
                  </th>
                  <th
                    className={`border-0 centered`}
                    style={{
                      color: "grey",
                      fontWeight: "bold",
                    }}
                  >
                    <div>
                      <span>version</span>
                    </div>
                  </th>
                  <th
                    className={`border-0 centered`}
                    style={{
                      color: "grey",
                      fontWeight: "bold",
                    }}
                  >
                    <div>
                      <span>User</span>
                    </div>
                  </th>
                  <th
                    className={`border-0 centered`}
                    style={{
                      color: "grey",
                      fontWeight: "bold",
                    }}
                  >
                    <div>
                      <span>Phone</span>
                    </div>
                  </th>
                  <th
                    className={`border-0 centered`}
                    style={{
                      color: "grey",
                      fontWeight: "bold",
                    }}
                  >
                    <div>
                      <span>Role</span>
                    </div>
                  </th>
                </tr>
              </thead>
              <tbody>
                {data.map((msg, index) => {
                  return (
                    <tr key={index}>
                      <td>{formatLogDate(msg.createdAt)}</td>
                      <td>{msg.action}</td>
                      <td style={{ textAlign: "center" }}>{msg.momentDate}</td>
                      <td style={{ textAlign: "center" }}>{msg.nowIOS}</td>
                      <td style={{ textAlign: "center" }}>{msg.timeZone}</td>
                      <td style={{ textAlign: "center" }}>{msg.version}</td>
                      <td style={{ textAlign: "center" }}>{parseUser(msg.user)}</td>
                      <td style={{ textAlign: "center" }}>{parseUserPhone(msg.user)}</td>
                      <td style={{ textAlign: "center" }}>{parseUserRole(msg.user)}</td>
                     
                    </tr>
                  );
                })}
              </tbody>
            </Table>
          </div>
        </div>
      </Modal.Body>

      <Modal.Footer className="flex-row-reverse">
        <Button className="modalButtons" variant="primary"  onClick={() => handleClose()}>
          OK
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default AppTestLogsModal;
