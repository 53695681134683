export const generateAndSendZpl = ({
  nameString,
  collectionSourceString,
  dob,
  srNo,
  selectedDevice,
  selectedSize,
  testTypeResult,
  errorCallback,
  isSeroclinix,
}) => {
  const zplLabel = {
    ZebraMedium: `
    CT~~CD,~CC^~CT~
    ^XA
    ~TA000
    ~JSN
    ^LT0
    ^MNW
    ^MTT
    ^PON
    ^PMN
    ^LH0,0
    ^JMA
    ^PR6,6
    ~SD15
    ^JUS
    ^LRN
    ^CI27
    ^PA0,1,1,0
    ^XZ
    ^XA
    ^MMT
    ^PW345
    ^LL244
    ^LS0
    ^FT27,38^A0N,17,18^FH\^CI28^FD${nameString}^FS^CI27
    ^FT0,37^A0N,17,18^FB317,1,4,R^FH\^CI28^FD${testTypeResult?.code}^FS^CI27
    ^FT27,57^A0N,17,18^FH\^CI28^FD${dob}^FS^CI27
    ^BY2,3,48^FT27,132^BCN,,Y,N
    ^FH\^FD>:${srNo}^FS
    ^FT27,78^A0N,17,18^FH\^CI28^FD${collectionSourceString}^FS^CI27
    ^PQ1,0,1,Y
    ^XZ
    `,

    ZebraLarge: `
    CT~~CD,~CC^~CT~
    ^XA
    ~TA000
    ~JSN
    ^LT0
    ^MNW
    ^MTT
    ^PON
    ^PMN
    ^LH0,0
    ^JMA
    ^PR6,6
    ~SD15
    ^JUS
    ^LRN
    ^CI27
    ^PA0,1,1,0
    ^XZ
    ^XA
    ^MMT
    ^PW448
    ^LL345
    ^LS0
    ^FT17,49^A0N,28,28^FH\^CI28^FD${nameString}^FS^CI27
    ^FT0,49^A0N,28,28^FB426,1,7,R^FH\^CI28^FD${testTypeResult?.code}^FS^CI27
    ^FT17,79^A0N,28,28^FH\^CI28^FD${dob}^FS^CI27
    ^BY3,3,61^FT6,184^BCN,,Y,N
    ^FH\^FD>:${srNo}^FS
    ^FT17,112^A0N,28,28^FH\^CI28^FD${collectionSourceString}^FS^CI27
    ^PQ1,0,1,Y
    ^XZ

    `,
  };

  const zplSeroClinix = {
    ZebraMedium: `
    CT~~CD,~CC^~CT~
    ^XA
    ~TA000
    ~JSN
    ^LT0
    ^MNW
    ^MTT
    ^PON
    ^PMN
    ^LH0,0
    ^JMA
    ^PR6,6
    ~SD15
    ^JUS
    ^LRN
    ^CI27
    ^PA0,1,1,0
    ^XZ
    ^XA
    ^MMT
    ^PW345
    ^LL244
    ^LS0
    ^FT27,38^A0N,17,18^FH\^CI28^FD${nameString}^FS^CI27
    ^FT0,37^A0N,17,18^FB317,1,4,R^FH\^CI28^FD${testTypeResult?.code}^FS^CI27
    ^FT27,57^A0N,17,18^FH\^CI28^FD${dob}^FS^CI27
    ^BY2,3,48^FT27,111^BCN,,Y,N
    ^FH\^FD>:${srNo}^FS
    ^PQ1,0,1,Y
    ^XZ
    `,

    ZebraLarge: `
    CT~~CD,~CC^~CT~
    ^XA
    ~TA000
    ~JSN
    ^LT0
    ^MNW
    ^MTT
    ^PON
    ^PMN
    ^LH0,0
    ^JMA
    ^PR6,6
    ~SD15
    ^JUS
    ^LRN
    ^CI27
    ^PA0,1,1,0
    ^XZ
    ^XA
    ^MMT
    ^PW448
    ^LL345
    ^LS0
    ^FT17,49^A0N,28,28^FH\^CI28^FD${nameString}^FS^CI27
    ^FT0,49^A0N,28,28^FB426,1,7,R^FH\^CI28^FD${testTypeResult?.code}^FS^CI27
    ^FT17,79^A0N,28,28^FH\^CI28^FD${dob}^FS^CI27
    ^BY3,3,61^FT6,148^BCN,,Y,N
    ^FH\^FD>:${srNo}^FS
    ^PQ1,0,1,Y
    ^XZ
    `,
  };

  let zplCode = null;
  if (isSeroclinix) {
    zplCode = zplSeroClinix[selectedSize?.labelSize] || zplSeroClinix["ZebraLarge"];
  } else {
    zplCode = zplLabel[selectedSize?.labelSize] || zplLabel["ZebraLarge"];
  }
  if (!zplCode) {
    errorCallback("Label size not correct.");
  }

  selectedDevice?.send(zplCode, undefined, errorCallback);
};

