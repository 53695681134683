import Icon from "components/Icon";
import React from "react";
import { Form } from "react-bootstrap";
import "./procedureCodeDetail.css";
import { formatCurrency } from "utils";

const ProcedureCodeDetailView = ({ handelDeleteCpt, cptCodeList, className = "", handelUpdateCpt }) => {
  return (
    <div className={className ? className : "procedure-view-container table-fixed"}>
      <table className="procedure-view-table">
        <thead>
          <tr>
            <th className="procedure-view-th">Code</th>

            <th className="procedure-view-th realtionTdWidth">Name</th>
            <th className="procedure-view-th">Unit</th>
            <th className="procedure-view-th">Max Unit</th>

            <th className="procedure-view-th">Internal Charge</th>

            <th className="procedure-view-th">Reference Charge</th>
            <th className="procedure-view-th text-center">Is PanelCpt</th>
            {handelDeleteCpt && <th className="procedure-view-th text-center">Action</th>}
          </tr>
        </thead>
        <tbody>
          {cptCodeList.map((item, index) => {
            return (
              <tr key={`cpt_detail_${index}`}>
                <td className="procedure-view-td">{item?.internalCode || ""}</td>

                <td className="procedure-view-td" title={item?.title || ""}>
                  {item?.title || ""}
                </td>

                <td className="procedure-view-td-small">
                  <input
                    className="w-100 modalInput no-arrow"
                    placeholder="Enter Unit"
                    value={item?.unit}
                    onChange={(event) => handelUpdateCpt({ keyName: "unit", value: event.target.value, index: index })}
                    type="number"
                  />
                </td>

                <td className="procedure-view-td-small">
                  <input
                    className="w-100 modalInput no-arrow"
                    placeholder="Enter Max Unit"
                    value={item?.maxUnit}
                    onChange={(event) =>
                      handelUpdateCpt({ keyName: "maxUnit", value: event.target.value, index: index })
                    }
                    type="number"
                  />
                </td>

                <td className="procedure-view-td">{formatCurrency(item?.charges || "")}</td>

                <td className="procedure-view-td">{formatCurrency(item?.referenceCharge || "")}</td>

                <td className="procedure-view-td text-center">
                  <Form.Check>
                    <Form.Check.Input
                      type="checkbox"
                      checked={!!item?.isPanelCpt}
                      onChange={(event) =>
                        handelUpdateCpt({ keyName: "isPanelCpt", value: event.target.checked, index: index })
                      }
                    />
                  </Form.Check>
                </td>

                {handelDeleteCpt && (
                  <td className="procedure-view-td procedure-text-center">
                    <Icon
                      iconType={"binIcon"}
                      containerClass={"cursor-pointer"}
                      innerClass={""}
                      imageClass={"delete-icon-small "}
                      handleClick={() => handelDeleteCpt(index)}
                    />
                  </td>
                )}
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};

export default ProcedureCodeDetailView;
