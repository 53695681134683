import React, { useState, useEffect } from "react";
import Form from "react-bootstrap/Form";
import { formatCurrency, parseCurrency } from "utils";

const CurrencyInput = ({ value, onChange, label = "", placeholder = "", className = "" }) => {
  const [inputValue, setInputValue] = useState("");

  useEffect(() => {
    const parsedValue = parseCurrency(value);
    setInputValue(formatCurrency(parsedValue));
  }, []);

  const handleChange = (e) => {
    const value = e.target.value;
    setInputValue(value);
    onChange(parseCurrency(value));
  };

  const handleBlur = () => {
    const parsedValue = parseCurrency(inputValue);
    setInputValue(formatCurrency(parsedValue));
  };

  return (
    <Form.Group className={`form-group-wrapper w-100 ${className}`}>
      {label && <Form.Label className="mt-0 text-capitalize">{label}</Form.Label>}
      <Form.Control
        className="modalInput"
        maxLength={65}
        onChange={handleChange}
        onBlur={handleBlur}
        value={inputValue}
        placeholder={placeholder}
        type="text"
      />
    </Form.Group>
  );
};

export default CurrencyInput;
