import api from "api";

export const listTemplate = async () => {
  try {
    const result = await api.getMessagetTemplate();
    return result.rows;
  } catch (err) {
    console.error("Error listing message Template:", err);
    return err;
  }
};

export const createMessageTemplateGQL = async (msgTemplate) => {
  try {
    const result = await api.createMessagesTemplate(msgTemplate);
    return result;
  } catch (error) {
    console.error("Error creating message Template:", error);
    return error;
  }
};

export const updateMessageTemplateGQL = async (updatedMessageTemplateData) => {
  try {
    const result = await api.updateMessagesTemplate(updatedMessageTemplateData);
    return result;
  } catch (error) {
    console.error("Error updating message Template:", error);
    throw error; // Rethrow the error for handling it in the calling code
  }
};

export const deleteMessageTemplateGQL = async (messageTemplate) => {
  try {
    const result = await api.deleteMessagesTemplate({ id: messageTemplate.id });
    return result;
  } catch (error) {
    console.error("Error deleting message Template:", error);
    throw error; // Rethrow the error for handling it in the calling code
  }
};
