import { useDispatch, useSelector } from "react-redux";
import { selectedHospiceDetailsSelector } from "store/features/checkEligibility/checkEligibilitySelectors";
import {
  messageTemplateModalSelector,
  selectedMessageTemplateSelector,
} from "store/features/messageTemplate/messageTemplateSelectors";
import { salesPersonAssigningSelector } from "store/features/salePerson/salePersonSelector";
import MessageTemplateModal from "views/MessageTemplate/MessageTemplateModal";
import ConfirmationModal from "./Modal/ConfirmationModal";
import { confirmationModalSelector } from "store/features/general/generalSelectors";
import { showConfirmationModal } from "store/features/general/generalAction";

const GlobalActionsComponent = () => {
  const salesPersonAssigningDetails = useSelector(salesPersonAssigningSelector);
  const selectedHospiceDetails = useSelector(selectedHospiceDetailsSelector);
  const messageTemplateModal = useSelector(messageTemplateModalSelector);
  const selectedMessageTemplate = useSelector(selectedMessageTemplateSelector);
  const confirmationModal = useSelector(confirmationModalSelector);
  
  const dispatch = useDispatch();

  const handleCloseModal = () => {
    dispatch(
      showConfirmationModal({
        showModal: false,
        message: "",
        title: "",
        onConfirm: null,
      })
    );
  };
  return (
    <>
      {/* {salesPersonAssigningDetails.showAssignModal && <AssignSalesPerson />} */}
      {}
      {selectedHospiceDetails?.showModal && <HospiceDetailsModal cssClass="seperateModal" />}
      {confirmationModal?.showModal && (
        <ConfirmationModal
          show={confirmationModal?.showModal}
          title={confirmationModal.title}
          message={confirmationModal.message}
          handleConfirm={(confirm) => {
            if (confirm) {
              confirmationModal.onConfirm();
            } else {
              handleCloseModal();
              if(confirmationModal?.onClose){
                confirmationModal?.onClose();
              }
            }
          }}
          isPreRegister={confirmationModal?.isPreRegister}
          handleClose={handleCloseModal}
        />
      )}

      {messageTemplateModal && <MessageTemplateModal cssClass="seperateModal" selectItem={selectedMessageTemplate} />}
    </>
  );
};

export default GlobalActionsComponent;
