import { createAsyncThunk } from "@reduxjs/toolkit";
import { ADD_SELECTED_PCS, REMOVE_SELECTED_PCS, SHOW_HIDE_CLIENT_PCS, RESET_CLIENT_PCS } from "./clientPcsConstant";

export const addSelectedPcs = createAsyncThunk(ADD_SELECTED_PCS, async (pcs) => {
  return pcs;
});
export const removeSelectedPcs = createAsyncThunk(REMOVE_SELECTED_PCS, async (pcs) => {
  return pcs;
});
export const showHideClientPcsModal = createAsyncThunk(SHOW_HIDE_CLIENT_PCS, async (showHideValue) => {
  return showHideValue;
});

export const resetClientPcsState = createAsyncThunk(RESET_CLIENT_PCS, async () => {});
