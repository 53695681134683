import React, { useEffect, useState } from "react";
import { Button, Modal } from "react-bootstrap";

const CreateOptions = ({ handleClose, title, label, handleSaveChange, selectedValue, placeholder }) => {
  const [option, setOption] = useState("");

  useEffect(() => {
    if (selectedValue) {
      setOption(selectedValue);
    }
  }, [selectedValue]);

  return (
    <Modal
      show
      animation={true}
      onHide={() => handleClose()}
      backdrop="static"
      centered
      size={"md"}
      keyboard={true}
      className="second-modal"
    >
      <Modal.Header closeButton>
        <Modal.Title className="my-0" id="contained-modal-title-vcenter">
          {title}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body style={{ paddingTop: 0 }}>
        <div className="row mb-3">
          <div className="col-lg-12 col-12 mb-3">
            <label className="modalLineHeaders mt-0">{label}:</label>
            <input
              value={option}
              className="modalInput"
              placeholder={placeholder}
              style={{
                width: "100%",
              }}
              onChange={(e) => setOption(e.target.value)}
            />
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" className="headerButton" onClick={() => handleClose()}>
          Close
        </Button>
        <Button variant="primary" className="modalButtons" onClick={() => handleSaveChange(option)}>
          Save
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default CreateOptions;
