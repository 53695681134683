import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import api from "../../../api";
import { ADMIN_USERS, CONFIG } from "../../../constant";
import { USER, USER_FETCH } from "./userConstants";
// Async thunk to fetch all users
export const fetchAllUsers = createAsyncThunk(USER_FETCH, async ({ id, user }) => {
  let response = [];
  if (CONFIG.isLabType) {
    response = await api.getBRMUsers();
  } else {
    response = await api.getUserFromLocalDB(id, user);
  }
  return response;
});

// Async thunk to create a user
export const createUserAsync = createAsyncThunk("user/createUser", async (user) => {
  const response = await createUser(user);
  return response;
});

// Async thunk to update a user
export const updateUserAsync = createAsyncThunk("user/updateUser", async ({ userId, updatedUser }) => {
  const response = await updateUser(userId, updatedUser);
  return response;
});

// Async thunk to delete a user
export const deleteUserAsync = createAsyncThunk("user/deleteUser", async (userId) => {
  const response = await deleteUser(userId);
  return response;
});

const userSlice = createSlice({
  name: USER,
  initialState: {
    users: [],
    filteredUsers: [],
  },
  reducers: {
    setFilteredUsers: (state, action) => {
      state.filteredUsers = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchAllUsers.fulfilled, (state, action) => {
        const data = action.payload.filter((f) => !ADMIN_USERS.includes(f.phone_number));
        state.users = data;
        state.filteredUsers = data;
      })
      .addCase(createUserAsync.fulfilled, (state, action) => {
        state.users.push(action.payload);
      })
      .addCase(updateUserAsync.fulfilled, (state, action) => {
        const updatedUser = action.payload;
        const existingUserIndex = state.users.findIndex((user) => user.id === updatedUser.id);
        if (existingUserIndex !== -1) {
          state.users[existingUserIndex] = updatedUser;
        }
      })
      .addCase(deleteUserAsync.fulfilled, (state, action) => {
        const deletedUserId = action.payload;
        state.users = state.users.filter((user) => user.id !== deletedUserId);
      })
  },
});

export default userSlice.reducer;
