import React, { useContext, useState, useRef } from "react";
import { AppContext } from "../../context/app-context";
import { Overlay, Popover } from "react-bootstrap";
import { PRE_REGISTRATION_STATUS, GENDER_TEXT, TEST_TYPE_VALUE } from "../../constant";
import {
  formatPhoneNumber,
  parseBooleanValue,
  formatDOB,
  formatOrderDate,
  formateDatellll,
  formatDateMDY,
  capitalizeLetter,
  nameToTitleCase,
} from "../../utils";
import { Link } from "react-router-dom";

const RenderTd = (props) => {
  const { item, user, createMoreInfo, handleOrderDetail } = props;
  const [showPopover, setShowPopover] = useState(false);
  const childNode = useRef();
  let setTimeoutConst = null;
  const handleMouseEnter = () => {
    setTimeoutConst = setTimeout(() => {
      setShowPopover(true);
    }, 500);
  };
  const handleMouseLeave = () => {
    clearTimeout(setTimeoutConst);
    setShowPopover(false);
  };
  const appContext = useContext(AppContext);

  const handleClick = () => {
    if (item.itemKey === "orderStatus" && handleOrderDetail) {
      const isCheck = user.hasOwnProperty("orderTracking");
      handleOrderDetail(isCheck ? user.orderTracking : [], user);
    }
  };

  const tdEmail = (email) => (
    <td
      className={`table-column ellipsis text-dark-danger text-dark-danger-hover cursor-pointer text-decoration-none ${item.textAlign}`}
      onClick={() => email && window.open(`mailto:${email}`)}
      title={email}
    >
      {email}
    </td>
  );

  const tdPhone = (phoneNumber, countryCode) => {
    const phNumber = formatPhoneNumber(phoneNumber, countryCode, true);
    return (
      <td
        className={`ellipsis text-dark-danger text-dark-danger-hover cursor-pointer text-decoration-none ${item.textAlign}`}
        onClick={() => {
          phoneNumber && window.open(`tel:+${phNumber.replace(/\D/g, "")}`);
        }}
        title={phNumber}
      >
        {phNumber}
      </td>
    );
  };

  const tdShowName = () => (
    <td className={`table-column ellipsis ${item.textAlign}`}>
      <a
        style={{ color: "#42cef5" }}
        onClick={() => {
          if (createMoreInfo) createMoreInfo("site", user.showId);
        }}
        onMouseOver={(e) => (e.target.style.cursor = "pointer")}
        title={appContext.getSiteName(user.showId)}
      >
        {capitalizeLetter(appContext.getSiteName(user.showId))}
      </a>
    </td>
  );

  const tdLink = () => (
    <td className={`table-column ellipsis ${item.textAlign}`}>
      {!user[item.itemKey] || user[item.itemKey] === "" ? (
        "-"
      ) : (
        <a
          style={{ color: "#42cef5" }}
          onClick={handleClick}
          onMouseOver={(e) => (e.target.style.cursor = "pointer")}
          title={user[item.itemKey]}
        >
          {user[item.itemKey]}
        </a>
      )}
    </td>
  );

  const tdDate = (Date) => (
    <>
      <td
        className={`table-column ellipsis ${item.textAlign}`}
        onMouseOver={(e) => (e.target.style.cursor = "pointer")}
        ref={childNode}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
        onClick={() => setShowPopover(true)}
      >
        {formatDateMDY(Date?.substring(0, 10))}
      </td>
      {showPopover && (
        <Overlay show={showPopover} placement="auto" flip target={childNode.current}>
          <Popover
            onMouseEnter={() => {
              setShowPopover(true);
            }}
            onMouseLeave={handleMouseLeave}
            id="popover"
            className="popOverSchedule"
          >
            <Popover.Header className="fw-bold"></Popover.Header>
            <Popover.Body>
              <div>{formatOrderDate(Date)}</div>
            </Popover.Body>
          </Popover>
        </Overlay>
      )}
    </>
  );

  const tdFormat = (item, user) => {
    if (item === "sex") return user.sex ? GENDER_TEXT[user.sex] : "-";
    if (item === "dob") return user.dob ? formatDOB(user.dob) : "-";
    if (item === "isVaccinated") return parseBooleanValue(user.isVaccinated);
    if (item === "isExternalTest") return parseBooleanValue(user.isExternalTest);
    if (item === "step") return PRE_REGISTRATION_STATUS[user.step];
    if (item === "orderId") return user.orderId !== -1 ? user.orderId : "-";
    if (item === "vaccinationType") {
      return Object.keys(user.vaccinationDetails).length > 0 ? user.vaccinationDetails.vaccinationType : "-";
    }
    if (item === "vaccinationDate") {
      return Object.keys(user.vaccinationDetails).length > 0 ? formatDOB(user.vaccinationDetails.vaccinationDate) : "-";
    }
    if (item === "vaccinationLocation") {
      return Object.keys(user.vaccinationDetails).length > 0 ? user.vaccinationDetails.vaccinationLocation : "-";
    }
    if (item === "externalTestDate") {
      return Object.keys(user.externalTestDate).length > 0 ? formatDOB(user.externalTestDate) : "-";
    }
    if (item === "orderKitType") {
      return user.orderKitType ? TEST_TYPE_VALUE[user.orderKitType] : "-";
    }
    if (item === "firstName") return user.firstName ? nameToTitleCase(user.firstName) : "";
    if (item === "lastName") return user.lastName ? nameToTitleCase(user.lastName) : "";
    return user[item] && user[item] !== "undefined" ? capitalizeLetter(user[item]) : "-";
  };

  if (item.itemKey === "createdAt") return tdDate(user.createdAt);
  if (item.itemKey === "orderStatus") return tdLink();
  if (item.itemKey === "email") return tdEmail(user.email);
  if (item.itemKey === "phone_number") return tdPhone(user.phone_number, user.countryCode);
  if (item.itemKey === "showName") return tdShowName(user.showId);

  return (
    <td className={`ellipsis ${item.textAlign}`} title={tdFormat(item.itemKey, user)}>
      {tdFormat(item.itemKey, user)}
    </td>
  );
};

export default RenderTd;
