import React from "react";

const SortIcon = ({ sortName, sortBy, sortDescending, handleClick }) => {
  return (
    <div onClick={handleClick} className="cursor-pointer">
      {sortBy !== sortName ? (
        <i className="fas fa-sort"></i>
      ) : sortDescending ? (
        <i className="fas fa-sort-down"></i>
      ) : (
        <i className="fas fa-sort-up"></i>
      )}
    </div>
  );
};

export default SortIcon;
