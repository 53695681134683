import React, { useEffect, useState } from "react";
import { Button, Modal, Accordion, Card } from "react-bootstrap";
import DiagnosisAccordionButton from "./DiagnosisAccordionButton";
import CptList from "components/CPT/CptList";
import Icon from "components/Icon";
import { CPT_CODE_SEARCH_TYPE } from "constant";
import { customCloneDeep } from "util/customLodash";

const DiagnosisAccordionView = ({ handleClose, handelSaveDiagnosisPanel, ictList, isNew }) => {
  const [activeKey, setActiveKey] = useState(() => Array.from({ length: ictList.length }, (_, i) => String(i)));

  const [localIctList, setLocalIctList] = useState([]);

  useEffect(() => {
    setLocalIctList(ictList);
  }, [ictList]);

  const handleToggle = (newCode) => {
    setActiveKey((currentCodes) => {
      const found = activeKey.some((code) => code === newCode);
      return found ? currentCodes.filter((code) => code !== newCode) : [...currentCodes, newCode];
    });
  };

  const handleCptList = (e, index) => {
    const copyCptList = customCloneDeep(localIctList);

    if (!Object.isExtensible(copyCptList[index])) {
      copyCptList[index] = { ...copyCptList[index] };
    }

    if (!copyCptList[index].cptCodes) {
      copyCptList[index].cptCodes = [];
    }

    copyCptList[index].cptCodes.push(e.searchCodeKey[0]);
    setLocalIctList(copyCptList);
  };

  const handelDeleteDiagnosis = (diagIndex) => {
    const copyCptList = customCloneDeep(localIctList);
    copyCptList.splice(diagIndex, 1);
    setLocalIctList(copyCptList);
  };

  const handleDiagCode = (item) => {
    const ictItem = item.ictCodes[0];
    setLocalIctList([...localIctList, { ...ictItem, cptCodes: [] }]);
  };

  const removeDiagCode = (mainIndex, procCodeIndex) => {
    const newData = localIctList.map((item, index) => {
      if (index === mainIndex) {
        return {
          ...item,
          cptCodes: item?.cptCodes?.filter((_, idx) => idx !== procCodeIndex),
        };
      }
      return item;
    });
    setLocalIctList(newData);
  };

  return (
    <>
      <Modal
        show
        className="second-modal"
        onHide={() => handleClose()}
        animation={true}
        centered
        size={"lg"}
        backdrop="static"
      >
        <Modal.Header closeButton>
          <Modal.Title className="my-0 h4" id="contained-modal-title-vcenter">
            Diagnosis And Procedure Codes
          </Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ paddingTop: 0 }}>
          <CptList
            item={{}}
            setItem={handleDiagCode}
            title="Diagnosis Code"
            searchtype={CPT_CODE_SEARCH_TYPE.ICT}
            groupWrapper="mt-1 form-group-wrapper"
            showLabelOnTop={true}
          />

          <Accordion activeKey={activeKey} className="mt-2">
            {localIctList &&
              localIctList.map((item, index) => {
                return (
                  <Card className="mb-2 " key={`cpt_${index}`}>
                    <Card.Header
                      className={`py-2 shadow-sm`}
                      style={{ display: "flex", justifyContent: "space-between" }}
                    >
                      <DiagnosisAccordionButton
                        eventKey={`${index}`}
                        isActive={activeKey.includes(`${index}`)}
                        onClick={() => handleToggle(`${index}`)}
                      >
                        {item.code}
                        {item.title}
                      </DiagnosisAccordionButton>

                      <div style={{ display: "flex", alignItems: "center" }}>
                        <CptList
                          title="Procedure Code"
                          searchtype={CPT_CODE_SEARCH_TYPE.CPT}
                          groupWrapper="mt-0 form-group-wrapper mb-0"
                          showLabelOnTop={false}
                          showCptSelectedCode={false}
                          showLabel={false}
                          setItem={(e) => handleCptList(e, index)}
                          item={item}
                          value={""}
                          searchCodeKey="searchCodeKey"
                          showInput={false}
                        />

                        <Icon
                          iconType={"binIcon"}
                          containerClass={"ms-2 cursor-pointer"}
                          innerClass={""}
                          imageClass={"testTabelDeleteIcon"}
                          handleClick={() => handelDeleteDiagnosis(index)}
                        />
                      </div>
                    </Card.Header>
                    <Accordion.Collapse eventKey={`${index}`}>
                      <Card.Body>
                        <div className="mb-3 d-flex flex-wrap">
                          {item?.cptCodes?.map((cpt, innerIndex) => {
                            return (
                              <div className="d-flex flex-wrap m-0 w-full" key={`proc_${innerIndex}`}>
                                <span title={cpt.code} className="cptCodeTag mt-0 mx-1 mb-1">
                                  {cpt.code}
                                  <Icon
                                    iconType={"binIcon"}
                                    containerClass={"ms-2 cursor-pointer"}
                                    innerClass={""}
                                    imageClass={"testTabelDeleteIcon"}
                                    handleClick={() => removeDiagCode(index, innerIndex)}
                                  />
                                </span>
                              </div>
                            );
                          })}
                        </div>
                      </Card.Body>
                    </Accordion.Collapse>
                  </Card>
                );
              })}
          </Accordion>
        </Modal.Body>

        <Modal.Footer>
          <Button variant="secondary" className="modalButtons" onClick={() => handleClose()}>
            Close
          </Button>

          <Button variant="primary" className="modalButtons" onClick={() => handelSaveDiagnosisPanel(localIctList)}>
            {isNew ? "Save" : "Update"}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default DiagnosisAccordionView;
