import React, { useState, useEffect, useCallback, useRef, useContext } from "react";
import { Button, Form, Modal, Card, Table, Row, Col } from "react-bootstrap";
import Select from "react-select";
import { TEST_TYPES, CONFIG } from "constant";
import { LOCATIONS_OPTIONS } from "constant";
import { formatDateMDY, capitalizeLetter, formatPhoneNumber, formatDateOfBirthDOB } from "utils";
import moment from "moment";
// import DatePicker from "react-date-picker";
import { debounce } from "debounce";
import API from "api";
import Loader from "components/Loader/Loader";
import { parseJSONObject, mergeArraysById } from "utils";

// import FilterPopOver from "components/FilterPopOver";
import Icon from "components/Icon";
import { COMBINE_PROFILE } from "constant";
import { AppContext } from "../../context/app-context";
import ProfileListItems from "views/Patient/ProfileRelationListItem";
import Filter from "components/Filter";
import RelationView from "views/Patient/RelationView";
import { useDispatch, useSelector } from "react-redux";
import { setProxyRelations } from "store/features/employees/employeesSlice";
import { proxyRelationsSelector } from "store/features/employees/employeesSelectors";

const ProfileRelationModal = (props) => {
  const { handleClose, employeesId, handleChangeRelations, patient } = props;
  const appContext = useContext(AppContext);
  const [users, setUsers] = useState([]);
  const [filter, setFilter] = useState({});
  const [showFilter, setShowFilter] = useState(false);
  const [filteredUsers, setFilteredUsers] = useState([]);
  const [triggerFilter, setTriggerFilter] = useState();
  const [relationOptions, setRelationOptions] = useState([]);
  const [loading, setLoading] = useState(false);
  const [timeFilter, setTimeFilter] = useState("All Users");
  const [fullscreen, setFullscreen] = useState(true);
  const [sortBy, setSortBy] = useState("");
  const [sortDescending, setSortDescending] = useState(true);
  const [pageNo, setPageNo] = useState(1);
  const proxyRelations = useSelector(proxyRelationsSelector);
  const [selectedProxyRelations, setSelectedProxyRelations] = useState(proxyRelations);

  const dispatch = useDispatch();

  useEffect(() => {
    getRelationsData();
  }, []);

  const filterUsersAfterSave = () => {
    const filtered = filteredUsers.filter(
      (user) => !selectedProxyRelations.every((relation) => relation.mdID === user.mdID)
    );
    setFilteredUsers(filtered);
  };

  const getRelationsData = async () => {
    const data = await API.getProxyContactData();
    const dataToSave = data
      ?.map((d) => {
        return { value: d.name, label: d.name };
      })
      .sort((a, b) => a.label.localeCompare(b.label)); // Sort alphabetically by label

    setRelationOptions(dataToSave);
  };

  const handleMakeRelation = (item, relation) => {
    if (item.mdID && relation) {
      const isExists = proxyRelations.find((f) => f.mdID === item.mdID);
      if (isExists) {
        appContext.showErrorMessage(`Relation already exist`);
        return;
      }
      const newRelationList = [...selectedProxyRelations, { ...item, relation: relation }];
      setSelectedProxyRelations(newRelationList);
    }
  };

  const onSaveChanges = () => {
    handleSave(selectedProxyRelations);
    dispatch(setProxyRelations(selectedProxyRelations));
    filterUsersAfterSave();
  };

  const [checkboxes, setCheckboxes] = useState([]);
  const searchTerms = ["First Name", "Last Name", "Email", "Phone"];

  const nestedFilter = (targetArray, filters) => {
    if (Object.keys(filters).length === 0) return targetArray;
    const filterKeys = Object.keys(filters);
    //filters main array of objects
    const models = targetArray.filter((obj) => {
      //goes through each key being filtered for
      return filterKeys.every((key) => {
        if (!filters[key].length) {
          return true;
        }
        if (key === "phoneNumber") {
          return obj[key] && obj[key].replace(/\D/g, "").includes(filters[key].replace(/\D/g, ""));
        }
        if (key === "Lab") {
          return obj.labID && appContext.getLabName(obj.labID).toLowerCase().includes(filters[key].toLowerCase());
        }
        if (key === "Show") {
          const siteIds = appContext.sites
            .filter((s) => s.name.toLowerCase().includes(filters[key].toLowerCase()))
            .map((s) => s.id);
          return siteIds.includes(obj.siteID);
        }
        if (key === "ShowID") {
          return obj.siteID === filters[key];
        }
        if (key === "Client") {
          return (
            obj.clientID && appContext.getClientName(obj.clientID).toLowerCase().includes(filters[key].toLowerCase())
          );
        }
        if (key === "result") {
          return obj[key] && obj["viewedResult"].toLowerCase().includes(filters[key]);
        }
        if (key === "sequenceNo") {
          return obj[key] && obj["sequenceNo"].toString().includes(filters[key]);
        }
        if (key === "emailSend") {
          return obj.emailSend !== null && obj.emailSend == filters[key];
        }
        if (key === "dob") {
          return obj[key] && obj[key] === filters[key];
        }

        if (key === "test_type" && filters[key] && filters[key].length > 0) {
          return obj[key] && filters[key].indexOf(obj[key]) !== -1;
        }

        return obj[key] && obj[key].toLowerCase().includes(filters[key].toLowerCase());
      });
    });
    return models;
  };

  useEffect(() => {
    if (users.length > 0) {
      setFilteredUsers(nestedFilter(users, filter));
    }
  }, [filter]);

  const handleSave = async (newRelationList) => {
    try {
      setLoading(true);
      const relationList = newRelationList.filter((f) => f.relation !== COMBINE_PROFILE);
      const combineList = newRelationList.filter((f) => f.relation === COMBINE_PROFILE);
      let result = await API.updateRelations({
        id: employeesId,
        connectedTo: relationList.map((m) => ({ id: m.mdID, relation: m.relation, relationType: "Child" })),
      });
      const conntedTo = parseJSONObject(result?.updatedEmployee?.connectedTo || []);

      const proxyProfiles = mergeArraysById(conntedTo, result?.updatedEmployee?.proxyProfiles);
      dispatch(setProxyRelations(proxyProfiles));

      let logData = {
        employeeID: employeesId,
        slug: "Proxy Update",
        auditType: "ProxyProfile",
        action: "View Details",
        relations: JSON.stringify(
          relationList.map((m) => ({
            id: m.mdID,
            firstName: m.firstName,
            lastName: m.lastName,
            relation: m.relation,
          }))
        ),
        companyID: CONFIG.clientID,
        userName: appContext?.user?.name,
        userID: appContext.user.sub,
      };

      await API.addLogs(logData);
      if (combineList.length > 0) {
        const res = await API.mergeEmployeeLogs(
          employeesId,
          combineList.map((m) => m.mdID)
        );
        if (res.data) {
          for (const emp of combineList) {
            await API.deleteEmployee(emp.mdID);
          }

          let logData = {
            slug: "Proxy Update",
            employeeID: employeesId,
            auditType: "CombineProfile",
            action: "View Details",
            deletedIds: JSON.stringify(
              combineList.map((m) => ({
                id: m.mdID,
                firstName: m.firstName,
                lastName: m.lastName,
                phoneNumber: m.phoneNumber || m.phone_number,
              }))
            ),
            companyID: appContext.company.id,
            userName: appContext.user.name,
            userID: appContext.user.sub,
          };
          await API.addLogs(logData);
        }
      }
      setLoading(false);
      handleClose();
      appContext.showSuccessMessage("Proxy relation updated successfully!");
    } catch (err) {
      setLoading(false);
      console.log("Error:-", err);
    }
  };

  useEffect(() => {
    setPageNo(1);
    fetchFromApi({ ...filter }, { ...timeFilter }, 1, sortBy, sortDescending);
  }, [filter, timeFilter, sortBy, sortDescending]);

  useEffect(() => {
    if (pageNo > 1) {
      loadEmployees(Object.keys(filter).length === 0 ? { id: 1 } : filter, timeFilter, pageNo, sortBy, sortDescending);
    }
  }, [pageNo]);

  const fetchFromApi = useCallback(
    debounce((filter, timeFilter, page, sortBy, sortDescending) => {
      loadEmployees(filter, timeFilter, page, sortBy, sortDescending);
    }, 800),
    [proxyRelations]
  );

  const loadEmployees = async (params, dateFilter, page, orderBy, orderByDirection) => {
    setLoading(true);
    const filterParam = params;
    try {
      Object.assign(filterParam, { page: page });
      if (dateFilter) {
        const { start, end } = dateFilter;
        if (start)
          Object.assign(filterParam, { startDate: moment(start.format("YYYY-MM-DD")).startOf("day").toISOString() });
        if (end) Object.assign(filterParam, { endDate: moment(end.format("YYYY-MM-DD")).endOf("day").toISOString() });
      }
      if (orderBy) {
        Object.assign(filterParam, { orderBy, orderByDirection });
      }

      const pgList = await API.getEmployeeListFromPG(filterParam);

      const filterEmployees = pgList.rows.filter(
        (f) => f.mdID !== employeesId && proxyRelations.findIndex((i) => i.mdID === f.mdID) === -1
      );
      setUsers(filterEmployees);
      setFilteredUsers(filterEmployees);
      setLoading(false);
    } catch (err) {
      setLoading(false);
    }
  };

  return (
    <Modal show onHide={handleClose} animation={true} size="2x2" scrollable keyboard={true} className="second-modal">
      <Modal.Header closeButton>
        <Modal.Title className="my-0">{`Manage Proxy - ${patient.first} ${patient.last}`}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Card
          className={`strpied-tabled-with-hover  ${
            filteredUsers.length === 1 || filteredUsers.length === 2 ? "test-details-table" : ""
          }`}
        >
          <Card.Body className="table-full-width desktop-noScroll">
            <Filter
              filterTerms={searchTerms}
              setFilter={setFilter}
              filter={filter}
              triggerFilter={triggerFilter}
              setTriggerFilter={setTriggerFilter}
              isUsersTable={true}
            />

            <div className={"table-responsive "}>
              <Table className="table table-fixed">
                <thead>
                  <tr>
                    <th className="tdWidth14">First Name</th>
                    <th className="tdWidth14">Last Name</th>
                    <th className="tdWidth10">Gender</th>
                    <th className="tdWidth14">DoB</th>
                    <th className="tdWidth18">Phone</th>
                    <th className="realtionTdWidth">Relationship</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  {filteredUsers.length > 0 &&
                    filteredUsers.map((emp, index) =>
                      employeesId ? (
                        <ProfileListItems
                          key={emp.mdID}
                          options={relationOptions}
                          //   handleCheck={handleCheckBox}
                          item={emp}
                          employeeId={employeesId}
                          index={index}
                          checkboxes={checkboxes}
                          handleMakeRelation={handleMakeRelation}
                          relations={proxyRelations}
                        />
                      ) : (
                        <></>
                      )
                    )}
                </tbody>
              </Table>
            </div>
          </Card.Body>
        </Card>
      </Modal.Body>
      {/* <div className="px-4 pt-2" style={{ minHeight: "3vh" }}>
        {error && <ErrorMessage error={error} handleChange={() => setError("")} />}
      </div> */}
      <Modal.Footer>
        <div className="d-flex justify-content-between  w-100">
          <Button className="profileButton btn-fill createButtons" variant="primary" onClick={handleClose}>
            Close
          </Button>
          <Button className="profileButton btn-fill createButtons" variant="primary" onClick={onSaveChanges}>
            Save
          </Button>
        </div>
      </Modal.Footer>
      {loading && <Loader />}
      {/* {showConfirmationMessage && (
        <ConfirmationModal
          show={showConfirmationMessage ? true : false}
          title="Update Custom Schedule"
          message={showConfirmationMessage}
          handleConfirm={handleConfirm}
          closeBtn
        />
      )} */}
    </Modal>
  );
};

export default ProfileRelationModal;
