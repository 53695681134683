import { COLUMNS, CUSTOM_INS } from "constant";
import React, { useState, useEffect, useContext } from "react";
import { Card, Col, Row } from "react-bootstrap";
import SwitchToggle from "../SwitchToggle/SwitchToggle";
import EditIcon from "assets/img/edit-icon.png";
import TrashIcon from "assets/img/trash-icon.png";
import { formatCurrency, formatPercentage, toTitleCase, formatDOB, eligibilityMessage } from "utils";
import Status from "components/Status";
import { AppContext } from "context/app-context";

const InsuranceViewCard = (props) => {
  const { insurance, handleUpdateIns, index, handleEdit, cssClass, handleRemove, user } = props;
  const [insObj, setInsObj] = useState(insurance);

  const appContext = useContext(AppContext);

  useEffect(() => {
    setInsObj(insurance);
  }, [insurance]);

  const handleShowEligibility = () => {
    appContext.showEligibilityDetails({
      ...user,
      ...insObj,
      ins_name_f: user.employee_demographics.first,
      ins_name_l: user.employee_demographics.last,
      ins_dob: user.employee_demographics.dob,
    });
  };

  const isNotCustomInsurance = () => !CUSTOM_INS.includes(insObj?.insuranceCompany);

  const showEligibilityStatus = () => {
    const BG_COLOR_CLASS = {
      Eligibile: "text-bg-success",
      "Not Eligibile": "text-bg-danger",
    };

    if (CUSTOM_INS.includes(insObj?.insuranceCompany)) {
      return <></>;
    }

    let insMessage = insObj?.message;
    let titleMessage = insObj?.message;
    const isPrimaryInsurance = insObj?.insuranceCompany === user.insuranceCompanyCode;

    if (insMessage) {
      titleMessage = eligibilityMessage(insObj, user);
    } else if (isPrimaryInsurance) {
      titleMessage = eligibilityMessage(user?.eligibilityStatus, user);
    }

    insMessage = titleMessage === "Eligibile" ? "Eligibile" : "Not Eligibile";
    return (
      <div onClick={handleShowEligibility}>
        <span class={`badge ${BG_COLOR_CLASS[insMessage]} cursor-pointer`} title={titleMessage}>
          {insMessage}
        </span>
      </div>
    );
  };

  return (
    <Card className={cssClass || ""}>
      <Card.Body>
        <Row className="align-items-center">
          <Col md={5} className="px-2">
            <div className="d-flex align-items-center gap-4">
              <span className="fw-bold fs-12 text-ellipsis d-block">{toTitleCase(insObj.insuranceCompanyCode)}</span>
              {/* <span>{`${COLUMNS[index] || ""}`}</span> */}
            </div>
          </Col>

          <Col md={7} className="px-2">
            <div className="d-flex align-items-center justify-content-end gap-2">
              {showEligibilityStatus()}
              <SwitchToggle
                checked={insObj.isActive}
                handleToogleChange={(e) => handleUpdateIns({ ...insObj, isActive: e.target.checked })}
                color={"blue"}
              />
              <img
                src={EditIcon}
                width="18"
                alt="edit icon"
                onMouseLeave={(e) => (e.target.style.color = "black")}
                onMouseOver={(e) => {
                  e.target.style.cursor = "pointer";
                }}
                aria-hidden="true"
                onClick={(e) => handleEdit(insObj)}
              />
              <img
                src={TrashIcon}
                width="18"
                alt="Trash icon"
                onMouseLeave={(e) => (e.target.style.color = "black")}
                onMouseOver={(e) => {
                  e.target.style.cursor = "pointer";
                }}
                aria-hidden="true"
                onClick={(e) => handleRemove(insObj)}
              />
            </div>
          </Col>
          {/* <Col md={2} className="text-right">
            <SwitchToggle
              checked={insObj.isActive}
              handleToogleChange={(e) => handleUpdateIns({ ...insObj, isActive: e.target.checked })}
              color={"blue"}
            />
          </Col>
          <Col md={2} className="text-right">
            <img
              src={EditIcon}
              width="18"
              alt="edit icon"
              onMouseLeave={(e) => (e.target.style.color = "black")}
              onMouseOver={(e) => {
                e.target.style.cursor = "pointer";
              }}
              aria-hidden="true"
              onClick={(e) => handleEdit(insObj)}
            />
          </Col>
          <Col md={2} className="text-right">
            <img
              src={TrashIcon}
              width="18"
              alt="Trash icon"
              onMouseLeave={(e) => (e.target.style.color = "black")}
              onMouseOver={(e) => {
                e.target.style.cursor = "pointer";
              }}
              aria-hidden="true"
              onClick={(e) => handleRemove(insObj)}
            />
          </Col> */}
        </Row>
        <div className="justify-content-between d-flex align-items-center" style={{ marginInline: "-4px" }}>
          <span className="fs-12">
            {isNotCustomInsurance() && <span className="font-bold">Member ID: </span>} {`${insObj?.medicalNo || ""}`}
          </span>
        </div>

        {/* <Row className="justify-content-between d-flex" style={{ lineHeight: "1.2" }}>
          {insObj?.effectiveStartDate && (
            <Col className="d-flex gap-2 justify-content-between gap-2">
              <div className="d-flex flex-column">
                <span className="fw-bold fs-12">Effective From:</span>
                <span className="fs-12">{formatDOB(insObj.effectiveStartDate)}</span>
              </div>
              <div className="d-flex flex-column">
                <span className="fs-12 fw-bold">Effective To:</span>
                <span className="fs-12">{formatDOB(insObj.effectiveEndDate)}</span>
              </div>
            </Col>
          )}
        </Row> */}
        {isNotCustomInsurance() && (
          <Row className="d-flex justify-content-between payerInformation-effective">
            <Col className="px-2 effective-from-width">
              <div className="d-flex flex-column">
                <span className="fw-bold fs-12">Effective From:</span>
                <span className="fs-12">{formatDOB(insObj.effectiveStartDate)}</span>
              </div>
            </Col>
            <Col className="pe-2 ps-0 effective-to-width">
              <div className="d-flex flex-column">
                <span className="fs-12 fw-bold">Effective To:</span>
                <span className="fs-12">{formatDOB(insObj.effectiveEndDate)}</span>
              </div>
            </Col>
            <Col className="d-flex flex-column pe-2 ps-0 copay-width">
              <span className="fw-bold fs-12">Copay: </span>
              <span className=" fs-12">{formatCurrency(insObj.copay)}</span>
            </Col>

            <Col className="d-flex flex-column pe-2 ps-0 deductable-width">
              <span className="fw-bold fs-12">Deductible: </span>
              <span className=" fs-12">{formatCurrency(insObj.deductible)} </span>
            </Col>
            <Col className="pe-2 ps-0 d-flex flex-column coinsurance-width">
              <span className="fw-bold fs-12">Coinsurance: </span>
              <span className=" fs-12">{formatPercentage(insObj.coinsurance)}</span>
            </Col>
          </Row>
        )}
      </Card.Body>
    </Card>
  );
};

export default InsuranceViewCard;
