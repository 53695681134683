import React, { useContext, useState, useMemo, useRef, useCallback } from "react";
import { formatPhoneNumberIntl } from "react-phone-number-input";
import { Button, Card, Table, Row, Col, Modal, Form, Container } from "react-bootstrap";
import { useLocation, Link } from "react-router-dom";
import SortIcon from "../components/SortIcon";
import { useEffect } from "react";
import API from "api";
import GQAPI from "views/GraphQL/gqApi";
import { Auth } from "aws-amplify";
import { Bounce } from "react-activity";
import "react-activity/dist/Bounce.css";
import Select from "react-select";
import ContactInfo from "../components/Modal/ContactInfo";
import Filter from "../components/Filter";
import TimeFilter from "../components/TimeFilter";
import MFPagination from "components/Pagination/MFPagination";
import CreateTestLabModal from "components/Modal/CreateTestLabModal";
import CreateTestDeliveryModal from "components/Modal/CreateTestDeliveryModal";
import Dropdown from "react-bootstrap/Dropdown";
import dotIcons from "../../src/assets/img/dots.png";
import DropdownMenu from "components/DropdownMenu/DropdownMenu";
import debounce from "debounce";
import {
  PAGE_LIMIT,
  PRE_REGISTRATION_STATUS,
  PERSONALISATION_KEY,
  PRE_REG_QR_PERSONALIZE,
  TEST_TYPE_VALUE,
  GEN_TYPE_VALUE,
} from "../constant";
import RenderTd from "components/Personalization/RenderTd";
import StatsView from "components/PreRegister/StatsView";
import RegisterCrew from "views/RegisterCrew";
import {
  changeSort,
  formatPhoneNumber,
  isValidPhone,
  phoneFormatter,
  getDropDownValue,
  parseBooleanValue,
  calculateTdWidth,
  sortTestList,
  sortingFilterInLC,
  personalisationInLC,
  showsInSS,
  formatDOB,
  makeBarcode,
  getPhoneNo,
  formatDoBForDemo,
  registrationShowSelectInLocalStorage,
  getValidName,
} from "../utils";
import HeaderItem from "components/Table/HeaderItem";
import { AppContext } from "../context/app-context";

import Loader from "components/Loader/Loader";
import ExportToExcel from "components/ExportToExcel";
import PersonalizationModal from "components/Modal/personalizationModal";
import OrderDetailModal from "components/Modal/OrderDetailModal";
import VaccinationDetailModal from "components/Modal/VaccinationDetailModal";
import ConfirmationModal from "components/Modal/ConfirmationModal";
import RegisterCrewManual from "./RegisterCrewManual";
import moment from "moment";
import Icon from "components/Icon";

const GenericPreRegistration = () => {
  const mainRef = useRef();
  const [mainWidth, setMainWidth] = useState(0);
  const [moreInfo, setMoreInfo] = useState({});
  const [modalShow, setModalShow] = useState(false);
  const [openPersonalizationModal, setOpenPersonalizationModal] = useState(false);
  const [selectedShow, setSelectedShow] = useState("");
  const [openOrderDetail, setOpenOrderDetail] = useState(false);
  const [selectedOrder, setSelectedOrder] = useState("");
  //const [personalize, setPersonalize] = useState(PRE_REG_QR_PERSONALIZE);
  const [checkboxes, setCheckboxes] = useState([]);
  const [showPreRegistration, setShowPreRegistration] = useState(false);
  const [showLabChangeModal, setShowLabChangeModal] = useState(false);
  const [showDeliveryModal, setShowDeliveryModal] = useState(false);
  const [selectedDeliveryUserId, setSelectedDeliveryUserId] = useState("");
  const [prevSearch, setPrevSearch] = useState("");
  const handleResize = () => {
    if (mainRef.current) setMainWidth(mainRef.current.offsetWidth);
  };

  useEffect(() => handleResize(), [mainRef]);

  useEffect(() => {
    handleResize();
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  });

  const tdWidth = calculateTdWidth(mainWidth, 6);

  const STATS_CLICK_TYPE = {
    ORDERS: "orders",
    ATTENDEES: "attendess",
    COMPLETED: "completed",
    UPLOAD: "upload",
    SHIPPED: "shipped",
    DELIVERED: "delivered",
  };

  const selectedItem = ["first", "last", "email", "phone_number", "id_number"];

  const [users, setUsers] = useState([]);
  const [filteredUsers, setFilteredUsers] = useState([]);
  const [sortBy, setSortBy] = useState("");
  const [sortDescending, setSortDescending] = useState(true);
  const [loading, setLoading] = useState(true);
  const location = useLocation();
  const [triggerFilter, setTriggerFilter] = useState(false);
  const [warningModal, setWarningModal] = useState(false);
  const [showToDelete, setShowToDelete] = useState("");
  const [labs, setLabs] = useState([]);
  const [timeFilter, setTimeFilter] = useState({});
  const [showFilter, setShowFilter] = useState(false);
  const [filter, setFilter] = useState({});
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [error, setError] = useState("");
  const [stats, setStats] = useState("");
  const [selectedUser, setSelectedUser] = useState("");
  const [openUserDetails, setOpenUserDetails] = useState(false);
  const [openDeleteConfirmation, setOpenDeleteConfirmation] = useState(false);

  const { filterDates } = TimeFilter;

  const searchTerms = ["First Name", "Last Name", "Email", "Phone", "ID Number"];

  const appContext = useContext(AppContext);
  const itemsToMap = useMemo(() => {
    const list = filterDates(filteredUsers, timeFilter);
    const ttlPages = list.length > PAGE_LIMIT ? Math.ceil(list.length / PAGE_LIMIT) : 1;
    setTotalPages(ttlPages);
    if (list.length < PAGE_LIMIT || currentPage > ttlPages) {
      setCurrentPage(1);
    }
    if (ttlPages > 1) {
      const indexOfLastUser = currentPage * PAGE_LIMIT;
      const indexOfFirstUser = indexOfLastUser - PAGE_LIMIT;
      return list.slice(indexOfFirstUser, indexOfLastUser);
    }
    return list;
  }, [filteredUsers, currentPage, timeFilter]);

  const nestedFilter = (targetArray, filters) => {
    if (Object.keys(filters).length === 0) return targetArray;
    const filterKeys = Object.keys(filters);
    //filters main array of objects
    console.log("Filter", filters);
    const models = targetArray.filter((obj) => {
      //goes through each key being filtered for
      return filterKeys.every((key) => {
        if (!filters[key].length) {
          return true;
        }

        if (key === "firstName") {
          return obj["first"] && obj["first"].toLowerCase().includes(filters[key]);
        }
        if (key === "lastName") {
          return obj["last"] && obj["last"].toLowerCase().includes(filters[key]);
        }
        if (key === "Show") {
          return obj.showId && appContext.getSiteName(obj.showId).toLowerCase().includes(filters[key].toLowerCase());
        }
        if (key === "step") {
          return obj.step && PRE_REGISTRATION_STATUS[obj.step].toLowerCase().includes(filters[key].toLowerCase());
        }
        if (key === "isVaccinated") {
          return parseBooleanValue(obj.isVaccinated).toLowerCase().includes(filters[key].toLowerCase());
        }
        if (key === "sex") {
          return obj[key] && obj[key] == filters[key];
        }

        if (key === "ordered") {
          return obj["testSend"] === "true" && obj["testSendQty"];
        }
        if (key === "shipped") {
          return obj["orderStatus"] && obj["orderStatus"] !== "delivered";
        }
        return obj[key] && obj[key].toLowerCase().includes(filters[key]);
      });
    });
    return models;
  };

  useEffect(() => {
    const sortingFilters = sortingFilterInLC.get();
    if (sortingFilters.sites.sortBy) {
      setSortDescending(sortingFilters.sites.sortDescending);
      setSortBy(sortingFilters.sites.sortBy);
    }
    // // Check Show from Session storage
    // const selectShowSt = showsInSS.get();
    // if (selectShowSt.selectedShow && Object.keys(selectShowSt.selectedShow).length !== 0)
    //   setSelectedShow(selectShowSt.selectedShow);
  }, []);

  // useEffect(() => {
  //  // loadData();
  //   setFilter({});
  // }, [selectedShow]);

  // const handleAddedPreReg = () => {
  //   if (selectedShow && selectedShow.value) {
  //     loadData();
  //   }
  // };

  // useEffect(async () => {
  //   if (appContext.isShowUpdated) {
  //     console.log("lifecycle", appContext.isShowUpdated, selectedShow);
  //     const prevShowList = showsInSS.get();
  //     if (prevShowList.selectedShow && Object.keys(prevShowList.selectedShow).length !== 0) {
  //       const isPreRegister =
  //         prevShowList.selectedShow.value === appContext.isShowUpdated.id && !appContext.isShowUpdated.preRegistration;
  //       if (isPreRegister) {
  //         prevShowList["selectedShow"] = {};
  //         showsInSS.save(prevShowList);
  //       }
  //     }
  //     if (selectedShow?.value === appContext.isShowUpdated.id && !appContext.isShowUpdated.preRegistration) {
  //       setSelectedShow("");
  //       setUsers([]);
  //       setFilteredUsers([]);
  //     }
  //   }
  // }, [appContext.isShowUpdated]);
  useEffect(() => {
    setLoading(false);
  }, [appContext.sites]);

  useEffect(() => {
    setLabs(appContext.labs);
  }, [appContext.labs]);
  console.log("PR show", appContext.isShowUpdated);
  useEffect(() => {
    const prevSortingState = sortingFilterInLC.get();
    prevSortingState["sites"] = { filter, timeFilter, sortBy, sortDescending };
    sortingFilterInLC.save(prevSortingState);
    if (filteredUsers.length === 0) return;
    changeSort(
      sortBy,
      sortDescending,
      filteredUsers,
      setFilteredUsers,
      appContext.getSiteName,
      appContext.getLabName,
      appContext.getClientName,
      appContext.getSiteTests
    );
  }, [sortBy, sortDescending]);

  useEffect(() => {
    if (location.state) {
      setShowFilter(true);
      setFilter({ ...filter, [location.state.term]: location.state.name });
      setTriggerFilter(true);
    }
    setTimeout(() => setShowFilter(false), 1);
  }, [location.state]);

  useEffect(() => {
    fetchFromApi(filter);
    if (filter && Object.keys(filter).length > 1) {
      setFilteredUsers(nestedFilter(users, filter));
    }
    if (Object.values(filter).some((e) => !e)) {
      const filterObj = { ...filter };
      Object.keys(filterObj).forEach((k) => filterObj[k] == "" && delete filterObj[k]);
      setFilter(filterObj);
    }
    if (Object.values(filter).every((e) => !e)) {
      setPrevSearch("");
    }
  }, [filter]);

  console.log("Users", users, filteredUsers);

  const fetchFromApi = useCallback(
    debounce((filter) => {
      if (
        filter &&
        Object.keys(filter).length === 1 &&
        filter[Object.keys(filter)[0]].trim() &&
        JSON.stringify(filter) !== JSON.stringify(prevSearch)
      )
        getPreRegistrationData(filter);
    }, 2500),
    []
  );

  const createMoreInfo = (type, id) => {
    let infoObj = null;
    if (type === "site") infoObj = appContext.sites.find((site) => site.id === id);
    else if (type === "client") infoObj = appContext.clients.find((client) => client.id === id);
    else infoObj = labs.find((lab) => lab.id === id);
    if (infoObj) {
      setMoreInfo({
        type,
        name: infoObj.name,
        contact: infoObj.contact_name,
        phone: infoObj.contact_phone,
        email: infoObj.contact_email,
      });
      setModalShow(true);
    }
  };
  const handleCloseContactInfo = () => {
    setMoreInfo({});
    setModalShow(false);
  };
  console.log("filter", filter);
  const getPreRegistrationData = async (params) => {
    setLoading(true);
    const filterParam = {};
    const keys = Object.keys(params);
    if (keys.length) {
      let phone = "";
      let countryCode = "";
      let phoneNo = "";
      let name = "";
      if (keys[0] === "phone_number") {
        [countryCode, phoneNo, phone] = getPhoneNo(params[keys[0]]);
      }
      console.log("countrycode", countryCode, "phoneNo", phoneNo, "phone", phone);
      if (keys[0] === "firstName" || keys[0] === "lastName") {
        name = getValidName(params[keys[0]]);
      }
      Object.assign(filterParam, {
        phone_number: phoneNo || name || params[keys[0]],
        type: GEN_TYPE_VALUE[keys[0]],
        siteID: "",
        countryCode,
      });
    }
    try {
      const response = await API.getPreRegistrationData(filterParam);
      console.log("response", response);
      refreshList(response);
      setUsers(response || []);
      setPrevSearch(params);
      setFilter(params);
      setLoading(false);
    } catch (err) {
      console.log(err.message);
      setLoading(false);
      appContext.showErrorMessage("Fail to load the data");
    }
  };
  const loadData = async () => {
    try {
      if (selectedShow) {
        let list = [];
        setLoading(true);
        list = await API.getShowPreRegistration(selectedShow.value);
        console.log("list", list);
        list = list.map((d) => {
          const obj = d;
          obj.vaccinationDetails =
            typeof d.vaccinationDetails === "string" ? JSON.parse(d.vaccinationDetails) : d.vaccinationDetails;
          if (obj.hasOwnProperty("orderTracking") && d.orderTracking.length > 0) {
            obj.orderTracking = typeof d.orderTracking === "string" ? JSON.parse(d.orderTracking) : d.orderTracking;
          }
          return obj;
        });
        setLoading(false);
        refreshList(list);
        setUsers(list || []);
      }
    } catch (err) {
      console.log("Error", err);
      setLoading(false);
      appContext.showErrorMessage("Fail to load the data");
    }
  };

  const refreshList = (list) => {
    const siteList = [...list] || [];

    if (sortBy) {
      setFilteredUsers(
        sortTestList(
          sortBy,
          sortDescending,
          siteList,
          appContext.getSiteName,
          appContext.getLabName,
          appContext.getClientName,
          appContext.parseResult
        )
      );
    } else {
      setFilteredUsers(siteList);
    }
  };

  const getSiteOptions = useMemo(() => {
    if (!appContext.sites) return [];
    return appContext.sites
      .filter((s) => s.preRegistration)
      .map((l) => {
        return { value: l.id, label: l.name, ...l };
      });
  }, [appContext.sites]);

  const handleFilterChange = (event, picker) => setTimeFilter({ start: picker.startDate, end: picker.endDate });
  const handleFilterCancel = (event, picker) => setTimeFilter({});
  const handleChangePage = (page) => setCurrentPage(page);

  const flipSort = (by) => {
    setSortBy(by);
    setSortDescending(sortBy === by ? !sortDescending : true);
  };

  useEffect(() => {
    const data = users.reduce((obj, pre) => {
      if (pre.orderId !== -1) obj["totalOrders"] = (obj["totalOrders"] || 0) + parseInt(pre.testSendQty || 0, 10);
      if (pre.orderId !== -1 && pre.testSend === "true") obj["totalAttendees"] = (obj["totalAttendees"] || 0) + 1;
      if (pre.isExternalTest || pre.isExternalTest === "true") obj["totalResult"] = (obj["totalResult"] || 0) + 1;
      if (pre.step === 5) obj["totalAttendeesQr"] = (obj["totalAttendeesQr"] || 0) + 1;
      if (pre.hasOwnProperty("orderStatus")) {
        if (pre.orderStatus !== "") {
          pre.orderStatus !== "delivered"
            ? (obj["totalShipped"] = (obj["totalShipped"] || 0) + 1)
            : (obj["totalReceived"] = (obj["totalReceived"] || 0) + 1);
        }
      }
      return obj;
    }, {});
    setStats(data);
  }, [users]);

  const handlePersonalization = async (data) => {
    setPersonalize([...data]);
    const dataIds = data.filter((d) => d.isCheck).map((c) => c.id);
    personalisationInLC.save("preregistration", dataIds);
    setOpenPersonalizationModal(false);
    await API.saveUserPersonalisation(appContext.user.sub, personalisationInLC.get());
  };

  const handleOrderDetail = async (order, obj) => {
    if (order) {
      const isArray = Array.isArray(order);
      let data = [];
      if (!isArray) {
        data.push(order);
      }
      setSelectedOrder({ data: data, user: obj });
      setOpenOrderDetail(true);
    }
  };

  const handleDeleteConfirm = async (isConfirm) => {
    setOpenDeleteConfirmation(false);
    if (!isConfirm) {
      setUserToDelete(null);
      return;
    }
    try {
      const updateSelectedTestResult = filteredUsers
        .filter((test) => checkboxes.includes(test.id))
        .map((d) => {
          return { id: d.id, phone_number: d.phone_number };
        });
      const ttlTest = updateSelectedTestResult.length;
      if (!ttlTest) {
        return;
      }
      setLoading(true);
      await API.deletePreRegistration(updateSelectedTestResult);
      setUsers(users.filter((test) => !checkboxes.includes(test.id)));
      setFilteredUsers(filteredUsers.filter((test) => !checkboxes.includes(test.id)));
      setCheckboxes([]);
      appContext.showSuccessMessage("Record removed successfully");
    } catch (error) {
      appContext.showErrorMessage(error.message);
    }
    setLoading(false);
  };

  const handleClearFilter = () => {
    setUsers([]);
    setFilteredUsers([]);
  };
  const handleCheckboxChange = (e, id) => {
    const filteredList = checkboxes.filter((c) => c !== id);
    if (e.target.checked) {
      filteredList.push(id);
    }
    setCheckboxes(filteredList);
  };
  const handleStatsClick = (type) => {
    switch (type) {
      case STATS_CLICK_TYPE.ORDERS:
      case STATS_CLICK_TYPE.ATTENDEES:
        setFilter({ ordered: "1" });
        break;
      case STATS_CLICK_TYPE.COMPLETED:
        setFilter({ step: "Pre Registered" });
        break;
      case STATS_CLICK_TYPE.UPLOAD:
        setFilter({ isExternalTest: "true" });
        break;
      case STATS_CLICK_TYPE.SHIPPED:
        setFilter({ shipped: "shipped" });
        break;
      case STATS_CLICK_TYPE.DELIVERED:
        setFilter({ orderStatus: "delivered" });
        break;
    }
  };

  // OPen delivery model from actions th

  const handleShowDeliveryModal = (id) => {
    setSelectedDeliveryUserId(id);
    setShowDeliveryModal(true);
  };

  const isPCRShow = () => selectedShow && selectedShow.orderKitType && selectedShow.orderKitType === "PCR";

  const TableRow = ({ user }) => {
    return (
      <tr className="trDataWrapper">
        {/* <td className="icon" style={{ width: "45px", minWidth: "45px", maxWidth: "45px" }}></td> */}
        <td className="ellipsis" style={{ textAlign: "left" }}>
          <div className="d-flex">
            <Form.Check>
              <Form.Check.Input
                type="checkbox"
                value={user.id}
                checked={checkboxes.indexOf(user.id) !== -1}
                onChange={(e) => handleCheckboxChange(e, user.id)}
              ></Form.Check.Input>
            </Form.Check>
          </div>
        </td>
        {PRE_REG_QR_PERSONALIZE.filter((f) => f.isCheck).map((item, index) => {
          return (
            <RenderTd
              handleOrderDetail={handleOrderDetail}
              key={index}
              item={item}
              user={user}
              createMoreInfo={createMoreInfo}
            />
          );
        })}

        {/* Dropdown actions in table */}
        {isPCRShow() ? (
          <td style={{ textAlign: "center" }}>
            <>
              <Dropdown className="custom-dropdown">
                <Dropdown.Toggle id="dropdown-basic">
                  <img src={dotIcons} alt="more icon" />
                </Dropdown.Toggle>
                <Dropdown.Menu>
                  <Dropdown.Item
                    style={{ color: "#42cef5" }}
                    onClick={() => {
                      setSelectedUser(user);
                      setOpenUserDetails(true);
                    }}
                  >
                    View
                  </Dropdown.Item>

                  {user.testID && (
                    <>
                      <Dropdown.Item>
                        <Link
                          style={{ textDecoration: "none", paddingRight: "57px" }}
                          to={{
                            pathname: "/admin/test/" + user.testID,
                          }}
                          className="py-2 ps-0"
                        >
                          View Test
                        </Link>
                      </Dropdown.Item>
                      {/* <Dropdown.Item style={{ color: "#42cef5" }}>Re-Print Label</Dropdown.Item> */}
                      {user.orderStatus !== "Shipped" && (
                        <Dropdown.Item onClick={() => handleShowDeliveryModal(user.id)} style={{ color: "#42cef5" }}>
                          {user.orderStatus}Add Tracking Info
                        </Dropdown.Item>
                      )}
                    </>
                  )}
                </Dropdown.Menu>
              </Dropdown>
            </>
          </td>
        ) : (
          <td style={{ color: "#42cef5", textAlign: "center", cursor: "pointer" }}>
            <Link
              to={{
                pathname: "/preregister/" + `${user.id}&phone=${user.phone_number}`,
                state: JSON.stringify(user),
              }}
              target="_blank"
              className="py-2 ps-0"
            >
              View
            </Link>
          </td>
        )}
      </tr>
    );
  };

  const populate = (record) => {
    const {
      zip,
      state,
      sex,
      city,
      isVaccinated,
      employeeType,
      street,
      phone_number,
      dob,
      last,
      first,
      email,
      id_number,
      id,
      country,
      countryCode,
    } = record;
    console.log("records", record);
    const [ccCode, phno] = getPhoneNo(phone_number, countryCode);

    const obj = {
      employee_demographics: {
        email: email,
        firstName: first,
        lastName: last,
        dob: formatDoBForDemo(dob),
        phoneNumber: `${ccCode}${phno}`,
        countryCode: ccCode,
        address: "",
        employeeType: employeeType,
        isVaccinated: isVaccinated,
        whiteGlove: false,
        city: city,
        country: country || "United States",
        state: state,
        street: street,
        street2: "",
        zip: zip,
        isLucira: false,
        sex: sex,
        testerDes: "",
        idNumber: id_number,
        status: "Pending",
      },
      phoneNumber: phone_number,
      email: email,
      employeeID: id,
    };

    obj = {
      ...obj,
      employee_demographics: obj.employee_demographics,
      email: obj.email,
      phoneNumber: obj.phoneNumber,
      employeeID: obj.employeeID,
    };

    return obj;
  };

  console.log("selected Show", selectedShow);
  //Create Test
  const handleCreateTest = async (lab) => {
    try {
      setLoading(true);

      const seltectedUserData = users.filter(
        (item) => checkboxes.includes(item.id) && !item?.sequenceNo && item.orderId === -1
      );

      if (seltectedUserData.length === 0) {
        setLoading(false);
        setCheckboxes([]);
        appContext.showErrorMessage("Test already created");
        return;
      }

      let clientObj = appContext.clients.filter((item) => item.id == selectedShow.clientID);
      let preRegisterArr = [];

      for (let i = 0; i < seltectedUserData.length; i++) {
        let user = { ...seltectedUserData[i] };
        const [countryCode, phoneNo] = getPhoneNo(user.phone_number, user.countryCode);
        let testObj = {
          employee_demographics: {
            email: user.email,
            firstName: user.first,
            lastName: user.last,
            dob: user.dob,
            phoneNumber: `${countryCode}${phoneNo}`,
            employeeType: "Staff",
            isVaccinated: user.isVaccinated,
            whiteGlove: false,
            city: user.city,
            country: user.country,
            state: user.state,
            street: user.street,
            street2: user.street2,
            zip: user.zipcode,
            sex: user.sex,
            testerDes: "",
            contryCode: countryCode,
            idNumber: user.id_number || moment().valueOf(),
          },
          sequenceNo: "",
          clientID: clientObj[0].id,
          clientName: clientObj[0].name,
          baseSiteID: user.showId,
          siteID: user.showId,
          site_name: user.showName,
          labID: lab.value,
          labName: lab.label,
          test_type: "PCR",
          phoneNumber: phoneNo,
          email: user.email,
          emailSend: false,
          isFalsePositive: false,
          status: "Pending",
          referenceID: moment().valueOf(),
          employeeID: "",
          createSource: "PR",
        };

        let env = window.location.host.includes("localhost") ? "localhost" : "remote";
        let record = await API.getPreRegisterData(phoneNo, env);
        let newObject = record.filter(
          (obj) =>
            (obj.phone_number == phoneNo || obj.phone_number == `${countryCode}${phoneNo}`) &&
            obj.first.toLowerCase() == testObj?.employee_demographics?.firstName.trim().toLowerCase() &&
            obj.last.toLowerCase() == testObj?.employee_demographics?.lastName.trim().toLowerCase() &&
            obj.email.toLowerCase() == testObj?.employee_demographics?.email.trim().toLowerCase() &&
            obj.idNumber.toLowerCase() === testObj?.employee_demographics?.idNumber.trim().toLowerCase() &&
            moment(obj.dob).format("MMDDYYYY") == testObj?.employee_demographics?.dob.trim().replace("/-/g", "")
        );
        if (newObject.length > 0 && newObject[0].fetchFrom === "Employee") {
          let newEmployeeData = populate(newObject[0]);
          testObj = { ...testObj, ...newEmployeeData };
        } else {
          let newEmployee = await GQAPI.createNewEmployee({
            ...testObj?.employee_demographics,
            countryCode,
            phoneNumber: phoneNo,
          });
          if (newEmployee && newEmployee.id) {
            testObj = { ...testObj, employeeID: newEmployee.id };
          }
        }
        // CREATE SEQUENCE NUMBER
        let newSequenceNo;
        newSequenceNo = await GQAPI.getTestAutoNumber();
        testObj.sequenceNo = newSequenceNo.counter;

        // Lab Check barcode is active or not
        const checkLab = labs.find((item) => item.id === testObj.labID && item.barCodeProvided);
        let barcode = "";
        if (checkLab) {
          barcode = await API.getLabProvidedBarCode(checkLab.id);
        } else if (!barcode) {
          barcode = makeBarcode(testObj.employee_demographics.idNumber, newSequenceNo.counter);
        }

        // create test
        testObj = {
          ...testObj,
          submittedByName: appContext?.user?.name,
          createdBy: appContext?.user?.sub,
          tester_name: appContext?.user?.name,
          barcode,
        };

        let newTest = await GQAPI.createTest(testObj);
        if (newTest) {
          preRegisterArr.push({
            id: user.id,
            testID: newTest.id,
            phone_number: user.phone_number,
            sequenceNo: testObj.sequenceNo,
            barcode,
          });
        }
      }
      if (preRegisterArr.length > 0) {
        await API.prTestCreation(preRegisterArr);
        // await loadData();
        appContext.showSuccessMessage("Test created successfully");
      } else {
        appContext.showErrorMessage("Test can't be created");
      }
      setLoading(false);
      setCheckboxes([]);
    } catch (err) {
      console.log("Create Error", err);
      setLoading(false);
      setCheckboxes([]);
      appContext.showErrorMessage("Error in Creating Test");
    }
  };

  const updateLabChange = async (lab) => {
    setShowLabChangeModal(false);
    handleCreateTest(lab);
  };

  const saveDelivery = async (delivery) => {
    setShowDeliveryModal(false);
    try {
      let deliveryOBj = { ...delivery, shipmentDate: moment(delivery.shipmentDate).toISOString() };
      let selectedUserData = users.find((obj) => obj.id === selectedDeliveryUserId);

      if (!selectedUserData?.id || !selectedUserData?.phone_number) {
        return appContext.showErrorMessage("Delivery can't be created");
      }

      const [countryCode, phoneNo] = getPhoneNo(selectedUserData.phone_number, selectedUserData.countryCode);
      deliveryOBj = { ...deliveryOBj, phone_number: phoneNo, id: selectedUserData.id };
      let deliveryResult = await GQAPI.createDelivery(deliveryOBj);
      if (deliveryResult == "ok") {
        setLoading(true);
        // loadData();
        setLoading(false);
        appContext.showSuccessMessage("Delivery created successfully");
      } else {
        appContext.showErrorMessage("Delivery can't be created");
      }
    } catch (error) {
      appContext.showErrorMessage("Delivery can't be created");
    }
    setCheckboxes([]);
    setSelectedDeliveryUserId("");
  };

  // Show select
  const handleSelectShow = (value) => {
    setSelectedShow(value);
    let selectShowSt = showsInSS.get();
    console.log("selectShowSt", selectShowSt);
    selectShowSt["selectedShow"] = value;
    showsInSS.save(selectShowSt);
  };

  return (
    <Container fluid>
      {!loading ? (
        <div className="shows-screen-print">
          <Row className="mx-0">
            <Col md="12">
              <Card className="border-0 mb-1">
                <Card.Body className="p-0">
                  <div className="shows-filter-wrapper">
                    <div className="shows-filter-inner">
                      <Icon
                        handleClick={() => setShowFilter(!showFilter)}
                        title={"Filter"}
                        label={"Filter"}
                        iconType={"filter"}
                      />
                      {selectedShow && (
                        <Icon
                          handleClick={() => setShowPreRegistration(true)}
                          title={"Add Manual"}
                          label={"Add"}
                          iconType={"createIcon"}
                        />
                      )}
                      {isPCRShow() && (
                        <Icon
                          disabled={checkboxes.length === 0}
                          onClick={() => setShowLabChangeModal(true)}
                          title={"Print a Label"}
                          label={"Print"}
                          iconType={"printIcon"}
                        />
                      )}
                      {filteredUsers.length > 0 && (
                        <div>
                          <ExportToExcel
                            items={filteredUsers}
                            selectedItem={selectedItem}
                            appContext={appContext}
                            filter={filter}
                            timeFilter={timeFilter}
                            title="Pre Registration User"
                            sortBy={sortBy}
                          />
                        </div>
                      )}
                      {selectedShow && (
                        <div className="fs-5 px-2">
                          <span>Show Test Type : {TEST_TYPE_VALUE[selectedShow.orderKitType] || "General"}</span>
                        </div>
                      )}
                    </div>
                  </div>
                  <div>
                    {showFilter && (
                      <Filter
                        filterTerms={searchTerms}
                        setFilter={setFilter}
                        filter={filter}
                        triggerFilter={triggerFilter}
                        setTriggerFilter={setTriggerFilter}
                        handleClearFilter={handleClearFilter}
                        isUsersTable={true}
                      />
                    )}
                  </div>
                </Card.Body>
              </Card>
            </Col>
            <Col md="12">
              <Card className="striped-tabled-with-hover bg-transparent">
                <Card.Header className="shows-screen-wrapper">
                  <Row className="pb-4">
                    <Col md="6">
                      <Card.Title>
                        <div>
                          Generic Pre Registration:
                          <span className="ps-2">{filteredUsers ? filteredUsers.length : 0}</span>
                        </div>
                      </Card.Title>
                    </Col>
                    <Col md="6">
                      <MFPagination
                        currentPage={currentPage}
                        handleChangePage={handleChangePage}
                        totalPages={totalPages}
                      />
                    </Col>

                    {/* <div className="buttonHeader clientsButtonHeader justify-content-between mb-1 w-100">
                      <div className="buttonHeader clientsButtonHeader w-100">
                        <Button className="headerButton mb-0" onClick={() => setShowFilter(!showFilter)}>
                          Filter
                        </Button>

                        {filteredUsers.length > 0 && (
                          <div>
                            <ExportToExcel
                              items={filteredUsers}
                              selectedItem={selectedItem}
                              appContext={appContext}
                              filter={filter}
                              timeFilter={timeFilter}
                              title="Pre Registration User"
                              sortBy={sortBy}
                            />
                          </div>
                        )}

                        {selectedShow && (
                          <Button className="headerButton" onClick={() => setShowPreRegistration(true)}>
                            Add Manual
                          </Button>
                        )}

                        {isPCRShow() && (
                          <Button
                            className="headerButton"
                            disabled={checkboxes.length === 0}
                            onClick={() => setShowLabChangeModal(true)}
                          >
                            Print Label
                          </Button>
                        )}

                        {selectedShow && (
                          <div className="fs-4 px-2">
                            <span>Show Test Type : {TEST_TYPE_VALUE[selectedShow.orderKitType] || "General"}</span>
                          </div>
                        )}
                      </div>
                    </div>
                    <div>
                      {showFilter && (
                        <Filter
                          filterTerms={searchTerms}
                          setFilter={setFilter}
                          filter={filter}
                          triggerFilter={triggerFilter}
                          setTriggerFilter={setTriggerFilter}
                          handleClearFilter={handleClearFilter}
                          isUsersTable={true}
                        />
                      )}
                    </div> */}

                    {/* {stats && Object.keys(stats).length > 0 && (
                      <div>
                        <Col>
                          <div className="preRegistrationOrderDetails">
                            <StatsView
                              label="Tests ordered"
                              value={stats.totalOrders ?? 0}
                              type={STATS_CLICK_TYPE.ORDERS}
                              handleClick={handleStatsClick}
                            />
                            <StatsView
                              label="Attendees who ordered"
                              value={stats.totalAttendees ?? 0}
                              type={STATS_CLICK_TYPE.ATTENDEES}
                              handleClick={handleStatsClick}
                            />
                            <StatsView
                              label="Completed Registration"
                              value={stats.totalAttendeesQr ?? 0}
                              type={STATS_CLICK_TYPE.COMPLETED}
                              handleClick={handleStatsClick}
                            />
                            <StatsView
                              label="Uploaded Test Results"
                              value={stats.totalResult ?? 0}
                              type={STATS_CLICK_TYPE.UPLOAD}
                              handleClick={handleStatsClick}
                            />
                            <StatsView
                              label="Tests Shipped"
                              value={stats.totalShipped ?? 0}
                              type={STATS_CLICK_TYPE.SHIPPED}
                              handleClick={handleStatsClick}
                            />
                            <StatsView
                              label="Tests Delivered"
                              value={stats.totalReceived ?? 0}
                              type={STATS_CLICK_TYPE.DELIVERED}
                              handleClick={handleStatsClick}
                            />
                          </div>
                        </Col>
                      </div>
                    )} */}
                  </Row>
                </Card.Header>

                <Card.Body className="table-full-width px-0 desktop-noScroll pt-0 mt-0">
                  <div className="table-responsive pendingReleaseTable" ref={mainRef}>
                    <Table
                      className="separate-border-tr"
                      style={{ width: mainWidth, maxWidth: mainWidth, minWidth: mainWidth }}
                    >
                      <thead>
                        <tr>
                          <th></th>
                          {PRE_REG_QR_PERSONALIZE.filter((f) => f.isCheck).map((item, index) => (
                            <HeaderItem
                              key={index}
                              width={tdWidth}
                              ItemKey={item.itemKey}
                              title={item.title}
                              flipSort={flipSort}
                              sortBy={sortBy}
                              sortDescending={sortDescending}
                              aligned={item.textAlign}
                            />
                          ))}

                          <th
                            className="border-0 centered text-grey"
                            style={{ fontWeight: "bold", width: tdWidth, maxWidth: tdWidth }}
                          >
                            QR Link
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {itemsToMap &&
                          itemsToMap.map((user) => {
                            return <TableRow key={user.id} user={user} />;
                          })}
                      </tbody>
                    </Table>
                  </div>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </div>
      ) : (
        <Loader />
      )}
      <ContactInfo show={modalShow} moreInfo={moreInfo} handleClose={handleCloseContactInfo} />
      {/* {openPersonalizationModal && (
        <PersonalizationModal
          data={personalize}
          handleChange={handlePersonalization}
          show={openPersonalizationModal}
          handleClose={() => setOpenPersonalizationModal(false)}
        />
      )} */}
      {openOrderDetail && (
        <OrderDetailModal
          order={selectedOrder}
          show={openOrderDetail}
          handleClose={() => {
            setOpenOrderDetail(false);
            setSelectedOrder("");
          }}
        />
      )}
      {openUserDetails && (
        <VaccinationDetailModal
          user={selectedUser}
          show={openUserDetails}
          handleClose={() => {
            setOpenUserDetails(false);
            setSelectedUser("");
          }}
        />
      )}
      {openDeleteConfirmation && (
        <ConfirmationModal
          show={openDeleteConfirmation}
          title="Delete Record"
          message="Are you sure, you want to remove Record?"
          handleConfirm={handleDeleteConfirm}
        />
      )}
      {showPreRegistration && (
        <Modal
          show={showPreRegistration}
          animation={true}
          onHide={() => setShowPreRegistration(false)}
          centered
          size="xl"
        >
          <Modal.Header closeButton>
            <Modal.Title className="my-0" id="contained-modal-title-vcenter">
              Event Registration
            </Modal.Title>
          </Modal.Header>

          <Modal.Body style={{ paddingTop: 0 }}>
            <RegisterCrewManual
              id={selectedShow.value}
              selectedShow={selectedShow}
              handleAddedPreReg={handleAddedPreReg}
            />
          </Modal.Body>
        </Modal>
      )}
      {showLabChangeModal && (
        <CreateTestLabModal
          handleSave={updateLabChange}
          labs={appContext.labs}
          handleClose={() => setShowLabChangeModal(false)}
        />
      )}
      {showDeliveryModal && (
        <CreateTestDeliveryModal handleSave={saveDelivery} handleClose={() => setShowDeliveryModal(false)} />
      )}
    </Container>
  );
};

export default GenericPreRegistration;
