
import Styles from "./CloseButton.module.css";

const CloseButton = (props) => {
  const {handleClose} = props;
  return (
    
    <div className={`${Styles.closeButton}`}  onClick={handleClose}>
      <span variant="primary" className={`${Styles.btnClose}`}>
        <i className="fa fa-times"></i>
      </span>
    </div>
  );
}
export default CloseButton;