import { configureStore } from "@reduxjs/toolkit";
import { combineReducers } from "redux";
import authReducer from "./features/authentication/authenticationSlice";
import checkEligibilityReducer from "./features/checkEligibility/checkEligibilitySlice";
import clientsReducer from "./features/clients/clientsSlice";
import dashboardReducer from "./features/dashboard/dashboardSlice";
import eligibilityFileReducer from "./features/eligibilityFile/eligibilityFileSlice";
import employeeClaimSliceReducer from "./features/employeeClaims/employeeClaimsSlice";
import employeesReducer from "./features/employees/employeesSlice";
import generalReducer from "./features/general/generalSlice";
import locationsReducer from "./features/locations/locationsSlice";
import providersReducer from "./features/providers/providersSlice";
import subAgentsReducer from "./features/subAgents/subAgentsSlice";
import usersReducer from "./features/users/userSlice";
import errorMiddleware from "./middlewares/errorMiddleware";
import loaderMiddleware from "./middlewares/loaderMiddleware";
import companySettingReducer from "./features/companySetting/companySettingSlice";
import employeeLogsReducer from "./features/employeeLogs/employeeLogsSlice";
import cptCodeReducer from "./features/cptCodes/cptCodeSlice";
import insuranceReducer from "./features/insurances/insuranceSlice";
import documentReducer from "./features/documents/documentSlice";
import claimInvoiceReducer from "./features/claimInvoice/claimInvoiceSlice";
import invoiceDocumentReducer from "./features/invoiceDocuments/invoiceDocumentSlice";
import bulkInvoicesReducer from "./features/bulkInvoices/bulkInvoicesSlice";
import processPaymentReducer from "./features/processPayment/processPaymentSlice";
import invoicePreviewSlice from "./features/invoicePreview/invoicePreviewSlice";
import clientPcsSlice from "./features/clientPcs/clientPcsSlice";
import salePersonSlice from "./features/salePerson/salePersonSlice";
import messageTemplateSlice from "./features/messageTemplate/messageTemplateSlice";
import resultsTestSlice from "./features/resultsTests/resultsTestSlice";
import generalSettingsSlice from "./features/testDictionary/testDictionarySlice";
import testAssaySlice from "./features/testAssay/testAssaySlice";
import genericModalSlice from "./features/genericTestsView/genericModalSlice";
import reslutPdfSlice from "./features/resultPdf/reslutPdfSlice";

const rootReducer = combineReducers({
  companySetting: companySettingReducer,
  employeeLogs: employeeLogsReducer,
  employees: employeesReducer,
  clients: clientsReducer,
  subAgents: subAgentsReducer,
  locations: locationsReducer,
  eligibilityFiles: eligibilityFileReducer,
  providers: providersReducer,
  employeeClaims: employeeClaimSliceReducer,
  auth: authReducer,
  eligibilities: checkEligibilityReducer,
  dashboard: dashboardReducer,
  general: generalReducer,
  users: usersReducer,
  cptCode: cptCodeReducer,
  insurances: insuranceReducer,
  documents: documentReducer,
  invoiceDocuments: invoiceDocumentReducer,
  claimInvoice: claimInvoiceReducer,
  bulkInvoice: bulkInvoicesReducer,
  invoicePreview: invoicePreviewSlice,
  processPayment: processPaymentReducer,
  clientPcs: clientPcsSlice,
  salePerson: salePersonSlice,
  messageTemplate: messageTemplateSlice,
  resultTest: resultsTestSlice,
  generalSettings: generalSettingsSlice,
  testAssay: testAssaySlice,
  genericModal: genericModalSlice,
  resultPdf: reslutPdfSlice,
});

const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) => [
    ...getDefaultMiddleware({
      serializableCheck: false,
    }),
    loaderMiddleware,
    errorMiddleware,
  ],
});

export default store;
