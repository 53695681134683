import React, { useEffect, useState } from 'react';
import Select from 'react-select';
import api from 'api';
import Icon from 'components/Icon';
import { TEST_SETTING_TYPES } from 'constant';

import CreateOptions from './CreateOptions';


const useOptions = (settingType) => {
  const [options, setOptions] = useState([]);
  
  const getOptions = async () => {
    try {
      const optionList = await api.getGeneralSetting(settingType);
      setOptions(optionList);
    } catch (error) {
      console.error(`Error fetching ${settingType}:`, error);
    }
  };

  const addOption = async (optionName, id = null) => {

      try {
        const isExist = options.some(
          (item) => item.label.toLowerCase() === optionName.toLowerCase(),
        );
        if (!isExist) {
          await api.createOption(settingType, optionName);
        }
      } catch (error) {
        console.error(`Error adding ${settingType}:`, error);
      }
    
    getOptions();
  };

  useEffect(() => {
    getOptions();
  }, [settingType]);

  return { options, addOption };
};

const TestSelector = ({
  settingType,
  onChange,
  value,
  tabIndex = 1,
  label = 'Units',
  className = '',
  title = 'Create New Unit',
  placeholder = 'Select Units',
  updateMainTest,
  required = false,
  isMulti = false,
  defaultValue = null,
}) => {
  const { options, addOption } = useOptions(settingType);

  const [showCreateOptionModal, setShowCreateOptionModal] = useState(false);

  const handelAddOption = (value) => {
    addOption(value);
    updateMainTest(value);
    setShowCreateOptionModal(false);
  };

  return (
    <div className="row mt-2">
      <p className="testDetailsSelector">
        {label}
        {required && <span style={{ color: 'red' }}>*</span>}
      </p>
      <div className="d-flex align-items-center">
        <Select
          className={`w-100 ${className}`}
          options={options}
          blurInputOnSelect={true}
          menuPlacement={'auto'}
          defaultValue={defaultValue}
          value={value}
          placeholder={placeholder}
          onChange={onChange}
          tabIndex={tabIndex}
          isMulti={isMulti}
        />
        <Icon
          iconType={'createIcon'}
          containerClass={'ms-2 cursor-pointer'}
          innerClass={'test-selector-add-icon'}
          handleClick={() => setShowCreateOptionModal(true)}
        />
      </div>
      {showCreateOptionModal && settingType !== TEST_SETTING_TYPES.OPTIONS && (
        <CreateOptions
          handleClose={() => setShowCreateOptionModal(false)}
          title={title}
          label={label}
          handleSaveChange={handelAddOption}
          placeholder={placeholder}
        />
      )}

 
    </div>
  );
};

export default TestSelector;
