import React, { useEffect, useState } from "react";
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    BarElement,
    ArcElement,
    Title,
    Tooltip,
    Legend,

} from "chart.js";

import API from "api";
import { Bar , Line , Pie} from "react-chartjs-2";
import { SUMMARY_STATS_TYPE } from "constant";
import moment from "moment";
import { GRAPH_COLOR_VALUES } from "constant";


ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    BarElement,
    ArcElement,
    Title,
    Tooltip,
    Legend
);

const DashboardBarChart = (props) => {


  const { statsType, connectedID , statsTitle } = props;
  
    const [ summaryResultData ,  setSummaryResultData] = useState([]);
    const [dateType , setDateType] = useState('month');
    const [labels , setGraphLabel] = useState([]);
    const [colors , setColors] = useState(GRAPH_COLOR_VALUES);
    const [legendcolor, setLegend] = useState([]);

    

    const loadGraphStats = async (statsSummary) => {
      try {
  
        let startDate = moment().startOf(dateType).format('YYYY-MM-DD');
        let endDate = "";
        let dates = [];
        let formatedDates = [];
        let graphlabels = [];
        if (dateType == "isoWeek") {
          endDate = moment().endOf(dateType).format('YYYY-MM-DD');
          for (var i = 0; i <= 6; i++) {
            dates.push(moment(startDate).add(i, 'days').format("DD/MM/YYYY"));
          };
          graphlabels = ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun",];
        } else {
          endDate = moment().endOf(dateType).format('YYYY-MM-DD');
          var day = moment().format('D');
          for (var i = 0; i < Number(day); i++) {
            dates.push(moment(startDate).add(i, 'days').format("DD/MM/YYYY"));
            formatedDates.push(moment(startDate).add(i, 'days').format('DD-MMM'));
          };
          graphlabels = formatedDates;
  
  
        }
  
        const summaryStatsData = await API.getSummaryFromPG({ summary: statsSummary, startDate, endDate, id: connectedID });
        //console.log("summaryStatsData:", summaryStatsData.rows);
  
  
        //get Clients-wise Data
        const nameArray = [];
        summaryStatsData?.rows?.map(test => {
          if (!(nameArray.includes(test.name))) {
            nameArray.push(test.name);
          }
  
        });
  
        let seriesDataArr = [];

          let tempSeriesArray = [];
          let dateElementLabel= ""

             dates.forEach((dateElement, index) => {
             tempSeriesArray.push(summaryStatsData?.rows?.filter((test) => test.date == dateElement).reduce((ttl , obj) => ttl + Number(obj.count) , 0)  || 0) ;
             
          });
          
        seriesDataArr.push({ data: tempSeriesArray || '' });

        let colorsArr = [];
        seriesDataArr?.map((data, index) => {
          colorsArr.push({ color: colors[index], label: data.datalabel });
        });
  
        setLegend(colorsArr);
        setGraphLabel(graphlabels);
        setSummaryResultData(seriesDataArr);
  
  
  
  
      } catch (err) {
        console.log("Error ", err);
      }
  
    };
  
    useEffect(async () => {
      loadGraphStats(statsType);
    }, []);

  const options = {
    responsive: true,
      maintainAspectRatio: false,
    plugins: {
      legend: {
        display: false,
      },
      title: {
        display: false,
        text: SUMMARY_STATS_TYPE[statsType],

      },
      legend: {
        display: false,
        labels: {
          usePointStyle: true,
          boxWidth: 8,

        }
      },
    },
  };


  const data = {
    labels,
    datasets:  summaryResultData.map((data , index) =>{
        return {
              label: data.datalabel || '' ,
              data: data.data,
              borderColor:  colors[index],
              backgroundColor: colors[index],
              borderWidth: 10,
            }
        
    }),

   
  };

    return (
  

<>

<Bar width={100} height={100} options={options} data={data} className="mb-3" />

<div className="d-flex justify-content-start px-2" style={{marginBlock: "-18px"}}>
  <p className="mb-1 text-center pe-2 stats-title">{statsTitle}</p>
  <div className="stats-title-border d-flex align-items-center flex-wrap">
      {
        legendcolor.map((element) => {
          let color = element.color;
          return <span className="row" title={element.label} style={{
            margin: "5px",
            height: "15px",
            width: "15px",
            backgroundColor: color,
            borderRadius: "50%",
            display: "inline-block",
            alignContent: 'center',

          }} value={element.label} >

      </span>
        })
      }
  </div>
</div>

</>
    );
};



export default DashboardBarChart;
