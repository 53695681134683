import React, { useEffect } from "react";
import { Button } from "react-bootstrap";
import FileSaver from "file-saver";
import * as XLSX from "xlsx-js-style";
import { formatPhoneNumber } from "utils";
import moment from "moment";
import Icon from "./Icon";
import { CONFIG } from "constant";

const ExportCrewToExcel = (props) => {
  const { items, selectedItem ,appContext} = props;

  const exportData = () => {
    if (!selectedItem) return items;
    return items.map((item) => {
      let obj = {};
      selectedItem.forEach((c) => {
        if (c === "phoneNumber") {
          Object.assign(obj, { [c]: `${item[c].replace(/ /g, "")}` });
        }else if (c === "gender") {
          Object.assign(obj, { [c]: item['sex'] });
        }else if (c === "isVaccinated") {
          Object.assign(obj, { [c]: item['isVaccinated'] ? 'Yes' : 'No' });
        } else {
          Object.assign(obj, { [c]: item[c] });
        }
      });
      return obj;
    });
  };
  const handleExportToExcel = async () => {
    if (items?.length > 0) {
      const data = exportData();
      const colLength = Object.keys(data[0]).length;
      const rowLength = Object.keys(data).length;
     
      const fileName = `${CONFIG.basename}.xlsx`;
      const fileType = "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
      const ws = XLSX.utils.aoa_to_sheet([selectedItem], { origin: "A1" });
      XLSX.utils.sheet_add_json(ws, data, { skipHeader: true, origin: -1 });
      const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
      const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
      const fileContent = new Blob([excelBuffer], { type: fileType });
      try {
        await FileSaver.saveAs(fileContent, fileName);
        appContext.showSuccessMessage("Excel file export successfully");
      } catch (err) {
        appContext.showErrorMessage("file can't be expored");
      }
    }
  };

  // return (
  //   <Button className="headerButton" onClick={handleExportToExcel}>
  //     Export to Excel
  //   </Button>
  // );
  return <Icon handleClick={handleExportToExcel} title={"Export to Excel"} label="Export" iconType={"XLSIcon"} />;
};

export default ExportCrewToExcel;
