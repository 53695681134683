import timesIcon from "assets/img/times-circle.svg";
import { DEFAULT_ACCEPTED_FILE_TYPES, FILE_REPRESENT_TYPES_MAP } from "constant";
import { useRef } from "react";
import { removeWhitespaceAndParentheses } from "../../utils";

const MultipleFileInput = (props) => {
  const {
    files,
    setFiles,
    setFileTypes,
    setSelectedFileNames,
    setFileSizes,
    selectedFileNames,
    acceptFiles,
    fileTypes,
    fileSizes,
  } = props;
  const fileInputRef = useRef();

  const handleDragOver = (e) => {
    e.preventDefault();
  };

  const openFileDialog = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  const onRemoveFile = (index, e) => {
    e.stopPropagation();

    const updatedFiles = [...files];
    const updatedFileTypes = setFileTypes ? [...setFileTypes] : [];
    const updatedFileNames = [...selectedFileNames];
    const updatedFileSizes = setFileSizes ? [...setFileSizes] : [];

    updatedFiles.splice(index, 1);
    updatedFileTypes.splice(index, 1);
    updatedFileNames.splice(index, 1);
    updatedFileSizes.splice(index, 1);

    setFiles(updatedFiles);
    setFileTypes && setFileTypes(updatedFileTypes);
    setSelectedFileNames(updatedFileNames);
    setFileSizes && setFileSizes(updatedFileSizes);
  };

  const isFileDuplicate = (fileName) => {
    return selectedFileNames.includes(fileName);
  };

  const isInputFileTypeValid = (type) => {
    return acceptFiles.includes(FILE_REPRESENT_TYPES_MAP.get(type) || type);
  };

  const addNewFiles = (newFiles) => {
    const updatedFiles = [...files];
    const updatedFileTypes = setFileTypes ? [...setFileTypes] : [];
    const updatedFileNames = [...selectedFileNames];
    const updatedFileSizes = setFileSizes ? [...setFileSizes] : [];

    newFiles.forEach((file, index) => {
      const newFileName = removeWhitespaceAndParentheses(file.name);
      if (!isFileDuplicate(newFileName) && isInputFileTypeValid(file.type)) {
        updatedFiles.push(file);
        updatedFileTypes.push(fileTypes ? fileTypes[index] : "");
        updatedFileNames.push(newFileName);
        updatedFileSizes.push(fileSizes ? fileSizes[index] : "");
      }
    });

    setFiles(updatedFiles);
    setFileTypes && setFileTypes(updatedFileTypes);
    setSelectedFileNames(updatedFileNames);
    setFileSizes && setFileSizes(updatedFileSizes);
  };

  const handleFileChange = (event) => {
    if (event.currentTarget.files && event.currentTarget.files.length > 0) {
      const newFiles = Array.from(event.currentTarget.files);
      addNewFiles(newFiles);
    }
  };

  const handleDrop = (e) => {
    e.preventDefault();
    if (e.dataTransfer && e.dataTransfer.files) {
      const newFiles = Array.from(e.dataTransfer.files);
      addNewFiles(newFiles);
    }
  };

  return (
    <div className="d-flex flex-column align-items-center" onDragOver={handleDragOver} onDrop={handleDrop}>
      <div className="d-flex flex-column align-items-center file-upload-area" onClick={openFileDialog}>
        {selectedFileNames && selectedFileNames.length > 0 ? (
          <div
            className={`d-flex align-items-center column-gap-15 ${
              selectedFileNames.length > 4 ? "small-file-icons" : ""
            }`}
          >
            {selectedFileNames.map((fileName, index) => (
              <div key={index} className="file-content-wrapper">
                <div className="file-icon-container file-width-cust">
                  <img
                    src={require(`assets/img/pdf.svg`).default}
                    alt="File Icon"
                    className={selectedFileNames.length > 4 ? "small-icon" : "file-icon"}
                  />
                  <span className="remove-icon" onClick={(e) => onRemoveFile(index, e)}>
                    <img style={{ width: "18px" }} src={timesIcon} alt="" />
                  </span>
                </div>
                <div className={"text-ellipsis"}>{fileName}</div>
              </div>
            ))}
          </div>
        ) : (
          <p className="mt-2 mb-0">Drag & Drop or Click to Select Files</p>
        )}
      </div>

      <input
        ref={fileInputRef}
        type="file"
        accept={acceptFiles || DEFAULT_ACCEPTED_FILE_TYPES}
        onChange={handleFileChange}
        multiple
        style={{ display: "none" }}
      />
    </div>
  );
};

export default MultipleFileInput;
