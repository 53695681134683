import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import api from "../../../api";
import { SUBAGENT, SUBAGENT_FETCH, USER_SUBAGENT_FETCH } from "./subAgentsContants";
// Async thunk to fetch all subAgents
export const fetchAllSubAgents = createAsyncThunk(SUBAGENT_FETCH, async () => {
  const response = await api.getSubAgents();
  return response;
});

// Async thunk to create a subAgent
export const createSubAgentAsync = createAsyncThunk("subAgent/createSubAgent", async (subAgent) => {
  const response = await api.newSubAgent(subAgent);
  return response;
});

//Async thunk to Fetch user subAgent
export const fetchUserSubAgent = createAsyncThunk(USER_SUBAGENT_FETCH, async () => {
  const subAgent = await api.getUserSubAgent();
  return subAgent;
});

// Async thunk to update a subAgent
export const updateSubAgentAsync = createAsyncThunk(
  "subAgent/updateSubAgent",
  async ({ subAgentId, updatedsubAgent }) => {
    const response = await api.updateSubAgent(subAgentId, updatedsubAgent);
    return response;
  }
);

// Async thunk to delete a subAgent
export const deleteSubAgentAsync = createAsyncThunk("subAgent/deleteSubAgent", async (subAgentId) => {
  const response = await api.deleteSubAgent(subAgentId);
  return response;
});

const subAgentSlice = createSlice({
  name: SUBAGENT,
  initialState: {
    subAgents: [],
    userSubAgent: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchAllSubAgents.fulfilled, (state, action) => {
        state.subAgents = action.payload;
      })
      .addCase(createSubAgentAsync.fulfilled, (state, action) => {
        state.subAgents.push(action.payload);
      })
      .addCase(updateSubAgentAsync.fulfilled, (state, action) => {
        const updatedsubAgent = action.payload;
        const existingsubAgentIndex = state.subAgents.findIndex((subAgent) => subAgent.id === updatedsubAgent.id);
        if (existingsubAgentIndex !== -1) {
          state.subAgents[existingsubAgentIndex] = updatedsubAgent;
        }
      })
      .addCase(deleteSubAgentAsync.fulfilled, (state, action) => {
        const deletedsubAgentId = action.payload;
        state.subAgents = state.subAgents.filter((subAgent) => subAgent.id !== deletedsubAgentId);
      })
      .addCase(fetchUserSubAgent.fulfilled, (state, action) => {
        state.subAgents = [action.payload];
        state.userSubAgent = action.payload;
      });
  },
});

export default subAgentSlice.reducer;
