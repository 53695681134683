import { createSlice } from "@reduxjs/toolkit";
import { INVOICE_PREVIEW } from "./invoicePreviewConstant";
import { addSelectedInvoice, resetInvoice } from "./invoicePreviewAction";
import { updateClaimMessage } from "../bulkInvoices/bulkInvoicesAction";

const invoicePreviewSlice = createSlice({
  name: INVOICE_PREVIEW,
  initialState: {
    selectedInvoice: {},
    isBulk: false,
    viewOnly: false,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(addSelectedInvoice.fulfilled, (state, action) => {
      state.selectedInvoice = action.payload.selectedInvoice;
      state.isBulk = action?.payload?.isBulk || false;
      state.viewOnly = action?.payload?.viewOnly || false;
    });
    builder.addCase(resetInvoice.fulfilled, (state) => {
      state.selectedInvoice = {};
      state.isBulk = false;
      state.viewOnly = false;
    });
    builder.addCase(updateClaimMessage.fulfilled, (state, action) => {
      const { id, message } = action.payload;
      if (state.selectedInvoice && state.selectedInvoice.claimID === id) {
        state.selectedInvoice.message = message;
      }
    });
  },
});

export default invoicePreviewSlice.reducer;
