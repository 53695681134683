import React from "react";
import Styles from "components/CheckBox/ViewOnlyCheckBox.module.css";
const ViewOnlyCheckBox = (props) => {
  const { color, id, bgColor, checked, handleChange, item } = props;

  const getColor = () => {
    switch (bgColor) {
      case "red":
        return Styles.red;
      case "blue":
        return Styles.blue;
      case "yellow":
        return Styles.yellow;
      case "orange":
        return Styles.orange;
      default:
        return Styles.green;
    }
  };
  return (
    <div>
      <input
        id={id}
        className={`${Styles.checkboxCustom}`}
        onChange={handleChange}
        name={id}
        type="checkbox"
        value={checked}
        checked={checked}
      />
      <label htmlFor={id} className={`mb-0 ${getColor()} ${Styles.checkboxCustomLabel}`} />
    </div>
  );
};

export default ViewOnlyCheckBox;
