import React from "react";
import notesOnIcon from "assets/img/file-icon.png";
import notesOffIcon from "assets/img/file-icon-gray.png";

const Notes = (props) => {
  const { note, handleClick, empNotes } = props;

  return (
    <div className="action-exclamation" onClick={() => handleClick()}>
      <span
        className="cursor-pointer"
        title={note || `${empNotes ? "Patient Note:" + empNotes : ""}` || "Add Note"}
        style={{
          borderColor: note ? "rgb(83 83 232)" : "rgb(186 184 184)",
        }}
      >
        {note || empNotes ? (
          <img className="notesIcon" src={notesOnIcon} alt="notes-on-img" />
        ) : (
          <img className="notesIcon" src={notesOffIcon} alt="notes-off-img" />
        )}
      </span>
    </div>
  );
};

export default Notes;
