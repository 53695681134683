import Status from "components/Status/Status";
import { getPreRegistrationLink } from "constant";
import { useState } from "react";
import { QRCode } from "react-qrcode-logo";
import QrCodeIcon from "../../assets/img/qr-code.svg";
import QrCodeDownloadIcon from "../../assets/img/qr-download.svg";
import logo from "assets/img/logo.jpg";

const TDPreRegisteration = (props) => {
  const { item , handleCopyPRLink, downloadQRCode , currentURL} = props;
  const [showCopyText, setShowCopyText] = useState(false);
  //console.log('Props om TDR:::' , props);


  const handleCopyPRLink1 = (id) => {
    navigator.clipboard.writeText(getPreRegistrationLink(id));
    setShowCopyText(true);
    setTimeout(() => setShowCopyText(false), 2000);
  };

  return (
    <td>
      <div className="d-flex align-items-center justify-content-center">
        <div className="d-flex align-items-center text-primary-black fs-6">
          <div className="star-icon-wrapper">
            {/* <Status type="circle" size="md" color="maroon" crossIcon /> */}
            <Status
              type="circle"
              size="md"
              color={item.preRegistration ? "green" : "maroon"}
              tickIcon={item.preRegistration}
              crossIcon={!item.preRegistration}
            />
          </div>
          {/* <span className={`preRegisterationBullet ${cssClass}`}></span> {label} */}
        </div>

        { item.preRegistration && (
                        <>
                          <div className="d-flex justify-content-between align-items-center position-relative">
                            {showCopyText && <div className="copy-link-text">Copy to clipboard!</div>}
                            {/* <div className="fontFourteen" style={{ paddingLeft: "8px" }}>
                              QR Code
                            </div> */}
                            <div className="d-flex align-items-center justify-content-between qr-code-download">
                              <div onClick={() => handleCopyPRLink1(item.id)} style={{cursor: "pointer"}}>
                              <img src={QrCodeIcon} alt="qr-code-icon" width="25" className="mx-2" />
                                {/* <i className="fas fa-copy" /> */}
                              </div>
                              <div onClick={() => downloadQRCode(item.name)} style={{cursor: "pointer"}}>
                                {/* <i className="fas fa-download"></i> */}
                                <img src={QrCodeDownloadIcon} alt="qr-code-download" width="25" />
                              </div>
                              <span style={{ display: "none" }}>
                                <QRCode
                                  id="qr-gen"
                                  ecLevel={"H"}
                                  value={getPreRegistrationLink(item.id, currentURL)}
                                  enableCORS={true}
                                  logoImage={logo}
                                  size="250"
                                  logoHeight={"70"}
                                  logoWidth={"70"}
                                  fgColor={"#000"}
                                  bgColor={"#fff"}
                                />
                              </span>
                            </div>
                          </div>
                     
                        </>
                      )}

        {/* <img src={QrCodeIcon} alt="qr-code-icon" width="25" className="mx-2" />
        <img src={QrCodeDownloadIcon} alt="qr-code-download" width="25" /> */}
      </div>
    </td>
  );
};
export default TDPreRegisteration;
