import ViewCheckBox from "components/CheckBox/ViewCheckBox";
import { Button, Card, Col, Modal, Row, Table } from "react-bootstrap";
// import ViewCheckBox from "./CheckBox/ViewCheckBox";

const ProviderSearchRecord = (props) => {
  const { data, handleSelect, handleClose, setSelectSearchData, selectSearchdata, cssClass } = props;
  const TableRow = ({ item, index }) => {
    // const { basic } = item;
    return (
      <tr className="cursor-pointer active" onClick={() => setSelectSearchData(item)}>
        <td className="popOver-centered">{item.firstName}</td>
        <td className="popOver-centered">{item.middleName}</td>
        <td className="popOver-centered">{item.lastName}</td>
        <td className="popOver-centered">{item.dob}</td>
        {/* <td className="popOver-centered">{item.taxonomies[0].code}</td> */}
        <td>{(selectSearchdata && selectSearchdata?.number) === item?.number && <ViewCheckBox />}</td>
      </tr>
    );
  };

  return (
    <Modal
      className={["searchList", cssClass]}
      show
      animation={true}
      size="xl"
      onHide={() => {
        handleClose();
      }}
      style={{ paddingLeft: "0" }}
      centered
      scrollable
    >
      <Modal.Header closeButton>
        <Modal.Title className="my-0" id="contained-modal-title-vcenter">
          Search Eligibility List
        </Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <div style={{ flex: 1 }}>
          <Row>
            <Col md="12">
              <Card className="strpied-tabled-with-hover m-0">
                <Card.Body>
                  <div className="form-group-wrapper m-0 d-block">
                    <div className="table-responsive overFlow-y-hidden">
                      <Table className="table">
                        <thead>
                          <tr>
                            <th className="popOver-centered border-0">First Name</th>
                            <th className="popOver-centered border-0">Middle Name</th>
                            <th className="popOver-centered border-0">Last Name</th>
                            <th className="popOver-centered border-0">DOB</th>
                            <th></th>
                          </tr>
                        </thead>
                        <tbody>
                          {data.map((item, i) => (
                            <TableRow key={i} index={i} item={item} />
                          ))}
                        </tbody>
                      </Table>
                    </div>
                  </div>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="primary" className="modalButtons headerButton btn-fill" onClick={handleClose}>
          Close
        </Button>
        <Button
          variant="secondary"
          className="modalButtons headerButton btn-fill"
          onClick={() => {
            if (selectSearchdata) handleSelect(selectSearchdata);
          }}
        >
          Ok
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default ProviderSearchRecord;
