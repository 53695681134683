import React, { useState, useMemo } from "react";
import { Modal, Button } from "react-bootstrap";
import Select from "react-select";
import { ReactSelectCustomContainer } from "components/CustomReactSelect/ReactSelectCustomOptionContainer";
import FileInput from "components/Input/FileInput";
import "../../../src/tableStyling.css";
import ErrorMessage from "components/Message/ErrorMessage";
import { getContentType } from "utils";
import DocumentPreviewModal from "components/Modal/DocumentPreviewModal";
import Loader from "components/Loader/Loader";
import { Storage } from "aws-amplify";
import api from "api";
import { userCompanyID } from "utils";
import { selectLoginUser } from "store/features/authentication/authenticationSelectors";
import { useSelector } from "react-redux";
import { CONFIG } from "constant";
import { BUCKET_EXTERNAL_IMAGES } from "constant";
import { generalSettings } from "store/features/testDictionary/testDictionarySelectors";


const TestConfirmationModal = (props) => {
  const {
    title,
    handleConfirm,
    options,
    handleOptions,
    selectedRow,
    handleRejectReason,
    handleClose,
    employeeID,
    defaultConfig,
    orderId,
  } = props;
  const [file, setFile] = useState("");
  const [selectedFileName, setSelectedFileName] = useState("");
  const [error, setError] = useState("");
  const [previewFileData, setPreviewFileData] = useState();
  const [loading, setLoading] = useState(false);
  const loginUser = useSelector(selectLoginUser);


  const generalSettingsData = useSelector(generalSettings);

  const CANCEL_REASON_OPTIONS = useMemo(() => {
    return generalSettingsData.filter((item) => item.settingType === "cancelled");
  }, [generalSettingsData]);

  const handleViewDoc = async () => {
    try {
      if (!selectedRow?.fileName) return;
      setLoading(true);
      // Determine the content type based on the file extension
      const fileExtension = selectedRow?.fileName.split(".").pop().toLowerCase();

      const contentType = getContentType(fileExtension);

      // Fetch the file from S3 with inline content disposition
      const result = await Storage.get(selectedRow.fileName, {
        bucket: BUCKET_EXTERNAL_IMAGES,

        download: false,
        contentDisposition: "inline",
        contentType: contentType,
      });

      setPreviewFileData({ fileType: fileExtension === "pdf" ? "pdf" : "image", fileUrl: result });
      setLoading(false);
    } catch (err) {
      setLoading(false);
      console.log("Error", err);
    }
  };

  const handleUploadFile = async () => {
    if (isDisableControls()) {
      handleClose();
      return;
    }

    let fileName = "";
    let fileType = null;
    if (file) {
      setLoading(true);

      try {
        fileName = await api.saveDocumentFile(employeeID, selectedFileName, file);

        //   const docObj = {
        //     isNew: true,
        //     docType: "Rejected Sample Image",
        //     docLabel: "",
        //     notes: selectedRow.rejectedReason,
        //     file: {},
        //     fileType: file.type,
        //     selectedFileName: file.name,
        //     fileSize: file.size,
        //     employeeID: employeeID,
        //     fileName: fileName,
        //     updatedBy: loginUser.id,
        //     updatedByName: `${loginUser.firstName}  ${loginUser.lastName}`,
        //   };

        // const response = await api.newDocumentCreateAPI([docObj]);

        setLoading(false);
        fileType = file.type;
      } catch (error) {
        console.error("Error uploading file:", error);
        setLoading(false);
        return;
      }
    }

    if (!selectedRow.rejectedReason || selectedRow.rejectedReason === "") {
      handleRejectReason({
        preDefineReason: null,
      });
    }

    handleConfirm({ isConfirm: true, fileName, fileType});
  };

  const isDisableControls = () => !!selectedRow.scanByLabUserID

  const renderUi = () => {
    if (selectedRow?.testStatus === "sendOut") {
      return (
        <div className="col-sm-12 testAdmin createClientsWrapper">
          <div className="form-group-wrapper">
            <label className="modalLineHeaders">Select Reference Lab: </label>
            <Select
              options={options}
              blurInputOnSelect={true}
              isDisabled={isDisableControls()}
              menuPlacement="auto"
              className="w-100 siteSelector"
              placeholder="Select Reference Lab"
              value={{
                label: selectedRow?.sendToName,
                value: selectedRow?.sendToID,
              }}
              onChange={(e) => handleOptions(e)}
              components={{ ValueContainer: ReactSelectCustomContainer }}
            />
          </div>
        </div>
      );
    }
    return (
      <div>
        <div className="form-group-wrapper">
          <label className="modalLineHeaders">Rejected Reason: </label>
          <Select
            options={CANCEL_REASON_OPTIONS}
            blurInputOnSelect={true}
            menuPlacement="auto"
            className="w-100 siteSelector site-select-test"
            placeholder="Select"
            value={
              selectedRow?.preDefineReason
                ? CANCEL_REASON_OPTIONS.find((c) => selectedRow?.preDefineReason?.includes(c.value))
                : null
            }
            onChange={(e) => {
              handleRejectReason({
                preDefineReason: `${e.label}: ${e.title}`,
                sendToName: "",
                sendToID: "",
              });
            }}
            isDisabled={isDisableControls()}
            components={{ ValueContainer: ReactSelectCustomContainer }}
          />
        </div>
        <div className="col-sm-12 testAdmin createClientsWrapper">
          <div className="form-group-wrapper">
            <label className="modalLineHeaders">Rejected Note: </label>
            <div className="mb-1">
              <textarea
                className="form-control"
                value={selectedRow?.rejectedReason}
                style={{ minHeight: "60px" }} // Set the minimum height
                disabled={ isDisableControls()}
                onChange={(e) => {
                  handleRejectReason({
                    rejectedReason: e.target.value,
                    sendToName: "",
                    sendToID: "",
                  });
                }}
              ></textarea>
            </div>
          </div>
        </div>
        {selectedRow?.fileName ? (
          <Button onClick={handleViewDoc}>Preview Image</Button>
        ) : selectedRow?.collectedDate ? (
          ""
        ) : (
          <div className="col-sm-12 testAdmin createClientsWrapper">
            <div className="form-group-wrapper">
              <label className="modalLineHeaders">Supported Image: </label>
              <FileInput
                setFile={setFile}
                setSelectedFileName={setSelectedFileName}
                selectedFileName={selectedFileName}
              />
            </div>
          </div>
        )}
      </div>
    );
  };

  return (
    <Modal
      backdrop="static"
      show
      animation={true}
      onHide={() => {
        handleConfirm({ isConfirm: false, fileName: "" });
      }}
      centered
      size={"md"}
      className="second-modal"
    >
      <Modal.Header closeButton>
        <Modal.Title className="my-0" id="contained-modal-title-vcenter">
          {title}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className=" desktop-noScroll">
        {" "}
        {loading && <Loader />}
        {renderUi()}
      </Modal.Body>
      <div className="px-4" style={{ height: "3vh" }}>
        {error && <ErrorMessage error={error} handleChange={() => setError("")} />}
      </div>
      <Modal.Footer className="d-flex justify-content-end">
        <Button
          className="modalButtons "
          variant="primary"
          onClick={() => {
            handleUploadFile();
          }}
        >
          {isDisableControls() ? "Close" : "Confirm"}
        </Button>
      </Modal.Footer>
      <DocumentPreviewModal
        isModalOpen={previewFileData?.fileType}
        setIsModalOpen={() => {
          setPreviewFileData();
        }}
        cssClass="preview-modal"
        fileUrl={previewFileData?.fileUrl}
        fileType={previewFileData?.fileType}
      />
    </Modal>
  );
};

export default TestConfirmationModal;
