import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { MESSAGE_MODES } from "constant";
import { userCompanyID } from "utils";
import api from "../../../api";
import { setMessage } from "../general/generalAction";
import { setLoader, showMessage } from "../general/generalSlice";
import {
  DOC_FETCH,
  DOC_CREATE,
  DOC_UPDATE,
  DOC_DELETE,
  DOCUMNET,
  DOC_FETCH_TYPE,
  DOC_TYPE_CREATE,
} from "./documentConstant";

export const fetchDocuemntAsync = createAsyncThunk(DOC_FETCH, async (param, { dispatch }) => {
  const response = await api.fetchDocumentAPI(param); // Your API call logic here
  return response || [];
});

export const fetchDocuemntTypesAsync = createAsyncThunk(DOC_FETCH_TYPE, async (param, { dispatch }) => {
  const response = await api.fetchDocumentTypesAPI(param); // Your API call logic here
  return response || [];
});
export const createDocuemntTypesAsync = createAsyncThunk(DOC_TYPE_CREATE, async (param, { dispatch }) => {
  const response = await api.creteDocumentTypesAPI(param); // Your API call logic here
  if (response && response && response.length > 0) return response[0];
  return null;
});

export const updateDocumentAsync = createAsyncThunk(DOC_UPDATE, async (param, { dispatch, getState }) => {
  const loginUSer = getState().auth.user;

  dispatch(setLoader(true));

  const logObject = {
    employeeID: param.employeeID,
    empID: param.employeeID,
    userID: loginUSer.sub,
    schrID: param.schrID,
    eventType: "UpdateDocument",
    action: "Done",
    clientID: userCompanyID.get(),
    userName: loginUSer.name,
  };

  let fileName = param.fileName;
  if (param.file) {
    fileName = await api.saveDocumentFile(param.employeeID, param.selectedFileName, param.file);
  }
  if (param.employeeID) {
    const response = await api.updateDocumentAPI({
      ...param,
      fileName,
      updatedBy: loginUSer.sub,
      updatedByName: loginUSer.name,
    }); // Your API call logic here

    if (response && response.data) {
      await dispatch(createDocuemntTypesAsync({ title: param.docType }));
      await api.saveLogs(logObject);
      dispatch(setMessage("Document updated Successfully", MESSAGE_MODES.success));
      return response.data;
    }
    dispatch(setMessage(`${response?.error || "Document Not Updated"}`, MESSAGE_MODES.error));

    return null;
  } else {
    dispatch(setMessage("Document updated Successfully", MESSAGE_MODES.success));
    return { ...param, fileName, updatedBy: loginUSer.sub, updatedByName: loginUSer.name };
  }
});

export const createDocumentAsync = createAsyncThunk(DOC_CREATE, async (param, { dispatch, getState }) => {
  const loginUSer = getState().auth.user;
  dispatch(setLoader(true));

  const logObject = {
    employeeID: param.employeeID,
    empID: param.employeeID,
    userID: loginUSer.sub,
    schrID: param.schrID,
    eventType: "CreatedDocument",
    action: "Done",
    clientID: userCompanyID.get(),
    userName: loginUSer.name,
  };
  let fileName = null;
  if (param.file) {
    fileName = await api.saveDocumentFile(param.employeeID, param.selectedFileName, param.file);
  }

  if (param.employeeID) {
    const response = await api.newDocumentCreateAPI([
      {
        ...param,
        fileName,
        updatedBy: loginUSer.sub,
        updatedByName: loginUSer.name,
      },
    ]); // Your API call logic here

    if (response.rows && response.rows.length > 0) {
      await dispatch(createDocuemntTypesAsync({ title: param.docType }));
      await api.saveLogs(logObject);
      dispatch(setMessage("Document Uploaded Successfully", MESSAGE_MODES.success));
      return response.rows[0];
    }
    dispatch(setMessage(`${response?.error || "Document Not Created"}`, MESSAGE_MODES.error));
    return null;
  } else {
    dispatch(setMessage("Document Uploaded Successfully", MESSAGE_MODES.success));
    return { ...param, fileName, updatedBy: loginUSer.sub, updatedByName: loginUSer.name };
  }
});

export const deleteDcoumentAsync = createAsyncThunk(DOC_DELETE, async (param, { dispatch, getState }) => {
  const loginUSer = getState().auth.user;
  const logObject = {
    employeeID: param.employeeID,
    empID: param.employeeID,
    userID: loginUSer.sub,
    schrID: param.schrID,
    eventType: "DeletedDocument",
    action: "Done",
    clientID: userCompanyID.get(),
    userName: loginUSer.name,
  };
  const response = await api.deleteDocumentAPI(param); // Your API call logic here
  if (response && response && response.status) {
    await api.saveLogs(logObject);
    dispatch(setMessage("Document Deleted Successfully", MESSAGE_MODES.success));
    return param;
  }
  dispatch(setMessage(`${response?.error || "Document Not Deleted"}`, MESSAGE_MODES.error));
  return null;
});

const documentSlice = createSlice({
  name: DOCUMNET,
  initialState: {
    documents: [],
    documentTypes: [],
  },
  reducers: {
    setDocumentTypes: (state, action) => {
      state.documentTypes = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchDocuemntAsync.fulfilled, (state, action) => {
        state.documents = action.payload;
      })
      .addCase(fetchDocuemntTypesAsync.fulfilled, (state, action) => {
        console.log('ActionS', action.payload)
        state.documentTypes = action.payload;
      })
      .addCase(createDocuemntTypesAsync.fulfilled, (state, action) => {
        if (action.payload) state.documentTypes.push(action.payload);
      })
      .addCase(createDocumentAsync.fulfilled, (state, action) => {
        if (action.payload) state.documents.unshift(action.payload);
      })
      .addCase(deleteDcoumentAsync.fulfilled, (state, action) => {
        if (action.payload) {
          const deleteDoc = action.payload;
          state.documents = state.documents.filter((f) => f.id !== deleteDoc.id);
        }
      })
      .addCase(updateDocumentAsync.fulfilled, (state, action) => {
        const updateDoc = action.payload;
        if (updateDoc) {
          const existingIndex = state.documents.findIndex((f) => f.id === updateDoc.id);
          if (existingIndex !== -1) {
            state.documents[existingIndex] = updateDoc;
          }
        }
      });
  },
});

export const { setDocumentTypes } = documentSlice.actions;
export default documentSlice.reducer;
