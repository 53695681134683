import React, { useEffect, useState } from "react";
import Select from "react-select";
import { capitalizeLetter, formatPhoneNumber, formatDateOfBirthDOB } from "utils";
import { Button, Form, Modal, Card, Table, Row, Col } from "react-bootstrap";

const RelationView = (props) => {
  const { relations, handleDeleteRelation, isNotDeletable } = props;

  return (
    <>
      <Table className="table table-striped">
        <thead>
          <tr>
            <th className="">First Name</th>
            <th className="">Last Name</th>
            <th className="">Gender</th>
            <th className="">DoB</th>
            <th className="">Phone</th>
            <th className="">Relation</th>
            {!isNotDeletable && <th className="">Action</th>}
          </tr>
        </thead>
        <tbody>
          {relations?.map((item, i) => {
            return (
              <tr key={`relation_${i}`}>
                <td>{capitalizeLetter(item.firstName)}</td>
                <td>{capitalizeLetter(item.lastName)}</td>
                <td>{item.sex}</td>
                <td>{formatDateOfBirthDOB(item.dob)}</td>
                <td>{formatPhoneNumber(item.phoneNumber || item.phone_number, "", true)}</td>
                <td>{item.relation}</td>
                {!isNotDeletable && (
                  <td>
                    <i className="fa fa-trash" onClick={() => handleDeleteRelation(item)} />
                  </td>
                )}
              </tr>
            );
          })}
        </tbody>
      </Table>
    </>
  );
};

export default RelationView;
