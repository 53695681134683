import React, { useState, useEffect, useContext, useMemo, useRef } from "react";
import { formatPhoneNumberIntl } from "react-phone-number-input";
import Select from "react-select";
import countriesOption from "countries";
import { Button, Modal } from "react-bootstrap";
import {
  toTitleCase,
  FormatAWSDate,
  makeAlphabetInput,
  convertToLower,
  isValidEmail,
  makeBarcode,
  formatDoBForDemo,
  isValidPhoneWithCode,
  isValidIdNum,
  getPhoneNo,
  isValidName,
  isValidZipCode,
  validSpace,
  formatZip,
  isValidState,
  isValidDobField,
  formatPhoneNumber,
  formatPhoneWithOutSpaces,
  zipCodeValidationUS,
  convertToUpper,
  sortByAlphaOrder,
} from "../../utils";
import ErrorMessage from "components/Message/ErrorMessage";
import Loader from "components/Loader/Loader";
import PhoneNoInput from "components/Phone/PhoneNoInput";
import EmployeeSearchRecord from "components/EmployeeSearchRecord";
import api from "api";
import GQAPI from "views/GraphQL/gqApi";
import { externalTypesOptions, countryListAllIsoData, TEST_TYPE_VALUE, INSURANCE_PROVIDER } from "constant";
import SingleDatePicker from "Date/SingleDatePicker";
import moment from "moment";
import AutoCompleteAddress from "components/AutoCompleteAddress";
import { AppContext } from "context/app-context";
import { stringConstant } from "constant";
import { CONFIG } from "constant";
import { UN_RESULT } from "constant";

const TestModal = (props) => {
  const { newTestCreate, handleCloseCreateTest, createTest, setTestCreate, clients, sites, labs, isNew, users } = props;
  const [loading, setLoading] = useState(false);
  const [recordData, setRecordData] = useState([]);
  const [showPopOver, setShowPopOver] = useState(false);
  const [selectSearchdata, setSelectSearchData] = useState(null);
  const [show, setShow] = useState("");
  const [error, setError] = useState("");
  const [disableVaccination, setDisabledVaccination] = useState(false);
  const [onSelectedCreateTestType, setOnSelectedCreateTestType] = useState({});
  const [onSelectedTester, setOnSelectedTester] = useState({});
  const [resultTypes, setResultTypes] = useState([]);
  const childNode = useRef();
  const [lab, setLabs] = useState([]);
  const [testOptions, setTestOptions] = useState();
  const appContext = useContext(AppContext);

  const loadTestOptions = async () => {
    const options = await api.getTestTypesOptions();
    setTestOptions(options);
  };

  const getSiteOptions = useMemo(() => {
    if (!newTestCreate.clientID) return [];
    return sites
      .filter((s) => s.clientID === newTestCreate.clientID)
      .map((l) => {
        return { value: l.id, label: l.name };
      });
  }, [newTestCreate.clientID]);

  useEffect(() => {
    if (appContext?.clients?.length > 0) {
      const client = appContext.clients.find((f) => f.id === CONFIG.clientID);
      if (client && client.resultTypes) {
        setResultTypes(
          sortByAlphaOrder(
            [
              ...(client.resultTypes.results.map((m) => ({ value: m, label: m })) || []),
              { value: UN_RESULT, label: UN_RESULT },
            ],
            "label"
          )
        );
      }
    }
  }, [appContext.clients]);

  const usersData = useMemo(() => {
    if (!users) return;
    return users
      .filter((u) => u.roles?.includes("Tester") || u.roles?.includes("Admin"))
      .map((u) => {
        return {
          value: u.userID,
          label: `${u.firstName} ${u.lastName}`,
          phone_number: u.phone_number?.replace(/ /g, ""),
        };
      });
  }, [users]);

  useEffect(() => {
    let updates = {};

    if (clients.length === 1 && !newTestCreate.clientID) {
      const { id, name } = clients[0];
      updates = {
        ...updates,
        clientID: id,
        clientName: name,
      };

      const baseSite = sites.find((s) => s.clientID === id);
      if (baseSite) {
        const { id, name } = baseSite;
        updates = {
          ...updates,
          siteID: id,
          site_name: name,
        };
      }
    }

    if (labs.length === 1 && !newTestCreate.labID) {
      const { id, name } = labs[0];
      updates = {
        ...updates,
        labID: id,
        labName: name,
      };
    }

    if (Object.keys(updates).length > 0) {
      setTestCreate((prev) => ({
        ...prev,
        ...updates,
      }));
    }
  }, [clients, labs, newTestCreate]);

  const getSelectedTester = (onChangeTesterData) => {
    setTestCreate({
      ...newTestCreate,
      createdBy: onChangeTesterData.value,
      tester_name: onChangeTesterData.label,
      testerPhone: onChangeTesterData.phone_number,
    });
    setOnSelectedTester(onChangeTesterData);
  };

  useEffect(() => {
    loadTestOptions();
    if (newTestCreate?.test_type) {
      const TEST_TYPE_VALUE = {
        Lucira: "Lucira",
        PCR: "PCR",
        Molecular: "Molecular",
        Rapid_PCR: "Other",
        Rapid_Antigen: "Antigen",
        Rapid_Flu: "RapidFlu",
      };

      const TEST_TYPE_LABEL = {
        Lucira: "Lucira",
        PCR: "PCR",
        Molecular: "Molecular",
        Other: "Rapid PCR",
        Antigen: "Antigen",
        RapidFlu: "Rapid Flu",
      };

      let type = newTestCreate?.test_type;

      setOnSelectedCreateTestType({ label: type, value: type });
      setTestCreate({
        ...newTestCreate,
        test_type: type,
        employee_demographics: {
          ...newTestCreate.employee_demographics,
          country: newTestCreate.employee_demographics?.country
            ? newTestCreate.employee_demographics?.country
            : "United States",
          isoCode: newTestCreate.employee_demographics?.isoCode ? newTestCreate.employee_demographics?.isoCode : "US",
          phoneNumber: formatPhoneWithOutSpaces(
            newTestCreate.employee_demographics.phoneNumber,
            newTestCreate.employee_demographics.countryCode
          ),
        },
      });
    } else {
      setTestCreate({
        ...newTestCreate,
      });
    }
    if (newTestCreate?.tester_name) {
      setOnSelectedTester({ label: newTestCreate?.tester_name, value: newTestCreate?.tester_name });
    }
  }, []);

  useEffect(() => {
    setTimeout(() => {
      setShow("");
    }, 4000);
  }, [show]);

  useEffect(() => {
    // setSite(appContext.sites);
    setLabs(appContext.labs);
  }, [appContext.sites, appContext.labs]);

  const handleUpdateAddress = (val, flag) => {
    if (!flag) {
      setTestCreate({
        ...newTestCreate,
        employee_demographics: { ...newTestCreate.employee_demographics, street: val },
      });
    } else {
      let addressObj = {
        street: val.street,
        country: val.country || "United States",
        city: val.city,
        zip: val.zipcode,
        county: val.county,
        state: val.state,
        isoCode: val.isoCode || val.isCode || "US",
      };
      setTestCreate({
        ...newTestCreate,
        employee_demographics: { ...newTestCreate.employee_demographics, ...addressObj },
      });
    }
  };

  const handlePhoneNo = (e) => {
    setTestCreate({
      ...newTestCreate,
      employee_demographics: { ...newTestCreate.employee_demographics, phoneNumber: e },
      phoneNumber: e,
    });
  };

  const searchPreReg = async (data, type) => {
    if (!data) return;
    setLoading(true);
    let phoneNo = data;
    let countryCode = "";
    if (phoneNo && type === "Phone") {
      const [cc, ph] = getPhoneNo(data);
      phoneNo = ph;
      countryCode = cc;
    }
    let env = CONFIG.isLive ? "remote" : "localhost";

    let record = [];
    try {
      let siteID = null;

      if (sites.length > 1) {
        siteID = newTestCreate.siteID;
      }

      record = await api.getPreRegisterData(phoneNo, env, type, countryCode, siteID);
      setLoading(false);
    } catch (err) {
      setLoading(false);
    }

    if (record && record.length === 1) {
      populate(record[0]);
      return;
    }
    if (record && record.length > 1) {
      setRecordData(
        record.map((e) => {
          return { ...e, first: toTitleCase(e.first), last: toTitleCase(e.last) };
        })
      );
      setShowPopOver(!showPopOver);
    }
  };

  const populate = (record) => {
    setShowPopOver(false);
    setSelectSearchData(null);
    const {
      zip,
      state,
      sex,
      fetchFrom,
      city,
      whiteGlove,
      isVaccinated,
      employeeType,
      street,
      phone_number,
      dob,
      last,
      first,
      email,
      id_number,
      id,
      country,
      countryCode,
      clientID,
      insurance_name,
      insuranceCompanyCode,
      insurance_number,
    } = record;

    const [ccCode, phno] = getPhoneNo(phone_number, countryCode);
    const val = countryListAllIsoData.find((f) => f.name === country) || { code: "US" };

    const obj = {
      employee_demographics: {
        email: email,
        firstName: first,
        lastName: last,
        dob: formatDoBForDemo(dob),
        phoneNumber: `${ccCode}${phno}`,
        countryCode: ccCode,
        address: "",
        employeeType: employeeType,
        isVaccinated: isVaccinated || false,
        whiteGlove: whiteGlove,
        city: city,
        country: country || "United States",
        isoCode: val.code || "US",
        state: state,
        street: street,
        street2: "",
        zip: zip,
        isLucira: false,
        sex: sex,
        testerDes: "",
        idNumber: id_number,
        insurance_name,
        insuranceCompanyCode,
        insurance_number,
        status: "Pending",
      },
      phoneNumber: phone_number,
      email: email,
      employeeID: CONFIG.clientID === clientID ? id : "",
    };

    setTestCreate({
      ...newTestCreate,
      employee_demographics: obj.employee_demographics,
      email: obj.email,
      phoneNumber: obj.phoneNumber,
      employeeID: obj.employeeID,
    });

    setDisabledVaccination(isVaccinated || false);
  };
  const onHandleSave = async () => {
    let newTestObj = _.cloneDeep(newTestCreate);
    const [ccCode, phoneNo, completePhone] = getPhoneNo(
      newTestCreate?.employee_demographics?.phoneNumber,
      newTestCreate?.employee_demographics?.countryCode
    );

    if (!newTestCreate.employee_demographics.firstName) {
      setError("First name is required");
      return;
    }
    if (!isValidName(newTestCreate.employee_demographics.firstName)) {
      setError("First name is invalid must contains at least 2 letters and Name should be realistic");
      return;
    }
    if (!newTestCreate.employee_demographics.lastName) {
      setError("Last name is required");
      return;
    }
    if (!isValidName(newTestCreate.employee_demographics.lastName)) {
      setError("Last name is invalid must contains at least 2 letters and Name should be realistic");
      return;
    }
    if (!newTestCreate.employee_demographics.email) {
      setError("Email name is required");
      return;
    }

    if (!isValidEmail(newTestCreate.employee_demographics.email)) {
      setError("Invalid Email");
      return;
    }

    if (!newTestCreate.employee_demographics.dob) {
      setError("Date of birth is required");
      return;
    }
    const startDate = moment("1900-01-01").startOf("day").format("YYYY-MM-DD");
    const endDate = moment().endOf("day").format("YYYY-MM-DD");
    if (
      !isValidDobField(newTestCreate.employee_demographics.dob, "MMDDYYYY") ||
      !(
        moment(FormatAWSDate(newTestCreate.employee_demographics?.dob)).isSame(moment().format("YYYY-MM-DD")) ||
        moment(FormatAWSDate(newTestCreate.employee_demographics?.dob)).isBetween(startDate, endDate)
      )
    ) {
      setError("Date of Birth is Invalid");
      return;
    }
    if (!newTestCreate.employee_demographics.phoneNumber) {
      setError("Phone number is required");
      return;
    }
    if (!isValidPhoneWithCode(newTestCreate.employee_demographics.phoneNumber)) {
      setError("Phone number is invalid");
      return;
    }

    if (!newTestCreate.employee_demographics.sex) {
      setError("Gender is required");
      return;
    }

    if (!newTestCreate.employee_demographics.idNumber) {
      setError("License No. is required");
      return;
    }

    if (!isValidIdNum(newTestCreate.employee_demographics.idNumber)) {
      setError(`Invalid license no must contains at least 5 characters`);
      return;
    }

    if (!newTestCreate.employee_demographics.street.trim()) {
      setError("Address is required");
      return;
    }
    if (!newTestCreate.employee_demographics.country) {
      setError("Country is Required");
      return;
    }
    if (newTestCreate.employee_demographics.zip === null || !newTestCreate.employee_demographics.zip) {
      setError("Zipcode is required");
      return;
    }

    // if (
    //   !isValidZipCode(
    //     newTestCreate?.employee_demographics?.isoCode || newTestCreate?.employee_demographics?.isCode || "US",
    //     newTestCreate?.employee_demographics?.zip
    //   )
    // ) {
    //   setError("Invalid Postal Code");
    //   return;
    // }
    if (!zipCodeValidationUS(newTestCreate.employee_demographics.zip)) {
      setError("Invalid Postal Code");
      return;
    }

    if (!newTestCreate.employee_demographics.state.trim()) {
      setError("State is required");
      return;
    }
    if (!newTestCreate.clientID) {
      setError("Client is required");
      return;
    }
    if (!newTestCreate.siteID) {
      setError(`${stringConstant.site} is required`);
      return;
    }

    if (!newTestCreate.labID) {
      setError("Lab is required");
      return;
    }

    if (!newTestCreate.test_type || newTestCreate.test_type == "" || newTestCreate.test_type == "undefined") {
      setError("Test Type is required");
      return;
    }

    if (newTestCreate.result === UN_RESULT) {
      newTestObj.result = "";
      newTestObj.status = "Pending";
    }

    newTestObj.testerPhone = appContext.user.phone_number;
    newTestObj.phoneNumber = phoneNo;
    newTestObj.employee_demographics.phoneNumber = phoneNo;
    newTestObj.employee_demographics.countryCode = ccCode;

    setLoading(true);

    try {
      // save employee data using proxy contact api
      let record = await api.proxyContant({
        ...newTestObj,
        updatedBy: appContext.user.sub,
        updatedByName: appContext.user.name,
        clientID: newTestCreate.clientID,
      });

      // when employee data update fail
      if (!record) {
        setError("Fail to save the Employee Record");
        setLoading(false);
        return;
      }

      // set the newly assigned employee id at test level and in demographics
      if (record.id) {
        newTestObj.employeeID = record.id;
        newTestObj.employee_demographics.id = record.id;
      }

      // check seuqence no is not being used in other test
      if (newTestCreate.sequenceNo) {
        let sequenceData = await GQAPI.getTestBySequenceNoByAPI(newTestCreate.sequenceNo);
        if (sequenceData == newTestCreate.sequenceNo || sequenceData < newTestCreate.sequenceNo) {
          setError("Sequence No. already used");
          setLoading(false);
          return;
        }
        let barcode = makeBarcode(newTestObj.employee_demographics.idNumber, newTestCreate.sequenceNo);
        newTestObj = { ...newTestObj, barcode };
      }

      // if create new test allocate the new sequenec no
      let newSequenceNo;
      if (!newTestCreate?.sequenceNo) {
        // if bio lab then change the barcode format
        if (CONFIG.appPackage === "com.bio.lab") {
          let dateOfTest = moment().format("YYMMDD");
          if (newTestObj.createdAt) {
            dateOfTest = moment(newTestObj.createdAt).format("YYMMDD");
          }
          newSequenceNo = await GQAPI.getLabTestAutoNumber(dateOfTest, newTestObj.labID);
        } else {
          newSequenceNo = await GQAPI.getTestAutoNumber();
        }

        const barcode = newSequenceNo.dailyCounter || newSequenceNo.counter;
        newTestObj = { ...newTestObj, sequenceNo: newSequenceNo.counter, barcode };
      }
      createTest(newTestObj);
    } catch (err) {
      setLoading(false);
      console.log(err);
    }
  };

  const handleTestType = (e) => {
    setOnSelectedCreateTestType({ label: e.label, value: e.value });
    setTestCreate({ ...newTestCreate, test_type: e.value });
  };

  return (
    <>
      <Modal show animation={true} onHide={handleCloseCreateTest} size="xl" style={{ paddingLeft: "0" }} centered>
        <Modal.Header closeButton>
          <Modal.Title className="my-0" id="contained-modal-title-vcenter">
            {isNew === false ? "Update Test" : "Create Test"}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body
          style={{
            paddingTop: 0,
          }}
        >
          <div className="tset-clent-edit createClientsWrapper">
            <div className="form-group-wrapper">
              <label className="modalLineHeaders">First Name: </label>
              <input
                className="modalInput"
                placeholder="First Name"
                style={{
                  width: "100%",
                  flexShrink: "initial",
                }}
                value={newTestCreate?.employee_demographics?.firstName}
                onChange={(e) => {
                  setTestCreate({
                    ...newTestCreate,
                    employee_demographics: {
                      ...newTestCreate.employee_demographics,
                      firstName: makeAlphabetInput(e.target.value),
                    },
                  });
                }}
              />
              <i
                ref={childNode}
                className="fas fa-search cursor-pointer"
                style={{ cursor: "pointer" }}
                onClick={() => searchPreReg(newTestCreate?.employee_demographics?.firstName, "FirstName")}
              />
            </div>
            <div className="form-group-wrapper">
              <label className="modalLineHeaders">Last Name: </label>
              <input
                className="modalInput"
                placeholder="Last Name"
                style={{
                  width: "100%",
                  flexShrink: "initial",
                }}
                value={newTestCreate?.employee_demographics?.lastName}
                onChange={(e) =>
                  setTestCreate({
                    ...newTestCreate,
                    employee_demographics: {
                      ...newTestCreate.employee_demographics,
                      lastName: makeAlphabetInput(e.target.value),
                    },
                  })
                }
              />
              <i
                ref={childNode}
                className="fas fa-search cursor-pointer"
                style={{ cursor: "pointer" }}
                onClick={() => searchPreReg(newTestCreate?.employee_demographics?.lastName, "LastName")}
              />
            </div>
            <div className="form-group-wrapper">
              <label className="modalLineHeaders">Email: </label>
              <input
                className="modalInput"
                placeholder="Email"
                style={{
                  width: "100%",
                  flexShrink: "initial",
                }}
                value={newTestCreate?.employee_demographics?.email}
                onChange={(e) =>
                  setTestCreate({
                    ...newTestCreate,
                    employee_demographics: {
                      ...newTestCreate.employee_demographics,
                      email: convertToLower(e.target.value),
                    },
                    email: convertToLower(e.target.value),
                  })
                }
              />
              <i
                ref={childNode}
                className="fas fa-search cursor-pointer"
                style={{ cursor: "pointer" }}
                onClick={() => searchPreReg(newTestCreate?.employee_demographics?.email, "Email")}
              />
            </div>
            <div className="form-group-wrapper dateWrapper">
              <label className="modalLineHeaders">DoB: </label>
              <input
                className="modalInput"
                type="date"
                style={{
                  width: "100%",
                  flexShrink: "initial",
                }}
                value={FormatAWSDate(newTestCreate.employee_demographics?.dob)}
                placeholder="Date of Birth"
                onChange={(e) =>
                  setTestCreate({
                    ...newTestCreate,
                    employee_demographics: {
                      ...newTestCreate.employee_demographics,
                      dob: moment(e.target.value).format("MMDDYYYY"),
                    },
                  })
                }
                min={moment("1900-01-01").format("YYYY-MM-DD")}
                max={moment().format("YYYY-MM-DD")}
              />
              <i
                ref={childNode}
                className="fas fa-search cursor-pointer"
                style={{ cursor: "pointer" }}
                onClick={() => searchPreReg(newTestCreate?.employee_demographics?.dob, "DOB")}
              />
            </div>

            <div className="form-group-wrapper phoneInput">
              <label className="modalLineHeaders">Phone #: </label>
              <PhoneNoInput handleChange={handlePhoneNo} value={newTestCreate?.employee_demographics?.phoneNumber} />
              <i
                ref={childNode}
                className="fas fa-search cursor-pointer"
                style={{ cursor: "pointer" }}
                onClick={() => searchPreReg(newTestCreate?.employee_demographics?.phoneNumber, "Phone")}
              />
            </div>
            <div className="form-group-wrapper">
              <label className="modalLineHeaders">Gender: </label>
              <div className="d-flex justify-content-between w-100">
                <span className="radioBox-wrapper">
                  <input
                    type="radio"
                    className="radioButton"
                    name="sex"
                    checked={newTestCreate?.employee_demographics?.sex == "M"}
                    onChange={(e) =>
                      setTestCreate({
                        ...newTestCreate,
                        employee_demographics: {
                          ...newTestCreate.employee_demographics,
                          sex: e.target.checked && "M",
                        },
                      })
                    }
                  />
                  <span className="radioButton">Male</span>
                </span>
                <span className="radioBox-wrapper">
                  <input
                    type="radio"
                    className="radioButton"
                    name="sex"
                    checked={newTestCreate?.employee_demographics?.sex == "F"}
                    onChange={(e) =>
                      setTestCreate({
                        ...newTestCreate,
                        employee_demographics: {
                          ...newTestCreate.employee_demographics,
                          sex: e.target.checked && "F",
                        },
                      })
                    }
                  />
                  <span className="radioButton">Female</span>
                </span>
                <span className="radioBox-wrapper">
                  <input
                    type="radio"
                    className="radioButton"
                    name="sex"
                    checked={newTestCreate?.employee_demographics?.sex == "X"}
                    onChange={(e) =>
                      setTestCreate({
                        ...newTestCreate,
                        employee_demographics: {
                          ...newTestCreate.employee_demographics,
                          sex: e.target.checked && "X",
                        },
                      })
                    }
                  />
                  <span className="radioButton">Not Specified</span>
                </span>
              </div>
            </div>
            <div className="form-group-wrapper">
              <label className="modalLineHeaders">DL/Passport #: </label>
              <input
                className="modalInput"
                placeholder="License/Passport"
                style={{
                  width: "100%",
                  flexShrink: "initial",
                }}
                maxLength={16}
                value={newTestCreate?.employee_demographics?.idNumber}
                onChange={(e) =>
                  setTestCreate({
                    ...newTestCreate,
                    employee_demographics: { ...newTestCreate.employee_demographics, idNumber: e.target.value },
                  })
                }
              />
              <i
                ref={childNode}
                className="fas fa-search cursor-pointer"
                style={{ cursor: "pointer" }}
                onClick={() => searchPreReg(newTestCreate?.employee_demographics?.idNumber, "License")}
              />
            </div>

            <div className="form-group-wrapper">
              <label className="modalLineHeaders">Address: </label>
              <AutoCompleteAddress
                value={newTestCreate?.employee_demographics?.street}
                cssClass={"modalInput"}
                handleChange={handleUpdateAddress}
              />
              {/* <input
                ref={ref}
                value={newTestCreate?.employee_demographics?.street}
                placeholder="Enter Address"
                className="modalInput"
                onChange={(e) =>
                  setTestCreate({
                    ...newTestCreate,
                    employee_demographics: {
                      ...newTestCreate.employee_demographics,
                      street: validSpace(e.target.value),
                    },
                  })
                }
              /> */}
            </div>
            <div className="form-group-wrapper">
              <label className="modalLineHeaders">City: </label>
              <input
                className="modalInput"
                placeholder="City "
                value={newTestCreate?.employee_demographics?.city}
                onChange={(e) =>
                  setTestCreate({
                    ...newTestCreate,
                    employee_demographics: { ...newTestCreate.employee_demographics, city: validSpace(e.target.value) },
                  })
                }
              />
            </div>
            <div className="form-group-wrapper">
              <label className="modalLineHeaders">Zip: </label>
              <input
                className="modalInput"
                placeholder="Zip"
                maxLength={10}
                value={newTestCreate?.employee_demographics?.zip}
                onChange={(e) => {
                  let val = e.target.value;
                  if (val) val = val.toUpperCase();
                  setTestCreate({
                    ...newTestCreate,
                    employee_demographics: {
                      ...newTestCreate.employee_demographics,
                      zip: newTestCreate.employee_demographics.isoCode === "US" ? formatZip(val) : validSpace(val),
                    },
                  });
                }}
              />
            </div>
            {loading && <Loader />}
            <div className="form-group-wrapper">
              <label className="modalLineHeaders">State: </label>
              <input
                className="modalInput"
                placeholder="State"
                maxLength={35}
                value={newTestCreate?.employee_demographics?.state}
                onChange={(e) =>
                  setTestCreate({
                    ...newTestCreate,
                    employee_demographics: {
                      ...newTestCreate.employee_demographics,
                      state: e.target.value.toUpperCase(),
                    },
                  })
                }
              />
            </div>
            <div className="form-group-wrapper">
              <label className="modalLineHeaders">Country: </label>
              <Select
                options={countriesOption}
                blurInputOnSelect={true}
                defaultValue={{ label: "United States", value: "United States" }}
                menuPlacement="auto"
                className="w-100 siteSelector"
                placeholder="Select Country"
                value={
                  newTestCreate.employee_demographics.country
                    ? {
                        label: newTestCreate.employee_demographics.country,
                        value: newTestCreate.employee_demographics.country,
                      }
                    : null
                }
                onChange={(e) => {
                  const country = countryListAllIsoData.find((f) => f.name === e.value) || {
                    code: newTestCreate.employee_demographics.isoCode || "US",
                  };
                  setTestCreate({
                    ...newTestCreate,
                    employee_demographics: {
                      ...newTestCreate.employee_demographics,
                      country: e.value,
                      isoCode: country.code,
                    },
                  });
                }}
              />
            </div>

            {clients.length > 1 && (
              <div className="form-group-wrapper">
                <label className="modalLineHeaders">Client </label>
                <Select
                  className="w-100 siteSelector"
                  options={clients.map((l) => {
                    return { value: l.id, label: l.name };
                  })}
                  blurInputOnSelect={true}
                  defaultValue={null}
                  menuPlacement="auto"
                  value={
                    newTestCreate.clientID
                      ? {
                          label: newTestCreate.clientName,
                          value: newTestCreate.clientID,
                        }
                      : null
                  }
                  placeholder="Select Client"
                  onChange={(e) =>
                    setTestCreate({
                      ...newTestCreate,
                      clientID: e.value,
                      clientName: e.label,
                      siteID: null,
                      site_name: null,
                    })
                  }
                />
              </div>
            )}
            {getSiteOptions.length > 1 && (
              <div className="form-group-wrapper">
                <label className="modalLineHeaders">{stringConstant.site} </label>
                <Select
                  className="w-100 siteSelector"
                  options={getSiteOptions}
                  blurInputOnSelect={true}
                  defaultValue={null}
                  menuPlacement="auto"
                  placeholder={`Select ${stringConstant.site}`}
                  value={
                    newTestCreate.siteID
                      ? {
                          label: newTestCreate.site_name,
                          value: newTestCreate.siteID,
                        }
                      : null
                  }
                  onChange={(e) => setTestCreate({ ...newTestCreate, siteID: e.value, site_name: e.label })}
                />
              </div>
            )}
            {labs.length > 1 && (
              <div className="form-group-wrapper">
                <label className="modalLineHeaders">Lab </label>
                <Select
                  className="w-100 siteSelector"
                  options={labs?.map((l) => {
                    return { value: l.id, label: l.name };
                  })}
                  blurInputOnSelect={true}
                  defaultValue={null}
                  menuPlacement="auto"
                  placeholder="Select Lab"
                  value={
                    newTestCreate.labID
                      ? {
                          label: newTestCreate.labName,
                          value: newTestCreate.labID,
                        }
                      : null
                  }
                  onChange={(e) => setTestCreate({ ...newTestCreate, labID: e.value, labName: e.label })}
                />
              </div>
            )}
            <div className="form-group-wrapper">
              <label className="modalLineHeaders">Test Type </label>
              <Select
                className="w-100 siteSelector"
                options={testOptions}
                blurInputOnSelect={true}
                defaultValue={null}
                menuPlacement="auto"
                placeholder="Test Type"
                value={
                  onSelectedCreateTestType.value
                    ? {
                        label: onSelectedCreateTestType.label,
                        value: onSelectedCreateTestType.value,
                      }
                    : null
                }
                onChange={(e) => handleTestType(e)}
              />
            </div>
            {/* <div className="form-group-wrapper">
              <label className="modalLineHeaders">Sequence: </label>
              <input
                className="modalInput"
                type="number"
                placeholder="Sequence No"
                style={{
                  width: "100%",
                }}
                value={newTestCreate?.sequenceNo}
                onChange={(e) => setTestCreate({ ...newTestCreate, sequenceNo: e.target.value })}
              />
            </div> */}
            <div className="form-group-wrapper dateWrapper">
              <label className="modalLineHeaders">Created At: </label>

              <input
                className="modalInput"
                type="datetime-local"
                value={newTestCreate?.createdAt}
                style={{ width: "100%", marginBottom: "10px" }}
                onChange={(e) => setTestCreate({ ...newTestCreate, createdAt: e.target.value })}
                max={moment().format("YYYY-MM-DDTHH:mm")}
              />
            </div>
            {!CONFIG.isWhiteLabel && (
              <>
                <div className="form-group-wrapper">
                  <label className="modalLineHeaders">Vaccinated: </label>
                  <div className="d-flex">
                    <span className="radioBox-wrapper">
                      <input
                        type="radio"
                        className="radioButton"
                        name="isVaccinated"
                        checked={newTestCreate?.employee_demographics?.isVaccinated}
                        disabled={disableVaccination}
                        onChange={(e) =>
                          setTestCreate({
                            ...newTestCreate,
                            employee_demographics: {
                              ...newTestCreate.employee_demographics,
                              isVaccinated: e.target.checked && true,
                            },
                          })
                        }
                      />
                      <span className="radioButton">Yes</span>
                    </span>
                    <span className="radioBox-wrapper">
                      <input
                        type="radio"
                        className="radioButton"
                        name="isVaccinated"
                        disabled={disableVaccination}
                        checked={!newTestCreate?.employee_demographics?.isVaccinated}
                        onChange={(e) =>
                          setTestCreate({
                            ...newTestCreate,
                            employee_demographics: {
                              ...newTestCreate.employee_demographics,
                              isVaccinated: e.target.checked && false,
                            },
                          })
                        }
                      />
                      <span className="radioButton">No</span>
                    </span>
                  </div>
                </div>
                <div className="form-group-wrapper">
                  <label className="modalLineHeaders">Employee Type: </label>
                  <div className="d-flex">
                    <span className="radioBox-wrapper">
                      <input
                        type="radio"
                        className="radioButton"
                        name="employeeType"
                        checked={newTestCreate?.employee_demographics?.employeeType == "Staff"}
                        onChange={(e) =>
                          setTestCreate({
                            ...newTestCreate,
                            employee_demographics: {
                              ...newTestCreate.employee_demographics,
                              employeeType: e.target.checked && "Staff",
                            },
                          })
                        }
                      />
                      <span className="radioButton">Staff</span>
                    </span>
                    <span className="radioBox-wrapper">
                      <input
                        type="radio"
                        className="radioButton"
                        name="employeeType"
                        checked={newTestCreate?.employee_demographics?.employeeType == "Guest"}
                        onChange={(e) =>
                          setTestCreate({
                            ...newTestCreate,
                            employee_demographics: {
                              ...newTestCreate.employee_demographics,
                              employeeType: e.target.checked && "Guest",
                            },
                          })
                        }
                      />
                      <span className="radioButton">Guest</span>
                    </span>
                  </div>
                </div>
                <div className="form-group-wrapper">
                  <label className="modalLineHeaders">White Glove: </label>
                  <div className="d-flex">
                    <span className="radioBox-wrapper">
                      <input
                        type="radio"
                        className="radioButton"
                        name="whiteGlove"
                        checked={newTestCreate?.employee_demographics?.whiteGlove}
                        onChange={(e) =>
                          setTestCreate({
                            ...newTestCreate,
                            employee_demographics: {
                              ...newTestCreate.employee_demographics,
                              whiteGlove: e.target.checked && true,
                            },
                          })
                        }
                      />
                      <span className="radioButton">Yes</span>
                    </span>
                    <span className="radioBox-wrapper">
                      <input
                        type="radio"
                        className="radioButton"
                        name="whiteGlove"
                        checked={!newTestCreate?.employee_demographics?.whiteGlove}
                        onChange={(e) =>
                          setTestCreate({
                            ...newTestCreate,
                            employee_demographics: {
                              ...newTestCreate.employee_demographics,
                              whiteGlove: e.target.checked && false,
                            },
                          })
                        }
                      />
                      <span className="radioButton">No</span>
                    </span>
                  </div>
                </div>
              </>
            )}

            <div className="form-group-wrapper">
              <label className="modalLineHeaders">Result: </label>
              {CONFIG.isWhiteLabel ? (
                <Select
                  className="w-100 siteSelector"
                  options={resultTypes}
                  blurInputOnSelect={true}
                  defaultValue={null}
                  menuPlacement="auto"
                  value={newTestCreate.result ? { value: newTestCreate.result, label: newTestCreate.result } : null}
                  placeholder="Select Result"
                  onChange={(e) =>
                    setTestCreate({
                      ...newTestCreate,
                      result: e.value,
                      status: "Processed",
                    })
                  }
                />
              ) : (
                <div className="d-flex justify-content-between w-100">
                  <span className="radioBox-wrapper">
                    <input
                      type="radio"
                      className="radioButton"
                      name="result"
                      value="Positive"
                      checked={newTestCreate?.result == "Positive"}
                      onChange={(e) =>
                        setTestCreate({
                          ...newTestCreate,
                          result: e.target.value,
                          status: "Pending",
                        })
                      }
                    />
                    <span className="radioButton">Positive</span>
                  </span>
                  <span className="radioBox-wrapper">
                    <input
                      type="radio"
                      className="radioButton"
                      name="result"
                      value="Negative"
                      checked={newTestCreate?.result == "Negative"}
                      onChange={(e) =>
                        setTestCreate({
                          ...newTestCreate,
                          result: e.target.value,
                          status: "Processed",
                        })
                      }
                    />
                    <span className="radioButton">Negative</span>
                  </span>
                  <span className="radioBox-wrapper">
                    <input
                      type="radio"
                      className="radioButton"
                      name="result"
                      value={UN_RESULT}
                      checked={newTestCreate?.result == UN_RESULT}
                      onChange={(e) =>
                        setTestCreate({
                          ...newTestCreate,
                          result: e.target.value,
                          status: "Processed",
                        })
                      }
                    />
                    <span className="radioButton">{UN_RESULT}</span>
                  </span>
                  <span className="radioBox-wrapper" style={{ display: "flex" }}>
                    <input
                      type="radio"
                      className="radioButton"
                      name="result"
                      value="Inconclusive"
                      checked={newTestCreate?.result == "Inconclusive"}
                      onChange={(e) =>
                        setTestCreate({
                          ...newTestCreate,
                          result: e.target.value,
                          status: "Processed",
                        })
                      }
                    />
                    <span className="radioButton">Inconclusive</span>
                  </span>
                </div>
              )}
            </div>

            {isNew === false && (
              <>
                <div className="form-group-wrapper">
                  <label className="modalLineHeaders">Tester </label>
                  <Select
                    className="w-100 siteSelector"
                    options={usersData}
                    blurInputOnSelect={true}
                    defaultValue={null}
                    menuPlacement="auto"
                    //value={getSelectedTester()}
                    placeholder="Select Tester"
                    value={
                      onSelectedTester.value
                        ? {
                            label: onSelectedTester.label,
                            value: onSelectedTester.value,
                          }
                        : null
                    }
                    onChange={(e) => getSelectedTester(e)}

                    // onChange={(e) =>
                    //   setTestCreate({
                    //     ...newTestCreate,
                    //     createdBy: e.value,
                    //     tester_name: e.label,
                    //     testerPhone: e.phone_number,
                    //   })
                    // }
                  />
                </div>
              </>
            )}

            <div className="form-group-wrapper">
              <label className="modalLineHeaders">Clinical Comments: </label>
              <input
                className="modalInput"
                placeholder="Clinical Comments"
                value={newTestCreate?.employee_demographics?.clinicalcomment}
                onChange={(e) => {
                  let val = e.target.value;
                  setTestCreate({
                    ...newTestCreate,
                    employee_demographics: {
                      ...newTestCreate.employee_demographics,
                      clinicalcomment: val,
                    },
                  });
                }}
              />
            </div>

            <div className="form-group-wrapper">
              <label className="modalLineHeaders">Payer Information: </label>
              <Select
                className="w-100 siteSelector"
                options={INSURANCE_PROVIDER}
                blurInputOnSelect={true}
                defaultValue={null}
                menuPlacement="auto"
                value={
                  newTestCreate.employee_demographics?.insurance_name
                    ? {
                        label: newTestCreate.employee_demographics?.insurance_name,
                        value: newTestCreate.employee_demographics?.insurance_number,
                      }
                    : ""
                }
                placeholder="Select Payer"
                onChange={(e) =>
                  setTestCreate({
                    ...newTestCreate,
                    employee_demographics: {
                      ...newTestCreate.employee_demographics,
                      insurance_name: e.label,
                      insuranceCompanyCode: e.value,
                    },
                  })
                }
              />
            </div>

            <div className="form-group-wrapper">
              <label className="modalLineHeaders">Insurance Number: </label>
              <input
                className="modalInput"
                placeholder="Insurance Number"
                value={newTestCreate?.employee_demographics?.insurance_number}
                onChange={(e) => {
                  let val = e.target.value;
                  setTestCreate({
                    ...newTestCreate,
                    employee_demographics: {
                      ...newTestCreate.employee_demographics,
                      insurance_number: convertToUpper(val),
                    },
                  });
                }}
              />
            </div>

            {showPopOver && recordData.length > 1 && (
              <EmployeeSearchRecord
                data={recordData}
                handleSelect={populate}
                handleClose={() => {
                  setShowPopOver(false), setSelectSearchData(null);
                }}
                setSelectSearchData={setSelectSearchData}
                selectSearchdata={selectSearchdata}
              />
            )}
          </div>
        </Modal.Body>
        <div className="px-4" style={{ height: "3vh" }}>
          {error && <ErrorMessage error={error} handleChange={() => setError("")} />}
        </div>
        <Modal.Footer>
          <Button
            style={{ marginBottom: 10 }}
            variant="secondary"
            className="modalButtons"
            onClick={() => {
              handleCloseCreateTest();
            }}
          >
            Close
          </Button>
          <Button
            style={{ marginBottom: 10 }}
            variant="primary"
            disabled={loading}
            className="modalButtons"
            onClick={onHandleSave}
          >
            {isNew === false ? "Update" : "Create"}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default TestModal;
