import React from "react";

const TestInputField = ({ label, value, onChange, type = "text", name = "", required = false }) => (
  <div>
    <p className="modalLineHeaders">
      {label}

      {required && <span style={{ color: "red" }}>*</span>}
    </p>
    <input
      className="modalInput"
      value={value}
      placeholder={label}
      style={{ width: "100%" }}
      type={type}
      name={name}
      onChange={onChange}
    />
  </div>
);

export default TestInputField;
