export const TESTING_SCHEDULE = "Testing Schedule";
export const QUESTION_SCHEDULE = "Question Schedule";
export const HEALTH_SAFETY_SCHEDULE = "Health & Safety Questions";
export const EMPLOYEE_DEPARTMENTS = "Employee Departments";
export const LOCATIONS = "Locations";
export const REGIONS = "Regions";
export const PROGRAMS = "Programs";
export const MESSAGE_SETTINGS = "Message Settings";
export const TIMER_TEST = "Timer Test";
export const DASHBOARD = "Dash Board";
export const REPORTS = "Reports";
export const CLAIMS = "Claims";
export const PROVIDER = "Provider Management";
export const GENERAL_SETTINGS = "General Settings";
export const ALERT_SETTINGS = "Alert Settings";
export const SYSTEM_USERS = "System Users";
// export const TEST_SETTING = "Tests/Assay Management";
export const TEST_SETTING = "Panel Management";
export const TEST_SETTING_PANEL = "Panel Management";
export const PROCEDURE_CODE = "Procedure Codes";
export const TEST_DICTIONARY = "Test Dictionary";

export const DIAGNOSIS_CODE = "Diagnosis Codes";
export const INSURANCES = "Insurances";
export const LAB_CLIENT = "Client Management";
export const INVOICES = "Invoices";
export const SALE_PERSONS = "Sale Persons";
export const MESSAGE_TEMPLATE = "Message Template";
export const REFERENCE = "Reference Lab";
export const TAB_SETTING = "Settings";

export const PAYER_MANAGEMENT = "Payer Management";
