import React, { useContext, useEffect, useState } from "react";
import API from "api";
// react-bootstrap components
import {
    Badge,
    Button,
    Card,
    Navbar,
    Nav,
    Table,
    Container,
    Row,
    Col,
    Form,
    OverlayTrigger,
    Tooltip,
} from "react-bootstrap";
import moment from "moment";
import DashboardLineChart from "./DashboardLineChart";
import DashboardPieChart from "./DashboardPieChart";
import DashboardBarChart from "./DashboardBarChart";
import DashboardDougnutChart from "./DashboardDougnutChart";
import DashboardChartTotalTestsDataCard from "./DashboardChartTotalTestsData";
import DashboardChartsTableData from "./DashboardChartsTableData";
import { Link } from "react-router-dom";
import { connectedIDInLocalStorage, formateDateLL } from "../../utils";
import { AppContext } from "context/app-context";

const ClientsDashboard = (props) => {
    const { sites } = props;

    const [summaryData, setSummaryData] = useState({ positiveTests: 0, negativeTests: 0, totalTests: 0, inconclusiveTests: 0, positiveTest: null });
    const [totalTestCounts, setTotalCounts] = useState('');
    const connectedIDFromRoute = window.location?.pathname?.split("/").pop();
    const [routingPathBasedMedFlow, setRoutingPathBasedMedFlow] = useState({
        userType: "E",
        siteID:
            connectedIDFromRoute.toLowerCase() !== "admin" ? connectedIDFromRoute : connectedIDInLocalStorage.get() || "A",
    });

    useEffect(() => {
        totalTestsCount();
    }, []);

    const totalTestsCount = async () => {
        if (sites && sites.length > 0) {
            let postiveTestsCount = 0;
            let negativeTestsCount = 0;
            let pendingTestsCount = 0;
            let inconclusiveTestsCount = 0;
            let totalTestCounts = 0;
            sites.map((siteTests) => {
                postiveTestsCount = parseInt(postiveTestsCount) + positiveTests(siteTests.tests).length;
                negativeTestsCount = parseInt(negativeTestsCount) + negativeTests(siteTests.tests);
                pendingTestsCount = parseInt(pendingTestsCount) + pendingTests(siteTests.tests);
                inconclusiveTestsCount = parseInt(inconclusiveTestsCount) + inconclusiveTests(siteTests.tests);
                totalTestCounts = parseInt(totalTestCounts) + totalTestOfShow(siteTests.tests);

            });

            let newObj = {
                positiveTests: parseInt(postiveTestsCount),
                negativeTests: parseInt(negativeTestsCount),
                pendingTests: parseInt(pendingTestsCount),
                inconclusiveTests: parseInt(inconclusiveTestsCount),
                totalTests: parseInt(totalTestCounts),
            }
            setTotalCounts(newObj);
        };





    }


    const positiveTests = (tests) => {
        const positiveTests = tests?.filter((test) => {
            return test.result?.toLowerCase() === "positive" && test.status.toLowerCase() === "processed";
            // return test.result?.toLowerCase() === "positive" 
        });
        return positiveTests;
    };

    const negativeTests = (tests) => {
        const negativeTests = tests.filter((test) => {
            return test.result?.toLowerCase() === "negative";
        });
        return negativeTests.length;
    };


    const pendingTests = (tests) => {
        const pendingTests = tests?.filter((test) => {
            return test.result?.toLowerCase() === "" || test.result?.toLowerCase() === "Pending";
        });
        return pendingTests.length;
    };

    const inconclusiveTests = (tests) => {
        const inconclusiveTests = tests.filter((test) => {
            return test.result?.toLowerCase() === "inconclusive";
        });
        return inconclusiveTests.length;
    };


    const lastPositiveTest = (tests) => {
        if (tests.length > 0) {
            const max = positiveTests(tests).reduce((prev, current) => {
                return new Date(prev.batch) > new Date(current.batch) ? prev : current;
            });
            return max;
        }
    };

    const showStartDate = (startDate) => {
        if (startDate) {
            return new Date(startDate).toDateString();
        }
        return "N/A";
    };

    const getShowRunningDays = (startDate) => {
        if (startDate) {
            return Math.ceil((new Date() - new Date(startDate).getTime()) / (1000 * 3600 * 24));
        }
        return "N/A";
    };

    const lastPositiveTestDate = (testDate) => {
        if (!testDate) return "N/A";
        const date = new Date(testDate);
        const currentDate = new Date();
        const dayLastPositive = Math.floor((currentDate.getTime() - date.getTime()) / (1000 * 3600 * 24));
        return `${date.toDateString()} (${dayLastPositive} days ago)`;
    };

    const totalTestOfShow = (tests) => {
        const ids = tests.filter(
            (test) => test.result?.toLowerCase() === "positive" && test.status.toLowerCase() === "pending"
        ).map((t) => t.id);
        return tests.filter((t) => !ids.includes(t.id)).length;
    };


    return (
        <div className="dashboard-wrapper client-dashboard-inner">
            <div className="container-fluid mb-3">
                <h4 className="mt-0">Client Summary</h4>
            </div>
            <div className="container-fluid">
                <div className="row">
                    <div className="col-12 col-lg-4 col-sm-12">
                        <div className="bg-white rounded-3">
                            <div className="box-height">
                                <div className="box-inner-height">
                                    <DashboardLineChart statsType={'clientstats'} statsTitle="Test Type" connectedID={routingPathBasedMedFlow.siteID} />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-12 col-lg-4 col-sm-12">
                        <div className="bg-white rounded-3">
                            <div className="box-height">
                                <div className="box-inner-height">
                                    <DashboardLineChart statsType={'clientsitestats'} statsTitle="Sites" connectedID={routingPathBasedMedFlow.siteID} />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-12 col-lg-4 col-sm-12">
                        <div className="bg-white rounded-3">
                            <div className="box-height">
                                <div className="box-inner-height">
                                    <DashboardChartTotalTestsDataCard totalTestCounts={totalTestCounts} clientDashboard="clientDashboard" />

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <table className="table mt-4 table-fixed">
                    <thead>
                        <tr>
                            <th className="text-start">Name</th>
                            <th className="text-center">Total Tests</th>
                            <th className="text-center">Positive</th>
                            <th className="text-center">Negative</th>
                            <th className="text-center">Inconclusive</th>
                            <th className="text-center">Show Running Days</th>
                            <th className="text-center">Show Start Day</th>
                            <th className="text-start">last Positive Test</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            sites && sites.map((site, index) => {
                                return (
                                    <tr>
                                        <td className="elepsis">{site?.name}</td>
                                        <td className="text-center">
                                            {site?.tests?.length !== 0 ? (
                                                <Link
                                                    style={{ color: "#464646" }}
                                                    to={{
                                                        pathname: "/admin/totaltests",
                                                        state: {  showId: site.id },
                                                      }}
                                                >
                                                    {totalTestOfShow(site.tests)}
                                                </Link>
                                            ) : (
                                                site.tests.length
                                            )}
                                        </td>
                                        <td className="text-center">


                                            {positiveTests(site.tests).length !== 0 ? (
                                                <Link
                                                    style={{ color: "#B62935" }}
                                                    to={{
                                                        pathname: "/admin/positivetests",
                                                        state: { showId: site.id },
                                                    }}
                                                >
                                                    {positiveTests(site.tests).length}
                                                </Link>
                                            ) : (
                                                positiveTests(site.tests).length
                                            )}

                                        </td>
                                        <td className="text-center">


                                            {negativeTests(site.tests) !== 0 ? (
                                                <Link
                                                    style={{ color: "#3BB339" }}
                                                    to={{
                                                        pathname: "/admin/totaltests",
                                                        state: { name: "negative", term: "result", showId: site.id , negativeTests: true},
                                                    }}
                                                >
                                                    {negativeTests(site.tests)}
                                                </Link>
                                            ) : (
                                                negativeTests(site.tests)
                                            )}

                                        </td>
                                        <td className="text-center">


                                            {inconclusiveTests(site.tests) !== 0 ? (
                                                <Link
                                                    style={{ color: "#42cef5" }}
                                                    to={{
                                                        pathname: "/admin/totaltests",
                                                        state: { negativeTests: true },
                                                    }}
                                                >
                                                    {inconclusiveTests(site.tests)}
                                                </Link>
                                            ) : (
                                                inconclusiveTests(site.tests)
                                            )}

                                        </td>
                                        <td className="text-center">
                                            {site.startDate !== 0 ? (
                                                getShowRunningDays(site.startDate)
                                            ) : (
                                                "N/A"
                                            )}
                                        </td>
                                        <td className="text-center">
                                            {site.startDate !== 0 ? (
                                                showStartDate(site.startDate)
                                            ) : (
                                                "N/A"
                                            )}
                                        </td>
                                        <td className="text-start">
                                            {positiveTests(site.tests).length === 0 ? (
                                                "No Positive Tests"
                                            ) : (
                                                <Link
                                                    style={{ color: "#A82632" }}
                                                    to={{
                                                        pathname: "/admin/positivetests",
                                                        state: { name: lastPositiveTest(positiveTests(site.tests)).barcode, term: "barcode" },
                                                    }}
                                                >
                                                    {lastPositiveTestDate(lastPositiveTest(positiveTests(site.tests)).batch)}
                                                </Link>
                                            )}
                                        </td>
                                    </tr>
                                );
                            })
                        }
                    </tbody>
                </table>
            </div>
        </div>
    );
}

export default ClientsDashboard;
