import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import api from "../../../api";
import { ELIGIBILITY_FILE, ELIGIBILITY_FILE_FETCH } from "./eligibilityFileConstant";

// Async thunk to fetch all eligibilityFiles
export const fetchAllEligibilityFiles = createAsyncThunk(ELIGIBILITY_FILE_FETCH, async () => {
  const response = await api.getEligibilityFiles();
  return response;
});

// Async thunk to create a eligibilityFile
export const careateEligibilityFileAsync = createAsyncThunk(
  "eligibilityFile/careateEligibilityFile",
  async (eligibilityFile) => {
    const response = await api.careateEligibilityFile(eligibilityFile);
    return response;
  }
);

// Async thunk to update a eligibilityFile
export const updateEligibilityFileAsync = createAsyncThunk(
  "eligibilityFile/updateEligibilityFile",
  async ({ eligibilityFileId, updateEligibilityFile }) => {
    const response = await api.updateEligibilityFile(eligibilityFileId, updateEligibilityFile);
    return response;
  }
);

// Async thunk to delete a eligibilityFile
export const deleteEligibilityFileAsync = createAsyncThunk(
  "eligibilityFile/deleteEligibilityFile",
  async (eligibilityFileId) => {
    const response = await api.deleteEligibilityFile(eligibilityFileId);
    return response;
  }
);

const eligibilityFileSlice = createSlice({
  name: ELIGIBILITY_FILE,
  initialState: {
    eligibilityFiles: [],
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchAllEligibilityFiles.fulfilled, (state, action) => {
        state.eligibilityFiles = action.payload;
      })
      .addCase(careateEligibilityFileAsync.fulfilled, (state, action) => {
        state.eligibilityFiles.push(action.payload);
      })
      .addCase(updateEligibilityFileAsync.fulfilled, (state, action) => {
        const updateEligibilityFile = action.payload;
        const existingeligibilityFileIndex = state.eligibilityFiles.findIndex(
          (eligibilityFile) => eligibilityFile.id === updateEligibilityFile.id
        );
        if (existingeligibilityFileIndex !== -1) {
          state.eligibilityFiles[existingeligibilityFileIndex] = updateEligibilityFile;
        }
      })
      .addCase(deleteEligibilityFileAsync.fulfilled, (state, action) => {
        const deletedeligibilityFileId = action.payload;
        state.eligibilityFiles = state.eligibilityFiles.filter(
          (eligibilityFile) => eligibilityFile.id !== deletedeligibilityFileId
        );
      });
  },
});

export default eligibilityFileSlice.reducer;
