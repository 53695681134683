import React, { useState, useContext, useMemo, useRef } from "react";

// react-bootstrap components
import { Badge, Button, Card, Navbar, Nav, Table, Container, Row, Col, Modal, Form } from "react-bootstrap";
import { useEffect } from "react";
import GQAPI from "views/GraphQL/gqApi";
import { Link } from "react-router-dom";
import parsePhoneNumber, { AsYouType } from "libphonenumber-js";
import { PDFViewer } from "@react-pdf/renderer";
import PDF from "components/PDF";
import Filter from "components/Filter";
import TimeFilter from "components/TimeFilter";
import FilterSelect from "components/FilterSelect";
import { AppContext } from "context/app-context";
import {
  changeSort,
  getCrewsName,
  formatTest,
  setPhoneNo,
  formatPhoneNumber,
  sortTestList,
  sortingFilterInLC,
  formatUTCDate,
} from "utils";
import ContactInfo from "components/Modal/ContactInfo";
import { PAGE_LIMIT, TEST_HEADER_TYPE, RESULT_COLOR } from "constant";
import MFPagination from "components/Pagination/MFPagination";
import Header from "components/Table/Header";
import PDFLayout from "components/PDF/PDFLayout";
import SweetAlert from "react-bootstrap-sweetalert";
import { Bounce } from "react-activity";
import ExportToExcel from "components/ExportToExcel";
import TestPDFLayout from "components/PDF/TestPDFLayout";
import ConfirmationModal from "components/Modal/ConfirmationModal";
import moment from "moment";
import SequenceNo from "components/SequenceNo";
import Loader from "../../components/Loader/Loader";
import Icon from "components/Icon";
import ViewOnlyCheckBox from "components/CheckBox/ViewOnlyCheckBox";
import { stringConstant, CONFIG } from "constant";

const PendingReleaseTestListView = () => {
  const mainRef = useRef();
  const [width, setWidth] = useState(0);
  useEffect(() => {
    setWidth(mainRef.current.offsetWidth);
  }, [mainRef]);

  const handleResize = () => {
    setWidth(mainRef.current?.offsetWidth);
  };

  useEffect(() => {
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  });
  const importResult = [];
  const [tests, setTests] = useState([]);
  const [filteredTests, setFilteredTests] = useState([]);
  const [labs, setLabs] = useState([]);
  const [sites, setSites] = useState([]);
  const [moreInfo, setMoreInfo] = useState({});
  const [modalShow, setModalShow] = useState(false);
  const [sortBy, setSortBy] = useState("");
  const [sortDescending, setSortDescending] = useState(true);
  const [testsLab, setTestsLab] = useState();
  const [testPdf, setTestPdf] = useState();
  const [showPdf, setShowPdf] = useState(false);
  const [timeFilter, setTimeFilter] = useState({});
  const [showFilter, setShowFilter] = useState(false);
  const [filter, setFilter] = useState({});
  const [checkboxes, setCheckboxes] = useState([]);
  const [allSelected, setAllSelected] = useState(false);
  const [releaseModalShow, setReleaseModalShow] = useState(false);
  const [siteContactPhones, setSiteContactPhones] = useState([]);
  const [crewPhoneNumbers, setCrewPhoneNumbers] = useState([]);
  const [testsToRelease, setTestsToRelease] = useState([]);
  const [releaseTo, setReleaseTo] = useState({});
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [loading, setLoading] = useState(false);
  const [showAlert, setShowAlert] = useState("");
  const [openConfirmation, setOpenConfirmation] = useState(false);
  const appContext = useContext(AppContext);
  const { filterDates } = TimeFilter;

  const searchTerms = [
    "First Name",
    "Last Name",
    "Date of Birth",
    "Email",
    "Phone",
    "Client",
    `${stringConstant.show}`,
    "Lab",
    `${stringConstant.sequenceNo}`,
    "Test Type",
    "Result",
    "Tester",
    "isVaccinated",
    "White Glove",
    "Pattern",
    "Capacity",
  ];

  const selectedItem = [
    "createdAt",
    "firstName",
    "lastName",
    "dob",
    "email",
    "phoneNumber",
    "clientID",
    "siteID",
    "labID",
    "result",
    "test_type",
    `${stringConstant.accession_no}`,
    "tester_name",
    "isVaccinated",
    "employeeType",
    "whiteGlove",
    "patternTestAnswer",
  ];

  const createMoreInfo = (type, id) => {
    let infoObj = null;
    if (type === "site") infoObj = appContext.sites.find((site) => site.id === id);
    else if (type === "client") infoObj = appContext.clients.find((client) => client.id === id);
    else infoObj = labs.find((lab) => lab.id === id);
    if (infoObj) {
      setMoreInfo({
        type,
        name: infoObj.name,
        contact: infoObj.contact_name,
        phone: infoObj.contact_phone,
        email: infoObj.contact_email,
      });
      setModalShow(true);
    }
  };

  const filterTests = (term, input) => {
    switch (term) {
      case "name":
        setFilteredTests(
          tests.filter((test) => getCrewName(test["employee_demographics"]).toLowerCase().includes(input.toLowerCase()))
        );
        break;
      case "lab":
        setFilteredTests(
          tests.filter((test) => appContext.getLabName(test["labID"]).toLowerCase().includes(input.toLowerCase()))
        );
        break;
      case "show":
        setFilteredTests(
          tests.filter((test) => appContext.getSiteName(test["siteID"]).toLowerCase().includes(input.toLowerCase()))
        );
        break;
      case "crew_ID":
        setFilteredTests(tests.filter((test) => test["owner"].toLowerCase().includes(input.toLowerCase())));
        break;
      default:
        setFilteredTests(tests.filter((test) => test[term].toLowerCase().includes(input.toLowerCase())));
    }
  };

  const testsToMap = useMemo(() => {
    const list = filteredTests ? [...filteredTests] : [];
    const ttlPages = list.length > PAGE_LIMIT ? Math.ceil(list.length / PAGE_LIMIT) : 1;
    setTotalPages(ttlPages);
    if (list.length < PAGE_LIMIT || currentPage > ttlPages) {
      setCurrentPage(1);
    }
    if (ttlPages > 1) {
      const indexOfLastUser = currentPage * PAGE_LIMIT;
      const indexOfFirstUser = indexOfLastUser - PAGE_LIMIT;
      return list.slice(indexOfFirstUser, indexOfLastUser);
    }
    return list;
  }, [filteredTests, currentPage]);

  const nestedFilter = (targetArray, filters) => {
    if (Object.keys(filter).length === 0) return targetArray;
    const filterKeys = Object.keys(filters);
    //filters main array of objects
    const models = targetArray.filter((obj) => {
      //goes through each key being filtered for
      return filterKeys.every((key) => {
        if (!filters[key].length) {
          return true;
        }

        if (key === "phoneNumber") {
          return obj[key] && obj[key].replace(/\D/g, "").includes(filters[key].replace(/\D/g, ""));
        }

        if (key === "Lab") {
          return obj.labID && appContext.getLabName(obj.labID).toLowerCase().includes(filters[key]);
        }

        if (key === "Tester") {
          return obj.tester_name && obj.tester_name.toLowerCase().includes(filters[key].toLowerCase());
        }
        if (key === "Client") {
          return (
            obj.clientID && appContext.getClientName(obj.clientID).toLowerCase().includes(filters[key].toLowerCase())
          );
        }
        if (key === "Show") {
          const siteIds = appContext.sites
            .filter((s) => s.name.toLowerCase().includes(filters[key].toLowerCase()))
            .map((s) => s.id);
          return siteIds.includes(obj.siteID);
        }
        if (key === "result") {
          return obj[key] && obj["viewedResult"].toLowerCase().includes(filters[key]);
        }
        if (key === "sequenceNo") {
          return obj[key] && obj[key].toString().includes(filters[key]);
        }
        if (key === "dob") {
          return obj[key] && obj[key] === filters[key];
        }

        if (key === "test_type" && filters[key] && filters[key].length > 0) {
          return obj[key] && filters[key].indexOf(obj[key]) !== -1;
        }

        if (key === "vaccinated") {
          return (obj["isVaccinated"] ? 1 : 0).toString() === filters[key];
        }

        if (key === "whiteGlove") {
          return (obj["whiteGlove"] ? 1 : 0).toString() === filters[key];
        }

        if (key === "capacity") {
          return obj["employeeType"] && obj["employeeType"].toLowerCase().includes(filters[key].toLowerCase());
        }

        if (key === "pattern") {
          if (filters[key] === "1") return obj["patternTestAnswer"] && obj["patternTestAnswer"] !== "undefined";

          if (filters[key] === "0") return obj["patternTestAnswer"] == "undefined" || obj["patternTestAnswer"] == "";
        }

        return obj[key] && obj[key].toLowerCase().includes(filters[key]);
      });
    });
    return models;
  };

  const displayPdf = async (test) => {
    handleSaveFilter();
    const empID = await appContext.getEmployeeID(test.employeeID);
    const t = test;
    t.empID = empID;
    appContext.getLab(test).then(async (resp) => {
      setTestsLab(resp);
      setShowPdf(true);
    });
    setTestPdf(formatTest(test));
  };

  const flipSort = (by) => {
    setSortDescending(sortBy === by ? !sortDescending : true);
    setSortBy(by);
  };

  const displayOwner = (test) => {
    let displayedId = test.owner.substr(-12);
    return displayedId;
  };

  const handleCheckboxChange = (e, id) => {
    const filteredList = checkboxes.filter((c) => c !== id);
    if (e.target.checked) {
      filteredList.push(id);
    }
    setCheckboxes(filteredList);
  };

  const toggleCheckboxes = (val) => setCheckboxes(val ? filteredTests.map((t) => t.id) : []);

  const retestCrew = async () => {
    setLoading(true);
    const params = testsToRelease
      .filter((f) => f.result.toLowerCase() === "inconclusive" || f.result.toLowerCase() === "invalid")
      .map((t) => {
        return {
          id: t.id,
          phoneNumber: t.phoneNumber,
          email: t.email,
          test_type: t.test_type,
          site_name: t.site_name,
          firstName: t.firstName,
          siteID: t.siteID,
          lastName: t.lastName,
          userName: appContext.user.name,
          type: "invalid",
        };
      });
    if (params.length > 0) {
      try {
        await GQAPI.sendSmsToCrews(params);
        appContext.showSuccessMessage(
          `Successfully message send to ${testsToRelease.length} ${testsToRelease.length > 1 ? "crews" : "crew"}`
        );
        setCheckboxes([]);
        setLoading(false);
      } catch (err) {
        console.log(err.message);
        appContext.showErrorMessage(err.message);
        setLoading(false);
      }
    }
    setLoading(false);
  };
  const releaseTests = async () => {
    setReleaseModalShow(false);
    setLoading(true);

    try {
      if (!testsToRelease.length) {
        return;
      }
      const ids = testsToRelease.map((t) => t.id);
      const params = testsToRelease.map((t) => {
        return {
          id: t.id,
          status: "Processed",
          timerStatus: "Processed",
          sequenceNo: t.sequenceNo,
          invalidResultStatus: 1,
        };
      });
      await GQAPI.releaseTest(params);
      if (releaseTo.crew) {
        await GQAPI.sendEmailToPendingRelease(params);
      }
      //
      const pendingTestList = testsToRelease.filter((f) => f.result.toLowerCase() === "positive");
      const inconclusiveTestList = testsToRelease.filter((f) => f.result.toLowerCase() === "inconclusive");
      if (pendingTestList.length > 0) addLogs(pendingTestList, "Pending Release");
      if (inconclusiveTestList.length > 0) addLogs(inconclusiveTestList, "Inconclusive Release");
      if (releaseTo.site) {
        //
        for (const phoneSite of releaseTo.site) {
          const testTypes = testsToRelease
            .filter((s) => s.siteID === phoneSite.id)
            .map((item) => item.test_type)
            .filter((value, index, self) => self.indexOf(value) === index);

          const testTypeText =
            testTypes.length === 2 ? testTypes.join("&") : testTypes.join(", ").replace(/, ([^,]*)$/, " & $1");

          await GQAPI.sendSMSNotification(
            setPhoneNo(phoneSite.contact_phone || phoneSite.phone_number),
            `Hello, there was a positive ${testTypeText} test result for ${phoneSite.name}. please login to your Health & Safety Portal to review.`
          );
        }
      }

      setLoading(false);
      setShowAlert(`Success! You have released ${testsToRelease.length} tests`);
      appContext.resetPendingTests(ids);
    } catch (error) {
      setLoading(false);
      console.lo("Error:- Pending Release", error.message);
      setShowAlert("Fail! Fail to release the Test");
    }
    setCheckboxes([]);
  };

  const hideAlert = () => {
    setShowAlert("");
  };

  useEffect(() => {
    const savedFilters = appContext.testFilter;
    if (savedFilters && savedFilters.location === "pendingreleasetest") {
      if (Object.keys(savedFilters.filter).length > 0) {
        setFilter(savedFilters.filter);
        setShowFilter(true);
      }
    }
    const sortingFilters = sortingFilterInLC.get();
    if (sortingFilters.pendingreleasetest.sortBy) {
      setSortDescending(sortingFilters.pendingreleasetest.sortDescending);
      setSortBy(sortingFilters.pendingreleasetest.sortBy);
    }
  }, []);

  useEffect(() => {
    setLabs(appContext.labs);
    setSites(appContext.sites);
  }, [appContext.labs, appContext.sites]);

  useEffect(() => {
    setTests(appContext.pendingTests);
    refreshTestList(appContext.pendingTests);
  }, [sites, labs, appContext.pendingTests]);

  useEffect(() => {
    const prevSortingState = sortingFilterInLC.get();
    prevSortingState["pendingreleasetest"] = { filter, timeFilter, sortBy, sortDescending };
    sortingFilterInLC.save(prevSortingState);
    if (filteredTests.length === 0) return;
    changeSort(
      sortBy,
      sortDescending,
      filteredTests,
      setFilteredTests,
      appContext.getSiteName,
      appContext.getLabName,
      appContext.getClientName,
      appContext.parseResult
    );
  }, [sortBy, sortDescending]);

  useEffect(() => {
    refreshTestList(tests);
  }, [filter, timeFilter]);

  useEffect(() => {
    setTestsToRelease(filteredTests.filter((test) => checkboxes.includes(test.id)));
  }, [checkboxes]);

  useEffect(() => {
    if (testsToRelease.length) {
      const testSites = testsToRelease
        .map((item) => item.siteID)
        .filter((value, index, self) => self.indexOf(value) === index);

      setSiteContactPhones(
        appContext.sites.filter((f) => {
          return testSites.includes(f.id) && (f.contact_phone || f.phone_number);
        })
      );
      setCrewPhoneNumbers(testsToRelease.filter((test) => test.phoneNumber));
    } else {
      setSiteContactPhones([]);
      setCrewPhoneNumbers([]);
    }
  }, [testsToRelease]);

  const refreshTestList = (list) => {
    const testTist = filterDates(nestedFilter(list, filter), timeFilter);
    if (sortBy) {
      setFilteredTests(
        sortTestList(
          sortBy,
          sortDescending,
          testTist,
          appContext.getSiteName,
          appContext.getLabName,
          appContext.getClientName,
          appContext.parseResult
        )
      );
    } else {
      setFilteredTests(testTist);
    }
  };

  const handleChangePage = (page) => setCurrentPage(page);
  const handleCloseContactInfo = () => {
    setMoreInfo({});
    setModalShow(false);
  };

  const handleSaveFilter = () => {
    appContext.setTestFilter({ location: "pendingreleasetest", filter, timeFilter });
  };

  const handleFalsePositive = async (isConfirm) => {
    setOpenConfirmation(false);
    if (!isConfirm) return;

    try {
      if (testsToRelease.length) {
        const params = testsToRelease.map((t) => {
          return {
            id: t.id,
            isFalsePositive: true,
            status: "Processed",
            sequenceNo: t.sequenceNo,
            invalidResultStatus: 2,
          };
        });
        await GQAPI.releaseTest(params);
        const seqno = testsToRelease.map((s) => s.id);
        const newList = filteredTests.filter((t) => !seqno.includes(t.id));
        setTests(newList);
        refreshTestList(newList);
        const pendingTestList = testsToRelease.filter((f) => f.result.toLowerCase() === "positive");
        const inconclusiveTestList = testsToRelease.filter((f) => f.result.toLowerCase() === "inconclusive");
        if (pendingTestList.length > 0) addLogs(pendingTestList, "False Positive");
        if (inconclusiveTestList.length > 0) addLogs(inconclusiveTestList, "False Inconclusive");
        appContext.showSuccessMessage(`Sucessfully marked ${testsToRelease.length} tests false postive`);
        setCheckboxes([]);
        appContext.resetPendingTests(seqno);
      }
    } catch (error) {
      appContext.showErrorMessage(error.message);
    }
  };

  const addLogs = (items, slug) => {
    const ids = items.map((s) => {
      return { id: s.id, sequenceNo: s.sequenceNo };
    });
    const params = { ids: ids, userID: appContext.user?.sub, userName: appContext.user?.name, slug: slug };
    GQAPI.addTestLogs(params);
  };

  const TableRow = ({ test }) => {
    return (
      <tr className="trDataWrapper">
        {/* <td>{test.owner && displayOwner(test)}</td> */}
        <td className="ellipsis" style={{ textAlign: "left" }}>
          {/* <Form.Check>
            <Form.Check.Input
              type="checkbox"
              value={test.id}
              checked={checkboxes.indexOf(test.id) !== -1}
              onChange={(e) => handleCheckboxChange(e, test.id)}
            ></Form.Check.Input>
          </Form.Check> */}

          <ViewOnlyCheckBox
            bgColor="red"
            id={test.id}
            checked={checkboxes.indexOf(test.id) !== -1}
            item={test}
            handleChange={(e) => handleCheckboxChange(e, test.id)}
          />
        </td>
        <td className="ellipsis" title={formatUTCDate(test.createdAt)}>
          {formatUTCDate(test.createdAt)}
        </td>

        <td className="table-column ellipsis" title={test.firstName}>
          {test.firstName}
        </td>
        <td className="table-column ellipsis" title={test.lastName}>
          {test.lastName}
        </td>
        <td className="ellipsis" title={test.dob}>
          {test.dob}
        </td>
        <td
          className="table-column ellipsis text-decoration-none text-dark-danger text-dark-danger-hover cursor-pointer"
          onClick={() => test?.email && window.open(`mailto:${test.email}`)}
          title={test.email}
        >
          {test.email}
        </td>
        <td
          className="table-column ellipsis text-decoration-none text-dark-danger text-dark-danger-hover cursor-pointer"
          onClick={() => {
            test.phoneNumber && window.open(`tel:${test.phoneNumber}`);
          }}
          title={formatPhoneNumber(test.phoneNumber)}
        >
          {formatPhoneNumber(test.phoneNumber)}
        </td>
        {!CONFIG.isWhiteLabel && (
          <td className="ellipsis" style={{ textAlign: "center" }}>
            <a
              style={{ color: "#42cef5" }}
              onClick={() => createMoreInfo("client", test.clientID)}
              onMouseOver={(e) => (e.target.style.cursor = "pointer")}
              title={appContext.getClientName(test.clientID)}
            >
              {appContext.getClientName(test.clientID)}
            </a>
          </td>
        )}
        <td className="ellipsis" style={{ textAlign: "center" }}>
          <a
            style={{ color: "#42cef5" }}
            onClick={() => createMoreInfo("site", test.siteID)}
            onMouseOver={(e) => (e.target.style.cursor = "pointer")}
            title={appContext.getSiteName(test.siteID)}
          >
            {appContext.getSiteName(test.siteID)}
          </a>
        </td>
        <td className="ellipsis" style={{ textAlign: "center" }}>
          <a
            style={{ color: "#42cef5" }}
            onClick={() => createMoreInfo("lab", test.labID)}
            onMouseOver={(e) => (e.target.style.cursor = "pointer")}
            title={appContext.getLabName(test.labID)}
          >
            {appContext.getLabName(test.labID)}
          </a>
        </td>

        <td className="ellipsis" style={{ textAlign: "center" }}>
          <span
            className="cursor-pointer"
            onClick={() => displayPdf(test)}
            style={{
              color: RESULT_COLOR[test.result?.toLowerCase()],
            }}
            title={appContext.parseResult(test)}
          >
            {appContext.parseResult(test)}
          </span>
        </td>
        <td className="ellipsis" style={{ textAlign: "center" }} title={test.test_type || "-"}>
          {test.test_type || "-"}
        </td>
        <SequenceNo num={test.sequenceNo} />
        <td className="ellipsis" style={{ textAlign: "center" }} title={test.tester_name}>
          {test.tester_name}
        </td>
        <td className="ellipsis" style={{ textAlign: "center" }}>
          <Link
            className="text-dark-danger text-dark-danger-hover cursor-pointer text-decoration-none"
            to={{
              pathname: "/admin/test/" + test.id,
              state: { pendingRelease: true, type: "pendingRelease", test: test },
            }}
            onClick={handleSaveFilter}
          >
            View
          </Link>
        </td>
      </tr>
    );
  };

  return showPdf ? (
    <PDFLayout setShowPdf={setShowPdf} testPdf={testPdf} testsLab={testsLab} />
  ) : !loading ? (
    <>
      <Container fluid>
        <SweetAlert
          show={showAlert.length > 0}
          type="success"
          title=""
          onConfirm={() => {
            hideAlert();
          }}
        >
          {showAlert}
        </SweetAlert>
        <div className="shows-screen-parent">
          <Row className="mx-0">
            <Col md="12">
              <Card className="border-0 mb-1">
                <Card.Body className="p-0">
                  <div className="shows-filter-wrapper">
                    <div className="shows-filter-inner">
                      <Icon
                        handleClick={() => setShowFilter(!showFilter)}
                        title={"Filter"}
                        label={"Filter"}
                        iconType={"filter"}
                      />
                      <Icon
                        handleClick={() => {
                          if (filteredTests.length > 0) {
                            setAllSelected(!allSelected);
                            toggleCheckboxes(!allSelected);
                          }
                        }}
                        title={!allSelected ? "Select All" : "Deselect All"}
                        label={!allSelected ? "Select All" : "Deselect All"}
                        iconType={"selectAllIcon"}
                      />
                      <Icon
                        handleClick={() => setReleaseModalShow(true)}
                        disabled={testsToRelease.length === 0}
                        title={"Release Tests"}
                        label={"Release Tests"}
                        iconType={"testNowIcon"}
                      />
                      <Icon
                        handleClick={retestCrew}
                        title={"Re-Test"}
                        label={"Re-Test"}
                        iconType={"loadCrewIcon"}
                        disabled={checkboxes.length ? false : true}
                      />
                      {filteredTests.length > 0 && (
                        <>
                          {Object.keys(filter).length > 0 && (
                            <>
                              <ExportToExcel
                                items={filteredTests}
                                selectedItem={selectedItem}
                                appContext={appContext}
                                filter={filter}
                                timeFilter={timeFilter}
                                title="Pending Release Tests"
                                sortBy={sortBy}
                              />
                              <TestPDFLayout
                                tests={filteredTests}
                                selectedItem={selectedItem}
                                filter={filter}
                                timeFilter={timeFilter}
                                title="Pending Release Tests"
                                appContext={appContext}
                                sortBy={sortBy}
                              />
                            </>
                          )}
                          <Icon
                            handleClick={() => setOpenConfirmation(true)}
                            title={"False Positive"}
                            label={"False Positive"}
                            iconType={"checkEligibilityIcon"}
                            disabled={checkboxes.length ? false : true}
                          />
                        </>
                      )}
                    </div>
                  </div>
                  {showFilter && <Filter filterTerms={searchTerms} setFilter={setFilter} filter={filter} />}
                </Card.Body>
              </Card>
            </Col>
          </Row>
          <Row className="mx-0">
            <Col md="12">
              <Card className="strpied-tabled-with-hover bg-transparent border-0">
                <Card.Header className="shows-screen-wrapper">
                  <Row className="pb-4">
                    <Col md-="6">
                      <Card.Title>
                        <div className="">
                          Pending Release:
                          <span className="ps-2"> {filteredTests ? filteredTests.length : 0}</span>
                        </div>
                      </Card.Title>
                    </Col>
                    <Col md="6">
                      <MFPagination
                        currentPage={currentPage}
                        handleChangePage={handleChangePage}
                        totalPages={totalPages}
                        showSelectedRecord
                        totalSelected={checkboxes.length}
                      />
                    </Col>
                  </Row>
                </Card.Header>
                {/* <Card.Header>
                  <div className="buttonHeader">
                    <Button className="headerButton" onClick={() => setShowFilter(!showFilter)}>
                      Filter
                    </Button>
                    <Button
                      className="headerButton"
                      onClick={() => {
                        if (filteredTests.length > 0) {
                          setAllSelected(!allSelected);
                          toggleCheckboxes(!allSelected);
                        }
                      }}
                    >
                      {!allSelected ? "Select All" : "Deselect All"}
                    </Button>
                    <Button
                      className="headerButton"
                      onClick={() => setReleaseModalShow(true)}
                      disabled={testsToRelease.length === 0}
                    >
                      Release Selected Tests
                    </Button>
                    <Button
                      className="headerButton"
                      disabled={checkboxes.length ? false : true}
                      onClick={() => retestCrew()}
                    >
                      Re-Test
                    </Button>
                    {filteredTests.length > 0 && (
                      <>
                        {Object.keys(filter).length > 0 && (
                          <>
                            <ExportToExcel
                              items={filteredTests}
                              selectedItem={selectedItem}
                              appContext={appContext}
                              filter={filter}
                              timeFilter={timeFilter}
                              title="Pending Release Tests"
                              sortBy={sortBy}
                            />
                            <TestPDFLayout
                              tests={filteredTests}
                              selectedItem={selectedItem}
                              filter={filter}
                              timeFilter={timeFilter}
                              title="Pending Release Tests"
                              appContext={appContext}
                              sortBy={sortBy}
                            />
                          </>
                        )}
                        <Button
                          className="headerButton"
                          disabled={checkboxes.length ? false : true}
                          onClick={() => setOpenConfirmation(true)}
                        >
                          False Positive
                        </Button>
                      </>
                    )}
                  </div>

                  {showFilter && <Filter filterTerms={searchTerms} setFilter={setFilter} filter={filter} />}
                  <MFPagination
                    currentPage={currentPage}
                    handleChangePage={handleChangePage}
                    totalPages={totalPages}
                    showSelectedRecord
                    totalSelected={checkboxes.length}
                  />
                </Card.Header> */}

                <Card.Body className="table-full-width px-0 desktop-noScroll mt-0 pt-0">
                  <div className="table-responsive pendingReleaseTable">
                    <Table className="separate-border-tr" ref={mainRef}>
                      <Header
                        type={TEST_HEADER_TYPE.pending}
                        flipSort={flipSort}
                        sortBy={sortBy}
                        sortDescending={sortDescending}
                      />
                      <tbody>
                        {testsToMap &&
                          testsToMap.map((test) => {
                            return <TableRow key={test.id} test={test} />;
                          })}
                      </tbody>
                    </Table>
                  </div>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </div>
      </Container>
      <ContactInfo show={modalShow} moreInfo={moreInfo} handleClose={handleCloseContactInfo} />
      <Modal
        show={releaseModalShow}
        // style={{ transform: "translate(0, -30%)" }}
        animation={true}
        onHide={() => setReleaseModalShow(false)}
        centered
        size={"md"}
      >
        <Modal.Header closeButton>
          <Modal.Title className="my-0" id="contained-modal-title-vcenter">
            Release Test Results
          </Modal.Title>
        </Modal.Header>

        <Modal.Body style={{ paddingTop: 0, marginRight: "auto", marginLeft: "auto" }}>
          <p>Select all that apply: </p>
          <div className="checkboxRow">
            <label className="checkboxLabel">
              <input
                disabled={!siteContactPhones}
                value="site"
                onChange={(e) => {
                  if (e.target.checked) {
                    setReleaseTo({
                      ...releaseTo,
                      [e.target.value]: siteContactPhones,
                    });
                  } else {
                    const newReleaseTo = releaseTo;
                    delete newReleaseTo[e.target.value];
                    setReleaseTo(newReleaseTo);
                  }
                }}
                className="releaseCheckbox"
                type="checkbox"
              />
              Health and Safety Officer
            </label>
            <label>
              <input
                value="crew"
                onChange={(e) => {
                  if (e.target.checked) {
                    setReleaseTo({
                      ...releaseTo,
                      [e.target.value]: crewPhoneNumbers,
                    });
                  } else {
                    const newReleaseTo = releaseTo;
                    delete newReleaseTo[e.target.value];
                    setReleaseTo(newReleaseTo);
                  }
                }}
                className="releaseCheckbox"
                type="checkbox"
              />
              Crew Member
            </label>
          </div>
        </Modal.Body>

        <Modal.Footer>
          <Button className="modalButtons" variant="secondary" onClick={() => setReleaseModalShow(false)}>
            Cancel
          </Button>
          <Button className="modalButtons" variant="primary" onClick={async () => await releaseTests()}>
            Release Results
          </Button>
        </Modal.Footer>
      </Modal>
      {openConfirmation && (
        <ConfirmationModal
          show={openConfirmation}
          title="Positive Test"
          message={`Are you sure, you want to mark as False Positive?`}
          handleConfirm={handleFalsePositive}
        />
      )}
    </>
  ) : (
    <Loader />
  );
};

export default PendingReleaseTestListView;
