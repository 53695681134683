import API from "api";
import { Auth } from "aws-amplify";
import { default as api, default as localApi } from "api";

import { CONFIG, HEALTH_AND_SAFTEY, defaultPersonalization } from "constant";
import { useEffect, useState } from "react";
import SweetAlert from "react-bootstrap-sweetalert";
import { useHistory } from "react-router-dom";
import {
  connectedIDInLocalStorage,
  medFlowInLocalStorage,
  medFlowPortalTitle,
  personalisationInLC,
  roleInLocalStorage,
  sortingFilterInLC,
  clientIDInLocalStorage,
} from "utils";
import loginLogo from "../../assets/img/login_logo.png";
import LoginUi from "./LoginUi";
import MultiRole from "./MultiRole";
import TwoFa from "./TwoFa";
import useLocalStorageState from "util/useLocalStorageState";
import Loader from "components/Loader/Loader";
import { setPermission, setGlobalUser } from "store/features/authentication/authenticationSlice";
import { useDispatch } from "react-redux";
import ForgotpassUi from "./ForgotpassUi";
import { callPublicAPI } from "views/GraphQL/gqApi";
import SendOTPUi from "./SendOTPUi";

const Login = () => {
  const [phone, setPhone] = useState("+1");
  const [password, setPassword] = useState("");
  const [phoneError, setPhoneError] = useState("");
  const [userId, setUserId] = useState("");
  const [disableBtn, setDisableBtn] = useState(false);
  const [multiRole, setMultiRole] = useState(false);
  const [loading, setLoading] = useState(false);
  const [isOTPUiOpen, setIsOTPUiOpen] = useState(false);
  const [twoFa, setTwoFa] = useLocalStorageState("twoFa", false);
  const [showForgotUi, setShowForgotUi] = useLocalStorageState("showForgotUi", false);

  const [rolesData, setRolesData] = useState([]);
  const [userRoles, setUserRoles] = useState([]);
  const [userData, setUserData] = useLocalStorageState("userData", null);
  const dispatch = useDispatch();
  let history = useHistory();

  useEffect(() => {
    const getUSer = async () => {
      const user = await API.getCurrentUser();
      if (user) redirectToAdmin();
    };
    getUSer();
  }, []);

  const handleMultiRole = () => {
    setMultiRole(true);
  };

  const handleRoleSelection = async (obj, uid) => {
    let role = obj.role;
    let type = "A";
    if (role === "Labs" || role === "Lab") {
      type = "L";
    } else if (role === "LabTechs") {
      type = "LT";
    } else if (role === "Employers" || role === "Employer" || role === HEALTH_AND_SAFTEY || role === 'Provider') {
      type = "E";
    } else if (role === "Clients" || role === "Client") {
      type = "C";
    }

    // get connected Id
    medFlowInLocalStorage.save(type);
    medFlowPortalTitle.save(obj.title);
    connectedIDInLocalStorage.save(obj.relatedId);
    sortingFilterInLC.save(sortingFilterInLC.get());
    roleInLocalStorage.save(role);

    if (CONFIG.isWhiteLabel) {
      CONFIG.whiteLabelID = obj.labID;
      CONFIG.clientID = obj.clientID;
      CONFIG.siteID = obj.siteID;
      CONFIG.basename = obj.title;
    }

    clientIDInLocalStorage.save(CONFIG.isWhiteLabel ? CONFIG.clientID : clientRole[0].relatedId);

    if (type) {
      let data = null;
      try {
        data = await API.getUserPersonalisation(userId || uid);
      } catch (e) {
        console.log(e);
      }
      if (data && data.hasOwnProperty("personalisation")) {
        personalisationInLC.save(
          "",
          typeof data.personalisation === "string" ? JSON.parse(data.personalisation) : data.personalisation
        );
      } else {
        personalisationInLC.save("", defaultPersonalization());
      }
    }
    setLoading(false);
    setTwoFa(false);
    redirectToAdmin();
  };

  const GET_USER_ROLES = {
    Employers: "Employer",
    Admins: "Admin",
    Labs: "Lab",
    Clients: "Client",
  };

  const handleSubmit = async () => {
    setMultiRole(false);
    setTwoFa(false);
    if (!phone) {
      setPhoneError({ message: "please enter the phone", error: true });
      return;
    }
    if (!password) {
      setPhoneError({ message: "please enter the password", error: true });
      return;
    }

    try {
      setDisableBtn(true);
      setLoading(true);

      const user = await Auth.signIn(phone, password);
      const response = await API.getEmployeeRoles(user.username);
      dispatch(setPermission(response[0]?.userPermission || null));
      dispatch(setGlobalUser(response[0] || null));
      if (user && user.attributes["custom:is2FaEnabled"] === "y") {
        setUserData(user);
        setTwoFa(true);
      } else {
        goToPortal(user);
      }
    } catch (error) {
      setDisableBtn(false);
      setLoading(false);

      setUserId("");
      setUserRoles([]);
      setRolesData([]);
      setPhoneError({ message: error.message, error: true });
    } finally {
      setDisableBtn(false);
      // setLoading(false);
    }
  };

  useEffect(() => {
    if (twoFa) {
      setLoading(false);
    }
  }, [twoFa]);

  const goToPortal = async (user) => {
    setLoading(false);
    if (!user) {
      return;
    }

    const roles = user.signInUserSession.accessToken.payload["cognito:groups"] || [];

    let defaultRole = GET_USER_ROLES[roles[0]];

    let getUserRoles = await API.getEmployeeRoles(user?.username);

    if (getUserRoles[0]?.personalisation) {
      let personalisationData;

      try {
        const rolesPersonalization = getUserRoles[0].personalisation;
        personalisationData = JSON.parse(rolesPersonalization);
      } catch (e) {
        personalisationData = getUserRoles[0].personalisation;
      }
      personalisationInLC.save("", personalisationData);
    }

    let availableRoles = getUserRoles.length > 0 ? JSON.parse(getUserRoles[0]?.roles) : [{ role: defaultRole }];

    let LabAdmin = getUserRoles.length > 0 ? getUserRoles[0].labAdmin : null;

    if (CONFIG.url && CONFIG.isWhiteLabel) {
      availableRoles = availableRoles.filter(
        (role) =>
          role.role === "Lab" ||
          role.role === "Labs" ||
          role.role?.includes("LabTech") ||
          role.role === HEALTH_AND_SAFTEY ||
          role.role === "Employers" ||
          role.role.includes("Provider")
      );
      if (availableRoles.length === 0) {
        console.log("User is not authorized");

        setDisableBtn(false);
        setPhoneError({ message: "Not Authorized!!!", error: true });
        await Auth.signOut();
        return;
      }
    } else {
      availableRoles = availableRoles
        .filter((role) => !role.isWhiteLabelLab)
        .filter((value, index, self) => index === self.findIndex((t) => t.relatedId === value.relatedId));
    }

    getUserRoles = availableRoles;
    let connectedID = user.attributes["custom:connectedID"];

    const { clientID, labID, siteID } = getUserRoles[0];

    if (getUserRoles.length > 0) {
      let userRole = getUserRoles;
      setUserRoles(userRole);

      if (LabAdmin) {
        const AdminRole = userRole.filter((f) => f.role === "Admin");
        const clientRole = userRole.filter((f) => f.role === "Client");
        handleRoleSelection({ ...AdminRole[0], relatedId: LabAdmin }, user?.username);
        clientIDInLocalStorage.save(CONFIG.isWhiteLabel ? CONFIG.clientID : clientRole[0].relatedId);
        return;
      }

      if (userRole?.length > 1) {
        setUserId(user?.username);
        let rolesGet = userRole.filter((role) =>
          ["Lab", "LabTech", "Admin", "Client","Provider", HEALTH_AND_SAFTEY].includes(role.role)
        );
        let roleArrFormat = [];
        if (rolesGet.length === 1) {
          handleRoleSelection(rolesGet[0], user?.username);
          return;
        }

        if (rolesGet) {
          roleArrFormat = rolesGet.map((element) => {
            if (element.role === HEALTH_AND_SAFTEY || element.role === "Employers") {
              return { ...element, role: "Employers" };
            }
            return { ...element, role: `${element.role}s` };
          });
        }
        setRolesData(roleArrFormat);
        handleMultiRole();
        return;
      } else if (userRole?.length === 1 && clientID) {
        connectedID = userRole[0]?.relatedId;
        if (CONFIG.isWhiteLabel) {
          CONFIG.whiteLabelID = labID;
          CONFIG.clientID = clientID;
          CONFIG.siteID = siteID;
          CONFIG.basename = userRole[0]?.title;
        }
        setRolesData([]);
      } else {
        setRolesData([]);
      }
    }

    let type = "A";

    if (getUserRoles?.some((s) => s.role === "Lab")) {
      type = "L";
    }
    if (getUserRoles?.some((s) => s.role === "LabTech")) {
      type = "LT";
    } else if (
      getUserRoles.some((s) => s.role === "Employer" || s.role === HEALTH_AND_SAFTEY || s.role === "Employers" || s.role === "Provider")
    ) {
      type = "E";
    } else if (getUserRoles.some((s) => s.role === "Client")) {
      type = "C";
    }
    medFlowPortalTitle.save(getUserRoles[0]?.title || "");
    medFlowInLocalStorage.save(type);
    connectedIDInLocalStorage.save(connectedID);
    sortingFilterInLC.save(sortingFilterInLC.get());
    clientIDInLocalStorage.save(CONFIG.isWhiteLabel ? CONFIG.clientID : clientRole[0].relatedId);

    // if (type) {
    //   let data = null;
    //   try {
    //     data = await API.getUserPersonalisation(user.username);
    //   } catch (e) {
    //     console.log(e);
    //   }
    //   if (data && data.length > 0) {
    //     console.log("dataOfLogin", data);
    //     personalisationInLC.save(
    //       "",
    //       typeof data[0].personalisation === "string" ? JSON.parse(data[0].personalisation) : data[0].personalisation
    //     );
    //   } else {
    //     personalisationInLC.save("", defaultPersonalization());
    //   }
    // }
    setTwoFa(false);
    redirectToAdmin();
  };

  const handleKeyDownEvent = (event) => {
    if (event.key === "Enter") {
      handleSubmit();
    }
  };

  const redirectToAdmin = () => {
    if (!twoFa) {
      if (history) {
        // setLoading(false);
        history.push("/admin");
      } else window.location.reload();
    }
  };

  const handleForgotPassword = async (resend = false) => {
    if (phone && phone.length > 6) {
      try {
        // await Auth.forgotPassword(phone);
        setLoading(true);

        const path = "/user/sendCode";
        const response = await callPublicAPI({ path, payload: { phone } });
        if (response && response.send) {
          setIsOTPUiOpen(false);
          setShowForgotUi(true);
          if (resend) {
            setPhoneError({ message: "OTP Sent to your email.", success: true, title: "OTP Sent." });
          }
        } else {
          setPhoneError({ message: "Record Not Found", error: true });
        }
      } catch (error) {
        setPhoneError({ message: error?.message, error: true });
      } finally {
        setLoading(false);
      }
    }
  };

  const handleSendOTPUi = async () => {
    setIsOTPUiOpen(true);
  };

  const isLoggedIn = medFlowInLocalStorage.get();
  if (isLoggedIn) redirectToAdmin();

  return (
    <div className="loginWrapper">
      <div className="login-left-side">
        <div className="text-center">{<img src={loginLogo} alt="logo" className="logo-width" />}</div>
        {phoneError && (
          <SweetAlert
            show={phoneError ? true : false}
            error={phoneError.error || false}
            success={phoneError.success || false}
            title={phoneError?.title || "Error"}
            onConfirm={() => setPhoneError("")}
            timeout={4000}
          >
            {phoneError?.message || ""}
          </SweetAlert>
        )}
        {loading && <Loader />}
      </div>
      {multiRole ? (
        <MultiRole roles={rolesData} handleRoleSelection={handleRoleSelection} />
      ) : twoFa ? (
        <TwoFa
          goToPortal={() => goToPortal(userData)}
          setLoading={(value) => setLoading(value)}
          setTwoFa={(value) => setTwoFa(value)}
        />
      ) : isOTPUiOpen ? (
        <SendOTPUi handleSendCode={handleForgotPassword} setPhone={setPhone} phone={phone} />
      ) : showForgotUi ? (
        <ForgotpassUi
          setShowForgotUi={(value) => setShowForgotUi(value)}
          phoneNumber={phone}
          setLoading={(value) => setLoading(value)}
          setPhoneError={(value) => setPhoneError(value)}
          setPassword={(value) => setPassword(value)}
          handleSubmit={handleSubmit}
          handleResendCode={() => handleForgotPassword(true)}
        />
      ) : (
        <LoginUi
          phone={phone}
          setPhone={setPhone}
          password={password}
          setPassword={setPassword}
          handleKeyDownEvent={handleKeyDownEvent}
          disableBtn={disableBtn}
          handleSubmit={handleSubmit}
          handleSendOTPUi={handleSendOTPUi}
          loading={loading}
        />
      )}
    </div>
  );
};

export default Login;
