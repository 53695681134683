import { BCC_VALUE, MEDICARE_ID } from "constant";
import moment from "moment";

export const getElgibilityLineItem = (newUser, cond, value) => {
  
  let partB = newUser.benefit?.find((f) => cond(f, value));

  if (partB) {
    const [planStartDate, planEndDate] =
      (value && value === BCC_VALUE.OUT_OF_POCKET ? partB.benefit?.split("-") : partB.plan_date?.split("-")) || [];
    return {
      ...partB,
      ...(planStartDate && { planStartDate: moment(planStartDate).format("MMM DD, YYYY") }),
      ...(((planStartDate && !planEndDate) || planEndDate === "99991231") && { planEndDate: "-" }),
      ...(planEndDate && planEndDate !== "99991231" && { planEndDate: moment(planEndDate).format("MMM DD, YYYY") }),
    };
  }
  return null;
};

export const getDeductible = (newUser, benefitValue, inNetwork) => {
  const OTHER_BASE_CONDITION = (f) =>
    MEDICARE_ID.includes(newUser.payerId)
      ? f.benefit_code === "30" && f.insurance_type_code === "MB"
      : f.benefit_code === "30" && f.inplan_network === inNetwork;

  // Check if Deductible is there
  const DEDUCTIBLE_CONDITION = (f, value) => OTHER_BASE_CONDITION(f) && f.benefit_coverage_code === value;

  // Check current year deductible
  const DEDUCTIBLE_IN_YEAR = (f, value) =>
    DEDUCTIBLE_CONDITION(f, value) && f.benefit_period_description?.toLowerCase() === "calendar year";
  // Check current year remaining deductible
  const DEDUCTIBLE_REMAINING = (f, value) =>
    DEDUCTIBLE_CONDITION(f, value) && f.benefit_period_description?.toLowerCase() === "remaining";

  // Check co-insurnae percentage
  const CO_INSURANCE_CONDITION = (f) => OTHER_BASE_CONDITION(f) && f.benefit_coverage_code === "A";

  const CO_PAYMENT_CONDITION = (f) =>
    f.benefit_code === "98" && f.inplan_network === inNetwork && f.benefit_coverage_code === "B";
  const CO_PAYMENT_SPECIALIST_CONDITION = (f) =>
    f.benefit_code === "96" && f.inplan_network === inNetwork && f.benefit_coverage_code === "B";

  let deductibleInYear = getElgibilityLineItem(newUser, DEDUCTIBLE_IN_YEAR, benefitValue);
  let deductibleRemaning = getElgibilityLineItem(newUser, DEDUCTIBLE_REMAINING, benefitValue);
  let coInsurance = getElgibilityLineItem(newUser, CO_INSURANCE_CONDITION);
  let coPayment = getElgibilityLineItem(newUser, CO_PAYMENT_CONDITION);
  let coPaymentSpecialist = getElgibilityLineItem(newUser, CO_PAYMENT_SPECIALIST_CONDITION);
  return { deductibleInYear, deductibleRemaning, coInsurance, coPayment, coPaymentSpecialist };
};

export const planDates = (key) => {
  if (!key || typeof key !== "string" || key === "") return {};
  const [planStartDate, planEndDate] = key.split("-") || [];
  return {
    ...(planStartDate && { planStartDate: moment(planStartDate).format("MMM DD, YYYY") }),
    ...(((planStartDate && !planEndDate) || planEndDate === "99991231") && { planEndDate: "to-Date" }),
    ...(planEndDate && planEndDate !== "99991231" && { planEndDate: moment(planEndDate).format("MMM DD, YYYY") }),
  };
};
