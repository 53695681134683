import React, { useMemo } from "react";
import { PDFDownloadLink } from "@react-pdf/renderer";
import AllTestPDF from "components/PDF/AllTestPDF";
import { Button } from "react-bootstrap";
import ReactPDF from "@react-pdf/renderer";
import FileSaver from "file-saver";
import { formatPhoneNumber } from "utils";
import moment from "moment";
import API from "api";
import Icon from "components/Icon";
import { CONFIG } from "constant";
import { connectedIDInLocalStorage } from "utils";

const TestPDFLayout = ({ tests, selectedItem, title, appContext, filter, timeFilter, sortBy }) => {
  const exportData = () => {
    if (!selectedItem) return tests;
    return tests.map((item) => {
      let obj = {};
      selectedItem.forEach((c) => {
        if (c === "phoneNumber") {
          const val = formatPhoneNumber(item[c]);
          Object.assign(obj, { [c]: val });
        } else if (c === "updatedAt" || c === "createdAt" || c === "batch" || c === "resultDate") {
          const val = item[c] ? moment(item[c]).format("MMM DD, YYYY") : moment(item.createdAt).format("MMM DD, YYYY");
          Object.assign(obj, { [c]: val });
        } else if (c === "clientID") {
          const val = appContext.getClientName(item[c]);
          Object.assign(obj, { [c]: val });
        } else if (c === "siteID") {
          const val = appContext.getSiteName(item[c]);
          Object.assign(obj, { [c]: val });
        } else if (c === "labID") {
          const val = appContext.getLabName(item[c]);
          Object.assign(obj, { [c]: val });
        } else if (c === "emailSend") {
          const val = item[c] ? "true" : "";
          Object.assign(obj, { [c]: val });
        } else {
          Object.assign(obj, { [c]: item[c] });
        }
      });
      return obj;
    });
  };

  const fetchData = () => {
    loadData();
  };

  const loadData = async () => {
    try {
      const filterParam = { ...filter };
      if (CONFIG.isWhiteLabel && connectedIDInLocalStorage.get()) {
        filterParam["labID"] = connectedIDInLocalStorage.get();
      }

      if (CONFIG.isWhiteLabel && CONFIG.clientID) {
        filterParam["clientID"] = CONFIG.clientID;
      }
      if (timeFilter) {
        const { start, end } = timeFilter;
        if (start)
          Object.assign(filterParam, { startDate: moment(start.format("YYYY-MM-DD")).startOf("day").toISOString() });
        if (end) Object.assign(filterParam, { endDate: moment(end.format("YYYY-MM-DD")).endOf("day").toISOString() });
        Object.assign(filterParam, { fileType: "PDF" });
      }
      if (Object.keys(filterParam).length > 0) {
        await API.downloadPdf(filterParam);
      }
    } catch (err) {
      console.log("Error", err);
    }
  };
  const handleClick = () => {
    const data = exportData();
    const fileName = "test.pdf";
    ReactPDF.pdf(<AllTestPDF tests={data} title={title} filter={filter} timeFilter={timeFilter} sortBy={sortBy} />)
      .toBlob()
      .then(async (fileContent) => {
        FileSaver.saveAs(fileContent, fileName);
      });
  };

  return (
    <>
      <Icon
        handleClick={handleClick}
        title={"Export Pdf"}
        label={"Export Pdf"}
        iconType={"pdfIcon"}
        //disabled={checkboxes.length ? false : true}
      />
    </>
  );
};

export default TestPDFLayout;
