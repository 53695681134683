export const AUTH = "auth";

export const AUTH_COMPANIES = "auth/companies";

export const AUTH_SIGN_IN = "auth/signin";

export const AUTH_CURRENT_USER = "auth/currentuser";

export const AUTH_COMPANY_USER = "auth/companyuser";

export const AUTH_LISTENER = "auth/listener";

export const AUTH_ERROR_EMAIL = "auth/ErroEmail";
