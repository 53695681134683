import { DataStore, Predicates, SortDirection } from '@aws-amplify/datastore';
import { API, Auth, Storage } from 'aws-amplify';
const mime = require('mime-types');

import axios from 'axios';
import {
  ADMIN_USER,
  BUCKET_EXTERNAL_IMAGES,
  CONFIG,
  HEALTH_AND_SAFTEY,
  RESULT_VALUE,
  TEST_STATUS,
  USER_ROLE_TYPE,
} from 'constant';
import { AWS_ENDPOINT } from 'constants/endpoints';
import graphQLApi from 'graphQLApi';
import moment from 'moment';
import { jsonToCSV, readString } from 'react-papaparse';
import {
  clientIDInLocalStorage,
  connectedIDInLocalStorage,
  FormatAWSDate,
  formatTest,
  getPhoneNo,
  medFlowInLocalStorage,
  removeWhitespaceAndParentheses,
  SYSTEM_TIME_ZONE,
  formatTestDemo,
} from 'utils';
import { v4 as uuidv4 } from 'uuid';
import * as mutations from './graphql/mutations';
import {
  Client,
  Documents,
  Employee,
  ExternalTest,
  GeneralSettingType,
  Lab,
  LencoBarcodes,
  Order,
  Site,
  Test,
  TestConsent,
  TestTypes,
  UploadRequest,
  UploadRequestMain,
} from './models';
import { callAmplifyAPI, callAmplifyAPIPut } from 'views/GraphQL/gqApi';
import { TEST_SETTING_TYPES } from 'constant';
import { callAmplifyAPIWithError } from 'views/GraphQL/gqApi';

const MAX_PAGE_LIMIT = 1000;
const PRE_REGISTER_URL = {
  LIVE: 'https://g8bgbgfb45.execute-api.eu-west-1.amazonaws.com/dev/register',
  DEV: 'https://qa3dzt4aoj.execute-api.eu-west-1.amazonaws.com/dev/register',
};
const apiName = 'AdminQueries';
const publicApiName = 'usersApi';

const callAPIPost = async (path, payload) => {
  try {
    const params = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `${(await Auth.currentSession()).getAccessToken().getJwtToken()}`,
      },
      body: { ...payload, clientID: CONFIG.clientID },
    };

    return await API.post(apiName, path, params);
  } catch (err) {
    throw err;
  }
};

const callAPIPut = async (path, payload) => {
  try {
    const params = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `${(await Auth.currentSession()).getAccessToken().getJwtToken()}`,
      },
      body: { ...payload, clientID: CONFIG.clientID },
    };

    return await API.put(apiName, path, params);
  } catch (err) {
    throw err;
  }
};

const getClients = async () => {
  const models = await DataStore.query(Client, Predicates.ALL, {
    sort: (s) => s.name(SortDirection.ASCENDING),
  });
  /* const clients = [];
  const promises = [];
  models.map(async (model, i) => {
    promises[i] = findTestsByClient(model.id);
    return clients.push({ ...model, tests: await promises[i] });
  });
  return Promise.all([models, ...promises]).then(() => clients); */
  return models;
};
// add new

export const exportClamDataToExcel = async (claimObj) => {
  try {
    return await callAmplifyAPI('/claims/export', claimObj);
  } catch (err) {
    console.error('Error export', err);
    return err;
  }
};

const getResultClients = async () => {
  const models = await DataStore.query(Client, (client) => client.resultType('eq', 'P/F'));
  return models;
};

const getClientById = async (id) => {
  const client = await DataStore.query(Client, (client) => client.id('eq', id));
  return client[0];
};

const getTestGraphQL = async (filter) => {
  let employees = await graphQLApi.getTestGQL(filter);
  return employees;
};

const getEmployeeGraphQl = async () => {
  let employees = await graphQLApi.getEmployeeGQL();
  return employees;
};
const getEmployeeByIdGraphQl = async (id) => {
  let employee = await graphQLApi.getEmployeeByIdGQl(id);
  return employee;
};

const deleteEmployeeGQL = async (employees) => {
  return await callAmplifyAPI('/employees/archive', { ids: employees });
};

const updateEmployeeGraphQL = async (employee) => {
  const employeeData = {
    id: employee.id,
    first: employee.first,
    last: employee.last,
    dob: moment(employee.dob).format('YYYY-MM-DD'),
    id_number: employee.id_number,
    email: employee.email,
    sex: employee.sex,
    phone_number: employee.phone_number,
    countryCode: employee.countryCode,
    street: employee.street,
    street2: employee.street2,
    city: employee.city,
    state: employee.state,
    zip: employee.zip,
    profileImage: employee.profileImage,
    vaccinationCardImage: employee.vaccinationCardImage,
    externalTestImage: employee.externalTestImage,
    _version: employee._version,
  };
  const updateEmp = await graphQLApi.updateEmployeeGQL(employeeData);
  return updateEmp;
};

const getCurrentUser = async () => {
  try {
    const response = await Auth.currentAuthenticatedUser({ bypassCache: true });
    const role = medFlowInLocalStorage.get() || 'U';
    return {
      ...response.attributes,
      roles: response.signInUserSession.accessToken.payload['cognito:groups'] || [],
      name: `${response.attributes['custom:firstName']} ${response.attributes['custom:lastName']}`,
      clientID: response.attributes['custom:clientID'],
      connectedID: response.attributes['custom:connectedID'],
      providerID: response.attributes['custom:providerID'],
      isAdmin: function () {
        return this.roles.some((role) => role === 'Admins') && role === 'A';
      },
      isLab: function () {
        return (
          this.roles.some((role) => role === 'Labs' || role === 'LabTechs') &&
          (role === 'L' || role === 'LT')
        );
      },
      isUser: function () {
        return this.roles.length === 0;
      },
      isSite: function () {
        return (
          this.roles.some((role) => role === 'Employers' || role === 'Providers') && role === 'E'
        );
      },
      isClient: function () {
        return this.roles.some((role) => role === 'Clients') && role === 'C';
      },
    };
  } catch (err) {
    console.log(err);
    localStorage.clear();
    return null;
  }
};

const getAllUsers = async () => {
  const models = await DataStore.query(Employee);
  return models;
};
export const getCptData = async (params) => {
  console.log('Params', params);
  if (!params?.codes) {
    return [];
  }

  try {
    return await callAmplifyAPI('/excpt', params);
  } catch (err) {
    console.error('Error fetching CPT data:', err);
    return err;
  }
};

const signIn = async (username, password) => {
  try {
    const response = await Auth.signIn(username, password);
    // console.log(response);
    return {
      ...response.attributes,
      roles: response.signInUserSession.accessToken.payload['cognito:groups'] || [],
      isAdmin: function () {
        return this.roles.some((role) => role === 'Admins');
      },
      isUser: function () {
        return this.roles.length === 0;
      },
    };
  } catch (err) {
    return null;
  }
};

const getLabs = async () => {
  const models = await DataStore.query(Lab, Predicates.ALL, {
    sort: (s) => s.name(SortDirection.ASCENDING),
  });
  return models;
};

const getLabsWithTests = async () => {
  const models = await DataStore.query(Lab);
  const labs = [];
  const promises = [];
  models.map(async (model, i) => {
    promises[i] = findTestsByLab(model.id);
    labs.push({ ...model, tests: await promises[i] });
  });
  return Promise.all([models, ...promises]).then(() => labs);
};

const getLabByID = async (labID) => {
  const model = await DataStore.query(Lab, (lab) => lab.id('eq', labID));
  return model[0];
};

const createSite = async (newSite, loginUser) => {
  try {
    console.log('[createSite]', newSite, loginUser);
    const userName = `${loginUser.firstName} ${loginUser.lastName}`;
    const localSiteObject = await DataStore.save(
      new Site({
        name: newSite.name,
        clientID: newSite.clientID,
        contactName: newSite.contactName,
        contact_name: newSite.contact || newSite.contact_name || null,
        phone_number: newSite.phone || newSite.phone_number || null,
        email: newSite.email || null,
        contact_email: newSite.contact_email || null,
        user_id: loginUser.userID,
        antigen: newSite.antigen,
        automateTestType: newSite.automateTestType,
        insurance_required: newSite.insurance_required,
        startDate: newSite.startDate,
        endDate: newSite.endDate,
        orderKitDate: newSite.orderKitDate ? moment(newSite.orderKitDate).toISOString() : null,
        orderKitType: newSite.orderKitType,
        enableShipment: newSite.enableShipment,
        orderQuantity: newSite.orderQuantity,
        sendInsuranceCard: newSite.sendInsuranceCard,
        resetTestDay: newSite.resetTestDay,
        showOnSummaryScreen: newSite.showOnSummaryScreen,
        messageTested: newSite.messageTested,
        messageResulted: newSite.messageResulted,
        messagePositive: newSite.messagePositive,
        isArchive: newSite.isArchive,
        isActive: newSite.isActive,
        vaccinationCard: newSite.vaccinationCard,
        externalTest: newSite.externalTest,
        externalTestAdmit: newSite.externalTestAdmit,
        externalTestType: newSite.externalTestType,
        admit: newSite.admit,
        patternTesting: newSite.patternTesting,
        patternHippa: newSite.patternHippa,
        preRegistration: newSite.preRegistration,
        messageReTest: newSite.messageReTest ? JSON.stringify(newSite.messageReTest) : null,
        isHRPR: newSite.isHRPR,
        isPCRCustody: newSite.isPCRCustody,
        sendIdentification: newSite.sendIdentification,
        sendExternalRequisition: newSite.sendExternalRequisition,
        sendPrescription: newSite.sendPrescription,
        sendAuthorization: newSite.sendAuthorization,
        sendOtherDocuments: newSite.sendOtherDocuments,
        isAutoSubmitClaim: newSite.isAutoSubmitClaim,
        isTesting: newSite.isTesting,
        status: 1,
        street: newSite.street,
        city: newSite.city,
        state: newSite.state,
        zipcode: newSite.zipcode,
        safeCampAdmin: newSite?.safeCampAdmin,
        setting: newSite?.setting,
        practiceID: newSite?.practiceID,
        fax: newSite.fax,
        contact_fax: newSite.contact_fax || null,
        createdBy: loginUser.userID,
        createdByName: userName,
        updatedByName: userName,
        discount: newSite.discount || 0,
        commission: newSite.commission || 0,
        siteType: newSite?.siteType,
      }),
    );

    console.log('localSiteObject', localSiteObject);

    const site = await callAmplifyAPI('/labclient', {
      site: { ...newSite, userID: loginUser.userID, id: localSiteObject.id },
    });

    return localSiteObject;
  } catch (ex) {
    console.log('Ex', ex);
  }
  return null;
};

const deleteUploadRequest = async (id, user) => {
  try {
    const path = '/uploadrequest/remove';
    const models = await DataStore.query(UploadRequestMain, (record) => record.id('eq', id));

    if (models.length === 0) return;

    const dbRequest = models[0];

    await DataStore.save(
      UploadRequestMain.copyOf(dbRequest, (updated) => {
        updated.status = 'cancel';
      }),
    );
    const params = {
      id: dbRequest?.id,
      version: dbRequest?._version,
      userID: user?.sub,
      userName: user?.name,
    };
    const apiRes = await callAmplifyAPI(path, params);
  } catch (err) {
    console.log('err', err.message);
  }
};

const clearUploadRequest = async (id) => {
  try {
    const models = await DataStore.query(UploadRequestMain);
    if (models.length === 0) return;
    for (let i = 0; i < models.length; i++) {
      await DataStore.delete(UploadRequestMain, (client) => client.id('eq', models[i].id));
    }
  } catch (err) {
    console.log('err', err.message);
  }
};

const clearUploadSubRequest = async (id) => {
  try {
    const models = await DataStore.query(UploadRequest);
    if (models.length === 0) return;
    for (let i = 0; i < models.length; i++) {
      await DataStore.delete(UploadRequest, (client) => client.id('eq', models[i].id));
    }
  } catch (err) {
    console.log('err', err.message);
  }
};

const clearOrder = async (id) => {
  try {
    const models = await DataStore.query(Order, (order) => order.isActive('eq', false));

    if (models.length === 0) return;
    for (let i = 0; i < models.length; i++) {
      // await DataStore.delete(Order, (client) => client.id("eq", models[i].id));
    }
  } catch (err) {
    console.log('err', err.message);
  }
};

const fetchUploadRequest = async (user) => {
  const twentyMinutesAgo = new Date(Date.now() - 20 * 60000).toISOString();

  const models = await DataStore.query(UploadRequestMain, (record) =>
    record.status('eq', 'pending').createdBy('eq', user?.sub).createdAt('ge', twentyMinutesAgo),
  );
  const sites = [];
  const promises = [];
  models.map(async (model, i) => {
    promises[i] = getUploadRequestBYReqID(model.id);
    sites.push({
      ...model,
      Items: await promises[i],
    });
  });
  return Promise.all([models, ...promises]).then(() => sites);
};
const getUploadRequestBYReqID = async (Id) => {
  const models = await DataStore.query(UploadRequest, (record) => record.requestID('eq', Id));

  return models;
};

const updateUploadRequest = async (Id, results) => {
  const models = await DataStore.query(UploadRequest, (record) => record.id('eq', Id));
  const dbRequest = models[0];
  const { data } = dbRequest;
  const fileData = { ...data };
  const { record, eligibiltyStatus } = results;
  if (eligibiltyStatus && eligibiltyStatus.error) {
    const { error_mesg } = eligibiltyStatus.error;
    Object.assign(fileData, { eligibility: 'fail', eligibilityMessage: error_mesg });
  } else if (eligibiltyStatus) {
    Object.assign(fileData, { eligibility: 'pass' });
  }

  await DataStore.save(
    UploadRequest.copyOf(dbRequest, (updated) => {
      updated.status = 'pass';
      updated.data = {
        ...fileData,
        patientCreated: 'pass',
        orderCreated: 'pass',
        ...(record && { empID: record?.id }),
      };
    }),
  );
};

const getUploadRequest = async (Id) => {
  const models = await DataStore.query(UploadRequest);
  console.log('getUploadRequest', models);
  return models;
};

const getSites = async (params) => {
  // Destructure params to extract dynamic values
  let models = await DataStore.query(
    Site,
    (site) => site.or((site) => [site.isArchive('eq', false), site.isArchive('notExists')]),
    {
      sort: (s) => s.name(SortDirection.ASCENDING), // Sort by name
    },
  );

  // if (params?.siteType === "lab") {
  //   models = models.filter((f) => f.siteType === "lab");
  // }else{
  //   models = models.filter((f) => f.siteType !== "lab");
  // }

  const type = medFlowInLocalStorage.get() || 'A';

  if (type === 'E') {
    return models.filter((f) => f.id === connectedIDInLocalStorage.get());
  }

  return models;

  const sites = [];
  const promises = [];
  models.map(async (model, i) => {
    promises[i] = findTestsBySite(model.id);
    sites.push({
      ...model,
      tests: await promises[i],
      labID: await promises[i].then((resp) => (resp[0] ? resp[0].labID : null)),
    });
  });
  return Promise.all([models, ...promises]).then(() => sites);
};
const getSitesWithTests = async () => {
  const models = await DataStore.query(
    Site,
    (site) => site.isArchive('eq', false).siteType('ne', 'lab'),
    {
      sort: (s) => s.name(SortDirection.ASCENDING),
    },
  );

  const sites = [];
  const promises = [];
  models.map(async (model, i) => {
    promises[i] = findTestsBySite(model.id);
    sites.push({
      ...model,
      tests: await promises[i],
      labID: await promises[i].then((resp) => (resp[0] ? resp[0].labID : null)),
    });
  });
  return Promise.all([models, ...promises]).then(() => sites);
};

const getShows = async () => {
  const models = await DataStore.query(Site);
  return models;
};

const getSitesByClient = async (clientID) => {
  const models = await DataStore.query(
    Site,
    (site) => site.clientID('eq', clientID).siteType('ne', 'lab'),
    {
      sort: (s) => s.name(SortDirection.ASCENDING),
    },
  );

  const sites = [];
  const promises = [];
  console.log(models);
  models.map(async (model, i) => {
    promises[i] = findTestsBySite(model.id);
    sites.push({
      ...model,
      tests: await promises[i],
      labID: await promises[i].then((resp) => (resp[0] ? resp[0].labID : null)),
    });
  });
  return Promise.all([models, ...promises]).then(() => sites);
};

const getSitesByUser = async (userID) => {
  let models = await DataStore.query(Site, (site) => site.id('eq', userID).siteType('ne', 'lab'), {
    sort: (s) => s.name(SortDirection.ASCENDING),
  });

  const sites = [];
  const promises = [];
  models.map(async (model, i) => {
    promises[i] = findTestsBySite(model.id);
    sites.push({
      ...model,
      tests: await promises[i],
      labID: await promises[i].then((resp) => (resp[0] ? resp[0].labID : null)),
    });
  });
  return Promise.all([models, ...promises]).then(() => sites);
  // console.log(models);
  // return models;
};

const getUserSiteData = async (userID) => {
  let models = await DataStore.query(Site, (site) => site.id('eq', userID).siteType('ne', 'lab'), {
    sort: (s) => s.name(SortDirection.ASCENDING),
  });
  return models;
};

const getUserSites = async (userID) => {
  let models = await DataStore.query(
    Site,
    (site) => site.user_id('eq', userID).siteType('ne', 'lab'),
    {
      sort: (s) => s.name(SortDirection.ASCENDING),
    },
  );

  if (models.length === 0) {
    const allSites = await DataStore.query(Site);
    models = allSites.filter((s) => s.show_contacts && s.show_contacts.includes(userID));
  }

  const sites = [];
  const promises = [];
  console.log(models);
  return models[0].id;
};

const findSiteByID = async (siteID) => {
  const models = await DataStore.query(Site, (site) => site.id('eq', siteID));
  return models;
};

const createUploadResult = async (param) => {
  const {
    siteID,
    clientID,
    fileName,
    filesStatus,
    fileType,
    orderDate,
    title,
    createdBy,
    createdByName,
    uploadCallType,
    status,
  } = param;
  const res = await DataStore.save(
    new UploadRequestMain({
      clientID,
      siteID,
      orderDate,
      fileName,
      filesStatus,
      fileType,
      title,
      createdBy,
      createdByName,
      uploadCallType,
      status,
    }),
  );

  return res;
};

const getEmployeeByID = async (employeeID) => {
  const models = await DataStore.query(Employee, (test) => test.id('eq', employeeID));
  return models.length > 0 ? models[0].mdID || employeeID : employeeID;
};
const getEmployeeTestCount = async (employeeID) => {
  const models = await DataStore.query(Test, (test) => test.employeeID('eq', employeeID));
  return models;
};

const getEmployeeLastShow = async (employeeID) => {
  const models = await DataStore.query(Test, (test) => test.employeeID('eq', employeeID), {
    sort: (s) => s.createdAt(SortDirection.DESC),
  });
  if (models.length === 0) return 'N/A';
  return models[0].site_name;
};

const createEmployee = async (demographics = {}, clientID, phoneNumber) => {
  const models = await DataStore.query(Employee, (emp) =>
    emp
      .clientID('eq', clientID)
      .first('eq', demographics.firstName)
      .last('eq', demographics.lastName)
      .dob('eq', buildAWSDate(demographics.dob)),
  );
  if (models.length > 0) {
    return models[0];
  }
  //Must be new. Create it
  const employee = await DataStore.save(
    new Employee({
      first: demographics.firstName,
      last: demographics.lastName,
      dob: buildAWSDate(demographics.dob),
      id_number: demographics.idNumber,
      clientID: clientID,
      phone_number: phoneNumber,
      street: demographics.street,
      street2: demographics.street2,
      city: demographics.city,
      state: demographics.state,
      zip: demographics.zip,
    }),
  );
  return employee;
};

const preRegisterEmployee = async (employee, env) => {
  const zipcode = employee.zipcode.replaceAll('[^0-9]+', '');
  let params = `first=${encodeURIComponent(employee.first)}&last=${encodeURIComponent(
    employee.last,
  )}&dob=${encodeURIComponent(employee.dob)}&id_number=${encodeURIComponent(employee.id_number)}`;
  params += `&email=${encodeURIComponent(employee.email)}&sex=${encodeURIComponent(
    employee.sex,
  )}&isVaccinated=${encodeURIComponent(employee.isVaccinated)}`;
  params += `&phone_number=${encodeURIComponent(employee.phone_number)}&street=${encodeURIComponent(employee.street)}`;
  params += `&city=${encodeURIComponent(employee.city)}&state=${encodeURIComponent(
    employee.state,
  )}&labid=${encodeURIComponent(CONFIG.appPackage)}&zipcode=${encodeURIComponent(
    zipcode,
  )}&countryCode=${encodeURIComponent(employee.countryCode)}`;

  const notification = await axios.post(`${PRE_REGISTER_URL[env]}?${params}`);

  return notification.data;
};

const getPreRegisterByPhone = async (phone, showId, env) => {
  let params = `phoneNo=${encodeURIComponent(phone)}`;
  if (showId) params += `&showId=${encodeURIComponent(showId)}`;
  const notification = await axios.post(`${PRE_REGISTER_URL[env]}?${params}`);
  return notification.data;
};

const preRegisterNewEmployee = async (employee, clientID, isVaccinationInfoEnable, step, env) => {
  const zipcode = employee.zipcode.replaceAll('[^0-9]+', '');
  let params = `first=${encodeURIComponent(employee.first)}&last=${encodeURIComponent(
    employee.last,
  )}&dob=${encodeURIComponent(employee.dob)}&id_number=${encodeURIComponent(employee.id_number)}`;
  params += `&email=${encodeURIComponent(employee.email)}&sex=${encodeURIComponent(
    employee.sex,
  )}&isVaccinated=${encodeURIComponent(employee.isVaccinated)}`;
  params += `&phone_number=${encodeURIComponent(employee.phone_number)}&countryCode=${encodeURIComponent(
    employee.countryCode,
  )}&street=${encodeURIComponent(employee.street)}&street2=${encodeURIComponent(employee.street2)}`;
  params += `&city=${encodeURIComponent(employee.city)}&state=${encodeURIComponent(
    employee.state,
  )}&country=${encodeURIComponent(employee.country)}&zipcode=${encodeURIComponent(zipcode)}&rStep=${step}`;

  if (employee.showId) {
    params += `&showId=${employee.showId.id}&showName=${encodeURIComponent(employee.showId.name)}`;
  }
  if (employee.profileImage) {
    params += `&profileImage=${encodeURIComponent(employee.profileImage)}`;
  }
  if (isVaccinationInfoEnable) {
    params += `&vaccinationType=${encodeURIComponent(employee.vaccinationType)}&vaccinationDate=${encodeURIComponent(
      employee.vaccinationFirstDate,
    )}&vaccinationLocation=${encodeURIComponent(
      employee.vaccinationFirstLocation,
    )}&vaccineCardFile=${encodeURIComponent(employee.vaccineCardFile)}`;
    if (employee.vaccinationSecondDate) {
      params += `&vaccinationDate1=${encodeURIComponent(
        employee.vaccinationSecondDate,
      )}&vaccinationLocation1=${encodeURIComponent(employee.vaccinationSecondLocation)}`;
    }
    if (employee.isBooster) {
      params += `&isBooster=${encodeURIComponent(employee.isBooster)}&boosterType=${encodeURIComponent(
        employee.boosterType,
      )}&boosterDate=${encodeURIComponent(employee.boosterDate)}&boosterLocation=${encodeURIComponent(
        employee.boosterLocation,
      )}`;
    }
  }
  if (employee.isExternalTest) {
    params += `&isExternalTest=${encodeURIComponent(employee.isExternalTest)}&externalTestDate=${encodeURIComponent(
      employee.externalTestDate,
    )}&externalTestResult=${encodeURIComponent(employee.externalTestResult)}&externalTestType=${encodeURIComponent(
      employee.externalTestType,
    )}&externalFile=${encodeURIComponent(employee.externalFile)}`;
  }

  console.log('Parmas', params);
  const notification = await axios.post(`${PRE_REGISTER_URL[env]}?${params}`);
  console.log('notification', notification);
  return notification.data;
};

const requestTestKit = async (employee, clientID, env) => {
  const zipcode = employee.zipcode.replaceAll('[^0-9]+', '');
  let params = `first=${encodeURIComponent(employee.first)}&last=${encodeURIComponent(
    employee.last,
  )}&order_phone=${encodeURIComponent(employee.order_phone)}`;
  params += `&email=${encodeURIComponent(employee.email)}&phone_number=${encodeURIComponent(
    employee.phone_number,
  )}&profileImage=${encodeURIComponent(employee.profileImage)}`;
  params += `&countryCode=${encodeURIComponent(employee.countryCode)}&street=${encodeURIComponent(
    employee.street,
  )}&street2=${encodeURIComponent(employee.street2)}`;
  params += `&city=${encodeURIComponent(employee.city)}&state=${encodeURIComponent(
    employee.state,
  )}&country=${encodeURIComponent(employee.country)}&zipcode=${encodeURIComponent(zipcode)}`;
  if (employee.showId) {
    params += `&showId=${encodeURIComponent(employee.showId.id)}&showName=${encodeURIComponent(employee.showId.name)}`;

    if (employee.showId.orderKitType) {
      params += `&orderKitType=${encodeURIComponent(employee.showId.orderKitType)}`;
    }
  }
  if (clientID) {
    params += `&clientId=${encodeURIComponent(clientID)}`;
  }
  params += `&testSend=${encodeURIComponent(employee.testSend)}&testSendQty=${encodeURIComponent(
    employee.testSendQty,
  )}`;

  params += `&capacity=${employee.capacity}`;
  if (employee.designation) {
    params += `&designation=${encodeURIComponent(employee.designation)}`;
  }

  if (employee.dob) {
    params += `&dob=${encodeURIComponent(employee.dob)}`;
  }

  if (employee.sex) {
    params += `&sex=${encodeURIComponent(employee.sex)}`;
  }
  if (employee.reorder) {
    params += `&reorder=${encodeURIComponent(employee.reorder)}`;
  }
  params += `&id_number=${encodeURIComponent(employee.id_number || 0)}`;

  console.log('Parmas', params);
  const notification = await axios.post(`${PRE_REGISTER_URL[env]}?${params}`);

  return notification.data;
};

const getPregRegistrationPhoneData = async (data) => {
  const path = 'https://9dpj6yddw2.execute-api.eu-west-1.amazonaws.com/default/preregistration';
  const employeeData = await axios.post(path, data);
  console.log('employeeData', employeeData);
  return employeeData.data;
};

const hipaaTestSearch = async (param, testKey) => {
  try {
    let path = '/hippa/search';
    let params = {
      body: {
        email: param.email,
        testKey: testKey,
      },
    };
    const res = await API.post(publicApiName, path, params);
    console.log('res', res);
    return res.rows;
  } catch (err) {
    console.log('Error:-', err);
  }
};

const getHipaaEmployeeList = async (param, testKey) => {
  try {
    let path = '/hippa/list';
    let params = {
      body: {},
    };
    const res = await API.post(publicApiName, path, params);
    console.log('res', res);
    return res.rows;
  } catch (err) {
    console.log('Error:-', err);
  }
};

const UpdateHipaaTestAns = async (param, testKey, email, firstName, lastName) => {
  try {
    let path = '/hippa/answer';
    let params = {
      body: {
        answers: JSON.stringify(param),
        testKey: testKey,
        email: email,
        ...(firstName && { firstName: firstName }),
        ...(lastName && { lastName: lastName }),
      },
    };
    const res = await API.post(publicApiName, path, params);

    return res.rows;
  } catch (err) {
    console.log('Error:-', err);
  }
};

const UpdateHipaaTestComplete = async (param) => {
  try {
    let path = '/hippa/complete';
    let params = {
      body: param,
    };
    const res = await API.post(publicApiName, path, params);
  } catch (err) {
    console.log('Error:-', err);
  }
};

const preRegisterClientShows = async (id, env) => {
  let params = `&preregistration=${encodeURIComponent(id)}`;

  console.log('Parmas', params);
  const notification = await axios.post(`${PRE_REGISTER_URL[env]}?${params}`);

  return notification.data.body;
};

const preRegisterShowData = async (id, env) => {
  let params = `&registeruser=${encodeURIComponent(id)}`;

  console.log('Parmas', params);
  const notification = await axios.post(`${PRE_REGISTER_URL[env]}?${params}`);

  return notification.data.body;
};

const createTest = async (cloneSequence, firstName, lastName, phone, email, sequenceNo) => {
  const models = await DataStore.query(Test, (test) => test.sequenceNo('eq', cloneSequence));

  if (models.length === 0) return;

  const dbEmp = models[0];

  // const employee = await DataStore.save(
  //   new Employee({
  //     first: 'Joann',
  //     last: 'Jackson',
  //     dob: buildAWSDate('10291989'),
  //     id_number: '123',
  //     phone_number: '323620699677',
  //     street: '',
  //     street2: '',
  //     city: 'CA',
  //     state: 'CA',
  //     zip: '',
  //   })
  // );

  const demo = {
    city: 'Fayetteville',
    dob: '10081982',
    email: email,
    firstName: firstName,
    id: '1a9431ed-e4c4-4fac-81d6-c8a91c842dd8',
    idNumber: '123',
    insurance_name: '',
    insurance_number: '',
    isVaccinated: true,
    lastName: lastName,
    phoneNumber: phone,
    sex: gender,
    state: '',
    street: '',
    street2: '',
    whiteGlove: false,
  };

  const test = await DataStore.save(
    new Test({
      barcode: sequenceNo,
      batch: dbEmp.batch,
      beenTimed: dbEmp.beenTimed,
      clientID: dbEmp.clientID,
      clientName: dbEmp.clientName,
      createSource: dbEmp.createSource,
      createdAt: dbEmp.createdAt,
      createdBy: dbEmp.createdBy,
      done: dbEmp.done,
      email: dbEmp.email,
      emailSend: dbEmp.emailSend,
      employeeID: dbEmp.employeeID,
      employee_demographics: demo,
      expired: dbEmp.expired,
      isAntigen: dbEmp.isAntigen,
      isFalsePositive: dbEmp.isFalsePositive,
      isLucira: dbEmp.isLucira,
      labID: dbEmp.labID,
      labName: dbEmp.labName,
      owner: dbEmp.owner,
      phoneNumber: dbEmp.phoneNumber,
      referenceID: dbEmp.referenceID,
      sequenceNo: sequenceNo,
      siteID: dbEmp.siteID,
      site_name: dbEmp.site_name,
      sr_no: dbEmp.sr_no,
      startTimeStamp: dbEmp.startTimeStamp,
      status: dbEmp.status,
      submittedBy: dbEmp.submittedBy,
      submittedByName: dbEmp.submittedByName,
      test_number: dbEmp.test_number,
      test_type: dbEmp.test_type,
      testerPhone: dbEmp.testerPhone,
      tester_name: dbEmp.tester_name,
      timerStatus: dbEmp.timerStatus,
    }),
  );
  return test;
};

const createClient = async (newClient) => {
  // const phoneNumber = newClient.phone.replace(/\D/g, "");
  const client = await DataStore.save(
    new Client({
      name: newClient.name,
      contact_name: newClient.contact,
      phone_number: newClient.phone,
      fax_number: newClient.fax_number,
      contact_email: newClient.email,
      resultType: newClient.resultType,
      showOnSummaryScreen: newClient.showOnSummaryScreen,
      whiteLabel: newClient.whiteLabel,
      whiteLabelPackage: newClient.whiteLabelPackage,
      isArchive: newClient.isArchive,
      street: newClient.street,
      city: newClient.city,
      state: newClient.state,
      zipcode: newClient.zipcode,
      // user_id: newUserID,
    }),
  );
  return client;
};

const createLab = async (newLab, newUserID) => {
  const lab = await DataStore.save(
    new Lab({
      name: newLab.name,
      contact_name: newLab.contact,
      phone_number: newLab.phone,
      fax_number: newLab.fax_number,
      contact_email: newLab.email,
      user_id: newUserID,
      street: newLab.street,
      city_state_zip: newLab.city_state_zip,
      lab_director: newLab.lab_director,
      testing_notes: newLab.testing_notes,
      clia_number: newLab.clia_number,
      referredDoctor: newLab.referredDoctor,
      default_antigen: newLab.default_antigen,
      default_molecular: newLab.default_molecular,
      default_other: newLab.default_other,
      default_rapidflu: newLab.default_rapidflu,
      default_lucira: newLab.default_lucira,
      tubes_provided: newLab.tubes_provided,
      sendInsurance: newLab.sendInsurance,
      invalid_notes: newLab.invalid_notes,
      indeterminate_notes: newLab.indeterminate_notes,
      logo: newLab.logo,
      barCodeProvided: newLab.barCodeProvided,
      barCodeAlertLimit: 0,
      antigen_notes: newLab.antigen_notes,
      molecular_notes: newLab.molecular_notes,
      showOnSummaryScreen: newLab.showOnSummaryScreen,
      whiteLabel: newLab.whiteLabel,
      whiteLabelPackage: newLab.whiteLabelPackage,
      other_notes: newLab.other_notes,
      lucira_notes: newLab.lucira_notes,
      rapidflu_notes: newLab.rapidflu_notes,
      custom_notes: newLab.custom_notes,
      isArchive: newLab.isArchive,
      pendingRelease: newLab.pendingRelease,
    }),
  );
  return lab;
};

const deleteClient = async (id) => {
  await DataStore.delete(Client, (client) => client.id('eq', id));
};

const deleteLab = async (id) => {
  await DataStore.delete(Lab, (lab) => lab.id('eq', id));
};

const deleteSite = async (id) => {
  await DataStore.delete(Site, (site) => site.id('eq', id));
};
const deleteEmployee = async (id) => {
  await DataStore.delete(Employee, (e) => e.id('eq', id));
};

const getTests = async () => {
  let currentPage = 0;
  let fetchData = true;
  let models = await DataStore.query(Test, (test) => test.labID('ne', null), {
    sort: (test) => test.createdAt(SortDirection.DESCENDING),
  });

  return models;
};

const getCompletedTests = async () => {
  let models = await DataStore.query(Test, (test) => test.result('ne', null), {
    sort: (test) => test.createdAt(SortDirection.DESCENDING),
  });

  return models;
};

const getExternalTest = async () => {
  let models = await DataStore.query(ExternalTest, Predicates.ALL, {
    sort: (test) => test.createdAt(SortDirection.DESCENDING),
  });

  return models;
};

const getTotalAdminTests = async () => {
  let models = await DataStore.query(Test, (test) => test.labID('ne', null), {
    sort: (test) => test.createdAt(SortDirection.DESCENDING),
  });

  return models;
};

const getPendingTests = async () => {
  let models = await DataStore.query(
    Test,
    (test) => test.status('eq', 'Pending').labID('ne', null),
    {
      sort: (test) => test.createdAt(SortDirection.DESCENDING),
    },
  );

  return models;
};

const getUnSubmittedTest = async () => {
  const models = await DataStore.query(
    Test,
    (test) => test.status('eq', 'Pending').result('eq', null).test_type('eq', 'PCR'),
    {
      sort: (test) => test.createdAt(SortDirection.DESCENDING),
    },
  );
  return models;
};

const getUnprocessedTests = async () => {
  let currentPage = 0;
  let fetchData = true;
  let models = await DataStore.query(
    Test,
    (test) =>
      test
        .labID('ne', null)
        .result('eq', null)
        .or((test) => test.status('eq', 'Processing').status('eq', 'Pending').status('eq', 'Sent')),
    {
      sort: (test) => test.createdAt(SortDirection.DESCENDING),
    },
  );

  return models;
};

const getAllEmployees = async () => {
  let models = await DataStore.query(Employee, Predicates.ALL);
  return models;
};

const submitUnprocessedTests = async (tests, user, env) => {
  const response = [];
  const testsByLab = {};
  let allTests = [];
  console.log('tests', tests);
  for (const test of tests) {
    if (!testsByLab[test.labID]) {
      testsByLab[test.labID] = [test];
    } else {
      testsByLab[test.labID].push(test);
    }
  }

  for (const lab in testsByLab) {
    const labData = await getLabByID(lab);
    if (labData) {
      allTests = allTests.concat(
        testsByLab[lab].map((t) => {
          return { ...t, labName: labData.name };
        }),
      );
      const [formattedTests, bucket, batch] = formatTests(labData, testsByLab[lab]);
      try {
        await Storage.put(`${batch}.csv`, formattedTests, { bucket: bucket });
        testsByLab[lab].forEach(async (test) => {
          const testObj = await DataStore.query(Test, (t) => t.id('eq', test.id));
          await DataStore.save(
            Test.copyOf(testObj[0], (updated) => {
              updated.status = 'Sent';
              updated.submittedBy = user.sub;
              updated.submittedByName = user.name;
            }),
          );
        });
        response.push({ lab: labData, tests: testsByLab[lab], error: null });
      } catch (e) {
        console.log('Error uploading to bucket', e);
        response.push({ lab: labData, tests: testsByLab[lab], error: 'Error uploading to bucket' });
      }
    }
  }

  await saveFileSendToLab(
    `${moment().format('DD_MM_YYYY_HH_mm_ss')}_send_to_lab_${CONFIG.envType}.csv`,
    allTests,
  );

  return response;
};

// Documents CRUD APIs
const getDocuments = async (employeeID) => {
  const models = await DataStore.query(Documents, (doc) =>
    doc.employeeID('eq', '3afa4605-31bf-4d85-a954-dd879aa0c14c'),
  );
  return models;
};

const fetchDocumentAPI = async (obj) => {
  const path = '/documents/list';
  try {
    const res = await callAmplifyAPI(path, {
      employeeID: obj.employeeID,
      ...(obj.testID && { testID: obj.testID }),
    });
    return res?.rows || [];
  } catch (e) {
    console.log('Error :', e);
  }
};

const newDocumentCreateAPI = async (param) => {
  const path = '/documents';
  try {
    const res = await callAmplifyAPI(path, {
      list: param,
    });
    return res;
  } catch (e) {
    console.log('Error :', e);
  }
};

const updateDocumentAPI = async (param) => {
  const {
    id,
    employeeID,
    docType,
    docLabel,
    fileName,
    fileType,
    fileSize,
    notes,
    updatedBy,
    updatedByName,
  } = param;
  const path = '/documents';
  try {
    const params = {
      body: {
        id,
        employeeID,
        docType,
        docLabel,
        fileName,
        fileType,
        fileSize,
        notes,
        updatedBy,
        updatedByName,
      },
      headers: {
        'Content-Type': 'application/json',
        Authorization: `${(await Auth.currentSession()).getAccessToken().getJwtToken()}`,
      },
    };
    const res = await API.put(apiName, path, params);
    return res;
  } catch (e) {
    console.log('Error :', e);
  }
};

const deleteDocumentAPI = async (param) => {
  console.log('deleteDocumentAPI', param);
  const path = '/documents';
  const { id } = param;

  try {
    const params = {
      body: {
        id,
      },
      headers: {
        'Content-Type': 'application/json',
        Authorization: `${(await Auth.currentSession()).getAccessToken().getJwtToken()}`,
      },
    };
    const res = await API.del(apiName, path, params);
    return res;
  } catch (e) {
    console.log('Error :', e);
  }
};

const saveDocumentFile = async (id, type, fileContent) => {
  try {
    let fileName = `Document/${id}/${moment().format('YYYY_MM_DD_HH_mm_ss')}$${type}`;
    await Storage.put(`${fileName}`, fileContent, {
      bucket: BUCKET_EXTERNAL_IMAGES,
    });
    return fileName;
  } catch (err) {
    console.log('Error in Image Uploading', err);
  }
  return '';
};

const saveResultFile = async (barcode, fileContent) => {
  try {
    let fileName = `results/${CONFIG.isLive ? 'live' : 'test'}/${CONFIG.clientID}/${barcode}`;
    await Storage.put(`${fileName}`, fileContent, {
      bucket: BUCKET_EXTERNAL_IMAGES,
    });
    return barcode;
  } catch (err) {
    console.log('Error in Image Uploading', err);
  }
  return '';
};

const saveTemplateFile = async (fileName, type, fileContent) => {
  try {
    const path = '/labclient/pdftemplate';

    let filePath = `pdf_template/${fileName}_${moment().format('YYYY_MM_DD_HH_mm_ss')}.${type}`;

    await Storage.put(`${filePath}`, fileContent);

    const { jsonData } = await callAmplifyAPI(path, filePath);
    return { jsonData, filePath };
  } catch (err) {
    console.log('Error in Image Uploading', err);
  }
  return '';
};

const uploadPDFTemplate = async (param) => {
  const path = '/labclient/pdftemplate';
  try {
    const res = await callAmplifyAPI(path, param);
    return res;
  } catch (e) {
    console.log('Error :', e);
  }
};

const saveOrderFileIntoBucket = async ({ name, fileContent, ext }) => {
  try {
    let fileName = `Order_Pdf/${CONFIG.envType}/${moment().format('YYYY_MM_DD_HH_mm_ss')}$${name}.${ext}`;
    let maxRetries = 2;
    let attempt = 0;
    while (attempt < maxRetries) {
      try {
        const res = await Storage.put(`${fileName}`, fileContent, {
          bucket: BUCKET_EXTERNAL_IMAGES,
          progressCallback: (event) => {
            console.log('progress event', event);
          },
          errorCallback: (err) => console.log('error err', err),
          completeCallback: (event) => {
            console.log('complete event', event);
          },
        });
        return fileName;
      } catch (ex) {
        console.log('Attempt to upload failed', attempt + 1);
        console.log('Error message:', err.message);
        if (err.response) {
          console.log('HTTP Status:', err.response.status);
          console.log('Response Headers:', err.response.headers);
          console.log('Response Data:', err.response.data);
        }
        attempt++;
        if (attempt >= maxRetries) {
          console.log('Max retries reached, giving up.');
          return '';
        }
      }
    }

    return '';
  } catch (err) {
    console.log('Error in Image Uploading', err);
  }
  return '';
};

const saveOrdersFiles = async (param) => {
  const path = '/savefiles';
  try {
    const res = await callAmplifyAPI(path, param);
    return res;
  } catch (e) {
    console.log('Error :', e);
  }
};

const searchCptCode = async (param) => {
  const path = '/cpt/list';
  try {
    const params = {
      body: { ...param, clientID: CONFIG.clientID },
      headers: {
        'Content-Type': 'application/json',
        Authorization: `${(await Auth.currentSession()).getAccessToken().getJwtToken()}`,
      },
    };
    const res = await API.post(apiName, path, params);
    return res;
  } catch (e) {
    console.log('Error :', e);
  }
};

const searchICTCode = async (param) => {
  const path = '/ict/list';
  try {
    const params = {
      body: { ...param, clientID: CONFIG.clientID },
      headers: {
        'Content-Type': 'application/json',
        Authorization: `${(await Auth.currentSession()).getAccessToken().getJwtToken()}`,
      },
    };
    const res = await API.post(apiName, path, params);
    return res;
  } catch (e) {
    console.log('Error :', e);
  }
};

const reSubmitUnprocessedTests = async (tests, user) => {
  const response = [];
  const testsByLab = {};
  for (const test of tests) {
    if (!testsByLab[test.labID]) {
      testsByLab[test.labID] = [test];
    } else {
      testsByLab[test.labID].push(test);
    }
  }

  for (const lab in testsByLab) {
    const labData = await getLabByID(lab);
    if (labData) {
      const [formattedTests, bucket, batch] = formatTests(labData, testsByLab[lab]);
      try {
        await Storage.put(`${batch}.csv`, formattedTests, { bucket: bucket });
        response.push({ lab: labData, tests: testsByLab[lab], error: null });
      } catch (e) {
        console.log('Error uploading to bucket', e);
        response.push({ lab: labData, tests: testsByLab[lab], error: 'Error uploading to bucket' });
      }
    }
  }
  return response;
};

const formatTests = (labData, tests) => {
  const labName = labData.name;
  let batch;
  switch (labName.toLowerCase()) {
    case 'lenco':
      batch = new Date(tests[0].createdAt)
        .toString()
        .toLocaleString('en-US', { timeZone: 'America/New_York' })
        .substring(0, 21)
        .replace(/(:|\s+)/g, '_');
      return [formatLencoCSV(tests, labData), 'lenco-tests', batch];
    case 'alliance':
      batch = new Date(tests[0].createdAt)
        .toString()
        .toLocaleString('en-US', { timeZone: 'America/New_York' })
        .substring(0, 21)
        .replace(/(:|\s+)/g, '_');
      return [formatAllianceCSV(tests, labData), 'alliance-tests', batch];
    case 'biolab':
      batch = new Date(tests[0].createdAt)
        .toString()
        .toLocaleString('en-US', { timeZone: 'America/Los_Angeles' })
        .substring(0, 21)
        .replace(/(:|\s+)/g, '_');
      return [formatBiolabCSV(tests, labData), 'biolabs-tests', batch];
    case 'sunrise':
      batch = new Date(tests[0].createdAt)
        .toString()
        .toLocaleString('en-US', { timeZone: 'America/Los_Angeles' })
        .substring(0, 21)
        .replace(/(:|\s+)/g, '_');
      return [formatSunriseCSV(tests, labData), 'sunrise-tests', batch];
    case 'lifebrite':
      batch = new Date(tests[0].createdAt)
        .toString()
        .toLocaleString('en-US', { timeZone: 'America/New_York' })
        .substring(0, 21)
        .replace(/(:|\s+)/g, '_');
      return [formatLifebriteCSV(tests, labData), 'lifebrite-tests', batch];
    case 'alta':
      batch = new Date(tests[0].createdAt)
        .toString()
        .toLocaleString('en-US', { timeZone: 'America/Los_Angeles' })
        .substring(0, 21)
        .replace(/(:|\s+)/g, '_');
      return [formatAltaCSV(tests, labData), 'alta-tests', batch];
    case 'new discovery':
      batch = new Date(tests[0].createdAt)
        .toString()
        .toLocaleString('en-US', { timeZone: 'EST' })
        .substring(0, 21)
        .replace(/(:|\s+)/g, '_');
      return [formatNewDiscoveryCSV(tests), 'new-discovery-tests', batch];
    case 'dls':
      batch = new Date(tests[0].createdAt)
        .toString()
        .toLocaleString('en-US', { timeZone: 'HST' })
        .substring(0, 21)
        .replace(/(:|\s+)/g, '_');
      return [formatDLSCSV(tests), 'dls-tests', batch];
    case 'oxygen':
      batch = new Date(tests[0].createdAt)
        .toString()
        .toLocaleString('en-US', { timeZone: 'EST' })
        .substring(0, 21)
        .replace(/(:|\s+)/g, '_');
      return [formatOxygenCSV(tests), 'oxygen-tests', batch];
    default:
      console.log('default case');
      return [
        formatSunriseCSV(tests, labData),
        'no-lab-found-tests',
        `${labName}/${new Date(tests[0].createdAt)
          .toString()
          .substring(0, 21)
          .replace(/(:|\s+)/g, '_')}`,
      ];
  }
};

const toISOLocal = (d) => {
  var z = (n) => ('0' + n).slice(-2);
  var zz = (n) => ('00' + n).slice(-3);
  var off = d.getTimezoneOffset();
  var sign = off > 0 ? '-' : '+';
  off = Math.abs(off);

  return (
    d.getFullYear() +
    '-' +
    z(d.getMonth() + 1) +
    '-' +
    z(d.getDate()) +
    'T' +
    z(d.getHours()) +
    ':' +
    z(d.getMinutes()) +
    ':' +
    z(d.getSeconds()) +
    '.' +
    zz(d.getMilliseconds()) +
    sign +
    z((off / 60) | 0) +
    ':' +
    z(off % 60)
  );
};

const parseDateOfBirth = (dob) => {
  if (dob.includes('-')) {
    let index = dob.indexOf('-');
    let dateFormat = 'MM-DD-YYYY';
    if (index !== 2) {
      dateFormat = 'YYYY-MM-DD';
    }
    return moment(dob, dateFormat).format('MM/DD/YYYY');
  }
  return dob;
};

const parseDateOfBirthLenco = (dob) => {
  if (dob.includes('-')) {
    let index = dob.indexOf('-');
    let dateFormat = 'MM-DD-YYYY';
    if (index !== 2) {
      dateFormat = 'YYYY-MM-DD';
    }
    return moment(dob, dateFormat).format('MM-DD-YYYY');
  }
  return dob;
};

const formatLencoCSV = (tests, labData) => {
  const formattedTests = tests.map((test) => {
    const demographics =
      typeof test.employee_demographics === 'object'
        ? test.employee_demographics
        : JSON.parse(test.employee_demographics);
    const obj = {
      Provider_ID: 33133,
      patientFirst: demographics?.firstName,
      patientLast: demographics?.lastName,
      patientDOB: demographics?.dob?.includes('-')
        ? parseDateOfBirthLenco(demographics?.dob)
        : `${demographics?.dob?.substring(0, 2)}-${demographics?.dob?.substring(2, 4)}-${demographics?.dob?.substring(
            4,
          )}`,
      patientGender: demographics.sex === 'M' ? 'male' : 'female',
      address1: demographics.street,
      addressCity: demographics.city,
      addressState: demographics.state,
      addressZip: demographics.zip.substring(0, 5),
      emailAddress: '',
      patientPhone: test.phoneNumber,
      Accession: test.barcode,
      collection_date: `${test.createdAt.substring(5, 7)}-${test.createdAt.substring(8, 10)}-${test.createdAt.substring(
        0,
        4,
      )}`,
    };
    if (labData.sendInsurance) {
      Object.assign(obj, {
        insurance_name: demographics.insurance_name,
        insurance_number: demographics.insurance_number,
      });
    }
    return obj;
  });
  return jsonToCSV(formattedTests);
};

const formatAllianceCSV = (tests, labData) => {
  const formattedTests = tests.map((test) => {
    const demographics =
      typeof test.employee_demographics === 'object'
        ? test.employee_demographics
        : JSON.parse(test.employee_demographics);
    const obj = {
      client_id: 3211,
      barcode: test.barcode,
      date_created: `${test.createdAt.substring(5, 7)}/${test.createdAt.substring(8, 10)}/${test.createdAt.substring(
        0,
        4,
      )}`,
      first_name: demographics.firstName,
      last_name: demographics.lastName,
      dob: demographics.dob.includes('-')
        ? parseDateOfBirth(demographics.dob)
        : `${demographics.dob.substring(0, 2)}/${demographics.dob.substring(2, 4)}/${demographics.dob.substring(4)}`,
      sex: demographics.sex,
      address_street: demographics.street,
      address_city: demographics.city,
      address_state: demographics.state,
      address_zip: demographics.zip,
      emailAddress: '',
      patientPhone: test.phoneNumber,
      reference_id: test.referenceID,
      result: '',
    };
    if (labData.sendInsurance) {
      Object.assign(obj, {
        insurance_name: demographics.insurance_name,
        insurance_number: demographics.insurance_number,
      });
    }
    return obj;
  });
  return jsonToCSV(formattedTests);
};

const formatBioBillingCSV = async (tests, labData) => {
  //
  // const filteredTests = tests.filter((f) => {
  //   const demo = parseJSONObject(f.employee_demographics);
  //   return (
  //     demo?.insurance_name?.toLowerCase()?.includes("medicare") ||
  //     demo?.insurance_name?.toLowerCase()?.includes("medicaid")
  //   );
  // });

  const filteredTests = tests;

  if (filteredTests.length === 0) return [];

  const uniqueSites = [...new Set(filteredTests.map((test) => test.siteID))];

  const siteToProviderMap = {};

  if (uniqueSites.length > 0) {
    const promiseArray = uniqueSites.map(async (siteID) => {
      const defaultProvider = await getDefaultProvider(siteID);
      siteToProviderMap[siteID] = defaultProvider;
    });

    await Promise.allSettled(promiseArray);
  }
  const formattedTests = filteredTests.map((test) => {
    const demographics =
      typeof test.employee_demographics === 'object'
        ? test.employee_demographics
        : JSON.parse(test.employee_demographics);

    const defaultProvider = siteToProviderMap[test.siteID];
    let client_id = 7137; // kei client id for lab gen
    const obj = {
      client_id: client_id,
      accession_no: test.barcode,
      date_created: toISOLocal(new Date(test.createdAt)),
      first_name: demographics.firstName,
      last_name: demographics.lastName,
      dob: demographics.dob.includes('-')
        ? parseDateOfBirth(demographics.dob)
        : `${demographics.dob.substring(0, 2)}/${demographics.dob.substring(2, 4)}/${demographics.dob.substring(4)}`,
      sex: demographics.sex,
      address_street: demographics.street,
      address_city: demographics.city,
      address_state: demographics.state,
      address_zip: demographics.zip,
      result: '',
      dos: moment(test.createdAt).format('MMDDYY'),
      insurance_name: demographics.insurance_name,
      insurance_number: demographics.insurance_number,
      payer_id: demographics.insuranceCompanyCode,
      ref_phy: defaultProvider?.name || '',
      ref_npi: defaultProvider?.npi || '',
      ict_code: 'Z20.822',
      eligibility: demographics?.isEligible ? 'Y' : 'N',
    };
    if (labData?.sendInsurance) {
      Object.assign(obj, {
        insurance_name: demographics.insurance_name,
        insurance_number: demographics.insurance_number,
      });
    }
    return obj;
  });

  return formattedTests;
};

const formatBiolabGenCSV = async (tests, labData) => {
  const uniqueSites = [...new Set(tests.map((test) => test.siteID))];

  const siteToProviderMap = {};

  if (uniqueSites.length > 0) {
    const promiseArray = uniqueSites.map(async (siteID) => {
      let models = await DataStore.query(Site, (site) => site.id('eq', siteID));
      siteToProviderMap[siteID] = models[0];
    });

    await Promise.allSettled(promiseArray);
  }

  const formattedTests = tests.map((test) => {
    const demographics =
      typeof test.employee_demographics === 'object'
        ? test.employee_demographics
        : JSON.parse(test.employee_demographics);

    let siteRecord = siteToProviderMap[test.siteID];
    let client_id = siteRecord?.practiceID || 3739; // kei client id for lab gen
    const obj = {
      client_id: client_id,
      barcode: test.barcode,
      date_created: toISOLocal(new Date(test.createdAt)),
      first_name: demographics.firstName,
      last_name: demographics.lastName,
      dob: demographics.dob.includes('-')
        ? parseDateOfBirth(demographics.dob)
        : `${demographics.dob.substring(0, 2)}/${demographics.dob.substring(2, 4)}/${demographics.dob.substring(4)}`,
      sex: demographics.sex,
      address_street: demographics.street,
      address_city: demographics.city,
      address_state: demographics.state,
      address_zip: demographics.zip,
      emailAddress: '',
      patientPhone: '',
      reference_id: test.barcode,
      result: '',
      phy_npi: '1639125487',
      phy_first_name: 'LISA',
      phy_last_name: 'MA',
      sample_type: demographics?.swabtype || 'oral',
    };
    if (labData?.sendInsurance) {
      Object.assign(obj, {
        insurance_name: demographics.insurance_name,
        insurance_number: demographics.insurance_number,
      });
    }
    return obj;
  });

  // Stop this function calling as move the process into lambda
  // const testPushed = await pushTestOnBucket(new Date(tests[0].createdAt), formattedTests, LAB_SFTP_BUCKET.BIO_LAB);

  return { testPushed: true, data: formattedTests };
};

const pushTestOnBucket = async (batch, tests, bucket_name) => {
  if (CONFIG.isLive && CONFIG.clientID === '456ba480-b8dd-47ea-a07a-940693f686ea') {
    try {
      await Storage.put(`${batch}.csv`, jsonToCSV(tests), { bucket: bucket_name });
      return true;
    } catch (e) {
      console.log('Error uploading to bucket', e);
      return 'Error uploading to bucket';
    }
  }
  return false;
};

const formatBiolabCSV = (tests, labData) => {
  console.log('Test', tests);
  const formattedTests = tests.map((test) => {
    const demographics =
      typeof test.employee_demographics === 'object'
        ? test.employee_demographics
        : JSON.parse(test.employee_demographics);
    const obj = {
      client_id: 3739,
      barcode: test.barcode,
      date_created: toISOLocal(new Date(test.createdAt)),
      first_name: demographics.firstName,
      last_name: demographics.lastName,
      dob: demographics.dob.includes('-')
        ? parseDateOfBirth(demographics.dob)
        : `${demographics.dob.substring(0, 2)}/${demographics.dob.substring(2, 4)}/${demographics.dob.substring(4)}`,
      sex: demographics.sex,
      address_street: demographics.street,
      address_city: demographics.city,
      address_state: demographics.state,
      address_zip: demographics.zip,
      emailAddress: '',
      patientPhone: test.phoneNumber,
      reference_id: test.referenceID,
      result: '',
    };
    if (labData?.sendInsurance) {
      Object.assign(obj, {
        insurance_name: demographics.insurance_name,
        insurance_number: demographics.insurance_number,
      });
    }
    return obj;
  });
  return jsonToCSV(formattedTests);
};

const formatSunriseCSV = (tests, labData) => {
  const formattedTests = tests.map((test) => {
    const demographics =
      typeof test.employee_demographics === 'object'
        ? test.employee_demographics
        : JSON.parse(test.employee_demographics);
    const obj = {
      client_id: 36,
      barcode: test.barcode,
      date_created: toISOLocal(new Date(test.createdAt)),
      first_name: demographics.firstName,
      last_name: demographics.lastName,
      dob: demographics?.dob?.includes('-')
        ? parseDateOfBirth(demographics.dob)
        : `${demographics?.dob?.substring(0, 2)}/${demographics?.dob?.substring(2, 4)}/${demographics?.dob?.substring(
            4,
          )}`,
      sex: demographics.sex,
      address_street: demographics.street,
      address_city: demographics.city,
      address_state: demographics.state,
      address_zip: demographics.zip,
      emailAddress: '',
      patientPhone: test.phoneNumber,
      reference_id: test.referenceID,
      result: '',
    };
    if (labData.sendInsurance) {
      Object.assign(obj, {
        insurance_name: demographics.insurance_name,
        insurance_number: demographics.insurance_number,
      });
    }
    return obj;
  });
  return jsonToCSV(formattedTests);
};

const formatAltaCSV = (tests, labData) => {
  const formattedTests = tests.map((test) => {
    const demographics =
      typeof test.employee_demographics === 'object'
        ? test.employee_demographics
        : JSON.parse(test.employee_demographics);
    const obj = {
      client_id: 1,
      barcode: test.barcode,
      date_created: toISOLocal(new Date(test.createdAt)),
      first_name: demographics.firstName,
      last_name: demographics.lastName,
      dob: demographics.dob.includes('-')
        ? parseDateOfBirth(demographics.dob)
        : `${demographics.dob.substring(0, 2)}/${demographics.dob.substring(2, 4)}/${demographics.dob.substring(4)}`,
      sex: demographics.sex,
      address_street: demographics.street,
      address_city: demographics.city,
      address_state: demographics.state,
      address_zip: demographics.zip,
      emailAddress: '',
      patientPhone: test.phoneNumber,
      reference_id: test.referenceID,
      result: '',
    };
    if (labData.sendInsurance) {
      Object.assign(obj, {
        insurance_name: demographics.insurance_name,
        insurance_number: demographics.insurance_number,
      });
    }
    return obj;
  });
  return jsonToCSV(formattedTests);
};

const formatLifebriteCSV = (tests, labData) => {
  const formattedTests = tests.map((test) => {
    const demographics =
      typeof test.employee_demographics === 'object'
        ? test.employee_demographics
        : JSON.parse(test.employee_demographics);
    const obj = {
      barcode: test.barcode,
      date_created: toISOLocal(new Date(test.createdAt)).split('T')[0],
      first_name: demographics.firstName,
      last_name: demographics.lastName,
      dob: demographics.dob.includes('-')
        ? parseDateOfBirth(demographics.dob)
        : `${demographics.dob.substring(0, 2)}/${demographics.dob.substring(2, 4)}/${demographics.dob.substring(4)}`,
      sex: demographics.sex,
      address_street: demographics.street,
      address_city: demographics.city,
      address_state: demographics.state,
      address_zip: demographics.zip,
      emailAddress: '',
      patientPhone: test.phoneNumber,
      reference_id: test.referenceID,
      insurance_name: demographics.insurance_name,
      insurance_number: demographics.insurance_number,
      test_code: 1,
      result: '',
    };
    if (labData.sendInsurance) {
      Object.assign(obj, {
        insurance_name: demographics.insurance_name,
        insurance_number: demographics.insurance_number,
      });
    }
    return obj;
  });
  return jsonToCSV(formattedTests);
};

const formatDLSCSV = (tests) => {
  const formattedTests = tests.map((test) => {
    const demographics =
      typeof test.employee_demographics === 'object'
        ? test.employee_demographics
        : JSON.parse(test.employee_demographics);
    return {
      PatientFirstName: demographics.firstName,
      PatientMiddleName: '',
      PatientLastName: demographics.lastName,
      patientDOB: demographics.dob.includes('-')
        ? parseDateOfBirthLenco(demographics.dob)
        : `${demographics.dob.substring(0, 2)}-${demographics.dob.substring(2, 4)}-${demographics.dob.substring(4)}`,
      barcode: test.barcode,
      date_created: test.createdAt.toString(),
      PatientGender: demographics.sex,
      Address1: demographics.street,
      Address2: '',
      AddressCity: demographics.city,
      AddressState: demographics.state,
      AddressZip: demographics.zip,
      EmailAddress: '',
      PatientPhone: test.phoneNumber,
      UniqueID: test.id,
      AccessionOrderID: test.barcode,
      TestkitID: '',
      Covid19TestCode: '',
      SpecimenSource: 'OP',
      CollectionDateTime: '',
      OrderingPhysician: '',
      Race: 'UNK: Unknown',
      Ethnicity: 'UNK: Unknown',
      FirstCovidTest: '',
      HealthCareWorker: '',
      CovidSymptomatic: '',
      SymptomOnsetDate: '',
      CovidHospitalized: '',
      InICU: '',
      CongregateCareSetting: '',
      Pregnant: '',
      result: '',
    };
  });
  return jsonToCSV(formattedTests);
};

const formatNewDiscoveryCSV = (tests) => {
  const formattedTests = tests.map((test) => {
    const demographics =
      typeof test.employee_demographics === 'object'
        ? test.employee_demographics
        : JSON.parse(test.employee_demographics);
    return {
      barcode: test.barcode,
      date_created: test.createdAt,
      first_name: demographics.firstName,
      last_name: demographics.lastName,
      dob: demographics.dob.includes('-')
        ? parseDateOfBirth(demographics.dob)
        : `${demographics.dob.substring(0, 2)}/${demographics.dob.substring(2, 4)}/${demographics.dob.substring(4)}`,
      sex: demographics.sex,
      address_street: demographics.street,
      address_city: demographics.city,
      address_state: demographics.state,
      address_zip: demographics.zip,
      emailAddress: '',
      patientPhone: test.phoneNumber,
      reference_id: test.referenceID,
      result: '',
    };
  });
  return jsonToCSV(formattedTests);
};

const formatOxygenCSV = (tests) => {
  const formattedTests = tests.map((test) => {
    const demographics =
      typeof test.employee_demographics === 'object'
        ? test.employee_demographics
        : JSON.parse(test.employee_demographics);
    return {
      accession_number: test.barcode,
      requisition_number: test.referenceID,
      date: new Date(test.createdAt).toString().substring(0, 15),
      time: new Date(test.createdAt).toString().substring(16),
      clinic_id: '52a3c04d-2b81-43c0-8c9d-5a5ae8f067fc',
      first_name: demographics.firstName,
      last_name: demographics.lastName,
      patient_dob: demographics.dob.includes('-')
        ? parseDateOfBirth(demographics.dob)
        : `${demographics.dob.substring(0, 2)}/${demographics.dob.substring(2, 4)}/${demographics.dob.substring(4)}`,
      patient_gender: demographics.sex,
      patient_phone: test.phoneNumber,
      patient_email: '',
      patient_ethnicity_code: 'U',
      patient_race_code: 'U',
      provider_first_name: '',
      provider_last_name: '',
      provider_npi: '1111111111',
      provider_phone_number: '',
      specimen_type: 'OS',
      panel_type: 'COV  (COVID-19 (2019 Coronavirus (SARS-CoV-2)))',
      result: '',
    };
  });
  return jsonToCSV(formattedTests);
};

const getCurrentLabUserTests = async () => {
  const user = await getCurrentUser();

  let lab = await DataStore.query(Lab, (lab) => lab.user_id('eq', user.sub));
  if (lab.length === 0) {
    const labs = await DataStore.query(Lab);
    lab = labs.filter((l) => {
      return l.lab_contacts && l.lab_contacts.includes(user.sub);
    });
    if (lab.length === 0) return [];
  }

  if (lab[0].default_antigen || lab[0].default_molecular) {
    const models = await DataStore.query(
      Test,
      (test) =>
        test
          .labID('eq', lab[0].id)
          .test_type('eq', 'PCR')
          .or((test) =>
            test.status('eq', 'Processing').status('eq', 'Pending').status('eq', 'Sent'),
          ),
      {
        sort: (test) => test.createdAt(SortDirection.DESCENDING),
      },
    );

    return models.filter((t) => !t.result);
  }

  const models = await DataStore.query(
    Test,
    (test) =>
      test
        .labID('eq', lab[0].id)
        .test_type('eq', 'PCR')
        .or((test) => test.status('eq', 'Processing').status('eq', 'Pending').status('eq', 'Sent')),
    {
      sort: (test) => test.createdAt(SortDirection.DESCENDING),
    },
  );

  return models.filter((t) => !t.result);

  return models;
};

const getAllCurrentLabUserTests = async () => {
  const user = await getCurrentUser();
  let lab = await DataStore.query(Lab, (lab) => lab.user_id('eq', user.sub));
  if (lab.length === 0) {
    const labs = await DataStore.query(Lab);
    lab = labs.filter((l) => {
      return l.lab_contacts && l.lab_contacts.includes(user.sub);
    });
    if (lab.length === 0) return [];
  }

  const models = await DataStore.query(Test, (test) => test.labID('eq', lab[0].id), {
    sort: (test) => test.createdAt(SortDirection.DESCENDING),
  });
  return models.filter((model) => model.result);
};

const getCurrentLabUserProcessedTests = async (id, user) => {
  let labID = id;
  if (!labID) {
    const lab = await DataStore.query(Lab, (lab) => lab.user_id('eq', user.sub));
    if (lab.length > 0) {
      labID = lab[0].id;
    } else {
      const labs = await DataStore.query(Lab);
      const filterLab = labs.filter((l) => {
        return l.lab_contacts && l.lab_contacts.includes(user.sub);
      });
      if (filterLab.length > 0) {
        labID = filterLab[0].id;
      }
    }
  }

  if (labID === null) return [];

  let currentPage = 0;
  let fetchData = true;
  const models = await DataStore.query(
    Test,
    (test) =>
      test
        .labID('eq', labID)
        .result('ne', null)
        .test_type('eq', 'PCR')
        .or((test) => test.status('eq', 'Processed').status('eq', 'Pending')),
    {
      sort: (test) => test.createdAt(SortDirection.DESCENDING),
    },
  );

  const tests = models.map((test) => {
    const obj = formatTest(test);
    obj.status = TEST_STATUS.Processed;
    return obj;
  });

  return tests;
};
const getPendingReleaseTests = async () => {
  const models = await DataStore.query(
    Test,
    (test) =>
      test.status('eq', 'Pending').or((t) => t.result('eq', 'Positive').result('eq', 'positive')),
    {
      sort: (test) => test.updatedAt(SortDirection.DESCENDING),
    },
  );

  return models;
};

const sendToLab = async (tests = []) => {
  tests.forEach(async (test) => {
    await DataStore.save(
      Test.copyOf(test, (updated) => {
        updated.status = 'Sent';
      }),
    );
  });
};

const findTestByID = async (id) => {
  const models = await DataStore.query(Test, (test) => test.id('eq', id));
  // console.log("findTestByID", models);
  return models[0];
};

const findTestByBarcode = async (id) => {
  const models = await DataStore.query(Test, (test) => test.barcode('eq', id));
  if (models.length > 0) return models[0];

  const byId = await DataStore.query(Test, (test) => test.id('eq', id));
  if (byId.length > 0) return byId[0];

  return null;
};
const findExternalTestByID = async (testID) => {
  const models = await DataStore.query(ExternalTest, (test) => test.id('eq', testID));
  console.log(models);
  return models[0];
};

const sortModelData = (models) => models?.sort((a, b) => a?.name?.localeCompare(b?.name));

const getTestTypes = async (category) => {
  // Define the base condition to filter by clientID and exclude "panel" type
  let condition = (test) => test.clientID('eq', CONFIG.clientID).isArchive('eq', false);

  // Update the condition if a category is specified
  if (category) {
    condition = (test) =>
      test.clientID('eq', CONFIG.clientID).category('eq', category).isArchive('eq', false);
  }

  // Query the DataStore with the defined condition
  const models = await DataStore.query(TestTypes, condition, {
    sort: (t) => t.name(SortDirection.ASCENDING),
  });

  //await DataStore.delete(TestTypes, (test) => test.id("eq", '880ea165-6c13-4c48-9983-be476143a295'));

  // for (let i = 0; i < models.length; i++) {
  //   console.log('models[i].isActive',models[i].isActive);

  //   // await DataStore.save(
  //   //   TestTypes.copyOf(models[i], (updated) => {
  //   //     updated.isArchive = false;
  //   //   })
  //   // );
  //   // if (!models[i].isActive) {
  //   //   await DataStore.save(
  //   //     TestTypes.copyOf(models[i], (updated) => {
  //   //       updated.isArchive = true;
  //   //     })
  //   //   );
  //   // }
  // }

  // Return the filtered models
  return sortModelData(models);
};

const getPanels = async () => {
  let condition = (test) => test.clientID('eq', CONFIG.clientID).type('eq', 'panel');

  const models = await DataStore.query(TestTypes, condition, {
    sort: (t) => t.name(SortDirection.ASCENDING),
  });
  // for (let i = 0; i < models.length; i++) {
  //   await DataStore.delete(TestTypes, (test) => test.id("eq", models[i].id));
  // }
  return sortModelData(models);
};

const getPanelsAndTests = async () => {
  let condition = (test) => test.clientID('eq', CONFIG.clientID);

  const models = await DataStore.query(TestTypes, condition, {
    sort: (t) => t.name(SortDirection.ASCENDING),
  });

  return sortModelData(models);
};
const getTestTypesOptions = async () => {
  let models = await DataStore.query(
    TestTypes,
    (test) =>
      test
        .clientID('eq', CONFIG.clientID)
        .and((c) => c.isArchive('eq', false).isActive('eq', true)), // Filter by isActive
    {
      sort: (t) => t.name(SortDirection.ASCENDING),
    },
  );

  if (models.length === 0) {
    models = await DataStore.query(
      TestTypes,
      (test) => test.clientID('eq', null).and((c) => c.isArchive('eq', false)), // Filter by isActive
      {
        sort: (t) => t.name(SortDirection.ASCENDING),
      },
    );
  }

  return sortModelData(models).map((e) => ({
    code: e.code,
    isSelected: false,
    clinicalcomment: '',
    sources: e.sources,
    cptCodes: e.cptCodes,
    ictCodes: e.ictCodes,
    label: e.name,
    value: e.name,
    id: e.id,
  }));
};

const getTestTypesForFilter = async () => {
  let models = await DataStore.query(
    TestTypes,
    (test) =>
      test
        .clientID('eq', CONFIG.clientID)
        .and((c) => c.isArchive('eq', false).isActive('eq', true)), // Filter by isActive
    {
      sort: (t) => t.name(SortDirection.ASCENDING),
    },
  );
  return sortModelData(models).map((e) => ({
    code: e.code,
    isSelected: false,
    clinicalcomment: '',
    sources: e.sources,
    cptCodes: e.cptCodes,
    ictCodes: e.ictCodes,
    label: e.name,
    value: e.id,
    id: e.id,
  }));
};
const getTestTypesByName = async (name) => {
  const models = await DataStore.query(TestTypes, (s) => s.name('eq', name), {
    sort: (t) => t.name(SortDirection.ASCENDING),
  });
  if (models.length > 0) {
    return models[0].sources;
  }
  return [];
};

const getTestTypesByID = async (id) => {
  const models = await DataStore.query(TestTypes, (s) => s.id('eq', id), {
    sort: (t) => t.name(SortDirection.ASCENDING),
  });
  if (models.length > 0) {
    return models[0];
  }
  return [];
};

const addTestTypes = async (obj) => {
  return await DataStore.save(
    new TestTypes({
      code: obj.code,
      name: obj.name,
      typeOfTest: obj.typeOfTest,
      category: obj.category,
      clientID: CONFIG.clientID,
      sources: obj.sources,
      cptCodes: obj.cptCodes,
      ictCodes: obj.ictCodes,
      targetRanges: obj.targetRanges,
      custom_notes: obj.custom_notes,
      units: obj.units,
      refInterval: obj.refInterval,
      minValue: obj.minValue,
      maxValue: obj.maxValue,
      setting: obj.setting,
      lonicCode: obj.lonicCode,
      isActive: obj.isActive,
      isArchive: false,
      isAssociatedPanel: obj?.isAssociatedPanel || false,
      isARKStone: obj?.isARKStone || false,
      isPanelOnly: obj?.isPanelOnly || false,
      location: obj.location,
      type: obj?.type || '',
      ranges: obj?.ranges || null,
    }),
  );
};

const updateTestTypes = async (obj) => {
  const items = await DataStore.query(TestTypes, (t) => t.id('eq', obj.id));
  return await DataStore.save(
    TestTypes.copyOf(items[0], (updated) => {
      updated.totalTime = obj.totalTime;
      updated.firstAlert = obj.firstAlert;
      updated.secondAlert = obj.secondAlert;
      updated.duration = obj.duration ? parseInt(obj.duration) : 0;
      updated.code = obj.code;
      updated.name = obj.name;
      updated.category = obj.category;
      updated.typeOfTest = obj.typeOfTest;
      updated.sources = obj.sources;
      updated.cptCodes = obj.cptCodes;
      updated.ictCodes = obj.ictCodes;
      updated.targetRanges = obj.targetRanges;
      updated.custom_notes = obj.custom_notes;
      updated.units = obj.units;
      updated.refInterval = obj.refInterval;
      updated.minValue = obj.minValue;
      updated.maxValue = obj.maxValue;
      updated.setting = obj.setting;
      updated.lonicCode = obj.lonicCode;
      updated.isActive = obj.isActive;
      updated.isPanelOnly = obj.isPanelOnly || false;
      updated.location = obj.location;
      updated.ranges = obj.ranges;
      (updated.isArchive = false), (updated['isAssociatedPanel'] = obj?.isAssociatedPanel || false);
      updated['isARKStone'] = obj?.isARKStone || false;
      updated['type'] = obj.type || '';
    }),
  );
};

const archiveTestType = async (obj) => {
  const items = await DataStore.query(TestTypes, (t) => t.id('eq', obj.id));
  return await DataStore.save(
    TestTypes.copyOf(items[0], (updated) => {
      updated.isActive = obj.isActive;
      updated.isArchive = obj.isArchive;
    }),
  );
};

const updateTestPathegon = async (obj) => {
  const items = await DataStore.query(TestTypes, (t) => t.id('eq', obj.id));
  return await DataStore.save(
    TestTypes.copyOf(items[0], (updated) => {
      updated.targetRanges = obj.targetRanges;
    }),
  );
};

const updateTestFalsePositive = async () => {
  const items = await DataStore.query(Test, (t) => t.sequenceNo('eq', 22923));
  if (items.length > 0) {
    return await DataStore.save(
      Test.copyOf(items[0], (updated) => {
        updated.isFalsePositive = false;
      }),
    );
  }
};

const findTestsByClient = async (clientID) => {
  const models = await DataStore.query(Test, (test) =>
    test.clientID('eq', clientID).result('ne', 'Expired'),
  );
  // console.log(models);
  return models;
};

const findTestsBySite = async (siteID) => {
  const models = await DataStore.query(Test, (test) =>
    test.siteID('eq', siteID).result('ne', 'Expired'),
  );
  return models;
};

const findTestsByLab = async (labID) => {
  const models = await DataStore.query(Test, (test) =>
    test.labID('eq', labID).result('ne', 'Expired'),
  );
  return models;
};

const releaseTest = async (test) => {
  const model = await findTestByID(test.id);
  try {
    await DataStore.save(
      Test.copyOf(model, (updated) => {
        updated.status = 'Processed';
      }),
    );
    return true;
  } catch (err) {
    return false;
  }
};

const setFalsePositiveTest = async (id) => {
  const model = await findTestByID(id);
  try {
    await DataStore.save(
      Test.copyOf(model, (updated) => {
        updated.status = 'Processed';
        updated.isFalsePositive = true;
      }),
    );
    return true;
  } catch (err) {
    return false;
  }
};

const changeBatchStatusToProcessing = async (tests) => {
  const processingTests = tests.filter((test) => test.status === 'Sent' || test.status === 'New');
  let ttl = processingTests.length;
  for (let i = 0; i < ttl; i++) {
    const test = processingTests[i];
    const model = await findTestByID(test.id);
    await DataStore.save(
      Test.copyOf(model, (updated) => {
        updated.status = 'Processing';
      }),
    );
  }
};

const fetchAllUsersInGroup = async (groupName) => {
  const path = '/listUsersInGroup';
  let NextToken = null;
  let users = [];
  try {
    do {
      const myInit = {
        queryStringParameters: {
          groupname: groupName,
          limit: 60,
          token: NextToken,
        },
        headers: {
          'Content-Type': 'application/json',
          Authorization: `${(await Auth.currentSession()).getAccessToken().getJwtToken()}`,
        },
      };
      const models = await API.get(apiName, path, myInit);
      NextToken = models.NextToken || null;
      users = users.concat(models.Users);
    } while (NextToken);
    return users;
  } catch (e) {
    console.log('Error getting users:', e);
  }
};
const restoreTests = async (ids) => {
  console.log('[restoreTests]');
  let path = '/restoreTests';
  let params = {
    body: {
      ids: ids,
    },
    headers: {
      'Content-Type': 'application/json',
      Authorization: `${(await Auth.currentSession()).getAccessToken().getJwtToken()}`,
    },
  };
  const models = await API.post(apiName, path, params);
  return models.errorIds;
};

const fetchAllCrewMembers = async (groupName, NextToken) => {
  const path = '/listUsersInGroup';
  let users = [];
  const myInit = {
    queryStringParameters: {
      groupname: groupName,
      limit: 60,
      token: NextToken,
    },
    headers: {
      'Content-Type': 'application/json',
      Authorization: `${(await Auth.currentSession()).getAccessToken().getJwtToken()}`,
    },
  };
  const models = await API.get(apiName, path, myInit);
  return models;
};

const fetchAllEmployees = async (phone) => {
  const models = await DataStore.query(Employee, (test) => test.phone_number('eq', phone));
  return models;
};

const fetchUsersInGroup = async (groupName) => {
  const path = '/listUsersInGroup';
  try {
    const myInit = {
      queryStringParameters: {
        groupname: groupName,
        limit: 60,
      },
      headers: {
        'Content-Type': 'application/json',
        Authorization: `${(await Auth.currentSession()).getAccessToken().getJwtToken()}`,
      },
    };
    const models = await API.get(apiName, path, myInit);

    return models.Users;
  } catch (e) {
    console.log('Error getting users:', e);
  }
};

const fetchAllUsers = async (filter, NextToken) => {
  let path = '/listUsers';
  let params = {
    queryStringParameters: {
      limit: 60,
      filter: filter,
      token: NextToken,
    },
    headers: {
      'Content-Type': 'application/json',
      Authorization: `${(await Auth.currentSession()).getAccessToken().getJwtToken()}`,
    },
  };
  const models = await API.get(apiName, path, params);
  return models;
};

const changeUserRole = async (prevRole, newRole, username) => {
  console.log(prevRole, newRole, username);
  let path = '/removeUserFromGroup';
  let prevRoleAlias;
  if (prevRole === 'Crew') {
    prevRoleAlias = 'Employee';
  } else if (prevRole === 'Health & Safety') {
    prevRoleAlias = 'Employer';
  } else {
    prevRoleAlias = prevRole;
  }
  let params = {
    body: {
      username: username,
      groupname: prevRoleAlias + 's',
    },
    headers: {
      'Content-Type': 'application/json',
      Authorization: `${(await Auth.currentSession()).getAccessToken().getJwtToken()}`,
    },
  };

  try {
    const roleRemoval = await API.post(apiName, path, params);
    path = '/addUserToGroup';
    params.body['groupname'] = newRole + 's';
    const roleChange = await API.post(apiName, path, params);
    // console.log("Changing role", roleRemoval, roleChange);
  } catch (e) {
    console.log('Error changing user role:', e);
    alert('Unable to change user role');
  }
};

const addUserToGroup = async (newRole, username) => {
  let params = {
    body: {
      username: username,
      groupname: newRole + 's',
    },
    headers: {
      'Content-Type': 'application/json',
      Authorization: `${(await Auth.currentSession()).getAccessToken().getJwtToken()}`,
    },
  };

  try {
    const path = '/addUserToGroup';
    const roleChange = await API.post(apiName, path, params);
  } catch (e) {
    console.log('Error changing user role:', e);
    alert('Unable to Add user role');
  }
};

const confirmUserSignUp = async (username) => {
  console.log(username);
  const path = '/confirmUserSignUp';
  try {
    const params = {
      body: {
        username: username,
      },
      headers: {
        'Content-Type': 'application/json',
        Authorization: `${(await Auth.currentSession()).getAccessToken().getJwtToken()}`,
      },
    };
    const done = await API.post(apiName, path, params);
  } catch (e) {
    console.log('Error changing user role:', e);
    alert('Fail to confirm user');
  }
};

const updateUserInfo = async (user) => {
  console.log('[update_info]', user);
  const path = '/updateUserInfo';
  let note = '';
  let connectedID = '';
  if (user.show) {
    note = user.show.label;
    connectedID = user.show.id;
  } else if (user.lab) {
    note = user.lab.label;
    connectedID = user.lab.id;
  } else if (user.client) {
    note = user.client.label;
  } else if (user.role === 'Admin' || user.role === 'Tester') note = 'System';
  else note = '';

  try {
    const infoUpdated = await callAmplifyAPI(path, {
      username: user.username,
      email: user.email,
      firstName: user.firstName,
      lastName: user.lastName,
      note: note,
      clientID: user.client ? user.client.id : '',
      connectedID: connectedID,
    });
    console.log('[update_info]', infoUpdated);
  } catch (e) {
    console.log('Error changing user role:', e);
    alert('Unable to update user info');
  }
};

const updateUserInfoV1 = async (user, rolesData, roles) => {
  const path = '/updateUserInfo';
  let note = '';
  let noteArr = [];
  let labID = user?.labID;
  let labPrivilege = user?.labPrivilege;

  if (rolesData.includes(HEALTH_AND_SAFTEY) && user.show) noteArr.push(user.show.label);
  if (rolesData.includes('Lab') && user.lab) noteArr.push(user.lab.label);
  if (rolesData.includes('Client') && user.client) noteArr.push(user.client.label);
  if (rolesData.includes('Admin') || rolesData.includes('Tester')) noteArr.push('System');

  if (noteArr.length > 0) {
    if (noteArr.length == '1') note = noteArr[0];
    else note = noteArr.join(',');
  }

  let connectedID = '';
  if (user.role === USER_ROLE_TYPE.Lab || user.role === USER_ROLE_TYPE.LabTech) {
    connectedID = user.lab.id;
  } else if (user.role === HEALTH_AND_SAFTEY) {
    connectedID = user.show.id;
  }
  if (user.lab && user.lab.appPackage) {
    labPrivilege = 'whiteLabelUser';
    labID = user.lab.appPackage;
  }

  if (labID && labID !== undefined && !labPrivilege) {
    labPrivilege = 'whiteLabelUser';
  }

  if (labID && !rolesData.includes('Lab') && !rolesData.includes('Tester')) {
    labID = '';
    labPrivilege = '';
  }

  let clientID = user.client ? user.client.id : '';

  if (CONFIG.isWhiteLabel) {
    connectedID = CONFIG.clientID;
    labID = CONFIG.whiteLabelID;
    clientID = CONFIG.clientID;
  }

  try {
    const infoUpdated = await callAmplifyAPI(path, {
      username: user.username || user.userID,
      email: user.email,
      firstName: user.firstName,
      lastName: user.lastName,
      note: user.associated || note,
      clientID: clientID,
      connectedID: connectedID,
      roles: roles,
      labPrivilege,
      labID,
      phone_number: user.phone_number,
      userRole: `${user.role === HEALTH_AND_SAFTEY ? 'Employer' : user.role}`,
      status: user?.status || 'Confirmed',
      systemUser: user.systemUser,
      is2FaEnabled: user?.is2FaEnabled ? 'y' : 'n',
      userPermission: JSON.stringify(user.userPermission),
    });
    return true;
  } catch (e) {
    console.log('Error changing user role:', e);
    alert('Unable to update user info');
  }
  return false;
};

const updateUserInfoV2 = async (user) => {
  const path = '/updateUserInfo';
  let labID = user?.lab?.id;
  let labPrivilege = user?.labPrivilege;

  let connectedID = '';
  if (user.role === USER_ROLE_TYPE.Lab || user.role === USER_ROLE_TYPE.LabTech) {
    connectedID = user.lab.id;
  } else if (user.role === HEALTH_AND_SAFTEY) {
    connectedID = user.show.id;
  }
  if (user.lab && user.lab.appPackage) {
    labPrivilege = 'whiteLabelUser';
  }

  if (labID && labID !== undefined && !labPrivilege) {
    labPrivilege = 'whiteLabelUser';
  }

  if (!labID) {
    labID = '';
    labPrivilege = '';
  }

  try {
    const infoUpdated = await callAmplifyAPI(path, {
      username: user.username || user.userID,
      email: user.email,
      firstName: user.firstName,
      lastName: user.lastName,
      note: user.note,
      clientID: user.client ? user.client.id : '',
      connectedID: connectedID,
      roles: user.roles,
      labPrivilege,
      labID,
      phone_number: user.phone_number,
      userRole: `${user.role === HEALTH_AND_SAFTEY ? 'Employer' : user.role}`,
      status: user?.status || 'Confirmed',
      systemUser: user.systemUser,
    });
    return true;
  } catch (e) {
    console.log('Error changing user role:', e);
    alert('Unable to update user info');
  }
  return false;
};

const updateUserSecondRole = async (user) => {
  console.log('[updateUserSecondRole]', user);
  const path = '/updateUserSecondRole';

  const params = {
    body: {
      username: user.username,
      role: user.adRole,
      connectedID: user.connectedID,
    },
    headers: {
      'Content-Type': 'application/json',
      Authorization: `${(await Auth.currentSession()).getAccessToken().getJwtToken()}`,
    },
  };

  try {
    const infoUpdated = await API.post(apiName, path, params);
    console.log('[update_info]', infoUpdated);
  } catch (e) {
    console.log('Error changing user role:', e);
    alert('Unable to update user info');
  }
};

const updateAssociatedInfo = async (username, note) => {
  console.log('[update_info]', username, note);
  const path = '/updateAssociatedInfo';

  const params = {
    body: {
      username: username,
      note: note,
    },
    headers: {
      'Content-Type': 'application/json',
      Authorization: `${(await Auth.currentSession()).getAccessToken().getJwtToken()}`,
    },
  };

  try {
    const infoUpdated = await API.post(apiName, path, params);
  } catch (e) {
    console.log('Error changing user role:', e);
  }
};

const updateUserPassword = async (username, password) => {
  console.log('[update_user_password_info]', username, password);
  const path = '/updateUserPassword';
  const params = {
    body: {
      username: username,
      password: password,
    },
    headers: {
      'Content-Type': 'application/json',
      Authorization: `${(await Auth.currentSession()).getAccessToken().getJwtToken()}`,
    },
  };

  const infoUpdated = await API.post(apiName, path, params);
  console.log('[update_user_password_info]', infoUpdated);
};

const updatePatientPassword = async (username) => {
  const path = '/resetUserPassword';
  const params = {
    body: {
      id: username,
    },
    headers: {
      'Content-Type': 'application/json',
      Authorization: `${(await Auth.currentSession()).getAccessToken().getJwtToken()}`,
    },
  };

  return await API.post(apiName, path, params);
};
const verifyOtp = async (otp) => {
  const path = '/verifyOtp';

  return await callAPIPost(path, { code: otp });
};

const resendOtp = async () => {
  const path = '/reSendOtp';

  return await callAPIPost(path, {});
};

const deleteUser = async (username, id) => {
  console.log('[delete_user_password_info]', username);
  const path = '/deleteUser';
  const params = {
    body: {
      username: username,
      id: id,
    },
    headers: {
      'Content-Type': 'application/json',
      Authorization: `${(await Auth.currentSession()).getAccessToken().getJwtToken()}`,
    },
  };

  try {
    await API.post(apiName, path, params);
  } catch (e) {
    console.log('Error changing user role:', e);
  }
};

const sendSMSNotification = async (phoneNumber, message) => {
  try {
    const notification = await axios.post(AWS_ENDPOINT + '/notification', {
      phone_number: parseInt(phoneNumber.replace(/\D/g, '')),
      message: message,
    });
    return notification;
  } catch (error) {
    console.log(error);
  }
};
const updateClientsHippa = async (id, newHippa, fileName) => {
  const client = await DataStore.query(Client, (client) => client.id('eq', id));
  await DataStore.save(
    Client.copyOf(client[0], (updated) => {
      updated.hippa = newHippa;
      updated.hippaFile = fileName || '';
    }),
  );
};
const updateSiteHippa = async (id, newHippa, fileName) => {
  const site = await DataStore.query(Site, (site) => site.id('eq', id));
  await DataStore.save(
    Site.copyOf(site[0], (updated) => {
      updated.hippa = newHippa;
      updated.hippaFile = fileName || '';
    }),
  );
};
const emptySiteUpdateStatus = async () => {
  const client = await DataStore.query(Client, (client) => client.id('eq', CONFIG.clientID));
  await DataStore.save(
    Client.copyOf(client[0], (updated) => {
      updated.uploadFileStatus = null;
    }),
  );
};

const updateClient = async (newClient) => {
  const client = await DataStore.query(Client, (client) => client.id('eq', newClient.id));
  await DataStore.save(
    Client.copyOf(client[0], (updated) => {
      updated.name = newClient.name;
      updated.contact_name = newClient.contact;
      updated.phone_number = newClient.phone;
      updated.fax_number = newClient.fax_number;
      updated.contact_email = newClient.email;
      updated.resultType = newClient.resultType;
      updated.showOnSummaryScreen = newClient.showOnSummaryScreen;
      updated.whiteLabel = newClient.whiteLabel;
      updated.whiteLabelPackage = newClient.whiteLabelPackage;
      updated.isArchive = newClient.isArchive;
      updated.street = newClient.street;
      updated.city = newClient.city;
      updated.state = newClient.state;
      updated.zipcode = newClient.zipcode;
      updated.setting = newClient?.setting || client?.setting;
      // updated.brmSetting = { appID : 'nz4ocewahrhpbfdnja6uethcvq', clientID: 'd813c694-ea26-4080-b34f-8686b9694ed3'}
    }),
  );
};

const updateProviderClient = async (pId, cId) => {
  await callAmplifyAPI('/providers/link', { id: pId, cid: cId });
  return;
};

const updateClientResultTypes = async (newClient) => {
  const client = await DataStore.query(Client, (client) => client.id('eq', newClient.id));
  await DataStore.save(
    Client.copyOf(client[0], (updated) => {
      updated.resultTypes = newClient.resultTypes;
    }),
  );
};

const updateSite = async (newSite, loginUser) => {
  const site = await DataStore.query(Site, (site) => site.id('eq', newSite.id));

  await DataStore.save(
    Site.copyOf(site[0], (updated) => {
      updated.name = newSite.name;
      updated.clientID = newSite.clientID;
      updated.contactName = newSite.contactName;
      updated.contact_name = newSite.contact || newSite.contact_name || null;
      updated.phone_number = newSite.phone || newSite.phone_number || null;
      updated.contact_phone = newSite.contact_phone || null;
      updated.email = newSite.email || null;
      updated.contact_email = newSite.contact_email || null;
      updated.antigen = newSite.antigen;
      updated.automateTestType = newSite.automateTestType;
      updated.insurance_required = newSite.insurance_required;
      updated.sendInsuranceCard = newSite.sendInsuranceCard;
      updated.resetTestDay = newSite.resetTestDay;
      updated.startDate = newSite.startDate;
      updated.endDate = newSite.endDate;
      updated.orderKitDate = newSite.orderKitDate
        ? moment(newSite.orderKitDate).toISOString()
        : null;
      (updated.orderKitType = newSite.orderKitType),
        (updated.enableShipment = newSite.enableShipment);
      updated.showOnSummaryScreen = newSite.showOnSummaryScreen;
      updated.messageTested = newSite.messageTested;
      updated.messageResulted = newSite.messageResulted;
      updated.messagePositive = newSite.messagePositive;
      updated.isArchive = newSite.isArchive;
      updated.isActive = newSite.isActive;
      updated.vaccinationCard = newSite.vaccinationCard;
      updated.admit = newSite.admit;
      updated.externalTest = newSite.externalTest;
      updated.externalTestAdmit = newSite.externalTestAdmit;
      updated.externalTestType = newSite.externalTestType;
      updated.patternTesting = newSite.patternTesting;
      updated.patternHippa = newSite.patternHippa;
      updated.preRegistration = newSite.preRegistration;
      updated.orderQuantity = newSite.orderQuantity;
      updated.messageReTest = newSite.messageReTest ? JSON.stringify(newSite.messageReTest) : null;
      updated.isHRPR = newSite.isHRPR;
      updated.safeCampAdmin = newSite.safeCampAdmin;
      updated.isPCRCustody = newSite.isPCRCustody;
      updated.sendIdentification = newSite.sendIdentification;
      updated.sendExternalRequisition = newSite.sendExternalRequisition;
      updated.sendPrescription = newSite.sendPrescription;
      updated.sendAuthorization = newSite.sendAuthorization;
      updated.sendOtherDocuments = newSite.sendOtherDocuments;
      updated.isAutoSubmitClaim = newSite.isAutoSubmitClaim;
      updated.isTesting = newSite.isTesting;
      updated.setting = newSite.setting;
      updated.practiceID = newSite.practiceID;
      updated.street = newSite.street;
      updated.city = newSite.city;
      updated.state = newSite.state;
      updated.zipcode = newSite.zipcode;
      updated.fax = newSite.fax || null;
      updated.contact_fax = newSite.contact_fax || null;
      updated.updatedByName = `${loginUser.firstName} ${loginUser.lastName}`;
      updated.discount = newSite.discount || 0;
      updated.commission = newSite.commission || 0;
      updated.siteType = newSite?.siteType;
    }),
  );

  await callAmplifyAPIPut('/labclient', { site: { ...newSite } });
  return site;
};

const updateSiteArchive = async (id, isArchive) => {
  const res = await DataStore.query(Site, (site) => site.id('eq', id));
  if (res.length === 0) return;
  await DataStore.save(
    Site.copyOf(res[0], (updated) => {
      updated.isArchive = isArchive;
    }),
  );
  const site = await callAmplifyAPIPut('/labclient/archive', { site: { id, isArchive } });
  return site;
};

const updateLabArchive = async (id, isArchive) => {
  console.log('UPDATING LAB ARCHIVE', id, isArchive);
  const res = await DataStore.query(Lab, (lab) => lab.id('eq', id));
  if (res.length === 0) return;
  await DataStore.save(
    Lab.copyOf(res[0], (updated) => {
      updated.isArchive = isArchive;
    }),
  );
  return;
};

const updateClientArchive = async (id, isArchive) => {
  console.log('UPDATING CLIENT ARCHIVE', id, isArchive);
  const res = await DataStore.query(Client, (client) => client.id('eq', id));
  if (res.length === 0) return;
  await DataStore.save(
    Client.copyOf(res[0], (updated) => {
      updated.isArchive = isArchive;
    }),
  );
  return;
};

const addUserToSite = async (updatedSite, user) => {
  console.log('FROM API', updatedSite, user);
  const models = await DataStore.query(Site, (site) => site.id('eq', updatedSite.id));
  return await DataStore.save(
    Site.copyOf(models[0], (updated) => {
      if (updated.show_contacts === null) {
        updated.show_contacts = [user];
      } else {
        if (models[0].show_contacts.indexOf(user) === -1) {
          updated.show_contacts = [...models[0].show_contacts, user];
        }
      }
    }),
  );
};

const removeUserToSite = async (updatedSite, user) => {
  console.log('FROM API', updatedSite, user);
  const models = await DataStore.query(Site, (site) => site.id('eq', updatedSite.id));
  return await DataStore.save(
    Site.copyOf(models[0], (updated) => {
      if (updated.show_contacts !== null) {
        updated.show_contacts = updated.show_contacts.filter((lc) => lc !== user);
      }
    }),
  );
};

const addUserToLab = async (newLab, user) => {
  if (newLab) {
    const lab = await DataStore.query(Lab, (lab) => lab.id('eq', newLab.id));
    console.log('[ADD_USER_TO_LAB]', lab);
    try {
      if (lab.length > 0) {
        const result = await DataStore.save(
          Lab.copyOf(lab[0], (updated) => {
            if (updated.lab_contacts === null) {
              updated.lab_contacts = [user];
            } else {
              if (updated.lab_contacts.indexOf(user) === -1)
                updated.lab_contacts = [...updated.lab_contacts, user];
            }
          }),
        );
        console.log('[ADD_USER_TO_LAB]', result);
      }
    } catch (e) {
      console.log('Error adding user to lab:', e);
    }
  }
};

const removeUserToLab = async (prevLab, user) => {
  if (prevLab) {
    const lab = await DataStore.query(Lab, (lab) => lab.id('eq', prevLab.id));
    console.log('[REMOVE_USER_TO_LAB]', lab);
    try {
      if (lab.length > 0) {
        const result = await DataStore.save(
          Lab.copyOf(lab[0], (updated) => {
            if (updated.lab_contacts !== null) {
              updated.lab_contacts = updated.lab_contacts.filter((lc) => lc !== user);
            }
          }),
        );
        console.log('[REMOVE_USER_TO_LAB]', result);
      }
    } catch (e) {
      console.log('Error adding user to lab:', e);
    }
  }
};

const updateLab = async (newLab) => {
  console.log('IN UPDATE LAB ', newLab);
  const lab = await DataStore.query(Lab, (lab) => lab.id('eq', newLab.id));
  return await DataStore.save(
    Lab.copyOf(lab[0], (updated) => {
      updated.name = newLab.name;
      updated.contact_name = newLab.contact;
      updated.phone_number = newLab.phone;
      updated.fax_number = newLab.fax_number;
      updated.contact_email = newLab.email;
      updated.street = newLab.street;
      updated.city_state_zip = newLab.city_state_zip;
      updated.lab_director = newLab.lab_director;
      updated.clia_number = newLab.clia_number;
      updated.custom_notes = newLab.custom_notes;
      updated.referredDoctor = newLab.referredDoctor;
      updated.testing_notes = newLab.testing_notes;
      updated.default_antigen = newLab.default_antigen;
      updated.default_molecular = newLab.default_molecular;
      updated.default_other = newLab.default_other;
      updated.default_rapidflu = newLab.default_rapidflu;
      updated.default_lucira = newLab.default_lucira;
      updated.tubes_provided = newLab.tubes_provided;
      updated.sendInsurance = newLab.sendInsurance;
      updated.logo = newLab.logo;
      updated.barCodeProvided = newLab.barCodeProvided;
      updated.barCodeAlertLimit = newLab.barCodeAlertLimit;
      updated.antigen_notes = newLab.antigen_notes;
      updated.molecular_notes = newLab.molecular_notes;
      updated.invalid_notes = newLab.invalid_notes;
      updated.indeterminate_notes = newLab.indeterminate_notes;
      updated.other_notes = newLab.other_notes;
      updated.lucira_notes = newLab.lucira_notes;
      updated.rapidflu_notes = newLab.rapidflu_notes;
      updated.showOnSummaryScreen = newLab.showOnSummaryScreen;
      updated.whiteLabel = newLab.whiteLabel;
      updated.whiteLabelPackage = newLab.whiteLabelPackage;
      updated.isArchive = newLab.isArchive;
      updated.pendingRelease = newLab.pendingRelease;
    }),
  );
};

const updateLabBarCodes = async (barCodeData, id) => {
  barCodeData.forEach(async (row) => {
    await DataStore.save(
      new LencoBarcodes({
        labID: id,
        barcode: row.barcode,
        available: true,
        isActive: 1,
      }),
    );
  });
};

const getLabBarCodes = async (id) => {
  const models = await DataStore.query(LencoBarcodes, (lab) =>
    lab.labID('eq', id).available('eq', true),
  );
  return models;
};

const updateLabLogo = async (id, fileName) => {
  const lab = await DataStore.query(Lab, (lab) => lab.id('eq', id));
  return await DataStore.save(
    Lab.copyOf(lab[0], (updated) => {
      updated.logo = fileName;
    }),
  );
};

const updateBarCodeAlert = async (val) => {
  const labs = await DataStore.query(Lab, (lab) => lab.barCodeProvided('eq', true));
  console.log('Labs', labs, parseInt(val));
  labs.forEach(async (lab) => {
    await DataStore.save(
      Lab.copyOf(lab, (updated) => {
        updated.barCodeAlertLimit = parseInt(val);
      }),
    );
  });
};

const resetDefaultLab = async (labID, key) => {
  const labs = await DataStore.query(Lab, (lab) => lab.id('ne', labID).whiteLabel('eq', false));
  const ttl = labs.length;
  for (let i = 0; i < ttl; i++) {
    await DataStore.save(
      Lab.copyOf(labs[i], (updated) => {
        updated[`${key}`] = false;
      }),
    );
  }
};

// const AWS = require('aws-sdk');
// AWS.config.update({
//   accessKeyId: process.env.AWS_ACCESS_KEY,

// })

const patientUpdateAPI = async (param) => {
  const path = '/employees/update';
  try {
    delete param.Tests;
    const params = {
      body: {
        employee: param,
        clientID: CONFIG.clientID,
      },
      headers: {
        'Content-Type': 'application/json',
        Authorization: `${(await Auth.currentSession()).getAccessToken().getJwtToken()}`,
      },
    };
    const res = await API.post(apiName, path, params);
    return res;
  } catch (e) {
    console.log('Error :', e);
  }
};

const buildAWSDate = (dobFromID) => {
  const awsdob = [];
  awsdob.push(dobFromID.substring(4));
  awsdob.push(dobFromID.substring(0, 2));
  awsdob.push(dobFromID.substring(2, 4));
  return awsdob.join('-');
};

const findHippaConsent = async (employeeID, showId) => {
  const models = await DataStore.query(TestConsent, (test) =>
    test.employeeID('eq', employeeID).siteID('eq', showId),
  );
  return models;
};

const deleteWrongTest = async (id) => {
  await DataStore.delete(Test, (test) => test.id('eq', id));
};

const updateDemographics = (c, crew) => {
  const demos = { ...c.employee_demographics };
  demos.firstName = crew.firstName;
  demos.lastName = crew.lastName;
  demos.email = crew.email;
  return demos;
};

const updateCrewInfo = async (crew) => {
  const res = await DataStore.query(Test, (test) => test.employeeID('eq', crew.employeeID));
  res.forEach(async (c) => {
    await DataStore.save(
      Test.copyOf(c, (updated) => {
        updated.employee_demographics = updateDemographics(c, crew);
        updated.email = crew.email;
      }),
    );
  });

  const employee = await DataStore.query(Employee, (test) => test.id('eq', crew.employeeID));
  employee.forEach(async (e) => {
    await DataStore.save(
      Test.copyOf(e, (updated) => {
        updated.first = crew.firstName;
        updated.last = crew.lastName;
        updated.email = crew.email;
      }),
    );
  });

  console.log('Data', crew, employee);
};
const getTestByIdGraphQl = async (id) => {
  let test = await graphQLApi.getTestByIdGQL(id);
  return test;
};

const getTestBySequenceGraphQl = async (sequenceNo) => {
  let test = await graphQLApi.getTestByIdGQL(sequenceNo);
  return test;
};
const updateCompleteCrewInfoV1 = async (crew) => {
  try {
    const demographics = crew.employee_demographics;
    console.log('crew', crew);
    let TestData = {
      appVer: crew?.appVer || '0',
      barcode: crew?.barcode || '',
      baseSiteID: crew?.baseSiteID || '',
      beenTimed: crew?.beenTimed || false,
      clientID: crew?.clientID || '',
      clientName: crew?.clientName || '',
      createSource: crew?.createSource ? crew.createSource : 'web',
      createdAt: moment(crew?.createdAt).utc().toISOString() || moment().utc().toISOString(),
      createdBy: crew?.createdBy || '',
      done: crew?.done || false,
      email: demographics.email,
      emailSend: crew?.emailSend || false,
      employeeID: crew.employeeID,
      expired: crew?.expired || false,
      employee_demographics: JSON.stringify(crew.employee_demographics),
      id: crew.id,
      invalidResultStatus: crew?.invalidResultStatus || 0,
      isAntigen: crew?.isAntigen || false,
      isFalsePositive: crew?.isFalsePositive || false,
      isLucira: crew?.isLucira || false,
      labID: crew?.labID || '',
      labName: crew?.labName || '',
      labelPrinted: crew?.labelPrinted || 0,
      owner: crew?.owner || '',
      patternTestAnswer: crew?.patternTestAnswer || null,
      pcrStatus: crew?.pcrStatus || 'new',
      phoneNumber: demographics.phoneNumber,
      referenceID: crew?.referenceID || '',
      result: crew?.result || '',
      siteID: crew?.siteID || '',
      schrID: crew?.schrID || '',
      schrTestID: crew?.schrTestID || '',
      sequenceNo: crew?.sequenceNo,
      site_name: crew?.site_name || '',
      sr_no: crew?.sr_no || '0',
      startTimeStamp: crew?.startTimeStamp || '',
      status: crew?.status || '',
      submittedBy: crew?.createdBy,
      submittedByName: crew?.submittedByName,
      test_number: crew?.test_number || 0,
      test_type: crew?.test_type || '',
      ...(crew?.testTypeID && { testTypeID: crew?.testTypeID }),
      testerPhone: crew?.testerPhone || '',
      tester_name: crew?.tester_name || '',
      timerStatus: crew?.timerStatus || '',
      timezone: crew.timezone || SYSTEM_TIME_ZONE,
      _version: crew._version,
    };
    if (crew?.resultDate) {
      Object.assign(TestData, {
        resultDate: crew.resultDate,
      });
    }
    const updatedTest = await API.graphql({
      query: mutations.updateTest,
      variables: { input: TestData },
    });
    const employeeData = {
      id: crew.employeeID,
      first: demographics.firstName,
      last: demographics.lastName,
      dob: FormatAWSDate(demographics.dob),
      id_number: demographics.idNumber,
      email: demographics.email,
      phone_number: demographics.phoneNumber,
      country: demographics?.country || '',
      countryCode: demographics.countryCode,
      insurance_name: demographics.insurance_name,
      insurance_number: demographics.insurance_number,
      street: demographics.street,
      street2: demographics.street2,
      city: demographics.city,
      state: demographics.state,
      zip: demographics.zip,
      autoRelease: demographics.autoRelease == true || demographics.autoRelease == '1' ? '1' : '0',
      isVaccinated: demographics?.isVaccinated || false,
      whiteGlove: demographics?.whiteGlove || false,
      vaccinationCardImage: demographics.vaccinationCardImage,
      externalTestImage: demographics.externalTestImage,
      _version: demographics._version,
      updatedBy: demographics.updatedBy,
      updatedByName: demographics.updatedByName,
    };
    if (demographics._version) {
      await API.graphql({ query: mutations.updateEmployee, variables: { input: employeeData } });
    }
    return updatedTest.data?.updateTest;
  } catch (error) {
    console.log('Error in Updating ', error);
  }
};

const updateCompleteCrewInfo = async (props) => {
  const { crew, user, isConfirm, chngObj, prevTestType } = props;
  const demographics = crew.employee_demographics;
  const testDemo = formatTestDemo({ ...crew.employee_demographics });

  for (const demo in testDemo) {
    if (testDemo[demo] === undefined) testDemo[demo] = '';
  }

  const TestData = {
    id: crew.id,
    barcode: crew.barcode,
    ...(crew.resultDateTime && { resultDateTime: moment(crew.resultDateTime).utc().toISOString() }),
    ...(crew.clientID && { clientID: crew.clientID }),
    ...(crew.clientName && { clientName: crew.clientName }),
    ...(crew.siteID && { siteID: crew.siteID }),
    ...(crew.site_name && { site_name: crew.site_name }),
    ...(crew.labID && { labID: crew.labID }),
    ...(crew.labName && { labName: crew.labName }),
    test_type: crew.test_type,
    ...(crew.result && { result: crew.result }),
    status: crew.status,
    ...(crew.labelPrinted && { labelPrinted: crew.labelPrinted }),
    ...(crew.tester_name && { tester_name: crew.tester_name }),
    ...(crew.createdBy && { createdBy: crew.createdBy }),
    ...(crew.releaseDate && { releaseDate: moment(crew.releaseDate).utc().toISOString() }),
    ...(crew.verifiedBy && { verifiedBy: crew.verifiedBy }),
    ...(crew.verifiedByName && { verifiedByName: crew.verifiedByName }),
    ...(crew.StampByName && { StampByName: crew.StampByName }),
    ...(crew.email && { email: crew.email }),
    ...(crew.phoneNumber && { phoneNumber: crew.phoneNumber }),
    ...(crew.scanByLabUserID && { scanByLabUserID: crew.scanByLabUserID }),
    ...(crew.scanByLabUserName && { scanByLabUserName: crew.scanByLabUserName }),
    ...(crew.testTypeID && { testTypeID: crew.testTypeID }),
    employee_demographics: JSON.stringify(testDemo),
    sr_no: isConfirm ? parseSrNoObject(crew.sr_no, crew.barcode, user) : crew.sr_no,
    createdAt: crew.createdAt,
    sendToID: crew.sendToID,
    sendToName: crew.sendToName,
    _version: crew._version,
  };

  let updatedTest = null;
  if (TestData._version) {
    updatedTest = await API.graphql({
      query: mutations.updateTest,
      variables: { input: TestData },
    });
  }
  const employeeData = {
    id: crew.employeeID,
    first: demographics.firstName,
    last: demographics.lastName,
    dob: FormatAWSDate(demographics.dob),
    id_number: demographics.idNumber,
    email: demographics.email,
    phone_number: demographics.phoneNumber,
    country: demographics?.country || '',
    countryCode: demographics.countryCode,
    insurance_name: demographics.insurance_name,
    insurance_number: demographics.insurance_number,
    insuranceCompanyCode: demographics.insuranceCompanyCode,
    street: demographics.street,
    street2: demographics.street2,
    city: demographics.city,
    state: demographics.state,
    zip: demographics.zip,
    county: demographics.county,
    autoRelease: demographics.autoRelease == true || demographics.autoRelease == '1' ? '1' : '0',
    isVaccinated: demographics?.isVaccinated || false,
    employeeType: demographics?.employeeType || '',
    whiteGlove: demographics?.whiteGlove || false,
    vaccinationCardImage: demographics.vaccinationCardImage,
    externalTestImage: demographics.externalTestImage,
    _version: demographics._version,
    updatedBy: demographics.updatedBy,
    updatedByName: demographics.updatedByName,
  };
  if (demographics._version) {
    await API.graphql({ query: mutations.updateEmployee, variables: { input: employeeData } });
  }

  if (prevTestType) {
    await callAmplifyAPI('/orders/tests/change', {
      orderID: crew.orderID,
      prevID: prevTestType,
      newID: crew.testTypeID,
    });
  }

  return updatedTest?.data?.updateTest;
};

const updateEmployeeProfile = async (employee) => {
  console.log('updateEmployeeProfile::', employee);
  const employeeData = {
    id: employee.id,
    first: employee.first,
    last: employee.last,
    dob: FormatAWSDate(employee.dob),
    id_number: employee.id_number,
    email: employee.email,
    phone_number: employee.phone_number,
    profileImage: employee.profileImage,
    countryCode: employee.countryCode,
    insurance_number: employee.insurance_number,
    insurance_name: employee.insurance_name,
    insuranceDetails: JSON.stringify(employee.insuranceDetails),
    insuranceCompanyCode: employee.insuranceCompanyCode,
    medicalNo: employee.medicalNo,
    primaryCarePhysician: employee.primaryCarePhysician,
    referringPyhsician: employee.referringPyhsician,
    renderingProvider: employee.renderingProvider,
    referringProvider: employee.referringProvider,
    orderingProvider: employee.orderingProvider,
    isBooster: employee.isBooster,
    isVaccinated: employee.isVaccinated,
    street: employee.street,
    street2: employee.street2,
    city: employee.city,
    state: employee.state,
    zip: employee.zip,
    note: employee.note,
    sendSMS: employee.sendSMS == true || employee.sendSMS == '1' ? '1' : '0',
    sendEmail: employee.sendEmail == true || employee.sendEmail == '1' ? '1' : '0',
    autoRelease: employee.autoRelease == true || employee.autoRelease == '1' ? '1' : '0',
    vaccinationCardImage: employee.vaccinationCardImage,
    externalTestImage: employee.externalTestImage,
    _version: employee._version,
    updatedBy: employee.updatedBy,
    updatedByName: employee.updatedByName,
  };

  let updatedCrew = '';
  if (employee._version) {
    updatedCrew = await API.graphql({
      query: mutations.updateEmployee,
      variables: { input: employeeData },
    });
  }

  return updatedCrew?.data?.updateEmployee;
};

const updateEmployeeRecord = async (employee) => {
  const dbEmp = await getRecordById(employee.id, 'employee');
  console.log('dbEmp', dbEmp);
  const employeeData = {
    ...employee,
    _version: dbEmp[0]?._version,
  };

  let updatedCrew = '';
  if (dbEmp[0]?._version) {
    updatedCrew = await API.graphql({
      query: mutations.updateEmployee,
      variables: { input: employeeData },
    });
  }

  return updatedCrew?.data?.updateEmployee;
};

const updateTestData = async (test) => {
  const TestData = {
    id: test.id,
    createdAt: test.createdAt,
    employee_demographics: JSON.stringify(test.employee_demographics),
    tester_name: test.tester_name,
    testerPhone: test.testerPhone,
    createdBy: test.createdBy,
    _version: test._version,
  };
  const updatedTest = await API.graphql({
    query: mutations.updateTest,
    variables: { input: TestData },
  });
  return updatedTest.data?.updateTest;
};

const updateTestingGQL = async (tests) => {
  const response = [];
  for (const test of tests) {
    const items = await getRecordById(test.id, 'test');
    const dbTest = items[0];
    const srNo = JSON.parse(dbTest.sr_no);

    srNo[0].date = moment(test.createdAt).add(30, 'minutes').toISOString();

    const TestData = {
      id: dbTest.id,
      scanByLabUserName: dbTest.tester_name,
      sr_no: JSON.stringify(srNo),
      scanByLabUserID: dbTest.createdBy,
      _version: dbTest._version,
    };
    const updatedTest = await API.graphql({
      query: mutations.updateTest,
      variables: { input: TestData },
    });
    response.push(updatedTest.data?.updateTest);
  }
};

const updateTestFromOrder = async (dbEmp = {}) => {
  try {
    const demo = dbEmp.employee_demographics;
    const [countryCode, phoneNo] = getPhoneNo(demo.phoneNumber, demo.countryCode);
    const test = {
      clientName: dbEmp.clientName,
      email: dbEmp.email,
      employee_demographics: JSON.stringify({ ...demo, countryCode, phoneNumber: phoneNo }),
      isAntigen: dbEmp?.test_type === 'Antigen' ? true : false,
      labID: dbEmp.labID,
      labName: dbEmp.labName,
      phoneNumber: phoneNo,
      baseSiteID: dbEmp.siteID,
      siteID: dbEmp.siteID,
      site_name: dbEmp.site_name,
      test_type: dbEmp.test_type,
      tester_name: dbEmp.tester_name,
    };

    await updateTest(dbEmp.testID, test);
  } catch (err) {
    console.log('Error:-', err);
  }
};

const parseSrNoObject = (sr_no, barcode, user) => {
  const newObj = { accession_no: barcode, date: moment().toISOString(), name: user.name };
  let arr = [];
  if (sr_no == '0') {
    arr.push(newObj);
  } else {
    try {
      arr = JSON.parse(sr_no);
    } catch (er) {
      console.log('Parser Error');
    }
    arr.push(newObj);
  }
  return JSON.stringify(arr);
};

const updateBarcodeNumber = async (test, appContext) => {
  const items = await getRecordById(test.id, 'test');

  const dbTest = items[0];
  const TestData = {
    id: test.id,
    barcode: test.barcode,
    labelPrinted: 2,
    sr_no: parseSrNoObject(test.sr_no, test.barcode, appContext.user),
    _version: dbTest._version,
  };

  const updatedTest = await API.graphql({
    query: mutations.updateTest,
    variables: { input: TestData },
  });

  await addTestLogs({
    ids: [{ id: dbTest.id, sequenceNo: dbTest.sequenceNo }],
    oldValue: dbTest.barcode,
    newValue: test.barcode,
    userID: appContext.user?.sub,
    userName: appContext.user?.name,
    slug: 'AccessionNoChanged',
  });
  return updatedTest.data?.updateTest;
};

const updateTestRecord = async (test) => {
  const TestData = {
    ...test,
  };

  const updatedTest = await API.graphql({
    query: mutations.updateTest,
    variables: { input: TestData },
  });

  return updatedTest.data?.updateTest;
};

const updateTestDataV1 = async (crew) => {
  console.log('crew:::', crew);

  try {
    const demographics = crew.employee_demographics;
    let TestData = {
      barcode: crew?.barcode || '',
      clientID: crew?.clientID || '',
      clientName: crew?.clientName || '',
      createdAt: moment(crew?.createdAt).utc().toISOString() || moment().utc().toISOString(),
      createdBy: crew?.createdBy || '',
      email: demographics.email,
      employeeID: crew.employeeID,
      employee_demographics: JSON.stringify(crew.employee_demographics),
      id: crew.id,
      isLucira: crew?.isLucira || false,
      labID: crew?.labID || '',
      labName: crew?.labName || '',
      phoneNumber: demographics.phoneNumber,
      result: crew?.result || '',
      siteID: crew?.siteID || '',
      sequenceNo: crew?.sequenceNo,
      site_name: crew?.site_name || '',
      status: crew?.status || '',
      test_type: crew?.test_type || '',
      testerPhone: crew?.testerPhone || '',
      tester_name: crew?.tester_name || '',
      _version: crew._version,
    };
    if (crew?.resultDate) {
      Object.assign(TestData, {
        resultDate: crew.resultDate,
      });
    }
    const updatedTest = await API.graphql({
      query: mutations.updateTest,
      variables: { input: TestData },
    });
    console.log('update Test::::', updatedTest);
    const employeeData = {
      id: crew.employeeID,
      first: demographics.firstName,
      last: demographics.lastName,
      dob: FormatAWSDate(demographics.dob),
      id_number: demographics.idNumber,
      email: demographics.email,
      phone_number: demographics.phoneNumber,
      country: demographics?.country || '',
      countryCode: demographics.countryCode,
      insurance_name: demographics.insurance_name,
      insurance_number: demographics.insurance_number,
      street: demographics.street,
      street2: demographics.street2,
      city: demographics.city,
      state: demographics.state,
      zip: demographics.zip,
      autoRelease: demographics.autoRelease == true || demographics.autoRelease == '1' ? '1' : '0',
      isVaccinated: demographics?.isVaccinated || false,
      whiteGlove: demographics?.whiteGlove || false,
      vaccinationCardImage: demographics.vaccinationCardImage,
      externalTestImage: demographics.externalTestImage,
      _version: demographics._version,
      updatedBy: demographics.updatedBy,
      updatedByName: demographics.updatedByName,
    };
    if (demographics._version) {
      await API.graphql({ query: mutations.updateEmployee, variables: { input: employeeData } });
    }
    return updatedTest.data?.updateTest;
  } catch (error) {
    console.log('Error in Updating ', error);
  }
};

const searchProviderAPI = async (provider) => {
  let path = '/searchProvider';
  let params = {
    body: {
      ...(!provider.npi ? { first_name: provider.firstName, last_name: provider.lastName } : {}),
      npi: provider.npi,
    },
    headers: {
      'Content-Type': 'application/json',
      Authorization: `${(await Auth.currentSession()).getAccessToken().getJwtToken()}`,
    },
  };

  try {
    const models = await API.post(apiName, path, params);

    return models?.data || [];
  } catch (e) {
    console.log('Error:', e);
    return [];
  }
};

const sendEmailToCrew = async (data) => {
  console.log('[sendEmailToCrew]', data);
  // const path = "/sendEmailToCrew";
  const path = '/sendEmailAttachement';

  const params = {
    body: {
      data: data,
      isPortalCall: true,
    },
    headers: {
      'Content-Type': 'application/json',
      Authorization: `${(await Auth.currentSession()).getAccessToken().getJwtToken()}`,
    },
  };

  const infoUpdated = await API.post(apiName, path, params);
};

const sendInvitationEmail = async (data) => {
  console.log('[sendGeneralEmail]', data);
  // const path = "/sendEmailToCrew";
  const path = '/sendGeneralEmail';

  const params = {
    body: {
      data: data,
    },
    headers: {
      'Content-Type': 'application/json',
      Authorization: `${(await Auth.currentSession()).getAccessToken().getJwtToken()}`,
    },
  };

  const infoUpdated = await API.post(apiName, path, params);
};

const getTestAutoNumber = async () => {
  console.log('[getAutoNumber]');
  const path = '/getAutoNumber';

  const params = {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `${(await Auth.currentSession()).getAccessToken().getJwtToken()}`,
    },
    body: {},
  };

  const counterData = await API.post(apiName, path, params);
  console.log('Counter Api', counterData);
};

const getTestFromAPi = async (test) => {
  const path = `/test/${test.id}`;

  const params = {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `${(await Auth.currentSession()).getAccessToken().getJwtToken()}`,
    },
  };

  const res = await API.get(apiName, path, params);
  console.log('res', res);
};

const getTestFromAPiQueryString = async (test) => {
  const path = `/test`;

  const params = {
    queryStringParameters: {
      id: test.id,
      barcode: test.barcode,
      sequenceNo: test.sequenceNo,
    },
    headers: {
      'Content-Type': 'application/json',
      Authorization: `${(await Auth.currentSession()).getAccessToken().getJwtToken()}`,
    },
  };

  const res = await API.get(apiName, path, params);
  console.log('res', res);
  if (res && res.data) return res.data;
  return null;
};
const fetchTestFromByClientID = async (test) => {
  const path = `/fetchTest`;

  const params = {
    body: {
      id: test.id,
      barcode: test.barcode,
      sequenceNo: test.sequenceNo,
      clientID: test.clientID, // Mandatory with one above any key
    },
    headers: {
      'Content-Type': 'application/json',
      Authorization: `${(await Auth.currentSession()).getAccessToken().getJwtToken()}`,
    },
  };

  const res = await API.post(apiName, path, params);
  console.log('res', res);
  if (res && res.data) return res.data;
  return null;
};

const getPreRegisterData = async (data, env, type, countryCode, siteID) => {
  const path = '/searchEmployee';
  const params = {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `${(await Auth.currentSession()).getAccessToken().getJwtToken()}`,
    },
    body: {
      env: env,
      phone_number: data,
      scanType: 'Search',
      countryCode,
      type,
      timezone: new Date().getTimezoneOffset(),
      clientID: CONFIG.clientID,
      siteID: siteID,
      ...(!CONFIG.siteID && { onlyEmployee: 'y' }),
    },
  };
  const counterData = await API.post(apiName, path, params);

  return counterData.Items;
};

const deletePreRegistration = async (id) => {
  console.log('[deletePreRegistration]');
  const path = '/deletePreRegistration';

  const params = {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `${(await Auth.currentSession()).getAccessToken().getJwtToken()}`,
    },
    body: {
      id: id,
    },
  };

  await API.post(apiName, path, params);
};

const getTestListFromApi = async (siteid) => {
  console.log('[testList]');
  const path = '/testList';

  const params = {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `${(await Auth.currentSession()).getAccessToken().getJwtToken()}`,
    },
    body: {
      siteID: siteid,
    },
  };

  const apiRes = await API.post(apiName, path, params);
  if (apiRes.Items) {
    return apiRes.Items.filter((f) => f._delete !== true && f.result);
  }
  return [];
};

const getTestLogs = async (id) => {
  const apiRes = await callAmplifyAPI('/getTestLogs', { id });

  return apiRes.data;
};

const getOrderLogs = async (id, testID) => {
  const apiRes = await callAmplifyAPI('/orders/logs', {
    id: id,
    testID,
  });
  return apiRes.data || [];
};
const getLogs = async (id) => {
  const apiRes = await callAmplifyAPI('/logs', {
    id: id,
  });
  return apiRes.data || [];
};

const getPatientLogs = async (id) => {
  const apiRes = await callAmplifyAPI('/employees/logs', {
    id: id,
  });

  return apiRes || [];
};

const getAppTestLogs = async (id) => {
  const path = '/getAppTestLogs';
  const params = {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `${(await Auth.currentSession()).getAccessToken().getJwtToken()}`,
    },
    body: {
      id: id,
    },
  };

  const apiRes = await API.post(apiName, path, params);
  return apiRes.data;
};
const getAdminTestList = async (id) => {
  const path = '/adminTestList';
  let items = [];
  let nextPage = null;
  do {
    const params = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `${(await Auth.currentSession()).getAccessToken().getJwtToken()}`,
      },
      body: {
        siteID: id,
        siteKey: 'employeeID',
        siteIndex: 'byEmployee',
        nextPage: nextPage,
      },
    };
    const apiRes = await API.post(apiName, path, params);
    nextPage = apiRes.LastEvaluatedKey ?? null;
    items = items.concat(apiRes.Items);
  } while (nextPage);
  return items;
};
const getRecordById = async (id, type) => {
  const path = '/getRecordById';
  let items = [];
  let nextPage = null;
  do {
    const params = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `${(await Auth.currentSession()).getAccessToken().getJwtToken()}`,
      },
      body: {
        id: id,
        name: type,
      },
    };
    const apiRes = await API.post(apiName, path, params);
    nextPage = apiRes.data?.LastEvaluatedKey ?? null;
    items = items.concat(apiRes.data?.Item);
  } while (nextPage);
  return items;
};
const getPRRecordById = async (id, phone) => {
  const path = '/getPRRecordById';
  const params = {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `${(await Auth.currentSession()).getAccessToken().getJwtToken()}`,
    },
    body: {
      id: id,
      phone_number: phone,
    },
  };
  const apiRes = await API.post(apiName, path, params);

  return apiRes.data;
};

const getShowPreRegistration = async (id) => {
  const path = '/getShowPreRegistration';
  let items = [];
  let nextPage = null;
  const token = (await Auth.currentSession()).getAccessToken().getJwtToken();

  do {
    const params = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: token,
      },
      body: {
        id: id,
        nextPage: nextPage,
      },
    };
    const apiRes = await API.post(apiName, path, params);
    nextPage = apiRes.data.LastEvaluatedKey ?? null;
    items = items.concat(apiRes.data.Items);
  } while (nextPage);

  return items;
};
const getExternalTestList = async (id) => {
  const path = '/getExternalTestList';
  let items = [];
  let nextPage = null;
  const token = (await Auth.currentSession()).getAccessToken().getJwtToken();

  do {
    const params = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: token,
      },
      body: {
        siteID: id,
        nextPage: nextPage,
      },
    };
    const apiRes = await API.post(apiName, path, params);
    console.log('apiRes', apiRes);
    nextPage = null;
    items = items.concat(apiRes.data);
  } while (nextPage);

  return items;
};

const addTestLogs = async (obj) => {
  const path = '/addTestLogs';
  const params = {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `${(await Auth.currentSession()).getAccessToken().getJwtToken()}`,
    },
    body: {
      params: obj,
    },
  };

  const apiRes = await API.post(apiName, path, params);
  return apiRes.data;
};

const addLogs = async (obj) => {
  try {
    const path = '/addLogs';
    const apiRes = await callAmplifyAPI(path, obj);
    return apiRes.data;
  } catch (error) {
    console.log(error);
  }
};

const addTestAppLogs = async (obj) => {
  const path = '/addTestAppLogs';
  const params = {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `${(await Auth.currentSession()).getAccessToken().getJwtToken()}`,
    },
    body: {
      data: obj,
    },
  };

  const apiRes = await API.post(apiName, path, params);
  return apiRes.data;
};

const getSettingForApp = async (siteid) => {
  console.log('[testList]');
  const path = '/getSettingForApp';

  const params = {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `${(await Auth.currentSession()).getAccessToken().getJwtToken()}`,
    },
    body: {},
  };

  const apiRes = await API.post(apiName, path, params);
  return apiRes;
};

const getTestFromApi = async (id) => {
  console.log('[testDetails]');
  const path = '/adminTestList';
  let tests = [];
  let nextPage = null;
  const token = (await Auth.currentSession()).getAccessToken().getJwtToken();
  do {
    const params = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: token,
      },
      body: {
        siteID: id,
        siteKey: 'clientID',
        siteIndex: 'byClient',
        nextPage: nextPage,
      },
    };
    const apiRes = await API.post(apiName, path, params);
    nextPage = apiRes.LastEvaluatedKey ?? null;
    tests = tests.concat(apiRes.Items);
  } while (nextPage);
  return tests;
};

const saveUserPersonalisation = async (userID, personalisationData) => {
  const path = '/saveUserPersonalisation';

  const personalisation =
    typeof personalisationData === 'string'
      ? personalisationData
      : JSON.stringify(personalisationData);

  const params = {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `${(await Auth.currentSession()).getAccessToken().getJwtToken()}`,
    },
    body: {
      userID: userID,
      personalisationData: personalisation,
    },
  };

  return await API.post(apiName, path, params);
};

const getUserPersonalisation = async (userID) => {
  const path = '/getUserPersonalisation';
  const params = {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `${(await Auth.currentSession()).getAccessToken().getJwtToken()}`,
    },
    body: {
      userID: userID,
    },
  };

  const apiRes = await API.post(apiName, path, params);
  return apiRes.data;
};

const updateEmailStatus = async (id) => {
  const res = await DataStore.query(Test, (test) => test.id('eq', id));
  if (res.length === 0) return;
  await DataStore.save(
    Test.copyOf(res[0], (updated) => {
      updated.emailSend = true;
    }),
  );
};

const updateTestResult = async (id, result, user) => {
  const res = await DataStore.query(Test, (test) => test.id('eq', id));
  if (res.length === 0) return;
  await DataStore.save(
    Test.copyOf(res[0], (updated) => {
      updated.result = RESULT_VALUE[result];
      updated.status = result === 'positive' ? 'Pending' : 'Processed';
      updated.timerStatus = result === 'positive' ? 'Pending' : 'Processed';
      updated.resultDateTime = moment().utc().toISOString();
      updated.resultDate = moment().utc().format('YYYY-MM-DD');
      updated.StampBy = user.sub;
      updated.StampByName = user.name;
    }),
  );
};

const updateSiteStatus = async (id, status) => {
  const res = await DataStore.query(Site, (site) => site.id('eq', id));
  if (res.length === 0) return;
  await DataStore.save(
    Site.copyOf(res[0], (updated) => {
      updated.status = status;
    }),
  );

  if (status === 1 && CONFIG.isLive) {
    await sendInvitationEmail([
      {
        email: 'pclarke@othermind.com',
        subject: 'Show/Event Approved',
        msg: `<p>Hello, <br><br> Your request to create ${res[0].name} has been approved. Please login to the SafeCamp portal to retrieve your url and QR code. <br><br> Thank you</p>`,
      },
    ]);
  }
};

const updateTestForTest = async (id) => {
  const res = await DataStore.query(Test, (test) => test.id('eq', id));
  if (res.length === 0) return;
  await DataStore.save(
    Test.copyOf(res[0], (updated) => {
      updated.labOrderDetail = JSON.stringify({ status: 'Created', message: 'Lab order Created ' });
    }),
  );
};
const updateTestLabOnly = async (id, labID, user) => {
  console.log('Lab ID', labID);
  const res = await DataStore.query(Test, (test) => test.id('eq', id));
  if (res.length === 0) return;
  await DataStore.save(
    Test.copyOf(res[0], (updated) => {
      updated.labID = labID.value;
      updated.labName = labID?.label;
    }),
  );
};

const updateTestLab = async (id, labID, user) => {
  const res = await DataStore.query(Test, (test) => test.id('eq', id));
  if (res.length === 0) return;
  await DataStore.save(
    Test.copyOf(res[0], (updated) => {
      updated.labID = labID.value;
      updated.labName = labID?.label;
      updated.status = 'Sent';
      updated.submittedBy = user.sub;
      updated.submittedByName = user.name;
    }),
  );
};

const submitToLab = async (labData, tests) => {
  const [formattedTests, bucket, batch] = formatTests(labData, tests);
  try {
    await Storage.put(`${batch}.csv`, formattedTests, { bucket: bucket });
  } catch (e) {
    console.log('Error uploading to bucket', e);
    return 'Error uploading to bucket';
  }
  return `Successfully submitted to ${labData.name}`;
};

const updateTestClientLab = async (id, clientID, siteID) => {
  const res = await DataStore.query(Test, (test) => test.id('eq', id));
  res.forEach(async (c) => {
    await DataStore.save(
      Test.copyOf(c, (updated) => {
        updated.clientID = clientID;
        updated.siteID = siteID;
      }),
    );
  });
};

const updateTestLabWithResetStatus = async (id, labID) => {
  const res = await DataStore.query(Test, (test) => test.id('eq', id));
  res.forEach(async (c) => {
    await DataStore.save(
      Test.copyOf(c, (updated) => {
        updated.result = null;
        updated.resultDate = null;
        updated.resultDateTime = null;
        updated.StampBy = null;
        updated.StampByName = null;
        updated.status = 'Sent';
        updated.labID = labID;
        updated.emailSend = false;
      }),
    );
  });
};

const updateExpiredTestResult = async (id, result, user) => {
  try {
    const res = await DataStore.query(Test, (test) => test.id('eq', id));
    res.forEach(async (c) => {
      await DataStore.save(
        Test.copyOf(c, (updated) => {
          updated.result = RESULT_VALUE[result];
          updated.status = result === 'positive' ? 'Pending' : 'Processed';
          updated.timerStatus = result === 'positive' ? 'Pending' : 'Processed';
          updated.resultDateTime = moment().utc().toISOString();
          updated.resultDate = moment().utc().format('YYYY-MM-DD');
          updated.emailSend = true;
          updated.expired = false;
          updated.StampBy = user.sub;
          updated.StampByName = user.name;
        }),
      );
    });
  } catch (error) {
    console.log('Error in Updating ', error);
  }
};

const updateTestType = async (id, type, user) => {
  try {
    const res = await DataStore.query(Test, (test) => test.id('eq', id));
    res.forEach(async (c) => {
      await DataStore.save(
        Test.copyOf(c, (updated) => {
          updated.test_type = type;
        }),
      );
    });
  } catch (error) {
    console.log('Test Type Change Error', error);
  }
};

const updateQuarantineData = async (id, startDate, endDate) => {
  const res = await DataStore.query(Test, (test) => test.id('eq', id));
  console.log('In Update Quarantine', id, startDate, endDate, res);
  if (res.length > 0) {
    if (startDate && endDate) {
      await DataStore.save(
        Test.copyOf(res[0], (updated) => {
          updated.quarantinedStart = moment(startDate).toISOString();
          updated.quarantinedEnd = moment(endDate).toISOString();
        }),
      );
    } else if (startDate) {
      await DataStore.save(
        Test.copyOf(res[0], (updated) => {
          updated.quarantinedStart = moment(startDate).toISOString();
          updated.quarantinedEnd = null;
        }),
      );
    }
  }
};

const getExpiredTest = async () => {
  const items = await DataStore.query(Test, (test) => test.result('eq', 'Expired'), {
    sort: (test) => test.createdAt(SortDirection.DESCENDING),
  });
  return items;
};

const getPositiveTests = async (id) => {
  if (!id) return [];

  const test = await DataStore.query(Test, (test) =>
    test.siteID('eq', id).quarantinedStart('ne', null).quarantinedEnd('eq', null),
  );
  return test;
};

const UpdateEndQuarantine = async (users) => {
  for (let i = 0; i < users.length; i++) {
    const res = await DataStore.query(Test, (test) => test.id('eq', users[i]));
    await DataStore.save(
      Test.copyOf(res[0], (updated) => {
        updated.quarantinedEnd = moment().utc().toISOString();
      }),
    );
  }
};

const getTodaysTest = async () => {
  const startDate = moment().subtract(1, 'days').startOf('day').utc().toISOString();
  const endDate = moment().subtract(1, 'days').endOf('day').utc().toISOString();

  const models = await DataStore.query(Test, (test) =>
    test.createdAt('between', [startDate, endDate]),
  );
  return models;
};

const deleteUserLinkFromEmployee = async (val) => {
  const users = await DataStore.query(Employee, (e) => e.subID('eq', val));
  users.forEach(async (user) => {
    await DataStore.save(
      Employee.copyOf(user, (updated) => {
        updated.subID = null;
      }),
    );
  });
};

const hrCheckIn = async (id) => {
  const path = '/graphqlEmployeeCheckIn';
  const params = {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `${(await Auth.currentSession()).getAccessToken().getJwtToken()}`,
    },
    body: {
      id: '60956afd-0d86-4268-9c3d-83b80733cfd0',
      version: 15,
      checkIn: moment().toISOString(),
    },
  };

  const apiRes = await API.post(apiName, path, params);
  return apiRes.data;
};

const getClientShowByApi = async (clientID) => {
  const path = '/getSiteByClient';
  const params = {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `${(await Auth.currentSession()).getAccessToken().getJwtToken()}`,
    },
    body: {
      clientID,
    },
  };

  const apiRes = await API.post(apiName, path, params);
  return apiRes.data;
};

const saveFileSendToLab = async (fileName, formattedTests) => {
  try {
    await Storage.put(fileName, jsonToCSV(formattedTests), { bucket: 'result-update' });
  } catch (err) {
    console.log('Error', err);
  }
};

const archiveOrder = async (formattedTests) => {
  try {
    await callAmplifyAPI('/orders/quickarchive', { ids: formattedTests.map((f) => f.id) });

    const fileName = `${moment().utc().format('YYYY-MM-DD HH:mm')}_archive_order_${CONFIG.envType}.csv`;
    await Storage.put(fileName, jsonToCSV(formattedTests), { bucket: 'result-update' });
  } catch (err) {
    console.log('Error', err);
  }
};

const archiveAllOrder = async (filter) => {
  try {
    console.log('Filter', filter);

    const res = await callAmplifyAPI('/orders/archive', { params: filter });
  } catch (err) {
    console.log('Error', err);
  }
};

const saveFileExternalStorage = async (phoneNumber, employeeID, type, fileContent) => {
  try {
    // set the date format
    let fileName = `${phoneNumber}/${employeeID}`;
    if (type)
      fileName = `${fileName}_${type}_#datePattern#_${moment().utc().format('YYYY-MM-DD HH:mm')}.png`;

    await Storage.put(fileName, fileContent, { bucket: BUCKET_EXTERNAL_IMAGES });
    return fileName;
  } catch (err) {
    console.log('Error in Image Uploading', err);
  }
  return '';
};

const saveFileCalredieImg = async (fileContent) => {
  try {
    // set the date format

    let fileName = `logs/calredie_${moment().utc().format('YYYY-MM-DD HH:mm')}.png`;

    await Storage.put(fileName, fileContent);
    return fileName;
  } catch (err) {
    console.log('Error in Image Uploading', err);
  }
  return '';
};

const uploadFileCalredieCsv = async (fileContent) => {
  try {
    // set the date format

    await Storage.put(
      `${moment().format('DD_MM_YYYY_HH_mm_ss.SSS')}_confirmation_code_${CONFIG.envType}.csv`,
      jsonToCSV(fileContent),
      {
        bucket: 'result-update',
      },
    );
  } catch (err) {
    console.log('Error in Uploading', err);
  }
  return '';
};

const getFileExternalBlob = async (fileName) => {
  try {
    const path = await Storage.get(fileName, {
      bucket: BUCKET_EXTERNAL_IMAGES,
      download: true,
    });
    const mimeType = mime.lookup(fileName) || 'application/octet-stream';
    const blob = new Blob([path.Body], { type: mimeType });
    return blob;
  } catch (err) {
    console.log('Error', err);
  }
  return '';
};

const getFileExternalStorage = async (fileName) => {
  try {
    const path = await Storage.get(fileName, { bucket: BUCKET_EXTERNAL_IMAGES });
    return path;
  } catch (err) {
    console.log('Error', err);
  }
  return '';
};

const getFileProfileStorage = async (fileName) => {
  try {
    let profilePath = `profile/${fileName}`;
    const path = await Storage.get(profilePath, { bucket: 'medflow-images' });

    return path;
  } catch (err) {
    console.log('Error', err);
  }
  return '';
};

const cloneTest = async () => {
  const res = await DataStore.query(Test, (test) => test.sequenceNo('eq', 46145));
  console.log('Res', res);
  if (res.length === 0) return;

  const demographics = res[0].employee_demographics;

  // const employee = await DataStore.save(
  //   new Employee({
  //     first: demographics.firstName,
  //     last: demographics.lastName,
  //     dob: buildAWSDate('10291972'),
  //     id_number: demographics.idNumber,
  //     phone_number: demographics.phoneNumber,
  //     street: demographics.street,
  //     street2: demographics.street2,
  //     city: demographics.city,
  //     state: demographics.state,
  //     zip: demographics.zip,
  //   })
  // );

  await DataStore.save(
    Test.copyOf(res[0], (updated) => {
      updated.employee_demographics = {
        ...demographics,
        dob: '10291972',
        id: 'eac9a1c8-a627-430c-86ed-b2a6053bcfc4',
      };
      updated.employeeID = 'eac9a1c8-a627-430c-86ed-b2a6053bcfc4';
    }),
  );
};

const getTestListFromPG = async (param) => {
  let statusNew = '';
  let statusPending = '';
  let test_type = [];
  let dob = '';
  if (param?.status?.toLowerCase() == 'new') {
    statusNew = 'pending';
    test_type = ['PCR'];
  }
  if (param?.status?.toLowerCase() == 'pending') {
    statusPending = 'pending';
    test_type = ['Lucira', 'Molecular', 'Antigen', 'Rapid Flu', 'Rapid PCR'];
  }
  if (param?.dob) {
    dob = param?.dob?.replace(/[&\/\\#,+()$~%.'":*?<>{}]/g, '');
  }

  if (clientIDInLocalStorage.get() && connectedIDInLocalStorage.get()) {
    param['labID'] = connectedIDInLocalStorage.get();
    param['clientID'] = clientIDInLocalStorage.get();
  }
  if (CONFIG.isWhiteLabel) {
    param['labID'] = CONFIG.whiteLabelID;
    param['clientID'] = CONFIG.clientID;
    if (CONFIG.siteID) {
      param['siteID'] = CONFIG.siteID;
    }
  }
  const path = '/getTestFromPG';

  try {
    const params = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `${(await Auth.currentSession()).getAccessToken().getJwtToken()}`,
      },
      body: {
        params: dob
          ? { ...param, dob }
          : statusNew
            ? { ...param, status: statusNew, test_type }
            : statusPending
              ? { ...param, test_type }
              : param,
      },
    };

    const apiRes = await API.post(apiName, path, params);

    return apiRes.data;
  } catch (err) {
    console.log('Error:- getTestListFromPG', err);
    return {};
  }
};

const exportToCalRedieData = async (param) => {
  param['clientID'] = CONFIG.clientID;
  param['labID'] = CONFIG.whiteLabelID;

  try {
    const apiRes = await callAmplifyAPI('/tests/calredie', {
      params: param,
    });

    return apiRes.data;
  } catch (err) {
    console.log('Error:- tests/calredie', err);
    return {};
  }
};

const getEmployeeListFromPG = async (param) => {
  let dob = '';
  if (param?.dob) {
    let employeDOB = new Date(param?.dob);
    dob =
      employeDOB.getFullYear() +
      '-' +
      ('0' + (employeDOB.getMonth() + 1)).slice(-2) +
      '-' +
      ('0' + employeDOB.getDate()).slice(-2);
  }

  const path = '/employees/list';

  // const defaultTestDate = !param.testDate ? { testDate: moment().format("YYYY-MM-DD") } : {};
  const defaultTestDate = null;
  const params = {
    params: {
      ...param,
      ...(dob && dob),
      ...(defaultTestDate && defaultTestDate),
      timezone: SYSTEM_TIME_ZONE,
      clientID: CONFIG.clientID,
      ...(CONFIG.siteID && { siteID: CONFIG.siteID }),
    },
  };

  const apiRes = await callAmplifyAPI(path, params);

  return apiRes;
};

const getHipaaEmployeeListFromPG = async (param) => {
  const path = '/getEmployeeFromPG';

  const apiRes = await callAmplifyAPI(path, param);

  return apiRes;
};

const getOrderListFromPG = async (params) => {
  const path = '/orders';

  const apiRes = await callAmplifyAPI(path, { params: { ...params, timezone: SYSTEM_TIME_ZONE } });

  return apiRes;
};

const getOrderTestList = async (id) => {
  const path = '/orders/tests';

  const apiRes = await callAmplifyAPI(path, { timezone: SYSTEM_TIME_ZONE, id });

  return apiRes;
};

const getOrderListThroughEmpID = async (empId) => {
  const path = '/orders/employee';

  const apiRes = await callAmplifyAPI(path, { id: empId });

  return apiRes?.rows || [];
};

const getPreRegistrationData = async (param) => {
  console.log('[getPreRegistrationData]', param);
  const path = '/getPreRegistrationData';

  const params = {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `${(await Auth.currentSession()).getAccessToken().getJwtToken()}`,
    },
    body: param,
  };

  const apiRes = await API.post(apiName, path, params);

  return apiRes;
};

const getSummaryFromPG = async (param) => {
  const path = '/getSummary';

  const params = {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `${(await Auth.currentSession()).getAccessToken().getJwtToken()}`,
    },
    body: {
      params: param,
    },
  };

  const apiRes = await API.post(apiName, path, params);

  return apiRes.data;
};

const getAppVersion = async (param) => {
  const path = '/appVersion';

  const params = {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `${(await Auth.currentSession()).getAccessToken().getJwtToken()}`,
    },
    body: {
      name: 'ios',
    },
  };
  const apiRes = await API.post(apiName, path, params);
  return apiRes.data;
};

const updateTestStatus = async () => {
  const res = await DataStore.query(Test, (test) => test.sequenceNo('eq', '83749'));
  if (res.length === 0) return;
  return;
  res.forEach(async (c) => {
    await DataStore.save(
      Test.copyOf(c, (updated) => {
        updated.result = null;
        updated.status = 'Sent';
        updated.resultDate = null;
        updated.resultDateTime = null;
      }),
    );
  });
};

const getTestBySequenceNo = async (num) => {
  const res = await DataStore.query(Test, (test) => test.sequenceNo('eq', parseInt(num)));
  return res;
};

const getEmployees = async () => {
  const models = await DataStore.query(Employee, Predicates.ALL);
  return models;
  models.forEach(async (e) => {
    console.log('Employee', e.id);
    // await DataStore.save(
    //   Employee.copyOf(e, (updated) => {
    //     updated.email = 'zero@yopmail.com';
    //     updated.phone_number = '2222222222';
    //   })
    // );
    // await DataStore.delete(Employee, (lab) => lab.id("eq", e.id));
  });
  return models;
};

const downloadcsv = async (param, setList) => {
  const path = '/downloadcsv';

  const params = {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `${(await Auth.currentSession()).getAccessToken().getJwtToken()}`,
    },
    body: {
      params: param,
    },
  };

  const res = await API.post(apiName, path, params);

  if (res) {
    const result = await Storage.get(`excelfile/${res}`, {
      bucket: 'employee-external-tests',
      download: true,
    });
    const data = await result.Body.text();

    readString(data, {
      header: true,
      worker: true,
      complete: (results) => {
        if (results.data) {
          setList(results.data.map((t) => formatTest(t)));
        }
      },
    });
  }
  return [];
};

const downloadEmployeeCSV = async (param, setList) => {
  const path = '/downloadEmployeeCSV';

  const params = {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `${(await Auth.currentSession()).getAccessToken().getJwtToken()}`,
    },
    body: {
      params: param,
    },
  };

  const res = await API.post(apiName, path, params);
  console.log('res', res);
  if (res) {
    const result = await Storage.get(`excelfile/${res}`, {
      bucket: 'employee-external-tests',
      download: true,
    });
    const data = await result.Body.text();

    readString(data, {
      header: true,
      worker: true,
      complete: (results) => {
        console.log(results);
        if (results.data) {
          setList(results.data);
        }
      },
    });
  }
  return [];
};
const downloadPdf = async (param) => {
  const path = '/downloadcsv';
  const params = {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `${(await Auth.currentSession()).getAccessToken().getJwtToken()}`,
    },
    body: {
      params: param,
    },
  };
  const res = await API.post(apiName, path, params);
  if (res) {
    let data = await Storage.get(`excelfile/${res}`, { bucket: BUCKET_EXTERNAL_IMAGES });
    fetch(data).then((response) => {
      response.blob().then((blob) => {
        const fileURL = window.URL.createObjectURL(blob);
        let alink = document.createElement('a');
        alink.href = fileURL;
        alink.download = 'TestPDF.pdf';
        alink.click();
      });
    });
  }
  return [];
};

const downloadEmployeePdf = async (param) => {
  const path = '/downloadEmployeeCSV';
  const params = {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `${(await Auth.currentSession()).getAccessToken().getJwtToken()}`,
    },
    body: {
      params: param,
    },
  };
  const res = await API.post(apiName, path, params);
  if (res) {
    let data = await Storage.get(`excelfile/${res}`, { bucket: 'employee-external-tests' });
    fetch(data).then((response) => {
      response.blob().then((blob) => {
        const fileURL = window.URL.createObjectURL(blob);
        let alink = document.createElement('a');
        alink.href = fileURL;
        alink.download = 'EmployeePDF.pdf';
        alink.click();
      });
    });
  }
  return [];
};

const getLabProvidedBarCode = async (labID) => {
  console.log('[getLabProvidedBarCode]');
  const path = '/getLabProvidedBarCode';

  const params = {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `${(await Auth.currentSession()).getAccessToken().getJwtToken()}`,
    },
    body: {
      params: { id: labID },
    },
  };

  const res = await API.post(apiName, path, params);
  console.log('res', res);
  return res.barcode;
};

const updateTestEmployeeData = async (id, version) => {
  const TestData = {
    id: id,
    employeeID: 'bcb771f5-2953-4f16-ac57-1d4db681a72b',
    _version: version,
  };
  const updatedTest = await API.graphql({
    query: mutations.updateTest,
    variables: { input: TestData },
  });
};

const getShipBobOrderDetails = async (id) => {
  const path = '/getShipBobOrder';
  const params = {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `${(await Auth.currentSession()).getAccessToken().getJwtToken()}`,
    },
    body: {
      id: id,
    },
  };
  const apiRes = await API.post(apiName, path, params);
  if (apiRes.data && apiRes.data.shipments && apiRes.data.shipments.length > 0) {
    const tracking = apiRes.data.shipments[0].tracking;
    if (tracking && tracking.tracking_url) {
      return tracking.tracking_url;
    }
  }
  return null;
};

const getPreRegistrationOrderLog = async (id) => {
  const path = '/getPreRegistrationOrderLog';
  const params = {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `${(await Auth.currentSession()).getAccessToken().getJwtToken()}`,
    },
    body: {
      id: id,
    },
  };
  const apiRes = await API.post(apiName, path, params);

  return apiRes.data;
};

const addTestResult = async (list) => {
  const path = '/addTestResult';
  const params = {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `${(await Auth.currentSession()).getAccessToken().getJwtToken()}`,
    },
    body: {
      tests: list,
    },
  };

  const apiRes = await API.post(apiName, path, params);
  return apiRes.data;
};

const prTestCreation = async (list) => {
  const path = '/prTestCreation';
  console.log('IN LAST prTestCreation', list);
  const params = {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `${(await Auth.currentSession()).getAccessToken().getJwtToken()}`,
    },
    body: {
      tests: list,
    },
  };

  const apiRes = await API.post(apiName, path, params);
  return apiRes.data;
};

const getCompanyZone = async () => {
  console.log('[getCompanyZone]');
  const path = '/getCompanyZone';

  const params = {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `${(await Auth.currentSession()).getAccessToken().getJwtToken()}`,
    },
    body: { id: '50f733f9-a473-4755-989a-35e6d1c0ba2b' },
  };

  const counterData = await API.post(apiName, path, params);
  console.log('getCompanyZone Api', counterData);
};

const getProfileUpdateLogs = async (id) => {
  const path = '/getProfileUpdateLogs';
  const params = {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `${(await Auth.currentSession()).getAccessToken().getJwtToken()}`,
    },
    body: {
      id: id,
    },
  };

  const apiRes = await API.post(apiName, path, params);
  console.log('Here are response data from employee logs', apiRes);
  return apiRes.data;
};

const getPRPCRTest = async (id) => {
  const path = '/getTestsByStatus';
  const params = {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `${(await Auth.currentSession()).getAccessToken().getJwtToken()}`,
    },
    body: {
      siteID: '180843da-f0c5-4c08-bd25-a651392836cd',
    },
  };

  const apiRes = await API.post(apiName, path, params);
  console.log('Here are response data from employee logs', apiRes);
  return apiRes.data;
};

const getPGEmployeeData = async (id) => {
  const path = '/pgSiteRecord';
  const params = {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `${(await Auth.currentSession()).getAccessToken().getJwtToken()}`,
    },
    body: {
      siteID: id,
    },
  };
  const apiRes = await API.post(apiName, path, params);
  return apiRes.rows;
};

const deleteDuplicateEmployee = async (empObj, primary_id, deleted_id, user) => {
  const path = '/deleteDuplicateEmployee';

  const params = {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `${(await Auth.currentSession()).getAccessToken().getJwtToken()}`,
    },
    body: {
      empObj: empObj,
      primary_id: primary_id,
      deleted_id: deleted_id,
      userID: user.sub,
      userName: user.name,
    },
  };
  const apiRes = await API.post(apiName, path, params);
  console.log('deleted ID:', apiRes);
  return apiRes;
};

const getTestByID = async (id, type) => {
  const path = '/getRecordById';

  const apiRes = await callAmplifyAPI(path, {
    id: id,
    name: type,
  });
  return apiRes?.data?.Item;
};

const getTestByEmployee = async (id) => {
  const path = '/getTestByEmployee';
  const params = {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `${(await Auth.currentSession()).getAccessToken().getJwtToken()}`,
    },
    body: {
      siteID: id,
    },
  };
  const apiRes = await API.post(apiName, path, params);
  console.log('Api Re', apiRes);
  return apiRes;
};

const mainCrewListing = async () => {
  console.log('[mainCrewInfo]', new Date().getTimezoneOffset());

  const path = '/mainCrewInfo';
  const params = {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `${(await Auth.currentSession()).getAccessToken().getJwtToken()}`,
    },
    body: {
      id: '1',
    },
  };
  const counterData = await API.post(apiName, path, params);

  console.log('Counter Api', counterData);
  return counterData.rows;
};

const getEmployeeZoneSchedule = async () => {
  console.log('[getEmployeeZoneSchedule]');

  const path = '/getEmployeeZoneSchedule';
  const params = {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `${(await Auth.currentSession()).getAccessToken().getJwtToken()}`,
    },
    body: {
      employeeID: '4f0d67fb-b023-4a7f-9578-647903a67b01',
      programID: 'f74e91bf-d39e-4d62-8e36-f74abebdc4ab',
      timezone: new Date().getTimezoneOffset(),
    },
  };
  const counterData = await API.post(apiName, path, params);

  console.log('Counter Api', counterData);
  return counterData.rows;
};

const addEmployeeRoles = async (obj) => {
  const path = '/addEmployeeRoles';

  const apiRes = await callAmplifyAPI(path, {
    ...obj,
    userPermission: JSON.stringify(obj.userPermission),
  });
  return apiRes?.data;
};

const addEmployee = async (obj) => {
  const path = '/addEmployee';
  const apiRes = await callAmplifyAPI(path, obj);
  return apiRes?.data;
};

const isUserExistsInDB = async (param) => {
  let phone = param.replace(/[^a-zA-Z0-9 ]/g, '');
  const path = '/isUserExistsInDB';

  const apiRes = await callAmplifyAPI(path, {
    phone: phone,
  });
  return apiRes?.data;
};

const updateEmployeeRoles = async (obj) => {
  const path = '/updateEmployeeRoles';
  const {
    id,
    username,
    roles,
    firstName,
    lastName,
    phone_number,
    email,
    userRole,
    note,
    status,
    systemUser,
    clientID,
    connectedID,
  } = obj;

  const apiRes = await callAmplifyAPI(path, {
    id,
    firstName: firstName,
    lastName: lastName,
    phone_number: phone_number,
    email: email,
    userRole: userRole,
    note: note,
    username: username,
    roles: roles,
    status,
    systemUser,
    clientID,
    connectedID,
  });
  return apiRes?.data;
};

const getEmployeeRoles = async (username) => {
  const path = '/getEmployeeRoles';
  const params = {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `${(await Auth.currentSession()).getAccessToken().getJwtToken()}`,
    },
    body: {
      username: username,
    },
  };
  try {
    const apiRes = await API.post(apiName, path, params);
    if (apiRes.Items.length > 0) {
      return apiRes.Items;
    }
  } catch (err) {
    console.log('Error', err.response);
    throw err;
  }

  return [];
};
const getUserFromLocalDB = async (username) => {
  const path = '/getUserFromLocalDB';
  const params = {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `${(await Auth.currentSession()).getAccessToken().getJwtToken()}`,
    },
    body: {
      id: username,
    },
  };
  const apiRes = await API.post(apiName, path, params);
  if (apiRes && apiRes.length > 0 && CONFIG.isWhiteLabel) console.log('In Filter User');
  return apiRes.filter(
    (u) =>
      u.phone_number !== '+18322959940' &&
      !ADMIN_USER.includes(removeWhitespaceAndParentheses(u.phone_number)),
  );
};

const addMultipleUserToGroup = async (obj) => {
  const path = '/addMultipleUserToGroup';
  const { username, groupname } = obj;
  const params = {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `${(await Auth.currentSession()).getAccessToken().getJwtToken()}`,
    },

    body: {
      username: username,
      groupname: groupname,
    },
  };
  const apiRes = await API.post(apiName, path, params);
  return apiRes.data;
};

const removeMultipleUserFromGroup = async (obj) => {
  const path = '/removeMultipleUserFromGroup';
  const { username, groupname } = obj;
  const gpName = groupname.map((g) => {
    if (g === 'Employerss') return 'Employers';
    return g;
  });
  const params = {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `${(await Auth.currentSession()).getAccessToken().getJwtToken()}`,
    },

    body: {
      username: username,
      groupname: groupname,
    },
  };
  const apiRes = await API.post(apiName, path, params);
  return apiRes.data;
};

const updateExternalTest = async () => {
  const path = '/updateExternalTest';
  const params = {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `${(await Auth.currentSession()).getAccessToken().getJwtToken()}`,
    },

    body: {
      employeeID: '753111a8-fe0f-4a06-a7c0-5f88912f33eb',
      tests: [
        {
          id: '9322a168-686d-4383-960d-89df5ec5af9a',
          testNow: false,
          isDone: true,
          value: 'Molecular',
          label: 'Cue',
          location: 'On Location',
          scheduleCategory: 'Testing',
          result: 'Negative',
          externalTestFile:
            '9322a168-686d-4383-960d-89df5ec5af9a/9322a168-686d-4383-960d-89df5ec5af9a_#datePattern#_2022-10-25 13:33.png',
          date: '2022-10-25T13:34:02.552Z',
        },
      ],
      userName: 'Admin Testin User',
    },
  };
  const apiRes = await API.post(apiName, path, params);
  return apiRes.data;
};

const deleteLogRecord = async (msg) => {
  const path = '/deleteLogs';
  const params = {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `${(await Auth.currentSession()).getAccessToken().getJwtToken()}`,
    },
    body: {
      id: msg.id,
      testID: msg.testID,
    },
  };
  const apiRes = await API.post(apiName, path, params);

  return apiRes;
};

const changeTestType = async (tests, userName) => {
  const path = '/updateTestType';
  const params = {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `${(await Auth.currentSession()).getAccessToken().getJwtToken()}`,
    },

    body: {
      tests: tests,
      userName: userName,
    },
  };
  const apiRes = await API.post(apiName, path, params);
  return apiRes.data;
};

const confirmEmployeeAccount = async () => {
  const path = '/confirmEmployeeAccount';
  const params = {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `${(await Auth.currentSession()).getAccessToken().getJwtToken()}`,
    },

    body: {
      id: '946c28d5-a574-4fc2-8f55-b359e6ab2614',
      appPackage: 'appPackage',
      userName: 'Admin Testin User',
    },
  };
  const apiRes = await API.post(apiName, path, params);
  return apiRes.data;
};

const getEmployeesTests = async (ids) => {
  const path = '/getEmployeesTests';
  const params = {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `${(await Auth.currentSession()).getAccessToken().getJwtToken()}`,
    },
    body: {
      ids: ids,
    },
  };

  const apiRes = await API.post(apiName, path, params);
  console.log('Here are response data from employee logs', apiRes);
  return apiRes;
};

const createHREmployee = async (ids) => {
  const path = '/createHREmployee';
  const params = {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `${(await Auth.currentSession()).getAccessToken().getJwtToken()}`,
    },
    body: {
      companyID: 'ecfda7c0-8e21-43ce-a10a-cac36d9d36b4',
      firstName: 'Austin',
      lastName: 'Rose',
      dob: '2022-12-26',
      street: '98G4+792',
      street2: '',
      city: 'Ranchi',
      state: 'JH',
      countryCode: '+1',
      country: 'India',
      zip: '834001',
      phoneNumber: '+15959599595',
      sex: 'M',
      email: 'hehd@hehdh.dhbdbd',
      idNumber: 'HDHDHDH',
      programName: 'Zone-A',
      programID: 'b0b58d09-73aa-4e92-8b5f-c638c3e11f16',
      department: 'a42447c5-3d96-41d2-b07f-2a2b31c7a988',
      id: '',
    },
  };

  const apiRes = await API.post(apiName, path, params);
  console.log('Here are response data from employee logs', apiRes);
  return apiRes;
};

const hrEmployeeListing = async () => {
  const path = '/hrEmployeeListing';
  const params = {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `${(await Auth.currentSession()).getAccessToken().getJwtToken()}`,
    },
    body: {
      siteID: '50f733f9-a473-4755-989a-35e6d1c0ba2b',
    },
  };

  const apiRes = await API.post(apiName, path, params);
  console.log('Here are response data from employee logs', apiRes);
  return apiRes;
};

const employeeByIds = async (ids) => {
  console.log('[employeeByIds]');
  let path = '/employeeByIds';
  let params = {
    body: {
      ids: ids,
    },
    headers: {
      'Content-Type': 'application/json',
      Authorization: `${(await Auth.currentSession()).getAccessToken().getJwtToken()}`,
    },
  };
  const models = await API.post(apiName, path, params);
  return models.data;
};

const getProxyContactData = async () => {
  const path = 'https://9dpj6yddw2.execute-api.eu-west-1.amazonaws.com/default/contacttypes';
  const relations = await axios.get(path);
  return relations.data;
};

const deleteTestwithHRid = async () => {
  const path = '/tests';
  const params = {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `${(await Auth.currentSession()).getAccessToken().getJwtToken()}`,
    },
    body: {
      schrID: '9d50959e-667e-434e-9f06-de1ba3f3bb3d',
      startDate: '2023-04-10T19:00:00.000Z',
      endDate: '2023-04-11T10:14:55.479Z',
    },
  };

  const apiRes = await API.post(apiName, path, params);
  console.log('Here are response data from employee logs', apiRes);
  return apiRes;
};

const proxyContant = async (obj) => {
  const path = '/proxyContant';
  const employee = obj.employee_demographics;
  try {
    const params = {
      ...(CONFIG.isWhiteLabel && { medflowDomain: CONFIG.appPackage }),
      showID: obj.siteID,
      clientID: obj.clientID,
      isCheck: employee.isCheck || 'n',
      // parentId: "19f7ce91-129c-4137-9a39-ab742b0ca1ec",
      // relation: {
      //   id: "2c2fceaf-b462-44e5-a11c-843b7c74851a",
      //   name: "Parent Child",
      // },

      employee: {
        id_number: employee.idNumber,
        site_name: obj.site_name,
        firstName: employee.firstName,
        lastName: employee.lastName,
        sex: employee.sex,
        phone_number: obj.phoneNumber,
        countryCode: employee.countryCode,
        street: employee.street,
        street2: employee.street2,
        state: employee.state,
        county: employee.county,
        city: employee.city,
        zip: employee.zip,
        dob: moment(employee.dob, 'MMDDYYYY').format('YYYY-MM-DD'),
        country: employee.country,
        email: employee.email,
        whiteGlove: employee.whiteGlove,
        isVaccinated: employee.isVaccinated,
        ...(employee.insuranceCompanyCode && {
          insurance_name: employee.insurance_name,
          insurance_number: employee.insurance_number,
          insuranceCompanyCode: employee.insuranceCompanyCode,
        }),
        updatedBy: obj.updatedBy,
        updatedByName: obj.updatedByName,
        lastUsed: moment().toISOString(),
        insuranceDetails: employee.insuranceDetails,
        insuranceCompanyCode: employee.insuranceCompanyCode,
        medicalNo: employee.medicalNo,
        status: employee.status,
        isHospice: employee.isHospice,
        primaryCarePhysician: employee.primaryCarePhysician,
        referringPyhsician: employee.referringPyhsician,
        renderingProvider: employee.renderingProvider,
        referringProvider: employee.referringProvider,
        orderingProvider: employee.orderingProvider,
        siteID: obj.siteID,
      },
      ...(obj.employeeID && { id: obj.employeeID }),
    };

    const apiRes = await callAmplifyAPI(path, params);

    return apiRes?.data;
  } catch (err) {
    return err.response.data?.errors?.[0] || null;
  }
};

const proxyContantFromPatient = async (obj) => {
  const path = '/proxyContant';
  const employee = obj.employee_demographics;
  try {
    const params = {
      ...(CONFIG.isWhiteLabel && { medflowDomain: CONFIG.appPackage }),
      showID: obj.siteID,
      clientID: obj.clientID,
      isCheck: 'y',
      // parentId: "19f7ce91-129c-4137-9a39-ab742b0ca1ec",
      // relation: {
      //   id: "2c2fceaf-b462-44e5-a11c-843b7c74851a",
      //   name: "Parent Child",
      // },
      employee: {
        id_number: employee.idNumber,
        site_name: obj.site_name,
        firstName: employee.firstName,
        lastName: employee.lastName,
        middleName: employee.middleName,
        sex: employee.sex,
        phone_number: obj.phoneNumber,
        countryCode: employee.countryCode,
        street: employee.street,
        street2: employee.street2,
        state: employee.state,
        county: employee.county,
        city: employee.city,
        zip: employee.zip,
        dob: moment(employee.dob, 'MMDDYYYY').format('YYYY-MM-DD'),
        country: employee.country,
        email: employee.email,
        whiteGlove: employee.whiteGlove,
        isVaccinated: employee.isVaccinated,
        insurance_name: employee.insurance_name,
        insurance_number: employee.insurance_number,
        insuranceCompanyCode: employee.insuranceCompanyCode,
        updatedBy: obj.updatedBy,
        updatedByName: obj.updatedByName,
        lastUsed: moment().toISOString(),
        insuranceDetails: employee.insuranceDetails,
        insuranceCompanyCode: employee.insuranceCompanyCode,
        medicalNo: employee.medicalNo,
        status: employee.status,
        isHospice: employee.isHospice,
        primaryCarePhysician: employee.primaryCarePhysician,
        referringPyhsician: employee.referringPyhsician,
        renderingProvider: employee.renderingProvider,
        referringProvider: employee.referringProvider,
        orderingProvider: employee.orderingProvider,
        siteID: obj.siteID,
        site_name: employee?.site_name,
        previousSiteID: employee?.previousSiteID || '',
        sendSMS: employee.sendSMS,
        sendEmail: employee.sendEmail,
        autoRelease: employee.autoRelease,
        note: employee.note,
        snf: employee.snf,
        isInsHousing: employee.isInsHousing,
        provider: employee.provider,
      },
      ...(obj.employeeID && { id: obj.employeeID }),
      customID: obj.customID,
    };

    const apiRes = await callAmplifyAPI(path, params);

    return { message: null, record: apiRes?.data, eligibiltyStatus: apiRes?.eligibilityResponse };
  } catch (err) {
    const errorMessage = err.response.data?.errors?.[0].message || 'Fail to save the Patient';
    return { message: errorMessage, record: null };
  }
};
const searchEmployee = async (obj) => {
  const path = '/searchEmployee';

  try {
    const params = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `${(await Auth.currentSession()).getAccessToken().getJwtToken()}`,
      },
      body: {
        phone_number: '2222222222',
        siteID: '35cab446-237a-402d-b661-afe60c086844',
        scanType: 'Lookup',
        type: 'Phone',
        countryCode: '+1',
        timezone: -300,
        requiredExternalTest: 'No',
        appPackage: 'com.bio.lab',
        clientID: '5659da45-a80c-478b-8301-2e8d5b2b56ac',
      },
    };

    const apiRes = await API.post(apiName, path, params);

    return apiRes?.data;
  } catch (err) {
    return null;
  }
};

const savePreRegistration = async (param, testKey) => {
  try {
    let path = '/preregistration';
    let params = {
      body: {
        recordId: param.id ? param.id : uuidv4(),
        first: param.first,
        last: param.last,
        profileImage: null,
        countryCode: param.countryCode,
        email: param.email,
        phone_number: param.phone_number,
        street: param.street,
        street2: null,
        city: param.city,
        state: param.state,
        country: param.country,
        zipcode: param.zipcode,
        sex: param.sex,
        dob: param.dob,
        isVaccinated: param.isVaccinated,
        id_number: param.id_number,
        insurance_name: param.insurance_name,
        insurance_number: param.insurance_number,
        insuranceCompanyCode: param.insuranceCompanyCode,
        showId: '',
        showName: '',
        clientId: '',
        isExternalTest: false,
        testSend: 'No',
        rStep: 1,
        labid: '',
      },
    };
    const res = await API.post(publicApiName, path, params);
    console.log('res', res);
    return res.rows;
  } catch (err) {
    console.log('Error:-', err);
  }
};

const insurancesList = async (obj) => {
  const path = '/insurances';

  try {
    const params = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `${(await Auth.currentSession()).getAccessToken().getJwtToken()}`,
      },
      body: {
        clientID: CONFIG.clientID,
      },
    };

    const apiRes = await API.post(apiName, path, params);

    return apiRes?.data;
  } catch (err) {
    return null;
  }
};

const checkEligibility = async (obj) => {
  const path = '/eligibility';

  try {
    const apiRes = await callAmplifyAPI(path, {
      clientID: CONFIG.clientID,
      firstName: obj.firstName || obj.first,
      lastName: obj.lastName || obj.last,
      middleName: '',
      medicalNo: [obj.insurance_number],
      dob: obj.dob,
      fdos: moment().format('YYYYMMDD'),
      empID: obj.mdID,
      companyID: obj.siteID || null,
      clientID: CONFIG.clientID,
      subAgentID: null,
      userID: obj.updatedBy,
      userName: obj.updatedByName,
      locationName: obj?.site_name || null,
      clientName: CONFIG.basename,
      subAgentName: null,
      payerids: [obj.insuranceCompanyCode],
      payerNames: [obj.insurance_name],
      ssn: null,
    });

    return apiRes;
  } catch (err) {
    return null;
  }
};

const assignedTestSchedule = async (employeeIds, detail, user) => {
  const path = '/employees/testschedule';

  const payload = {
    dateOfTest: moment(detail.date).format('MMDDYYYY'),
    testDate: moment(detail.date).toISOString(),
    testType: { test: detail.testType },
    data: employeeIds,
    createdBy: user.sub,
    createdByName: user.name,
  };
  const apiRes = await callAmplifyAPI(path, payload);
  return apiRes?.data;
};

const removeTestSchedule = async (employeeIds, detail, user) => {
  const path = '/employees/testschedule/remove';

  const payload = {
    date: moment(detail.date).format('MMDDYYYY'),
    testDate: moment(detail.date).format('MM-DD-YYYY'),
    ids: employeeIds,
    userID: user.sub,
    userName: user.name,
  };
  const apiRes = await callAmplifyAPI(path, payload);
  return apiRes?.data;
};

const uploadPatientTestSchedule = async (obj, fileName) => {
  try {
    await Storage.put(`${fileName}`, jsonToCSV(obj), {
      bucket: 'result-update',
    });
  } catch (err) {
    return null;
  }
};

const acknowledgedPositiveTest = async (arr) => {
  try {
    await Storage.put(
      `${moment().format('DD_MM_YYYY_HH_mm_ss.SSS')}_acknowledged_${CONFIG.envType}.csv`,
      jsonToCSV(arr),
      {
        bucket: 'result-update',
      },
    );
  } catch (err) {
    return null;
  }
};

const importLabClient = async (params) => {
  const res = await callAmplifyAPI('/labclient/import', params);
  return res;
};

const getTodayScheduleTests = async () => {
  const path = '/employees/testschedule/list';

  const payload = {
    clientID: CONFIG.clientID,
    timezone: SYSTEM_TIME_ZONE,
    startDate: moment().format('YYYY-MM-DD'),
    endDate: moment().format('YYYY-MM-DD'),
  };
  const apiRes = await callAmplifyAPI(path, payload);
  console.log('Api', apiRes);
};

const resetTestSchedule = async () => {
  const path = '/employees/testschedule/reset';

  const payload = {
    clientID: CONFIG.clientID,
    employeeID: '2350452e-18ad-4dd8-a15d-3ec2edf4019a',
    date: '10182023',
  };
  const apiRes = await callAmplifyAPI(path, payload);
  console.log('Api', apiRes);
};

const upComingSchedule = async () => {
  const path = '/employees/testschedule/upcoming';
  const payload = {
    ...(CONFIG.siteID && { siteID: CONFIG.siteID }),
    startDate: moment().format('YYYY-MM-DD'),
    timezone: SYSTEM_TIME_ZONE,
  };
  const apiRes = await callAmplifyAPI(path, payload);
  return apiRes?.rows || [];
};

const updateTest = async (id, obj) => {
  try {
    const resApi = await callAmplifyAPI('/getRecordById', {
      id: id,
      name: 'test',
    });

    const {
      data: { Item: dbTest },
    } = resApi;

    if (!dbTest) return;

    const objToUpdate = {
      id: id,
      ...obj,
      _version: dbTest._version,
    };

    await API.graphql({ query: mutations.updateTest, variables: { input: objToUpdate } });
  } catch (ex) {
    console.log('updateTest', ex);
  }
};
const payerList = async () => {
  try {
    const params = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `${(await Auth.currentSession()).getAccessToken().getJwtToken()}`,
      },
    };

    const apiRes = await API.get(apiName, '/payers', params);

    return apiRes;
  } catch (err) {
    return null;
  }
};

const splitReqForm = async (params) => {
  const resApi = await callAmplifyAPI('/requisitionformfiles', {
    params: { ...params, clientID: CONFIG.clientID },
  });
  return resApi;
};

const downloadRequisitionForm = async (params) => {
  try {
    const resApi = await callAmplifyAPI('/requisitionform', params);

    return resApi;
  } catch (err) {
    return null;
  }
};

const downloadRequisitionZip = async (filePath, fileName, bucket) => {
  try {
    const options = bucket ? { bucket } : {};
    const data = await Storage.get(filePath, { ...options });
    const response = await fetch(data);

    if (!response.ok) {
      return false;
    }

    const blob = await response.blob();

    if (blob.size === 0) {
      return false;
    }

    const fileURL = window.URL.createObjectURL(blob);
    let alink = document.createElement('a');
    alink.href = fileURL;
    alink.download = fileName;
    document.body.appendChild(alink);
    alink.click();

    return true;
  } catch (error) {
    console.error('Error downloading requisition zip:', error);
    return false;
  } finally {
    if (alink) {
      document.body.removeChild(alink);
      window.URL.revokeObjectURL(fileURL);
    }
  }
};

const getDefaultProvider = async (id) => {
  try {
    const resApi = await callAmplifyAPI('/defaultProvider', {
      id: id,
    });

    const { Item } = resApi;
    return Item;
  } catch (err) {
    return null;
  }
};

const getPaymentIntent = async (id) => {
  try {
    const resApi = await callAmplifyAPI('/payments/intent', {
      amount: 123,
      payment_methods: ['card'],
    });

    console.log('[getPaymentIntent]', resApi);
  } catch (err) {
    return null;
  }
};

const exportOrder = async (params) => {
  const path = '/orders/export';

  const apiRes = await callAmplifyAPI(path, { params: { ...params, timezone: SYSTEM_TIME_ZONE } });

  return apiRes;
};

const getClientInsurances = async (params) => {
  const path = '/insurances/all';

  const apiRes = await callAmplifyAPI(path, params);

  return apiRes?.rows;
};

const getTestSources = async (params) => {
  const path = '/sources/list';

  const apiRes = await callAmplifyAPI(path, params);

  return apiRes?.rows;
};

const assingTestResult = async (params) => {
  const path = '/test/update';

  const apiRes = await callAmplifyAPI(path, params);

  return apiRes;
};

const addSources = async (params) => {
  const path = '/sources';

  const apiRes = await callAmplifyAPI(path, params);

  return apiRes?.rows;
};

const createMulipleTests = async (params) => {
  const path = '/test/create';

  const apiRes = await callAmplifyAPI(path, params);

  return apiRes?.data || [];
};

const createEmployeeTests = async (params) => {
  const path = '/test/create/order';

  const apiRes = await callAmplifyAPI(path, params);

  return apiRes?.data || [];
};

const createBulkTestFromOrder = async (params) => {
  const path = '/test/create-multi';

  const apiRes = await callAmplifyAPI(path, params);

  return apiRes?.data || [];
};

const createPatientUser = async (param) => {
  const path = '/createPatientLogin';
  const apiRes = await callAmplifyAPIWithError(path, param);
  return apiRes;
};

const invoicesPDF = async (params) => {
  const path = '/getURL';

  const apiRes = await callAmplifyAPI(path, { path: 'invoices/test/000001.pdf' });
  console.log('invoicesPDF', apiRes);
  return apiRes?.data || [];
};

export const getTestsDetails = async (searchKey) => {
  try {
    return await callAmplifyAPI('/testassaylist', searchKey);
  } catch (err) {
    console.error('Error fetching tests data:', err);
    return err;
  }
};

export const createTestsDetails = async (params) => {
  try {
    return await callAmplifyAPI('/testassay', params);
  } catch (err) {
    console.error('Error create tests data:', err);
    return err;
  }
};
export const createBulkTestsDetails = async (params) => {
  try {
    return await callAmplifyAPI('/testassaybulk', params);
  } catch (err) {
    console.error('Error create tests data:', err);
    return err;
  }
};

export const updateTestsDetails = async (params) => {
  try {
    return await callAmplifyAPIPut('/testassay', params);
  } catch (err) {
    console.error('Error create tests data:', err);
    return err;
  }
};

const getConnectedProfiles = async (params) => {
  try {
    return await callAmplifyAPI('/employees/connected', params);
  } catch (err) {
    console.error('Error create tests data:', err);
    return err;
  }
};

const updateRelations = async (params) => {
  try {
    return await callAmplifyAPI('/employees/connected/update', params);
  } catch (err) {
    console.error('Error create tests data:', err);
    return err;
  }
};

const deleteRelations = async (params) => {
  const apiRes = await callAmplifyAPIWithError('/employees/connected/delete', params);
  return apiRes;
};

const getMultipleTests = async (params) => {
  try {
    return await callAmplifyAPI('/tests', params);
  } catch (err) {
    console.error('Error create tests data:', err);
  }
  return [];
};

const getEligibilityFile = async (params) => {
  try {
    const data = await callAmplifyAPI('/getURL', params);
    return data?.url;
  } catch (err) {
    console.error('Error create tests data:', err);
  }
  return '';
};

const getCompanySetting = async () => {
  try {
    const data = await callAmplifyAPI('/setting', {});
    return data;
  } catch (err) {
    console.error('Error create tests data:', err);
  }
  return '';
};
const getCptWithTests = async (params) => {
  try {
    const data = await callAmplifyAPI('/cpt/search', params);
    return data;
  } catch (err) {
    console.error('Error create tests data:', err);
  }
  return '';
};

const getGeneralSetting = async (settingType) => {
  const models = await DataStore.query(
    GeneralSettingType,
    (s) => s.settingType('eq', settingType) && s.clientID('eq', CONFIG.clientID),
    {
      sort: (s) => s.label(SortDirection.ASCENDING),
    },
  );

  if (models.length > 0) {
    return models.map((model) => ({
      label: model.label,
      value: model.value,
      code: model.code,
    }));
  }
  return [];
};

const createOption = async (settingType, optionValue) => {
  const settingTypeConfig = {
    source: {
      valueField: 'source',
      labelField: 'source',
      additionalFields: ['code'],
    },
    cancelled: {
      valueField: 'label',
      labelField: 'label',
      additionalFields: ['title'],
    },
    default: {
      valueField: 'value',
      labelField: 'label',
      additionalFields: [],
    },
  };

  const config = settingTypeConfig[settingType] || settingTypeConfig.default;
  const { valueField, labelField, additionalFields } = config;

  let dataToSave = {
    settingType,
    clientID: CONFIG.clientID,
  };

  let valueToSearch;
  if (typeof optionValue === 'object') {
    valueToSearch = optionValue[valueField];

    dataToSave.value = optionValue[valueField];
    dataToSave.label = optionValue[labelField];

    additionalFields.forEach((field) => {
      if (optionValue[field] !== undefined) {
        dataToSave[field] = optionValue[field];
      }
    });
  } else {
    valueToSearch = optionValue;
    dataToSave.value = optionValue;
    dataToSave.label = optionValue;
  }

  const models = await DataStore.query(
    GeneralSettingType,
    (s) =>
      s.settingType('eq', settingType) &&
      s.clientID('eq', CONFIG.clientID) &&
      s.value('eq', valueToSearch),
  );

  if (models.length > 0) {
    return;
  }

  const newOption = await DataStore.save(new GeneralSettingType(dataToSave));
  return newOption;
};

const updateOptions = async (settingType, optionValue, id, loginUser) => {
  const existingOption = await DataStore.query(GeneralSettingType, id);

  if (existingOption) {
    const settingTypeConfig = {
      source: {
        valueField: 'source',
        labelField: 'source',
        additionalFields: ['code'],
      },
      cancelled: {
        valueField: 'label',
        labelField: 'label',
        additionalFields: ['title'],
      },
      default: {
        valueField: 'value',
        labelField: 'label',
        additionalFields: [],
      },
    };

    const config = settingTypeConfig[settingType] || settingTypeConfig.default;
    const { valueField, labelField, additionalFields } = config;

    let dataToUpdate = {
      settingType,
      clientID: CONFIG.clientID,
    };

    if (typeof optionValue === 'object') {
      dataToUpdate.value = optionValue[valueField];
      dataToUpdate.label = optionValue[labelField];

      additionalFields.forEach((field) => {
        if (optionValue[field] !== undefined) {
          dataToUpdate[field] = optionValue[field];
        }
      });
    } else {
      dataToUpdate.value = optionValue;
      dataToUpdate.label = optionValue;
    }

    const keyName = {
      source: {
        label: 'Source',
        code: 'Source Code',
      },
      cancelled: {
        title: 'Details',
        label: 'Reason',
      },
      department: {
        value: 'Name',
      },
      unit: {
        value: 'Unit',
      },
      default: {
        value: 'Name',
      },
    };

    const changeLog = [];
    for (const key in dataToUpdate) {
      if (dataToUpdate[key] !== existingOption[key] && keyName[settingType][key]) {
        changeLog.push({
          key: key,
          name: keyName[settingType][key],
          newResult: dataToUpdate[key],
          oldResult: existingOption[key],
        });
      }
    }

    if (changeLog.length > 0) {
      const logObj = {
        updatedBy: loginUser.sub,
        updatedByName: loginUser.name,
        userID: loginUser.sub,
        userName: loginUser.name,
        testID: id,
        slug: 'Update',
        changeObject: changeLog,
      };
      await addLogs(logObj);
    }

    const updatedOption = await DataStore.save(
      GeneralSettingType.copyOf(existingOption, (updated) => {
        Object.assign(updated, dataToUpdate);
      }),
    );
    return updatedOption;
  }
};

const deleteOption = async (id) => {
  const existingOption = await DataStore.query(GeneralSettingType, id);

  if (existingOption) {
    // Delete the existing option
    const deletedOption = await DataStore.delete(existingOption);
    return deletedOption;
  } else {
    throw new Error(`Option with id ${id} not found.`);
  }
};

const createTestLabel = async (optionValue) => {
  const models = await DataStore.query(
    GeneralSettingType,
    (s) =>
      s.settingType('eq', TEST_SETTING_TYPES.TEST_LABEL) &&
      s.clientID('eq', CONFIG.clientID) &&
      s.value('eq', optionValue.code),
  );

  if (models.length > 0) {
    return;
  }

  const newOption = await DataStore.save(
    new GeneralSettingType({
      settingType: TEST_SETTING_TYPES.TEST_LABEL,
      clientID: CONFIG.clientID,
      label: optionValue.code,
      title: optionValue.label,
      value: optionValue.code,
    }),
  );
  return newOption;
};

const updateTestLabel = async (optionValue, id) => {
  const existingOption = await DataStore.query(GeneralSettingType, id);

  if (existingOption) {
    // Update the existing option's value
    const updatedOption = await DataStore.save(
      GeneralSettingType.copyOf(existingOption, (updated) => {
        updated.settingType = TEST_SETTING_TYPES.TEST_LABEL;
        updated.label = optionValue.label;
        updated.value = optionValue.code;
      }),
    );
    return updatedOption;
  }
};

const createResultOptions = async (optionValue) => {
  const models = await DataStore.query(
    GeneralSettingType,
    (s) =>
      s.settingType('eq', TEST_SETTING_TYPES.OPTIONS) &&
      s.clientID('eq', CONFIG.clientID) &&
      s.value('eq', optionValue.value),
  );

  if (models.length > 0) {
    return;
  }

  const newOption = await DataStore.save(
    new GeneralSettingType({
      settingType: TEST_SETTING_TYPES.OPTIONS,
      clientID: CONFIG.clientID,
      label: optionValue.label,
      value: optionValue.value,
      code: optionValue.code,
    }),
  );
  return newOption;
};

const updateResultOptions = async (optionValue, id) => {
  const existingOption = await DataStore.query(GeneralSettingType, id);

  if (existingOption) {
    const updatedOption = await DataStore.save(
      GeneralSettingType.copyOf(existingOption, (updated) => {
        updated.settingType = TEST_SETTING_TYPES.OPTIONS;
        updated.label = optionValue.label;
        updated.value = optionValue.value;
        updated.code = optionValue.code;
      }),
    );
    return updatedOption;
  }
};



const getAllGeneralSetting = async () => {
  const models = await DataStore.query(GeneralSettingType, (s) =>
    s.clientID('eq', CONFIG.clientID),
  );
  return models;
};

const getEligibilityList = async (param) => {
  const path = '/eligibility/list';

  const apiRes = await callAPIPost(path, { params: param });
  return apiRes;
};

const createPortalUser = async ({ newUser, role, personalization }) => {
  try {
    if (newUser && !newUser.subID) {
      const phoneNumber = newUser.phone_number || newUser.phoneNumber;
      const attributePayload = {
        preferred_username: phoneNumber,
        email: newUser.email,
        phone_number: newUser.phone_number,
        'custom:role': role,
        'custom:autoConfirm': 'true',
        'custom:firstName': `${newUser.firstName}`,
        'custom:lastName': `${newUser.lastName}`,
        'custom:note': `${newUser.firstName} ${newUser.lastName}`,
        'custom:labID': connectedIDInLocalStorage.get(),
      };
      console.log('attributePayload', attributePayload);
      const signUp = await Auth.signUp({
        username: phoneNumber,
        password: newUser.password,
        attributes: attributePayload,
      });
      return signUp;
    }
  } catch (err) {
    console.log('Error', err);
    return null;
  }
};

const createMessagesTemplate = async (params) => {
  const resApi = await callAmplifyAPI('/messages', params);
  return resApi;
};

const updateMessagesTemplate = async (params) => {
  const resApi = await callAmplifyAPI('/messages', params);
  return resApi;
};

const deleteMessagesTemplate = async (params) => {
  const resApi = await callAmplifyAPI('/messages/remove', params);
  return resApi;
};

const getMessagetTemplate = async (params) => {
  const resApi = await callAmplifyAPI('/messages/list', params);
  return resApi;
};

const updateTestNames = async (params) => {
  const resApi = await callAmplifyAPI('/testassay/updatename', params);
  return resApi;
};
const createTestPanel = async (params) => {
  const resApi = await callAmplifyAPI('/panel', params);
  return resApi;
};
const getTestPanel = async (params) => {
  const resApi = await callAmplifyAPI('/panel/tests');
  return resApi;
};

const getTestResultDetails = async (params) => {
  const resApi = await callAmplifyAPI('/test/result', params);
  return resApi;
};

const fetchTestDetail = async (test) => {
  const path = `/orders/details`;
  const apiRes = await callAmplifyAPI(path, { id: test.id });

  return apiRes.data;
};

export const deleteTestAssay = async (deletedItems) => {
  const apiRes = await callAmplifyAPI('/deletetestassay', { testsList: deletedItems });

  return apiRes;
};

const deletePanels = async (id) => {
  let condition = (test) => test.clientID('eq', CONFIG.clientID).type('eq', 'panel');

  const model = await DataStore.delete(TestTypes, (test) => condition(test).id('eq', id));

  // for (let i = 0; i < models.length; i++) {
  //   await DataStore.delete(TestTypes, (test) => test.id("eq", models[i].id));
  // }
  return model;
};

const ordertestUpdate = async (params) => {
  const path = '/orders/tests/update';

  const apiRes = await callAmplifyAPI(path, params);

  return apiRes;
};

const changesTestStatus = async (testData) => {
  const path = '/test/status/email';
  const apiRes = await callAmplifyAPI(path, { tests: testData });
  return apiRes;
};

const restoreOrders = async (params) => {
  const path = '/orders/unarchive';

  const apiRes = await callAmplifyAPI(path, { params });

  return apiRes;
};

const canelTests = async (tests) => {
  const path = '/test/cancel';

  const apiRes = await callAmplifyAPI(path, { tests });

  return apiRes;
};

const resendToARKStone = async (id) => {
  const path = '/test/resend/ark';

  const apiRes = await callAmplifyAPI(path, { id });

  return apiRes;
};

const updateOrderTestStatus = async (params) => {
  const path = '/orders/update/status';

  const apiRes = await callAmplifyAPI(path, params);

  return apiRes;
};

const acknwoledgedOrder = async (params) => {
  const path = '/orders/acknowledged';

  const apiRes = await callAmplifyAPI(path, params);

  return apiRes;
};

const cancelOrder = async (orders) => {
  const path = '/orders/cancel';

  const apiRes = await callAmplifyAPI(path, orders);

  return apiRes;
};

const receiveTests = async (tests) => {
  const path = '/test/receive';

  const apiRes = await callAmplifyAPI(path, tests);

  return apiRes;
};

export default {
  receiveTests,
  cancelOrder,
  acknwoledgedOrder,
  updateOrderTestStatus,
  resendToARKStone,
  getPanelsAndTests,
  createTestPanel,
  getTestResultDetails,
  getTestPanel,
  getCptWithTests,
  updateTestNames,
  getAllGeneralSetting,
  createTestLabel,
  createOption,
  getGeneralSetting,
  getCompanySetting,
  getEligibilityFile,
  getMultipleTests,
  updateRelations,
  getConnectedProfiles,
  createTestsDetails,
  assingTestResult,
  getTestsDetails,
  invoicesPDF,
  addSources,
  createMulipleTests,
  createEmployeeTests,
  createBulkTestFromOrder,
  getTestSources,
  getClientInsurances,
  exportOrder,
  getPaymentIntent,
  getDefaultProvider,
  splitReqForm,
  downloadRequisitionForm,
  payerList,
  checkEligibility,
  updateTestingGQL,
  insurancesList,
  savePreRegistration,
  searchEmployee,
  getDocuments,
  newDocumentCreateAPI,
  fetchDocumentAPI,
  updateDocumentAPI,
  deleteDocumentAPI,
  saveDocumentFile,
  saveResultFile,
  saveTemplateFile,
  deleteTestwithHRid,
  proxyContant,
  hrEmployeeListing,
  createHREmployee,
  updateExternalTest,
  addMultipleUserToGroup,
  removeMultipleUserFromGroup,
  addEmployeeRoles,
  addEmployee,
  updateEmployeeRoles,
  getEmployeeRoles,
  getEmployeeZoneSchedule,
  mainCrewListing,
  getTestByEmployee,
  getPGEmployeeData,
  deleteDuplicateEmployee,
  getCompanyZone,
  addTestResult,
  getPreRegistrationOrderLog,
  getShipBobOrderDetails,
  updateTestEmployeeData,
  getLabProvidedBarCode,
  getEmployees,
  getEmployeeGraphQl,
  deleteEmployeeGQL,
  getTodaysTest,
  getClients,
  getResultClients,
  getSitesByClient,
  getLabs,
  getLabsWithTests,
  createEmployee,
  requestTestKit,
  preRegisterEmployee,
  getPreRegisterByPhone,
  preRegisterNewEmployee,
  preRegisterClientShows,
  deletePreRegistration,
  preRegisterShowData,
  getEmployeeTestCount,
  getEmployeeLastShow,
  createTest,
  getTests,
  getPendingTests,
  sendToLab,
  getCurrentUser,
  getAllUsers,
  signIn,
  getCurrentLabUserTests,
  getAllCurrentLabUserTests,
  getCurrentLabUserProcessedTests,
  getTestGraphQL,
  getRecordById,
  getPRRecordById,
  changeBatchStatusToProcessing,
  getPendingReleaseTests,
  findTestByID,
  findTestByBarcode,
  findTestsByClient,
  releaseTest,
  setFalsePositiveTest,
  fetchUsersInGroup,
  fetchAllUsersInGroup,
  fetchAllUsers,
  changeUserRole,
  addUserToGroup,
  confirmUserSignUp,
  updateUserInfo,
  updateUserInfoV1,
  updateUserInfoV2,
  updateUserSecondRole,
  deleteUser,
  updateUserPassword,
  updatePatientPassword,
  sendSMSNotification,
  deleteEmployee,
  createLab,
  deleteLab,
  updateClientsHippa,
  updateSiteHippa,
  updateClient,
  updateProviderClient,
  updateSite,
  updateSiteArchive,
  updateLab,
  updateLabBarCodes,
  getLabBarCodes,
  updateLabLogo,
  updateBarCodeAlert,
  resetDefaultLab,
  getClientById,
  createSite,
  getSites,
  getSitesWithTests,
  getShows,
  createClient,
  deleteClient,
  deleteSite,
  getSitesByUser,
  getUserSiteData,
  findSiteByID,
  addUserToSite,
  removeUserToSite,
  addUserToLab,
  removeUserToLab,
  getUnprocessedTests,
  getUnSubmittedTest,
  restoreTests,
  getLabByID,
  getExternalTestList,
  submitUnprocessedTests,
  reSubmitUnprocessedTests,
  getTestTypes,
  getTestTypesOptions,
  getTestTypesForFilter,
  getTestTypesByName,
  getTestTypesByID,
  addTestTypes,
  updateTestTypes,
  archiveTestType,
  updateTestType,
  updateTestPathegon,
  findHippaConsent,
  getCompletedTests,
  getTotalAdminTests,
  deleteWrongTest,
  sendEmailToCrew,
  sendInvitationEmail,
  updateCrewInfo,
  updateCompleteCrewInfo,
  updateCompleteCrewInfoV1,
  updateEmailStatus,
  updateTestResult,
  getExpiredTest,
  updateQuarantineData,
  getTestAutoNumber,
  getTestListFromApi,
  exportToCalRedieData,
  getTestFromApi,
  getPreRegisterData,
  findExternalTestByID,
  getExternalTest,
  fetchAllCrewMembers,
  fetchAllEmployees,
  getPositiveTests,
  UpdateEndQuarantine,
  getUserSites,
  getEmployeeByIdGraphQl,
  getEmployeeByID,
  updateEmployeeGraphQL,
  getAllEmployees,
  updateExpiredTestResult,
  getTestLogs,
  getOrderLogs,
  getLogs,
  getPatientLogs,
  addTestLogs,
  updateTestLab,
  updateTestClientLab,
  getAppTestLogs,
  addTestAppLogs,
  addLogs,
  submitToLab,
  updateTestLabOnly,
  getAdminTestList,
  getShowPreRegistration,
  updateTestFalsePositive,
  formatTests,
  getPreRegistrationData,
  getTestByIdGraphQl,
  getTestByID,
  getTestBySequenceGraphQl,
  deleteUserLinkFromEmployee,
  updateTestForTest,
  hrCheckIn,
  getClientShowByApi,
  saveFileSendToLab,
  archiveOrder,
  archiveAllOrder,
  getUserPersonalisation,
  saveUserPersonalisation,
  getSettingForApp,
  updateLabArchive,
  getCptData,
  updateTestData,
  updateTestDataV1,
  updateClientArchive,
  cloneTest,
  getTestListFromPG,
  getEmployeeListFromPG,
  getSummaryFromPG,
  updateTestStatus,
  getTestBySequenceNo,
  downloadcsv,
  downloadEmployeeCSV,
  prTestCreation,
  saveFileExternalStorage,
  getFileExternalStorage,
  getProfileUpdateLogs,
  getPRPCRTest,
  getPregRegistrationPhoneData,
  updateSiteStatus,
  updateEmployeeProfile,
  getFileProfileStorage,
  getUserFromLocalDB,
  downloadPdf,
  downloadEmployeePdf,
  confirmEmployeeAccount,
  getEmployeesTests,
  isUserExistsInDB,
  changeTestType,
  employeeByIds,
  getProxyContactData,
  updateClientResultTypes,
  getTestFromAPi,
  getTestFromAPiQueryString,
  updateBarcodeNumber,
  saveFileCalredieImg,
  uploadFileCalredieCsv,
  deleteLogRecord,
  getHipaaEmployeeListFromPG,
  getOrderListFromPG,
  getOrderTestList,
  getOrderListThroughEmpID,
  hipaaTestSearch,
  UpdateHipaaTestAns,
  UpdateHipaaTestComplete,
  getHipaaEmployeeList,
  updateTestRecord,
  fetchTestFromByClientID,
  updateEmployeeRecord,
  searchProviderAPI,
  patientUpdateAPI,
  searchICTCode,
  searchCptCode,
  assignedTestSchedule,
  uploadPatientTestSchedule,
  getTodayScheduleTests,
  resetTestSchedule,
  upComingSchedule,
  removeTestSchedule,
  emptySiteUpdateStatus,
  updateTest,
  acknowledgedPositiveTest,
  saveOrderFileIntoBucket,
  saveOrdersFiles,
  createUploadResult,
  fetchUploadRequest,
  updateUploadRequest,
  deleteUploadRequest,
  clearUploadRequest,
  clearOrder,
  proxyContantFromPatient,
  clearUploadSubRequest,
  formatBiolabGenCSV,
  formatBioBillingCSV,
  downloadRequisitionZip,
  updateTestFromOrder,
  createPatientUser,
  verifyOtp,
  resendOtp,
  uploadPDFTemplate,
  getFileExternalBlob,
  updateOptions,
  updateTestLabel,
  deleteOption,
  getEligibilityList,
  createMessagesTemplate,
  updateMessagesTemplate,
  getMessagetTemplate,
  deleteRelations,
  deleteMessagesTemplate,
  importLabClient,
  getPanels,
  fetchTestDetail,
  deleteTestAssay,
  deletePanels,
  ordertestUpdate,
  changesTestStatus,
  restoreOrders,
  canelTests,
  updateResultOptions,
  createResultOptions,
};
