import React, { useEffect, useState } from "react";
import API from "api";
// react-bootstrap components
import {
  Badge,
  Button,
  Card,
  Navbar,
  Nav,
  Table,
  Container,
  Row,
  Col,
  Form,
  OverlayTrigger,
  Tooltip,
} from "react-bootstrap";
import moment from "moment";
import DashboardLineChart from "./DashboardLineChart";
import DashboardPieChart from "./DashboardPieChart";
import DashboardBarChart from "./DashboardBarChart";
import DashboardDougnutChart from "./DashboardDougnutChart";
import DashboardChartTotalTestsDataCard from "./DashboardChartTotalTestsData";
import DashboardChartsTableData from "./DashboardChartsTableData";

  const AdminDashboard = (props) => {

    const [totalTestCounts , setTotalCounts] = useState('');
    const [orderedClientNamesArray , setOrderedClientNames ] = useState([]);
    const [orderedLabNamesArray , setOrderedLabNames ] = useState([]);
    const [orderedSiteNamesArray , setOrderedSiteNames ] = useState([]);
    const totalCounts = (testCountsObj) => {
        setTotalCounts(testCountsObj);
    };
    const orderedClientNamesData = (orderedNames) => {
        setOrderedClientNames(orderedNames);
    };
    const orderedLabNamesData = (orderedNames) => {
        setOrderedLabNames(orderedNames);
    };
    const orderedSiteNamesData = (orderedNames) => {
        setOrderedSiteNames(orderedNames);
    };
return (
    <div className="dashboard-wrapper bg-transparent">
        <div className="container-fluid mb-3">
            <DashboardChartTotalTestsDataCard totalTestCounts={totalTestCounts} />
        </div>
        <div className="dashboard-wrapper">
            <div className="container-fluid">
                <div className="row">
                    <div className="col-12 col-md-6 col-lg-6 col-sm-12 col-xl-4">
                        <div className="bg-white rounded-3 pt-3 position-relative">
                           <div className="box-height p-0">
                               <div className="box-inner-height">
                               <DashboardLineChart statsType={'clientstats'} statsTitle="Clients" orderedClientNamesData={orderedClientNamesData} />
                               </div>
                           </div>
                          { orderedClientNamesArray && <DashboardChartsTableData statsType={'clientstats'} totalCounts={totalCounts} orderedClientNamesArray={orderedClientNamesArray}/> }
                        </div>
                    </div>

                    <div className="col-12 col-md-6 col-lg-6 col-sm-12 col-xl-4">
                        <div className="bg-white rounded-3 pt-3 position-relative">
                            <div className="box-height p-0">
                                <div className="box-inner-height">
                                    <DashboardLineChart statsType={'labstats'} statsTitle="Labs" orderedLabNamesData={orderedLabNamesData} />
                                </div>
                            </div>
                           { orderedLabNamesArray && <DashboardChartsTableData statsType={'labstats'} totalCounts={totalCounts} orderedLabNamesArray={orderedLabNamesArray}/> }
                        </div>
                    </div>

                    <div className="col-12 col-md-6 col-lg-6 col-sm-12 col-xl-4">
                        <div className="bg-white rounded-3 pt-3 position-relative">
                            <div className="box-height p-0">
                                <div className="box-inner-height">
                                    <DashboardLineChart statsType={'sitestats'} statsTitle="Sites"  orderedSiteNamesData={orderedSiteNamesData} />
                                </div>
                            </div>
                           { orderedClientNamesArray && <DashboardChartsTableData statsType={'sitestats'} orderedSiteNamesArray={orderedSiteNamesArray}/> }
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
  );
}

export default AdminDashboard;
