import React, { useState, useMemo, useEffect } from "react";
import { Modal, Button } from "react-bootstrap";
import ErrorMessage from "components/Message/ErrorMessage";
import AutoCompleteAddress from "components/AutoCompleteAddress";
import Select from "react-select";
import PhoneNoInput from "components/Phone/PhoneNoInput";
import { countryListAllIsoData } from "constant";
import api from "api";
import { getValidName } from "utils";
import SwitchToggle from "components/SwitchToggle/SwitchToggle";
import { isValidPhoneWithCode } from "utils";

const EmployeeModal = (props) => {
  const { handleClose, selectItem, handleSave } = props;
  const getObject = () => {
    const country = countryListAllIsoData.find((f) => f.name === selectItem.country) || { code: "US" };

    return { ...selectItem, isoCode: country.code };
  };

  const [error, setError] = useState("");
  const [newEmployee, setNewEmployee] = useState(getObject());

  const handleSaveChange = async (newEmployee) => {
    if (!newEmployee.firstName || !newEmployee.firstName.trim()) {
      setError("First Name required");
      return;
    }

    if (!newEmployee.lastName || !newEmployee.lastName.trim()) {
      setError("Last Name is required");
      return;
    }

    if (!newEmployee.email || !newEmployee.email.trim()) {
      setError("Email is required");
      return;
    }

    if (!isValidEmail(newEmployee.email)) {
      setError("Invalid Email");
      return;
    }

    if (!newEmployee.phoneNumber) {
      setError("Phone Number is required");
      return;
    }
    if (!isValidPhoneWithCode(newEmployee.phoneNumber)) {
      setError("Invalid Phone Number");
      return;
    }

    handleSave(newEmployee);
  };

  const handleUpdateAddress = (val, flag) => {
    if (!flag) {
      setNewEmployee({ ...newEmployee, street: val });
    } else {
      const addressObj = {
        street: val.street,
        country: val.country,
        city: val.city,
        zip: val.zipcode,
        state: val.state,
        isoCode: val.isoCode,
      };
      setNewEmployee({ ...newEmployee, ...addressObj });
    }
  };

  const handlePhoneNo = (e) => {
    setNewEmployee({
      ...newEmployee,
      phone_number: e,
    });
  };

  return (
    <Modal show animation={true} onHide={() => handleClose()} backdrop="static" centered size={"lg"}>
      <Modal.Header closeButton>
        <Modal.Title className="my-0" id="contained-modal-title-vcenter">
          {newEmployee.isNew ? "Create New Employee" : "Edit Employee Data"}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body style={{ paddingTop: 0 }}>
        <div className="createClientsWrapper">
          <div className="leftSide">
            <div className="row mb-3">
              <div className="form-group-wrapper">
                <label className="modalLineHeaders">First Name:</label>
                <input
                  value={newEmployee?.firstName}
                  className="modalInput"
                  placeholder="First Name"
                  style={{
                    width: "100%",
                  }}
                  onChange={(e) =>
                    setNewEmployee({
                      ...newEmployee,
                      firstName: getValidName(e.target.value),
                    })
                  }
                />
              </div>
              <div className="form-group-wrapper">
                <label className="modalLineHeaders mt-0">Last Name:</label>
                <input
                  type="text"
                  className="modalInput"
                  value={newEmployee.lastName}
                  placeholder="Last Name"
                  onChange={(e) => setNewEmployee({ ...newEmployee, lastName: getValidName(e.target.value) })}
                />
              </div>

              <div className="form-group-wrapper">
                <label className="modalLineHeaders">Email:</label>
                <input
                  type="email"
                  className="modalInput"
                  placeholder="Email"
                  value={newEmployee.email}
                  onChange={(e) => {
                    let val = e.target.value;
                    if (val) val = val.toLowerCase();
                    setNewEmployee({
                      ...newEmployee,
                      email: val,
                    });
                  }}
                />
              </div>

              <div className="form-group-wrapper phoneInput position-relative">
                <label className="modalLineHeaders">Phone Number:</label>
                <PhoneNoInput
                  handleChange={(e) =>
                    setNewEmployee({
                      ...newEmployee,
                      phoneNumber: e,
                    })
                  }
                  value={newEmployee?.phoneNumber}
                />
              </div>

              <div className="d-flex align-items-center column-gap-50">
                <label>Test Request:</label>
                <SwitchToggle
                  checked={newEmployee.isTest}
                  handleChange={(e) =>
                    setNewEmployee({
                      ...newEmployee,
                      isTest: e.target.checked,
                    })
                  }
                />
              </div>
            </div>

            {error && <ErrorMessage error={error} handleChange={() => setError("")} />}
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="primary" className="headerButton btn-fill" onClick={() => handleClose()}>
          Close
        </Button>
        <Button variant="secondary" className="headerButton btn-fill" onClick={() => handleSaveChange(newEmployee)}>
          {newEmployee.isNew ? "Create" : "Update"}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default EmployeeModal;
