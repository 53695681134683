import React from "react";
import TestSubmitResponse from "components/Modal/TestSubmitResponse";
import { Button, Modal } from "react-bootstrap";

const TestSubmitModal = (props) => {
  const { data, handleClose } = props;

  const parseSubmitMessage = (list) => {
    const message = [];
    list.forEach((obj) => {
      const showNames = obj.tests
        .map((item) => item.site_name)
        .filter((value, index, self) => self.indexOf(value) === index);
      showNames.forEach((s) => {
        message.push({ lab: obj.lab, show: s, tests: obj.tests.filter((f) => f.site_name === s).length });
      });
    });

    return message;
  };

  const successMessage = parseSubmitMessage(data.filter((s) => s.error === null));
  const errorMessage = parseSubmitMessage(data.filter((s) => s.error !== null));

  return (
    <Modal show animation={true} onHide={() => handleClose()} centered>
      <Modal.Header closeButton>
        <Modal.Title className="my-0" id="contained-modal-title-vcenter">
          Test Submit Result
        </Modal.Title>
      </Modal.Header>

      <Modal.Body style={{ paddingTop: 0 }}>
        <div className="form-group-wrapper d-block">
          <div className="table-responsive">
            <TestSubmitResponse title="Success Result" data={successMessage} />
            <TestSubmitResponse title="Error Result" data={errorMessage} />
          </div>
        </div>
      </Modal.Body>

      <Modal.Footer className="flex-row-reverse">
        <Button className="modalButtons" variant="primary" onClick={() => handleClose()}>
          OK
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default TestSubmitModal;
