import API from "api";
import logo from "assets/img/logo.jpg";
import { Auth } from "aws-amplify";
import Loader from "components/Loader/Loader";
import ConfirmationModal from "components/Modal/ConfirmationModal";
import MFPagination from "components/Pagination/MFPagination";
import LabClientModal from "./LabClientModal";
import { CONFIG, PAGE_LIMIT, TEST_ORDER_LIMIT_CATEGORY_VALUE, newLabClientObject, CLIENT_MANAGEMENT } from "constant";
import { AppContext } from "context/app-context";
import { useContext, useEffect, useState, useMemo } from "react";
import { Card, Col, Row } from "react-bootstrap";
import { QRCode } from "react-qrcode-logo";
import {
  checkValidity,
  emailMsgToCreatedFacility,
  getPreRegistrationLink,
  sortingFilterInLC,
  convertLabClientObject,
  onHandleDownloadSample,
} from "utils";
import Filter from "../../components/Filter";
import { t } from "../../stringConstants";

import ImportErrorModal from "components/Modal/ImportErrorModal";
import LocationImportModal from "components/Modal/LocationImportModal";
import PersonalizationModal from "components/Modal/personalizationModal";
import MainTable from "components/Table/MainTable";
import { MESSAGE_MODES, LAB_CLIENT_CSV_COLUMNS, TABLE_QUICK_TOOLS, LAB_CLIENT_DROPDOWN_OPTIONS } from "constant";
import { LAB_CLIENT_PERSONALIZE } from "constants/personalization";
import { formatPhoneNumberIntl } from "react-phone-number-input";
import { useDispatch, useSelector } from "react-redux";
import { setMessage } from "store/features/general/generalAction";
import { downloadDataAsCSV, formatNumber, getValidReg, tdLink } from "utils";
import Icon from "../../components/Icon";
import { selectLoginUser } from "store/features/authentication/authenticationSelectors";
import { fetchAllLocations, createLabClient, updateLabClient } from "store/features/locations/locationsSlice";
import { locationsSelectors } from "store/features/locations/locationsSelectors";
import NotesModal from "components/Notes/NotesModal";
import ClientProviderModal from "components/Modal/ClientProviderModal";
import { providerList } from "store/features/providers/providersSelectors";
import { draggablePersonalizationLocalStorage } from "utils";
import { PERSONALISATION_KEY } from "constant";
import { userGivenPermission } from "store/features/authentication/authenticationSelectors";

const LabClient = () => {
  const appContext = useContext(AppContext);
  const [locations, setLocations] = useState([]);
  const [filteredLocations, setFilteredLocations] = useState([]);
  const [itemToDelete, setItemToDelete] = useState(null);
  const [loading, setLoading] = useState(false);
  const [openCreateModal, setOpenCreateModal] = useState(false);
  const [selectItem, setSelectItem] = useState(newLabClientObject);
  const [sortBy, setSortBy] = useState("");
  const [sortDescending, setSortDescending] = useState(true);
  const [showFilter, setShowFilter] = useState(false);
  const [filter, setFilter] = useState({});
  const [triggerFilter, setTriggerFilter] = useState();
  const [currentPage, setCurrentPage] = useState(1);
  const [showCopyText, setShowCopyText] = useState("");
  const [personalize, setPersonalize] = useState([]);
  const [openPersonalizationModal, setOpenPersonalizationModal] = useState(false);
  const [orders, setOrders] = useState([]);
  const [openImportModal, setOpenImportModal] = useState(false);
  const [openErrModal, setOpenErrModal] = useState(false);
  const [title, setTitle] = useState("");
  const [errorData, setErrorData] = useState([]);
  const [successData, setsuccessData] = useState([]);
  const [checkboxes, setCheckboxes] = useState([]);
  const [noteModal, setNoteModal] = useState(null);
  const [providerModal, setProviderModal] = useState(null);
  const dispatch = useDispatch();
  const permission = useSelector(userGivenPermission);

  const quickTools = [
    TABLE_QUICK_TOOLS.checkbox,
    permission[CLIENT_MANAGEMENT]?.write && TABLE_QUICK_TOOLS.edit,
    permission[CLIENT_MANAGEMENT]?.write && TABLE_QUICK_TOOLS.notes,
    "provider",
  ];
  const user = useSelector(selectLoginUser);
  const labLocations = useSelector(locationsSelectors);

  const PROVIDER_OPTIONS = useSelector(providerList);
  // console.log(labLocations)

  const searchTerms = ["Name", "Contact Name", "Contact Email", "Contact Phone", "practiceID"];

  const currentItems = (locs) => {
    if (!locs || locs.length === 0) return [];
    const indexOfLastUser = currentPage * PAGE_LIMIT;
    const indexOfFirstUser = indexOfLastUser - PAGE_LIMIT;
    return locs.slice(indexOfFirstUser, indexOfLastUser);
  };

  const locationsToMap = useMemo(() => {
    return currentItems(filteredLocations);
  }, [filteredLocations]);

  const sortData = (schs, sortParam) => {
    if (sortDescending) {
      return [...schs].sort((a, b) => (a[sortParam] < b[sortParam] ? 1 : b[sortParam] < a[sortParam] ? -1 : 0));
    } else {
      return [...schs].sort((a, b) => (b[sortParam] < a[sortParam] ? 1 : a[sortParam] < b[sortParam] ? -1 : 0));
    }
  };

  const getLabClients = async () => {
    try {
      await dispatch(fetchAllLocations());
    } catch (err) {
      console.log("Error:-", err);
    }
  };

  useEffect(() => {
    const sortLS = sortingFilterInLC.get();
    if (sortLS.facilities && sortLS.facilities.sortBy) {
      setSortBy(sortLS.facilities.sortBy);
      setSortDescending(sortLS.facilities.sortDescending);
    }
    // if (sortLS.facilities && sortLS.facilities.filter) {
    //   setFilter(sortLS.facilities.filter);
    // }
    getLabClients();
    const response = draggablePersonalizationLocalStorage.get(
      user,
      PERSONALISATION_KEY.LAB_CLIENT,
      LAB_CLIENT_PERSONALIZE
    );
    setPersonalize(response);
  }, [appContext?.company]);

  const getPageNumbers = (users) => {
    const pageNumbers = users.length > PAGE_LIMIT ? Math.ceil(users.length / PAGE_LIMIT) : 1;
    return pageNumbers;
  };

  let pageNumbers = getPageNumbers(locations);

  if (filteredLocations && filteredLocations.length > 0) {
    pageNumbers = getPageNumbers(filteredLocations);
  }

  useEffect(() => {
    if (labLocations && labLocations.length > 0) {
      formatFacility();
    }
    // setLocations(
    //   appContext.locations?.filter((f) => f.id !== CONFIG.generalFacility)
    // );
    // setFilteredLocations(
    //   appContext.locations?.filter((f) => f.id !== CONFIG.generalFacility)
    // );
  }, [labLocations, orders]);

  // useEffect(() => {
  //   const hasDeletePermission = permission[CLIENT_MANAGEMENT]?.delete;
  //   const deleteOptionIndex = LAB_CLIENT_DROPDOWN_OPTIONS.findIndex((option) => option.value === "delete");
  //   console.log("I am here in client management");
  //   if (!hasDeletePermission && deleteOptionIndex !== -1) {
  //     LAB_CLIENT_DROPDOWN_OPTIONS.splice(deleteOptionIndex, 1); // Remove the delete option if it exists
  //   } else if (hasDeletePermission && deleteOptionIndex === -1) {
  //     LAB_CLIENT_DROPDOWN_OPTIONS.push({ value: "delete", title: "Archive" });
  //   }
  // }, [CLIENT_MANAGEMENT]);

  const LAB_CLIENT_DROPDOWN = useMemo(() => {
    const hasDeletePermission = permission[CLIENT_MANAGEMENT]?.delete;
    const deleteOption = { value: "delete", title: "Archive" };

    const newDropdownOptions = LAB_CLIENT_DROPDOWN_OPTIONS.filter((option) => option.value !== "delete");

    if (hasDeletePermission) {
      newDropdownOptions.push(deleteOption);
    }

    return newDropdownOptions;
  }, [permission[CLIENT_MANAGEMENT]?.delete]);

  const getAssociateClient = (id) => {
    let res = PROVIDER_OPTIONS.filter((provider) => {
      return provider.associatedClient === id;
    });
    return res.length;
  };

  const formatFacility = () => {
    let facilities = labLocations.map((item) => ({ ...item, associatedClients: getAssociateClient(item?.id) }));

    setLocations(facilities);
    setFilteredLocations(sortData(nestedFilter(facilities, filter), sortBy));
  };

  const handleSaveFilter = () => {
    const pervSortLS = sortingFilterInLC.get();
    pervSortLS.facilities = { sortBy, sortDescending, filter };
    sortingFilterInLC.save(pervSortLS);
  };

  useEffect(() => {
    if (locations.length > 0) {
      setFilteredLocations(sortData(nestedFilter(locations, filter), sortBy));
      setCurrentPage(1);
    }
  }, [filter]);

  useEffect(() => {
    if (filteredLocations.length > 0) {
      handleSaveFilter();
      setFilteredLocations(sortData(filteredLocations, sortBy));
    }
  }, [sortBy, sortDescending]);

  const handleConfirmDelete = async (isConfirm) => {
    const deletedItemRow = itemToDelete;
    setItemToDelete(null);
    if (!isConfirm) {
      return;
    }
    try {
      setLoading(true);

      const isDeleted = await API.updateSiteArchive(deletedItemRow.id, true);
      dispatch(setMessage("Client Archived successfully", MESSAGE_MODES.success));
      if (isDeleted) {
        setFilteredLocations((prevLocations) => prevLocations.filter((location) => location.id !== deletedItemRow.id));
        setLocations((prevLocations) => prevLocations.filter((location) => location.id !== deletedItemRow.id));
      }
      getLabClients();
      appContext?.resetSites();
      setLoading(false);
    } catch (error) {
      setLoading(false);
      dispatch(setMessage(error.message, MESSAGE_MODES.error));
    }
  };

  const OnHandleLocation = async (loc) => {
    const findVal = locations.find((f) => loc.id !== f.id && checkValidity(f.name) == checkValidity(loc.name));
    if (findVal) {
      dispatch(setMessage("Client already exist", MESSAGE_MODES.error));
      return;
    }
    setOpenCreateModal(false);

    try {
      if (loc.isNew) {
        const res = await dispatch(createLabClient({ ...loc, siteType: "client" }));
        if (res) {
          dispatch(setMessage("Client Created Successfully", MESSAGE_MODES.success));
        }
      } else {
        await dispatch(updateLabClient({ ...loc, siteType: "client" }));
        dispatch(setMessage("Client Updated Successfully", MESSAGE_MODES.success));
      }
      setSelectItem(newLabClientObject);
      appContext.resetSites();
    } catch (err) {
      console.log("Error", err);
      dispatch(setMessage(err.message, MESSAGE_MODES.error));
    }
  };

  const createUser = async (newUser) => {
    const firstName = newUser.contact_name.split(" ")[0] || "";
    const lastName = newUser.contact_name.split(" ")[1] || "";
    const attributePayload = {
      preferred_username: newUser.phone_number,
      email: newUser.contact_email,
      phone_number: newUser.phone_number,
      "custom:role": "Employers",
      "custom:autoConfirm": "true",
      "custom:firstName": `${firstName}`,
      "custom:lastName": `${lastName}`,
      "custom:note": `${newUser.contact_name}`,
      "custom:labID": newUser.id,
      "custom:personalisation": JSON.stringify({
        personalize: "1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17",
      }),
    };

    try {
      const signUp = await Auth.signUp({
        username: newUser.phone_number,
        password: newUser.password,
        attributes: attributePayload,
      });
      if (signUp) {
        const message = emailMsgToCreatedFacility(
          newUser.contact_name,
          newUser.phone_number.replaceAll(/[^0-9]/gi, ""),
          newUser.password
        );
        await API.sendEmail([
          {
            email: newUser.contact_email,
            subject: "SafeCamp LTC Team",
            msg: message,
          },
        ]);
      }
      return true;
    } catch (error) {
      console.log("ERROR createUser: ", error);
      dispatch(setMessage(error.message, MESSAGE_MODES.error));
      return false;
    }
  };

  const handleEdit = (item) => {
    setSelectItem({ ...item, isNew: false });
    setOpenCreateModal(true);
  };

  const flipSort = (by) => {
    setSortDescending(sortBy === by ? !sortDescending : true);
    setSortBy(by);
  };

  const downloadQRCode = (site) => {
    const canvas = document.getElementById("qr-gen");
    const pngUrl = canvas.toDataURL("image/png").replace("image/png", "image/octet-stream");
    let downloadLink = document.createElement("a");
    downloadLink.href = pngUrl;
    downloadLink.download = `${site.name}.png`;
    document.body.appendChild(downloadLink);
    downloadLink.click();
    document.body.removeChild(downloadLink);
  };
  const handleCopyPRLink = (id) => {
    navigator.clipboard.writeText(getPreRegistrationLink(id));
    setShowCopyText(id);
    setTimeout(() => setShowCopyText(null), 2000);
  };

  const nestedFilter = (targetArray, filters) => {
    if (Object.keys(filters).length === 0) return targetArray;
    const filterKeys = Object.keys(filters);

    const models = targetArray.filter((obj) => {
      return filterKeys.every((key) => {
        if (!filters[key].length) {
          return true;
        }

        return obj[key] && obj[key].toLowerCase().includes(filters[key]);
      });
    });
    return models;
  };

  const handleChangePage = (number) => {
    setCurrentPage(number);
  };

  const handlePersonalization = async (data) => {
    setPersonalize([...data]);
    const personalisationData = draggablePersonalizationLocalStorage.saveAs(data, PERSONALISATION_KEY.LAB_CLIENT);
    setOpenPersonalizationModal(false);
    draggablePersonalizationLocalStorage.save(JSON.stringify(personalisationData));
    await API.saveUserPersonalisation(appContext.user.sub, personalisationData);
  };

  const parseClient = (item) => {
    if (item.client) item.client.name;
    if (!item.clientID) return "N/A";

    if (appContext.companies) {
      const client = appContext.companies.find((c) => c.id === item.clientID);
      if (client) {
        return client.name;
      }
    }
    return "N/A";
  };

  const handleClientImport = async ({ locData, clientID }) => {
    const arr = [];
    const arrdata = [];
    const locList = [...locations];
    const locationKeysArr = Object.keys(locData[0].data);
    if (locationKeysArr.length > 0) {
      let err = false;
      LAB_CLIENT_CSV_COLUMNS.forEach((f, i) => {
        if (!locationKeysArr.includes(f)) {
          dispatch(setMessage(`Column Header must be ${f}`, MESSAGE_MODES.error));
          setOpenImportModal(null);
          err = true;
          return;
        }
      });
      if (err) return;
    }

    setOpenImportModal(null);
    setLoading(true);
    const ttlLength = locData.length;

    for (let index = 0; index < ttlLength; index++) {
      const { data } = locData[index];

      if (Object.values(data).every((c) => c.length == 0)) continue;

      const loc = convertLabClientObject(data, clientID);

      if (loc.name) {
        loc.name = getValidReg(loc.name);

        const findVal = locList.find((f) => {
          const isNameExists = false; // checkValidity(f.name) == checkValidity(loc.name);
          const isPracticeID = f.practiceID === loc.practiceID;
          return isNameExists || isPracticeID;
        });

        if (findVal) {
          arr.push({
            message: `Client name record already exists on row ${index + 2}`,
            data: loc,
          });
          continue;
        }
      } else {
        arr.push({
          message: `name is required on row ${index + 2}`,
          data: loc,
        });
        continue;
      }

      // if (!loc.phone_number) {
      //   arr.push({
      //     message: `phone number is required on row ${index + 2}.`,
      //     data: loc,
      //   });
      //   continue;
      // }
      if (loc.phone_number) {
        const phone = formatPhoneNumberIntl(loc.phone_number);

        const phoneArr = phone.split(" ");
        loc.countryCode = phoneArr[0];
      }

      // if (loc.phone_number && !validatePhone(loc.phone_number)) {
      //   arr.push({
      //     message: `phone number is invalid ${loc.phone_number} on row ${index + 2}`,
      //     data: loc,
      //   });
      //   continue;
      // }

      arrdata.push(loc);
      locList.push(loc);
    }

    setOpenImportModal(null);
    if (arrdata.length == 0 && arr.length === 0) {
      dispatch(setMessage(t("emptyFile"), MESSAGE_MODES.error));
      setLoading(false);
      return;
    }

    if (arr.length > 0) {
      setErrorData(arr);
      setTitle("Client");
      setOpenErrModal(true);
      setsuccessData([]);
      setLoading(false);
      return;
    }

    let sucessfullyProcessed = [];

    try {
      const res = await API.importLabClient({ clients: arrdata });
      // const res = [];
      arrdata.forEach((item) => {
        if (!res[item.uuid].status) {
          arr.push({
            message: `Fail to save the record`,
            data: item,
          });
        } else {
          sucessfullyProcessed.push(item);
        }
      });
    } catch (ex) {
      console.log("Ex", ex);
      dispatch(setMessage(t("failtoimportdata"), MESSAGE_MODES.error));
    }
    setLoading(false);
    if (arr.length > 0 || sucessfullyProcessed.length > 0) {
      setErrorData(arr);
      setTitle("Client");
      setsuccessData(sucessfullyProcessed);
      setOpenErrModal(true);
    }
  };

  const handleImport = async (locData) => {
    const clientID = CONFIG.clientID;

    if (locData.length === 0) {
      dispatch(setMessage(t("emptyFile"), MESSAGE_MODES.error));
      setOpenImportModal(false);
      return;
    }
    if (!clientID) {
      dispatch(setMessage("Client was not selected", MESSAGE_MODES.error));
      return;
    }

    handleClientImport({ locData, clientID });
  };

  const tdPreRegisteration = (user) => {
    return (
      <td className="ellipsis position-relative" style={{ textAlign: "center" }}>
        {user.preRegistration && (
          <div className="d-flex align-items-center justify-content-evenly qr-code-download">
            {showCopyText && showCopyText === user.id && (
              <div className="copy-link-text clipBoard-copy">Copied to clipboard!</div>
            )}
            <div onClick={() => handleCopyPRLink(user.id)}>
              <i className="fas fa-copy maroon" />
            </div>
            <div onClick={() => downloadQRCode(user)}>
              <i className="fas fa-download maroon"></i>
            </div>
            <span style={{ display: "none" }}>
              <QRCode
                id="qr-gen"
                ecLevel={"H"}
                value={getPreRegistrationLink(user.id)}
                enableCORS={true}
                logoImage={logo}
                size="250"
                logoHeight={"70"}
                logoWidth={"70"}
                fgColor={"#000"}
                bgColor={"#fff"}
              />
            </span>
          </div>
        )}
      </td>
    );
  };

  const tdFormat = (item, user) => {
    if (item === "testOrderCategory") return TEST_ORDER_LIMIT_CATEGORY_VALUE[user.testOrderCategory];
    return user[item];
  };

  const renderTd = (item, user) => {
    if (item.itemKey === "monthToDate") return tdLink(user.monthToDate, "month", user.id, "siteID");
    if (item.itemKey === "yearToDate") return tdLink(user.yearToDate, "year", user.id, "siteID");
    if (item.itemKey === "totalOrders") return tdLink(user.totalOrders, "", user.id, "siteID");
    if (item.itemKey === "preRegisterationLink") return tdPreRegisteration(user);
    return (
      <td
        className="ellipsis"
        style={{
          textAlign: item.textAlign,
          textOverflow: item.textOverflow,
        }}
        title={tdFormat(item.itemKey, user)}
      >
        {tdFormat(item.itemKey, user)}
      </td>
    );
  };
  const handleCheckboxChange = (e, row) => {
    const filteredList = checkboxes.filter((c) => c !== row.id);
    if (e.target.checked) {
      filteredList.push(row.id);
    }
    setCheckboxes(filteredList);
  };

  const showClientLogs = () => {
    // Show client logs modals call here
  };

  const handleCellClick = (key, row, event) => {
    switch (key) {
      case TABLE_QUICK_TOOLS.edit:
        if (row.id !== "596daf9b-8f9d-451c-a28f-a458bac57ce4" && row.id !== "a081f285-4949-45bd-9516-ba2ff3e8d0b1") {
          handleEdit(row);
        }
        break;
      case TABLE_QUICK_TOOLS.checkbox:
        handleCheckboxChange(event, row);
        break;
      case TABLE_QUICK_TOOLS.notes:
        setNoteModal(row);
        break;
      case "associatedClients":
        if (row["associatedClients"]) setProviderModal(row);
        break;
      default:
        break;
    }
  };

  return (
    <div style={{ flex: 1 }}>
      <Row>
        <Col md="12">
          {!loading ? (
            <Card
              className={`strpied-tabled-with-hover  ${
                locationsToMap?.length === 1 || locationsToMap?.length === 2 ? "test-details-table" : ""
              }`}
            >
              <Card.Header>
                <Card.Title
                  as="h4"
                  style={{
                    marginBottom: 10,
                    fontWeight: "bold",
                  }}
                >
                  {t("labClient")} ({filteredLocations ? formatNumber(filteredLocations.length) : 0})
                </Card.Title>
                <div className="buttonHeader">
                  <Icon
                    handleClick={() => setShowFilter(!showFilter)}
                    title={"Filter"}
                    label={"Filter"}
                    iconType={"filter"}
                  />
                  <Icon
                    handleClick={() => setOpenPersonalizationModal(true)}
                    title={t("personalize")}
                    iconType="personalizeIcon"
                    label={t("personalize")}
                  />

                  {permission[CLIENT_MANAGEMENT]?.write && (
                    <Icon
                      handleClick={() => {
                        const obj = { ...newLabClientObject };

                        setSelectItem({ ...obj, clientID: CONFIG.clientID });

                        setOpenCreateModal(true);
                      }}
                      label={"Create"}
                      title={"Create Facility"}
                      iconType={"addUserIcon"}
                    />
                  )}

                  {/* <Icon
                    handleClick={handleDownloadSample}
                    title={"Download Csv Sample"}
                    iconType="downloadIcon"
                    label="Download"
                  /> */}

                  {permission[CLIENT_MANAGEMENT]?.write && (
                    <Icon
                      handleClick={() => {
                        setOpenImportModal(true);
                      }}
                      title={"Import Client"}
                      iconType="importIcon"
                      label="Load Clients"
                    />
                  )}
                </div>
                {showFilter && (
                  <Filter
                    filterTerms={searchTerms}
                    setFilter={setFilter}
                    filter={filter}
                    triggerFilter={triggerFilter}
                    setTriggerFilter={setTriggerFilter}
                  />
                )}
                <MFPagination currentPage={currentPage} handleChangePage={handleChangePage} totalPages={pageNumbers} />
              </Card.Header>
              <Card.Body className="table-full-width desktop-noScroll">
                <MainTable
                  cssClass={locations.length === 0 && "overFlow-y-hidden"}
                  columns={personalize}
                  rows={locationsToMap}
                  flipSort={flipSort}
                  sortBy={sortBy}
                  sortDescending={sortDescending}
                  widthToSkip={160}
                  selectedRows={checkboxes}
                  draggable
                  resizable
                  personalisationKey={PERSONALISATION_KEY.LAB_CLIENT}
                  handleSaveDragAndResize={(personalizeArr) => setPersonalize(personalizeArr)}
                  tools={quickTools}
                  dropDownOptions={LAB_CLIENT_DROPDOWN}
                  handleDropDownClick={(type, row) => {
                    if (type === "Archive") {
                      if (
                        row.id !== "596daf9b-8f9d-451c-a28f-a458bac57ce4" &&
                        row.id !== "a081f285-4949-45bd-9516-ba2ff3e8d0b1"
                      ) {
                        setItemToDelete(row);
                      }
                    }
                    if (type === "Audit Trail") {
                      console.log("row", row);
                      appContext.showLogs({ ...row, recordType: "logs", title: `Client Logs: ${row.name}` });
                    }
                  }}
                  handleCellClick={handleCellClick}
                  customColumnCellRenderer={renderTd}
                />
              </Card.Body>
            </Card>
          ) : (
            <Loader />
          )}

          {itemToDelete && (
            <ConfirmationModal
              show={itemToDelete ? true : false}
              title="Remove Client"
              message="Are you sure, you want to remove Client?"
              handleConfirm={handleConfirmDelete}
            />
          )}

          {noteModal && (
            <NotesModal
              handleClose={(obj) => {
                setNoteModal(null);
              }}
              labClient={true}
              user={user}
              data={noteModal}
            />
          )}
          {providerModal && (
            <ClientProviderModal
              handleClose={(obj) => {
                setProviderModal(null);
              }}
              user={user}
              data={providerModal}
            />
          )}
          {openCreateModal && (
            <LabClientModal
              handleClose={() => {
                setSelectItem(newLabClientObject), setOpenCreateModal(false);
              }}
              selectItem={selectItem}
              handleSave={OnHandleLocation}
              appContext={appContext}
            />
          )}

          {openPersonalizationModal && (
            <PersonalizationModal
              data={personalize}
              handleChange={handlePersonalization}
              show={openPersonalizationModal}
              handleClose={() => setOpenPersonalizationModal(false)}
            />
          )}
          {openImportModal && (
            <LocationImportModal
              handleClose={() => setOpenImportModal(false)}
              handleImport={handleImport}
              appContext={appContext}
              handleDownloadSample={() => onHandleDownloadSample()}
            />
          )}

          {openErrModal && (
            <ImportErrorModal
              location
              title={title}
              data={{ errorData, successData }}
              handleClose={async () => {
                setOpenErrModal(false);
                setErrorData([]);
                setsuccessData([]);
                setTitle("");
                dispatch(fetchAllLocations());
              }}
            />
          )}
        </Col>
      </Row>
    </div>
  );
};

export default LabClient;
