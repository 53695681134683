import React, { useContext, useState, useMemo, useRef, useEffect } from "react";
import { Button, Card, Table, Row, Col, Modal, Form, OverlayTrigger, Alert } from "react-bootstrap";
import TextEditor from "views/TextEditor";
import { DISCLAIMER_TYPE } from "constant";
import { DISCLAIMER_KEYS } from "constant";
import PhoneNoInput from "components/Phone/PhoneNoInput";
import { parseBooleanValue, isValidPhone } from "utils";
import ViewOnlyCheckBox from "components/CheckBox/ViewOnlyCheckBox";
import Loader from "components/Loader/Loader";
import API from "api";
import ErrorMessage from "components/Message/ErrorMessage";

const LabModal = (props) => {
  const { lab, handleClose, image, appContext } = props;
  const [newLab, setNewLab] = useState(lab);
  const [selectedImage, setSelectedImage] = useState(image);
  const [logo, setLogo] = useState(null);
  const [showEditor, setShowEditor] = useState(null);
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const inputFile = useRef();

  useEffect(() => {
    setNewLab(newLab);
    setSelectedImage(image);
  }, [lab]);

  const handleImageClick = () => {
    if (inputFile.current) {
      inputFile.current.click();
    }
  };

  const handleDisclaimerSave = (val, type) => {
    setShowEditor(null);
    setNewLab({ ...newLab, [DISCLAIMER_KEYS[type]]: val });
  };

  const handleSave = () => {
    if (newLab.isNew) {
      createLab();
    } else {
      updateLab();
    }
  };

  const isValidToSave = async (obj, isNew) => {
    if (!obj.name) {
      setError("Lab name is required");
      return false;
    }

    if (!obj.contact) {
      setError("Lab primary contact is required");
      return false;
    }

    if (!obj.email) {
      setError("Lab email is required");
      return false;
    }

    if (!obj.phone) {
      setError("Lab phone is required");
      return false;
    }

    if (!obj.street) {
      setError("Lab address is required");
      return false;
    }

    if (!obj.city_state_zip) {
      setError("Lab city/state/zip is required");
      return false;
    }

    if (!obj.lab_director) {
      setError("Lab director name is required");
      return false;
    }

    if (!obj.clia_number) {
      setError("Lab CLIA# is required");
      return false;
    }

    if (!obj.testing_notes) {
      setError("Lab Disclaimer is required");
      return false;
    }

    if (!isValidPhone(obj.phone)) {
      setError("Invalid Phone Number");
      return false;
    }

    if (obj.default_antigen) {
      await API.resetDefaultLab(obj.id, "default_antigen");
    }

    if (obj.default_molecular) {
      await API.resetDefaultLab(obj.id, "default_molecular");
    }

    if (obj.default_rapidflu) {
      await API.resetDefaultLab(obj.id, "default_rapidflu");
    }

    if (obj.default_lucira) {
      await API.resetDefaultLab(obj.id, "default_lucira");
    }

    if (obj.default_other) {
      await API.resetDefaultLab(obj.id, "default_other");
    }

    return true;
  };

  const createLab = async () => {
    const validInput = await isValidToSave(newLab, true);
    if (!validInput) {
      return;
    }
    handleClose();

    try {
      setLoading(true);
      const newest = await API.createLab(newLab);
      if (logo) {
        const isLogoSave = await uploadLogo(newest.id);
        if (isLogoSave) {
          await API.updateLabLogo(newest.id, `labs/${newest.id}-logo.png`);
        }
      }
      setLogo(null);
      setNewLab({});
      appContext.resetLabs();
      appContext.showSuccessMessage("Lab created successfully.");
    } catch (error) {
      console.log(error);
      appContext.showErrorMessage(error.message);
    }
    setLoading(false);
  };

  const updateLab = async () => {
    const validInput = await isValidToSave(newLab, false);
    if (!validInput) {
      return;
    }

    handleClose();
    const obj = { ...newLab };
    try {
      setLoading(true);
      if (logo) {
        const isLogoSave = await uploadLogo(obj.id);
        if (isLogoSave) Object.assign(obj, { logo: `labs/${obj.id}-logo.png` });
      }
      await API.updateLab(obj);
      setLogo(null);
      setNewLab({});
      appContext.resetLabs();
      appContext.showSuccessMessage("Lab update successfully");
    } catch (error) {
      console.log("Error", error);
      appContext.showErrorMessage(error.message);
    }
    setLoading(false);
  };

  const onChangeImage = async (event) => {
    if (event.currentTarget.files && event.currentTarget.files.length > 0) {
      const file = event.currentTarget.files[0];
      const isValid = await isValidImageHeightWidth(file);
      if (!isValid) {
        // setError("File resolution should be upto 1500px*500px and less then 1600px*600px");
        setError("File resolution should be min 1200px*400px");
        setLogo("");
        setSelectedImage("");
        return;
      }
      const message = isValidFile(file);
      if (message.length > 0) {
        setLogo();
        setSelectedImage();
        setError(message);
        return;
      }

      setLogo(file);
      setSelectedImage(URL.createObjectURL(file));
    }
  };

  const isValidImageHeightWidth = (file) => {
    return new Promise((resolve, reject) => {
      const img = new Image();
      img.src = window.URL.createObjectURL(file);
      img.onload = () => {
        console.log("Image ", img.width, img.height);
        if (img.width >= 1200 && img.width <= 1600 && img.height >= 400 && img.height <= 600) {
          resolve(true);
        }
        resolve(false);
      };
      img.onerror = () => resolve(false);
    });
  };

  return (
    <Modal className="createLabModal" show={true} animation={true} onHide={handleClose} centered size="xl">
      <Modal.Header className="mb-0" closeButton>
        <Modal.Title className="my-0" id="contained-modal-title-vcenter">
          {newLab.isNew ? "Create Lab" : "Update Lab"}
        </Modal.Title>
      </Modal.Header>

      <Modal.Body style={{ paddingTop: "0", paddingBottom: "0" }}>
        {loading && <Loader />}
        <div className="createShowWrapper">
          <div className="createShowInner">
            <div className="createClientsWrapper">
              <div className="form-group-wrapper">
                <label className="modalLineHeaders">Lab Name: </label>
                <input
                  id="labname"
                  className="labModalInput"
                  placeholder="Lab Name"
                  disabled={!newLab.isNew}
                  value={newLab.name}
                  onChange={(e) => setNewLab({ ...newLab, name: e.target.value })}
                />
              </div>
              <div className="form-group-wrapper">
                <label className="modalLineHeaders">Contact Name: </label>
                <input
                  id="contactname"
                  className="labModalInput"
                  placeholder="Primary Contact"
                  value={newLab.contact}
                  onChange={(e) => setNewLab({ ...newLab, contact: e.target.value })}
                />
              </div>
              <div className="form-group-wrapper">
                <label className="modalLineHeaders">Email: </label>
                <input
                  id="contactemail"
                  className="labModalInput"
                  placeholder="Email"
                  value={newLab.email}
                  onChange={(e) => setNewLab({ ...newLab, email: e.target.value })}
                />
              </div>
              <div className="form-group-wrapper">
                <label className="modalLineHeaders">Phone Number: </label>

                <PhoneNoInput
                  value={newLab.phone}
                  handleChange={(e) => setNewLab({ ...newLab, phone: e })}
                  cssCode={"w-100"}
                />
              </div>
              <div className="form-group-wrapper">
                <label className="modalLineHeaders">Fax Number: </label>

                <PhoneNoInput
                  value={newLab.fax_number}
                  handleChange={(e) => setNewLab({ ...newLab, fax_number: e })}
                  cssCode={"w-100"}
                />
              </div>
              <div className="form-group-wrapper">
                <label className="modalLineHeaders">Address: </label>
                <input
                  className="modalInput"
                  id="street"
                  placeholder="Address"
                  value={newLab.street}
                  onChange={(e) => setNewLab({ ...newLab, street: e.target.value })}
                />
              </div>
              <div className="form-group-wrapper">
                <label className="modalLineHeaders">City/State/Zip: </label>
                <input
                  id="city_state_zip"
                  style={{
                    marginTop: 5,
                  }}
                  className="modalInput"
                  placeholder="City, State Zip"
                  value={newLab.city_state_zip}
                  onChange={(e) => setNewLab({ ...newLab, city_state_zip: e.target.value })}
                />
              </div>
              <div className="form-group-wrapper">
                <label className="modalLineHeaders">Lab Director: </label>
                <input
                  id="lab_director"
                  className="labModalInput"
                  placeholder="Lab Director"
                  value={newLab.lab_director}
                  onChange={(e) => setNewLab({ ...newLab, lab_director: e.target.value })}
                />
              </div>
              <div className="form-group-wrapper">
                <label className="modalLineHeaders">CLIA #: </label>
                <input
                  id="clia_number"
                  className="labModalInput"
                  placeholder="CLIA Number"
                  value={newLab.clia_number}
                  onChange={(e) => setNewLab({ ...newLab, clia_number: e.target.value })}
                />
              </div>
              <div className="form-group-wrapper">
                <label className="modalLineHeaders">Referred DOC: </label>
                <input
                  id="referredDoctor"
                  className="labModalInput"
                  placeholder="Doctor Name"
                  value={newLab?.referredDoctor?.name || ""}
                  onChange={(e) => setNewLab({ ...newLab, referredDoctor: { name: e.target.value } })}
                />
              </div>

              <div className="form-group-wrapper">
                <label className="modalLineHeaders">Disclaimer: </label>
                <div
                  className="linkedText"
                  onClick={() => setShowEditor({ type: DISCLAIMER_TYPE.DISCLAIMER, message: newLab.testing_notes })}
                >
                  {newLab.isNew ? "Edit Text" : "Update Text"}
                </div>
                {showEditor && (
                  <TextEditor
                    text={showEditor.message ?? ""}
                    handleSave={handleDisclaimerSave}
                    handleClose={() => setShowEditor(null)}
                    type={showEditor.type}
                  />
                )}
              </div>
              <div className="form-group-wrapper">
                <label className="modalLineHeaders">Logo: </label>
                <div className="inputFileWrapper" onClick={handleImageClick}>
                  {selectedImage && <img src={selectedImage} />}
                  <input
                    type="file"
                    ref={inputFile}
                    accept="image/*"
                    onChange={(e) => onChangeImage(e)}
                    style={{ display: "none" }}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="createSettingsWrapper">
            <div>
              <div className="form-group-wrapper showsModal">
                <div className="w-100">
                  <div className="checkbox checkBoxWrapper">
                    <span style={{ marginRight: "8px" }}>Print Labels</span>
                    <div className="d-flex justify-content-start align-items-center">
                      <span className="labelYesNo">{parseBooleanValue(newLab.tubes_provided)}</span>
                      <ViewOnlyCheckBox
                        id="tubes_provided"
                        bgColor="blue"
                        checked={newLab.tubes_provided}
                        handleChange={(e) => {
                          const val = !newLab.tubes_provided;
                          if (val) {
                            setNewLab({ ...newLab, tubes_provided: val });
                          } else {
                            if (!newLab.barCodeProvided) setNewLab({ ...newLab, tubes_provided: val });
                          }
                        }}
                      />
                    </div>
                  </div>
                  <div className="checkbox checkBoxWrapper">
                    <span style={{ marginRight: "8px" }}>Default Antigen</span>
                    <div className="d-flex justify-content-start align-items-center">
                      <span className="labelYesNo">{parseBooleanValue(newLab.default_antigen)}</span>
                      <ViewOnlyCheckBox
                        id="default_antigen"
                        bgColor="blue"
                        checked={newLab.default_antigen}
                        handleChange={(e) => setNewLab({ ...newLab, default_antigen: !newLab.default_antigen })}
                      />
                    </div>
                  </div>

                  <div className="checkbox checkBoxWrapper">
                    <span style={{ marginRight: "8px" }}>Default Molecular</span>
                    <div className="d-flex justify-content-start align-items-center">
                      <span className="labelYesNo">{parseBooleanValue(newLab.default_molecular)}</span>
                      <ViewOnlyCheckBox
                        id="default_molecular"
                        bgColor="blue"
                        checked={newLab.default_molecular}
                        handleChange={(e) => setNewLab({ ...newLab, default_molecular: !newLab.default_molecular })}
                      />
                    </div>
                  </div>

                  <div className="checkbox checkBoxWrapper">
                    <span style={{ marginRight: "8px" }}>Default Accula</span>
                    <div className="d-flex justify-content-start align-items-center">
                      <span className="labelYesNo">{parseBooleanValue(newLab.default_other)}</span>
                      <ViewOnlyCheckBox
                        id="default_other"
                        bgColor="blue"
                        checked={newLab.default_other}
                        handleChange={(e) => setNewLab({ ...newLab, default_other: !newLab.default_other })}
                      />
                    </div>
                  </div>

                  <div className="checkbox checkBoxWrapper">
                    <span style={{ marginRight: "8px" }}>Default Rapid Flu</span>
                    <div className="d-flex justify-content-start align-items-center">
                      <span className="labelYesNo">{parseBooleanValue(newLab.default_rapidflu)}</span>
                      <ViewOnlyCheckBox
                        id="default_rapidflu"
                        bgColor="blue"
                        checked={newLab.default_rapidflu}
                        handleChange={(e) => setNewLab({ ...newLab, default_rapidflu: !newLab.default_rapidflu })}
                      />
                    </div>
                  </div>
                  <div className="checkbox checkBoxWrapper">
                    <span style={{ marginRight: "8px" }}>Default Lucira</span>
                    <div className="d-flex justify-content-start align-items-center">
                      <span className="labelYesNo">{parseBooleanValue(newLab.default_lucira)}</span>
                      <ViewOnlyCheckBox
                        id="default_lucira"
                        bgColor="blue"
                        checked={newLab.default_lucira}
                        handleChange={(e) => setNewLab({ ...newLab, default_lucira: !newLab.default_lucira })}
                      />
                    </div>
                  </div>

                  <div className="checkbox checkBoxWrapper">
                    <span style={{ marginRight: "8px" }}>Send Insurance Info</span>
                    <div className="d-flex justify-content-start align-items-center">
                      <span className="labelYesNo">{parseBooleanValue(newLab.sendInsurance)}</span>
                      <ViewOnlyCheckBox
                        id="sendInsurance"
                        bgColor="blue"
                        checked={newLab.sendInsurance}
                        handleChange={(e) => setNewLab({ ...newLab, sendInsurance: !newLab.sendInsurance })}
                      />
                    </div>
                  </div>
                  <div className="checkbox checkBoxWrapper">
                    <span style={{ marginRight: "8px" }}>Use Lab BC Numbers</span>
                    <div className="d-flex justify-content-start align-items-center">
                      <span className="labelYesNo">{parseBooleanValue(newLab.barCodeProvided)}</span>
                      <ViewOnlyCheckBox
                        id="barCodeProvided"
                        bgColor="blue"
                        checked={newLab.barCodeProvided}
                        handleChange={(e) => {
                          const val = !newLab.barCodeProvided;
                          let limit = 0;
                          if (val) {
                            const barcodelabs = appContext.labs.filter((l) => l.barCodeProvided === true);
                            if (barcodelabs.length > 0) limit = barcodelabs[0].barCodeAlertLimit || 0;
                          }
                          setNewLab({
                            ...newLab,
                            barCodeProvided: val,
                            tubes_provided: val ? val : newLab.tubes_provided,
                            barCodeAlertLimit: limit,
                          });
                        }}
                      />
                    </div>
                  </div>

                  <div className="checkbox checkBoxWrapper">
                    <span style={{ marginRight: "8px" }}>Exclude from Summary</span>
                    <div className="d-flex justify-content-start align-items-center">
                      <span className="labelYesNo">{parseBooleanValue(newLab.showOnSummaryScreen || false)}</span>
                      <ViewOnlyCheckBox
                        id="showOnSummaryScreen"
                        bgColor="blue"
                        checked={newLab.showOnSummaryScreen}
                        handleChange={(e) => setNewLab({ ...newLab, showOnSummaryScreen: !newLab.showOnSummaryScreen })}
                      />
                    </div>
                  </div>
                  <div className="checkbox checkBoxWrapper">
                    <span style={{ marginRight: "8px" }}>Archive</span>
                    <div className="d-flex justify-content-start align-items-center">
                      <span className="labelYesNo">{parseBooleanValue(newLab.isArchive)}</span>
                      <ViewOnlyCheckBox
                        id="isArchive"
                        bgColor="blue"
                        checked={newLab.isArchive}
                        handleChange={(e) => setNewLab({ ...newLab, isArchive: !newLab.isArchive })}
                      />
                    </div>
                  </div>
                  <div className="checkbox checkBoxWrapper">
                    <span style={{ marginRight: "8px" }}>White Label</span>
                    <div className="d-flex justify-content-start align-items-center">
                      <span className="labelYesNo">{parseBooleanValue(newLab.whiteLabel)}</span>
                      <ViewOnlyCheckBox
                        id="whiteLabel"
                        bgColor="blue"
                        checked={newLab.whiteLabel}
                        handleChange={(e) => setNewLab({ ...newLab, whiteLabel: !newLab.whiteLabel })}
                      />
                    </div>
                  </div>

                  {newLab.whiteLabel && appContext.isSuperAdmin() && (
                    <>
                      <div className="checkbox checkBoxWrapper">
                        <span style={{ marginRight: "8px" }}>App Package Name</span>
                        <div className="d-flex justify-content-start align-items-center">
                          <input
                            id="whitelabelpackage"
                            className="labModalInput"
                            placeholder="Package Name"
                            value={newLab.whiteLabelPackage}
                            onChange={(e) => setNewLab({ ...newLab, whiteLabelPackage: e.target.value })}
                          />
                        </div>
                      </div>
                      <div className="checkbox checkBoxWrapper">
                        <span style={{ marginRight: "8px" }}>Postive Release Tab</span>
                        <div className="d-flex justify-content-start align-items-center">
                          <span className="labelYesNo">{parseInt(newLab.pendingRelease) === 1 ? "Yes" : "No"}</span>
                          <ViewOnlyCheckBox
                            id="pendingRelease"
                            bgColor="blue"
                            checked={parseInt(newLab.pendingRelease) === 1 ? true : false}
                            handleChange={(e) => {
                              setNewLab({ ...newLab, pendingRelease: 1 - newLab.pendingRelease });
                            }}
                          />
                        </div>
                      </div>
                    </>
                  )}
                  <div className="checkbox checkBoxWrapper">
                    <span style={{ marginRight: "8px" }}>Invalid Notes</span>
                    <div className="d-flex justify-content-start align-items-center">
                      <div
                        className="showDisclaimerText"
                        onClick={() =>
                          setShowEditor({ type: DISCLAIMER_TYPE.INVALIDNOTES, message: newLab.invalid_notes })
                        }
                      >
                        <span>Disclaimer</span>
                      </div>
                    </div>
                  </div>
                  <div className="checkbox checkBoxWrapper">
                    <span style={{ marginRight: "8px" }}>Indeterminate Notes</span>
                    <div className="d-flex justify-content-start align-items-center">
                      <div
                        className="showDisclaimerText"
                        onClick={() =>
                          setShowEditor({ type: DISCLAIMER_TYPE.INDERMINATENOTES, message: newLab.indeterminate_notes })
                        }
                      >
                        <span>Disclaimer</span>
                      </div>
                    </div>
                  </div>
                  <div className="checkbox checkBoxWrapper">
                    <span style={{ marginRight: "8px" }}>Antigen</span>
                    <div className="d-flex justify-content-start align-items-center">
                      <div
                        className="showDisclaimerText"
                        onClick={() => setShowEditor({ type: DISCLAIMER_TYPE.ANTIGEN, message: newLab.antigen_notes })}
                      >
                        <span>Disclaimer</span>
                      </div>
                    </div>
                  </div>
                  <div className="checkbox checkBoxWrapper">
                    <span style={{ marginRight: "8px" }}>Molecular</span>
                    <div className="d-flex justify-content-start align-items-center">
                      <div
                        className="showDisclaimerText"
                        onClick={() =>
                          setShowEditor({ type: DISCLAIMER_TYPE.MOLECULAR, message: newLab.molecular_notes })
                        }
                      >
                        <span>Disclaimer</span>
                      </div>
                    </div>
                  </div>
                  <div className="checkbox checkBoxWrapper">
                    <span style={{ marginRight: "8px" }}>Accula</span>
                    <div className="d-flex justify-content-start align-items-center">
                      <div
                        className="showDisclaimerText"
                        onClick={() => setShowEditor({ type: DISCLAIMER_TYPE.ACCULA, message: newLab.other_notes })}
                      >
                        <span>Disclaimer</span>
                      </div>
                    </div>
                  </div>
                  <div className="checkbox checkBoxWrapper">
                    <span style={{ marginRight: "8px" }}>Lucira</span>
                    <div className="d-flex justify-content-start align-items-center">
                      <div
                        className="showDisclaimerText"
                        onClick={() => setShowEditor({ type: DISCLAIMER_TYPE.LUCIRA, message: newLab.lucira_notes })}
                      >
                        <span>Disclaimer</span>
                      </div>
                    </div>
                  </div>
                  <div className="checkbox checkBoxWrapper">
                    <span style={{ marginRight: "8px" }}>Rapid Flu</span>
                    <div className="d-flex justify-content-start align-items-center">
                      <div
                        className="showDisclaimerText"
                        onClick={() =>
                          setShowEditor({ type: DISCLAIMER_TYPE.RAPIDFLU, message: newLab.rapidflu_notes })
                        }
                      >
                        <span>Disclaimer</span>
                      </div>
                    </div>
                  </div>
                  <div className="checkbox checkBoxWrapper">
                    <span style={{ marginRight: "8px" }}>Custom</span>
                    <div className="d-flex justify-content-start align-items-center">
                      <div
                        className="showDisclaimerText"
                        onClick={() => setShowEditor({ type: DISCLAIMER_TYPE.CUSTOM, message: newLab.custom_notes })}
                      >
                        <span>Disclaimer</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {error && <ErrorMessage error={error} handleChange={() => setError("")} />}
      </Modal.Body>

      <Modal.Footer style={{ borderTop: "1px solid lightgrey", paddingTop: "15px" }}>
        <Button className="modalButtons" style={{ marginRight: "25px" }} variant="secondary" onClick={handleClose}>
          Close
        </Button>
        <Button style={{ marginLeft: "25px" }} className="modalButtons" variant="primary" onClick={() => handleSave()}>
          {newLab.isNew ? "Create" : "Update"}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};
export default LabModal;
