import api from "api";
import { CONFIG } from "constant";
import React, { useState, useEffect } from "react";
import { Form, Row, Col } from "react-bootstrap";
import { ViewportList } from "react-viewport-list";
import EventBar from "./EventBar";
import ConfirmationModal from "components/Modal/ConfirmationModal";
import moment from "moment";
import Loader from "components/Loader/Loader";
import { EOS_MODAL_GROUPS } from "constant";
import GroupLabelTab from "./GroupLabelTab";
import GlobalSearch from "components/GlobalSearch";

const SideListBar = (props) => {
  const { selectedDate, user, updateDateList } = props;
  const [empsToShow, setEmpsToShow] = useState([]);
  const [filteredEmps, setFilteredEmps] = useState({ eosEmps: [], nonEosEmps: [] });
  const [filteredPatient, setFilteredPatients] = useState([]);
  const [patients, setPatients] = useState([]);
  const [filter, setFilter] = useState({});
  const [selectAll, setSellectAll] = useState(false);
  const [checkboxes, setCheckboxes] = useState([]);
  const [scheduled, setScheduled] = useState(true);
  const [openConfirmation, setOpenConfirmation] = useState(false);
  const [scheduledPatient, setScheduledPatient] = useState([]);
  const [expectedOnSet, setExpectedOnSet] = useState(true);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    loadAllEmployees();
  }, []);

  useEffect(() => {
    if (selectedDate) {
      loadEmployees();
    }
  }, [selectedDate]);

  useEffect(() => {
    setFilteredPatients(globalFilter(filter));
  }, [filter]);

  const loadAllEmployees = async () => {
    const filterParam = { short: "yes" };
    try {
      const pgList = await api.getEmployeeListFromPG(filterParam);
      setEmpsToShow(pgList?.rows || []);
    } catch (err) {
      console.log("Load Employees Error:-", err);
    }
  };

  const loadEmployees = async () => {
    const filterParam = {
      short: "yes",
      current: "yes",
      testDate: moment(selectedDate, "MM-DD-YYYY").format("YYYY-MM-DD"),
    };
    try {
      setLoading(true);
      const pgList = await api.getEmployeeListFromPG(filterParam);
      setLoading(false);
      setScheduledPatient(pgList?.rows || []);
    } catch (err) {
      console.log("Load Employees Error:-", err);
    }
  };

  const globalFilter = (filter) => {
    if (filter && Object.keys(filter).length > 0) {
      return patients.filter((f) =>
        `${f.firstName} ${f.lastName}`.toLowerCase().includes(filter.fullName?.toLowerCase())
      );
    }
    return patients;
  };

  useEffect(() => {
    if (empsToShow) {
      if (scheduled) {
        setFilteredPatients(scheduledPatient);
        setPatients(scheduledPatient);
      } else {
        const nonScheduledPatients = empsToShow.filter(
          (patient) => !scheduledPatient.some((scheduledPat) => patient.id === scheduledPat.id)
        );
        setFilteredPatients(nonScheduledPatients);
        setPatients(nonScheduledPatients);
      }
    }
    setCheckboxes([]);
    setSellectAll(false);
    setFilter({});
  }, [scheduled, empsToShow, scheduledPatient]);

  const updateScheduledList = (typeOfAction) => {
    setScheduledPatient((prevCheckboxes) => {
      if (typeOfAction === "add") {
        const filteredItem = empsToShow.filter((e) => checkboxes.includes(e.id));
        return [...prevCheckboxes, ...filteredItem];
      }
      return prevCheckboxes.filter((e) => !checkboxes.includes(e.id));
    });

    updateDateList((pervList) => {
      const prevItemIndex = pervList.findIndex((d) => d.date === selectedDate);
      if (prevItemIndex !== -1) {
        const newPervList = [...pervList];
        if (typeOfAction === "add") {
          newPervList[prevItemIndex].count = parseInt(newPervList[prevItemIndex].count) + checkboxes.length;
        } else {
          newPervList[prevItemIndex].count = parseInt(newPervList[prevItemIndex].count) - checkboxes.length;
        }
        return newPervList;
      }
      return pervList;
    });

    setCheckboxes([]);
  };

  const handleSaveGolobalSearch = (search) => {
    if (search !== filter.fullName) setFilter({ ...filter, fullName: search });
  };
  const updateCountInDateListing = (type) => {};

  const handleMoreAction = () => {
    console.log("handleMoreAction");
  };

  const handleChangeSelection = (e, emp) => {
    setCheckboxes((prevCheckboxes) => {
      const index = prevCheckboxes.indexOf(emp.id);
      if (index === -1) {
        return [...prevCheckboxes, emp.id];
      } else {
        return prevCheckboxes.filter((id) => id !== emp.id);
      }
    });
  };
  const handleEditUser = () => {
    console.log("handleEditUser");
  };
  const handleUpdateSchedule = () => {
    console.log("handleUpdateSchedule");
  };

  const addSchedule = async () => {
    try {
      const empIds = checkboxes.map((e) => {
        return {
          employeeID: e,
        };
      });
      const detail = {
        date: moment(selectedDate, "MM-DD-YYYY").format("YYYY-MM-DD"),
        testType: [{ test: [{ value: "PCR", label: "PCR" }] }],
      };
      setLoading(true);
      await api.assignedTestSchedule(empIds, detail, user);
      setLoading(false);
      updateScheduledList("add");
    } catch (ex) {
      console.log("[addSchedule]", ex);
    }
  };

  const removeSchdeule = async () => {
    try {
      const detail = {
        date: moment(selectedDate, "MM-DD-YYYY").format("YYYY-MM-DD"),
      };
      setLoading(true);
      await api.removeTestSchedule(checkboxes, detail, user);
      setLoading(false);
      updateScheduledList("remove");
    } catch (ex) {
      console.log("[addSchedule]", ex);
    }
  };

  const handleChangeSchedule = () => {
    if (checkboxes.length === 0) return;
    setOpenConfirmation(true);
  };

  const handleConfirmation = (isConfirm) => {
    setOpenConfirmation(false);
    if (!isConfirm) return;
    if (scheduled) {
      removeSchdeule();
    } else {
      addSchedule();
    }
  };

  const handleSelectAll = (val) => {
    setSellectAll(val);
    if (val) {
      const ids = filteredPatient.map((m) => m.id);
      setCheckboxes(ids);
    } else {
      setCheckboxes([]);
    }
  };

  return (
    <div className="calendar-details eos-calendar-details">
      <div className="p-2 fw-bold">{`Selected Date ${selectedDate}`}</div>
      <div className="d-flex align-items-center justify-content-between m-2">
        <div className={`${scheduled ? "underlinedText" : ""} cursor-pointer filter-groups`}>
          <span
            onClick={() => {
              if (!scheduled) setScheduled(true);
            }}
          >
            Patients Scheduled ({scheduledPatient.length})
          </span>
          <span className="active-group-identifier" />
        </div>
        <div className={`${!scheduled ? "underlinedText" : ""} cursor-pointer filter-groups`}>
          <span
            onClick={() => {
              if (scheduled) setScheduled(false);
            }}
          >
            Patients Not Scheduled ({Math.abs(empsToShow.length - scheduledPatient.length)})
          </span>
          {!scheduled && <span className="active-group-identifier" />}
        </div>
      </div>
      <GlobalSearch
        cssClass={`eos-searchBar  mt-1`}
        handleSave={handleSaveGolobalSearch}
        globalSearchText={filter.fullName || ""}
      />
      <div className="d-flex align-items-center justify-content-between mb-2" style={{ paddingInline: "26px" }}>
        <div className="d-flex align-items-center">
          <Form.Check>
            <Form.Check.Input
              type="checkbox"
              onChange={(e) => handleSelectAll(!selectAll)}
              checked={selectAll}
              value="selectAll"
              style={{ marginTop: "7px" }}
            />
          </Form.Check>
          <span className="ms-1 d-inline-block selectedTextSpan">{!selectAll ? "Select All" : "Deselect All"}</span>
        </div>

        <span className="event selectedTextSpan">Selected: {checkboxes.length}</span>
        <div className="my-1 mt-2">
          <i
            onClick={handleChangeSchedule}
            style={{ fontSize: "20px", color: checkboxes.length === 0 ? "grey" : "#bf1e2e" }}
            className={`fa ${scheduled ? "fa-minus-circle" : "fa-plus-circle"} mx-2`}
            title={scheduled ? "Remove" : "Add"}
          />
        </div>
      </div>
      <div className="details-container px-3">
        <div className={`events-container`}>
          {filteredPatient.length > 0 && (
            <ViewportList
              key={filteredPatient.length}
              items={filteredPatient}
              itemMinSize={42}
              margin={16}
              overflowAnchor="auto"
              withCache={false}
            >
              {(emp, index) => (
                <EventBar
                  emp={emp}
                  key={`${emp.id}-${index}`}
                  handleUpdateSchedule={handleUpdateSchedule}
                  handleEditUser={handleEditUser}
                  scheduled={scheduled}
                  isSelected={checkboxes.indexOf(emp.id) !== -1}
                  onChangeSelection={handleChangeSelection}
                  handleMoreAction={handleMoreAction}
                />
              )}
            </ViewportList>
          )}
        </div>
      </div>
      {openConfirmation && (
        <ConfirmationModal
          show={openConfirmation}
          title="Confirm"
          message={`Are you sure you want to ${scheduled ? "remove" : "add"} ${
            checkboxes.length
          } patient(s) to the testing schedule?`}
          handleConfirm={handleConfirmation}
          cssClass={"seperateModal"}
        />
      )}
      {loading && <Loader />}
    </div>
  );
};

export default SideListBar;
