import React from "react";
import { ProgressBar } from "react-bootstrap";

const CustomProgressBar = ({ item, total }) => {
  const progress = Math.round((item / total) * 100);

  const label = `${item} of ${total}`;

  return (
    <div>
      <ProgressBar now={progress} variant="secondary" animated />
      <div style={{ textAlign: "center" }}>{label}</div>
    </div>
  );
};

export default CustomProgressBar;
