export const DOCUMNET = "invoiceDocuments";

export const DOC_FETCH = "invoiceDocumentFetch";

export const DOC_CREATE = "invoiceDocumentCreate";

export const DOC_UPDATE = "invoiceDocumentUpdate";

export const DOC_DELETE = "invoiceDocumentDelete";

export const DOC_FETCH_TYPE = "invoiceDocumentFetchType";

export const DOC_TYPE_CREATE = "invoiceDocumentTypeCreate";
