import React, { useEffect, useState, useContext } from "react";
import { Button, Modal } from "react-bootstrap";
import { AppContext } from "context/app-context";
import { CANCEL_REASON_OPTIONS, CONFIG } from "constant";
import api from "api";
import { capitalizeLetter } from "../../../utils";

const CancelledReasonModal = ({ handleClose, handleSaveChange, selectedValue }) => {
  const [option, setOption] = useState({ title: "", label: "" });
  const appContext = useContext(AppContext);

  useEffect(() => {
    if (selectedValue) {
      setOption(selectedValue);
    }
  }, [selectedValue]);

  const handleSave = () => {
    if (!option.label) {
      appContext.showErrorMessage("Enter the reason");

      return;
    }
    if (!option.title) {
      appContext.showErrorMessage("Enter the details");
      return;
    }

    handleSaveChange({ ...option, label: capitalizeLetter(option.label), title:   capitalizeLetter(option.title)});
  };

  // const handleSaveBulk = async () => {
  //   const type = "cancelled";
  //   for (const item of CANCEL_REASON_OPTIONS) {
  //     console.log("item", {...item, clientID : CONFIG.clientID});
  //     await api.createOption(type, item);

  //   }
  // };

  return (
    <Modal
      show
      animation={true}
      onHide={() => handleClose()}
      backdrop="static"
      centered
      size={"md"}
      keyboard={true}
      className="second-modal"
    >
      <Modal.Header closeButton>
        <Modal.Title className="my-0" id="contained-modal-title-vcenter">
          Cancelled/Rejected Reason
        </Modal.Title>
      </Modal.Header>
      <Modal.Body style={{ paddingTop: 0 }}>
        <div className="row mb-3">
          <div className="col-lg-12 col-12 mb-3">
            <label className="modalLineHeaders mt-0">Reason:</label>
            <input
              value={option.label}
              className="modalInput"
              placeholder="Enter Reason"
              style={{
                width: "100%",
              }}
              onChange={(e) => setOption({ ...option, label: e.target.value })}
            />

            <div className="col-lg-12 col-12 mt-3">
              <label className="modalLineHeaders mt-0">Details:</label>
              <input
                value={option.title}
                className="modalInput"
                placeholder="Enter Details"
                style={{
                  width: "100%",
                }}
                onChange={(e) => setOption({ ...option, title: e.target.value })}
              />
            </div>
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" className="headerButton" onClick={() => handleClose()}>
          Close
        </Button>
        <Button variant="primary" className="modalButtons" onClick={handleSave}>
          Save
        </Button>
        {/* <Button variant="primary" className="modalButtons" onClick={handleSaveBulk}>
          Save Bulk
        </Button> */}
      </Modal.Footer>
    </Modal>
  );
};

export default CancelledReasonModal;
