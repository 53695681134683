import API from "api";
import ErrorMessage from "components/Message/ErrorMessage";
import { useContext, useRef } from "react";
// import ProviderSearchRecord from "components/ProviderSearchRecord";
import InputField from "components/InputField/InputField";
import SwitchToggle from "components/SwitchToggle/SwitchToggle";
import { DEFAULT_PROVIDERS, DEFAULT_PROVIDERS_ARR, PARSE_PROVIDER } from "constant";
import { useEffect, useState } from "react";
import { Button, Modal } from "react-bootstrap";
import Form from "react-bootstrap/Form";
import { useDispatch, useSelector } from "react-redux";
import Select from "react-select";
import { createProvider, updateProvider, createPortalNewUser } from "store/features/providers/providersActions";
import { setOpenCreatorProviderModal as setOpenCreator } from "store/features/providers/providersSlice";
import { formatPhoneNumber } from "utils";
import { CONFIG, MESSAGE_MODES, NPI_TYPE_OPTIONS } from "../../../src/constant";
import { setMessage } from "../../../src/store/features/general/generalAction";
import { setLoader } from "../../../src/store/features/general/generalSlice";
import { locationsSelectors } from "../../../src/store/features/locations/locationsSelectors";
import { t } from "../../../src/stringConstants";
import { fetchAllLocations } from "store/features/locations/locationsSlice";
import searchIcon from "../../assets/img/search.svg";
import { selectedProvider } from "store/features/providers/providersSelectors";
import { AppContext } from "context/app-context";

const ProviderModal = (props) => {
  const { show, user, handleClose, setting, readOnly, cssClass, providers } = props;

  const dispatch = useDispatch();

  const [newUser, setNewUser] = useState(user);
  const [error, setError] = useState("");
  const [defaultSetting, setDefaultSetting] = useState(user.isNew ? {} : setting.defaultSetting);
  const [phoneError, setPhoneError] = useState("");
  const [recordData, setRecordData] = useState([]);
  const [selectSearchData, setSelectSearchData] = useState(null);
  const childNode = useRef();
  const appContext = useContext(AppContext);

  const CLIENT_OPTIONS = useSelector(locationsSelectors).map((f) => ({ label: f.name, value: f.id }));

  useEffect(() => {
    if (CLIENT_OPTIONS && CLIENT_OPTIONS?.length === 0) {
      dispatch(fetchAllLocations());
    }
  }, []);

  useEffect(() => {
    setNewUser({ ...user, labName: appContext?.userLabName || "Medflow" });
  }, [user]);

  useEffect(() => {
    if (!newUser?.isNew) {
      const findProvider = providers.find((f) => f.id === newUser?.id);
      setNewUser({ ...newUser, subID: findProvider?.subID });
    }
  }, [providers]);

  const handleUpdateAddress = (val, flag) => {
    if (!flag) {
      setNewUser({ ...newUser, street: val });
    } else {
      const addressObj = {
        street: val.street,
        country: val.country,
        city: val.city,
        zip: val.zipcode,
        state: val.state,
        isoCode: val.isoCode,
      };
      setNewUser({ ...newUser, ...addressObj });
    }
  };

  const handleSave = async (user, defaultSetting) => {
    try {
      if (user.isNew) {
        dispatch(createProvider(user, defaultSetting));
        dispatch(setMessage(t("providerCreatedSuccessfully"), MESSAGE_MODES.success));
      } else {
        dispatch(updateProvider(user, defaultSetting));
        dispatch(setMessage(t("providerUpdatedSuccessfully"), MESSAGE_MODES.success));
      }
    } catch (error) {
      console.log("ERROR:User-", error);
      dispatch(setMessage(error.message, MESSAGE_MODES.error));
      dispatch(setOpenCreator(true));
    }
  };

  const populate = (record) => {
    setSelectSearchData(null);
    setRecordData([]);

    const { basic, addresses, taxonomies } = record;

    // Find the LOCATION address if available
    const locationAddress = addresses?.find((address) => address.address_purpose === "LOCATION") || addresses[0];

    // Determine the primary taxonomy
    const primaryTaxonomy = taxonomies.find((taxonomy) => taxonomy.primary) || taxonomies[0];

    // Format phone numbers if they exist
    const formattedPhoneNumber = locationAddress?.telephone_number
      ? formatPhoneNumber(`+1${locationAddress.telephone_number}`)
      : "";

    const formattedFaxNumber = locationAddress?.fax_number ? formatPhoneNumber(`+1${locationAddress.fax_number}`) : "";

    // Create the user object
    const obj = {
      ...newUser,
      firstName: basic.first_name,
      middleName: basic.middle_name,
      lastName: basic.last_name,
      street: locationAddress?.address_1,
      city: locationAddress?.city,
      zip: locationAddress?.postal_code,
      state: locationAddress?.state,
      phoneNumber: formattedPhoneNumber,
      fax: formattedFaxNumber,
      taxid: primaryTaxonomy?.code,
      npi: record.number,
      npiType: record.enumeration_type,
      speciality: primaryTaxonomy?.desc,
      isFinder: true,
    };

    setNewUser(obj);
  };

  const searchProvider = async (provider) => {
    let record = [];
    dispatch(setLoader(true));
    try {
      record = await API.searchProviderAPI(provider);
      dispatch(setLoader(false));
    } catch (err) {
      console.log(err.message);
      dispatch(setLoader(false));
    }

    if (record.length === 0) {
      setError("records Not Found!");
      return;
    }

    if (record && record.length === 1) {
      populate(record[0]);
    }
    if (record && record.length > 1) {
      setRecordData(record);
    }
  };

  const handleFindProvider = (newUSer) => {
    const keys = ["firstName", "lastName", "npi"];
    if (keys.some((e) => newUser[e])) {
      searchProvider(newUser);
    } else {
      dispatch(setMessage("Please Fill Provider Name Or NPI", MESSAGE_MODES.info));
    }
  };

  const onHandleSave = () => {
    let roles = [];
    const objToSave = { ...newUser };

    Object.assign(objToSave, {
      name: `${newUser.firstName.trim()} ${newUser?.middleName?.trim() || ""} ${newUser?.lastName.trim()}`,
    });

    if (!newUser.npi || !newUser.npi.trim()) {
      setError("NPI is required");
      return;
    }

    // Find Existing NPI Number
    const findProvider = providers.find((f) => f.npi === newUser.npi);
    const isPortalCall = newUser.isCheckedUser === "active" || newUser?.subID;

    if (findProvider && (newUser.isNew || (!newUser.isNew && newUser.id !== user.id))) {
      setError("NPI is already exist");
      return;
    }

    if (!newUser.firstName || !newUser.firstName.trim()) {
      setError("First Name is required");
      return;
    }

    if (!newUser.lastName || !newUser.lastName.trim()) {
      setError("Last Name is required");
      return;
    }

    if (isPortalCall) {
      if (!newUser.associatedClient) {
        setError("Please select the Associated Client");
        return;
      }

      if (!newUser.email) {
        setError("Please enter the email");
        return;
      }
    }

    if (!newUser.phoneNumber) {
      setError("Please enter the phone number");
      return;
    }

    let provDefaultSetting = defaultSetting;
    if (!newUser.isNew && setting.defaultSetting && Object.keys(setting.defaultSetting).length > 0) {
      const obj = {};
      DEFAULT_PROVIDERS_ARR.forEach((key) => {
        if (defaultSetting[key] === setting.defaultSetting[key]) {
          obj[key] = defaultSetting[key] || "";
        } else if (defaultSetting[key] !== setting.defaultSetting[key] && setting.defaultSetting[key] === newUser.id) {
          obj[key] = defaultSetting[key] || "";
        } else if (defaultSetting[key] && defaultSetting[key] !== setting.defaultSetting[key]) {
          obj[key] = defaultSetting[key] || "";
        } else {
          obj[key] = setting.defaultSetting[key] || "";
        }
      });
      provDefaultSetting = obj;
    }

    handleSave(objToSave, provDefaultSetting);
    handleClose();
  };

  const handleResultReportingChange = (e) => {
    const checked = e.target.checked;
    if (!checked) {
      setNewUser({
        ...newUser,
        setting: { ...newUser.setting, resultReportingPatient: checked, portalOnly: false, patientPreference: false },
      });
      return;
    }

    setNewUser({
      ...newUser,
      setting: { ...newUser.setting, resultReportingPatient: checked },
    });
  };

  const handleUserCreate = (e) => {
    const { name, checked } = e.target;
    const value = checked ? "active" : "inactive";
    setNewUser({ ...newUser, [name]: value, password: "", isCheckedUser: "active" });
  };

  const createUserForPortal = async () => {
    if (!newUser.associatedClient) {
      setError("Please select the Associated Client");
      return;
    }

    if (!newUser.email) {
      setError("Please enter the email");
      return;
    }
    // const response = await dispatch(createPortalNewUser({ ...newUser, isShowMessage: true })).unwrap();

    dispatch(createPortalNewUser({ ...newUser, isShowMessage: true }));
  };

  return (
    <>
      <Modal
        show={show}
        backdrop="static"
        animation={true}
        onHide={() => {
          setNewUser("");
          handleClose();
        }}
        style={{ paddingLeft: "0" }}
        className={cssClass ?? ""}
        size={"2xl"}
        keyboard={false}
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title className="my-0" id="contained-modal-title-vcenter">
            {newUser.isNew ? "Create Provider" : "Update Provider"}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body
          style={{
            paddingTop: 0,
          }}
        >
          <div className="createClientsWrapper">
            <div
              className="leftSide"
              style={{
                flex: "0 0 calc(70% - 15px)",
                maxWidth: "calc(70% - 15px)",
              }}
            >
              <div style={{ display: "flex", width: "100%", justifyContent: "space-between", gap: "10px" }}>
                <div style={{ display: "flex", width: "100%" }}>
                  <div style={{ display: "flex", width: "33.33%", paddingRight: "12px" }}>
                    <InputField
                      type="text"
                      groupWrapper={"form-group-wrapper"}
                      inputStyle="modalInput"
                      labelStyle="text-capitalize"
                      label="NPI:"
                      value={newUser.npi}
                      index="npi"
                      maxLength={20}
                      placeholder="Search By NPI"
                      handleChange={(e) => setNewUser({ ...newUser, npi: e.target.value })}
                      required
                    />
                    <img
                      src={searchIcon}
                      style={{ width: "30px", marginLeft: "10px", marginTop: "5px", cursor: "pointer" }}
                      onClick={() => handleFindProvider(newUser)}
                    />
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="testAdmin createClientsWrapper allowShrink resubmissionFormWrapper">
                  <div className={" crew-profile-row4 crew-profile-again"}>
                    <InputField
                      type="text"
                      inputStyle="modalInput w-100"
                      labelStyle="text-capitalize"
                      label="First Name"
                      value={newUser.firstName}
                      index="firstName"
                      maxLength={30}
                      placeholder="First Name"
                      handleChange={(e) => setNewUser({ ...newUser, firstName: e.target.value })}
                      required
                    />

                    <InputField
                      type="text"
                      inputStyle="modalInput w-100"
                      labelStyle="text-capitalize"
                      label="Middle Name"
                      value={newUser.middleName}
                      index="middleName"
                      maxLength={30}
                      placeholder="Middle Name"
                      handleChange={(e) => setNewUser({ ...newUser, middleName: e.target.value })}
                    />

                    <InputField
                      type="text"
                      inputStyle="modalInput w-100"
                      labelStyle="text-capitalize"
                      label="Last Name"
                      value={newUser.lastName}
                      index="lastName"
                      maxLength={30}
                      placeholder="Last Name"
                      handleChange={(e) => setNewUser({ ...newUser, lastName: e.target.value })}
                      required
                    />
                  </div>
                </div>
                <div className="testAdmin createClientsWrapper allowShrink resubmissionFormWrapper">
                  <div className={" crew-profile-row4 crew-profile-again"}>
                    {/* <InputField
                  type="address"
                  inputStyle="modalInput w-100"
                  labelStyle="text-capitalize"
                  label="Address"
                  value={newUser.street}
                  index="street"
                  maxLength={65}
                  placeholder="Address"
                  handleChange={(val, flag) => handleUpdateAddress(val, flag)}
                /> */}
                    <InputField
                      type="address"
                      inputStyle="modalInput w-100"
                      labelStyle="text-capitalize"
                      label="Address"
                      value={newUser.street}
                      index="city"
                      placeholder="City"
                      handleChange={(val, flag) => handleUpdateAddress(val, flag)}
                    />

                    <InputField
                      type="text"
                      inputStyle="modalInput w-100"
                      labelStyle="text-capitalize"
                      label="City"
                      value={newUser.city}
                      index="city"
                      placeholder="City"
                      handleChange={(e) => setNewUser({ ...newUser, city: e.target.value })}
                    />
                    <InputField
                      type="text"
                      inputStyle="modalInput"
                      labelStyle="text-capitalize"
                      label="State"
                      value={newUser.state}
                      index="state"
                      maxLength={20}
                      placeholder="State"
                      handleChange={(e) => setNewUser({ ...newUser, state: e.target.value })}
                    />
                  </div>
                </div>

                <div className="testAdmin createClientsWrapper allowShrink resubmissionFormWrapper">
                  <div className={" crew-profile-row4 crew-profile-again"}>
                    <InputField
                      type="text"
                      inputStyle="modalInput"
                      labelStyle="text-capitalize"
                      label="Zip Code"
                      value={newUser.zip}
                      index="zip"
                      maxLength={20}
                      placeholder="Zip Code"
                      handleChange={(e) => setNewUser({ ...newUser, zip: e.target.value })}
                    />

                    <InputField
                      type="phone"
                      inputStyle="modalInput"
                      labelStyle="text-capitalize"
                      label="Phone Number"
                      value={newUser.phoneNumber}
                      index="phoneNumber"
                      maxLength={20}
                      placeholder="Enter Phone"
                      handleChange={(e) => setNewUser({ ...newUser, phoneNumber: e })}
                      required
                    />

                    <InputField
                      type="text"
                      inputStyle="modalInput"
                      labelStyle="text-capitalize"
                      label="Email"
                      value={newUser.email}
                      index="email"
                      maxLength={100}
                      placeholder="Email"
                      handleChange={(e) => setNewUser({ ...newUser, email: e.target.value })}
                    />
                  </div>
                </div>

                <div className="testAdmin createClientsWrapper allowShrink resubmissionFormWrapper">
                  <div className={" crew-profile-row4 crew-profile-again"}>
                    <InputField
                      type="dropDown"
                      options={NPI_TYPE_OPTIONS}
                      inputStyle=""
                      labelStyle="text-capitalize"
                      label="NPI Type:"
                      isClearable={true}
                      value={NPI_TYPE_OPTIONS.find((f) => f.value === newUser.npiType) || ""}
                      index="npiType"
                      placeholder="NPI"
                      handleChange={(e) => setNewUser({ ...newUser, npiType: e?.value })}
                    />

                    <InputField
                      type="text"
                      inputStyle="modalInput"
                      labelStyle="text-capitalize"
                      label="Taxonomy ID:"
                      value={newUser.taxid}
                      index="taxid"
                      maxLength={20}
                      placeholder="Taxonomy ID"
                      handleChange={(e) => setNewUser({ ...newUser, taxid: e.target.value })}
                    />
                    <InputField
                      type="text"
                      label="Speciality:"
                      inputStyle="modalInput"
                      labelStyle="text-capitalize"
                      value={newUser.speciality || ""}
                      placeholder="Enter Speciality"
                      index="specialitypi"
                      handleChange={(e) => setNewUser({ ...newUser, speciality: e.target.value })}
                    />
                  </div>
                </div>

                <div className="testAdmin createClientsWrapper allowShrink resubmissionFormWrapper">
                  <div className={"crew-profile-row4 crew-profile-again"}>
                    {/* <Form.Group className="form-group-wrapper">
                  <Form.Label>Default:</Form.Label>

                  <Select
                    options={DEFAULT_PROVIDERS}
                    blurInputOnSelect={true}
                    isClearable={true}
                    isMulti
                    menuPlacement="auto"
                    value={
                      defaultSetting && Object.keys(defaultSetting).length > 0
                        ? Object.entries(defaultSetting)
                            .filter(([key, value]) => value === newUser.id || (newUser.isNew && value))
                            .map(([key, val]) => ({ value: key, label: PARSE_PROVIDER[key] }))
                        : null
                    }
                    placeholder="Select Defualt Provider"
                    onChange={(e) => {
                      let obj = {};
                      e.map((m) => {
                        obj[m.value] = newUser.id || true;
                      });

                      setDefaultSetting(obj);
                    }}
                  />
                </Form.Group> */}

                    <InputField
                      type="phone"
                      inputStyle="modalInput"
                      labelStyle="text-capitalize"
                      label="Fax"
                      value={newUser.fax}
                      index="fax"
                      maxLength={20}
                      placeholder="Enter Fax"
                      handleChange={(e) => setNewUser({ ...newUser, fax: e })}
                    />

                    <Form.Group className="form-group-wrapper">
                      <Form.Label>Associated Client:</Form.Label>

                      <Select
                        options={CLIENT_OPTIONS}
                        blurInputOnSelect={true}
                        isClearable={true}
                        menuPlacement="auto"
                        value={CLIENT_OPTIONS.find((f) => f.value === newUser.associatedClient) || ""}
                        placeholder="Select Client"
                        onChange={(e) => setNewUser({ ...newUser, associatedClient: e?.value })}
                      />
                    </Form.Group>
                    <InputField
                      type="text"
                      groupWrapper={"form-group-wrapper "}
                      inputStyle="modalInput"
                      labelStyle="text-capitalize"
                      label="Internal Reference Code:"
                      value={newUser.code}
                      index="code"
                      maxLength={8}
                      placeholder="Internal Reference Code"
                      handleChange={(e) => setNewUser({ ...newUser, code: e.target.value })}
                    />
                  </div>
                </div>
                <div className="testAdmin createClientsWrapper allowShrink resubmissionFormWrapper">
                  <div className="crew-profile-row4 crew-profile-again">
                    <div
                      className="d-flex align-items-center column-gap-10 profile-
                          detail mb-3"
                    >
                      <span className="section-title">Active: </span>
                      <SwitchToggle
                        checked={newUser.isActive}
                        handleChange={(e) =>
                          setNewUser({
                            ...newUser,
                            isActive: e.target.checked,
                          })
                        }
                      />
                    </div>
                    <div>
                      {newUser && newUser?.isNew && (
                        <div className="testAdmin createClientsWrapper allowShrink resubmissionFormWrapper">
                          <div
                            className="d-flex align-items-center justify-content-between profile-
                          detail mb-3 column-gap-10"
                          >
                            <span>Create Portal Access ?</span>
                            <SwitchToggle
                              id="isCheckedUser"
                              name="isCheckedUser"
                              handleToogleChange={handleUserCreate}
                              checked={newUser?.isCheckedUser === "active"}
                            />
                          </div>
                        </div>
                      )}
                      {!newUser?.isNew && !newUser?.subID && (
                        <div className="testAdmin createClientsWrapper allowShrink resubmissionFormWrapper align-items-center justify-content-between">
                          <span>Portal Access</span>
                          <span
                            className="modalButtons cursor-pointer text-decoration-underline text-primary"
                            variant="secondary"
                            onClick={createUserForPortal}
                          >
                            Create User
                          </span>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="leftSide"
              style={{
                flex: "0 0 calc(30% - 15px )",
                maxWidth: "calc(30% - 15px )",
              }}
            >
              <div className="p-3 permissionBox">
                <strong>Result Reporting</strong>
                <div>
                  <div className="d-flex align-items-center justify-content-between userPermission">
                    <span className="mx-1 font-bold">Provider</span>
                    <SwitchToggle
                      checked={newUser?.setting?.resultReportingProvider}
                      handleChange={(e) =>
                        setNewUser({
                          ...newUser,
                          setting: { ...newUser.setting, resultReportingProvider: e.target.checked },
                        })
                      }
                    />
                  </div>
                  <div className="userPermission d-block">
                    <div className="d-flex align-items-center justify-content-between">
                      <span className="mx-1 font-bold">Patient</span>
                      <SwitchToggle
                        checked={newUser?.setting?.resultReportingPatient}
                        handleChange={handleResultReportingChange}
                      />
                    </div>
                    <div style={{ marginLeft: "10px" }}>
                      <div className="d-flex align-items-center justify-content-between">
                        <span className="mx-1">Portal Only</span>
                        <SwitchToggle
                          checked={newUser?.setting?.portalOnly}
                          handleChange={(e) =>
                            setNewUser({
                              ...newUser,
                              setting: { ...newUser.setting, portalOnly: e.target.checked },
                            })
                          }
                          disabled={!newUser?.setting?.resultReportingPatient}
                        />
                      </div>

                      <div className="d-flex align-items-center justify-content-between">
                        <span className="mx-1">Patient Preference</span>
                        <SwitchToggle
                          checked={newUser?.setting?.patientPreference}
                          handleChange={(e) =>
                            setNewUser({
                              ...newUser,
                              setting: { ...newUser.setting, patientPreference: e.target.checked },
                            })
                          }
                          disabled={!newUser?.setting?.resultReportingPatient}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
        <div className="px-4" style={{ minHeight: "3vh" }}>
          {error && <ErrorMessage error={error} handleChange={() => setError("")} />}
        </div>
        <Modal.Footer>
          <Button
            style={{ marginBottom: 10 }}
            variant="primary"
            className="modalButtons headerButton btn-fill"
            onClick={() => {
              handleClose();
            }}
          >
            Close
          </Button>

          {!readOnly && (
            <Button
              style={{ marginBottom: 10 }}
              variant="secondary"
              className="modalButtons headerButton btn-fill"
              onClick={onHandleSave}
            >
              {newUser.isNew ? "Create" : "Update"}
            </Button>
          )}
        </Modal.Footer>
      </Modal>
      {recordData.length > 1 && (
        <ProviderSearchRecord
          data={recordData}
          handleSelect={populate}
          handleClose={() => {
            setRecordData([]), setSelectSearchData(null);
          }}
          setSelectSearchData={setSelectSearchData}
          selectSearchdata={selectSearchData}
        />
      )}
    </>
  );
};

export default ProviderModal;
