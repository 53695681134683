import React, { useEffect, useState, useContext } from "react";
import "bootstrap-daterangepicker/daterangepicker.css";
// react-bootstrap components
import { Button, Card, Form, Container, Row, Col, Modal } from "react-bootstrap";
import { useLocation } from "react-router-dom";
import API from "api";
import { Storage } from "aws-amplify";
import SweetAlert from "react-bootstrap-sweetalert";
import { AppContext } from "../context/app-context";
import ErrorMessage from "components/Message/ErrorMessage";
import { PDFViewer } from "@react-pdf/renderer";
import RequisitionPDF from "components/RequisitionPDF";
import { Bounce } from "react-activity";
import {
  getDemoGraphics,
  formatZipCode,
  formatTest,
  isValidPhone,
  setPhoneNo,
  formatPhoneNumber,
  formatDateOfBirthDOB,
  phoneFormatter,
  formatQuaratineDisplayDate,
} from "../utils";
import "react-activity/dist/Bounce.css";
import ImageView from "components/Image/ImageView";
import ReactPDF from "@react-pdf/renderer";
import PDF from "components/PDF";
import moment from "moment";
import api from "../api";
import QuarantineModal from "components/Modal/QuarantineModal";
import SingleDatePicker from "Date/SingleDatePicker";
import Loader from "../components/Loader/Loader";
import AutoCompleteAddress from "components/AutoCompleteAddress";

function ExternalTestCrew(props) {
  const [test, setTest] = useState();
  const [site, setSite] = useState();
  const [lab, setLab] = useState();
  const [siteContactPhone, setSiteContactPhone] = useState();
  const [demographics, setDemographics] = useState();
  const [showAlert, setShowAlert] = useState(false);
  const [modalShow, setModalShow] = useState(false);
  const [releaseTo, setReleaseTo] = useState([]);
  const [showPDF, setShowPDF] = useState();
  const [signature, setSignature] = useState();
  const [loading, setLoading] = useState(false);
  const [hippaConsent, setHippaConsent] = useState(false);
  const [showRevokeAlert, setShowRevokeAlert] = useState(false);
  const [showImages, setShowImages] = useState(null);
  const [showSubmitAlert, setShowSubmitAlert] = useState(false);
  const [editButton, setEditButton] = useState(true);
  const [editInfo, setEditInfo] = useState();
  const [Employee, setEmployee] = useState();
  const [error, setError] = useState("");
  const [openQuarantined, setOpenQuaratined] = useState(false);
  const [allowToEditQuarantine, setAllowToEditQuarantine] = useState(false);
  const location = useLocation();
  const appContext = useContext(AppContext);

  const hideAlert = () => {
    props.history.push("/admin/pendingrelease");
    setShowAlert(false);
  };

  const getTest = async (id) => {
    const model = await API.findExternalTestByID(id);
    setEditInfo(model);
    setDemographics(getDemoGraphics(model));
    setTest(model);
  };

  const {
    match: { params },
  } = props;

  useEffect(() => {
    setLoading(true);
    const {
      match: { params },
    } = props;
    getTest(params.testId);
  }, []);

  useEffect(() => {
    if (appContext.user) setAllowToEditQuarantine(appContext.user.isSite() || appContext.user.isClient());
  }, [appContext.user]);

  useEffect(() => {
    if (editInfo && editInfo.employee_demographics) {
      const { employee_demographics } = editInfo;
      setEmployee(employee_demographics);
    }
  }, [editInfo]);

  useEffect(() => {
    const getSite = async (id) => {
      const model = await API.findSiteByID(id);

      model.length > 0 && setSite(model[0]);
    };

    const getSignature = async (test) => {
      /* const hippaRecord = await API.findHippaConsent(test.employeeID, test.siteID);
      console.log("Hippa Record", hippaRecord);
      const isHippaConsent = true; */
      const imgObject = [];

      try {
        const fileName = `${test.siteID}&&${test.employeeID}&&sig.png`;
        const sigt = await Storage.get(fileName);
        setSignature(sigt);
        imgObject.push({ title: "HIPPA Consent", isHIPPA: true, imgSrc: sigt });

        const sig = await Storage.get(`${test.employeeID}&&${test.siteID}&&ins-f.jpeg`);
        if (sig) imgObject.push({ title: "Insurance Card Front", isHIPPA: false, imgSrc: sig });
        const sig1 = await Storage.get(`${test.employeeID}&&${test.siteID}&&ins-b.jpeg`);
        if (sig1) imgObject.push({ title: "Insurance Card Back", isHIPPA: false, imgSrc: sig1 });
      } catch (error) {
        console.log("Image Error", error);
      }

      if (imgObject.length > 0) {
        setShowImages(imgObject);
      }
      setHippaConsent(true);
    };

    if (test) {
      setSite(getSite(test.siteID));
      console.log("appcontext.getLab", appContext);
      appContext.getLab(test).then((resp) => setLab(resp));

      getLabByID(test);
      getSignature(test);
    }

    setLoading(false);
  }, [test]);

  useEffect(() => {
    site && setSiteContactPhone(site.contact_phone);
  }, [site]);

  const getLabByID = async (test) => {
    let lab = lab?.find((lab) => lab.id === test.labID);
    if (!lab) {
      lab = await API.getLabByID(test?.labID);
    }
    setLab(lab);
  };

  function calculate_age(dob) {
    const y = dob?.substring(4);
    const m = parseInt(dob?.substring(0, 2)) - 1;
    const d = dob?.substring(2, 4);
    const newDob = new Date(y, m, d);
    const diff_ms = Date.now() - newDob.getTime();
    const age_dt = new Date(diff_ms);
    return Math.abs(age_dt.getUTCFullYear() - 1970);
  }

  const capitalizeFirst = (string) => {
    return string.charAt(0).toUpperCase() + string.slice(1);
  };

  // const handleReleaseTo = (e) => {
  //   setReleaseTo({...releaseTo, ...e.target.value})
  //   console.log(e, releaseTo);
  // };

  const formatIDNo = () => {
    if (calculate_age(demographics?.dob) < 18) {
      const id = demographics?.idNumber;
      if (id && id.length > 5) {
        return `${id.substring(0, 3)}-${id.substring(3, 5)}-${id.substring(5)}`;
      }
      if (id && id.length > 3) {
        return `${id.substring(0, 3)}-${id.substring(3)}`;
      }
      return demographics?.idNumber;
    }
    return demographics?.idNumber;
  };

  const showSubmitButton = () => {
    if (!test) return true;
    const st = test.status.toLowerCase();
    if (st === "new") return true;
    if (st === "pending" && !test.result) return true;
    return false;
  };
  const handleSave = async () => {
    if (!Employee.firstName) {
      setError("First name is required");
      return;
    }
    if (!Employee.lastName) {
      setError("Last name is required");
      return;
    }

    if (!Employee.email) {
      setError("Email name is required");
      return;
    }

    if (!Employee.phoneNumber) {
      setError("Phone name is required");
      return;
    }
    if (!isValidPhone(Employee.phoneNumber)) {
      setError("Invalid Phone Number");
      return;
    }
    if (!Employee.dob) {
      setError("DOB is required");
      return;
    }
    // if (Employee.zip.length < 5) {
    //   setError("Invalid Postal Code");
    //   return;
    // }
    if (!isValidZipCode(demographics?.isCode || "US", Employee.zip)) {
      setError("Invalid Postal Code");
      return;
    }
    if (!new RegExp(/[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,15}/g).test(Employee.email)) {
      setError("Invalid Email");
      return;
    }
    if (window.confirm("Are you Sure?")) {
      const obj = { ...editInfo, employee_demographics: Employee };

      await api.updateCompleteCrewInfo({ crew: obj, user: appContext.user, isConfirm: false });
      setEditInfo(obj);
      setEditButton(true);
    }
  };
  const handelCancelButton = () => {
    setEditButton(true);
    getTest(params.testId);
  };
  const emailTest = (t) => {
    setLoading(true);
    const test = formatTest(t);
    appContext.getLab(test).then(async (resp) => {
      ReactPDF.pdf(<PDF test={test} testsLab={resp} parseTestResultForPDF={appContext.parseTestResultForPDF} />)
        .toBlob()
        .then(async (data) => {
          try {
            const fileName = `${test.id}-result.pdf`;
            await Storage.put(fileName, data);
            await API.sendEmailToCrew([{ email: test.email, fileName: `public/${fileName}` }]);
            await API.updateEmailStatus(test.id);
            // send sms as well if email is sending first time
            if (!test.emailSend && test.phoneNumber) {
              let smsMessage = `Hello, your test results are in for your test with ${test.site_name}, please log into Med Flow to review.`;
              const siteObj = appContext.sites.find((s) => s.id === test.siteID);
              if (siteObj && siteObj.messageResulted && result === "negative") {
                smsMessage = siteObj.messageResulted;
              } else if (siteObj && siteObj.messagePositive && result === "positive") {
                smsMessage = siteObj.messagePositive;
              }
              await API.sendSMSNotification(setPhoneNo(test.phoneNumber), smsMessage);
            }
            setLoading(false);
            appContext.showSuccessMessage("Email has been sent successfully");
          } catch (error) {
            console.log("Error");
            setLoading(false);
          }
        });
    });
  };

  const handleSaveQuarantine = async (obj) => {
    try {
      await API.updateQuarantineData(test.id, obj.startDate, obj.endDate);
      getTest(test.id);
      appContext.showSuccessMessage("Quarantine dates changed successfully");
      window.location.reload();
    } catch (err) {
      appContext.showErrorMessage(err.message);
    }
  };

  const handleUpdateAddress = (val, flag) => {
    if (!flag) {
      setEmployee({ ...Employee, street: val });
    } else {
      const addressObj = {
        street: val.street,
        country: val.country,
        city: val.city,
        zipcode: val.zipcode,
        zip: val.zipcode,
        state: val.state,
        isoCode: val.isoCode,
      };
      setEmployee({ ...Employee, ...addressObj });
    }
  };

  return showPDF ? (
    <Container fluid>
      <Row>
        <Col md="12">
          <Card style={{ padding: 20, marginLeft: "auto", marginRight: "auto" }} className="strpied-tabled-with-hover">
            <Card.Header>
              <Row style={{ alignItems: "center" }}>
                <Card.Title
                  as="h4"
                  style={{
                    marginBottom: 10,
                    fontWeight: "bold",
                    color: "#565656",
                  }}
                >
                  Requisition Form - {demographics?.finationrstName} {demographics?.lastName}
                </Card.Title>
                <Button style={{ marginLeft: "auto", marginRight: "auto" }} onClick={() => setShowPDF(false)}>
                  Close
                </Button>
              </Row>
            </Card.Header>
            <Card.Body className="table-full-width table-responsive px-0">
              <PDFViewer className="pdfWrapper">
                <RequisitionPDF test={test} lab={lab} signature={signature} demographics={demographics} />
                {/* <SitePDF site={pdf} client={client} /> */}
              </PDFViewer>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  ) : !loading ? (
    <>
      <Container fluid>
        <SweetAlert show={showAlert} success title="Success" onConfirm={hideAlert}>
          Test Released
        </SweetAlert>
        <SweetAlert show={showRevokeAlert} success title="Success" onConfirm={() => setShowRevokeAlert(false)} />
        <SweetAlert
          show={showSubmitAlert}
          success
          title="Success"
          onConfirm={() => {
            window.history.back();
            setShowSubmitAlert(false);
          }}
        >
          Test Submitted Successfully.
        </SweetAlert>
        <Row>
          <Col md="7">
            <Card>
              <Card.Header>
                <Card.Title
                  as="h4"
                  style={{
                    marginBottom: 10,
                    fontWeight: "bold",
                    color: "#565656",
                  }}
                >
                  Patient Profile{" "}
                  {calculate_age(demographics?.dob) < 18 ? (
                    <span style={{ color: "red", fontWeight: "bold" }}>- Minor</span>
                  ) : null}
                </Card.Title>
              </Card.Header>
              <Card.Body>
                <Form>
                  {/* <Row>
                    <Col className="pr-1" md="5">
                      <Form.Group>
                        <label>Company (disabled)</label>
                        <Form.Control
                          defaultValue="Creative Code Inc."
                          disabled
                          placeholder="Company"
                          type="text"
                        />
                      </Form.Group>
                    </Col>
                    <Col className="px-1" md="3">
                      <Form.Group>
                        <label>Username</label>
                        <Form.Control
                          defaultValue="michael23"
                          placeholder="Username"
                          type="text"
                        />
                      </Form.Group>
                    </Col>
                    <Col className="pl-1" md="4">
                      <Form.Group>
                        <label htmlFor="exampleInputEmail1">
                          Email address
                        </label>
                        <Form.Control
                          placeholder="Email"
                          type="email"
                        />
                      </Form.Group>
                    </Col>
                  </Row> */}
                  <Row>
                    <Col className="pr-1" md="3">
                      <Form.Group>
                        <label>First Name</label>
                        <Form.Control
                          readOnly={editButton}
                          plaintext
                          className={`${!editButton ? "EditStyle" : ""}`}
                          onChange={(e) => setEmployee({ ...Employee, firstName: e.target.value })}
                          value={Employee?.firstName}
                          defaultValue={demographics?.firstName}
                          placeholder={`${!editButton ? "Enter First Name " : ""}`}
                          type="text"
                        />
                      </Form.Group>
                    </Col>
                    <Col className="pl-1" md="3">
                      <Form.Group>
                        <label>Last Name</label>
                        <Form.Control
                          readOnly={editButton}
                          plaintext
                          className={`${!editButton ? "EditStyle" : ""}`}
                          onChange={(e) => setEmployee({ ...Employee, lastName: e.target.value })}
                          value={Employee?.lastName}
                          defaultValue={demographics?.lastName}
                          placeholder={`${!editButton ? "Enter Last Name " : ""}`}
                          type="text"
                        />
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row>
                    <Col md="3">
                      <Form.Group>
                        <label>Phone Number</label>
                        <Form.Control
                          style={{
                            textDecoration: "underline",
                            color: "#A82632",
                          }}
                          className={`${!editButton ? "EditStyle" : ""}`}
                          onMouseOver={(e) => {
                            editButton ? (e.target.style.cursor = "pointer") : (e.target.style.cursor = "auto");
                            e.target.style.textDecoration = "underline";
                          }}
                          onClick={() =>
                            editButton ? Employee?.phoneNumber && window.open(`tel:+1${Employee.phoneNumber}`) : false
                          }
                          onChange={(e) => setEmployee({ ...Employee, phoneNumber: setPhoneNo(e.target.value) })}
                          readOnly={editButton}
                          maxLength={15}
                          plaintext
                          value={
                            editButton
                              ? formatPhoneNumber(Employee?.phoneNumber)
                              : phoneFormatter(Employee?.phoneNumber)
                          }
                          defaultValue={
                            test?.phoneNumber &&
                            `(${test.phoneNumber.substring(0, 3)}) ${test.phoneNumber.substring(
                              3,
                              6
                            )}-${test.phoneNumber.substring(6)}`
                          }
                          placeholder={`${!editButton ? "Enter Phone Number " : ""}`}
                          type="text"
                        />
                      </Form.Group>
                    </Col>
                    <Col md="3">
                      <Form.Group>
                        <label>Date of Birth</label>
                        {editButton ? (
                          <Form.Control
                            readOnly={editButton}
                            plaintext
                            onChange={(e) => setEmployee({ ...Employee, dob: e.target.value })}
                            value={formatDateOfBirthDOB(Employee?.dob)}
                            defaultValue={formatDateOfBirthDOB(demographics?.dob)}
                            placeholder="Date of Birth"
                            type="text"
                          />
                        ) : (
                          <div>
                            <SingleDatePicker
                              onApply={(event, picker) => {
                                setEmployee({ ...Employee, dob: moment(picker.startDate).format("MMDDYYYY") });
                              }}
                              value={formatDateOfBirthDOB(Employee?.dob)}
                              startDate={formatDateOfBirthDOB(Employee?.dob)}
                              currentDate={true}
                            />
                          </div>
                        )}
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row>
                    <Col md="3">
                      <Form.Group>
                        <label>Email Address</label>
                        <Form.Control
                          style={{
                            textDecoration: "underline",
                            color: "#A82632",
                          }}
                          className={`${!editButton ? "EditStyle" : ""}`}
                          onMouseOver={(e) => {
                            editButton ? (e.target.style.cursor = "pointer") : (e.target.style.cursor = "auto");
                            e.target.style.textDecoration = "underline";
                          }}
                          onClick={() =>
                            editButton ? Employee?.email && window.open(`mailto:${Employee.email}`) : false
                          }
                          plaintext
                          onChange={(e) => setEmployee({ ...Employee, email: e.target.value })}
                          value={Employee?.email}
                          readOnly={editButton}
                          defaultValue={test?.email}
                          placeholder={`${!editButton ? "Enter Email " : ""}`}
                          type="text"
                        />
                      </Form.Group>
                    </Col>
                    <Col md="3">
                      <Form.Group readOnly={editButton} plaintext>
                        {calculate_age(demographics?.dob) > 18 ? <label>ID Number</label> : <label>SSN</label>}
                        <Form.Control
                          plaintext
                          readOnly
                          defaultValue={formatIDNo()}
                          placeholder="ID Number"
                          type="text"
                        />
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row>
                    <Col md="3">
                      <Form.Group>
                        <label>Address</label>
                        {/* <Form.Control
                          plaintext
                          className={`${!editButton ? "EditStyle" : ""}`}
                          onChange={(e) => setEmployee({ ...Employee, street: e.target.value })}
                          readOnly={editButton}
                          value={Employee?.street}
                          defaultValue={demographics?.street}
                          placeholder={`${!editButton ? "Enter Address  " : ""}`}
                          type="text"
                        /> */}
                        <AutoCompleteAddress
                          readOnly={editButton}
                          value={Employee?.street}
                          handleChange={handleUpdateAddress}
                        />
                      </Form.Group>
                    </Col>
                    <Col md="3">
                      <Form.Group>
                        <label>Address 2</label>
                        <Form.Control
                          plaintext
                          className={`${!editButton ? "EditStyle" : ""}`}
                          readOnly={editButton}
                          onChange={(e) => setEmployee({ ...Employee, street2: e.target.value })}
                          value={Employee?.street2}
                          defaultValue={demographics?.street2}
                          placeholder={`${!editButton ? "Enter Address 2 " : ""}`}
                          type="text"
                        />
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row>
                    <Col className="pr-1" md="4">
                      <Form.Group>
                        <label>City</label>
                        <Form.Control
                          plaintext
                          readOnly={editButton}
                          className={`${!editButton ? "EditStyle" : ""}`}
                          onChange={(e) => setEmployee({ ...Employee, city: e.target.value })}
                          value={Employee?.city}
                          defaultValue={demographics?.city}
                          placeholder={`${!editButton ? "Enter City" : ""}`}
                          type="text"
                        />
                      </Form.Group>
                    </Col>
                    <Col className="pl-1" md="4">
                      <Form.Group>
                        <label>State</label>
                        <Form.Control
                          plaintext
                          className={`${!editButton ? "EditStyle" : ""}`}
                          readOnly={editButton}
                          onChange={(e) => setEmployee({ ...Employee, state: e.target.value })}
                          value={Employee?.state}
                          defaultValue={demographics?.state}
                          placeholder={`${!editButton ? "Enter Country" : ""}`}
                          type="text"
                        />
                      </Form.Group>
                    </Col>
                    <Col className="pl-1" md="4">
                      <Form.Group>
                        <label>Postal Code</label>
                        <Form.Control
                          plaintext
                          className={`${!editButton ? "EditStyle" : ""}`}
                          onChange={(e) => setEmployee({ ...Employee, zip: e.target.value })}
                          readOnly={editButton}
                          value={formatZipCode(Employee?.zip)}
                          defaultValue={formatZipCode(demographics?.zip)}
                          placeholder={`${!editButton ? "Enter Postal Code " : ""}`}
                          type="text"
                        />
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row>
                    <Col md="4">
                      <Form.Group>
                        <label>Insurance Carrier</label>
                        <Form.Control
                          plaintext
                          className={`${!editButton ? "EditStyle" : ""}`}
                          readOnly={editButton}
                          onChange={(e) => setEmployee({ ...Employee, insurance_name: e.target.value })}
                          value={Employee?.insurance_name}
                          defaultValue={demographics?.insurance_name}
                          placeholder={`${!editButton ? "Enter Insurance Carrier" : ""}`}
                          type="text"
                        />
                      </Form.Group>
                    </Col>
                    <Col md="4">
                      <Form.Group>
                        <label>Insurance ID</label>
                        <Form.Control
                          plaintext
                          className={`${!editButton ? "EditStyle" : ""}`}
                          readOnly={editButton}
                          onChange={(e) => setEmployee({ ...Employee, insurance_number: e.target.value })}
                          value={Employee?.insurance_number}
                          defaultValue={demographics?.insurance_number}
                          placeholder={`${!editButton ? "Enter Insurance Id" : ""}`}
                          type="text"
                        />
                      </Form.Group>
                    </Col>
                    <Col md="4">
                      {editButton ? (
                        <div>
                          <h6 className="vaccination-status-name">Vaccination Status</h6>
                          <span className="vaccination-status">{Employee?.isVaccinated ? "Yes" : "No"}</span>
                        </div>
                      ) : (
                        <div>
                          <h6 className="vaccination-status-name">Vaccination Status</h6>
                          <input
                            className="radioBox-wrapper"
                            type="radio"
                            defaultChecked={Employee?.isVaccinated}
                            name="isVaccinated"
                            onChange={(e) => setEmployee({ ...Employee, isVaccinated: e.target.checked && true })}
                          />
                          <span className="radioButton">Yes</span>
                          <input
                            className="radioBox-wrapper"
                            type="radio"
                            defaultChecked={!Employee?.isVaccinated}
                            name="isVaccinated"
                            onChange={(e) => setEmployee({ ...Employee, isVaccinated: e.target.checked && false })}
                          />
                          <span className="radioButton">No</span>
                        </div>
                      )}
                    </Col>
                  </Row>
                  <Row>
                    <Col md="4">
                      <h6 className="vaccination-status-name">HIPAA Consent</h6>
                      <span className="vaccination-status">{hippaConsent ? "True" : "False"}</span>
                    </Col>
                    <Col md="4">
                      <h6 className="vaccination-status-name">White Glove</h6>
                      <span className="vaccination-status">{Employee?.whiteGlove ? "True" : "False"}</span>
                    </Col>
                    <Col md="4">
                      <h6 className="vaccination-status-name">Tester Designation</h6>
                      <span className="vaccination-status">{Employee?.employeeType ? Employee?.employeeType : ""}</span>
                    </Col>
                  </Row>
                  {allowToEditQuarantine && (
                    <Row>
                      <Col md="4">
                        <h6 className="vaccination-status-name">Quarantined Start</h6>
                        <span className="vaccination-status">{formatQuaratineDisplayDate(test?.quarantinedStart)}</span>
                      </Col>
                      <Col md="4">
                        <h6 className="vaccination-status-name">Quarantined End</h6>
                        <span className="vaccination-status">{formatQuaratineDisplayDate(test?.quarantinedEnd)}</span>
                      </Col>
                    </Row>
                  )}

                  <Row>
                    <Col md="12">
                      <Form.Group>
                        <label>Notes</label>
                        <Form.Control cols="80" defaultValue="" placeholder="" rows="4" as="textarea" />
                      </Form.Group>
                    </Col>
                  </Row>
                  {error && <ErrorMessage error={error} handleChange={() => setError("")} />}
                  {location.state?.pendingRelease && test?.result !== "negative" && (
                    <Button
                      className="btn-fill pull-right createButtons"
                      variant="warning"
                      style={{
                        marginRight: 10,
                        marginTop: 10,
                      }}
                      onClick={async () => {
                        // const result = await API.releaseTest(test);
                        // if (result) {
                        //   setShowAlert(true);
                        // }
                        setModalShow(true);
                      }}
                    >
                      Release Test Results
                    </Button>
                  )}
                  {location.state?.pendingRelease && (
                    <>
                      <Button
                        className="btn-fill float-right createButtons"
                        style={{
                          marginRight: 10,
                          marginTop: 10,
                        }}
                        variant="danger"
                        onClick={async () => {
                          const result = await API.sendSMSNotification(
                            test.phoneNumber,
                            "Hello, can you please report back to your testing site for further information regarding your test."
                          );
                          if (result) {
                            alert("The crew member has been sent a text message to be retested.");
                          }
                        }}
                      >
                        Retest
                      </Button>
                      <Button
                        className="btn-fill float-right createButtons"
                        style={{
                          marginRight: 10,
                          marginTop: 10,
                        }}
                        variant="warning"
                        onClick={async () => setShowPDF(true)}
                      >
                        Requisition Form
                      </Button>
                    </>
                  )}
                  {location.state?.unprocessed && (
                    <>
                      <Button
                        className="btn-fill float-right createButtons"
                        style={{
                          marginRight: 10,
                          marginTop: 10,
                        }}
                        variant="warning"
                        onClick={async () => setShowPDF(true)}
                      >
                        Requisition Form
                      </Button>
                    </>
                  )}
                  {location.state?.labPortal && (
                    <Button
                      className="btn-fill float-right createButtons"
                      style={{
                        marginRight: 10,
                        marginTop: 10,
                      }}
                      variant="warning"
                      onClick={async () => setShowPDF(true)}
                    >
                      Requisition Form
                    </Button>
                  )}
                  {location.state?.admin && (
                    <Button
                      className="btn-fill float-right createButtons"
                      style={{
                        marginRight: 10,
                        marginTop: 10,
                      }}
                      variant="warning"
                      onClick={async () => setShowPDF(true)}
                    >
                      Requisition Form
                    </Button>
                  )}
                  {test?.result && (
                    <Button
                      className="btn-fill float-right createButtons"
                      style={{
                        marginRight: 10,
                        marginTop: 10,
                      }}
                      variant="warning"
                      onClick={async () => emailTest(test)}
                    >
                      Resend Results
                    </Button>
                  )}

                  {(test?.result === "Positive" || test?.result === "Fail") && allowToEditQuarantine && (
                    <Button
                      className="btn-fill float-right createButtons"
                      style={{
                        marginRight: 10,
                        marginTop: 10,
                      }}
                      variant="warning"
                      onClick={() => setOpenQuaratined(true)}
                    >
                      Quarantined
                    </Button>
                  )}
                  <Button
                    className="btn-fill float-right createButtons"
                    style={{
                      marginRight: 10,
                      marginTop: 10,
                    }}
                    variant="warning"
                    onClick={() => setShowRevokeAlert(true)}
                  >
                    Revoke
                  </Button>
                  {/* {editButton && (
                    <Button
                      className="btn-fill float-right createButtons"
                      style={{
                        marginRight: 10,
                        marginTop: 10,
                      }}
                      variant="warning"
                      onClick={() => setEditButton(false)}
                    >
                      Edit Info
                    </Button>
                  )}
                  {!editButton && (
                    <Button
                      className="btn-fill float-right createButtons"
                      style={{
                        marginRight: 10,
                        marginTop: 10,
                      }}
                      variant="success"
                      onClick={handleSave}
                    >
                      Save
                    </Button>
                  )}
                  {!editButton && (
                    <Button
                      className="btn-fill float-right createButtons"
                      style={{
                        marginRight: 10,
                        marginTop: 10,
                      }}
                      variant="secondary"
                      onClick={handelCancelButton}
                    >
                      Cancel
                    </Button>
                  )} */}

                  <div className="clearfix" />
                </Form>
              </Card.Body>
            </Card>
          </Col>
          <Col md="5">
            <Card>
              <Card.Header
                as="h5"
                style={{
                  marginBottom: 10,
                  fontWeight: "bold",
                  color: "#565656",
                }}
              >
                Test Details
              </Card.Header>
              <Card.Body>
                <Card.Text>Type: {demographics?.externalTestType}</Card.Text>
                <Card.Text>Date Collected: {moment(test?.createdAt).format("llll")}</Card.Text>
                <Card.Text>Show: {test?.site_name}</Card.Text>
                {test?.result && <Card.Text>Date Processed: {moment(test?.updatedAt).format("llll")}</Card.Text>}
                <Card.Text>Result: {demographics?.externalTestResult}</Card.Text>
                <Card.Text>
                  Test Date:
                  {demographics?.externalTestResult
                    ? demographics?.externalTestDate
                      ? moment(demographics?.externalTestDate).format("MM-DD-YYYY HH:mm:ss")
                      : moment(demographics?.externalTestDate).format("MM-DD-YYYY HH:mm:ss")
                    : ""}
                </Card.Text>
                <Card.Text>Tester: {test?.tester_name}</Card.Text>
              </Card.Body>
            </Card>
            {showImages && (
              <Card>
                <Card.Header
                  as="h5"
                  style={{
                    marginBottom: 10,
                    fontWeight: "bold",
                    color: "#565656",
                  }}
                >
                  HIPPA Signature
                </Card.Header>
                <Card.Body className="imageWrapper">
                  {showImages
                    .filter((i) => i.isHIPPA === true)
                    .map((showImg, index) => {
                      return <ImageView key={index} imgSrc={showImg.imgSrc} />;
                    })}
                </Card.Body>
              </Card>
            )}
            {showImages && (
              <Card>
                <Card.Header
                  as="h5"
                  style={{
                    marginBottom: 10,
                    fontWeight: "bold",
                    color: "#565656",
                  }}
                >
                  Insurance Card Images
                </Card.Header>
                <Card.Body className="imageWrapper">
                  {showImages
                    .filter((i) => i.isHIPPA === false)
                    .map((showImg, index) => {
                      return <ImageView key={index} imgSrc={showImg.imgSrc} />;
                    })}
                </Card.Body>
              </Card>
            )}
            <Card>
              <Card.Header
                as="h5"
                style={{
                  marginBottom: 10,
                  fontWeight: "bold",
                  color: "#565656",
                }}
              >
                Vaccination Card Image
              </Card.Header>
              <Card.Body className="imageWrapper">{/*  <ImageView key={index} imgSrc={showImg.imgSrc} /> */}</Card.Body>
            </Card>
            <Card>
              <Card.Header
                as="h5"
                style={{
                  marginBottom: 10,
                  fontWeight: "bold",
                  color: "#565656",
                }}
              >
                External Test Image
              </Card.Header>
              <Card.Body className="imageWrapper">{/*  <ImageView key={index} imgSrc={showImg.imgSrc} /> */}</Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
      <Modal
        show={modalShow}
        // style={{ transform: "translate(0, -30%)" }}
        animation={true}
        onHide={() => setModalShow(false)}
        centered
        size={"md"}
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">Release Test Results</Modal.Title>
        </Modal.Header>

        <Modal.Body style={{ paddingTop: 0, marginRight: "auto", marginLeft: "auto" }}>
          <p>Select all that apply: </p>
          <div className="checkboxRow">
            <label className="checkboxLabel">
              <input
                disabled={!siteContactPhone}
                value="site"
                onChange={(e) => {
                  if (e.target.checked) {
                    setReleaseTo({
                      ...releaseTo,
                      [e.target.value]: siteContactPhone,
                    });
                  } else {
                    const newReleaseTo = releaseTo;
                    delete newReleaseTo[e.target.value];
                    setReleaseTo(newReleaseTo);
                  }
                }}
                className="releaseCheckbox"
                type="checkbox"
              />
              Health and Safety Officer
            </label>
            <label>
              <input
                value="crew"
                onChange={(e) => {
                  if (e.target.checked) {
                    setReleaseTo({
                      ...releaseTo,
                      [e.target.value]: `+1${test?.phoneNumber.replace(/\D/g, "")}`,
                    });
                  } else {
                    const newReleaseTo = releaseTo;
                    delete newReleaseTo[e.target.value];
                    setReleaseTo(newReleaseTo);
                  }
                }}
                className="releaseCheckbox"
                type="checkbox"
              />
              Crew Member
            </label>
          </div>
        </Modal.Body>

        <Modal.Footer>
          <Button className="modalButtons" variant="secondary" onClick={() => setModalShow(false)}>
            Cancel
          </Button>
          <Button
            className="modalButtons"
            variant="primary"
            onClick={async () => {
              await API.releaseTest(test);
              releaseTo.site &&
                (await API.sendSMSNotification(
                  releaseTo.site,
                  `Hello, there was a positive (${test.test_type}) test result for ${site.name}. please login to your Health & Safety Portal to review.`
                ));
              releaseTo.crew &&
                (await API.sendSMSNotification(
                  releaseTo.crew,
                  `Hello, your test results are in for your test with ${site.name}, please log into Med Flow to review.`
                ));
              await appContext.resetPendingTests();
              props.history.push("/admin/pendingrelease");
            }}
          >
            Release Results
          </Button>
        </Modal.Footer>
      </Modal>
      {openQuarantined && (
        <QuarantineModal test={test} handleSave={handleSaveQuarantine} handleClose={() => setOpenQuaratined(false)} />
      )}
    </>
  ) : (
    <Loader />
  );
}

export default ExternalTestCrew;
