import React, { useState } from "react";
import Loader from "components/Loader/Loader";
import { NOTIFICATION_STATUS } from "constant";
import { Button, Modal, Card, Table, Row, Col } from "react-bootstrap";
import Status from "components/Status/Status";
import StatsView from "components/PreRegister/StatsView";
import ConfirmationModal from "components/Modal/ConfirmationModal";

const ImportFileStatusModal = ({ data, handleClose, handleCancelRequests }) => {
  // const [loading, setLoading] = useState(false);

  let files = data.filesStatus || [];
  const TableRow = ({ err, index }) => {
    let ttl = data?.Items?.filter((item) => item.fileID === err.id);
    return (
      <tr>
        <td style={{ textAlign: "center" }}>{index + 1}</td>
        <td>{err?.fileName.split("$")[1]}</td>
        {/* <td>{err?.status}</td> */}
        <td className="ellipsis text-center">
          <div className="star-icon-wrapper cursor-pointer" style={{ textAlign: "center" }}>
            <Status
              type="circle"
              size="md"
              color={err.status === "pending" ? "gray" : err.status.includes("Parsed") ? "green" : "orange"}
              crossIcon
            />
          </div>
        </td>
        <td style={{ textAlign: "center", fontWeight: "bold" }}>{ttl?.length ?? 0}</td>
        <td style={{ textAlign: "center", fontWeight: "bold" }}>
          {ttl?.filter((item) => item.status !== NOTIFICATION_STATUS.pending).length ?? 0}
        </td>
        <td style={{ textAlign: "center", fontWeight: "bold" }}>
          {ttl?.filter((item) => item.status == NOTIFICATION_STATUS.pending).length ?? 0}
        </td>
      </tr>
    );
  };
  return (
    <>
      <Modal show animation={true} onHide={() => handleClose()} size={"lg"} scrollable style={{ marginBottom: 0 }}>
        <Modal.Header closeButton>
          <Modal.Title className="my-0" id="contained-modal-title-vcenter">
            File Upload Summary
          </Modal.Title>
        </Modal.Header>

        <Modal.Body className="marginTop0" style={{ paddingTop: "0px" }}>
          <>
            <div className="statsOrderDetails">
              <StatsView label="Files" value={files.length ?? 0} cssClass={"fs-5"} />
              <StatsView
                label="Completed"
                value={files.filter((f) => f.status.includes("Parsed")).length ?? 0}
                cssClass={"fs-5"}
              />
              <StatsView
                label="Remaining"
                value={files.filter((f) => !f.status.includes("Parsed")).length ?? 0}
                cssClass={"fs-5"}
              />
            </div>
            <div style={{ flex: 1 }}>
              <Row>
                <Col md="12">
                  <Card className="strpied-tabled-with-hover">
                    <Card.Body>
                      {files.length > 0 && (
                        <div className="d-block">
                          <div className="table-responsive" style={{ overflow: "hidden" }}>
                            <span>Details</span>
                            <Table className="table-hover table-striped">
                              <thead>
                                <tr>
                                  <th>Sr#</th>
                                  <th>FileName</th>
                                  <th style={{ textAlign: "center" }}>Status</th>
                                  <th style={{ textAlign: "center" }}>Total Records</th>
                                  <th style={{ textAlign: "center" }}>Completed</th>
                                  <th style={{ textAlign: "center" }}>Remaining</th>
                                </tr>
                              </thead>
                              <tbody>
                                {files.length > 0 &&
                                  files.map((err, index) => <TableRow err={err} key={index} index={index} />)}
                              </tbody>
                            </Table>
                          </div>
                        </div>
                      )}
                    </Card.Body>
                  </Card>
                </Col>
              </Row>
            </div>
          </>
        </Modal.Body>

        <Modal.Footer>
          <Button className="modalButtons headerButton btn-fill" variant="primary" onClick={() => handleClose()}>
            Close
          </Button>
          <Button
            className="modalButtons headerButton btn-fill"
            variant="secondary"
            // onClick={() => handleCancelAll(data.id)}
            onClick={() => handleCancelRequests(data)}
          >
            Cancel
          </Button>
        </Modal.Footer>
        {/* {loading && <Loader />} */}
      </Modal>
    </>
  );
};

export default ImportFileStatusModal;
