import MainTable from "components/Table/MainTable";
import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { ASSOCIATED_PANEL_PERSONALIZE } from "constants/personalization";
import { renderSourcesCell } from "utils";
import { customUniqBy } from "util/customLodash";
import Status from "components/Status/Status";
import { ORDER_STATUS } from "constant";

const TestAssociatedViewModal = ({ handleClose, associatedPanels, title }) => {
  const [uniqueSources, setUniqueSources] = useState([]);

  const [selectedSource, setSelectedSource] = useState("");

  const handelSourceSelect = (e) => {
    const targetValue = e.target.value;
    setSelectedSource(targetValue);
  };

  const customRenderTD = (item, row, index) => {
    if (item.itemKey === "sources") {
      return renderSourcesCell(item, row);
    }
    if (item.itemKey === "unit") {
      return <td key={`unit_${item.id}`}>{row?.units ? row?.units?.label : ""}</td>;
    }
    if (item.itemKey === "status") {
      const testStatus = row[item.itemKey];
      return (
        <div className="star-icon-wrapper cursor-pointer text-center">
          <Status type="circle" size="md" title={testStatus} color={ORDER_STATUS[testStatus]} crossIcon={testStatus} />
        </div>
      );
    }
  };

  const getUniqueSources = (data) => {
    let allSources = data.flatMap((obj) => obj.sources);
    return customUniqBy(allSources, "label");
  };

  useEffect(() => {
    const uniqueData = getUniqueSources(associatedPanels);
    setUniqueSources(uniqueData);
  }, [associatedPanels]);

  return (
    <Modal
      show
      animation={true}
      size="xl"
      onHide={() => {
        handleClose();
      }}
      style={{ padding: "0px" }}
      backdrop="static"
      centered
      scrollable
      className="second-modal"
    >
      <Modal.Header closeButton style={{ marginBottom: "0px" }}>
        <Modal.Title className="my-0" id="contained-modal-title-vcenter">
          {title}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body style={{ background: "#F2F2F2" }}>
        <div>
          {/* <RadioInput
            options={uniqueSources}
            handleRadioButtonChange={(e) => handelSourceSelect()}
            input={selectedSource}
          /> */}

          {/* <p className="modalLineHeaders">Sources</p>
          <Select
            className="w-100 siteSelector"
            options={uniqueSources}
            blurInputOnSelect={true}
            defaultValue={null}
            menuPlacement="auto"
            placeholder="Select Source"
            onChange={handelSourceSelect}
          /> */}
        </div>

        <p className="font-bold" style={{ fontSize: "18px", marginBottom: "5px" }}>
          Associated Panels
        </p>

        <MainTable
          columns={ASSOCIATED_PANEL_PERSONALIZE}
          rows={associatedPanels}
          customColumnCellRenderer={customRenderTD}
          trDataWrapperHeader={"trDataWrapperHeader"}
          // widthToSkip={155}
        />
      </Modal.Body>
    </Modal>
  );
};

export default TestAssociatedViewModal;
