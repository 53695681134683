import { createUserAsync, updateUserAsync, deleteUserAsync, fetchAllUsers } from "./userSlice";

export const createUser = (user) => (dispatch) => {
  dispatch(createUserAsync(user));
};

export const updateUser = (userId, updatedUser) => (dispatch) => {
  dispatch(updateUserAsync({ userId, updatedUser }));
};
export const fetchUsers = (id, user) => (dispatch) => {
  dispatch(fetchAllUsers({ id, user }));
};

export const deleteUser = (userId) => (dispatch) => {
  dispatch(deleteUserAsync(userId));
};
