import React, { useState, useContext, useMemo, useRef, useCallback } from "react";

// react-bootstrap components
import {
  Badge,
  Button,
  Card,
  Navbar,
  Nav,
  Table,
  Container,
  Row,
  Col,
  DropdownButton,
  Dropdown,
  Modal,
  Form,
} from "react-bootstrap";
import { useEffect } from "react";
import API from "api";
import GQAPI from "views/GraphQL/gqApi";
import { CSVLink } from "react-csv";
import { Importer, ImporterField } from "react-csv-importer";
import { Link } from "react-router-dom";
import Filter from "components/Filter";
import TimeFilter from "components/TimeFilter";
import { useLocation, useHistory } from "react-router-dom";
import { SortDirection, Storage } from "aws-amplify";
import "react-activity/dist/Bounce.css";
import {
  changeSort,
  getCrewsName,
  formatTest,
  formatPhoneNumber,
  sortTestList,
  sortingFilterInLC,
  formatUTCDate,
  isAllowToArchive,
} from "utils";
import FilterSelect from "components/FilterSelect";
import ContactInfo from "components/Modal/ContactInfo";
import Header from "components/Table/Header";
import PGPagination from "components/Pagination/PGPagination";
import { AppContext } from "context/app-context";
import { PAGE_LIMIT, TEST_HEADER_TYPE, RESULT_COLOR, SUPER_ADMIN_ID } from "constant";
import PDFLayout from "components/PDF/PDFLayout";
import ReactPDF from "@react-pdf/renderer";
import PDF from "components/PDF";
import ConfirmationModal from "components/Modal/ConfirmationModal";
import ExportToExcelData from "components/ExportToExcelData";
import TestPDFLayout from "components/PDF/TestPDFLayout";
import ViewOnlyCheckBox from "components/CheckBox/ViewOnlyCheckBox";
import TestLogsModal from "components/Modal/TestLogsModal";
import moment from "moment";
import { debounce } from "debounce";
import { useInitialRender } from "components/useInitialRender";
import Loader from "components/Loader/Loader";
import Icon from "components/Icon";
import { stringConstant, CONFIG, TABLE_QUICK_TOOLS } from "constant";
import ChangeResultModal from "components/Modal/ChangeResultModal";
import SequenceNo from "components/SequenceNo";
import Status from "components/Status/Status";
import { UN_RESULT } from "constant";
import { exportToCalRedie } from "utils";
import { downloadDataAsCSV } from "utils";
import CalRedieLogsModal from "components/Modal/CalredieLogsModal";
import { getValidName } from "utils";
import { COMPLETED_TEST_LOCATION_COLUMN_DETAILS } from "views/Test/Columns";
import MainTable from "components/Table/MainTable";

const TestListAdmin = () => {
  const importResult = [];
  const [totalRecord, setTotalRecord] = useState(0);
  const [tests, setTests] = useState([]);
  const [filteredTests, setFilteredTests] = useState([]);
  const [labs, setLabs] = useState([]);
  const [moreInfo, setMoreInfo] = useState({});
  const [modalShow, setModalShow] = useState(false);
  const [sortBy, setSortBy] = useState("");
  const [sortDescending, setSortDescending] = useState(true);
  const [testsLab, setTestsLab] = useState();
  const [testPdf, setTestPdf] = useState();
  const [showPdf, setShowPdf] = useState(false);
  const [timeFilter, setTimeFilter] = useState({});
  const [showFilter, setShowFilter] = useState(false);
  const [filter, setFilter] = useState({});
  const [triggerFilter, setTriggerFilter] = useState();
  const [loading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [itemToDelete, setItemToDelete] = useState({});
  const [openDeleteConfirmation, setOpenDeleteConfirmation] = useState(false);
  const [checkboxes, setCheckboxes] = useState([]);
  const [allSelected, setAllSelected] = useState(false);
  const [pageNo, setPageNo] = useState(1);
  const [openConfirmation, setOpenConfirmation] = useState(false);
  const [showResultModal, setShowResultModal] = useState(false);
  const [openUploadLogsModal, setOpenUploadLogsModal] = useState(false);

  const { filterDates } = TimeFilter;
  const quickTools = [TABLE_QUICK_TOOLS.checkbox, TABLE_QUICK_TOOLS.edit, TABLE_QUICK_TOOLS.notes];

  const location = useLocation();
  const history = useHistory();
  const initialRender = useInitialRender();
  const appContext = useContext(AppContext);

  const searchTerms = CONFIG.isWhiteLabel
    ? [
        "First Name",
        "Last Name",
        "Date of Birth",
        "Email",
        "Phone",
        `${stringConstant.show}`,
        "Status",
        "Result",
        "Test Type",
        `${stringConstant.sequenceNo}`,
        "Tester",
        "Result Sent",
        "Submitted to H&S",
      ]
    : [
        "First Name",
        "Last Name",
        "Date of Birth",
        "Email",
        "Phone",
        "Client",
        `site_name`,
        "Lab",
        "Status",
        "Result",
        "Test Type",
        `${stringConstant.sequenceNo}`,
        "Tester",
        "Result Sent",
        "isVaccinated",
        "White Glove",
        "Pattern",
        "Capacity",
      ];

  const selectedItem = CONFIG.isWhiteLabel
    ? [
        "createdAt",
        "firstName",
        "lastName",
        "dob",
        "email",
        "phoneNumber",
        "siteID",
        "result",
        "test_type",
        `${stringConstant.accession_no}`,
        "emailSend",
      ]
    : [
        "createdAt",
        "firstName",
        "lastName",
        "dob",
        "email",
        "phoneNumber",
        "clientID",
        "siteID",
        "labID",
        "result",
        "test_type",
        `${stringConstant.accession_no}`,
        "tester_name",
        "emailSend",
        "barcode",
        "isVaccinated",
        "employeeType",
        "whiteGlove",
        "patternTestAnswer",
      ];

  const displayOwner = (test) => {
    let displayedId = test.owner.substr(-12);
    return displayedId;
  };

  const createMoreInfo = (type, id) => {
    let infoObj = null;
    if (type === "site") infoObj = appContext.sites.find((site) => site.id === id);
    else if (type === "client") infoObj = appContext.clients.find((client) => client.id === id);
    else infoObj = labs.find((lab) => lab.id === id);
    if (!infoObj) return;
    setMoreInfo({
      type,
      name: infoObj.name,
      contact: infoObj.contact_name,
      phone: infoObj.contact_phone || infoObj.phone_number,
      email: infoObj.contact_email,
    });
    setModalShow(true);
  };

  const fetchFromApi = useCallback(
    debounce((filter, timeFilter, page, sortBy, sortDescending) => {
      loadTestFromPG(filter, timeFilter, page, sortBy, sortDescending);
    }, 800),
    []
  );

  const testsToMap = useMemo(() => {
    const list = filteredTests ? [...filteredTests] : [];
    const ttlPages = list.length > PAGE_LIMIT ? Math.ceil(list.length / PAGE_LIMIT) : 1;
    setTotalPages(ttlPages);
    if (list.length < PAGE_LIMIT || currentPage > ttlPages) {
      setCurrentPage(1);
    }
    if (ttlPages > 1) {
      const indexOfLastUser = currentPage * PAGE_LIMIT;
      const indexOfFirstUser = indexOfLastUser - PAGE_LIMIT;
      return list.slice(indexOfFirstUser, indexOfLastUser);
    }
    return list;
  }, [filteredTests, currentPage]);

  const displayPdf = async (test) => {
    handleSaveFilter();
    const empID = await appContext.getEmployeeID(test.employeeID);
    const t = test;
    t.empID = empID;
    appContext.getLab(test).then(async (resp) => {
      setTestsLab(resp);
      setShowPdf(true);
    });
    setTestPdf(formatTest(test));
  };

  const flipSort = (by) => {
    setSortDescending(sortBy === by ? !sortDescending : true);
    setSortBy(by);
  };

  useEffect(() => {
    if (pageNo > 1) {
      loadTestFromPG(Object.keys(filter).length === 0 ? { id: 1 } : filter, timeFilter);
    }
  }, [pageNo]);

  useEffect(() => {
    setLabs(appContext.labs);
  }, [appContext.labs]);

  useEffect(() => {
    const savedFilters = appContext.testFilter;
    if (savedFilters && savedFilters.location === "alltests") {
      if (Object.keys(savedFilters.filter).length > 0) {
        setFilter(savedFilters.filter);
        setShowFilter(true);
      }
      if (Object.keys(savedFilters.timeFilter).length > 0) {
        setTimeFilter(savedFilters.timeFilter);
      }
    }
    const sortingFilters = sortingFilterInLC.get();
    if (sortingFilters.alltests.sortBy) {
      setSortDescending(sortingFilters.alltests.sortDescending);
      setSortBy(sortingFilters.alltests.sortBy);
    }
  }, []);

  useEffect(() => {
    const prevSortingState = sortingFilterInLC.get();
    prevSortingState["alltests"] = { filter, timeFilter, sortBy, sortDescending };
    sortingFilterInLC.save(prevSortingState);
    if (filteredTests.length === 0) return;
  }, [sortBy, sortDescending]);

  useEffect(() => {
    if (location.state) {
      if (location.state.term === "Lab") setLabs(location.state.models);

      setShowFilter(true);

      let filterTerm = location.state.term;
      let filterTermName = location.state.name;

      if (filterTerm === "Show") {
        const obj = location.state.models.find((c) => c.name === filterTermName);
        if (obj) {
          filterTerm = "ShowID";
          filterTermName = obj.id;
        }
      }

      if (location.state.positiveTests) {
        let resultValue = "positive";
        if (filterTerm === "Client") {
          const obj = location.state.models.find((c) => c.name === filterTermName);
          if (obj && obj.resultType === "P/F") resultValue = "fail";
        }
        setFilter({
          ...filter,
          [filterTerm]: filterTermName,
          result: "positive",
        });
      } else if (location.state.negativeTests) {
        let resultValue = "negative";
        if (filterTerm === "Client") {
          const obj = location.state.models.find((c) => c.name === filterTermName);
          if (obj && obj.resultType === "P/F") resultValue = "pass";
        }
        setFilter({
          ...filter,
          [filterTerm]: filterTermName,
          result: resultValue,
        });
      } else {
        setFilter({ ...filter, [filterTerm]: filterTermName });
      }
      setTriggerFilter(true);
      setTimeout(() => setShowFilter(false), 1);
    }
  }, [location.state]);

  useEffect(() => {
    if (initialRender) return;

    if (appContext.testObject && appContext.testObject !== 0) {
      const eventType = appContext.testObject.opType;
      const model = formatTest(appContext.testObject.element);
      if (model.status !== "Processed" && model.status !== "Completed") return;
      const newList = [...filteredTests];
      if (eventType === "INSERT") {
        newList.unshift(model);
      } else if (eventType === "UPDATE") {
        const index = newList.findIndex((t) => t.id === model.id);
        if (index !== -1) {
          newList.splice(index, 1, model);
        } else {
          newList.unshift(model);
        }
      } else if (eventType === "DELETE") {
        const index = newList.findIndex((t) => t.id === model.id);
        if (index !== -1) {
          newList.splice(index, 1);
        }
      }

      const testAfterFilter = filterDates(nestedFilter([...newList], filter), timeFilter);
      if (filteredTests.length < testAfterFilter.length) {
        setTotalRecord(totalRecord + 1);
      }
      refreshTestList(testAfterFilter);
    }
  }, [appContext.testObject]);

  useEffect(() => {
    fetchFromApi({ ...filter }, { ...timeFilter }, 1, sortBy, sortDescending);
  }, [filter, triggerFilter, timeFilter, sortBy, sortDescending]);

  const loadTestFromPG = async (params, dateFilter, page, orderBy, orderByDirection) => {
    setLoading(true);
    const filterParam = params;
    Object.assign(filterParam, { page: pageNo, status: "Processed" });

    if (dateFilter) {
      const { start, end } = dateFilter;
      if (start)
        Object.assign(filterParam, { startDate: moment(start.format("YYYY-MM-DD")).startOf("day").toISOString() });
      if (end) Object.assign(filterParam, { endDate: moment(end.format("YYYY-MM-DD")).endOf("day").toISOString() });
    }

    if (orderBy) {
      Object.assign(filterParam, { orderBy, orderByDirection });
    }

    const pgList = await API.getTestListFromPG(filterParam);
    setLoading(false);
    const ttlTests = tests.concat(pgList.rows.map((t) => formatTest(t)));
    if (pageNo === 1) {
      setTotalRecord(pgList.total);
    }
    setTests(ttlTests);
    refreshTestList(ttlTests);
  };

  const handleExportCalrediePatient = async () => {
    try {
      setLoading(true);
      if (checkboxes.length === 0) {
        const resFilePathc = await API.exportToCalRedieData({ date: moment().format("YYMMDD") });

        const resut = await Storage.get(`csvfile/${resFilePathc}`, {
          bucket: "employee-external-tests",
        });
        const response = await fetch(resut);
        const blob = await response.blob();

        if (blob.size === 0) {
          appContext.showErrorMessage("No Pending Data For Submit to Calredie");
          setLoading(false);
          return;
        }
        saveAs(blob, `biomobilelab_${resFilePathc}`);
      } else {
        const tests = filteredTests.filter((f) => f.result && checkboxes.includes(f.id));
        if (tests.length === 0) {
          appContext.showErrorMessage("All tests results Already Submitted!");
          setLoading(false);
          return;
        }
        const labReceord = appContext.labs.find((f) => f.id === CONFIG.whiteLabelID);

        const results = exportToCalRedie(tests, labReceord);
        downloadDataAsCSV(results, `biomobilelab_${moment().toISOString()}`);
      }
      setCheckboxes([]);
      setLoading(false);
    } catch (err) {
      console.log("Error:-", err);
      setLoading(false);
    }
  };

  const handleUploadLogs = () => {
    setOpenUploadLogsModal(true);
  };

  const afterDeletedTest = (testList) => {
    console.log("afterDeletedTest", testList);
    const tmpList = filteredTests.filter((t) => testList.findIndex((f) => f.id === t.id) === -1);
    setFilteredTests(tmpList);
    setTotalRecord(totalRecord - testList.length);
    setCheckboxes([]);
  };

  const nestedFilter = (targetArray, filters) => {
    if (Object.keys(filter).length === 0) return targetArray;
    const filterKeys = Object.keys(filters);
    //filters main array of objects
    const models = targetArray.filter((obj) => {
      //goes through each key being filtered for
      return filterKeys.every((key) => {
        if (!filters[key].length) {
          return true;
        }
        if (key === "phoneNumber") {
          return obj[key] && obj[key].replace(/\D/g, "").includes(filters[key].replace(/\D/g, ""));
        }
        if (key === "Lab") {
          return obj.labID && appContext.getLabName(obj.labID).toLowerCase().includes(filters[key].toLowerCase());
        }
        if (key === "Show") {
          const siteIds = appContext.sites
            .filter((s) => s.name.toLowerCase().includes(filters[key].toLowerCase()))
            .map((s) => s.id);
          return siteIds.includes(obj.siteID);
        }
        if (key === "ShowID") {
          return obj.siteID === filters[key];
        }
        if (key === "Client") {
          return (
            obj.clientID && appContext.getClientName(obj.clientID).toLowerCase().includes(filters[key].toLowerCase())
          );
        }
        if (key === "result") {
          return obj[key] && obj["viewedResult"].toLowerCase().includes(filters[key]);
        }
        if (key === "sequenceNo") {
          return obj[key] && obj["sequenceNo"].toString().includes(filters[key]);
        }
        if (key === "emailSend") {
          return obj.emailSend !== null && obj.emailSend == filters[key];
        }
        if (key === "dob") {
          return obj[key] && obj[key] === filters[key];
        }

        if (key === "test_type" && filters[key] && filters[key].length > 0) {
          return obj[key] && filters[key].indexOf(obj[key]) !== -1;
        }

        return obj[key] && obj[key].toLowerCase().includes(filters[key].toLowerCase());
      });
    });
    return models;
  };

  const refreshTestList = (list) => {
    if (sortBy) {
      setFilteredTests(
        sortTestList(
          sortBy,
          sortDescending,
          list,
          appContext.getSiteName,
          appContext.getLabName,
          appContext.getClientName,
          appContext.parseResult
        )
      );
    } else {
      setFilteredTests(list);
    }
  };

  const handleDeleteConfirm = async (isConfirm) => {
    setOpenDeleteConfirmation(false);
    if (!isConfirm) {
      return;
    }
    if (Object.keys(itemToDelete).length > 0) {
      await API.deleteWrongTest(itemToDelete.id);
      setItemToDelete({});
    }
  };

  const handleSaveFilter = () => {
    appContext.setTestFilter({ location: "alltests", filter, timeFilter, sortBy, sortDescending });
  };

  const handleCheckboxChange = (e, id) => {
    const filteredList = checkboxes.filter((c) => c !== id);
    if (e.target.checked) {
      filteredList.push(id);
    }
    setCheckboxes(filteredList);
  };

  const toggleCheckboxes = (val) => setCheckboxes(val ? filteredTests.map((t) => t.id) : []);

  const handleConfirmation = async () => {
    setOpenConfirmation(false);
    const updateSelectedTestResult = tests.filter((test) => checkboxes.includes(test.id));
    const ttlTest = updateSelectedTestResult.length;
    if (!ttlTest) {
      return;
    }
    setLoading(true);

    try {
      if (!loading) setLoading(true);
      const testList = updateSelectedTestResult.map((t) => {
        return {
          id: t.id,
          sequenceNo: t.sequenceNo,
          StampByName: appContext.user.name,
        };
      });
      await GQAPI.unResultTests(testList);
      setFilteredTests(filteredTests.filter((test) => !checkboxes.includes(test.id)));
      setTotalRecord(totalRecord - ttlTest);
      appContext.showSuccessMessage(`${ttlTest} test${ttlTest === 1 ? "" : "s"} has been ${UN_RESULT} successfully`);
    } catch (err) {
      console.log("Error", err);
      appContext.showErrorMessage(`Fail to ${UN_RESULT} the Test`);
    }
    setLoading(false);
    setCheckboxes([]);
  };

  const clearSelection = () => {
    setAllSelected(false);
    setCheckboxes([]);
    setLoading(false);
  };

  const TableRow = ({ test }) => {
    return (
      <tr className="trDataWrapper">
        <td className="ellipsis" style={{ textAlign: "left" }}>
          {/* <Form.Check>
            <Form.Check.Input
              type="checkbox"
              value={test.id}
              checked={checkboxes.indexOf(test.id) !== -1}
              onChange={(e) => handleCheckboxChange(e, test.id)}
            ></Form.Check.Input>
          </Form.Check> */}
          <ViewOnlyCheckBox
            bgColor="red"
            id={test.id}
            checked={checkboxes.indexOf(test.id) !== -1}
            item={test}
            handleChange={(e) => handleCheckboxChange(e, test.id, test)}
          />
        </td>
        <td className="table-column ellipsis">{formatUTCDate(test.createdAt)}</td>

        <td className="table-column ellipsis" title={getValidName(test.firstName)}>
          {getValidName(test.firstName)}
        </td>
        <td className="table-column ellipsis" title={getValidName(test.lastName)}>
          {getValidName(test.lastName)}
        </td>
        <td className="ellipsis" title={test.dob}>
          {test.dob}
        </td>
        <td
          className="table-column ellipsis text-dark-danger text-center text-dark-danger-hover cursor-pointer text-decoration-none"
          onClick={() => test?.email && window.open(`mailto:${test.email}`)}
          title={test.email}
        >
          {test.email}
        </td>
        <td
          className="table-column ellipsis text-dark-danger text-center text-dark-danger-hover cursor-pointer text-decoration-none"
          onClick={() => {
            test.phoneNumber && window.open(`tel:${test.phoneNumber}`);
          }}
          title={test.phoneNumber && formatPhoneNumber(test.phoneNumber, "", true)}
        >
          {test.phoneNumber && formatPhoneNumber(test.phoneNumber, "", true)}
        </td>
        {!CONFIG.isWhiteLabel && (
          <td className="table-column ellipsis" style={{ textAlign: "center" }}>
            <a
              style={{ color: "#42cef5" }}
              onClick={() => createMoreInfo("client", test.clientID)}
              onMouseOver={(e) => (e.target.style.cursor = "pointer")}
              title={appContext.getClientName(test.clientID)}
            >
              {appContext.getClientName(test.clientID)}
            </a>
          </td>
        )}
        <td className="ellipsis" style={{ textAlign: "center" }} title={appContext.parseResult(test)}>
          <span
            className="text-dark-danger text-dark-danger-hover cursor-pointer text-decoration-none"
            onClick={() => displayPdf(test)}
            style={{
              color: RESULT_COLOR[test.result?.toLowerCase()],
            }}
          >
            {appContext.parseResult(test)}
          </span>
        </td>

        <td className="ellipsis" style={{ textAlign: "center" }} title={test.test_type || "-"}>
          {test.test_type || "-"}
        </td>
        <SequenceNo
          num={CONFIG.isWhiteLabel ? test.barcode : test.sequenceNo}
          test={test}
          showLogs={appContext.showLogs}
        />
        <td className="table-column ellipsis" style={{ textAlign: "center" }}>
          <a
            style={{ color: "#42cef5" }}
            onClick={() => createMoreInfo("site", test.siteID)}
            onMouseOver={(e) => (e.target.style.cursor = "pointer")}
            title={appContext.getSiteName(test.siteID)}
          >
            {appContext.getSiteName(test.siteID)}
          </a>
        </td>
        {!CONFIG.isWhiteLabel && (
          <td className="table-column ellipsis" style={{ textAlign: "center" }}>
            <a
              style={{ color: "#42cef5" }}
              onClick={() => createMoreInfo("lab", test.labID)}
              onMouseOver={(e) => (e.target.style.cursor = "pointer")}
              title={appContext.getLabName(test.labID)}
            >
              {appContext.getLabName(test.labID)}
            </a>
          </td>
        )}

        {!CONFIG.isWhiteLabel && (
          <td className="ellipsis" style={{ textAlign: "center" }} title={test.status}>
            {test.status}
          </td>
        )}

        {
          <td className="ellipsis" style={{ textAlign: "center" }} title={test.tester_name || "NA"}>
            {test.tester_name || "NA"}
          </td>
        }
        {CONFIG.isWhiteLabel && (
          <td className="ellipsis" style={{ textAlign: "center" }}>
            {test.batch ? (
              <div className="star-icon-wrapper">
                <Status type="circle" size="md" color={"green"} tickIcon />
              </div>
            ) : (
              <div className="star-icon-wrapper">
                <Status type="circle" size="md" color={"maroon"} crossIcon />
              </div>
            )}
          </td>
        )}

        <td className="ellipsis" style={{ textAlign: "center" }}>
          {test.emailSend ? (
            <div className="star-icon-wrapper">
              <Status type="circle" size="md" color={"green"} tickIcon />
            </div>
          ) : (
            <div className="star-icon-wrapper">
              <Status type="circle" size="md" color={"maroon"} crossIcon />
            </div>
          )}
        </td>

        <td className="ellipsis" style={{ textAlign: "center" }}>
          <Link
            className="text-dark-danger text-dark-danger-hover cursor-pointer text-decoration-none"
            onClick={handleSaveFilter}
            to={{ pathname: "/admin/test/" + test.id, state: { admin: true, type: "allTest", test: test } }}
          >
            View
          </Link>
        </td>
      </tr>
    );
  };

  const handleChangePage = (page, direction) => {
    setCurrentPage(page);
    const currentRecord = filteredTests.length;
    if (direction === "next" && page === totalPages && totalRecord > 0 && currentRecord < totalRecord) {
      setPageNo(pageNo + 1);
    }
  };

  const handleCloseContactInfo = () => {
    setMoreInfo({});
    setModalShow(false);
  };

  const handleSendEmail = async () => {
    const selectedTest = filteredTests.filter((test) => checkboxes.includes(test.id));
    if (!selectedTest.length) {
      return;
    }
    const params = selectedTest.map((t) => {
      return {
        id: t.id,
        sequenceNo: t.sequenceNo,
      };
    });
    await appContext.sendEmailToCrew(params, "Email");
  };

  const handleUpdateNoteReocrd = (row) => {
    setFilteredTests((prevTests) => {
      const prevIndex = prevTests.findIndex((t) => t.id === row.id);
      if (prevIndex !== -1) {
        prevTests[prevIndex].note = row.note;
      }
      return [...prevTests];
    });
  };

  const handleCellClick = (key, row, event) => {
    switch (key) {
      case "result":
        appContext.showTestResult(row);
        break;
      case TABLE_QUICK_TOOLS.checkbox:
        handleCheckboxChange(event, row.id, row);
        break;
      case TABLE_QUICK_TOOLS.edit:
        history.push("/admin/test/" + row.id, { admin: true, type: "allTest", test: row });
        handleSaveFilter();
        break;
      case TABLE_QUICK_TOOLS.notes:
        appContext.openNoteModal({ ...row, objectType: "test", handleUpdateNoteReocrd, title: row.barcode });
        break;
      default:
        break;
    }
  };

  return showPdf ? (
    <PDFLayout setShowPdf={setShowPdf} testPdf={testPdf} testsLab={testsLab} />
  ) : (
    <>
      <Container fluid>
        <div className="shows-screen-parent">
          <Row className="mx-0">
            <Col md="12">
              <Card className="border-0 mb-1">
                <Card.Body className="p-0">
                  <div className="shows-filter-wrapper">
                    <div className="shows-filter-inner">
                      <Icon
                        handleClick={() => setShowFilter(!showFilter)}
                        title={"Filter"}
                        label={"Filter"}
                        iconType={"filter"}
                      />
                      {/* Date Range */}
                      <FilterSelect setTimeFilter={setTimeFilter} />
                      <Icon
                        handleClick={() => {
                          if (filteredTests && filteredTests.length > 0) {
                            setAllSelected(!allSelected);
                            toggleCheckboxes(!allSelected);
                          }
                        }}
                        title={!allSelected ? "Select All" : "Deselect All"}
                        label={!allSelected ? "Select All" : "Deselect All"}
                        iconType={"selectAllIcon"}
                      />

                      {isAllowToArchive(appContext.user?.phone_number) && (
                        <Icon
                          handleClick={() =>
                            appContext.openDeleteConfirmation(
                              filteredTests.filter((f) => checkboxes.includes(f.id)),
                              afterDeletedTest
                            )
                          }
                          title={"Archive"}
                          label={"Archive"}
                          iconType={"binIcon"}
                          disabled={checkboxes.length === 0}
                        />
                      )}
                      <ExportToExcelData
                        items={filteredTests}
                        selectedItem={selectedItem}
                        appContext={appContext}
                        filter={{ ...filter, status: "Processed" }}
                        timeFilter={timeFilter}
                        title="Completed Tests"
                        sortBy={sortBy}
                        setLoading={setLoading}
                      />
                      {CONFIG.isWhiteLabel && !appContext.user?.isSite() && (
                        <Icon
                          handleClick={() => setShowResultModal(true)}
                          title={"Edit Results"}
                          label={"Edit Results"}
                          iconType={"resultsIcon"}
                          disabled={checkboxes.length === 0}
                        />
                      )}
                      {/* {CONFIG.isWhiteLabel && (
                        <>
                          <Icon
                            handleClick={handleExportCalrediePatient}
                            title={"Export to Calredie"}
                            label="Export to H&S"
                            iconType={"XLSIcon"}
                          />
                          <Icon
                            handleClick={handleUploadLogs}
                            title={"H&S Logs"}
                            label="H&S Logs"
                            iconType={"importIcon"}
                          />
                        </>
                      )} */}
                      {(appContext.user["custom:role"] === "Admins" || CONFIG.isWhiteLabel) && (
                        <Icon
                          handleClick={handleSendEmail}
                          disabled={checkboxes.length ? false : true}
                          title={"Send Results"}
                          label={"Send Results"}
                          iconType={"messageIcon"}
                        />
                      )}

                      {filteredTests.length > 0 && !CONFIG.isWhiteLabel && (
                        <>
                          {Object.keys(filter).length > 0 && (
                            <>
                              <TestPDFLayout
                                tests={filteredTests}
                                selectedItem={selectedItem}
                                filter={filter}
                                timeFilter={timeFilter}
                                title="Completed Tests"
                                appContext={appContext}
                              />
                            </>
                          )}
                        </>
                      )}

                      {appContext.user.phone_number == "+12222222222" && (
                        <Icon
                          handleClick={() => setOpenConfirmation(true)}
                          disabled={checkboxes.length ? false : true}
                          title={`${UN_RESULT} Test`}
                          label={`${UN_RESULT} Test`}
                          iconType={"resultsIcon"}
                        />
                      )}
                    </div>
                  </div>
                  {showFilter && (
                    <Filter
                      filterTerms={searchTerms}
                      setFilter={setFilter}
                      filter={filter}
                      triggerFilter={triggerFilter}
                      setTriggerFilter={setTriggerFilter}
                    />
                  )}
                </Card.Body>
              </Card>
            </Col>
          </Row>
          <Row className="mx-0">
            <Col md="12">
              <Card className="strpied-tabled-with-hover bg-transparent border-0">
                <Card.Header className="shows-screen-wrapper">
                  <Row className="pb-4">
                    <Col md-="6">
                      <Card.Title>
                        <div className="">
                          Completed Tests:
                          <span className="ps-2"> {totalRecord}</span>
                        </div>
                      </Card.Title>
                    </Col>
                    <Col md="6">
                      <PGPagination
                        timeFilter={timeFilter}
                        currentPage={currentPage}
                        handleChangePage={handleChangePage}
                        totalPages={totalPages}
                        totalRecord={totalRecord}
                        currentRecord={filteredTests.length}
                        showSelectedRecord
                        totalSelected={checkboxes.length}
                      />
                    </Col>
                  </Row>
                </Card.Header>

                {/* <Card.Title
                    as="h4"
                    style={{
                      marginBottom: 10,
                      fontWeight: "bold",
                      color: "#565656",
                    }}
                  >
                    Completed Tests ({totalRecord})
                  </Card.Title>
                  <div className="buttonHeader">
                    <Button className="headerButton" onClick={() => setShowFilter(!showFilter)}>
                      Filter
                    </Button>
                    <FilterSelect setTimeFilter={setTimeFilter} />
                    <Button
                      className="headerButton"
                      onClick={() => {
                        if (filteredTests && filteredTests.length > 0) {
                          setAllSelected(!allSelected);
                          toggleCheckboxes(!allSelected);
                        }
                      }}
                    >
                      {!allSelected ? "Select All" : "Deselect All"}
                    </Button>
                    {appContext.user["custom:role"] === "Admins" && (
                      <Button
                        className="headerButton"
                        disabled={checkboxes.length ? false : true}
                        onClick={handleSendEmail}
                      >
                        Send Email
                      </Button>
                    )}

                    {filteredTests.length > 0 && (
                      <>
                        {Object.keys(filter).length > 0 && (
                          <>
                            <ExportToExcelData
                              items={filteredTests}
                              selectedItem={selectedItem}
                              appContext={appContext}
                              filter={{ ...filter, status: "Processed" }}
                              timeFilter={timeFilter}
                              title="Completed Tests"
                              sortBy={sortBy}
                              setLoading={setLoading}
                            />
                            <TestPDFLayout
                              tests={filteredTests}
                              selectedItem={selectedItem}
                              filter={filter}
                              timeFilter={timeFilter}
                              title="Completed Tests"
                              appContext={appContext}
                            />
                          </>
                        )}
                      </>
                    )}

                    {appContext.user.phone_number == "+12222222222" && (
                      <Button
                        className="headerButton"
                        onClick={() => setOpenConfirmation(true)}
                        disabled={checkboxes.length ? false : true}
                      >
                        UnResult Test
                      </Button>
                    )}
                  </div>
                  {showFilter && (
                    <Filter
                      filterTerms={searchTerms}
                      setFilter={setFilter}
                      filter={filter}
                      triggerFilter={triggerFilter}
                      setTriggerFilter={setTriggerFilter}
                    />
                  )}
                  <PGPagination
                    timeFilter={timeFilter}
                    currentPage={currentPage}
                    handleChangePage={handleChangePage}
                    totalPages={totalPages}
                    totalRecord={totalRecord}
                    currentRecord={filteredTests.length}
                    showSelectedRecord
                    totalSelected={checkboxes.length}
                  /> */}

                <Card.Body className="table-full-width px-0 desktop-noScroll mt-0 pt-0">
                  <div className="table-responsive pendingReleaseTable">
                    <MainTable
                      columns={COMPLETED_TEST_LOCATION_COLUMN_DETAILS}
                      rows={testsToMap}
                      flipSort={flipSort}
                      sortBy={sortBy}
                      sortDescending={sortDescending}
                      selectedRows={checkboxes}
                      tools={quickTools}
                      dropDownOptions={[{ value: "Logs", title: "Logs" }]}
                      handleDropDownClick={(type, row) => {
                        if (type === "Logs") {
                          appContext.showLogs(row);
                        }
                      }}
                      handleCellClick={handleCellClick}
                    />
                  </div>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </div>
      </Container>

      <ContactInfo show={modalShow} moreInfo={moreInfo} handleClose={handleCloseContactInfo} />
      {openDeleteConfirmation && (
        <ConfirmationModal
          show={openDeleteConfirmation}
          title="Delete User"
          message={`Are you sure, you want to remove Test of ${itemToDelete.name}?`}
          handleConfirm={handleDeleteConfirm}
        />
      )}
      {showResultModal && (
        <ChangeResultModal
          handleClose={() => setShowResultModal(false)}
          appContext={appContext}
          tests={filteredTests.filter((test) => checkboxes.includes(test.id))}
          callback={clearSelection}
          processed={true}
        />
      )}

      {openUploadLogsModal && (
        <CalRedieLogsModal
          handleClose={() => setOpenUploadLogsModal(false)}
          user={appContext.user}
          appContext={appContext}
        />
      )}
      {openConfirmation && (
        <ConfirmationModal
          show={openConfirmation}
          title={`${UN_RESULT} Tests`}
          message={`Are you sure, you want to ${UN_RESULT} the Tests`}
          handleConfirm={handleConfirmation}
        />
      )}
      {loading && <Loader />}
    </>
  );
};

export default TestListAdmin;
