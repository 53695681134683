import React, { useState } from "react";
import { Button, Modal } from "react-bootstrap";
import Select from "react-select";
import InputField from "components/InputField/InputField";

const SourceSiteModal = ({ handleClose, clients,rowData,  handleAddSourceSite }) => {
  const [inputValue, setInputValue] = useState(rowData || "");
  const [selectedOption, setSelectedOption] = useState(null);

  const handleChangeSourceSite = (option) => {
    setSelectedOption(option);
    setInputValue("");
  };

  const handleInputChange = (newValue, { action }) => {
    if (action === "input-change") {
      setSelectedOption(null);
      setInputValue(newValue);
    }
  };

  const saveSourceSite = () => {
    const value = inputValue?.toUpperCase();
    console.log('Value', value);
    handleAddSourceSite(value);
  };

  return (
    <Modal
      show
      animation={true}
      onHide={() => handleClose()}
      backdrop="static"
      centered
      size={"md"}
      keyboard={true}
      className="second-modal"
    >
      <Modal.Header closeButton>
        <Modal.Title className="my-0" id="contained-modal-title-vcenter">
          Source Site
        </Modal.Title>
      </Modal.Header>
      <Modal.Body style={{ paddingTop: 0 }}>
        
          
          <InputField
            type="text"
            inputStyle="modalInput"
            labelStyle="mt-0 modalLineHeaders text-capitalize"
            label="Source Site"
            value={inputValue}
            index="sourceSite"
            maxLength={100}
            placeholder="Source Site"
            handleChange={(e) => setInputValue(e.target.value)}
          />
          {/* <Select
            className="w-100 siteSelector"
            options={clients}
            blurInputOnSelect={true}
            menuPlacement="auto"
            placeholder="Select Source Site"
            value={selectedOption} // Control the selected option
            inputValue={inputValue} // Control the typed input value
            onChange={handleChangeSourceSite} // Handle option selection
            onInputChange={handleInputChange} // Handle typing input
          /> */}
        
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" className="headerButton" onClick={() => handleClose()}>
          Close
        </Button>
        <Button variant="primary" className="modalButtons" onClick={saveSourceSite}>
          Save
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default SourceSiteModal;
