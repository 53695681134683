import React, { useState, useEffect } from "react";
import Select from "react-select";
import { toTitleCase } from "utils";

const TestTypeSelector = ({
  label = "Select",
  value,
  onChange,
  placeholder = "Select an option...",

  tabIndex = 0,
  className = "",
  required = false,
  testTypeList = [],
  testPanelList = [],
  selectedPanel = null,
}) => {
  const [optionsList, setOptionsList] = useState([]);

  const fetchDetails = async () => {
    const filteredMainTests = testTypeList.map((item) => ({
      type: item?.type || "",
      label: toTitleCase(item.name),
      value: item.id,
      category: item.category,
      clientID: item.clientID,
      code: item.code,
      custom_notes: item.custom_notes,
      typeOfTest: item.typeOfTest,
      _version: item._version,
      name: item.name,
      id: item.id,
      unit: item.units,
      refInterval: item?.refInterval || "",
      targetRanges: item.targetRanges,
      sources: item.sources,
    }));

    const optionsPanelsTest = testPanelList
      .filter((item) => !selectedPanel || item.id !== selectedPanel.id)
      .map((item) => ({
        ...item,
        label: item.name,
        value: item.id,
      }));

    const options = [...optionsPanelsTest, ...filteredMainTests];

    setOptionsList(options);
  };

  useEffect(() => {
    fetchDetails();
  }, []);

  return (
    <div className="row mt-2">
      <div className="col-sm-6">
        <p className="testDetailsSelector">
          {label}
          {required && <span style={{ color: "red" }}>*</span>}
        </p>
        <div className="d-flex align-items-center">
          <Select
            className={`w-100 ${className}`}
            options={optionsList}
            blurInputOnSelect={true}
            menuPlacement={"auto"}
            value={value}
            placeholder={placeholder}
            onChange={onChange}
          />
        </div>
      </div>
    </div>
  );
};

export default TestTypeSelector;
