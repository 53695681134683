import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import api from "../../../api";
import { EMPLOYEE_LOGS, EMPLOYEE_LOGS_FETCH } from "./employeeLogsConstant";

export const fetchApiDataAsync = createAsyncThunk(EMPLOYEE_LOGS_FETCH, async (id) => {
  const response = await api.getEmployeeLogs(id);
  return response;
});

const employeeLogsSlice = createSlice({
  name: EMPLOYEE_LOGS,
  initialState: { data: null },
  extraReducers: (builder) => {
    builder.addCase(fetchApiDataAsync.fulfilled, (state, action) => {
      state.data = action.payload;
    });
  },
});

export default employeeLogsSlice.reducer;
