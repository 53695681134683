import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import api from "../../../api";
import { TEST_DICTIONARY, GENERAL_SETTINGS_FETCH, SHOW_ASSOCIATED_PANEL } from "./testDictionaryConstant";
import { setMessage } from "../general/generalAction";
import { MESSAGE_MODES } from "constant";

// Async thunk to fetch all clients
export const fetchGeneralSettings = createAsyncThunk(GENERAL_SETTINGS_FETCH, async () => {
  const response = await api.getAllGeneralSetting();
  return response;
});

// export const showTestAssociatePanel = createAsyncThunk(SHOW_ASSOCIATED_PANEL, async (id) => {
//   const response = await api.getTestTypesByID(id);
//   return response;
// });

export const showTestAssociatePanel = createAsyncThunk(SHOW_ASSOCIATED_PANEL, async (id, { dispatch }) => {
  const testResult = await api.getTestTypesByID(id);

  if (testResult && testResult.targetRanges && testResult.targetRanges.length > 0) {
    return testResult;
  } else {
    dispatch(setMessage("No associated panel link with this test.", MESSAGE_MODES.error));
  }
});

const generalSettingsSlice = createSlice({
  name: TEST_DICTIONARY,
  initialState: {
    settings: [],
    associatedPanelData: null,
  },
  reducers: {
    emptyAssociatedPanel: (state, action) => {
      state.associatedPanelData = null;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchGeneralSettings.fulfilled, (state, action) => {
      state.settings = action.payload;
    });
    builder.addCase(showTestAssociatePanel.fulfilled, (state, action) => {
      state.associatedPanelData = action.payload;
    });
  },
});
export const { emptyAssociatedPanel } = generalSettingsSlice.actions;

export default generalSettingsSlice.reducer;
