import api from "api";
import React, { useState, useEffect } from "react";

const ResendOTP = ({ setTwoFaError, setLoading }) => {
  const [showResend, setShowResend] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => {
      setShowResend(true);
    }, 2 * 60 * 1000);

    return () => clearTimeout(timer);
  }, [showResend]);

  const handleResendOtp = async () => {
    setShowResend(false);

    try {
      setLoading(true);
      const result = await api.resendOtp();
      if (!result) {
        setTwoFaError("Fail to send the code.");
      }
    } catch (error) {
      console.log("Error", { error });
    } finally {
      setLoading(false);
    }

    setTimeout(() => {
      setShowResend(true);
    }, 2 * 60 * 1000);
  };

  return (
    <>
      {showResend && (
        <p className="mt-2" style={{ cursor: "pointer", fontSize: "15px" }} onClick={handleResendOtp}>
          Resend Code?
        </p>
      )}
    </>
  );
};

export default ResendOTP;
