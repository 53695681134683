import { createSlice } from "@reduxjs/toolkit";
import { BULK_INVOICES } from "./bulkInvoicesConstant";
import {
  addSelectedClaim,
  resetClaimList,
  removeClaimItem,
  updateClaimMessage,
  setErrorForSelectedClaims,
} from "./bulkInvoicesAction";

const bulkInvoicesSlice = createSlice({
  name: BULK_INVOICES,
  initialState: {
    selectedClaims: [],
  },
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(addSelectedClaim.fulfilled, (state, action) => {
      state.selectedClaims = action.payload;
    });
    builder.addCase(resetClaimList.fulfilled, (state) => {
      state.selectedClaims = [];
    });
    builder.addCase(removeClaimItem.fulfilled, (state, action) => {
      state.selectedClaims = state.selectedClaims.filter((item) => item.id !== action.payload);
    });
    builder.addCase(updateClaimMessage.fulfilled, (state, action) => {
      const { id, message, minimumAmount } = action.payload;
      const claimIndex = state.selectedClaims.findIndex((item) => item.claimID === id);
      if (claimIndex !== -1) {
        state.selectedClaims[claimIndex].message = message;
        state.selectedClaims[claimIndex].minAmount = Number(minimumAmount);
      }
    });
    builder.addCase(setErrorForSelectedClaims.fulfilled, (state, action) => {
      const updatedClaims = action.payload;

      // Create a map of updated claims by their local_id
      const updatedClaimsMap = updatedClaims.reduce((map, claim) => {
        map[claim.local_id] = claim;
        return map;
      }, {});

      // Update state.selectedClaims using the map for faster lookup
      state.selectedClaims = state.selectedClaims.map((claim) => {
        const updateObj = updatedClaimsMap[claim.id];
        if (updateObj) {
          return {
            ...claim,
            isError: !updateObj.status,
            errorMessage: updateObj.message,
          };
        }
        return claim;
      });
    });
  },
});

export default bulkInvoicesSlice.reducer;
