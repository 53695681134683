export const PROVIDER = "provider";

export const PROVIDER_FETCH = "provider/fetch";

export const CREATE_PROVIDER = "provider/createProvider";

export const UPDATE_PROVIDER = "provider/updateProvider";

export const DELETE_PROVIDER = "provider/deleteProvider";

export const UPDATE_PROVIDER_CLIENT = "provider/updateProviderClient";

export const CREATE_PORTAL_PROVIDER = "provider/portalProvider";

export const FETCH_RENDERING_PROVIDER = "provider/renderingProvider";