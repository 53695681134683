import React, { useState } from "react";
import { Card, Table, Row, Col, Button, Modal, ModalBody } from "react-bootstrap";
import ViewCheckBox from "./CheckBox/ViewCheckBox";
import { formatDOB, formatPhoneNumber, parseBooleanValue } from "utils";
import ErrorMessage from "./Message/ErrorMessage";

const EmployeeSearchRecord = (props) => {
  const { data, handleSelect, handleClose, setSelectSearchData, selectSearchdata } = props;
  const [error, setError] = useState("");

  const handleSubmit = () => {
    if (!selectSearchdata) {
      setError("Please Select AnyOne");
      return;
    }

    handleSelect(selectSearchdata);
  };
  const TableRow = ({ item, index }) => {
    return (
      <tr className="cursor-pointer active" onClick={() => setSelectSearchData(item)}>
        <td className="popOver-centered">{item.first || item.firstName}</td>
        <td className="popOver-centered">{item.last || item.lastName}</td>
        <td className="popOver-centered">{formatDOB(item.dob)}</td>
        <td className="popOver-centered">{item.email}</td>
        <td className="popOver-centered">{formatPhoneNumber(item.phone_number, item.countryCode)}</td>
        {/* <td className="popOver-centered">{parseBooleanValue(item.isHR)}</td> */}
        <td>{selectSearchdata?.id === item.id && <ViewCheckBox />}</td>
      </tr>
    );
  };

  return (
    <Modal
      className="searchList seperateModal"
      show
      animation={true}
      size="xl"
      onHide={() => {
        handleClose();
      }}
      style={{ paddingLeft: "0" }}
      centered
      scrollable
    >
      <Modal.Header closeButton>
        <Modal.Title className="my-0" id="contained-modal-title-vcenter">
          Search List
        </Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <div style={{ flex: 1 }}>
          <Row>
            <Col md="12">
              <Card className="strpied-tabled-with-hover m-0">
                <Card.Body>
                  <div className="form-group-wrapper m-0 d-block">
                    <div className="table-responsive overFlow-y-hidden">
                      <Table className="table table-hover">
                        <thead>
                          <tr>
                            <th className="popOver-centered border-0">First Name</th>
                            <th className="popOver-centered border-0">Last Name</th>
                            <th className="popOver-centered border-0">Date of Birth</th>
                            <th className="popOver-centered border-0">Email</th>
                            <th className="popOver-centered border-0">Phone Number</th>
                            {/* <th className="popOver-centered border-0">HR</th> */}
                            <th className="checkbox-min-width"></th>
                          </tr>
                        </thead>
                        <tbody>
                          {data.map((item, i) => (
                            <TableRow key={i} index={i} item={item} />
                          ))}
                        </tbody>
                      </Table>
                    </div>
                  </div>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </div>
      </Modal.Body>
      <div className="px-4" style={{ height: "3vh" }}>
        {error && <ErrorMessage error={error} handleChange={() => setError("")} />}
      </div>
      <Modal.Footer>
        <Button onClick={handleClose}>Close</Button>
        <Button onClick={() => handleSubmit()}>Ok</Button>
      </Modal.Footer>
    </Modal>
  );
};

export default EmployeeSearchRecord;
