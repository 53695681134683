import { AWS_API_METHODS, callAmplifyAPI } from "./defaultApi";

const createSalePerson = async (params) => {
  try {
    return await callAmplifyAPI("/saleperson", params);
  } catch (err) {
    console.error("Error creating sale person:", err);
    return null;
  }
};

const updateSalePerson = async (params) => {
  try {
    return await callAmplifyAPI("/saleperson", params, AWS_API_METHODS.put);
  } catch (err) {
    console.error("Error creating sale person:", err);
    return null;
  }
};

const listSalePerson = async (params) => {
  try {
    return await callAmplifyAPI("/saleperson/list", params);
  } catch (err) {
    console.error("Error creating sale person:", err);
    return err;
  }
};

const deleteSalePerson = async (params) => {
  try {
    return await callAmplifyAPI("/saleperson", params, AWS_API_METHODS.delete);
  } catch (err) {
    console.error("Error creating sale person:", err);
    return err;
  }
};

const linkSalesPerson = async (params) => {
  try {
    return await callAmplifyAPI("/saleperson/client", params, AWS_API_METHODS.post);
  } catch (error) {
    console.error("Error linking sale person:", error);
    return null;
  }
};

const unlinkSalesPerson = async (params) => {
  try {
    return await callAmplifyAPI("/saleperson/client", params, AWS_API_METHODS.delete);
  } catch (error) {
    console.error("Error deleting sale person:", error);
    return null;
  }
};

const saleReportFilter = async (params) => {
  try {
    return await callAmplifyAPI("/reports/filter", params, AWS_API_METHODS.post);
  } catch (error) {
    console.error("Error deleting sale person:", error);
    return null;
  }
};

export default {
  createSalePerson,
  updateSalePerson,
  listSalePerson,
  deleteSalePerson,
  linkSalesPerson,
  unlinkSalesPerson,
  saleReportFilter
};
