import React, { useEffect, useState } from "react";
import { Modal, Button, Form, Col, Row } from "react-bootstrap";
import ErrorMessage from "components/Message/ErrorMessage";
import Loader from "components/Loader/Loader";
import api from "api";
import ImageInput from "components/Input/ImageInput";
import moment from "moment";
import { Storage } from "aws-amplify";
import { CSVReader } from "react-papaparse";
import { exportToCalRedie, SYSTEM_TIME_ZONE } from "utils";
import { CONFIG, TEST_TYPES } from "constant";
import InputField from "components/InputField/InputField";
import Select from "react-select";
import { UPLOAD_CSV_FILE } from "constant";
import DateItem from "components/TestSchedule/Items/DateItem";
import SideListBar from "./SideListBar";

const UpComingScheduleModal = (props) => {
  const { handleClose, user, appContext } = props;
  const [scheduleList, setScheduleList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [selectedDate, setSelectedDate] = useState(moment().format("MM-DD-YYYY"));

  useEffect(() => {
    loadScheduleList();
  }, []);

  const loadScheduleList = async () => {
    try {
      const apiRes = await api.upComingSchedule();
      setScheduleList(
        apiRes.map((d) => {
          return { ...d, date: moment(d.date).format("MM-DD-YYYY") };
        })
      );
    } catch (ex) {
      setScheduleList([]);
    }
  };

  return (
    <Modal
      show
      // style={{ transform: "translate(0, -30%)" }}
      animation={true}
      onHide={() => {
        handleClose();
      }}
      centered
      scrollable
      size="lg"
    >
      <Modal.Header closeButton>
        <Modal.Title className="my-0 modal-title h4" id="contained-modal-title-vcenter">
          Upcoming Test Schedule Management
        </Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <Row>
          <Col md="6">
            <div className="calendar-details">
              <div className="p-2 fw-bold">Schedule Dates</div>
              <div className="employee-schedule-item">
                {scheduleList.map((item) => {
                  return (
                    <DateItem
                      key={item.date}
                      date={item.date}
                      count={item.count}
                      handleChangeDate={setSelectedDate}
                      cssClass={`upComingSchedule-item ${item.date == selectedDate ? "selectedScheduled" : ""}`}
                    />
                  );
                })}
              </div>
            </div>
          </Col>
          <Col md="6">
            <SideListBar selectedDate={selectedDate} user={user} updateDateList={setScheduleList} />
          </Col>
        </Row>
      </Modal.Body>

      <Modal.Footer>
        <Button
          className="headerButton btn-fill"
          variant="secondary"
          onClick={() => {
            handleClose();
          }}
        >
          Close
        </Button>
      </Modal.Footer>

      {loading && <Loader />}
    </Modal>
  );
};

export default UpComingScheduleModal;
