import { createSlice } from "@reduxjs/toolkit";
import {
  deleteSalesPerson,
  fetchSalePersons,
  setSelectedClientId,
  setSelectedSalePerson,
  setSalesPersonAssignDetails,
  setShowSalesPersonModal,
  updateSalesPersonInList,
  unLinkSalesPerson,
  fetchSaleFilter,
} from "./salePersonAction";
import { SALES_PERSON } from "./salePersonConstant";

const initialState = {
  selectedSalePerson: null,
  selectedClientId: null,
  showSalesPersonModal: false,
  salesPersonsList: [],
  totalSalesPerson: 0,
  assigningDetails: {
    showAssignModal: false,
    salesPersonListToAssign: [],
    clientId: null,
  },
  saleReportFilter: { insurances: [], salePersons: [] },
};

const salePersonSlice = createSlice({
  name: SALES_PERSON,
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(setSelectedSalePerson.fulfilled, (state, action) => {
      state.selectedSalePerson = action.payload;
    });
    builder.addCase(fetchSaleFilter.fulfilled, (state, action) => {
      state.saleReportFilter = action.payload;
    });
    builder.addCase(setSelectedClientId.fulfilled, (state, action) => {
      const { selectedClientId, salesPersonsList, totalSalesPerson } = action.payload;
      state.selectedClientId = selectedClientId;
      state.salesPersonsList = salesPersonsList;
      state.totalSalesPerson = totalSalesPerson;
    });
    builder.addCase(fetchSalePersons.fulfilled, (state, action) => {
      const { salesPersonsList, totalSalesPerson } = action.payload;
      state.salesPersonsList = salesPersonsList;
      state.totalSalesPerson = totalSalesPerson;
    });
    builder.addCase(setShowSalesPersonModal.fulfilled, (state, action) => {
      state.showSalesPersonModal = !!action.payload;
    });
    builder.addCase(setSalesPersonAssignDetails.fulfilled, (state, action) => {
      state.assigningDetails = { ...action.payload };
    });
    builder.addCase(updateSalesPersonInList.fulfilled, (state, action) => {
      const { isNew, updatedSalesPerson } = action.payload;
      const salesPersonArr = state.salesPersonsList || [];

      if (!!isNew) {
        salesPersonArr.push(updatedSalesPerson);
        state.totalSalesPerson++;
      } else {
        const indexToUpd = salesPersonArr.findIndex((salesPerson) => salesPerson.id === updatedSalesPerson.id);
        salesPersonArr.splice(indexToUpd, 1, updatedSalesPerson);
      }
      state.salesPersonsList = salesPersonArr || [];
    });
    builder.addCase(deleteSalesPerson.fulfilled, (state, action) => {
      const deletedSalesPerson = action.payload;
      const prevList = [...state.salesPersonsList];
      const indexToRemove = prevList.findIndex((salesPerson) => salesPerson.id === deletedSalesPerson.id);
      prevList.splice(indexToRemove, 1);
      state.salesPersonsList = prevList;
      state.totalSalesPerson--;
    });
    builder.addCase(unLinkSalesPerson.fulfilled, (state, action) => {
      const { siteId, salePersonId, confirmed } = action.payload;
      if (confirmed && state.totalSalesPerson > 0 && state.selectedClientId === siteId) {
        const prevList = [...state.salesPersonsList];
        const indexToRemove = prevList.findIndex((salesPerson) => salesPerson.id === salePersonId);
        prevList.splice(indexToRemove, 1);
        state.salesPersonsList = prevList;
        state.totalSalesPerson--;
      }
    });
  },
});

export default salePersonSlice.reducer;
