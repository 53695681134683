import CptList from "components/CPT/CptList";
import React from "react";

const TestIctOrCptSelector = ({ searchType, setItem, item, label,placeholder }) => {

  return (
    <div className="test-component-element">
      <p className="test-type-input-label">{label}</p>
      <CptList
        item={item}
        setItem={setItem}
        searchtype={searchType}
        groupWrapper="mt-1 form-group-wrapper margin-bottom-1"
        showLabelOnTop={true}
        showInput={true}
        showLabel={true}
        showCross={false}
        totalSelected={true}
        placeholder={placeholder}
      />
    </div>
  );
};

export default TestIctOrCptSelector;
