import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  ADD_BULK_INVOICES,
  CLEAR_BULK_INVOICES,
  REMOVE_BULK_INVOICES_CLAIM,
  UPDATE_CLAIM_MESSAGE,
  SET_ERROR_SELECTED_CLAIMS,
} from "./bulkInvoicesConstant";

export const addSelectedClaim = createAsyncThunk(ADD_BULK_INVOICES, async (claims) => {
  return claims;
});
export const removeClaimItem = createAsyncThunk(REMOVE_BULK_INVOICES_CLAIM, async (id) => {
  return id;
});

export const resetClaimList = createAsyncThunk(CLEAR_BULK_INVOICES, async () => {
  return;
});

export const updateClaimMessage = createAsyncThunk(
  UPDATE_CLAIM_MESSAGE,
  async ({ id, message, minimumAmount,faxSubject }, { rejectWithValue }) => {
    try {
      return { id, message, minimumAmount, faxSubject };
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const setErrorForSelectedClaims = createAsyncThunk(SET_ERROR_SELECTED_CLAIMS, async (idsArray) => {
  return idsArray;
});
