import React, { useContext, useEffect, useState } from "react";
import API from "api";

import { SUMMARY_TABLES_KEY_LABELS, GRAPH_COLOR_VALUES } from "constant";
import { parseIntVal, formateDateLL, formateDateDDMMMYYYY } from "utils";
import { SUMMARY_TABLES_STATS_TYPE } from "constant";
import Loader from "components/Loader/Loader";
import { Link } from "react-router-dom"
import { AppContext } from "context/app-context";


const DashboardChartsTableData = (props) => {

    const { statsType, totalCounts, orderedClientNamesArray, orderedLabNamesArray, orderedSiteNamesArray } = props;
    const [sites, setSites] = useState([]);
    const [testsSummaryData, setTestsSummaryData] = useState([]);
    const [loading, setLoading] = useState();
    const [positiveTest, setPositiveTest] = useState([]);
    const [filter, setFilter] = useState('');
    const [sortedSummaryData , setSortedsummaryData] = useState([]);
    const appContext = useContext(AppContext);
    const lastPositiveTest = (id) => {
        let key = SUMMARY_TABLES_KEY_LABELS[statsType];
        let positiveTestsData = positiveTest.find((c) => c[key] === id)
        return positiveTestsData;
    };

    const tableSummaryData = async () => {

        let summaryData = "";
        if (statsType === 'clientstats') {
            summaryData = await API.getSummaryFromPG({ summary: "client" });
            loadTotalTestCounts(summaryData.rows);
        } else if (statsType === 'sitestats') {
            const models = await API.getSites();

              let fiteredshowOnSummaryScreen = models.filter((m) => m.showOnSummaryScreen === false);
            summaryData = await API.getSummaryFromPG({ summary: "site" });
            summaryData.rows = [...(summaryData?.rows || [])].filter((f) => fiteredshowOnSummaryScreen.findIndex((s) => f.id === s.id) !== -1);
          
       
        } else if (statsType === 'labstats') {


            summaryData = await API.getSummaryFromPG({ summary: "lab" });
        }


        if(summaryData.rows){
            setTestsSummaryData(summaryData.rows);
            setFilter(summaryData.rows);
        }
      
    };

    const loadDashboardData = async () => {
        try {
            setLoading(true);
            const ptData = await API.getSummaryFromPG({ summary: "positiveTest" });
            setPositiveTest(ptData.rows);

            let flag = tableSummaryData();

            if (flag) setLoading(false);
        } catch (err) {
            console.log("Error ", err);
            setLoading(false);
        }
    };

    const loadTotalTestCounts = async (data) => {
        let sum = { totalTests: 0, positiveTests: 0, negativeTests: 0  , inconclusiveTests: 0};
        data.reduce((obj, client) => {
            obj["totalTests"] = (obj["totalTests"] || 0) + client.totalTest;
            obj["positiveTests"] = (obj["positiveTests"] || 0) + parseIntVal(client.positiveCount);
            obj["negativeTests"] = (obj["negativeTests"] || 0) + parseIntVal(client.negativeCount);
            obj["inconclusiveTests"] = (obj["inconclusiveTests"] || 0) + parseIntVal(client.inconclusiveTests);
            return obj;
        }, sum)

        totalCounts(sum);
    };

    const sortByOtherArray = async (arr, orderArr) => {
        return arr.sort((a, b) => {
            var aIndex = orderArr?.indexOf(a.name);
            var bIndex = orderArr?.indexOf(b.name);
            if (aIndex === -1 && bIndex === -1) {
                return 0;
            }
            if (aIndex === -1) {
                return 1;
            }
            if (bIndex === -1) {
                return -1;
            }
            return aIndex - bIndex;
        });
    };

    useEffect(async() => {
        if (testsSummaryData && orderedClientNamesArray) {
            await loadColorValues(orderedClientNamesArray);
        } else if (testsSummaryData && orderedLabNamesArray) {
            await loadColorValues(orderedLabNamesArray);
        } else if (testsSummaryData && orderedSiteNamesArray) {
            await loadColorValues(orderedSiteNamesArray);
        }
    }, [testsSummaryData]);

    useEffect(async () => {
       await loadDashboardData();
    }, []);

    const loadColorValues = async (orderedNamesArr) => {
        let sortedsummary = [];
        sortedsummary = await sortByOtherArray(testsSummaryData, orderedNamesArr);
        if (sortedsummary) {
            orderedNamesArr && orderedNamesArr?.forEach((element, index) => {
                if (element && sortedsummary[index]?.colorValue) {
                    sortedsummary[index].colorValue = GRAPH_COLOR_VALUES[index];
                }
            });
        }

        setSortedsummaryData(sortedsummary);
    };

    const handleChange = (e) => {
        if (e.target.value === "" ) {
            setFilter(testsSummaryData);
            return;
        }

        const results = testsSummaryData?.filter(test => {
            return test?.name.toLowerCase().includes(e.target.value.toLowerCase());
        })
        setFilter(results);

    }

    return (
        <>
            <div className="search-card">
                <div
                    className="card-body d-flex align-items-center justify-content-between">
                    <p className="card-title custom-title">{SUMMARY_TABLES_STATS_TYPE[statsType]}s</p>
                    <div>
                        <div className="form position-relative">
                            <input type="search" id="filterSearch" name="filterSearch" onChange={handleChange}  placeholder="Search..." className="search-field" />
                            <div><i className="fa fa-search search-icon"></i></div>
                        </div>
                    </div>
                </div>
            </div>
            <table className="dashboard-tables-data">
                <tbody>
                    <tr>
                        <td>
                            {filter && sortedSummaryData && (
                                filter?.map((test, index) => {
                                    const positiveData = lastPositiveTest(test?.id);
                                    return (
                                        <table key={index} className="table">
                                            <tbody className="position-relative">
                                                <tr className="clientColor">
                                                    <svg width="35px" height="34px" viewBox="0 0 35 34" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
                                                        <polygon id="triangularLabel" points="0 0 35 0 0 34" fill={test?.colorValue} />
                                                    </svg>
                                                </tr>
                                                <tr className="border-bottom-transparent">
                                                    <td style={{ width: '50%' }}>
                                                        <p className="custom-title">Name:</p>
                                                        <span className="text-black">{test.name}</span>
                                                    </td>
                                                    <td>
                                                        <p className="custom-title">Total Tests</p>
                                                        <span className="fw-bold">
                                                            <p>
                                                                {test.totalTest !== 0 ? (
                                                                    <Link
                                                                        className="text-black text-decoration-none"
                                                                        to={{
                                                                            pathname: "/admin/totaltests",
                                                                            state: { name: test.name, term: SUMMARY_TABLES_STATS_TYPE[statsType], models: filter },
                                                                        }}
                                                                    >
                                                                        {test.totalTest}
                                                                    </Link>
                                                                ) : (
                                                                    0
                                                                )}
                                                            </p>
                                                        </span>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        <p className="positive-number">Positive</p>
                                                        <span className="fw-bold">
                                                            <p>
                                                                {test.positiveCount !== 0 ? (
                                                                    <Link
                                                                        className="text-black text-decoration-none"
                                                                        to={{
                                                                            pathname: "/admin/alltests",
                                                                            state: { name: test.name, term: SUMMARY_TABLES_STATS_TYPE[statsType], models: filter, positiveTests: true },
                                                                        }}
                                                                    >
                                                                        {test.positiveCount}
                                                                    </Link>
                                                                ) : (
                                                                    0
                                                                )}
                                                            </p>
                                                        </span>
                                                    </td>
                                                    <td>
                                                        <p className="negative-number">Negative</p>
                                                        <span className="fw-bold">
                                                            <p>
                                                                {test.negativeCount !== 0 ? (
                                                                    <Link
                                                                        className="text-black text-decoration-none"
                                                                        to={{
                                                                            pathname: "/admin/alltests",
                                                                            state: { name: test.name, term: SUMMARY_TABLES_STATS_TYPE[statsType], models: filter, negativeTests: true },
                                                                        }}

                                                                    >
                                                                        {test.negativeCount}
                                                                    </Link>
                                                                ) : (
                                                                    0
                                                                )}
                                                            </p>
                                                        </span>
                                                    </td>
                                                    <td>
                                                        <p className="total-number">Last Positive</p>
                                                        <span className="fw-bold">
                                                            <p>
                                                                {!positiveData ? (
                                                                    "No Positive Tests"
                                                                ) : (
                                                                    <Link
                                                                        className="text-black text-decoration-none"
                                                                        to={{
                                                                            pathname: "/admin/alltests",
                                                                            state: { name: positiveData?.barcode || 1, term: "barcode" },
                                                                        }}

                                                                    >
                                                                        {formateDateDDMMMYYYY(positiveData.resultDate)}
                                                                    </Link>
                                                                )}
                                                            </p>
                                                        </span>

                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    );
                                })
                            )
                            }

                            {loading && <Loader />}
                        </td>
                    </tr>
                </tbody>
            </table>

        </>
    );
}

export default DashboardChartsTableData;
