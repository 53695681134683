import React, { useEffect, useState } from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  BarElement,
  ArcElement,
  Title,
  Tooltip,
  Legend,

} from "chart.js";
import { Bar , Line , Pie , Doughnut} from "react-chartjs-2";
import { GRAPH_COLOR_VALUES } from "constant";


ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  BarElement,
  ArcElement,
  Title,
  Tooltip,
  Legend
);

const DashboardPieChart = (props) => {

  const [colors, setColors] = useState(GRAPH_COLOR_VALUES);
  const [legendcolor, setLegend] = useState([]);
  const [chartDataSet , setChartDataSet] = useState([]);
  const [chartDataLabels , setChartDatalabel] = useState([]);
  const {chartData} = props;

    const loadChartData = () => {
      let dataArray = [];
      let labelsArray = [];
      const asArray = Object.entries(chartData);
      const filteredData = asArray.filter(([key, value]) => typeof value === 'number' && key !== 'totalTests' && key != 'showRunningDays');
      filteredData.map((data , index) => {
      let formattedLabel = formatTestLabels(data[0]);
      labelsArray.push(formattedLabel);
      dataArray.push(data[1]);
  
      })

      setChartDataSet(dataArray);
      setChartDatalabel(labelsArray);
    };


    const formatTestLabels = (label) => {
      if(label === 'positiveTests') return 'Positive';
      if(label === 'pendingTests') return 'Pending';
      if(label === 'negativeTests') return 'Negative';
      if(label === 'inconclusiveTests') return 'Inconclusive';
    };

    useEffect(()=>{
      loadChartData();
    } , [chartData]);


  const options = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      title: {
        display: true,
        text: "Tests Stats",
      },
    },
  };

  const labels = chartDataLabels;

  const data = {
    labels,
    datasets: [
        {
          label: 'Tests Count:',
          data: chartDataSet,
          backgroundColor: [
            'rgba(255, 99, 132, 0.2)',
            'rgba(75, 192, 192, 0.2)',
            'rgba(255, 206, 86, 0.2)',
            'rgba(54, 162, 235, 0.2)',
            'rgba(153, 102, 255, 0.2)',
            'rgba(255, 159, 64, 0.2)',
          ],
          borderColor: [
            'rgba(255, 99, 132, 1)',
            'rgba(75, 192, 192, 1)',
            'rgba(255, 206, 86, 1)',
            'rgba(54, 162, 235, 1)',
            'rgba(153, 102, 255, 1)',
            'rgba(255, 159, 64, 1)',
          ],
          borderWidth: 1,
       
        },
        
      ],
      
        options: {
          legend: {
            display: false,
            position: 'bottom',
            labels: {
              fontColor: "#000080",
            }
          }
        }
      
      

   
  };

  return (
    <>
      <Pie options={options} data={data} />
    </>
  );
};



export default DashboardPieChart;
