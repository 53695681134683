import api from "api";
import Filter from "components/Filter";
import Icon from "components/Icon";
import Loader from "components/Loader/Loader";
import PersonalizationModal from "components/Modal/personalizationModal";
import MFPagination from "components/Pagination/MFPagination";
import MainTable from "components/Table/MainTable";
import { PAGE_LIMIT, TABLE_QUICK_TOOLS } from "constant";
import { MESSAGE_TEMPLATE_PERSONALIZE } from "constants/personalization";
import { AppContext } from "context/app-context";
import { useContext, useEffect, useState } from "react";
import { Card, Col, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { showConfirmationModal } from "store/features/general/generalAction";
import { deleteMessageTemplate, fetchMessageTemplateLists } from "store/features/messageTemplate/messageTemplateAction";
import { messageTemplateListSelector } from "store/features/messageTemplate/messageTemplateSelectors";
import {
  setSelectedMessageTemplate,
  setShowMessageTemplateModal,
} from "store/features/messageTemplate/messageTemplateSlice";
import { t } from "stringConstants";

const MessageTemplateView = (props) => {
  const [messageTemplates, setMessageTemplates] = useState([]);
  const [filteredMessagesList, setFilteredMessagesList] = useState([]);
  const [sortBy, setSortBy] = useState("");
  const [sortDescending, setSortDescending] = useState(true);
  const [loading, setLoading] = useState(false);
  const [showFilter, setShowFilter] = useState(false);
  const [filter, setFilter] = useState({});
  const appContext = useContext(AppContext);
  const [allSelected, setAllSelected] = useState(false);
  const [checkboxes, setCheckboxes] = useState([]);
  const messageTemplatesList = useSelector(messageTemplateListSelector);

  const dispatch = useDispatch();

  const quickTools = [TABLE_QUICK_TOOLS.checkbox, TABLE_QUICK_TOOLS.edit, TABLE_QUICK_TOOLS.delete];

  const searchTerms = ["Name", "Category"];

  const [currentPage, setCurrentPage] = useState(1);
  const [usersPerPage, setUsersPerPage] = useState(PAGE_LIMIT);

  const sortUsers = (users, sortParam) => {
    if (users?.length > 0) {
      if (sortDescending) {
        return [...users].sort((a, b) => (a[sortParam] < b[sortParam] ? 1 : b[sortParam] < a[sortParam] ? -1 : 0));
      } else {
        return [...users].sort((a, b) => (b[sortParam] < a[sortParam] ? 1 : a[sortParam] < b[sortParam] ? -1 : 0));
      }
    }
    return [];
  };

  const handleCheckboxChange = (e, user) => {
    const filteredList = checkboxes.filter((c) => c !== user.id);
    if (e.target.checked) {
      filteredList.push(user.id);
    }
    setCheckboxes(filteredList);
  };

  const nestedFilter = (targetArray, filters) => {
    if (Object.keys(filter).length === 0) return targetArray;

    const filterKeys = Object.keys(filters);
    //filters main array of objects
    const models = targetArray.filter((obj) => {
      //goes through each key being filtered for
      return filterKeys.every((key) => {
        if (filters[key] == "" && !Object.keys(filters[key]).length) {
          return true;
        }
        return obj[key] && obj[key].toLowerCase().includes(filters[key].toLowerCase());
      });
    });
    return models;
  };

  const handleChangePage = (number) => {
    setCurrentPage(number);
  };

  const currentUsers = (users) => {
    const indexOfLastUser = currentPage * usersPerPage;
    const indexOfFirstUser = indexOfLastUser - usersPerPage;
    return users.slice(indexOfFirstUser, indexOfLastUser);
  };

  let salePersonsToMap = currentUsers(filteredMessagesList);

  const getPageNumbers = (users) => {
    const pageNumbers = users.length > PAGE_LIMIT ? Math.ceil(users.length / PAGE_LIMIT) : 1;
    return pageNumbers;
  };

  let pageNumbers = getPageNumbers(messageTemplates);

  useEffect(() => getMessageTemplateList(), []);
  useEffect(() => formatSalesPersonList(), [messageTemplatesList]);

  const getMessageTemplateList = () => {
    dispatch(fetchMessageTemplateLists());
  };

  const formatSalesPersonList = () => {
    setMessageTemplates(messageTemplatesList);
    const filteredClaims = sortUsers(nestedFilter(messageTemplatesList, filter), sortBy);
    setFilteredMessagesList(filteredClaims);
  };

  useEffect(() => {
    if (filteredMessagesList.length > 0)
      setFilteredMessagesList(sortUsers(nestedFilter(filteredMessagesList, filter), sortBy));
  }, [sortBy, sortDescending]);

  useEffect(() => {
    if (messageTemplates.length > 0) {
      setFilteredMessagesList(sortUsers(nestedFilter(messageTemplates, filter), sortBy));
      setCurrentPage(1);
    }
  }, [filter]);

  if (filteredMessagesList.length > 0) {
    pageNumbers = getPageNumbers(filteredMessagesList);
  }

  const flipSort = (by) => {
    setSortDescending(sortBy === by ? !sortDescending : true);
    setSortBy(by);
    handleChangePage(1);
  };

  const toggleCheckboxes = (val) => {
    setCheckboxes(val ? filteredMessagesList.map((t) => t.id) : []);
  };

  const handleCellClick = (key, row, event) => {
    switch (key) {
      case TABLE_QUICK_TOOLS.checkbox:
        handleCheckboxChange(event, row);
        break;

      case TABLE_QUICK_TOOLS.edit:
        dispatch(setSelectedMessageTemplate({ ...row, isNew: false }));
        dispatch(setShowMessageTemplateModal(true));
        break;
      case TABLE_QUICK_TOOLS.delete:
        const confirmationModalData = {
          showModal: true,
          onConfirm: () => dispatch(deleteMessageTemplate(row)),
          title: "Delete Message Template",
          message: "Are you sure, you want to delete that message Template?",
        };
        dispatch(showConfirmationModal(confirmationModalData));
        break;

      default:
        break;
    }
  };

  return (
    <div style={{ flex: 1 }}>
      {!loading ? (
        <Row>
          <Col>
            <Card className="strpied-tabled-with-hover">
              <Card.Header>
                <Card.Title
                  as="h4"
                  style={{
                    marginBottom: 10,
                    fontWeight: "bold",
                  }}
                >
                  {t("messageTemplate")}
                </Card.Title>
                <div className="buttonHeader">
                  <Icon
                    handleClick={() => setShowFilter(!showFilter)}
                    title={"Filter"}
                    label={"Filter"}
                    iconType={"filter"}
                  />

                  <Icon
                    handleClick={async () => {
                      if ((filteredMessagesList || []).length > 0) {
                        setAllSelected(!allSelected);
                        toggleCheckboxes(!allSelected);
                      }
                    }}
                    label={!allSelected ? "Select All" : "Deselect All"}
                    title={!allSelected ? "Select All" : "Deselect All"}
                    iconType={"selectAllIcon"}
                  />

                  <Icon
                    title={t("createMessageTemplate")}
                    label={t("createMessageTemplate")}
                    iconType={"acknowledgedPositiveIcon"}
                    handleClick={() => {
                      dispatch(setSelectedMessageTemplate({ isNew: true }));
                      dispatch(setShowMessageTemplateModal(true));
                    }}
                  />
                </div>

                {showFilter && (
                  <Filter
                    filterTerms={searchTerms}
                    setFilter={setFilter}
                    filter={filter}
                    isUsersTable={true}
                    employeeClaim={true}
                    personalisationData={MESSAGE_TEMPLATE_PERSONALIZE}
                  />
                )}
                {/* <div className="mb-4">
                  <MFPagination
                    currentPage={currentPage}
                    handleChangePage={handleChangePage}
                    totalPages={pageNumbers}
                    totalSelected={checkboxes.length}
                  />
                </div> */}
              </Card.Header>
              <Card.Body className="table-full-width desktop-noScroll">
                <MainTable
                  columns={MESSAGE_TEMPLATE_PERSONALIZE}
                  rows={salePersonsToMap}
                  flipSort={flipSort}
                  sortBy={sortBy}
                  sortDescending={sortDescending}
                  widthToSkip={155}
                  tools={quickTools}
                  selectedRows={checkboxes}
                  handleCellClick={handleCellClick}
                />
              </Card.Body>
            </Card>
          </Col>
        </Row>
      ) : (
        <Loader />
      )}
    </div>
  );
};

export default MessageTemplateView;
