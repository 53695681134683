import { createSlice } from "@reduxjs/toolkit";

const genericModalSlice = createSlice({
  name: "GenericModalView",
  initialState: {
    showGenericModal: false,
    genericData: [],
    genericPathogenData: [],
    showGenericPathogenModal: false,
  },
  reducers: {
    setGenericModalData: (state, action) => {
      state.showGenericModal = action.payload.showGenericModal;
      state.genericData = action.payload.genericData;
    },
    setGenericPathogenModalData: (state, action) => {
      state.showGenericPathogenModal = action.payload.showGenericPathogenModal;
      state.genericPathogenData = action.payload.genericPathogenData;
    },
  },
});

export const { setGenericModalData, setGenericPathogenModalData } = genericModalSlice.actions;

export default genericModalSlice.reducer;
