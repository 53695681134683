// @ts-check
import { initSchema } from '@aws-amplify/datastore';
import { schema } from './schema';



const { Lab, Site, Client, Employee, Test, LencoBarcodes, TestConsent, TestTypes, EmployeeBarCodes, EmployeeTestCounter, ExternalTest, EmployeeLogin, TestSession, Documents, Order, UploadRequestMain, UploadRequest, GeneralSettingType, Users, MessageTemplate } = initSchema(schema);

export {
  Lab,
  Site,
  Client,
  Employee,
  Test,
  LencoBarcodes,
  TestConsent,
  TestTypes,
  EmployeeBarCodes,
  EmployeeTestCounter,
  ExternalTest,
  EmployeeLogin,
  TestSession,
  Documents,
  Order,
  UploadRequestMain,
  UploadRequest,
  GeneralSettingType,
  Users,
  MessageTemplate
};