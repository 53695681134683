import React from "react";
import DateRangePicker from "react-bootstrap-daterangepicker";
import moment from "moment";

const SingleDatePicker = (props) => {
  const { onApply, value, currentDate, startDate } = props;

  return (
    <DateRangePicker
      initialSettings={{
        startDate: startDate ? startDate : moment(),
        singleDatePicker: true,
        showDropdowns: true,
        autoUpdateInput: false,
        autoApply: true,
        maxDate: currentDate ? moment() : false,
      }}
      onApply={onApply}
    >
      <input
        type="text"
        placeholder="MM-DD-YYYY"
        readOnly
        value={value ? moment(value).format("MM-DD-YYYY") : ""}
        className={
          startDate
            ? `modalInput form-control-plaintext w-100`
            : `form-control dobInput edit_style ${value !== null && "selected"}`
        }
        style={{ cursor: "pointer" }}
      />
    </DateRangePicker>
  );
};

export default SingleDatePicker;
