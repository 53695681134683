import React, { useState, useContext, useMemo, useRef, useCallback } from "react";

// react-bootstrap components
import {
  Badge,
  Button,
  Card,
  Navbar,
  Nav,
  Table,
  Container,
  Row,
  Col,
  DropdownButton,
  Dropdown,
  Modal,
  InputGroup,
  FormControl,
} from "react-bootstrap";
import { useEffect } from "react";
import API from "api";
import { CSVLink } from "react-csv";
import { Importer, ImporterField } from "react-csv-importer";
import { Link } from "react-router-dom";
import { PDFViewer } from "@react-pdf/renderer";
import PDF from "components/PDF";
import Filter from "components/Filter";
import TimeFilter from "components/TimeFilter";
import FilterSelect from "components/FilterSelect";
import { useLocation } from "react-router-dom";
import HeaderItem from "components/Table/HeaderItem";
import PGPagination from "components/Pagination/PGPagination";
import { PAGE_LIMIT, RESULT_COLOR, TOTAL_TEST_HEADER } from "constant";
import {
  changeSort,
  getCrewsName,
  formatPhoneNumber,
  formatTest,
  calculateTdWidth,
  sortingFilterInLC,
  sortTestList,
  formatUTCDate,
  isValidName,
  isValidPhoneWithCode,
  isValidDate,
  isValidEmail,
  setPhoneNo,
  setDate,
  connectedIDInLocalStorage,
} from "utils";
import { AppContext } from "context/app-context";
import PDFLayout from "components/PDF/PDFLayout";
import ExportToExcelData from "components/ExportToExcelData";
import TestPDFLayout from "components/PDF/TestPDFLayout";
import Loader from "components/Loader/Loader";
import moment from "moment";
import ImportTestDoneModal from "components/Modal/ImportTestDoneModal";
import ImportErrorModal from "components/Modal/ImportErrorModal";
import { debounce } from "debounce";
import { useInitialRender } from "components/useInitialRender";
import { CostExplorer } from "aws-sdk";
import Icon from "components/Icon";
import { stringConstant } from "constant";
const siteID = window.location?.pathname?.split("/").pop();

const TotalTestsApiNew = (props) => {
  const componentRef = useRef();
  const [width, setWidth] = useState(0);
  const [errorMessage, setErrorMessage] = useState("");
  useEffect(() => {
    setWidth(componentRef.current.offsetWidth);
  }, [componentRef]);

  const handleResize = () => {
    if (componentRef.current) setWidth(componentRef.current.offsetWidth);
  };

  useEffect(() => {
    handleResize();
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  });
  useEffect(() => {
    setTimeout(() => {
      setErrorMessage("");
    }, 4000);
  }, [errorMessage]);

  console.log("TotalTestApiNew:");
  const tdWidth = calculateTdWidth(width, 11);

  const importResult = [];
  const [totalRecord, setTotalRecord] = useState(0);
  const [tests, setTests] = useState([]);
  const [filteredTests, setFilteredTests] = useState([]);
  const [labs, setLabs] = useState([]);
  const [sites, setSites] = useState([]);
  const [moreInfo, setMoreInfo] = useState({});
  const [modalShow, setModalShow] = useState(false);
  const [sortBy, setSortBy] = useState("");
  const [sortDescending, setSortDescending] = useState(true);
  const [testsLab, setTestsLab] = useState();
  const [testPdf, setTestPdf] = useState();
  const [showPdf, setShowPdf] = useState(false);
  const [timeFilter, setTimeFilter] = useState({});
  const [showFilter, setShowFilter] = useState(false);
  const [filter, setFilter] = useState({});
  const [triggerFilter, setTriggerFilter] = useState();
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [showImportModal, setShowImportModal] = useState(false);
  const [showResultModal, setShowResultModal] = useState(false);
  const [openErrModal, setOpenErrModal] = useState(false);
  const [data, setData] = useState({});
  const [pageNo, setPageNo] = useState(1);
  const [checkboxes, setCheckboxes] = useState([]);
  const [loading, setLoading] = useState(false);
  const { filterDates } = TimeFilter;

  const location = useLocation();
  const initialRender = useInitialRender();
  const appContext = useContext(AppContext);

  const searchTerms = [
    "First Name",
    "Last Name",
    "Date of Birth",
    "Phone",
    "Email",
    "Show",
    `${stringConstant.sequenceNo}`,
    "Status",
    "Result",
    "Test Type",
    "isVaccinated",
    "White Glove",
    "Pattern",
    "Capacity",
  ];

  const selectedItem = [
    "createdAt",
    "firstName",
    "lastName",
    "dob",
    "phoneNumber",
    "email",
    "siteID",
    "sequenceNo",
    "status",
    "result",
    "test_type",
    "isVaccinated",
    "employeeType",
    "whiteGlove",
    "patternTestAnswer",
  ];

  const fetchFromApi = useCallback(
    debounce((filter, timeFilter, page, sortBy, sortDescending) => {
      loadTestFromPG(filter, timeFilter, page, sortBy, sortDescending);
    }, 800),
    []
  );

  const displayPdf = async (test) => {
    handleSaveFilter();
    const empID = await appContext.getEmployeeID(test.employeeID);
    const t = test;
    t.empID = empID;
    appContext?.getSite(test)?.then(async (resp) => {
      setTestsLab(resp);
      setShowPdf(true);
    });
    setTestPdf(formatTest(test));
  };

  const flipSort = (by) => {
    setSortBy(by);
    setSortDescending(sortBy === by ? !sortDescending : true);
  };

  const nestedFilter = (targetArray, filters) => {
    if (Object.keys(filters).length === 0) return targetArray;
    const filterKeys = Object.keys(filters);
    //filters main array of objects
    const models = targetArray.filter((obj) => {
      //goes through each key being filtered for
      return filterKeys.every((key) => {
        if (!filters[key].length) {
          return true;
        }
        if (key === "phoneNumber") {
          return obj[key] && obj[key].replace(/\D/g, "").includes(filters[key].replace(/\D/g, ""));
        }
        if (key === "Lab") {
          return obj.labID && appContext.getLabName(obj.labID).toLowerCase().includes(filters[key].toLowerCase());
        }
        if (key === "Show") {
          const siteIds = appContext.sites
            .filter((s) => s.name.toLowerCase().includes(filters[key].toLowerCase()))
            .map((s) => s.id);
          return siteIds.includes(obj.siteID);
        }
        if (key === "ShowID") {
          return obj.siteID === filters[key];
        }
        if (key === "Client") {
          return (
            obj.clientID && appContext.getClientName(obj.clientID).toLowerCase().includes(filters[key].toLowerCase())
          );
        }
        if (key === "result") {
          return obj[key] && obj["viewedResult"].toLowerCase().includes(filters[key]);
        }
        if (key === "sequenceNo") {
          return obj[key] && obj["sequenceNo"].toString().includes(filters[key]);
        }
        if (key === "emailSend") {
          return obj.emailSend !== null && obj.emailSend == filters[key];
        }
        if (key === "dob") {
          return obj[key] && obj[key] === filters[key];
        }

        if (key === "test_type" && filters[key] && filters[key].length > 0) {
          return obj[key] && filters[key].indexOf(obj[key]) !== -1;
        }

        return obj[key] && obj[key].toLowerCase().includes(filters[key].toLowerCase());
      });
    });
    return models;
  };

  const handleImportData = async (testData) => {
    if (testData.length === 0) return;
    const header = TOTAL_TEST_HEADER.every((c) => Object.keys(testData[0].data).includes(c));
    if (!header) {
      setErrorMessage("data not matched please check it column");
      return;
    }
    const arr = [];
    const arrdata = [];
    for (let index = 0; index < testData.length; index++) {
      const { data } = testData[index];

      const test = { ...data };
      if (test.firstName?.length == 0 || test.phoneNumber?.length == 0) continue;
      if (!isValidName(test.firstName) || !isValidName(test.lastName)) {
        arr.push({ message: `First Name or Last Name must be Letters on row ${index + 2}` });
        continue;
      }
      test.phoneNumber = setPhoneNo(test.phoneNumber);
      if (!isValidPhoneWithCode(test.phoneNumber)) {
        arr.push({ message: `phone number is not correct ${test.phoneNumber} on row ${index + 2}` });
        continue;
      }
      if (!isValidDate(test?.createdAt)) {
        arr.push({ message: `Invalid (${test.createdAt}) Date format is (DD/MM/YYYY)  on row ${index + 2}.` });
        continue;
      }
      if (!isValidEmail(test.email)) {
        arr.push({ message: `Invalid email ${test.email} on row ${index + 2}.` });
        continue;
      }
      test.createdAt = setDate(test.createdAt);
      arrdata.push(test);
    }
    console.log(arr, arrdata);
    setData({ errData: arr, successData: arrdata });
    setShowImportModal(false);
    setOpenErrModal(true);
  };

  const testsToMap = useMemo(() => {
    const list = filteredTests ? [...filteredTests] : [];
    const ttlPages = list.length > PAGE_LIMIT ? Math.ceil(list.length / PAGE_LIMIT) : 1;
    setTotalPages(ttlPages);
    if (list.length < PAGE_LIMIT || currentPage > ttlPages) {
      setCurrentPage(1);
    }
    if (ttlPages > 1) {
      const indexOfLastUser = currentPage * PAGE_LIMIT;
      const indexOfFirstUser = indexOfLastUser - PAGE_LIMIT;
      return list.slice(indexOfFirstUser, indexOfLastUser);
    }
    return list;
  }, [filteredTests, currentPage]);

  useEffect(() => {
    if (
      appContext.testFilter &&
      appContext.testFilter.location === "totaltest" &&
      Object.keys(appContext.testFilter.filter).length > 0
    ) {
      setFilter(appContext.testFilter.filter);
      setShowFilter(true);
    }
    const sortingFilters = sortingFilterInLC.get();
    if (sortingFilters.totaltest.sortBy) {
      setSortDescending(sortingFilters.totaltest.sortDescending);
      setSortBy(sortingFilters.totaltest.sortBy);
    }
  }, []);

  useEffect(() => {
    if (initialRender) return;
    if (appContext.eventObject && appContext.eventObject !== 0) {
      const eventType = appContext.eventObject.opType;
      const model = formatTest(appContext.eventObject.element);
      const newList = [...filteredTests];

      if (eventType === "INSERT") {
        newList.unshift(model);
      } else if (eventType === "UPDATE") {
        const index = newList.findIndex((t) => t.id === model.id);
        if (index !== -1) {
          newList.splice(index, 1, model);
        }
      }
      const testAfterFilter = filterDates(nestedFilter([...newList], filter), timeFilter);
      if (filteredTests.length < testAfterFilter.length && eventType === "INSERT") {
        setTotalRecord(totalRecord + 1);
      }
      refreshTestList(testAfterFilter);
    }
  }, [appContext.eventObject]);

  useEffect(() => {
    setSites(appContext.sites);
    setLabs(appContext.labs);
  }, [appContext.sites, appContext.labs]);

  useEffect(() => {
    const prevSortingState = sortingFilterInLC.get();
    prevSortingState["totaltest"] = { filter, timeFilter, sortBy, sortDescending };
    sortingFilterInLC.save(prevSortingState);
  }, [sortBy, sortDescending]);

  useEffect(() => {
    fetchFromApi({ ...filter }, { ...timeFilter }, 1, sortBy, sortDescending);
  }, [filter, timeFilter, sortBy, sortDescending]);

  useEffect(() => {}, [appContext.eventObject]);

  useEffect(() => {
    if (location.state) {
      if (location.state.term === "Lab") setLabs(location.state.models);

      setShowFilter(true);

      let filterTerm = location.state.term;
      let filterTermName = location.state.name;
      let dobTerm = location.state.dobTerm || null;
      let dobTermName = location.state.dob || null;
      let showTerm = null;
      let showTermName = null;
      const filterObj = { ...filter };
      if (filterTerm === "Show") {
        const obj = appContext.sites.find((c) => c.name === filterTermName);
        if (obj) {
          filterTerm = "ShowID";
          filterTermName = obj.id;
        }
      }
      if (location.state.showId) {
        showTerm = "ShowID";
        showTermName = location.state.showId;
      }

      if (location.state.positiveTests) {
        let resultValue = "positive";
        if (filterTerm === "Client") {
          const obj = location.state.models.find((c) => c.name === filterTermName);
          if (obj && obj.resultType === "P/F") resultValue = "fail";
        }
        Object.assign(filterObj, {
          result: resultValue,
        });
      } else if (location.state.negativeTests) {
        let resultValue = "negative";
        if (filterTerm === "Client") {
          const obj = location.state.models.find((c) => c.name === filterTermName);
          if (obj && obj.resultType === "P/F") resultValue = "pass";
        }
        Object.assign(filterObj, {
          result: resultValue,
        });
      }
      if (filterTermName) Object.assign(filterObj, { [filterTerm]: filterTermName });
      if (showTermName) Object.assign(filterObj, { [showTerm]: showTermName });
      if (dobTermName) Object.assign(filterObj, { [dobTerm]: dobTermName });
      setFilter(filterObj);

      setTriggerFilter(true);
      setTimeout(() => setShowFilter(false), 1);
    }
  }, [location.state]);

  useEffect(() => {
    if (pageNo > 1) {
      loadTestFromPG(Object.keys(filter).length === 0 ? { id: 1 } : filter, timeFilter, pageNo, sortBy, sortDescending);
    }
  }, [pageNo]);

  const loadTestFromPG = async (params, dateFilter, page, orderBy, orderByDirection) => {
    setLoading(true);
    try {
      const filterParam = Object.fromEntries(Object.entries({ ...params }).filter(([key]) => !key.includes("dob")));
      console.log("params", filterParam);
      Object.assign(filterParam, { page: page, siteID: siteID || "E" });

      if (dateFilter) {
        const { start, end } = dateFilter;
        if (start)
          Object.assign(filterParam, { startDate: moment(start.format("YYYY-MM-DD")).startOf("day").toISOString() });
        if (end) Object.assign(filterParam, { endDate: moment(end.format("YYYY-MM-DD")).endOf("day").toISOString() });
      }
      if (orderBy) {
        Object.assign(filterParam, { orderBy, orderByDirection });
      }
      const pgList = await API.getTestListFromPG(filterParam);
      setLoading(false);
      let ttlTests = tests.concat(pgList.rows.filter((f) => f.result !== "Expired").map((t) => formatTest(t)));
      if (pageNo === 1) {
        setTotalRecord(pgList.total);
      }

      const DobFilter = Object.fromEntries(Object.entries(params).filter(([key]) => key.includes("dob")));
      console.log("DobFilter", DobFilter, filter);
      if (Object.keys(DobFilter).length > 0 && ttlTests) {
        ttlTests = nestedFilter(ttlTests, DobFilter);
        setTotalRecord(ttlTests.length);
      }
      setTests(ttlTests);
      refreshTestList(ttlTests);
    } catch (err) {
      setLoading(false);
    }
  };

  const refreshTestList = (list) => {
    if (sortBy) {
      setFilteredTests(
        sortTestList(
          sortBy,
          sortDescending,
          list,
          appContext.getSiteName,
          //appContext.getLabName,
          appContext.getClientName,
          appContext.parseResult
        )
      );
    } else {
      setFilteredTests(list);
    }
  };

  const handleChangePage = (page, direction) => {
    setCurrentPage(page);
    const currentRecord = filteredTests.length;
    if (direction === "next" && page === totalPages && totalRecord > 0 && currentRecord < totalRecord) {
      setPageNo(pageNo + 1);
    }
  };

  const handleSaveFilter = (e, tests) => {
    appContext.setTestFilter({ location: "totaltests", filter, timeFilter });
  };

  const updateTestResult = async (result) => {
    const updateSelectedTestResult = filteredTests.filter((test) => checkboxes.includes(test.id));
    const ttlTest = updateSelectedTestResult.length;
    if (!ttlTest) {
      return;
    }
    setLoading(true);
    let ttlTestProcess = 0;
    try {
      for (let i = 0; i < updateSelectedTestResult.length; i++) {
        try {
          await API.updateTestResult(updateSelectedTestResult[i].id, result, appContext.user);
          ttlTestProcess += 1;
        } catch (err) {
          console.log("Error", err.message);
        }
      }
      appContext.showSuccessMessage(`${ttlTestProcess} test${ttlTest === 1 ? "" : "s"} has been resulted ${result}`);
      setLoading(false);
    } catch (err) {
      setLoading(false);
    }
    setCheckboxes([]);
  };

  const TableRow = ({ test }) => {
    return (
      <tr>
        <td className="ellipsis" title={formatUTCDate(test.createdAt)}>
          {formatUTCDate(test.createdAt)}
        </td>
        <td className="ellipsis" title={test.firstName}>
          {test.firstName}
        </td>
        <td className="ellipsis" title={test.lastName}>
          {test.lastName}
        </td>
        <td className="ellipsis" title={test.dob}>
          {test.dob}
        </td>
        <td
          className="ellipsis"
          style={{ textDecoration: "underline", color: "#A82632" }}
          onMouseOver={(e) => {
            e.target.style.cursor = "pointer";
            e.target.style.textDecoration = "none";
          }}
          onMouseLeave={(e) => {
            e.target.style.textDecoration = "underline";
          }}
          onClick={() => {
            test.phoneNumber && window.open(`tel:${test.phoneNumber}`);
          }}
          title={test.phoneNumber && formatPhoneNumber(test.phoneNumber)}
        >
          {test.phoneNumber && formatPhoneNumber(test.phoneNumber)}
        </td>
        <td
          className="ellipsis"
          style={{
            textDecoration: "underline",
            color: "#A82632",
          }}
          onMouseOver={(e) => {
            e.target.style.cursor = "pointer";
            e.target.style.textDecoration = "underline";
          }}
          onClick={() => test?.email && window.open(`mailto:${test.email}`)}
          title={test?.email}
        >
          {test?.email}
        </td>
        <td style={{ textAlign: "center" }} className="ellipsis">
          {appContext.getSiteName(test.siteID)}
        </td>

        <td style={{ textAlign: "center" }} className="ellipsis">
          {test.status}
        </td>
        <td style={{ textAlign: "center" }}>
          <span
            onMouseOver={(e) => {
              e.target.style.cursor = "pointer";
              e.target.style.textDecoration = "none";
            }}
            onMouseLeave={(e) => {
              e.target.style.textDecoration = "underline";
            }}
            onClick={() => displayPdf(test)}
            style={{
              color: RESULT_COLOR[test.result?.toLowerCase()],
              textDecoration: "underline",
            }}
          >
            {appContext.parseResult(test)}
          </span>
        </td>
        <td style={{ textAlign: "center" }}>{test.sequenceNo}</td>
        <td className="ellipsis" style={{ textAlign: "center" }}>
          {test.test_type || "-"}
        </td>
        <td style={{ textAlign: "center" }}>
          <Link
            to={{
              pathname: "/site/test/" + test.siteID + "/" + test.id,
              state: { type: "siteTotalTests", test: test },
            }}
            onClick={(e) => handleSaveFilter(e, test)}
            onContextMenu={(e) => handleSaveFilter(e, test)}
          >
            View
          </Link>
        </td>
      </tr>
    );
  };

  return showPdf ? (
    <PDFLayout setShowPdf={setShowPdf} testPdf={testPdf} testsLab={testsLab} />
  ) : (
    <>
      {showImportModal && (
        <ImportTestDoneModal
          handleClose={() => setShowImportModal(false)}
          handleImport={handleImportData}
          errorMessage={errorMessage}
        />
      )}
      {openErrModal && <ImportErrorModal title="Total Test" handleClose={() => setOpenErrModal(false)} data={data} />}
      <Container fluid style={{ flex: 1 }}>
        <Row>
          <Col md="12">
            <Card className="striped-tabled-with-hover">
              <Card.Header>
                <Card.Title
                  as="h4"
                  style={{
                    marginBottom: 10,
                    fontWeight: "bold",
                    color: "#565656",
                  }}
                >
                  Total Tests ({totalRecord})
                </Card.Title>
                <div className="buttonHeader">
                  {/* <Button className="headerButton" onClick={() => setShowFilter(!showFilter)}>
                    Filter
                  </Button> */}
                  <Icon
                    handleClick={() => setShowFilter(!showFilter)}
                    title={"Filter"}
                    label={"Filter"}
                    iconType={"filter"}
                  />
                  {/* Date Range */}
                  <FilterSelect setTimeFilter={setTimeFilter} />
                  {filteredTests.length > 0 && (
                    <>
                      <ExportToExcelData
                        items={filteredTests}
                        selectedItem={selectedItem}
                        appContext={appContext}
                        filter={{ ...filter, siteID: siteID || "E" }}
                        timeFilter={timeFilter}
                        title="Total Tests"
                        sortBy={sortBy}
                        setLoading={setLoading}
                      />
                      {/*  <TestPDFLayout
                        tests={filteredTests}
                        selectedItem={selectedItem}
                        filter={filter}
                        timeFilter={timeFilter}
                        title="Total Tests"
                        appContext={appContext}
                        sortBy={sortBy}
                      /> */}
                    </>
                  )}
                  {/* <Button className="headerButton" onClick={() => setShowImportModal(true)}>
                    Import Tests
                  </Button> */}
                  <Icon
                    handleClick={() => setShowImportModal(true)}
                    title={"Import Tests"}
                    label={"Import Tests"}
                    iconType={"importTests"}
                  />
                </div>
                {showFilter && (
                  <Filter
                    filterTerms={searchTerms}
                    setFilter={setFilter}
                    filter={filter}
                    triggerFilter={triggerFilter}
                    setTriggerFilter={setTriggerFilter}
                  />
                )}
                <PGPagination
                  timeFilter={timeFilter}
                  currentPage={currentPage}
                  handleChangePage={handleChangePage}
                  totalPages={totalPages}
                  totalRecord={totalRecord}
                  currentRecord={filteredTests.length}
                  showSelectedRecord
                  totalSelected={checkboxes.length}
                />
              </Card.Header>

              <Card.Body className="table-full-width px-0 desktop-noScroll">
                <div className="table-responsive pendingReleaseTable">
                  <Table className="table-hover table-striped">
                    <thead ref={componentRef}>
                      <tr>
                        <HeaderItem
                          width={tdWidth}
                          ItemKey="createdAt"
                          title="Date Tested"
                          flipSort={flipSort}
                          sortBy={sortBy}
                          sortDescending={sortDescending}
                        />
                        <HeaderItem
                          width={tdWidth}
                          ItemKey="firstName"
                          title="First Name"
                          flipSort={flipSort}
                          sortBy={sortBy}
                          sortDescending={sortDescending}
                        />
                        <HeaderItem
                          width={tdWidth}
                          ItemKey="lastName"
                          title="Last Name"
                          flipSort={flipSort}
                          sortBy={sortBy}
                          sortDescending={sortDescending}
                        />
                        <HeaderItem
                          width={tdWidth}
                          ItemKey="dob"
                          title="DoB"
                          flipSort={flipSort}
                          sortBy={sortBy}
                          sortDescending={sortDescending}
                        />
                        <HeaderItem
                          width={tdWidth}
                          ItemKey="phoneNumber"
                          title="Phone"
                          flipSort={flipSort}
                          sortBy={sortBy}
                          sortDescending={sortDescending}
                        />

                        <HeaderItem
                          width={tdWidth}
                          ItemKey="email"
                          title="Email"
                          flipSort={flipSort}
                          sortBy={sortBy}
                          sortDescending={sortDescending}
                        />
                        <HeaderItem
                          width={tdWidth}
                          ItemKey="siteID"
                          title="Show"
                          flipSort={flipSort}
                          sortBy={sortBy}
                          sortDescending={sortDescending}
                          aligned="centered"
                        />

                        <HeaderItem
                          width={tdWidth}
                          ItemKey="status"
                          title="Status"
                          flipSort={flipSort}
                          sortBy={sortBy}
                          sortDescending={sortDescending}
                          aligned="centered"
                        />

                        <HeaderItem
                          width={tdWidth}
                          ItemKey="result"
                          title="Result"
                          flipSort={flipSort}
                          sortBy={sortBy}
                          sortDescending={sortDescending}
                          aligned="centered"
                        />
                        <HeaderItem
                          width={tdWidth}
                          ItemKey="sequenceNo"
                          title="Sequence"
                          flipSort={flipSort}
                          sortBy={sortBy}
                          sortDescending={sortDescending}
                          aligned="centered"
                        />
                        <HeaderItem
                          width={tdWidth}
                          ItemKey="test_type"
                          title="Test Type"
                          flipSort={flipSort}
                          sortBy={sortBy}
                          sortDescending={sortDescending}
                          aligned="centered"
                        />

                        <th
                          className="border-0 centered text-grey"
                          style={{ fontWeight: "bold", minWidth: tdWidth, width: tdWidth, maxWidth: tdWidth }}
                        >
                          Actions
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {testsToMap &&
                        testsToMap.map((test) => {
                          return <TableRow key={test.id} test={test} />;
                        })}
                    </tbody>
                  </Table>
                </div>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
      {loading && <Loader />}
    </>
  );
};

export default TotalTestsApiNew;
