import React, { useState, useEffect, useMemo, useContext } from "react";
import { Button, Form, Modal } from "react-bootstrap";
import { externalTypesOptions } from "constant";
import Select from "react-select";
import { parseBooleanValue } from "utils";
import ViewOnlyCheckBox from "components/CheckBox/ViewOnlyCheckBox";
import { useDispatch, useSelector } from "react-redux";
import API from "api";
import { setSelectedEmployeeForTest } from "store/features/employees/employeesSlice";
import { CONFIG } from "../../constant";
import { SYSTEM_TIME_ZONE } from "../../utils";
import moment from "moment";
import { selectLab, selectClient } from "store/features/authentication/authenticationSelectors";
import { showConfirmationModal } from "store/features/general/generalAction";
import { selectedDeviceSelector } from "store/features/general/generalSelectors";
import { AppContext } from "context/app-context";
import Loader from "components/Loader/Loader";

const TestSelectionModal = (props) => {
  const { employeeForTests } = props;
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);

  const labObject = useSelector(selectLab);
  const clientObject = useSelector(selectClient);
  const appContext = useContext(AppContext);

  const [error, setError] = useState("");
  const [testList, setTestList] = useState([]);
  const [selectedTest, setSelectedTest] = useState([]);
  const selectedDevice = useSelector(selectedDeviceSelector);

  const getTestList = async (filtersData) => {
    const apiData = await API.getTestTypes(filtersData);

    setTestList(apiData);
  };

  useEffect(() => {
    getTestList();
  }, []);

  const closeModal = () => {
    dispatch(
      showConfirmationModal({
        showModal: false,
        message: "",
        title: "",
        onConfirm: null,
        onClose: null,
      })
    );
  }

  const createTests = async (isPrint) => {
    closeModal();
    setLoading(true);
    const params = {
      tests: selectedTest.map((t) => {
        const tmp = { ...t };
        delete tmp.targetRanges;
        delete tmp._deleted;
        delete tmp._lastChangedAt;
        delete tmp._version;
        delete tmp.totalTime;
        delete tmp.createdByName;
        delete tmp.firstAlert;
        delete tmp.hrCounter;
        delete tmp.secondAlert;
        const baseSource = tmp.sources.slice(0, 1);
        return {
          ...tmp,
          sources: baseSource,
          selectedSource: baseSource.label,
        };
      }),
      employees: employeeForTests.map((e) => {
        return {
          id: e.mdID,
          provider: {
            prov_id: e.referringProvider,
            prov_name: e.referringPyhsician,
            prov_npi: "",
          },
          siteID: e.siteID,
          site_name: e.site_name,
        };
      }),
      info: {
        clientID: clientObject.id,
        clientName: clientObject.name,
        labID: CONFIG.whiteLabelID,
        labName: labObject.name,
        timezone: SYSTEM_TIME_ZONE,
        orderDate: moment().toISOString(),
        testOrderDate: moment().format("YYMMDD"),
        testDate: moment().toISOString(),
        createSource: "portal",
        shipped: isPrint
      },
    };

    const employeeData = await API.createEmployeeTests(params);

    if (isPrint) {
      employeeData?.forEach((testData) => {
        testData?.tests?.forEach((test) => {
          if (selectedDevice) {
            if (test.barcode) {
              appContext.printBarcode(test);
            }
          }
        });
      });
    }
    setLoading(false);
    handleClose();
  };

  const onHandleSave = async () => {
    try {
      if (selectedTest.length === 0) return;
      const confirmationModalData = {
        showModal: true,
        onConfirm: () => createTests(true),
        onClose: () => closeModal(),
        title: "Confirmation",
        isPreRegister: true,
        message: "Would you like to create the test ordered and print label?",
      };
      dispatch(showConfirmationModal(confirmationModalData));
    } catch (ex) {
      console.log("Ex", ex);
    }
  };

  const handleClose = () => {
    dispatch(setSelectedEmployeeForTest([]));
  };

  return (
    <>
      <Modal
        show
        size="md"
        animation={true}
        className="selectedTestsModal"
        onHide={() => {
          handleClose();
        }}
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title className="my-0" id="contained-modal-title-vcenter">
            Select Tests
          </Modal.Title>
        </Modal.Header>
        <Modal.Body
          style={{
            paddingTop: 0,
          }}
        >
          {loading && <Loader />}
          <div className="form-group-wrapper">
            <label className="modalLineHeaders">Select Test </label>
            <Select
              className="w-100 siteSelector"
              options={testList.map((l) => {
                return { value: l.id, label: l.name, ...l };
              })}
              isMulti
              blurInputOnSelect={true}
              defaultValue={null}
              menuPlacement="auto"
              placeholder="Select Test"
              onChange={(e) => setSelectedTest(e)}
            />
          </div>

          {error.length > 0 && <strong style={{ color: "Red" }}>{error}</strong>}
        </Modal.Body>
        <Modal.Footer>
          <Button
            style={{ marginBottom: 10 }}
            variant="secondary"
            className="modalButtons"
            onClick={() => {
              handleClose();
            }}
          >
            Close
          </Button>
          <Button
            disabled={selectedTest.length === 0}
            style={{ marginBottom: 10 }}
            variant="primary"
            className="modalButtons"
            onClick={onHandleSave}
          >
            Save
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default TestSelectionModal;
