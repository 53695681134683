import React, { useState } from "react";
import { Button, Row, Modal } from "react-bootstrap";
import { useEffect } from "react";
import "../../src/tableStyling.css";

const TextEditor = ({ text, handleSave, handleClose, type }) => {
  const [consentForm, setConsentForm] = useState(text);

  useEffect(() => {
    setConsentForm(text);
  }, [text]);

  const onChangeForm = (e) => {
    setConsentForm(e.target.value);
  };

  return (
    <Modal
      show
      animation={true}
      className="paddingLeft-0"
      fullscreen
      onHide={() => {
        handleClose();
      }}
      dialogClassName="hipaaDialogue"
    >
      <Modal.Header closeButton>
        <Modal.Title className="my-0" id="contained-modal-title-vcenter">
          Result Disclaimer
        </Modal.Title>
      </Modal.Header>

      <Modal.Body className="hipaaBody">
        <textarea value={consentForm} onChange={onChangeForm} className="hipaaTextArea" />
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={() => handleClose()} className="modalButtons" variant="secondary">
          Close
        </Button>
        <Button
          onClick={() => handleSave(consentForm, type)}
          className="modalButtons"
          variant="primary"
          style={{
            marginRight: "20px",
          }}
        >
          Save
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default TextEditor;
