export const HIPPA_QUESTIONS = [
  {
    id: 1,
    question: "What does HIPAA stand for? ",
    options: [
      "Helping in Protection Accountability Act",
      "Health Insurance and Portability Accountability Act",
      "Health and Information for Patient Affordable service Act",
    ],
    answer: "2",
  },
  {
    id: 2,
    question: "What kind of personally identifiable health information is protected by HIPAA privacy rule? ",
    options: ["paper", "electronic", "spoken word", "All of the above"],
    answer: "4",
  },
  {
    id: 3,
    question: "HIPAA security and privacy regulations apply to anyone working in the office and Lab",
    options: ["True", "False"],
    answer: "1",
  },
  {
    id: 4,
    question:
      "The Privacy Rule protects all 'individually identifiable health information' held or transmitted in any form whether electronic, paper, or oral Information that relates to a person’s past, present, or future physical or mental health condition. This includes: ",
    options: [
      "physical or mental health condition",
      "provisions of health care to the person",
      "payment of the person's health care",
      "All of the above",
    ],
    answer: "4",
  },
  {
    id: 5,
    question: "What is PHI?",
    options: ["Protected Health Identifier", "Patient Health Insurance", "Protected Health Information"],
    answer: "3",
  },
  {
    id: 6,
    question: "Protected Health Information, PHI can include which of the following:",
    options: [
      "Name",
      "Date of birth",
      "Medical record number",
      "Message or issue",
      "Diagnosis",
      "Admission date, time and reason",
      "All of the above",
    ],
    answer: "7",
  },
  {
    id: 7,
    question: "My responsibility under HIPAA includes:",
    options: [
      "Handling PHI as if it were my own",
      "Disposing of scrap paper and other documents with PHI by shredding or confidential disposal",
      "Accessing PHI, only the minimum necessary, to do my job",
      "Discussing potential violations or any HIPAA concerns with my supervisor, manager or the designated HIPAA Privacy/Security Official",
      "All of the above",
      "Other",
    ],
    answer: "5",
  },
  {
    id: 8,
    question: "What is a privacy breach? ",
    options: [
      "A team member fails to maintain confidentiality by discussing patient health information on social media",
      "Lab results are sent to the wrong patient or recipient",
      "A laptop or Smartphone containing electronic PHI is lost or stolen – and the device is not encrypted",
      "A computer hacker gains access to systems that contain PHI",
      "All of the above",
      "None of the above",
    ],
    answer: "5",
  },
  {
    id: 9,
    question: "An example of a Business Associate of a HIPAA covered entity is:",
    options: [
      "An answering service",
      "A contractor providing IT services and support",
      "A transcription service",
      "Collection and billing vendors",
      "All of the above",
    ],
    answer: "5",
  },
  {
    id: 10,
    question: "If you need to report a HIPAA concern or violation, which of the following can you do?",
    options: [
      "Contact my supervisor or manager",
      "Contact my HIPAA Site Coordinator",
      "Contact my organization’s HIPAA Compliance Officer",
      "All of the above",
    ],
    answer: "4",
  },
  {
    id: 11,
    question: "Consequences for confidentiality include which of the following",
    options: [
      "Financial penalties",
      "Public shaming",
      "Imprisonment (civil or criminal)",
      "revocation of medical or professional license",
    ],
    answer: "1",
  },
];
