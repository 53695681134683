import React, { useMemo, useEffect } from "react";
import "./TestDetailsRangeView.css";
import Icon from "components/Icon";
import TestCollapsibleMenu from "./TestCollapsibleMenu";
import { Button } from "react-bootstrap";
import { rangeDataOptions } from "constant";
import { getSelectedValues } from "../../../util/rangeUtil";

const TestDetailsRangeViewNew = ({
  onChange,
  data,
  handelRemoveView,
  handleAddRange,
  setFilter,
  handelRemoveNode,
  handleAddNode,
  handelCollaspeNode,
  handleAddNewRow,
  handelRemoveRow,
}) => {
  // const {
  //   AgeStart,
  //   AgeEnd,
  //   NormalLow,
  //   NormalHigh,
  //   HighLow,
  //   HighHigh,
  //   PanicLow,
  //   PanicHigh,
  //   RepeatLow,
  //   RepeatHigh,
  //   LowLow,
  //   LowHigh,
  //   RangesAdded,
  //   collapse,
  //   FilterKey = [null],
  // } = data;

  const { RangesAdded, collapse, FilterKey = [null], RangeArr } = data;

  const rangesArr = ["Low", "Normal", "High", "Panic", "Repeat"];

  const getFilteredOptions = (includedValues, excludeValues) => {
    const exdValue = [...excludeValues].filter((item) => item !== includedValues?.keyType).filter(Boolean);

    return rangeDataOptions.filter((option) => !exdValue.includes(option.keyType));
    // if (!includedValues) return rangeDataOptions.filter((option) => option.keyType === includedValues.keyType);
  };

  const hasAgeFilter = useMemo(() => {
    return FilterKey.some((item) => item?.keyType === "age");
  }, [FilterKey]);

  const handleSelectChange = (selectedOption, index) => {

    const updatedBlocks = [...FilterKey];

    updatedBlocks[index] = selectedOption;

    const excludeValues = updatedBlocks?.map((value) => value?.keyType).filter(Boolean);
    const options = getFilteredOptions(null, excludeValues);

    if (
      options.length &&
      !RangesAdded &&
      (updatedBlocks[index + 1] === null || updatedBlocks[index + 1] === undefined)
    ) {
      updatedBlocks[index + 1] = null;
    }

    setFilter(updatedBlocks);
  };

  const renderDropdowns = (block, index, options) => {
    const isSelected = block !== null;

    const selectedValues = getSelectedValues({ FilterKey, RangeArr, index, collapse });

    const displayValue = selectedValues ? { label: selectedValues, value: selectedValues } : block;

    return (
      <div className="d-flex align-items-center" key={index}>
        <Icon
          iconType={!isSelected || collapse ? "rightArrow" : "downArrow"}
          containerClass={"cursor-pointer ml-5"}
          innerClass={"test-selector-add-icon"}
          imageClass={"delete-icon "}
          handleClick={handelCollaspeNode}
        />

        <TestCollapsibleMenu
          key={index}
          options={options}
          onChange={(selectedOption) => handleSelectChange(selectedOption, index)}
          value={displayValue}
          className="minWidth50"
        />
      </div>
    );
  };

  return (
    <div style={{ padding: "10px", borderRadius: "10px" }}>
      {FilterKey?.map((block, blockIndex) => {
        const excludeValues = FilterKey?.map((value) => value?.keyType).filter(Boolean);

        const options = getFilteredOptions(block, excludeValues);
        if (!collapse || blockIndex === 0) {
          return (
            <div
              key={blockIndex}
              className="block-section"
              style={{
                marginLeft: blockIndex === 0 ? "0px" : `${blockIndex * 40}px`,
              }}
            >
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <div className="w-50 d-flex align-items-center">
                  {renderDropdowns(block, blockIndex, options)}

                  <Icon
                    iconType={"binIcon"}
                    containerClass={"cursor-pointer ms-2"}
                    imageClass={"delete-icon "}
                    handleClick={() => handelRemoveNode(blockIndex)}
                  />

                  {FilterKey.length - 1 === blockIndex && options.length > 0 && (
                    <Icon
                      iconType={"createIcon"}
                      containerClass={"ms-2 cursor-pointer"}
                      innerClass={"test-selector-add-icon"}
                      handleClick={handleAddNode}
                    />
                  )}
                </div>

                {RangesAdded === false && blockIndex === FilterKey.length - 1 && (
                  <div
                    className="d-flex justify-content-end"
                    style={{
                      minWidth: "350px",
                      width: FilterKey?.length > 2 ? `${370 + (FilterKey.length - 2) * 80}px` : "370px",
                    }}
                  >
                    <Button
                      className="border-0 px-0 text-decoration-underline"
                      variant="primary"
                      disabled={RangesAdded}
                      onClick={handleAddRange}
                    >
                      Add Ranges
                    </Button>
                  </div>
                )}
              </div>
            </div>
          );
        }
      })}

      {(RangesAdded || RangesAdded === undefined) && !collapse && (
        <div
          className="range-view-container"
          style={{
            marginLeft: FilterKey.filter(Boolean).length * 40,
          }}
        >
          <table className="range-view-table">
            <thead>
              <tr>
                {hasAgeFilter && (
                  <th className="range-view-th range-special-bg" colSpan="2">
                    Age Range
                  </th>
                )}

                {rangesArr.map((range, index) => (
                  <th className="range-view-th" colSpan="2" key={`range_${index}`}>
                    {range}
                  </th>
                ))}
                <th className="range-view-th">Action</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                {hasAgeFilter && (
                  <>
                    <td className="range-view-td text-center range-special-bg">Start</td>
                    <td className="range-view-td text-center range-special-bg">End</td>
                  </>
                )}

                {rangesArr.map((item, index) => (
                  <>
                    <td className="range-view-td text-center" key={`low_${index}`}>
                      Low
                    </td>
                    <td className="range-view-td text-center" key={`high_${index}`}>
                      High
                    </td>
                  </>
                ))}

                <td className="range-view-td"></td>
              </tr>

              {RangeArr &&
                RangeArr.map((item, rowIndex) => {
                  const {
                    AgeStart,
                    AgeEnd,
                    NormalLow,
                    NormalHigh,
                    HighLow,
                    HighHigh,
                    PanicLow,
                    PanicHigh,
                    RepeatLow,
                    RepeatHigh,
                    LowLow,
                    LowHigh,
                  } = item;

                  return (
                    <tr>
                      {hasAgeFilter && (
                        <>
                          <td className="text-center range-special-bg">
                            <input
                              className="range-view-input"
                              name="AgeStart"
                              value={AgeStart}
                              type="number"
                              onChange={(e) => onChange(e, rowIndex)}
                            />
                          </td>
                          <td className="text-center range-special-bg">
                            <input
                              className="range-view-input"
                              name="AgeEnd"
                              value={AgeEnd}
                              type="number"
                              onChange={(e) => onChange(e, rowIndex)}
                            />
                          </td>
                        </>
                      )}

                      <td className="text-center bg-white">
                        <input
                          className="range-view-input"
                          name="LowLow"
                          value={LowLow}
                          onChange={(e) => onChange(e, rowIndex)}
                        />
                      </td>
                      <td className="text-center bg-white">
                        <input
                          className="range-view-input"
                          name="LowHigh"
                          value={LowHigh}
                          onChange={(e) => onChange(e, rowIndex)}
                        />
                      </td>

                      <td className="text-center bg-white">
                        <input
                          className="range-view-input"
                          name="NormalLow"
                          value={NormalLow}
                          onChange={(e) => onChange(e, rowIndex)}
                        />
                      </td>
                      <td className="text-center bg-white">
                        <input
                          className="range-view-input"
                          name="NormalHigh"
                          value={NormalHigh}
                          onChange={(e) => onChange(e, rowIndex)}
                        />
                      </td>

                      <td className="text-center bg-white">
                        <input
                          className="range-view-input"
                          name="HighLow"
                          value={HighLow}
                          onChange={(e) => onChange(e, rowIndex)}
                        />
                      </td>
                      <td className="text-center bg-white">
                        <input
                          className="range-view-input"
                          name="HighHigh"
                          value={HighHigh}
                          onChange={(e) => onChange(e, rowIndex)}
                        />
                      </td>

                      <td className="text-center bg-white">
                        <input
                          className="range-view-input"
                          name="PanicLow"
                          value={PanicLow}
                          onChange={(e) => onChange(e, rowIndex)}
                        />
                      </td>
                      <td className="text-center bg-white">
                        <input
                          className="range-view-input"
                          name="PanicHigh"
                          value={PanicHigh}
                          onChange={(e) => onChange(e, rowIndex)}
                        />
                      </td>

                      <td className="text-center bg-white">
                        <input
                          className="range-view-input"
                          name="RepeatLow"
                          value={RepeatLow}
                          onChange={(e) => onChange(e, rowIndex)}
                        />
                      </td>
                      <td className="text-center bg-white">
                        <input
                          className="range-view-input"
                          name="RepeatHigh"
                          value={RepeatHigh}
                          onChange={(e) => onChange(e, rowIndex)}
                        />
                      </td>

                      <td className="text-center bg-white">
                        <div className="d-flex align-items-center">
                          <Icon
                            iconType={"binIcon"}
                            containerClass={"cursor-pointer"}
                            innerClass={""}
                            imageClass={"delete-icon "}
                            handleClick={() => handelRemoveRow(rowIndex)}
                          />
                          {hasAgeFilter && rowIndex === RangeArr.length - 1 && (
                            <Icon
                              iconType={"createIcon"}
                              containerClass={"cursor-pointer ms-2"}
                              imageClass={"delete-icon "}
                              handleClick={handleAddNewRow}
                            />
                          )}
                        </div>
                      </td>
                    </tr>
                  );
                })}
            </tbody>
          </table>
        </div>
      )}
    </div>
  );
};

export default TestDetailsRangeViewNew;
