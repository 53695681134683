import AutoCompleteAddress from 'components/AutoCompleteAddress';
import PhoneNoInput from 'components/Phone/PhoneNoInput';
import moment from 'moment';
import { useEffect, useState } from 'react';
import Form from 'react-bootstrap/Form';
import DatePicker from 'react-date-picker';
import Select from 'react-select';
import styles from './InputStyle.module.css';
import { useSelector } from 'react-redux';
import { selectLab } from 'store/features/authentication/authenticationSelectors';

const InputField = (props) => {
  const {
    label,
    type,
    placeholder,
    handleChange,
    labelStyle,
    inputStyle,
    value,
    maxlength,
    groupWrapper,
    disabled,
    index,
    errorList,
    options,
    isClearable,
    getOptionValue,
    getOptionLabel,
    componentOptions,
    onInputChange,
    required,
    isFutureDateAllowed,
    pattern = '',
    isMulti = false,
  } = props;
  const [error, setError] = useState(false);

  useEffect(() => {
    if (errorList && errorList.length > 0) {
      const res = errorList?.includes(index);
      setError(res);
    }
  }, [errorList]);

  useEffect(() => {
    if (options && options.length === 1 && type === 'dropDown') {
      handleChange(options[0]);
    }
  }, []);

  const labData = useSelector(selectLab);

  const countryVal = {
    label: labData?.country || 'US',
    value: labData?.country || 'US',
  };

  const getInPutFields = (inputType) => {
    switch (inputType) {
      case 'phone':
        return (
          <PhoneNoInput
            handleChange={handleChange}
            value={value || ''}
            disabled={disabled}
            cssCode={`${inputStyle} w-100 ${error && 'errorField'}`}
          />
        );
      case 'address':
        return (
          <AutoCompleteAddress
            cssClass={`${styles.inputStyling} ${inputStyle} ${disabled ? 'disabledAddressBar' : ''} ${
              error && 'errorField'
            }`}
            value={value || ''}
            readOnly={disabled}
            handleChange={(e, flag) => handleChange(e, flag)}
          />
        );
      case 'datePicker':
        return (
          <DatePicker
            className={`${inputStyle} ${error && 'errorField'}`}
            openCalendarOnFocus={false}
            dayPlaceholder="dd"
            monthPlaceholder="mm"
            yearPlaceholder="yyyy"
            format="MM/dd/y"
            onChange={(e) => handleChange(e)}
            disabled={disabled}
            value={value && new Date(value) != 'Invalid Date' ? moment(value, 'YYYY-MM-DD').toDate() : null}
            maxDate={!isFutureDateAllowed && new Date()}
            minDate={new Date('1900-01-01')}
          />
        );
      case 'dropDown':
        return (
          <Select
            options={options}
            className={`${inputStyle || ''} ${error && 'errorField'}`}
            blurInputOnSelect={true}
            isClearable={isClearable}
            getOptionValue={getOptionValue}
            getOptionLabel={getOptionLabel}
            menuPlacement="auto"
            placeholder={placeholder}
            isDisabled={disabled}
            isMulti={isMulti}
            value={label === 'Country' ? value || countryVal : value}
            onChange={(e) => handleChange(e)}
            components={componentOptions}
            onInputChange={onInputChange}
          />
        );
      default:
        return (
          <Form.Control
            type={inputType}
            maxLength={maxlength}
            pattern={pattern}
            value={value || ''}
            placeholder={placeholder}
            disabled={disabled}
            onChange={(e) => handleChange(e)}
            className={`${styles.inputStyling} ${inputStyle} ${error ? 'errorField' : ''}`}
          />
        );
    }
  };
  return (
    <Form.Group className={`${groupWrapper ? groupWrapper : 'form-group-wrapper '}`}>
      {label ? (
        <Form.Label className={`${styles.inputLabel} ${labelStyle}`}>
          {`${label} `} {required ? <span className="fw-bold fs-6 maroon">*</span> : null}
        </Form.Label>
      ) : (
        ''
      )}

      {getInPutFields(type)}
    </Form.Group>
  );
};

export default InputField;
