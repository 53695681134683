import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import api from "../../../api";
import { CLIENT, CLIETNT_FETCH, USER_CLIETNT_FETCH } from "./clientsConstant";

// Async thunk to fetch all clients
export const fetchAllClients = createAsyncThunk(CLIETNT_FETCH, async () => {
  const response = await api.getCompanies();
  return response;
});

//Async thunk to Fetch user client
export const fetchUserClients = createAsyncThunk(USER_CLIETNT_FETCH, async (param, { getState }) => {
  const client = await api.getUserCompany();
  return client;
});

// Async thunk to create a client
export const createClientAsync = createAsyncThunk("client/createClient", async (client) => {
  const response = await api.newClient(client);
  return response;
});

// Async thunk to update a client
export const updateClientAsync = createAsyncThunk("client/updateClient", async ({ clientId, updatedclient }) => {
  const response = await api.updateClient(clientId, updatedclient);
  return response;
});

// Async thunk to delete a client
export const deleteClientAsync = createAsyncThunk("client/deleteClient", async (clientId) => {
  const response = await api.deleteClient(clientId);
  return response;
});

const clientSlice = createSlice({
  name: CLIENT,
  initialState: {
    clients: [],
    userCompany: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchAllClients.fulfilled, (state, action) => {
        state.clients = action.payload;
      })
      .addCase(createClientAsync.fulfilled, (state, action) => {
        state.clients.push(action.payload);
      })
      .addCase(updateClientAsync.fulfilled, (state, action) => {
        const updatedclient = action.payload;
        const existingclientIndex = state.clients.findIndex((client) => client.id === updatedclient.id);
        if (existingclientIndex !== -1) {
          state.clients[existingclientIndex] = updatedclient;
        }
      })
      .addCase(deleteClientAsync.fulfilled, (state, action) => {
        const deletedclientId = action.payload;
        state.clients = state.clients.filter((client) => client.id !== deletedclientId);
      })
      .addCase(fetchUserClients.fulfilled, (state, action) => {
        state.clients = [action.payload];
        state.userCompany = action.payload;
      });
  },
});

export default clientSlice.reducer;
