import React from "react";
import "./TestDetailsRangeView.css";
import { Button, Modal} from "react-bootstrap";
import TestDetailsRangeView from "./TestDetailsRangeView";

const TestRangeModal = ({
  onChange,
  data,
  handelRemoveView,
  handleClose,
  handleAddRange,
  onChangeGender,
  onChangeBacteria,
}) => {

  return (
    <Modal
      show
      animation={true}
      className="second-modal modal-padding-0"
      onHide={() => handleClose()}
      centered
      size={"full"}
      backdrop="static"
    >
      <Modal.Header closeButton>
        <Modal.Title className="my-0 h4" id="contained-modal-title-vcenter">
          Test Ranges
        </Modal.Title>
      </Modal.Header>
      <Modal.Body style={{ paddingTop: 0 }}>
        <TestDetailsRangeView
          data={data}
          onChange={onChange}
          onChangeGender={onChangeGender}
          onChangeBacteria={onChangeBacteria}
          handelRemoveView={handelRemoveView}
          handleAddRange={handleAddRange}
        />
      </Modal.Body>

      <Modal.Footer>
        <Button variant="secondary" className="modalButtons" onClick={() => handleClose()}>
          Close
        </Button>

        <Button variant="primary" className="modalButtons" onClick={() => handleClose()}>
          Save
        </Button>
      </Modal.Footer>
    </Modal>
  );
};
export default TestRangeModal;
