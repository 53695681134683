import React from "react";

const RadioButton = (props) => {
  const { id, label, name, checked, handleRadioButtonChange, value, disabled, cssClass } = props;
  return (
    <div className={`radio-button-wrapper ${cssClass ?? ""}`}>
      <input
        value={value}
        type="radio"
        name={name}
        id={id}
        disabled={disabled}
        checked={checked}
        onChange={(e) => handleRadioButtonChange(e)}
      />
      <label className="mb-0" htmlFor={id}>
        {label}
      </label>
    </div>
  );
};

export default RadioButton;
