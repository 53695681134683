import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { MESSAGE_MODES } from "constant";
import { userCompanyID } from "utils";
import api from "../../../api";
import { setMessage } from "../general/generalAction";
import { setLoader, showMessage } from "../general/generalSlice";
import {
  DOC_FETCH,
  DOC_CREATE,
  DOC_UPDATE,
  DOC_DELETE,
  DOCUMNET,
  DOC_FETCH_TYPE,
  DOC_TYPE_CREATE,
} from "./invoiceDocumentConstant";

export const updateInvoiceDocsAsync = createAsyncThunk(DOC_UPDATE, async (param, { dispatch, getState }) => {
  const loginUSer = getState().auth.user;

  dispatch(setLoader(true));

  let fileName = param.fileName;
  if (param.file) {
    fileName = await api.saveInvoiceDocFile(param.selectedFileName, param.file);
  }
  return { ...param, fileName, updatedBy: loginUSer.sub, updatedByName: loginUSer.name };
});

export const createInvoiceDocsAsync = createAsyncThunk(DOC_CREATE, async (param, { dispatch, getState }) => {
  const loginUSer = getState().auth.user;
  dispatch(setLoader(true));

  let fileName = null;
  if (param.file) {
    fileName = await api.saveInvoiceDocFile(param.selectedFileName, param.file);
  }

  dispatch(setMessage("Document Uploaded Successfully", MESSAGE_MODES.success));

  return { ...param, fileName, updatedBy: loginUSer.sub, updatedByName: loginUSer.name, file: "" };
});

const invoiceDocumentSlice = createSlice({
  name: DOCUMNET,
  initialState: {
    invoiceDocs: [],
  },
  reducers: {
    setInvoiceDocs: (state, action) => {
      state.invoiceDocs = action.payload;
    },
    deleteInvoiceDocs: (state, action) => {
      if (action.payload) state.invoiceDocs = state.invoiceDocs.filter((f) => f.id !== action.payload?.id);
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(createInvoiceDocsAsync.fulfilled, (state, action) => {
        if (action.payload) state.invoiceDocs.unshift(action.payload);
      })
      .addCase(updateInvoiceDocsAsync.fulfilled, (state, action) => {
        const updateDoc = action.payload;
        if (updateDoc) {
          const existingIndex = state.invoiceDocs.findIndex((f) => f.id === updateDoc.id);
          if (existingIndex !== -1) {
            state.invoiceDocs[existingIndex] = updateDoc;
          }
        }
      });
  },
});

export const { setInvoiceDocs } = invoiceDocumentSlice.actions;
export default invoiceDocumentSlice.reducer;
