import React, { useEffect, useState } from "react";
import totalTestsImg from '../../assets/img/icon1.svg';
import positiveTestsImg from '../../assets/img/plus.svg';
import negativeTestsImg from '../../assets/img/minus.svg';

import API from "api";
// react-bootstrap components
import {
    Badge,
    Button,
    Card,
    Navbar,
    Nav,
    Table,
    Container,
    Row,
    Col,
    Form,
    OverlayTrigger,
    Tooltip,
} from "react-bootstrap";


const DashboardChartTotalTestsDataCard = (props) => {

    const {totalTestCounts, clientDashboard} = props;
   
    return (
        <>
            <div className="row">
                <div className={`col-12 col-sm-12 px-4 ${clientDashboard ? 'col-md-12 col-xl-12 mb-3' : 'col-md-6 col-xl-3'}`}>
                    <div className="card total-test mb-xl-0 mb-md-3">
                        <div className="card-body custom-card-body">
                            <div className="detail d-flex align-items-center">
                                <img src={totalTestsImg} className="icon-svg" alt="total tests" />
                                <p className="title mb-0">Total Tests</p>
                            </div>
                            <span className="number total-number">{totalTestCounts?.totalTests || 0}</span>
                        </div>
                    </div>
                </div>
                <div className={`col-12 col-sm-12 px-4 ${clientDashboard ? 'col-md-12 col-xl-12 mb-3' : 'col-md-6 col-xl-3'}`}>
                    <div className="card positive-test mb-xl-0 mb-md-3">
                        <div className="card-body custom-card-body">
                            <div className="detail d-flex align-items-center">
                                <img src={positiveTestsImg} className="icon-svg" alt="positive tests" />
                                <p className="title mb-0">Positive Tests</p>
                            </div>
                            <span className="number positive-number">{totalTestCounts?.positiveTests || 0}</span>
                        </div>
                    </div>
                </div>
                <div className={`col-12 col-sm-12 px-4 ${clientDashboard ? 'col-md-12 col-xl-12 mb-3' : 'col-md-6 col-xl-3'}`}>
                    <div className="card negative-test mb-xl-0 mb-md-3">
                        <div className="card-body custom-card-body">
                            <div className="detail d-flex align-items-center">
                                <img src={negativeTestsImg} className="icon-svg" alt="negative tests" />
                                <p className="title mb-0">Negative Tests</p>
                            </div>
                            <span className="number negative-number">{totalTestCounts?.negativeTests || 0}</span>
                        </div>
                    </div>
                </div>
                <div className={`col-12 col-sm-12 px-4 ${clientDashboard ? 'col-md-12 col-xl-12' : 'col-md-6 col-xl-3'}`}>
                    <div className="card inconclusive-test mb-xl-0 mb-md-3">
                        <div className="card-body custom-card-body">
                            <div className="detail d-flex align-items-center">
                                <img src={negativeTestsImg} className="icon-svg" alt="negative tests" />
                                <p className="title mb-0">Inconclusive Tests</p>
                            </div>
                            <span className="number inconclusive-number">{totalTestCounts?.inconclusiveTests || 0}</span>
                        </div>
                    </div>
                </div>
            </div>
        </>



    );
}

export default DashboardChartTotalTestsDataCard;
