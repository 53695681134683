export const genderOBJ = {
  M: "Male",
  F: "Female",
};

export const getMatchScore = (item, userData) => {
  let score = 0;

  for (const filter of item.FilterKey || []) {
    if (filter) {
      const keyType = filter.keyType;
      if (keyType === "age") {
        const ageStart = Number(item.AgeStart);
        const ageEnd = Number(item.AgeEnd);
        if (userData.age >= ageStart && userData.age <= ageEnd) {
          score += 1;
        }
      } else {
        const userValue = userData[keyType]?.value;

        if (userValue !== undefined && userValue === filter.value) {
          score += 1;
        }
      }
    }
  }

  return score;
};

export const isWithinAgeRange = (item, ageNumber) => {
  const ageStart = Number(item.AgeStart);
  const ageEnd = Number(item.AgeEnd);
  return ageNumber >= ageStart && ageNumber <= ageEnd;
};

export const getGroupMatchScore = (group, userData) => {
  let score = 0;

  for (const filter of group.FilterKey || []) {
    const keyType = filter.keyType;

    if (keyType === "age") {
      continue;
    }

    const userValue = userData[keyType]?.value;
    if (userValue !== undefined && userValue === filter.value) {
      score += 1;
    }
  }

  return score;
};

// export const findBestMatchingRange = (ranges, userData) => {

//     const scoredGroups = ranges.map((group) => ({
//       group,
//       score: getGroupMatchScore(group, userData),
//     }));

//     const maxGroupScore = Math.max(...scoredGroups.map(({ score }) => score));

//     const bestGroups = scoredGroups
//       .filter(({ score }) => score === maxGroupScore)
//       .map(({ group }) => group);

//     const matchingRanges = [];

//     for (const group of bestGroups) {
//       const matchingRange = findMatchingRange(group, userData.age);

//       if (matchingRange) {
//         matchingRanges.push({
//           range: matchingRange,
//           group,
//         });
//       }
//     }

//     if (matchingRanges.length > 0) {
//       return matchingRanges[0].range;
//     }

//     for (const group of bestGroups) {
//       if (group.RangeArr.length > 0) {
//         return group.RangeArr[0];
//       }
//     }

//     for (const group of ranges) {
//       if (group.RangeArr.length > 0) {
//         return group.RangeArr[0];
//       }
//     }

//     return null;
//   };

const doesGroupMatchAllFilters = (group, userData) => {
  for (const filter of group.FilterKey || []) {
    const keyType = filter.keyType;

    if (keyType === "age") {
      continue;
    }

    const userValue = userData[keyType]?.value;
    if (userValue === undefined || userValue !== filter.value) {
      return false;
    }
  }

  return true;
};

export const findMatchingRange = (group, userAge) => {
  return group.RangeArr.find((rangeItem) => {
    const ageStart = Number(rangeItem.AgeStart);
    const ageEnd = Number(rangeItem.AgeEnd);
    return userAge >= ageStart && userAge <= ageEnd;
  });
};

// Main function to find the best matching range
export const findBestMatchingRange = (ranges, userData) => {
  const matchingGroups = ranges.filter((group) => doesGroupMatchAllFilters(group, userData));

  const matchingRanges = [];

  for (const group of matchingGroups) {
    const matchingRange = findMatchingRange(group, userData.age);

    if (matchingRange) {
      matchingRanges.push({
        range: matchingRange,
        group,
      });
    }
  }



  if (matchingRanges.length > 0) {
    return matchingRanges[0].range;
  }

  if (ranges.length > 0 && ranges?.[0]?.FilterKey.length === 0) {
    return ranges?.[0].RangeArr?.[0];
  }

  return null;
};

const isNonEmpty = (value) => {
  return value !== undefined && value !== null && value !== "";
};

const getAgeRange = (RangeArr) => {
  for (const obj of RangeArr) {
    const ageStart = obj.AgeStart;
    const ageEnd = obj.AgeEnd;
    if (isNonEmpty(ageStart) && isNonEmpty(ageEnd)) {
      return `${ageStart}-${ageEnd}`;
    }
  }
  return "";
};

export const getSelectedValues = ({ FilterKey, RangeArr, index, collapse }) => {
  if (index !== 0 || !collapse) {
    return null;
  }

  return FilterKey.filter((item) => item !== null && item?.label)
    .map((item) => {
      if (item.keyType === "age") {
        const ageRange = getAgeRange(RangeArr);
        return ageRange ? `Age: ${ageRange}` : "";
      }
      return item.label;
    })
    .filter((label) => label)
    .join(", ");
};
