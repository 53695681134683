import React, { useEffect, useState, useContext, useMemo, useRef } from "react";
import { formatPhoneNumberIntl } from "react-phone-number-input";
import editIcon from "../assets/img/edit-icon.svg";
import profileImageDefault from "../assets/img/default_profile.png";
import "bootstrap-daterangepicker/daterangepicker.css";
// react-bootstrap components
import { Button, Card, Form, Container, Row, Col, Modal, CloseButton } from "react-bootstrap";
import { useLocation, useHistory } from "react-router-dom";
import GQAPI from "views/GraphQL/gqApi";
import API from "api";
import SweetAlert from "react-bootstrap-sweetalert";
import Select from "react-select";
import { AppContext } from "../context/app-context";
import ErrorMessage from "components/Message/ErrorMessage";
import { userGivenPermission } from "store/features/authentication/authenticationSelectors";
import QrCodeDownloadIcon from "assets/img/qr-code-rounded.svg";
import EditIcon from "assets/img/edit-icon.png";
import { COMPLETED_TESTS } from "constant";
import { showResultPdf } from "store/features/resultPdf/reslutPdfSlice";

import {
  TEST_TYPE_VALUE,
  countryListAllIsoData,
  IMAGE_UPLOAD_TYPE,
  PROFILE_IMAGE_PATH,
  TEST_EMPTY_OBJECT,
  RESULT_COLOR,
  SWAB_TYPES_OPT,
  POSSIBLE_EXPOSURE_OPT,
  CONFIRMED_EXPOSURE_OPT,
  TEST_SETTING_TYPES,
  CUSTOM_INS,
} from "constant";
import {
  getDemoGraphics,
  formatZip,
  formatTest,
  setPhoneNo,
  formatPhoneNumber,
  formatDateOfBirthDOB,
  formatQuaratineDisplayDate,
  isValidName,
  isValidPhoneWithCode,
  isValidIdNum,
  getValidName,
  getPhoneNo,
  isValidEmail,
  connectedIDByURL,
  ChangeObject,
  formatPhoneWithOutSpaces,
  qrResponseJOSN,
  toTitleCase,
  zipCodeValidationUS,
  getValidDep,
  getRecviedDateFromSrNo,
  loadReqFormTest,
  isLocalhost,
  convertTestToOrderObj,
  compareTestdReturnNewValues,
  getResultColors,
  createChangeObjForLogs,
  prevTestTypeKey,
} from "../utils";
import "react-activity/dist/Bounce.css";
import { Storage } from "aws-amplify";
import { useSelector } from "react-redux";
import moment from "moment";
import QuarantineModal from "components/Modal/QuarantineModal";
import EditTestDetailsModal from "components/Modal/EditTestDetailsModal";
import ConfirmationModal from "components/Modal/ConfirmationModal";
import SingleDatePicker from "Date/SingleDatePicker";
import PhoneNoInput from "components/Phone/PhoneNoInput";
import ImageInput from "components/Input/ImageInput";
import "react-tabs/style/react-tabs.css";
import Loader from "../components/Loader/Loader";
import TestModal from "components/Modal/TestModal";
import AutoCompleteAddress from "components/AutoCompleteAddress";
import { stringConstant } from "constant";
import { CONFIG } from "constant";
import { QRCode } from "react-qrcode-logo";
import gqApi from "./GraphQL/gqApi";
import RadioInput from "components/RadioInput/RadioInput";
import { SYMPTOMS_OPT } from "constant";
import CheckBoxInput from "components/CheckBoxInput/CheckBoxInput";
import { SYMPTOMS } from "constant";
import { WORKER_OPT } from "constant";
import { async } from "@firebase/util";
import html2canvas from "html2canvas";
import whiteLabelLogo from "assets/img/medflow_icon.png";
import { UN_RESULT } from "constant";
import { GET_CUST_COLOR } from "constant";
import { RECEIVED_BYLAB } from "constant";
import { ADMIN_USER } from "constant";
import DocumentList from "components/Document/DocumentList";
import DocumentForm from "components/Document/DocumentForm";
import { INSURANCE_PROVIDER } from "constant";
import LabBarCode from "components/BarCode/LabBarCode";
import { EDIT_PERMISSION_ROLE } from "constant";
import { useDispatch } from "react-redux";
import { setSelectedTestForResult } from "store/features/resultsTests/resultsTestAction";
import { showResultReqForm } from "store/features/resultPdf/reslutPdfSlice";
import { LAB_ID } from "constant";
import { refLabsSelectors } from "store/features/locations/locationsSelectors";
import ResultCell from "./GraphQL/TestListCompletedTest/ResultCell";

function TestDetails(props) {
  const location = useLocation();
  const history = useHistory();
  const downloadRef = useRef();
  const [test, setTest] = useState();

  const [site, setSite] = useState();
  const [lab, setLab] = useState();
  const [testNames, setTestNames] = useState([]);
  const [demographics, setDemographics] = useState();
  const [showAlert, setShowAlert] = useState(false);
  const [releaseTo, setReleaseTo] = useState([]);
  const [signature, setSignature] = useState();
  const [loading, setLoading] = useState(false);
  const [hippaConsent, setHippaConsent] = useState(false);
  const [showImages, setShowImages] = useState(null);
  const [showSubmitAlert, setShowSubmitAlert] = useState(false);
  const [editButton, setEditButton] = useState(true);
  const [editInfo, setEditInfo] = useState();
  const [Employee, setEmployee] = useState({});
  const [defaultTestData, setDefaultTestData] = useState({});
  const labLocations = useSelector(refLabsSelectors);

  const [error, setError] = useState("");
  const [openQuarantined, setOpenQuaratined] = useState(false);
  const [allowToEditQuarantine, setAllowToEditQuarantine] = useState(false);
  const [openDataChangeConfirmation, setOpenDataChangeConfirmation] = useState(false);
  const [changeBarcodeConfirmation, setChangeBarcodeConfirmation] = useState(false);
  const [phoneError, setPhoneError] = useState("");
  const [vaccinationImage, setVaccinationImage] = useState("");
  const [externalTestImage, setExternalTestImage] = useState("");
  const [changeData, setchangeData] = useState({});
  const [changeDataCheck, setchangeDataCheck] = useState(true);
  const appContext = useContext(AppContext);
  const [profileImage, setProfileImage] = useState(profileImageDefault);
  const [openDocumentModal, setOpenDocumentModal] = useState(null);
  const [selectedDocument, setSelectedDocument] = useState({ isNew: true });
  const [users, setUsers] = useState([]);
  //
  const [openCreator, setOpenCreator] = useState(false);
  const [newTestCreate, setTestCreate] = useState(_.cloneDeep(TEST_EMPTY_OBJECT));
  const [labs, setLabs] = useState([]);
  const [resultTypes, setResultTypes] = useState([]);
  const [openHSForm, setOpenHSForm] = useState(false);
  const [documents, setDocuments] = useState([]);
  const [barcodeBase64, setBarcodeBase64] = useState("");
  const [testSources, setTestSources] = useState("");
  const [testOptions, setTestOptions] = useState();

  const dispatch = useDispatch();

  const barcodeRef = useRef(null);

  const permission = useSelector(userGivenPermission);

  const labOptions = useMemo(() => {
    return labLocations.map((item) => ({
      ...item,
      label: item.name,
      value: item.id,
    }));
  }, [labLocations]);

  const hideAlert = () => {
    props.history.push("/admin/pendingrelease");
    setShowAlert(false);
  };

  const getUserFromLocalDB = async () => {
    let getUserDAta = await API.getUserFromLocalDB(CONFIG.isWhiteLabel ? CONFIG.whiteLabelID : "");
    setUsers(getUserDAta);
  };

  const usersData = useMemo(() => {
    if (!users) return;
    return users
      .filter((u) => u.roles?.includes("Tester") || u.roles?.includes("Admin") || u.roles?.includes("Lab"))
      .map((u) => {
        return {
          value: u.userID,
          label: `${u.firstName} ${u.lastName}`,
          phone_number: u.phone_number?.replace(/ /g, ""),
        };
      });
  }, [users]);

  const getSelectedTester = (onChangeTesterData) => {
    setTestCreate({
      ...newTestCreate,
      createdBy: onChangeTesterData.value,
      tester_name: onChangeTesterData.label,
      testerPhone: onChangeTesterData.phone_number,
    });
  };

  const triggerNewData = (data) => {
    const {
      match: { params },
      location: { state },
    } = props;
    if (data.id === state?.test?.id || data.id === params.testId) {
      if (!editButton) {
        setchangeData(data);
        setOpenDataChangeConfirmation(true);
      } else {
        setEditInfo(data);
        setDemographics(getDemoGraphics(data));
        setEmployee(getDemoGraphics(data));
        setTest(data);
      }
    } else {
      return;
    }
  };

  const downloadQRCode = async () => {
    const element = downloadRef.current;
    const canvas = await html2canvas(element);
    const pngUrl = canvas.toDataURL("image/png").replace("image/png", "image/octet-stream");
    let downloadLink = document.createElement("a");
    downloadLink.href = pngUrl;
    downloadLink.download = Employee ? `${Employee.firstName} ${Employee.lastName}.png` : `QRCode.png`;
    document.body.appendChild(downloadLink);
    downloadLink.click();
    document.body.removeChild(downloadLink);
  };

  // SETTING UP LISTNER
  useEffect(() => {
    if (changeDataCheck && appContext?.testObject && appContext.testObject?.opType === "UPDATE") {
      triggerNewData(appContext.testObject.element);
    }
  }, [appContext?.testObject]);

  useEffect(() => {
    getUserFromLocalDB();
  }, []);

  useEffect(() => {
    if (appContext?.clients?.length > 0) {
      const client = appContext.clients.find((f) => f.id === CONFIG.clientID);
      if (client && client.resultTypes) {
        setResultTypes([
          ...(client.resultTypes.results.map((m) => ({ value: m, label: m })) || []),
          { value: UN_RESULT, label: UN_RESULT },
        ]);
      }
    }
  }, [appContext.clients]);

  useEffect(() => {
    if (appContext.labs instanceof Array) {
      setLabs(appContext.labs);
    } else if (appContext.labs instanceof Object) {
      setLabs([appContext.labs]);
    }
    setSite(appContext.sites);
  }, [appContext.sites, appContext.labs]);

  // SETTING UP LISTNER
  useEffect(() => {
    if (changeDataCheck && appContext?.eventObject && appContext.eventObject?.opType === "UPDATE") {
      triggerNewData(appContext.eventObject.element);
    }
  }, [appContext?.eventObject]);

  const handleDataChangeConfirm = async (isConfirm) => {
    if (isConfirm) {
      setOpenDataChangeConfirmation(false);
      setEditInfo(changeData);
      setDemographics(getDemoGraphics(changeData));
      setEmployee(getDemoGraphics(changeData));
      setTestCreate(changeData);
      setTest(changeData);
    } else {
      setOpenDataChangeConfirmation(false);
    }
  };

  const handleChangeBarcodeNo = async (isConfirm) => {
    setChangeBarcodeConfirmation(false);
    if (isConfirm) {
      const res = await gqApi.validateAccessionNo(newTestCreate);

      if (!res) {
        appContext.showErrorMessage(`Accession No. ${newTestCreate.barcode} is already assigned. Please use the other`);
      } else {
        handleSave(true);
      }
    } else {
      handleSave(false);
    }
  };

  const getResultToShow = (testCreate) => {
    const { resultDetails, result } = testCreate;

    const resultColor = getResultColors(testCreate);

    if (resultDetails != null) {
      return { label: "View Detail", labelColor: resultColor };
    }

    const resultKey = result ? result.toLowerCase() : "-";
    return {
      label: result || "-",
      labelColor: resultColor,
    };
  };

  const resultToShow = useMemo(() => getResultToShow(newTestCreate), [newTestCreate]);

  const loadTestOptions = async () => {
    const options = await API.getTestTypesOptions();
    setTestOptions(options);
  };

  const setImageIfPathExists = async (filePath, setImageFunction) => {
    if (filePath) {
      const image = await API.getFileProfileStorage(filePath);
      setImageFunction(image);
    }
  };

  const getTest = async (id) => {
    try {
      setLoading(true);

      const [model] = await API.getRecordById(id, "test");
      const { employee = {}, employee_demographics, test_type } = model;

      const { vaccinationCardImage, externalTestImage, profileImage, autoRelease: empAutoRelease } = employee;

      const autoRelease = empAutoRelease || empAutoRelease === "1";

      const demoWithFormattedDOB = {
        ...employee_demographics,
        dob: moment(employee_demographics.dob, "MMDDYYYY").format("YYYY-MM-DD"),
      };

      setEditInfo(model);

      setTest(model);
      const formatedObj = {
        ...employee,
        ...demoWithFormattedDOB,
        vaccinationCardImage,
        externalTestImage,
        autoRelease,
      };
      setEmployee(formatedObj);
      setDefaultTestData({ ...formatedObj, test_type, result: model?.result, barcode: model?.barcode });

      await setImageIfPathExists(externalTestImage, setExternalTestImage);
      await setImageIfPathExists(profileImage, setProfileImage);
      await setImageIfPathExists(vaccinationCardImage, setVaccinationImage);
      const testNames = await loadReqFormTest({ clientID: model.clientID, getTestTypes: API.getTestTypes });

      setLoading(false);
      const optionList = await API.getGeneralSetting(TEST_SETTING_TYPES.SOURCE);
      setTestSources(optionList);
      setTestNames(testNames);
    } catch (err) {
      setLoading(false);
      console.log("Error:-", err.message);
    }
  };

  const {
    match: { params },
    location: { state },
  } = props;

  useEffect(async () => {
    setLoading(true);
    loadTestOptions();
    if (state?.test?.id) {
      getTest(state?.test?.id);
    } else {
      if (params.testId) {
        getTest(params.testId);
      } else {
        let testID = connectedIDByURL(window.location.pathname);
        if (testID?.testID != "") {
          await getTest(testID.testID);
        }
      }
    }
  }, []);

  useEffect(() => {
    if (appContext.user) setAllowToEditQuarantine(appContext.user.isSite() || appContext.user.isClient());
  }, [appContext.user]);

  useEffect(() => {
    if (editInfo && editInfo.employee_demographics) {
      setLoading(true);
      const emp = getDemoGraphics(editInfo);
      const list = countryListAllIsoData.find((f) => f.name === emp.country) || { code: "US" };
      setEmployee(emp);
      setTestCreate({ ...editInfo, employee_demographics: emp });
      setDemographics({ ...emp, isoCode: list.code });
      setLoading(false);
    }
  }, [editInfo]);

  useEffect(() => {
    const getSite = async (id) => {
      const model = await API.findSiteByID(id);
      model.length > 0 && setSite(model[0]);
    };

    const getSignature = async (test) => {
      const imgObject = [];

      try {
        const siteID = test.baseSiteID || test.siteID;
        const fileName = `${siteID}&&${test.employeeID}&&sig.png`;
        const sigt = await Storage.get(fileName);
        setSignature(sigt);
        imgObject.push({ title: "HIPPA Consent", isHIPPA: true, imgSrc: sigt });

        const sig = await Storage.get(`${test.employeeID}&&${siteID}&&ins-f.jpeg`);
        if (sig) imgObject.push({ title: "Insurance Card Front", isHIPPA: false, imgSrc: sig });
        const sig1 = await Storage.get(`${test.employeeID}&&${siteID}&&ins-b.jpeg`);
        if (sig1) imgObject.push({ title: "Insurance Card Back", isHIPPA: false, imgSrc: sig1 });
      } catch (error) {
        console.log("Image Error", error);
      }

      if (imgObject.length > 0) {
        setShowImages(imgObject);
      }
      setHippaConsent(true);
    };

    const getDocuments = async (test) => {
      try {
        const res = await API.fetchDocumentAPI({ testID: test.id, employeeID: test.employeeID });
        if (res && res.length > 0) {
          const filteredTests = res.filter((item) => {
            const isOrderIDNull = !item?.orderID;
            const isTestIDNull = !item?.testID;
            const isOrderIDMatch = item?.orderID === test.orderID;
            const isTestIDMatch = item?.testID === test.id;

            return isOrderIDMatch || isTestIDMatch || (isOrderIDNull && isTestIDNull);
          });
          setDocuments(filteredTests);
        }
      } catch (err) {
        console.log("Error:-", err);
      }
    };

    if (test) {
      setSite(getSite(test.siteID));

      getDocuments(test);
      getSignature(test);
    }

    setLoading(false);
  }, [test]);

  function calculate_age(dob) {
    const val = formatDateOfBirthDOB(dob);
    const years = moment().diff(val, "years", false);
    return years;
  }

  const formatIDNo = () => {
    if (calculate_age(demographics?.dob) < 18) {
      const id = demographics?.idNumber;
      if (id && id.length > 5) {
        return `${id.substring(0, 3)}-${id.substring(3, 5)}-${id.substring(5)}`;
      }
      if (id && id.length > 3) {
        return `${id.substring(0, 3)}-${id.substring(3)}`;
      }
      return demographics?.idNumber;
    }
    return demographics?.idNumber;
  };

  const displayPdf = async (test) => {
    appContext.showTestResult(test);
  };

  const handleSave = async (isConfirm) => {
    let phoneNo = "";
    let countryCode = "";
    if (!Employee) return;
    if (!Employee.firstName) {
      setError("First name is required");
      return;
    }
    if (!isValidName(Employee.firstName)) {
      setError("First name is invalid must contains at least 2 letters and Name should be realistic");
      return;
    }
    if (!Employee.lastName) {
      setError("Last name is required");
      return;
    }
    if (!isValidName(Employee.lastName)) {
      setError("Last name is invalid must contains at least 2 letters and Name should be realistic");
      return;
    }

    if (!Employee.email) {
      setError("Email name is required");
      return;
    }
    if (!isValidEmail(Employee.email)) {
      setError("Invalid Email");
      return;
    }

    if (!Employee.phoneNumber) {
      setError("Phone name is required");
      return;
    }

    // FOR PHONE CHECK COUTRY CODE FOR INTERNATIONAL RECORD VERIFICATION
    const phone = formatPhoneNumberIntl(Employee.phoneNumber);
    const phoneArr = phone.split(" ");
    // check Country code exist

    if (phoneArr[0].includes("+")) {
      let [cc, pNo] = getPhoneNo(Employee.phoneNumber, phoneArr[0]);
      phoneNo = pNo;
      countryCode = cc;
    } else {
      phoneNo = Employee.phoneNumber;
      countryCode = Employee.countryCode ? Employee.countryCode : "+1";
    }

    if (phoneError || !isValidPhoneWithCode(`${countryCode}${phoneNo}`)) {
      setError("Invalid Phone Number");
      return;
    }
    if (!Employee.dob) {
      setError("DOB is required");
      return;
    }
    if (Employee.dob === "Invalid date") {
      setError("Invalid Date of Birth");
      return;
    }

    if (!zipCodeValidationUS(Employee.zip)) {
      setError("Invalid Postal Code");
      return;
    }

    if (!Employee.idNumber) {
      setError(`${calculate_age(Employee.dob) < 18 ? "SSN" : "ID Number"} is required`);
      return;
    }
    if (!isValidIdNum(Employee.idNumber)) {
      setError(`Invalid ${calculate_age(Employee.dob) < 18 ? "SSN" : "ID Number"} must contains at least 5 characters`);
      return;
    }

    if (!newTestCreate.clientID) {
      setError("Client is required");
      return;
    }
    if (!newTestCreate.siteID) {
      setError("Site is required");
      return;
    }

    if (!newTestCreate.labID) {
      setError("Lab is required");
      return;
    }

    if (!newTestCreate.test_type || newTestCreate.test_type == "" || newTestCreate.test_type == "undefined") {
      setError("Test Type is required");
      return;
    }

    if (newTestCreate.barcode && newTestCreate.barcode.length !== 10) {
      setError("Barcode is Invalid e.g. (YYMMDD0001)");
      return;
    }
    if (
      newTestCreate.createdAt &&
      newTestCreate.resultDateTime &&
      moment(newTestCreate.resultDateTime).isBefore(moment(newTestCreate.createdAt))
    ) {
      setError("Result date time should be after date collected");
      return;
    }
    if (
      newTestCreate.createdAt &&
      newTestCreate.receivedDate &&
      moment(newTestCreate.receivedDate).isBefore(moment(newTestCreate.createdAt))
    ) {
      setError("Received date time should be after date collected");
      return;
    }
    setLoading(true);
    setchangeDataCheck(false);
    const testPrevObject = await API.getRecordById(test.id, "test");
    const testModal = testPrevObject[0];
    const testVersionNo = testModal._version;
    const models = await API.getRecordById(test.employeeID, "employee");
    const versionNo = models[0]?._version || null;
    const fileName = `${Employee.mdID ? Employee.mdID : test.employeeID}/${test.employeeID}-${
      Employee.phoneNumber
    }-${moment().toISOString()}`;

    // Vacination Image
    let vaccinationFileName = "";
    if (typeof vaccinationImage === "object") {
      vaccinationFileName = await API.saveFileExternalStorage(
        phoneNo,
        test.employeeID,
        IMAGE_UPLOAD_TYPE.VacinationCard,
        vaccinationImage
      );
    }

    // External Test Image
    let externalImageFileName = "";
    if (typeof externalTestImage === "object") {
      externalImageFileName = await API.saveFileExternalStorage(
        phoneNo,
        test.employeeID,
        IMAGE_UPLOAD_TYPE.ExternalTest,
        externalTestImage
      );
    }

    const emp = {
      ...Employee,
      countryCode: countryCode,
      vaccinationCardImage: vaccinationFileName ? vaccinationFileName : Employee.vaccinationCardImage,
      externalTestImage: externalImageFileName ? externalImageFileName : Employee.externalTestImage,
      othercomment: Employee.symptoms?.includes(SYMPTOMS.other) ? Employee.othercomment : "",
      _version: versionNo,
      updatedBy: appContext.user.sub,
      updatedByName: appContext.user.name,
      phoneNumber: phoneNo,
    };

    if (Employee?.autoRelease) {
      const pendingTestList = appContext.pendingTests?.filter((f) => f.employeeID === test.employeeID);
      const paramsData = [];
      pendingTestList.forEach((element) => {
        paramsData.push({
          id: element.id,
          status: "Processed",
          timerStatus: "Processed",
          sequenceNo: element.sequenceNo,
          invalidResultStatus: 1,
        });
      });
      try {
        await GQAPI.releaseTest(paramsData);
      } catch (err) {
        console.log("Error:-", err.message);
      }
    }
    try {
      if (isConfirm && newTestCreate.barcode !== editInfo.barcode) {
        await GQAPI.addTestLogs({
          ids: [{ id: test.id, sequenceNo: test.sequenceNo }],
          oldValue: editInfo.barcode,
          newValue: newTestCreate.barcode,
          userID: appContext.user?.sub,
          userName: appContext.user?.name,
          slug: "AccessionNoChanged",
        });
        newTestCreate.labelPrinted = 2;
      }
      const newTestObj = { ...newTestCreate, ...(!isConfirm && { barcode: editInfo.barcode }) };
      if (newTestCreate.result === UN_RESULT && CONFIG.isWhiteLabel) {
        Object.assign(newTestObj, { result: "", status: "Pending" });
      }

      if (!test.result && newTestObj.result && !newTestObj.resultDateTime) {
        Object.assign(newTestObj, { resultDateTime: moment().toISOString() });
      }

      // const res = ChangeObject(emp, demographics, newTestObj, testModal);

      const fieldsName = {
        "employee_demographics.firstName": "First Name",
        "employee_demographics.lastName": "Last Name",
        "employee_demographics.dob": "DOB",
        "employee_demographics.email": "Email",
        "employee_demographics.city": "City",
        "employee_demographics.idNumber": "SSN",
        "employee_demographics.phoneNumber": "Phone Number",
        "employee_demographics.state": "State",
        "employee_demographics.street": "Address",
        "employee_demographics.street2": "Apt/Unit#",
        "employee_demographics.zip": "Zip",
        "employee_demographics.insurance_name": "Payer Name",
        "employee_demographics.insurance_number": "Member ID",
        "employee_demographics.insuranceGroupId": "Group ID",
        "employee_demographics.clinicalcomment": "Clinical Comment",
        "employee_demographics.comment": "Lab Comment",
        test_type: "Test Name",
        tester_name: "Collected By",
        receivedDate: "Received Date",
        scanByLabUserName: "Received By",
        releaseDate: "Release Date",
        verifiedByName: "Released By",
        resultDateTime: "Result Date Time",
        StampByName: "Resulted By",
        testerPhone: "Tester Phone",
        sendToName: "Reference Lab",
      };

      const chngObj = createChangeObjForLogs({
        oldRecord: testPrevObject?.[0],
        newRecord: {
          ...newTestObj,
          employee_demographics: {
            ...newTestObj.employee_demographics,
            ...emp,
            dob: moment(emp.dob).format("MMDDYYYY"),
          },
        },
        fieldsName,
      });

      let logs = null;

      if (chngObj.length > 0) {
        const ids = [{ id: test.id, sequenceNo: test.sequenceNo }];
        logs = {
          ids: ids,
          changeObject: chngObj,
          userID: appContext.user?.sub,
          userName: appContext.user?.name,
          slug: "Test Updated",
        };
      }

      const srNo = JSON.parse(newTestObj.sr_no);

      if (newTestObj.receivedDate) {
        srNo[0].date = moment(newTestObj.receivedDate).toISOString();
      }

      if (newTestObj.receivedBy) {
        srNo[0].name = newTestObj.receivedBy;
      }

      newTestObj.sr_no = JSON.stringify(srNo);

      const obj = {
        ...newTestObj,
        employee_demographics: {
          ...newTestObj.employee_demographics,
          ...emp,
          ...(newTestObj.testTypeID && { testTypeID: newTestObj.testTypeID }),
        },
        _version: testVersionNo,
        createdAt: moment(newTestObj.createdAt).toISOString(),
      };

      const finalEmpolyeeData = {
        ...Employee,
        test_type: newTestCreate.test_type,
        result: newTestCreate?.result,
        barcode: newTestCreate?.barcode,
      };

      const comapredData = compareTestdReturnNewValues(defaultTestData, finalEmpolyeeData);

      const formattedOrder1 = convertTestToOrderObj(comapredData);

      const prevTestType = prevTestTypeKey(defaultTestData, obj);

      console.log("prevTestType,", obj);

      const updateTest = await API.updateCompleteCrewInfo({
        crew: obj,
        user: appContext.user,
        isConfirm: isConfirm,
        chngObj,
        prevTestType,
      });

      let testStatus =
        testModal?.sendToID && !obj?.sendToID ? "Accepted" : !testModal?.sendToID && obj?.sendToID ? "sendOut" : null;

      if (testStatus) {
        await API.updateOrderTestStatus({
          orderID: testModal.orderID,
          testID: testModal.id,
          status: testStatus,
          sendToID: obj.sendToID,
          sendToName: obj.sendToName,
        });
      }

      if (logs) await GQAPI.addTestLogs(logs);

      setDemographics(getDemoGraphics(updateTest));
      setEditInfo(updateTest);
      setTest(updateTest);
      setEmployee(getDemoGraphics(updateTest));
      setTestCreate(updateTest);
      setEditButton(true);

      switch (location?.state?.type) {
        case "pendingRelease":
          appContext.resetPendingTests();
          break;
        case "inconclusiveTest":
          appContext.resetInvalidTests();
          break;
      }
      appContext.showSuccessMessage("Test updated Successfully");

      const {
        match: { params },
      } = props;
      if (params.employeeId) {
        getTest(params.employeeId);
      }
      if (params.testId) {
        getTest(params.testId);
      }

      setLoading(false);
    } catch (err) {
      console.log("Error in crew save", err);
      appContext.showErrorMessage(err.message);
      setLoading(false);
    }
    setTimeout(() => {
      setchangeDataCheck(true);
    }, 60000);
  };

  const handleCancelButton = async () => {
    if (!Employee) {
      setEditButton(true);
      return;
    }
    let models = await API.getRecordById(test.employeeID, "employee");
    let vacinatedFilePath = models[0]?.vaccinationCardImage;
    let externalTestFilePath = models[0]?.externalTestImage;
    let profileImageFilePath = models[0]?.profileImage;

    if (!profileImageFilePath) {
      setProfileImage(profileImageDefault);
    }
    if (!externalTestFilePath) {
      setExternalTestImage("");
    }
    if (!vacinatedFilePath) {
      setVaccinationImage("");
    }
    setEmployee(getDemoGraphics(editInfo));
    setTestCreate(editInfo);

    setEditButton(true);
  };

  const emailTest = async (t) => {
    setLoading(true);
    const test = formatTest(t);
    const params = [
      {
        id: test.id,
        sequenceNo: test.sequenceNo,
      },
    ];
    try {
      setLoading(true);
      await appContext.sendEmailToCrew(params, "Email");
      appContext.showSuccessMessage("Email has been sent successfully");
      setLoading(false);
    } catch (err) {
      console.log("err", err);
      setLoading(false);
    }
  };

  const handleSaveQuarantine = async (obj) => {
    try {
      await API.updateQuarantineData(test.id, obj.startDate, obj.endDate);
      getTest(test.id);
      window.location.reload();
      appContext.showSuccessMessage("Quarantine dates changed successfully");
    } catch (err) {
      appContext.showErrorMessage(err.message);
    }
  };

  const dataOfBirthParse = (dob) => {
    if (dob && dob !== "Invalid date") {
      return formatDateOfBirthDOB(dob);
    }
    return moment().format("MM/DD/YYYY");
  };

  const handleCloseCreateTest = () => {
    setTestCreate(_.cloneDeep(TEST_EMPTY_OBJECT));
    setOpenCreator(false);
  };

  const updateTest = async (updatedTestData) => {
    updatedTestData.id = test?.id;
    updatedTestData._version = test?._version;
    let testData = {
      ...updatedTestData,
      submittedByName: appContext?.user?.name,
      createdBy: appContext?.user?.sub,
      barcode: test?.barcode,
    };

    const updateTest = await API.updateTestDataV1(testData);

    setLoading(false);

    if (updateTest) {
      appContext.showSuccessMessage("Test updated successfully");
      handleCloseCreateTest();
    } else {
      appContext.showErrorMessage("Test can't be updated");
    }
  };

  const openAssignResultModal = () => {
    let resultDetails =
      typeof newTestCreate?.resultDetails === "string"
        ? JSON.parse(newTestCreate.resultDetails)
        : newTestCreate.resultDetails;

    dispatch(
      setSelectedTestForResult([
        {
          ...newTestCreate,
          resultDetails,
        },
      ])
    );
  };

  const handleUpdateAddress = (val, flag) => {
    if (!flag) {
      setEmployee({ ...Employee, street: val });
    } else {
      const addressObj = {
        street: val.street,
        country: val.country,
        city: val.city,
        zipcode: val.zipcode,
        zip: val.zipcode,
        county: val.county,
        state: val.state,
        isoCode: val.isoCode,
      };
      setEmployee({ ...Employee, ...addressObj });
    }
  };

  const custodyButtonStatus = (result) => {
    const userRoles = appContext.user?.roles || [];
    const isUserAuthroized = EDIT_PERMISSION_ROLE.some((role) => userRoles.includes(role));

    if (result) return newTestCreate.result || editButton || !isUserAuthroized;
    return editButton || !isUserAuthroized;
  };

  const getTesterDetails = () => {
    if (newTestCreate.createdBy) {
      const data = usersData.find((f) => f.value === newTestCreate.createdBy);
      if (data) {
        return data;
      }
    }
    return { label: newTestCreate.tester_name, value: newTestCreate.tester_name };
  };

  const getVerifiedDetails = () => {
    if (newTestCreate.verifiedBy) {
      const data = usersData.find((f) => f.value === newTestCreate.verifiedBy);
      if (data) {
        return data;
      }
    }
    return { label: newTestCreate.verifiedByName, value: newTestCreate.verifiedBy };
  };

  const showReqForm = ({ test, signature, testNames }) => {
    if (
      test.labID === LAB_ID.alta ||
      test.labID === LAB_ID.caliber ||
      test.labID === LAB_ID.discovery ||
      test.labID === LAB_ID.seroclinix ||
      test.labID === LAB_ID.inc ||
      isLocalhost()
    ) {
      dispatch(showResultReqForm(test));
    } else {
      appContext.showRequisitionForm({ test, signature, testNames });
    }
  };

  const handelChangeReferenceLab = (event) => {
    const { label = null, value = null } = event || {};

    setTestCreate((previousValue) => ({
      ...previousValue,
      sendToName: label,
      sendToID: value,
    }));
  };

  return !loading ? (
    <>
      <Container fluid className="profile-container">
        <SweetAlert show={showAlert} success title="Success" onConfirm={hideAlert}>
          Test Released
        </SweetAlert>
        <SweetAlert
          show={showSubmitAlert}
          success
          title="Success"
          onConfirm={() => {
            window.history.back();
            setShowSubmitAlert(false);
          }}
        >
          Test Submitted Successfully.
        </SweetAlert>
        <Row className="crew-member-profile">
          <Col md="12">
            <Card className="member-profile-card">
              <Card.Header className="d-flex align-items-center justify-content-between">
                <Card.Title as="h4" className="w-100 d-flex justify-content-between mb-0">
                  <div>
                    Test Details
                    {calculate_age(demographics?.dob) < 18 ? (
                      <>
                        {" "}
                        - <span className="crew-minor-text"> MINOR</span>
                      </>
                    ) : null}
                    {CONFIG.isWhiteLabel
                      ? ` - ${newTestCreate?.barcode || ""}`
                      : newTestCreate.sequenceNo
                      ? ` - ${newTestCreate.sequenceNo}`
                      : ""}
                  </div>
                  {newTestCreate.barcode && (
                    <>
                      <div className="d-flex align-items-center  column-gap-50">
                        <div className="d-flex align-items-center qr-code-download w-75">
                          {/* <i className="fas fa-download"></i> */}
                          <span className="mx-2">Patient QR Code:</span>
                          <img
                            src={QrCodeDownloadIcon}
                            alt="qr"
                            className="cursor-pointer"
                            width="30"
                            onClick={downloadQRCode}
                          />

                          <div
                            className="qrCodeWrapper text-center"
                            ref={downloadRef}
                            style={{ position: "absolute", left: "-9999px" }}
                          >
                            <div className="d-flex justify-content-center align-items-center">
                              <QRCode
                                id="qr-gen-view"
                                ecLevel={"H"}
                                className="text-center px-4"
                                value={JSON.stringify(qrResponseJOSN(Employee, Employee?.id) || "")}
                                enableCORS={true}
                                logoImage={whiteLabelLogo}
                                size="250"
                                logoHeight={"50"}
                                logoWidth={"50"}
                                fgColor={"#000"}
                                bgColor={"#fff"}
                              />
                            </div>
                            <div className="text-center fs-4 fw-bold qrSubdiv">{`${toTitleCase(
                              Employee.firstName
                            )} ${toTitleCase(Employee.lastName)}`}</div>
                          </div>
                        </div>

                        <CloseButton
                          onClick={() => {
                            if (history.length > 1) {
                              history.goBack();
                            } else {
                              history.push("/admin");
                            }
                          }}
                          variant="white"
                        />
                      </div>
                    </>
                  )}
                </Card.Title>
              </Card.Header>
              <Card.Body className="card-text py-3" style={{ backgroundColor: "#f6fbff" }}>
                <Form className="crew-member-profile">
                  <Row>
                    <Col md="12">
                      <div className="testAdmin createClientsWrapper">
                        <span className="mx-3 fs-5 fw-bold">Demographic Information</span>
                        <div className="crew-profile-info section-testDetails">
                          <Form.Group className="form-group-wrapper">
                            <label className="modalLineHeaders mt-0 text-capitalize">First Name</label>
                            <Form.Control
                              readOnly={editButton}
                              className={`${!editButton ? "modalInput" : ""}`}
                              onChange={(e) => setEmployee({ ...Employee, firstName: getValidName(e.target.value) })}
                              value={Employee?.firstName}
                              defaultValue={demographics?.firstName}
                              placeholder={`${!editButton ? "Enter First Name " : ""}`}
                              type="text"
                            />
                          </Form.Group>
                          <Form.Group className="form-group-wrapper">
                            <label className="modalLineHeaders mt-0 text-capitalize">Last Name</label>
                            <Form.Control
                              readOnly={editButton}
                              className={`${!editButton ? "modalInput" : ""}`}
                              onChange={(e) => setEmployee({ ...Employee, lastName: getValidName(e.target.value) })}
                              value={Employee?.lastName}
                              defaultValue={demographics?.lastName}
                              placeholder={`${!editButton ? "Enter Last Name " : ""}`}
                              type="text"
                            />
                          </Form.Group>

                          <Form.Group className="form-group-wrapper">
                            <label className="modalLineHeaders mt-0 text-capitalize">Date of Birth</label>
                            {editButton ? (
                              <Form.Control
                                readOnly={editButton}
                                value={formatDateOfBirthDOB(Employee?.dob)}
                                defaultValue={formatDateOfBirthDOB(demographics?.dob)}
                                placeholder="Date of Birth"
                                type="text"
                              />
                            ) : (
                              <div>
                                <SingleDatePicker
                                  onApply={(event, picker) => {
                                    setEmployee({ ...Employee, dob: moment(picker.startDate).format("YYYY-MM-DD") });
                                  }}
                                  value={dataOfBirthParse(Employee?.dob)}
                                  startDate={dataOfBirthParse(Employee?.dob)}
                                />
                              </div>
                            )}
                          </Form.Group>
                          <Form.Group className="form-group-wrapper">
                            <label className="modalLineHeaders mt-0 text-capitalize">Phone Number</label>
                            {editButton ? (
                              <Form.Control
                                className={`${!editButton ? "modalInput underlinedText" : "underlinedText"}`}
                                onMouseOver={(e) => {
                                  editButton ? (e.target.style.cursor = "pointer") : (e.target.style.cursor = "auto");
                                  e.target.style.textDecoration = "underline";
                                }}
                                onClick={() =>
                                  editButton
                                    ? Employee?.phoneNumber && window.open(`tel:+1${Employee.phoneNumber}`)
                                    : false
                                }
                                onChange={(e) => setEmployee({ ...Employee, phoneNumber: setPhoneNo(e.target.value) })}
                                readOnly
                                maxLength={15}
                                value={formatPhoneNumber(Employee?.phoneNumber, Employee?.countryCode || "+1", true)}
                                defaultValue={formatPhoneNumber(
                                  Employee?.phoneNumber,
                                  Employee?.countryCode || "+1",
                                  true
                                )}
                                placeholder={`${!editButton ? "Enter Phone Number " : ""}`}
                                type="text"
                              />
                            ) : (
                              <PhoneNoInput
                                error={setPhoneError}
                                handleChange={(e) => {
                                  setEmployee({ ...Employee, phoneNumber: e });
                                }}
                                value={formatPhoneWithOutSpaces(Employee?.phoneNumber, Employee?.countryCode || "+1")}
                                cssCode={"crewPhoneInput w-100"}
                              />
                            )}
                          </Form.Group>

                          <Form.Group className="form-group-wrapper">
                            <label className="modalLineHeaders mt-0 text-capitalize">Email Address</label>
                            <Form.Control
                              className={`${!editButton ? "modalInput underlinedText" : "underlinedText"}`}
                              onMouseOver={(e) => {
                                editButton ? (e.target.style.cursor = "pointer") : (e.target.style.cursor = "auto");
                                e.target.style.textDecoration = "underline";
                              }}
                              onClick={() =>
                                editButton ? Employee?.email && window.open(`mailto:${Employee.email}`) : false
                              }
                              onChange={(e) => setEmployee({ ...Employee, email: e.target.value })}
                              value={Employee?.email}
                              readOnly={editButton}
                              defaultValue={test?.email}
                              placeholder={`${!editButton ? "Enter Email " : ""}`}
                              type="email"
                            />
                          </Form.Group>
                          <Form.Group className="form-group-wrapper" readOnly={editButton} plaintext>
                            {calculate_age(demographics?.dob) > 18 ? (
                              <label className="modalLineHeaders mt-0 text-capitalize">ID Number</label>
                            ) : (
                              <label>SSN</label>
                            )}
                            <Form.Control
                              readOnly={editButton}
                              className={`${!editButton ? "modalInput" : ""}`}
                              value={Employee?.idNumber}
                              onChange={(e) => setEmployee({ ...Employee, idNumber: e.target.value })}
                              defaultValue={formatIDNo()}
                              placeholder="ID Number"
                              type="text"
                            />
                          </Form.Group>

                          <Form.Group className="form-group-wrapper">
                            <label className="mt-0 modalLineHeaders text-capitalize">Address</label>

                            <AutoCompleteAddress
                              readOnly={editButton}
                              value={Employee?.street}
                              handleChange={handleUpdateAddress}
                            />
                          </Form.Group>
                          <Form.Group className="form-group-wrapper">
                            <label className="mt-0 modalLineHeaders text-capitalize">Address 2</label>
                            <Form.Control
                              className={`${!editButton ? "modalInput" : ""}`}
                              readOnly={editButton}
                              onChange={(e) => setEmployee({ ...Employee, street2: e.target.value })}
                              value={Employee?.street2 || ""}
                              defaultValue={demographics?.street2 || ""}
                              placeholder={`${!editButton ? "Enter Address 2 " : ""}`}
                              type="text"
                            />
                          </Form.Group>

                          <Form.Group className="form-group-wrapper">
                            <label className="mt-0 modalLineHeaders text-capitalize">City</label>
                            <Form.Control
                              readOnly={editButton}
                              className={`${!editButton ? "modalInput" : ""}`}
                              onChange={(e) => setEmployee({ ...Employee, city: e.target.value })}
                              value={Employee?.city}
                              defaultValue={demographics?.city}
                              placeholder={`${!editButton ? "Enter City" : ""}`}
                              type="text"
                            />
                          </Form.Group>
                          <Form.Group className="form-group-wrapper">
                            <label className="modalLineHeaders mt-0 text-capitalize">State</label>
                            <Form.Control
                              className={`${!editButton ? "modalInput" : ""}`}
                              readOnly={editButton}
                              onChange={(e) => setEmployee({ ...Employee, state: e.target.value })}
                              value={Employee?.state}
                              defaultValue={demographics?.state}
                              maxLength={35}
                              placeholder={`${!editButton ? "Enter Country" : ""}`}
                              type="text"
                            />
                          </Form.Group>

                          <Form.Group className="form-group-wrapper">
                            <label className="modalLineHeaders mt-0 text-capitalize">Postal Code</label>
                            <Form.Control
                              className={`${!editButton ? "modalInput" : ""}`}
                              onChange={(e) => setEmployee({ ...Employee, zip: e.target.value })}
                              readOnly={editButton}
                              value={Employee?.isoCode === "US" ? formatZip(Employee?.zip) : Employee?.zip}
                              defaultValue={Employee?.isoCode === "US" ? formatZip(Employee?.zip) : Employee?.zip}
                              placeholder={`${!editButton ? "Enter Postal Code " : ""}`}
                              maxLength={10}
                              type="text"
                            />
                          </Form.Group>
                        </div>
                        <span className="mx-3 fs-5 fw-bold">Insurance Information</span>
                        <div className="crew-profile-info-cl-3  section-testDetails">
                          <div className="form-group-wrapper">
                            <label className="modalLineHeaders  mt-0 text-capitalize">Payer </label>
                            <Select
                              className="w-100 roleSelector"
                              options={INSURANCE_PROVIDER}
                              blurInputOnSelect={true}
                              isDisabled={editButton}
                              defaultValue={null}
                              menuPlacement="auto"
                              placeholder="Select Payer"
                              value={
                                Employee?.insurance_name
                                  ? { label: Employee?.insurance_name, value: Employee?.insuranceCompanyCode }
                                  : null
                              }
                              onChange={(e) => {
                                setEmployee({ ...Employee, insuranceCompanyCode: e.value, insurance_name: e.label });
                              }}
                            />
                          </div>
                          {!CUSTOM_INS.includes(Employee?.insuranceCompanyCode) && (
                            <>
                              <Form.Group className="form-group-wrapper">
                                <label className="mt-0 modalLineHeaders text-capitalize">Member ID</label>
                                <Form.Control
                                  readOnly={editButton}
                                  className={`${!editButton ? "modalInput" : ""}`}
                                  onChange={(e) => setEmployee({ ...Employee, insurance_number: e.target.value })}
                                  value={Employee?.insurance_number}
                                  placeholder={`${!editButton ? "Enter Member ID" : ""}`}
                                  type="text"
                                />
                              </Form.Group>

                              <Form.Group className="form-group-wrapper">
                                <label className="mt-0 modalLineHeaders text-capitalize">Group ID</label>
                                <Form.Control
                                  readOnly={editButton}
                                  className={`${!editButton ? "modalInput" : ""}`}
                                  onChange={(e) => setEmployee({ ...Employee, insuranceGroupId: e.target.value })}
                                  value={Employee?.insuranceGroupId}
                                  placeholder={`${!editButton ? "Enter Group ID" : ""}`}
                                  type="text"
                                />
                              </Form.Group>
                            </>
                          )}
                        </div>
                        <span className="mx-3 fs-5 fw-bold">Chain of Custody</span>
                        <div className="section-testDetails w-100">
                          <div className="crew-profile-info">
                            <Form.Group className="form-group-wrapper">
                              <label className="modalLineHeaders mt-0 text-capitalize">Date Collected</label>
                              <Form.Control
                                type="datetime-local"
                                readOnly={custodyButtonStatus()}
                                placeholder="Date Collected"
                                value={
                                  newTestCreate?.createdAt
                                    ? moment(newTestCreate?.createdAt).format("YYYY-MM-DDTHH:mm")
                                    : ""
                                }
                                onChange={(e) => setTestCreate({ ...newTestCreate, createdAt: e.target.value })}
                              />
                            </Form.Group>

                            <Form.Group className="form-group-wrapper">
                              <label className="modalLineHeaders mt-0 text-capitalize">Date Received</label>
                              <Form.Control
                                type="datetime-local"
                                readOnly={custodyButtonStatus()}
                                placeholder="Date Received"
                                value={getRecviedDateFromSrNo(newTestCreate)}
                                onChange={(e) => setTestCreate({ ...newTestCreate, receivedDate: e.target.value })}
                              />
                            </Form.Group>
                            <Form.Group className="form-group-wrapper">
                              <label className="modalLineHeaders mt-0 text-capitalize">Date Resulted</label>
                              <Form.Control
                                type="datetime-local"
                                readOnly={custodyButtonStatus()}
                                placeholder="Result Date"
                                value={
                                  newTestCreate?.result
                                    ? newTestCreate?.resultDateTime
                                      ? moment(newTestCreate?.resultDateTime).format("YYYY-MM-DDTHH:mm")
                                      : newTestCreate?.resultDate
                                      ? moment(newTestCreate?.resultDate).format("YYYY-MM-DDTHH:mm")
                                      : moment(newTestCreate?.updatedAt).format("YYYY-MM-DDTHH:mm")
                                    : ""
                                }
                                onChange={(e) => setTestCreate({ ...newTestCreate, resultDateTime: e.target.value })}
                              />
                            </Form.Group>
                            <Form.Group className="form-group-wrapper">
                              <label className="modalLineHeaders mt-0 text-capitalize">Date Released </label>
                              <Form.Control
                                type="datetime-local"
                                readOnly={newTestCreate?.releaseStatus !== "Confirmed" || editButton}
                                placeholder="Date Released"
                                value={
                                  newTestCreate?.releaseDate
                                    ? moment(newTestCreate?.releaseDate).format("YYYY-MM-DDTHH:mm")
                                    : ""
                                }
                                onChange={(e) => setTestCreate({ ...newTestCreate, releaseDate: e.target.value })}
                              />
                            </Form.Group>
                          </div>
                          <div className="crew-profile-info ">
                            <div className="form-group-wrapper">
                              <label className="modalLineHeaders  mt-0 text-capitalize">Collected By </label>
                              <Select
                                className="w-100 roleSelector"
                                options={usersData}
                                isDisabled={custodyButtonStatus()}
                                blurInputOnSelect={true}
                                defaultValue={null}
                                menuPlacement="auto"
                                //value={getSelectedTester()}
                                placeholder="Select Collected By"
                                value={getTesterDetails()}
                                onChange={(e) => getSelectedTester(e)}
                              />
                            </div>
                            <div className="form-group-wrapper">
                              <label className="modalLineHeaders  mt-0 text-capitalize">Received By</label>
                              <Select
                                className="w-100 roleSelector"
                                options={usersData}
                                isDisabled={custodyButtonStatus()}
                                blurInputOnSelect={true}
                                defaultValue={null}
                                menuPlacement="auto"
                                placeholder="Select Received By"
                                value={
                                  newTestCreate.scanByLabUserName
                                    ? {
                                        label: newTestCreate.scanByLabUserName,
                                        value: newTestCreate.scanByLabUserName,
                                      }
                                    : null
                                }
                                onChange={(e) =>
                                  setTestCreate({
                                    ...newTestCreate,
                                    scanByLabUserName: e.label,
                                    scanByLabUserID: e.value,
                                  })
                                }
                              />
                            </div>

                            <div className="form-group-wrapper">
                              <label className="modalLineHeaders  mt-0 text-capitalize">Resulted By</label>
                              <Select
                                className="w-100 roleSelector"
                                options={usersData}
                                isDisabled={custodyButtonStatus()}
                                blurInputOnSelect={true}
                                defaultValue={null}
                                menuPlacement="auto"
                                placeholder="Select Resulted By"
                                value={
                                  newTestCreate.StampByName
                                    ? { label: newTestCreate.StampByName, value: newTestCreate.StampByName }
                                    : null
                                }
                                onChange={(e) => setTestCreate({ ...newTestCreate, StampByName: e.label })}
                              />
                            </div>
                            <div className="form-group-wrapper">
                              <label className="modalLineHeaders  mt-0 text-capitalize">Released By </label>
                              <Select
                                className="w-100 roleSelector"
                                options={usersData}
                                isDisabled={newTestCreate?.releaseStatus !== "Confirmed" || editButton}
                                blurInputOnSelect={true}
                                defaultValue={null}
                                menuPlacement="auto"
                                //value={getSelectedTester()}
                                placeholder="Select Verified By"
                                value={getVerifiedDetails()}
                                onChange={(e) => {
                                  setTestCreate({
                                    ...newTestCreate,
                                    verifiedBy: e.value,
                                    verifiedByName: e.label,
                                  });
                                }}
                              />
                            </div>
                            <div className="form-group-wrapper">
                              <label className="modalLineHeaders mt-0 text-capitalize">Custody Status</label>
                              <input
                                className="modalInput w-100"
                                style={{
                                  color: newTestCreate?.result
                                    ? "green"
                                    : GET_CUST_COLOR[getValidDep(newTestCreate.pcrStatus)] || "",
                                }}
                                readOnly
                                value={newTestCreate?.result ? RECEIVED_BYLAB : getValidDep(newTestCreate.pcrStatus)}
                              />
                            </div>

                            <div className="form-group-wrapper">
                              <label className="modalLineHeaders  mt-0 text-capitalize">Reference Lab:</label>

                              <Select
                                options={labOptions || []}
                                blurInputOnSelect={true}
                                isDisabled={editButton}
                                menuPlacement="auto"
                                className="w-100 siteSelector"
                                placeholder="Select Reference Lab"
                                isClearable={true}
                                value={{
                                  label: newTestCreate?.sendToName,
                                  value: newTestCreate?.sendToID,
                                }}
                                onChange={handelChangeReferenceLab}
                                // components={{ ValueContainer: ReactSelectCustomContainer }}
                              />
                            </div>
                          </div>
                        </div>
                        <span className="mx-3 fs-5 fw-bold">Test Details</span>
                        <div className="crew-profile-info-cl-3  section-testDetails">
                          <div className="form-group-wrapper">
                            <label className="modalLineHeaders  mt-0 text-capitalize">Test Name </label>
                            <Select
                              className="w-100 roleSelector"
                              options={testOptions}
                              blurInputOnSelect={true}
                              isDisabled={editButton || newTestCreate?.result}
                              defaultValue={null}
                              menuPlacement="auto"
                              placeholder="Test Name"
                              value={
                                newTestCreate.test_type
                                  ? testOptions?.find((f) => f.value === newTestCreate.test_type)
                                  : null
                              }
                              onChange={(e) =>
                                setTestCreate({ ...newTestCreate, testType: e, test_type: e.label, testTypeID: e.id })
                              }
                            />
                          </div>

                          <div className="form-group-wrapper">
                            <label className="modalLineHeaders  mt-0 text-capitalize">Accession Number</label>
                            {editButton ? (
                              <input
                                className="modalInput w-100"
                                readOnly={editButton}
                                placeholder="Accession Number"
                                value={newTestCreate?.barcode}
                                onChange={(e) => {
                                  setTestCreate({
                                    ...newTestCreate,
                                    barcode: e.target.value,
                                  });
                                }}
                              />
                            ) : (
                              <div className="merged-input-container">
                                <input
                                  className="merged-input"
                                  style={{ width: "22%" }}
                                  readOnly
                                  value={newTestCreate?.barcode?.slice(0, 6)}
                                />
                                <input
                                  className="merged-input"
                                  style={{ width: "78%" }}
                                  readOnly={test?.result}
                                  placeholder="Barcode"
                                  value={newTestCreate?.barcode.slice(6)}
                                  maxLength={"4"}
                                  onChange={(e) => {
                                    setTestCreate({
                                      ...newTestCreate,
                                      barcode: `${newTestCreate?.barcode?.slice(0, 6)}${e.target.value.replace(
                                        /[^0-9]/g,
                                        ""
                                      )}`,
                                    });
                                  }}
                                />
                              </div>
                            )}
                          </div>
                          <div className="form-group-wrapper">
                            <label className="modalLineHeaders  mt-0 text-capitalize">Result</label>
                            {editButton ? (
                              <>
                                <span
                                  className="vaccination-status pl-3 cursor-pointer"
                                  style={{
                                    color: test?.resultColor || resultToShow.labelColor,
                                  }}
                                  onClick={() => dispatch(showResultPdf(newTestCreate))}
                                >
                                  {resultToShow.label}
                                </span>
                                {/* <ResultCell test={newTestCreate} /> */}
                                {/* {resultToShow.label === "View Detail" && permission[COMPLETED_TESTS]?.write && (
                                  <img
                                    src={EditIcon}
                                    alt="edit icon"
                                    width="18"
                                    style={{ marginLeft: "10px", cursor: "pointer" }}
                                    aria-hidden="true"
                                    onClick={openAssignResultModal}
                                  />
                                )} */}
                              </>
                            ) : (
                              // <Select
                              //   className="w-100 roleSelector"
                              //   options={resultTypes}
                              //   blurInputOnSelect={true}
                              //   defaultValue={null}
                              //   menuPlacement="auto"
                              //   value={
                              //     newTestCreate.result
                              //       ? { value: newTestCreate.result, label: newTestCreate.result }
                              //       : null
                              //   }
                              //   placeholder="Select Result"
                              //   onChange={(e) =>
                              //     setTestCreate({
                              //       ...newTestCreate,
                              //       result: e.value,
                              //       status: "Processed",
                              //     })
                              //   }
                              // />

                              <div>
                                Edit Result
                                <img
                                  src={EditIcon}
                                  alt="edit icon"
                                  width="18"
                                  style={{ marginLeft: "10px", cursor: "pointer" }}
                                  aria-hidden="true"
                                  onClick={() => {
                                    dispatch(setSelectedTestForResult([newTestCreate]));
                                  }}
                                />
                              </div>
                            )}
                          </div>
                          <Form.Group className="form-group-wrapper">
                            <label className="modalLineHeaders mt-0 text-capitalize">Clinical Comments</label>
                            <Form.Control
                              className={`${!editButton ? "modalInput" : ""}`}
                              readOnly={editButton}
                              onChange={(e) => setEmployee({ ...Employee, clinicalcomment: e.target.value })}
                              value={Employee?.clinicalcomment}
                              defaultValue={demographics?.clinicalcomment}
                              placeholder={`${!editButton ? "Enter Clinical Comments" : ""}`}
                              type="text"
                            />
                          </Form.Group>
                          <Form.Group className="form-group-wrapper">
                            <label className="modalLineHeaders mt-0 text-capitalize">Laboratory Comments</label>
                            <Form.Control
                              className={`${!editButton ? "modalInput" : ""}`}
                              readOnly={editButton}
                              onChange={(e) => setEmployee({ ...Employee, comment: e.target.value })}
                              value={Employee?.comment}
                              defaultValue={demographics?.comment}
                              placeholder={`${!editButton ? "Enter Laboratory Comments" : ""}`}
                              type="text"
                            />
                          </Form.Group>
                        </div>
                      </div>
                    </Col>

                    <Col md="12" className="hsWrapper  mb-2 bg-white">
                      <div
                        className="d-flex align-items-center justify-content-between cursor-pointer py-1  fw-bold fs-6"
                        onClick={() => setOpenHSForm(!openHSForm)}
                      >
                        <span className="mx-3"> Additional Screening Information</span>
                        {openHSForm ? (
                          <i className="fas fa-chevron-down mx-3" />
                        ) : (
                          <i className="fas fa-chevron-up mx-3" />
                        )}
                      </div>
                      {openHSForm && (
                        <div className="testAdmin createClientsWrapper">
                          <div className="px-3 py-3 w-100">
                            <div className="form-group-wrapper mx-2 my-4">
                              <strong>Health & Safety Questions:</strong>
                            </div>

                            <div className="first-last-name-wrapper">
                              <Select
                                className="w-100 roleSelector"
                                options={testSources || []}
                                blurInputOnSelect={true}
                                isDisabled={editButton}
                                defaultValue={null}
                                menuPlacement="auto"
                                placeholder="Source"
                                value={
                                  Employee?.swabtype
                                    ? testSources?.filter(
                                        (f) => f.value.toLowerCase() === Employee?.swabtype?.toLowerCase()
                                      )
                                    : null
                                }
                                onChange={(e) => setEmployee({ ...Employee, swabtype: e.value })}
                              />

                              <RadioInput
                                label={"Are you an Essential Worker?"}
                                options={WORKER_OPT}
                                handleRadioButtonChange={(e) => setEmployee({ ...Employee, worker: e.target.value })}
                                input={Employee?.worker}
                                disabled={editButton}
                              />
                            </div>

                            <div className="first-last-name-wrapper">
                              <RadioInput
                                label={"Possible Exposure?"}
                                options={POSSIBLE_EXPOSURE_OPT}
                                handleRadioButtonChange={(e) =>
                                  setEmployee({ ...Employee, possibleexposure: e.target.value })
                                }
                                input={Employee?.possibleexposure}
                                disabled={editButton}
                              />
                              <RadioInput
                                label={"Confirmed Exposure?"}
                                options={CONFIRMED_EXPOSURE_OPT}
                                handleRadioButtonChange={(e) =>
                                  setEmployee({ ...Employee, confirmedexposure: e.target.value })
                                }
                                input={Employee?.confirmedexposure}
                                disabled={editButton}
                              />
                            </div>

                            <CheckBoxInput
                              label="Please mark all that apply:"
                              options={SYMPTOMS_OPT}
                              handleButtonChange={(val) => setEmployee({ ...Employee, symptoms: val })}
                              input={Employee?.symptoms || ""}
                              disabled={editButton}
                            />
                            <div className="first-last-name-wrapper">
                              {Employee.symptoms?.includes(SYMPTOMS.other) && (
                                <Form.Group className="form-group-wrapper">
                                  <label className="modalLineHeaders mt-0 text-capitalize">Other Comment</label>
                                  <Form.Control
                                    className={`${!editButton ? "modalInput" : ""}`}
                                    readOnly={editButton}
                                    onChange={(e) => setEmployee({ ...Employee, othercomment: e.target.value })}
                                    value={Employee?.othercomment}
                                    defaultValue={demographics?.othercomment}
                                    placeholder={`${!editButton ? "Enter Other Symptoms" : ""}`}
                                    type="text"
                                  />
                                </Form.Group>
                              )}

                              <Form.Group className="form-group-wrapper">
                                <label className="modalLineHeaders mt-0 text-capitalize">Test Comment</label>
                                <Form.Control
                                  className={`${!editButton ? "modalInput" : ""}`}
                                  readOnly={editButton}
                                  onChange={(e) => setEmployee({ ...Employee, comment: e.target.value })}
                                  value={Employee?.comment}
                                  defaultValue={demographics?.comment}
                                  placeholder={`${!editButton ? "Enter Test Comment" : ""}`}
                                  type="text"
                                />
                              </Form.Group>
                            </div>

                            <div className="first-last-name-wrapper">
                              {allowToEditQuarantine && (
                                <>
                                  <div className="form-group-wrapper">
                                    <label className="text-capitalize mt-0 modalLineHeaders  mt-0 text-capitalize">
                                      Quarantined Start
                                    </label>{" "}
                                    <br />
                                    <span className="vaccination-status pl-3">
                                      {formatQuaratineDisplayDate(test?.quarantinedStart)}
                                    </span>
                                  </div>
                                  <div className="form-group-wrapper">
                                    <label className="text-capitalize mt-0 modalLineHeaders mt-0 text-capitalize">
                                      Quarantined End
                                    </label>{" "}
                                    <br />
                                    <span className="vaccination-status pl-3">
                                      {formatQuaratineDisplayDate(test?.quarantinedEnd)}
                                    </span>
                                  </div>
                                </>
                              )}
                            </div>
                          </div>
                        </div>
                      )}
                    </Col>
                    <Col md="12" className="additional-info-radios border-right-1">
                      <div className="justify-content-between d-flex align-items-center">
                        <span className="mx-3 fs-5 fw-bold">Documents</span>
                        <div className="plus-icon">
                          <i
                            className={`fas fa-plus-circle fa-lg cursor-pointer w-auto`}
                            onClick={() => (
                              setSelectedDocument({ isNew: true, testID: test.id }), setOpenDocumentModal(Employee)
                            )}
                          />
                        </div>
                      </div>

                      <DocumentList
                        list={documents}
                        disabled={editButton}
                        setDocuments={setDocuments}
                        appContext={appContext}
                        setLoading={setLoading}
                        dropDownOptions={[{ title: "Download" }, { title: "Edit" }]}
                        tabelForm
                        handleEdit={(item) => (
                          setSelectedDocument({ ...item, isNew: false }), setOpenDocumentModal(Employee)
                        )}
                      />
                    </Col>
                  </Row>

                  {test && (
                    <div className="text-right w-100">
                      {error && <ErrorMessage error={error} handleChange={() => setError("")} />}

                      {location?.state?.pendingRelease && (
                        <>
                          <Button
                            className="profileButton btn-fill createButtons"
                            style={{
                              marginRight: 10,
                              marginTop: 10,
                            }}
                            variant="secondary"
                            onClick={async () => {
                              setLoading(true);
                              if (test?.result.toLowerCase() === "positive") {
                                const result = await API.sendSMSNotification(
                                  test.phoneNumber,
                                  "Hello, can you please report back to your testing site for further information regarding your test."
                                );
                                if (result) {
                                  appContext.showSuccessMessage(
                                    "The crew member has been sent a text message to be retested."
                                  );
                                }
                                setLoading(false);
                              } else {
                                const params = [
                                  {
                                    id: test.id,
                                    phoneNumber: test.phoneNumber,
                                    email: test.email,
                                    test_type: TEST_TYPE_VALUE[test.test_type],
                                    site_name: test.site_name,
                                    firstName: Employee.firstName,
                                    siteID: test.siteID,
                                    lastName: Employee.lastName,
                                    userName: appContext.user.name,
                                    type: "invalid",
                                  },
                                ];
                                try {
                                  await GQAPI.sendSmsToCrews(params);
                                  appContext.showSuccessMessage(
                                    "The crew member has been sent a message to be retested."
                                  );
                                  setLoading(false);
                                } catch (err) {
                                  console.log(err.message);
                                  appContext.showErrorMessage(err.message);
                                  setLoading(false);
                                }
                              }
                            }}
                          >
                            Retest
                          </Button>
                        </>
                      )}
                      <Button
                        className="profileButton btn-fill createButtons"
                        style={{
                          marginRight: 10,
                          marginTop: 10,
                        }}
                        disabled={!test?.sequenceNo}
                        variant="primary"
                        // onClick={async () => {
                        //   appContext.showRequisitionForm({ test, signature, testNames });
                        // }}
                        onClick={() => showReqForm({ test, signature, testNames })}
                      >
                        Requisition Form
                      </Button>
                      <div style={{ position: "absolute", left: "-10000px" }} ref={barcodeRef}>
                        <LabBarCode code={test.barcode} />
                      </div>
                      {test?.result && (
                        <Button
                          className="profileButton btn-fill createButtons"
                          style={{
                            marginRight: 10,
                            marginTop: 10,
                          }}
                          variant="primary"
                          onClick={async () => emailTest(test)}
                        >
                          Resend Results
                        </Button>
                      )}

                      {(test?.result === "Positive" || test?.result === "Fail") &&
                        !CONFIG.isWhiteLabel &&
                        allowToEditQuarantine && (
                          <Button
                            className="profileButton btn-fill createButtons"
                            style={{
                              marginRight: 10,
                              marginTop: 10,
                            }}
                            variant="secondary"
                            onClick={() => setOpenQuaratined(true)}
                          >
                            Quarantined
                          </Button>
                        )}

                      {editButton && permission?.test?.editTest && (
                        <Button
                          className="profileButton btn-fill createButtons"
                          style={{
                            marginRight: 10,
                            marginTop: 10,
                          }}
                          variant="primary"
                          onClick={() => {
                            setEditButton(false);
                          }}
                        >
                          Edit Info
                        </Button>
                      )}
                      {!editButton && (
                        <Button
                          className="profileButton btn-fill createButtons"
                          style={{
                            marginRight: 10,
                            marginTop: 10,
                          }}
                          variant="secondary"
                          onClick={() => {
                            if (newTestCreate.barcode !== editInfo.barcode) {
                              setChangeBarcodeConfirmation(true);
                            } else {
                              handleSave();
                            }
                          }}
                        >
                          Save
                        </Button>
                      )}
                      {!editButton && (
                        <Button
                          className="profileButton btn-fill createButtons"
                          style={{
                            marginRight: 10,
                            marginTop: 10,
                          }}
                          variant="primary"
                          onClick={handleCancelButton}
                        >
                          Cancel
                        </Button>
                      )}
                    </div>
                  )}

                  <div className="clearfix" />
                </Form>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>

      {openQuarantined && (
        <QuarantineModal test={test} handleSave={handleSaveQuarantine} handleClose={() => setOpenQuaratined(false)} />
      )}

      {openCreator && (
        <TestModal
          newTestCreate={newTestCreate}
          handleCloseCreateTest={handleCloseCreateTest}
          createTest={updateTest}
          setTestCreate={setTestCreate}
          clients={appContext.clients}
          sites={appContext.sites}
          labs={appContext.labs}
          isNew={false}
          users={users}
        />
      )}

      {openDocumentModal && (
        <DocumentForm
          emp={openDocumentModal}
          document={selectedDocument}
          handleClose={() => {
            setOpenDocumentModal(null);
          }}
          setLoading={setLoading}
          appContext={appContext}
          setDocuments={setDocuments}
          cssClass="seperateModal"
        />
      )}

      {openDataChangeConfirmation && (
        <ConfirmationModal
          show={openDataChangeConfirmation}
          title="Test Update Alert"
          message={`New Changes occures, are you want to update your edit info!`}
          handleConfirm={handleDataChangeConfirm}
        />
      )}
      {changeBarcodeConfirmation && (
        <ConfirmationModal
          show={changeBarcodeConfirmation}
          title={`Change ${stringConstant.barcode} Confirmation`}
          message={`Are you sure you want to edit the ${stringConstant.barcode}. from ${editInfo.barcode} to ${newTestCreate.barcode} for this test?`}
          handleConfirm={handleChangeBarcodeNo}
          isPreRegister={true}
          handleClose={() => setChangeBarcodeConfirmation(false)}
        />
      )}
    </>
  ) : (
    <Loader />
  );
}

export default TestDetails;
