import MainTable from "components/Table/MainTable";
import React, { useState, useEffect } from "react";
import { Card, Row, Col } from "react-bootstrap";
import { TAB_SETTINGS_COLUMN } from "constants/personalization";
import InputField from "components/InputField/InputField";
import { PRINTER_SETTINGS_OPTIONS, PRINTER_LABEL_SIZE_OPTIONS, CONFIG } from "constant";
import Icon from "components/Icon";
import { v4 as uuidv4 } from "uuid";
import api from "api";
import { customCloneDeep } from "util/customLodash";
import { useDispatch } from "react-redux";
import { updateLabClient } from "store/features/locations/locationsSlice";

const TabSettings = ({ strippedTable, widthClass, title, callFor, labClient, setLabClient }) => {
  const [selectedPrinter, setSelectedPrinter] = useState(null);

  const [selectedSize, setSelectedSize] = useState(null);

  const [clientData, setClientData] = useState(null);

  const dispatch = useDispatch();

  const handlePrinterChange = (selectedOption) => {
    setSelectedPrinter(selectedOption);
    setSelectedSize(null);
  };

  const handleSizeChange = (selectedOption) => {
    setSelectedSize(selectedOption);
  };

  useEffect(() => {
    const fetchClientData = async () => {
      if (CONFIG.clientID && callFor === "client") {
        const clientRes = await api.getClientById(CONFIG.clientID);
        setClientData(clientRes);
      }
      if (callFor === "labClient") {
        setClientData(labClient);
      }
    };
    fetchClientData();
  }, [CONFIG?.clientID]);

  const handleCellClick = () => {};

  const handleAddRow = async () => {
    if (selectedPrinter && selectedSize) {


      const newRow = {
        id: uuidv4(),
        printer_name: selectedPrinter.label,
        printer_size: selectedSize.label,
        labelSize: selectedSize.value,
      };

      const cloneClient = customCloneDeep(clientData);

      cloneClient.setting = cloneClient.setting || {};

      cloneClient.setting.printerSetting = cloneClient.setting.printerSetting || [];

      const existingIndex = cloneClient.setting.printerSetting.findIndex(
        (row) => row.printer_name === newRow.printer_name
      );

      if (existingIndex !== -1) {
        cloneClient.setting.printerSetting[existingIndex] = newRow;
      } else {
        cloneClient.setting.printerSetting.push(newRow);
      }

      setClientData(cloneClient);

      if (callFor === "client") {
        await api.updateClient(cloneClient);
      }
      if (callFor === "labClient") {
        setLabClient(cloneClient);
        await dispatch(updateLabClient({ ...cloneClient, siteType: "client" }));
      }
    }
    setSelectedSize(null);
  };

  const handleTestRemove = (item) => {
    const cloneClient = customCloneDeep(clientData);

    cloneClient.setting = cloneClient.setting || {};
    cloneClient.setting.printerSetting = cloneClient.setting.printerSetting || [];

    cloneClient.setting.printerSetting = cloneClient.setting.printerSetting.filter((row) => row.id !== item.id);

    setClientData(cloneClient);
    api.updateClient(cloneClient);
  };

  const customRenderTD = (item, row, index) => {
    if (item.itemKey === "action") {
      return (
        <td key={`bin_${item.id}`} style={{ textAlign: "right" }}>
          <Icon
            iconType={"binIcon"}
            containerClass={"ms-2 cursor-pointer"}
            innerClass={""}
            imageClass={"testTabelDeleteIcon"}
            handleClick={() => handleTestRemove(row)}
          />
        </td>
      );
    }
    return <td key={item.id}>{row?.title || row?.label}</td>;
  };

  return (
    <Row>
      <Col md="12">
        <Card>
          {title && (
            <Card.Header>
              <Card.Title
                as="h4"
                style={{
                  marginBottom: 10,
                  fontWeight: "bold",
                }}
              >
                {title}
              </Card.Title>
            </Card.Header>
          )}
          <Card.Body className="table-full-width desktop-noScroll">
            <div className="d-flex justify-content-start align-items-center tabSettings-wrapper gap-3 mb-4">
              <div className={widthClass}>
                <InputField
                  type="dropDown"
                  groupWrapper="w-100"
                  options={PRINTER_SETTINGS_OPTIONS}
                  labelStyle="text-capitalize"
                  label="Printer Name:"
                  index="value"
                  isClearable={true}
                  value={selectedPrinter}
                  placeholder="Printer Name:"
                  handleChange={handlePrinterChange}
                />
              </div>
              <div className={widthClass}>
                <InputField
                  type="dropDown"
                  groupWrapper="w-100"
                  options={PRINTER_LABEL_SIZE_OPTIONS[selectedPrinter?.value] || null}
                  labelStyle="text-capitalize"
                  label="Label Size:"
                  index="value"
                  isClearable={true}
                  value={selectedSize}
                  placeholder="Label Size:"
                  handleChange={handleSizeChange}
                  disabled={!selectedPrinter}
                />
              </div>
              <Icon
                iconType={"createIcon"}
                containerClass={"mt-3"}
                innerClass={""}
                imageClass={(selectedPrinter || selectedSize) && "cursor-pointer"}
                handleClick={() => handleAddRow()}
                disabled={!selectedPrinter || !selectedSize}
              />
            </div>
            {clientData?.setting?.printerSetting?.length > 0 && (
              <MainTable
                columns={TAB_SETTINGS_COLUMN}
                rows={clientData?.setting?.printerSetting || []}
                widthToSkip={160}
                handleCellClick={handleCellClick}
                customColumnCellRenderer={customRenderTD}
                strippedTable={strippedTable}
              />
            )}
          </Card.Body>
        </Card>
      </Col>
    </Row>
  );
};

export default TabSettings;
