import Icon from "components/Icon";
import React from "react";

const AddResultDisclaimer = ({ onClick, label = "Add Text", icon = null }) => {
  return (
    <div className="test-component-element">
      <div style={{ display: "flex", height: "100%", alignItems: "center", marginTop: "15px" }}>
        {icon && (
          <Icon
            iconType={icon}
            containerClass={"ms-2 cursor-pointer"}
            innerClass={"test-selector-edit-icon"}
            handleClick={onClick}
          />
        )}

        <p
          style={{
            color: "#5b90fa",
            fontWeight: "500",
            fontSize: "24px",
            margin: "0px",
            padding: "0px",
            marginLeft: "10px",
            lineHeight: 1,
            cursor: "pointer",
          }}
          onClick={onClick}
        >
          {label}
        </p>
      </div>
    </div>
  );
};

export default AddResultDisclaimer;
