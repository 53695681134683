/*!

=========================================================
* Light Bootstrap Dashboard React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/light-bootstrap-dashboard-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/light-bootstrap-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/

import CrewProfile from "components/CrewProfile";
import TestListAdmin from "views/Test/TestListAdmin";
import UnprocessedTestsApi from "views/GraphQL/UnprocessedTestsApi";
import ProfileEmployeeTest from "views/ProfileEmployeeTest";
import TestDetails from "views/TestDetails";
import PatientView from "views/GraphQL/PatientView";
import PatientProfile from "views/PatientProfile";
import OrdersView from "views/Order/OrderView";

const dashboardRoutes = [
  {
    path: "/patient",
    name: "Patient",
    icon: "nc-icon nc-notes",
    component: PatientView,
    layout: "/admin",
  },
  {
    path: "/order",
    name: "Orders",
    icon: "nc-icon nc-notes",
    component: OrdersView,
    layout: "/admin",
  },
  // {
  //   path: "/senttest",
  //   name: "Waiting Results",
  //   icon: "nc-icon nc-notes",
  //   component: UnprocessedTestsApi,
  //   layout: "/admin",
  // },
  // {
  //   path: "/alltests",
  //   name: "Completed Tests",
  //   icon: "nc-icon nc-check-2",
  //   component: TestListAdmin,
  //   layout: "/admin",
  // },

  {
    path: "/test/:testId",
    name: "Test",
    icon: "nc-icon nc-circle-09",
    component: TestDetails,
    layout: "/admin",
    redirect: true,
  },
  {
    path: "/employee/:employeeId",
    name: "Profile Employee Test",
    icon: "nc-icon nc-circle-09",
    component: ProfileEmployeeTest,
    layout: "/admin",
    redirect: true,
  },
  {
    path: "/profile/:patientId",
    name: "Profile Patient Test",
    icon: "nc-icon nc-circle-09",
    component: PatientProfile,
    layout: "/admin",
    redirect: true,
  },

  {
    path: "/crewprofile/:empId",
    name: "CrewProfile",
    icon: "nc-icon nc-circle-09",
    component: CrewProfile,
    layout: "/admin",
    redirect: true,
  },

  // {
  //   path: "/users",
  //   name: "System User",
  //   icon: "nc-icon nc-badge",
  //   component: UsersView,
  //   layout: "/admin",
  // },
  // {
  //   path: "/pendingrelease",
  //   name: "Pending Release",
  //   icon: "nc-icon nc-notes",
  //   component: PendingReleaseTestListView,
  //   layout: "/admin",
  // },
  // {
  //   path: "/tests",
  //   name: "New Tests",
  //   icon: "nc-icon nc-notes",
  //   component: TableListApi,
  //   layout: "/admin",
  // },
  // {
  //   path: "/processing",
  //   name: "Tests Processing",
  //   icon: "nc-icon nc-notes",
  //   component: TableListApi,
  //   layout: "/admin",
  // },
  // {
  //   path: "/processedtests",
  //   name: "Processed Tests",
  //   icon: "nc-icon nc-check-2",
  //   component: ProcessedTestListApi,
  //   layout: "/admin",
  // },

  // {
  //   path: "/order",
  //   name: "Orders",
  //   icon: "nc-icon nc-notes",
  //   component: OrdersView,
  //   layout: "/admin",
  // },

  // {
  //   path: "/testtypes",
  //   name: "Settings",
  //   icon: "nc-icon nc-notes",
  //   component: TestTypesSetting,
  //   layout: "/admin",
  // },
];

export default dashboardRoutes;
