import api from "api";
import { useSelector } from "react-redux";
import Loader from "components/Loader/Loader";
import StatsView from "components/PreRegister/StatsView";
import Status from "components/Status/Status";
import { ORDER_STATUS } from "constant";
import { NOTIFICATION_STATUS, PAGE_LIMIT } from "constant";
import moment from "moment";
import React, { useEffect, useState, useContext, useMemo } from "react";
import { Button, Modal, Card, Table, Row, Col } from "react-bootstrap";
import { parseTestDates } from "utils";
import { formatDateMDY } from "utils";
import { capitalizeLetter } from "utils";
import Select from "react-select";
import MainTable from "components/Table/MainTable";
import { ORDER_IMPORT_MODAL_PERSONALIZE } from "constants/personalization";
import { selectClient } from "store/features/authentication/authenticationSelectors";

const PatientImportErrorModal = ({ data, files, handleClose, onCompleteLoad, title, appContext }) => {
  const [successData, setSuccessData] = useState([]);
  const [filteredList, setFilteredList] = useState([]);
  const [filter, setFilter] = useState({});
  const [loading, setLoading] = useState(false);
  const [dropdown, setDropdown] = useState({});
  const [sortBy, setSortBy] = useState("");
  const [sortDescending, setSortDescending] = useState(true);

  const userClientData = useSelector(selectClient);
  
  const parseDOB = (dob) => {
    const parsedDate = moment(dob.padStart(6, "0"), "MMDDYY");
    if (parsedDate.year() > new Date().getFullYear()) {
      parsedDate.subtract(100, "years");
    }
    return parsedDate.format("YYYY-MM-DD");
  };

  const listData = useMemo(() => {
    return data.map((record) => {
      const { data: m } = record;

      return {
        ...m,
        dob: m.dob ? parseDOB(m.dob) : "",
        ...(m.test_date && { test_date: parseTestDates(m.test_date) }),
        sex: m.gender,
        insurance_name: m.p_insurance,
        insurance_number: m.p_insurance_id,
        insuranceCompanyCode: m.p_code,
        clientID: record.clientID,
        siteID: record.siteID,
        street2: m.room_number,
        floorNo: m.floorID,
        status: record.status,
        requestID: record.id,
        ...(m.s_code && {
          insuranceDetails: {
            insuranceCompany: m.s_code,
            insuranceCompanyCode: m.s_insurance,
            medicalNo: m.s_insurance_id,
          },
        }),
      };
    });
  }, [data]);

  useEffect(() => {
    if (dropdown.value) {
      setFilteredList(listData.filter((f) => f.fileID === dropdown.value));
    }
  }, [dropdown]);

  useEffect(() => {
    if (sortBy) {
      setFilteredList(
        sortDescending
          ? [...listData].sort((a, b) => {
              const testerB = b[sortBy] ?? "";
              const testerA = a[sortBy] ?? "";
              return testerB < testerA ? 1 : testerA < testerB ? -1 : 0;
            })
          : [...listData].sort((a, b) => {
              const testerB = b[sortBy] ?? "";
              const testerA = a[sortBy] ?? "";
              return testerB > testerA ? 1 : testerA > testerB ? -1 : 0;
            })
      );
    } else {
      setFilteredList(listData);
    }
  }, [listData, sortBy, sortDescending]);

  const handleEditUser = (index, user) => {
    if (user.patientCreated === NOTIFICATION_STATUS.pending) return;
    const obj = {
      ...user,
      first: user.first_name,
      last: user.last_name,
      isNew: user.empID ? false : true,
      index: index,
      mdID: user.empID,
    };
    appContext.showPatient(obj);
  };

  const handleEditOrder = (index, user) => {
    if (user.patientCreated !== NOTIFICATION_STATUS.pass) return appContext.showErrorMessage("Patient Not Created");
    if (user.orderCreated === NOTIFICATION_STATUS.pass) return appContext.showErrorMessage("Order Created Already");
    const demo = {
      firstName: user.first_name,
      lastName: user.last_name,
      sex: user.sex,
      dob: user.dob,
      insurance_name: user.insurance_name,
      insurance_number: user.insurance_number,
      insuranceCompanyCode: user.insuranceCompanyCode,
    };
    const obj = {
      ...user,
      isNew: true,
      index: index,
      employeeID: user.empID,
      employee_demographics: demo,
      test_type: "PCR",
    };
    appContext.showOrderModal(obj);
  };

  const checkEligibilityDetails = (user) => {
    const demo = {
      ins_name_f: user.first_name,
      ins_name_l: user.last_name,
      sex: user.sex,
      ins_dob: user.dob,
      insurance_name: user.insurance_name,
      insurance_number: user.insurance_number,
      insuranceCompanyCode: user.insuranceCompanyCode,
    };

    appContext.showEligibilityDetails({ ...demo, ...user.eligibilityStatus });
  };

  const handleStatsClick = (type) => {
    let arr = [];
    if (type === STATS_CLICK_TYPE.ORDERS) {
      arr = listData;
    }
    if (type === STATS_CLICK_TYPE.COMPLETED) {
      arr = listData.filter((f) => f.status !== NOTIFICATION_STATUS.pending);
    }
    if (type === STATS_CLICK_TYPE.REMAINING) {
      arr = listData.filter((f) => f.status === NOTIFICATION_STATUS.pending);
    }
    if (type === STATS_CLICK_TYPE.REJECTED) {
      arr = listData.filter((f) =>
        [f.status, f.patientCreated, f.eligibility, f.orderCreated].includes(NOTIFICATION_STATUS.fail)
      );
    }
    if (type === STATS_CLICK_TYPE.PATIENT) {
      arr = listData.filter((f) => !f.profileMatched && f.patientCreated === NOTIFICATION_STATUS.pass);
    }
    if (type === STATS_CLICK_TYPE.FAILPATIENT) {
      arr = listData.filter((f) => f.patientCreated === NOTIFICATION_STATUS.fail);
    }
    if (type === STATS_CLICK_TYPE.FAILORDER) {
      arr = listData.filter((f) => f.orderCreated === NOTIFICATION_STATUS.fail);
    }
    if (type === STATS_CLICK_TYPE.FAILELIGIBILITY) {
      arr = listData.filter((f) => f.eligibility === NOTIFICATION_STATUS.fail);
    }
    setFilteredList(arr);
  };

  const STATS_CLICK_TYPE = {
    ORDERS: "orders",
    ATTENDEES: "attendess",
    COMPLETED: "completed",
    REMAINING: "remaning",
    PATIENT: "patient",
    REJECTED: "rejected",
    FAILORDER: "failorder",
    FAILPATIENT: "failpatient",
    FAILELIGIBILITY: "faileligibility",
  };

  const handleComplete = async () => {
    onCompleteLoad(data[0].requestID);
  };

  const selectOptions = files?.filesStatus?.map((option) => ({
    label: option.fileName?.split("$")[1],
    value: option.id,
  }));

  const flipSort = (by) => {
    setSortBy(by);
    setSortDescending(!sortDescending);
  };
  const tdFormatTitle = (item, row) => {
    if (item === "orderCreated") {
      return row.orderMessage?.[0]?.message;
    }
    if (item === "patientCreated") {
      return row.patientMessage?.[0]?.message;
    }
    if (item === "eligibility") {
      return row.eligibilityMessage;
    }
    if (item === "test_date") return row[item]?.join(", ");

    return row[item];
  };

  const handleClick = (item, row, index) => {
    if (item === "patientCreated") {
      handleEditUser(index, row);
    }
    if (item === "orderCreated") {
      handleEditOrder(index, row);
    }
    if (item === "eligibility") {
      checkEligibilityDetails(row);
    }
  };
  const tdFormat = (item, row, index) => {
    if (item === "patientCreated") {
      return (
        <div className="star-icon-wrapper cursor-pointer" onClick={() => handleClick(item, row, index)}>
          <Status type="circle" size="md" color={ORDER_STATUS[row[item]]} crossIcon={row[item]} />
        </div>
      );
    }
    if (item === "orderCreated") {
      return (
        <div className="star-icon-wrapper cursor-pointer" onClick={() => handleClick(item, row, index)}>
          <Status type="circle" size="md" color={ORDER_STATUS[row[item]]} crossIcon={row[item]} />
        </div>
      );
    }
    if (item === "eligibility") {
      return (
        <div className="star-icon-wrapper cursor-pointer" onClick={() => handleClick(item, row, index)}>
          <Status type="circle" size="md" color={ORDER_STATUS[row[item]]} crossIcon={row[item]} />
        </div>
      );
    }
    if (item === "test_date") return row[item]?.join(", ");

    return row[item];
  };
  const customRenderTD = (item, row, index) => {
    return (
      <td
        key={item.id}
        className="ellipsis"
        style={{
          textAlign: item.textAlign,
          textOverflow: item.textOverflow,
        }}
        title={tdFormatTitle(item.itemKey, row, index)}
      >
        {tdFormat(item.itemKey, row, index)}
      </td>
    );
  };
  const handleCellClick = (key, row, event) => {};

  const checkFileStatus = () => {
    return listData.length === listData.filter((f) => f.status && f.status !== NOTIFICATION_STATUS.pending).length;
  };

  const isEmployeeCall = () => data[0]?.uploadCallType === "employee";

  return (
    <>
      <Modal show animation={true} onHide={() => handleClose()} size={"xl"} scrollable>
        <Modal.Header closeButton>
          <Modal.Title className="my-0" id="contained-modal-title-vcenter">
            {isEmployeeCall() ? "Patient Upload Management" : "Order Creation Summary"}
          </Modal.Title>
        </Modal.Header>

        <Modal.Body className="marginTop0">
          <>
            <div className="statsOrderDetails">
              {!isEmployeeCall() && (
                <>
                  <StatsView
                    label="Orders"
                    value={listData.length ?? 0}
                    type={STATS_CLICK_TYPE.ORDERS}
                    handleClick={handleStatsClick}
                    numberStyle={{ fontSize: "20px" }}
                  />
                  <StatsView
                    label="Completed"
                    value={listData.filter((f) => f.status !== NOTIFICATION_STATUS.pending).length ?? 0}
                    type={STATS_CLICK_TYPE.COMPLETED}
                    handleClick={handleStatsClick}
                    numberStyle={{ fontSize: "20px" }}
                  />
                  <StatsView
                    label="Failed Orders"
                    value={listData.filter((f) => f.orderCreated === NOTIFICATION_STATUS.fail).length ?? 0}
                    type={STATS_CLICK_TYPE.FAILORDER}
                    handleClick={handleStatsClick}
                    numberStyle={{ fontSize: "20px" }}
                  />

                  <StatsView
                    label="Remaining"
                    value={listData.filter((f) => f.status === NOTIFICATION_STATUS.pending).length ?? 0}
                    type={STATS_CLICK_TYPE.REMAINING}
                    handleClick={handleStatsClick}
                    numberStyle={{ fontSize: "20px" }}
                  />
                </>
              )}
              {/* <StatsView
                label="Rejected/Errors"
                value={
                  listData.filter((f) =>
                    [f.status, f.patientCreated, f.eligibility, f.orderCreated].includes(NOTIFICATION_STATUS.fail)
                  ).length ?? 0
                }
                type={STATS_CLICK_TYPE.REJECTED}
                handleClick={handleStatsClick}
                numberStyle={{ fontSize: "20px" }}
              /> */}
              <StatsView
                label="New Patients"
                value={
                  listData.filter((f) => !f.profileMatched && f.patientCreated === NOTIFICATION_STATUS.pass).length ?? 0
                }
                type={STATS_CLICK_TYPE.PATIENT}
                handleClick={handleStatsClick}
                numberStyle={{ fontSize: "20px" }}
              />
              <StatsView
                label="Patient Failed"
                value={listData.filter((f) => f.patientCreated === NOTIFICATION_STATUS.fail).length ?? 0}
                type={STATS_CLICK_TYPE.FAILPATIENT}
                handleClick={handleStatsClick}
                numberStyle={{ fontSize: "20px" }}
              />
              {userClientData?.setting?.eligibility && (
                <StatsView
                  label="Eligiblity Failed"
                  value={listData.filter((f) => f.eligibility === NOTIFICATION_STATUS.fail).length ?? 0}
                  type={STATS_CLICK_TYPE.FAILELIGIBILITY}
                  handleClick={handleStatsClick}
                  numberStyle={{ fontSize: "20px" }}
                />
              )}
            </div>
            <div style={{ flex: 1 }}>
              <Row>
                <Col md="12">
                  <Card>
                    <Card.Body
                      className="table-full-width  px-2 desktop-noScroll mt-0"
                      style={{ backgroundColor: "#f8f8f8" }}
                    >
                      {listData.length > 0 && (
                        <div className="d-block">
                          <Row>
                            <Col md={6}>
                              <span className="fw-bold">Details: {filteredList.length}</span>
                            </Col>
                            <Col md={6}>
                              <Select
                                className="w-100 custom-select-style"
                                options={selectOptions}
                                blurInputOnSelect={true}
                                defaultValue={null}
                                menuPlacement="bottom"
                                placeholder={`Select file`}
                                value={dropdown ? dropdown : null}
                                onChange={(e) => setDropdown(e)}
                              />
                            </Col>
                          </Row>
                          <div className="table-responsive">
                            <MainTable
                              columns={
                                (isEmployeeCall()
                                  ? ORDER_IMPORT_MODAL_PERSONALIZE.filter((e) => e.isEmployee)
                                  : ORDER_IMPORT_MODAL_PERSONALIZE
                                ).filter((col) => userClientData?.setting?.eligibility || col.itemKey !== "eligibility")
                              }
                              rows={filteredList}
                              flipSort={flipSort}
                              sortBy={sortBy}
                              sortDescending={sortDescending}
                              customColumnCellRenderer={customRenderTD}
                              handleCellClick={handleCellClick}
                            />
                          </div>
                        </div>
                      )}
                    </Card.Body>
                  </Card>
                </Col>
              </Row>
            </div>
          </>
        </Modal.Body>

        <Modal.Footer>
          <Button className="modalButtons headerButton btn-fill" variant="primary" onClick={() => handleClose()}>
            Close
          </Button>
          {checkFileStatus() && (
            <Button className="modalButtons headerButton btn-fill" variant="secondary" onClick={() => handleComplete()}>
              Complete
            </Button>
          )}
        </Modal.Footer>
        {loading && <Loader />}
      </Modal>
    </>
  );
};

export default PatientImportErrorModal;
