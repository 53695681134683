import React, { useState } from "react";

// react-bootstrap components
import {
  Badge,
  Button,
  Card,
  Navbar,
  Nav,
  Table,
  Container,
  Row,
  Col,
  DropdownButton,
  Dropdown,
  Modal,
} from "react-bootstrap";
import { useEffect } from "react";
import API from "api";
import { CSVLink } from "react-csv";
import { Importer, ImporterField } from "react-csv-importer";
import SortIcon from "../components/SortIcon";
import SearchBar from "../components/SearchBar";
import { Link } from "react-router-dom";
import parsePhoneNumber, { AsYouType } from "libphonenumber-js";

const ClientTests = () => {
  const importResult = [];
  const [tests, setTests] = useState([]);
  const [filteredTests, setFilteredTests] = useState([]);
  const [labs, setLabs] = useState([]);
  const [sites, setSites] = useState([]);
  const [moreInfo, setMoreInfo] = useState({});
  const [modalShow, setModalShow] = useState(false);
  const [sortBy, setSortBy] = useState("");
  const [sortDescending, setSortDescending] = useState(true);
  const [searchTerm, setSearchTerm] = useState("name");
  const [searchInput, setSearchInput] = useState("");

  const searchTerms = ["crew_ID", "name", "phone_number", "lab", "show", "barcode", "status"];
  const testsToMap = searchInput !== "" ? filteredTests : tests;

  useEffect(() => {
    const getClient = async (id) => {
      const model = await API.findTestByID(id);
      setDemographics(JSON.parse(model.employee_demographics));
      setTest(model);
    };

    const {
      match: { params },
    } = props;
    getTest(params.testId);
  }, []);

  useEffect(() => {
    labs.length !== 0 && sites.length !== 0 && fetchTests();
  }, [sites, labs]);

  useEffect(() => {
    changeSort();
  }, [sortBy, sortDescending]);

  const fetchTests = async () => {
    const models = await API.getTests();
    setTests(models);
  };

  const fetchLabs = async () => {
    const models = await API.getLabs();
    setLabs(models);
  };

  const fetchSites = async () => {
    const models = await API.getSites();
    setSites(models);
  };

  const changeSort = () => {
    switch (sortBy) {
      case "employee_demographics":
        setTests(
          sortDescending
            ? [...tests].sort((a, b) =>
                JSON.parse(b[sortBy]).lastName < JSON.parse(a[sortBy]).lastName
                  ? 1
                  : JSON.parse(a[sortBy]).lastName < JSON.parse(b[sortBy]).lastName
                  ? -1
                  : 0
              )
            : [...tests].sort((a, b) =>
                JSON.parse(b[sortBy]).lastName > JSON.parse(a[sortBy]).lastName
                  ? 1
                  : JSON.parse(a[sortBy]).lastName > JSON.parse(b[sortBy]).lastName
                  ? -1
                  : 0
              )
        );
        break;
      case "siteID":
        setTests(
          sortDescending
            ? [...tests].sort((a, b) =>
                getSiteName(b[sortBy]) < getSiteName(a[sortBy])
                  ? 1
                  : getSiteName(a[sortBy]) < getSiteName(b[sortBy])
                  ? -1
                  : 0
              )
            : [...tests].sort((a, b) =>
                getSiteName(b[sortBy]) > getSiteName(a[sortBy])
                  ? 1
                  : getSiteName(a[sortBy]) > getSiteName(b[sortBy])
                  ? -1
                  : 0
              )
        );
        break;
      case "labID":
        setTests(
          sortDescending
            ? [...tests].sort((a, b) =>
                getLabName(b[sortBy]) < getLabName(a[sortBy])
                  ? 1
                  : getLabName(a[sortBy]) < getLabName(b[sortBy])
                  ? -1
                  : 0
              )
            : [...tests].sort((a, b) =>
                getLabName(b[sortBy]) > getLabName(a[sortBy])
                  ? 1
                  : getLabName(a[sortBy]) > getLabName(b[sortBy])
                  ? -1
                  : 0
              )
        );
        break;
      default:
        setTests(
          sortDescending
            ? [...tests].sort((a, b) => (b[sortBy] < a[sortBy] ? 1 : a[sortBy] < b[sortBy] ? -1 : 0))
            : [...tests].sort((a, b) => (b[sortBy] > a[sortBy] ? 1 : a[sortBy] > b[sortBy] ? -1 : 0))
        );
    }
  };

  const getLabName = (id) => {
    const labObj = labs.find((lab) => lab.id === id);
    return labObj ? labObj.name : "NA";
  };

  const getSiteName = (id) => {
    const siteObj = sites.find((site) => site.id === id);
    return siteObj ? siteObj.name : "NA";
  };

  const getCrewsName = (empDemos) => {
    let parsed = JSON.parse(empDemos);
    let first = parsed.firstName.charAt(0).toUpperCase() + parsed.firstName.slice(1).toLowerCase();
    let last = parsed.lastName.charAt(0).toUpperCase() + parsed.lastName.slice(1).toLowerCase();
    return `${first} ${last}`;
  };

  const createMoreInfo = (type, id) => {
    const infoObj = type === "site" ? sites.find((site) => site.id === id) : labs.find((lab) => lab.id === id);
    if (!infoObj) return;
    setMoreInfo({
      type,
      name: infoObj.name,
      contact: infoObj.contact_name,
      phone: infoObj.contact_phone,
      email: infoObj.contact_email,
    });
    setModalShow(true);
  };

  const filterTests = (term, input) => {
    switch (term) {
      case "name":
        setFilteredTests(
          tests.filter((test) =>
            getCrewsName(test["employee_demographics"]).toLowerCase().includes(input.toLowerCase())
          )
        );
        break;
      case "lab":
        setFilteredTests(tests.filter((test) => getLabName(test["labID"]).toLowerCase().includes(input.toLowerCase())));
        break;
      case "show":
        setFilteredTests(
          tests.filter((test) => getSiteName(test["siteID"]).toLowerCase().includes(input.toLowerCase()))
        );
        break;
      case "crew_ID":
        setFilteredTests(tests.filter((test) => test["owner"].toLowerCase().includes(input.toLowerCase())));
        break;
      default:
        setFilteredTests(tests.filter((test) => test[term].toLowerCase().includes(input.toLowerCase())));
    }
  };

  const flipSort = (by) => {
    setSortBy(by);
    setSortDescending(!sortDescending);
  };

  const TableRow = ({ test }) => {
    return (
      <tr>
        <td>{test.owner}</td>
        <td>{getCrewsName(test.employee_demographics)}</td>
        <td>{test.phoneNumber && parsePhoneNumber(test.phoneNumber, "US").format("NATIONAL")}</td>
        <td>
          <a
            style={{ color: "#42cef5" }}
            onClick={() => createMoreInfo("site", test.siteID)}
            onMouseOver={(e) => (e.target.style.cursor = "pointer")}
          >
            {getSiteName(test.siteID)}
          </a>
        </td>
        <td>
          <a
            style={{ color: "#42cef5" }}
            onClick={() => createMoreInfo("lab", test.labID)}
            onMouseOver={(e) => (e.target.style.cursor = "pointer")}
          >
            {getLabName(test.labID)}
          </a>
        </td>
        <td>{test.barcode}</td>
        <td>{new Date(test.createdAt).toDateString()}</td>
        <td>{test.status}</td>
        <td>
          <span
            style={{
              color: test.result?.toLowerCase() === "negative" ? "green" : "red",
            }}
          >
            {test.result && test.result.charAt(0).toUpperCase() + test.result.slice(1)}
          </span>
        </td>
        <td>NA</td>
        <td>
          <Link style={{ color: "#42cef5" }} to={"/admin/test/" + test.id}>
            View
          </Link>
        </td>
      </tr>
    );
  };

  const SetSortIcon = ({ sortName }) => {
    return <SortIcon sortName={sortName} sortBy={sortBy} sortDescending={sortDescending} />;
  };

  return (
    <>
      <Container fluid>
        <Row>
          <Col md="12">
            <Card className="strpied-tabled-with-hover">
              <Card.Header>
                <Card.Title as="h4">Tests</Card.Title>
                <SearchBar
                  searchTerms={searchTerms}
                  filter={filterTests}
                  setSearchInput={setSearchInput}
                  setSearchTerm={setSearchTerm}
                  searchTerm={searchTerm}
                  searchInput={searchInput}
                  placeholder="Search tests..."
                />
              </Card.Header>

              <Card.Body className="table-full-width table-responsive px-0">
                <Table className="table-hover table-striped">
                  <thead>
                    <tr>
                      <th className="border-0" onClick={() => flipSort("owner")}>
                        Crew ID
                        <SetSortIcon sortName="owner" />
                      </th>
                      <th className="border-0" onClick={() => flipSort("employee_demographics")}>
                        Name
                        <SetSortIcon sortName="employee_demographics" />
                      </th>
                      <th className="border-0" onClick={() => flipSort("phone")}>
                        Phone
                        <SetSortIcon sortName="phone" />
                      </th>
                      <th className="border-0" onClick={() => flipSort("siteID")}>
                        Show
                        <SetSortIcon sortName="siteID" />
                      </th>
                      <th className="border-0" onClick={() => flipSort("labID")}>
                        Lab
                        <SetSortIcon sortName="labID" />
                      </th>
                      <th className="border-0" onClick={() => flipSort("barcode")}>
                        Barcode
                        <SetSortIcon sortName="barcode" />
                      </th>
                      <th className="border-0" onClick={() => flipSort("createdAt")}>
                        Batch
                        <SetSortIcon sortName="createdAt" />
                      </th>
                      <th className="border-0" onClick={() => flipSort("status")}>
                        Status
                        <SetSortIcon sortName="status" />
                      </th>
                      <th className="border-0" onClick={() => flipSort("result")}>
                        Result
                        <SetSortIcon sortName="result" />
                      </th>
                      <th className="border-0">CT Score</th>
                      <th className="border-0">Actions</th>
                    </tr>
                  </thead>
                  <tbody>
                    {testsToMap?.map((test) => {
                      return <TableRow key={test.id} test={test} />;
                    })}
                  </tbody>
                </Table>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
      <Modal
        show={modalShow}
        style={{ transform: "translate(0, -30%)" }}
        animation={true}
        onHide={() => {
          setMoreInfo({});
          setModalShow(false);
        }}
        centered
        size={"md"}
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">{moreInfo.name}'s Contact Info</Modal.Title>
        </Modal.Header>

        <Modal.Body style={{ paddingTop: 0, marginRight: "auto", marginLeft: "auto" }}>
          <p>Contact Name: {moreInfo.contact}</p>
          <p>Contact Phone: {moreInfo.phone}</p>
          <p>
            Contact Email: <a href={`mailto:${moreInfo.email}`}>{moreInfo.email}</a>
          </p>
        </Modal.Body>

        <Modal.Footer>
          <Button
            variant="secondary"
            onClick={() => {
              setMoreInfo({});
              setModalShow(false);
            }}
          >
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default ClientTests;
