import Filter from "components/Filter";
import Icon from "components/Icon";
import Loader from "components/Loader/Loader";
import ProcedureImportModal from "components/Modal/ProcedureImportModal";
import Status from "components/Status";
import MainTable from "components/Table/MainTable";
import { PROCEDURE_CODE_PERSONALIZE } from "constants/personalization";
import { debounce } from "debounce";
import moment from "moment";
import { useCallback, useEffect, useStat, useMemo, useState, useContext } from "react";
import { Card, Col, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { selectLoginUser } from "store/features/authentication/authenticationSelectors";
import { setMessage } from "store/features/general/generalAction";
import { setLoader } from "store/features/general/generalSlice";
import "../../../src/tableStyling.css";
import AddProcedureCodeSettingsModal from "../../components/GeneralSettings/AddProcedureCodeSettingsModal";
import { MESSAGE_MODES, TABLE_QUICK_TOOLS, PROCEDURE_CODES, PAGE_LIMIT } from "../../constant";
import {
  cptCodeSelector,
  openCreateCPTModalSelector,
  selectedCPTItemSelector,
  totalCptRecordsSelector,
} from "../../store/features/cptCodes/cptCodeSelectors";
import {
  fetchCPTCodeAsync,
  setOpenCreateCPTModal,
  setSelectedCPTItem,
} from "../../store/features/cptCodes/cptCodeSlice";
import { t } from "../../stringConstants";
import { draggablePersonalizationLocalStorage, userCompanyID, formatNumber } from "../../utils";
import { userGivenPermission } from "store/features/authentication/authenticationSelectors";
import PGPagination from "components/Pagination/PGPagination";
import { AppContext } from "context/app-context";
import PersonalizationModal from "components/Modal/personalizationModal";
import api from "api";

const ProcedureCodeSettings = (props) => {
  const [sortBy, setSortBy] = useState("");
  const procedureList = useSelector(cptCodeSelector);
  const totalCptRecords = useSelector(totalCptRecordsSelector);
  const [sortDescending, setSortDescending] = useState(true);
  const [showFilter, setShowFilter] = useState(false);
  const [loading, setLoading] = useState(false);
  const [filter, setFilter] = useState({});
  const [openImportModal, setOpenImportModal] = useState(false);
  const dispatch = useDispatch();
  const selectedCPTItem = useSelector(selectedCPTItemSelector);
  const openCreateCPTModal = useSelector(openCreateCPTModalSelector);
  const user = useSelector(selectLoginUser);
  const permission = useSelector(userGivenPermission);
  const [pageNo, setPageNo] = useState(1);
  const [checkboxes, setCheckboxes] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);

  const [openPersonalizationModal, setOpenPersonalizationModal] = useState(false);

  const [personalize, setPersonalize] = useState([]);

  const [filterProcedureList, setFilterProcedureList] = useState([]);

  const quickTools = [permission[PROCEDURE_CODES]?.write && TABLE_QUICK_TOOLS.edit];

  const searchTerms = ["Procedure Code", "Alias", "Procedure Code Desc", "Internal Charge", "Active"];

  const appContext = useContext(AppContext);

  useEffect(() => {
    if (pageNo > 1) {
      const concatResult = filterProcedureList.concat(procedureList);
      setFilterProcedureList(concatResult);
    } else {
      setFilterProcedureList(procedureList);
    }
  }, [procedureList]);

  const fetchFromApi = useCallback(
    debounce(async (filter, sortBy, sortDescending, page) => {
      dispatch(
        fetchCPTCodeAsync({
          ...(Object.keys(filter).length > 0 ? filter : {}),
          orderBy: sortBy,
          orderByDirection: sortDescending,
          page: page || 1,
        })
      );
    }, 1000),
    []
  );

  useEffect(() => {
    setPageNo(1);
    fetchFromApi(filter, sortBy, sortDescending, 1);
  }, [filter, sortBy, sortDescending]);

  const flipSort = (by) => {
    setSortDescending(sortBy === by ? !sortDescending : true);
    setSortBy(by);
  };

  const procedureCodesToMap = useMemo(() => {
    const list = filterProcedureList ? [...filterProcedureList] : [];
    const ttlPages = list.length > PAGE_LIMIT ? Math.ceil(list.length / PAGE_LIMIT) : 1;
    setTotalPages(ttlPages);
    if (list.length < PAGE_LIMIT || currentPage > ttlPages) {
      setCurrentPage(1);
    }
    if (ttlPages > 1) {
      const indexOfLastUser = currentPage * PAGE_LIMIT;
      const indexOfFirstUser = indexOfLastUser - PAGE_LIMIT;
      return list.slice(indexOfFirstUser, indexOfLastUser);
    }
    return list;
  }, [filterProcedureList, currentPage]);

  const handleChangePage = (page, direction) => {
    setCurrentPage(page);
    const currentRecord = filterProcedureList.length;
    if (direction === "next" && page === totalPages && totalCptRecords > 0 && currentRecord < totalCptRecords) {
      setPageNo(pageNo + 1);
    }
  };

  const handleImport = async (procedureData) => {
    setOpenImportModal(false);
    try {
      if (procedureData.length === 0) return;
      dispatch(setLoader(true));
      const arr = [];
      const notNpiFound = [];
      const ttlLength = procedureData.length;
      const alphanumericRegex = /^[A-Za-z0-9]+$/;
      const uniqueProcedureCodes = new Set();
      const duplicateCounts = new Map();
      for (let index = 0; index < ttlLength; index++) {
        const { data } = procedureData[index];
        const procedure = { ...data };
        if (!procedure.proc_code || !procedure.proc_code.trim() || !alphanumericRegex.test(procedure.proc_code)) {
          notNpiFound.push(procedure);
          continue;
        }
        if (uniqueProcedureCodes.has(procedure.proc_code)) {
          const count = duplicateCounts.get(procedure.proc_code) || 0;
          duplicateCounts.set(procedure.proc_code, count + 1);
          continue;
        }
        uniqueProcedureCodes.add(procedure.proc_code);
        if (!procedure.alias || !procedure.alias.trim() || !alphanumericRegex.test(procedure.procedure_code)) {
          notNpiFound.push(procedure);
          continue;
        }
        if (!procedure.proc_desc || !procedure.proc_desc.trim()) {
          notNpiFound.push(procedure);
          continue;
        }
        if (!procedure.charge || !procedure.charge.trim()) {
          notNpiFound.push(procedure);
          continue;
        }
        if (!procedure.active || !procedure.active.trim()) {
          notNpiFound.push(procedure);
          continue;
        }

        arr.push({
          ...procedure,
          clientID: userCompanyID.get(),
          requestedBy: user.sub,
          requestedByName: user.name,
        });
      }

      const fileName = `procedure_code/procedure_import${moment().format("DD_MM_YYYY_HH_mm_ss.SSS")}.csv`;
      // await api.saveFileOnBucket(arr, fileName);
      dispatch(setLoader(false));
      const duplicateMessage = Array.from(duplicateCounts)
        .map(([code, count]) => {
          return `${count} duplicate(s) of procedure code "${code}" found.`;
        })
        .join(" ");

      if (notNpiFound.length > 0 || duplicateMessage) {
        const message = `${notNpiFound.length} Procedure(s) is Invalid Not Found or Already Exist. ${duplicateMessage}`;
        dispatch(setMessage(message, MESSAGE_MODES.info));
      }
    } catch (err) {
      console.log(err);
      dispatch(setMessage(err, MESSAGE_MODES.error));
      dispatch(setLoader(false));
    }
  };

  const tdFormat = (item, row) => {
    if (item === "isActive")
      return row?.isActive ? (
        <div className="star-icon-wrapper">
          <Status type="circle" size="md" color="green" tickIcon />
        </div>
      ) : (
        <div className="star-icon-wrapper">
          <Status type="circle" size="md" color="maroon" crossIcon />
        </div>
      );
    return row[item];
  };

  const renderTd = (item, row) => {
    return (
      <td
        key={item.id}
        className="ellipsis"
        style={{
          textAlign: item.textAlign,
          textOverflow: item.textOverflow,
        }}
      >
        {tdFormat(item.itemKey, row)}
      </td>
    );
  };

  const handleCheckboxChange = (e, user) => {
    const filteredList = checkboxes.filter((c) => c !== user.id);
    if (e.target.checked) {
      filteredList.push(user.id);
    }
    setCheckboxes(filteredList);
  };

  const handleCellClick = (key, row, event) => {
    switch (key) {
      case TABLE_QUICK_TOOLS.edit:
        dispatch(setSelectedCPTItem({ item: row }));
        dispatch(setOpenCreateCPTModal(true));
        break;
      case TABLE_QUICK_TOOLS.checkbox:
        handleCheckboxChange(event, row);
        break;
      default:
        break;
    }
  };

  useEffect(() => {
    if (pageNo > 1) {
      fetchFromApi(filter, sortBy, sortDescending, pageNo);
    }
  }, [pageNo]);
  const loginUser = appContext.user;

  const handlePersonalization = async (data) => {
    setPersonalize([...data]);

    const personalisationData = draggablePersonalizationLocalStorage.saveAs(data, "cptCodes");
    setOpenPersonalizationModal(false);
    draggablePersonalizationLocalStorage.save(JSON.stringify(personalisationData));

    await api.saveUserPersonalisation(loginUser.sub, personalisationData);
  };

  useEffect(() => {
    const response = draggablePersonalizationLocalStorage.get(loginUser, "cptCodes", PROCEDURE_CODE_PERSONALIZE);
    setPersonalize(response);
  }, []);

  return (
    <div style={{ flex: 1 }}>
      {!loading ? (
        <Row>
          <Col>
            <Card className="strpied-tabled-with-hover">
              <Card.Header>
                <Card.Title
                  as="h4"
                  style={{
                    marginBottom: 10,
                    fontWeight: "bold",
                  }}
                >
                  {`${t("procedureCodes")} (${formatNumber(totalCptRecords)})`}
                </Card.Title>
                <div className="buttonHeader">
                  <Icon
                    handleClick={() => setShowFilter(!showFilter)}
                    title={"Filter"}
                    label={"Filter"}
                    iconType={"filter"}
                  />

                  <Icon
                    handleClick={() => setOpenPersonalizationModal(true)}
                    title={t("personalize")}
                    label={t("personalize")}
                    iconType={"personalizeIcon"}
                  />
                  {permission[PROCEDURE_CODES]?.write && (
                    <Icon
                      handleClick={() => dispatch(setOpenCreateCPTModal(true))}
                      title={"Create Procedure"}
                      label={"Create"}
                      iconType={"createIcon"}
                    />
                  )}
                </div>
                {showFilter && (
                  <Filter
                    filterTerms={searchTerms}
                    setFilter={setFilter}
                    filter={filter}
                    isUsersTable={true}
                    personalisationData={PROCEDURE_CODE_PERSONALIZE}
                  />
                )}
              </Card.Header>

              <Card.Body className="table-full-width desktop-noScroll">
                <div className="table-responsive pendingReleaseTable">
                  <Card.Header className="shows-screen-wrapper">
                    <Row className="pb-4">
                      <Col md-="6">
                        <Card.Title>
                          {/* <div className="">
                            Procedure codes:
                            <span className="ps-2"> {totalCptRecords || 0}</span>
                          </div> */}
                        </Card.Title>
                      </Col>
                      <Col md="6">
                        <PGPagination
                          currentPage={currentPage}
                          handleChangePage={handleChangePage}
                          totalPages={totalPages}
                          totalRecord={totalCptRecords}
                          currentRecord={filterProcedureList?.length}
                          totalSelected={checkboxes?.length}
                        />
                      </Col>
                    </Row>
                  </Card.Header>
                  <MainTable
                    columns={personalize}
                    rows={procedureCodesToMap}
                    flipSort={flipSort}
                    sortBy={sortBy}
                    draggable
                    resizable
                    sortDescending={sortDescending}
                    personalisationKey={"cptCodes"}
                    widthToSkip={155}
                    tools={quickTools}
                    selectedRows={checkboxes}
                    handleSaveDragAndResize={(personalizeArr) => setPersonalize(personalizeArr)}
                    handleCellClick={handleCellClick}
                    customColumnCellRenderer={renderTd}
                    dropDownOptions={[{ value: "Logs", title: "Audit Trail" }]}
                    handleDropDownClick={(type, row) => {
                      if (type === "Audit Trail") {
                        appContext.showLogs({ ...row, title: "Procedure Logs", recordType: "cpt" });
                      }
                    }}
                  />
                </div>
              </Card.Body>
            </Card>
          </Col>

          {openCreateCPTModal && (
            <AddProcedureCodeSettingsModal
              handleClose={() => {
                dispatch(setOpenCreateCPTModal(false));
                dispatch(setSelectedCPTItem(null));
              }}
              selectedItem={selectedCPTItem?.item || null}
            />
          )}

          {openPersonalizationModal && (
            <PersonalizationModal
              data={personalize}
              handleChange={handlePersonalization}
              show={openPersonalizationModal}
              handleClose={() => setOpenPersonalizationModal(false)}
            />
          )}
          {openImportModal && (
            <ProcedureImportModal
              handleClose={() => setOpenImportModal(false)}
              handleImport={handleImport}
              title={t("importProcedureDataTitle")}
            />
          )}
        </Row>
      ) : (
        <Loader />
      )}
    </div>
  );
};

export default ProcedureCodeSettings;
