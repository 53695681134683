/*!

=========================================================
* Light Bootstrap Dashboard React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/light-bootstrap-dashboard-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/light-bootstrap-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, { Component, useEffect, useState, useLayoutEffect } from "react";
import { useLocation, NavLink, useHistory } from "react-router-dom";

import { Nav } from "react-bootstrap";
import { logoImage } from "constant";

import logo from "assets/img/reactlogo.png";
import API from "api";
import { useRendersCount } from "react-use";
import { CONFIG } from "constant";
import moment from "moment";

function Sidebar({ color, image, routes, user, pendingTests, invalidTests, setTestFilter, testFilter }) {
  const location = useLocation();
  const [tests, setTests] = useState([]);
  const history = useHistory();

  const activeRoute = (routeName) => {
    return location.pathname.indexOf(routeName) > -1 ? "active" : "";
  };

  const isUserAllowed = (user, roles) => {
    let allowed = false;
    roles.forEach((role) => {
      if (user?.[role]()) {
        allowed = true;
      }
    });
    return allowed;
  };

  useEffect(() => {
    // console.log(pendingTests.length);
  }, [pendingTests]);

  const handleNavClick = () => {
    if (testFilter) setTestFilter(null);
  };

  return (
    <div className="sidebar" data-image={image} data-color={color}>
      <div
        className="sidebar-background"
        style={{
          backgroundImage: "url(" + image + ")",
        }}
      />
      <div className="sidebar-wrapper">
        <div className="logo d-flex align-items-center justify-content-start">
          {/* <a
            href="https://www.creative-tim.com?ref=lbd-sidebar"
            className="simple-text logo-mini mx-1"
          >
            <div className="logo-img">
              <img
                src={require("assets/img/reactlogo.png").default}
                alt="..."
              />
            </div>
          </a> */}
          <img
            onClick={() => history.push("/admin")}
            src={logoImage}
            alt="..."
            style={{ width: 240, ...(CONFIG.isWhiteLabel && { padding: "20px 10px" }) }}
          />
        </div>
        <Nav>
          {routes.map((prop, key) => {
            if (!prop.redirect)
              return (
                <li className={prop.upgrade ? "active active-pro" : activeRoute(prop.layout + prop.path)} key={key}>
                  <NavLink
                    to={prop.layout + prop.path}
                    className="nav-link"
                    activeClassName="active"
                    onClick={handleNavClick}
                  >
                    <i className={prop.icon} />
                    <p>
                      {prop.name}{" "}
                      {(prop.name === "Pending Release" || prop.name === "Inconclusive") &&
                        (pendingTests.length > 0 || invalidTests.length > 0) && (
                          <span className="sidebarListing-badge">
                            {prop.name === "Pending Release" ? pendingTests.length : invalidTests.length}
                          </span>
                        )}
                    </p>
                  </NavLink>
                </li>
              );
            return null;
          })}
          <div className="navFooter">
            <div>Version 1.0.0</div>
            <div>
              <div>{"11/19/2024 06:00AM"}</div>
            </div>
          </div>
        </Nav>
      </div>
    </div>
  );
}

export default Sidebar;
