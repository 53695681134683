export const DOCUMNET = "documents";

export const DOC_FETCH = "documentFetch";

export const DOC_CREATE = "documentCreate";

export const DOC_UPDATE = "documentUpdate";

export const DOC_DELETE = "documentDelete";

export const DOC_FETCH_TYPE = "documentFetchType";

export const DOC_TYPE_CREATE = "documentTypeCreate";
