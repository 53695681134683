import React from "react";
import { Button } from "react-bootstrap";
import Select from "react-select";

const TestCollapsibleMenu = ({ className, placeholder, value, onChange, options }) => {
  return (
    <Select
      className={`${className}`}
      options={options}
      blurInputOnSelect={true}
      menuPlacement={"auto"}
      value={value}
      placeholder={placeholder}
      onChange={onChange}
    />
  );
};

export default TestCollapsibleMenu;
