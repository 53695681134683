import React, { useState, useContext, useEffect } from "react";
import { Button, Modal, Table } from "react-bootstrap";
import { AppContext } from "context/app-context";
import api from "api";
import { connectedIDInLocalStorage } from "utils";
import Loader from "components/Loader/Loader";

const EmployeeDemoChange = (props) => {
  const { handleClose } = props;
  const [employees, setEmployees] = useState([]);
  const [loading, setLoading] = useState(false);
  const appContext = useContext(AppContext);

  useEffect(() => {
    loadData();
  }, []);

  const loadData = async () => {
    const items = await api.getPGEmployeeData(connectedIDInLocalStorage.get());
    console.log("items", items);
    setEmployees(items);
  };

  const handleOnClick = async (item) => {
    console.log("item", item);
    const model = await api.getRecordById(item.employeeID, "employee");
    const tests = await api.getTestByEmployee(item.employeeID);
    console.log("Model", model);
  };

  return (
    <>
      {!loading ? (
        <Modal show animation={true} onHide={() => handleClose()} size="xl">
          <Modal.Header closeButton>
            <Modal.Title className="my-0" id="contained-modal-title-vcenter">
              Employee Data
            </Modal.Title>
          </Modal.Header>

          <Modal.Body style={{ paddingTop: 0 }}>
            <div className="form-group-wrapper d-block">
              <div className="table-responsive">
                <Table className="table-hover table-striped">
                  <thead>
                    <tr>
                      <th
                        className={`border-0`}
                        style={{
                          color: "grey",
                          fontWeight: "bold",
                        }}
                      >
                        <div>
                          <span>Employee ID</span>
                        </div>
                      </th>
                      <th
                        className={`border-0  `}
                        style={{
                          color: "grey",
                          fontWeight: "bold",
                        }}
                      >
                        Demographics
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {employees.map((user, index) => {
                      return (
                        <tr>
                          <td>{user.employeeID}</td>
                          <td onClick={() => handleOnClick(user)}>
                            <p style={{ width: "30%", overflowWrap: "break-word" }}>{user.employee_demographics}</p>
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </Table>
              </div>
            </div>
          </Modal.Body>

          <Modal.Footer className="flex-row-reverse">
            <Button className="modalButtons" variant="primary" onClick={() => handleClose()}>
              OK
            </Button>
          </Modal.Footer>
        </Modal>
      ) : (
        <Loader />
      )}
    </>
  );
};

export default EmployeeDemoChange;
