import React, { useState, useContext, useMemo, useRef, useCallback } from "react";

// react-bootstrap components
import {
  Badge,
  Button,
  Card,
  Navbar,
  Nav,
  Table,
  Container,
  Row,
  Col,
  DropdownButton,
  Dropdown,
  Modal,
  Form,
} from "react-bootstrap";
import { useEffect } from "react";
import API from "api";
import { CSVLink } from "react-csv";
import { Importer, ImporterField } from "react-csv-importer";
import SortIcon from "components/SortIcon";
import { Link } from "react-router-dom";
import { PDFViewer } from "@react-pdf/renderer";
import PDF from "components/PDF";
import Filter from "components/Filter";
import TimeFilter from "components/TimeFilter";
import FilterSelect from "components/FilterSelect";
import { useLocation } from "react-router-dom";
import HeaderItem from "components/Table/HeaderItem";
import MFPagination from "components/Pagination/MFPagination";
import { PAGE_LIMIT } from "constant";
import GQAPI from "./gqApi";
import api from "api";
import {
  changeSort,
  getCrewsName,
  formatPhoneNumber,
  formatTest,
  sortingFilterInLC,
  calculateTdWidth,
  formatUTCDate,
  parseBooleanValue,
  connectedIDInLocalStorage,
} from "utils";
import PDFLayout from "components/PDF/PDFLayout";
import { AppContext } from "context/app-context";
import ExportToExcel from "components/ExportToExcel";
import TestPDFLayout from "components/PDF/TestPDFLayout";
import QuarantineModal from "components/Modal/QuarantineModal";
import Loader from "components/Loader/Loader";
import moment from "moment";
import { debounce } from "debounce";
import graphQLApi from "graphQLApi";
import Icon from "components/Icon";
import { stringConstant } from "constant";

const PositiveTestsApiNew = () => {
  const componentRef = useRef();
  const [width, setWidth] = useState(0);
  const siteID = window.location?.pathname?.split("/").pop();

  useEffect(() => {
    if (componentRef && componentRef.current) {
      setWidth(componentRef.current.offsetWidth);
    }
  }, [componentRef]);

  const handleResize = () => {
    if (componentRef && componentRef.current) setWidth(componentRef.current.offsetWidth);
  };

  useEffect(() => {
    handleResize();
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  });

  const tdWidth = calculateTdWidth(width - 130, 10);

  const importResult = [];
  const [totalRecord, setTotalRecord] = useState(0);
  const [tests, setTests] = useState([]);
  const [filteredTests, setFilteredTests] = useState([]);
  const [labs, setLabs] = useState([]);
  const [sites, setSites] = useState([]);
  const [moreInfo, setMoreInfo] = useState({});
  const [sortBy, setSortBy] = useState("");
  const [sortDescending, setSortDescending] = useState(true);
  const [testsLab, setTestsLab] = useState();
  const [testPdf, setTestPdf] = useState();
  const [showPdf, setShowPdf] = useState(false);
  const [timeFilter, setTimeFilter] = useState({});
  const [showFilter, setShowFilter] = useState(false);
  const [filter, setFilter] = useState({});
  const [triggerFilter, setTriggerFilter] = useState();
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [openQuarantined, setOpenQuaratined] = useState(false);
  const { filterDates } = TimeFilter;
  const [selectedTest, setSelectedTest] = useState("");
  const [pageNo, setPageNo] = useState(1);
  const [loading, setLoading] = useState(false);
  const [checkboxes, setCheckboxes] = useState([]);
  const location = useLocation();

  const appContext = useContext(AppContext);

  const searchTerms = [
    "First Name",
    "Last Name",
    "Date of Birth",
    "Phone",
    "Email",
    "Show",
    "Status",
    "Result",
    `${stringConstant.sequenceNo}`,
    "Test Type",
    "False Positive",
    "isVaccinated",
    "White Glove",
    "Pattern",
    "Capacity",
  ];

  const selectedItem = [
    "batch",
    "firstName",
    "lastName",
    "dob",
    "phoneNumber",
    "email",
    "siteID",
    "status",
    "result",
    "sequenceNo",
    "test_type",
    "isFalsePositive",
  ];

  //   useEffect(() => {
  //     labs.length !== 0 && sites.length !== 0 && setTests(appContext.completedTests);
  //   }, [sites, labs]);

  const findPositiveTests = (tests) => {
    return tests.filter((test) => test.result?.toLowerCase() === "positive" && test.status === "Processed");
  };
  const nestedFilter = (targetArray, filters) => {
    if (Object.keys(filter).length === 0) return targetArray;
    const filterKeys = Object.keys(filters);
    //filters main array of objects
    const models = targetArray.filter((obj) => {
      //goes through each key being filtered for
      return filterKeys.every((key) => {
        if (!filters[key].length) {
          return true;
        }
        if (key === "phoneNumber") {
          return obj[key] && obj[key].replace(/\D/g, "").includes(filters[key].replace(/\D/g, ""));
        }
        if (key === "sequenceNo") {
          return obj[key] && obj["sequenceNo"].toString().includes(filters[key]);
        }
        if (key === "Show") {
          return obj.siteID && appContext.getSiteName(obj.siteID).toLowerCase().includes(filters[key].toLowerCase());
        }
        if (key === "ShowID") {
          return obj.siteID === filters[key];
        }
        if (key === "isFalsePositive") {
          return parseBooleanValue(obj[key]).toLowerCase().includes(filters[key]?.toLowerCase());
        }

        if (key === "dob") {
          return obj[key] && obj[key] === filters[key];
        }

        if (key === "test_type" && filters[key] && filters[key].length > 0) {
          return obj[key] && filters[key].indexOf(obj[key]) !== -1;
        }

        return obj[key] && obj[key].toLowerCase().includes(filters[key].toLowerCase());
      });
    });
    return models;
  };

  const displayPdf = async (test) => {
    handleSaveFilter();
    const empID = await appContext.getEmployeeID(test.employeeID);
    const t = test;
    t.empID = empID;
    appContext.getSite(test).then(async (resp) => {
      setTestsLab(resp);
      setShowPdf(true);
    });
    setTestPdf(formatTest(test));
  };

  const flipSort = (by) => {
    setSortBy(by);
    setSortDescending(sortBy === by ? !sortDescending : true);
  };

  const testsToMap = useMemo(() => {
    const list = filteredTests ? [...filteredTests] : [];
    const ttlPages = list.length > PAGE_LIMIT ? Math.ceil(list.length / PAGE_LIMIT) : 1;
    setTotalPages(ttlPages);
    if (list.length < PAGE_LIMIT || currentPage > ttlPages) {
      setCurrentPage(1);
    }
    if (ttlPages > 1) {
      const indexOfLastUser = currentPage * PAGE_LIMIT;
      const indexOfFirstUser = indexOfLastUser - PAGE_LIMIT;
      return list.slice(indexOfFirstUser, indexOfLastUser);
    }
    return list;
  }, [filteredTests, currentPage]);

  useEffect(() => {
    const savedFilters = appContext.testFilter;
    if (savedFilters && savedFilters.location === "positivetest") {
      if (Object.keys(savedFilters.filter).length > 0) {
        setFilter(savedFilters.filter);
        setShowFilter(true);
      }
    }
    const sortingFilters = sortingFilterInLC.get();
    if (sortingFilters.positivetest.sortBy) {
      setSortDescending(sortingFilters.positivetest.sortDescending);
      setSortBy(sortingFilters.positivetest.sortBy);
    }
  }, []);

  useEffect(() => {
    if (appContext.eventObject && appContext.eventObject !== 0) {
      const eventType = appContext.eventObject.opType;
      const model = formatTest(appContext.eventObject.element);

      if (model.status !== "Processed" || model.result.toLowerCase() !== "positive") return;
      const newList = [...filteredTests];

      if (eventType === "INSERT") {
        newList.unshift(model);
      } else if (eventType === "UPDATE") {
        const index = newList.findIndex((t) => t.id === model.id);
        if (index !== -1) {
          newList.splice(index, 1, model);
        } else {
          newList.unshift(model);
        }
      }
      const testAfterFilter = filterDates(nestedFilter([...newList], filter), timeFilter);
      if (filteredTests.length < testAfterFilter.length) {
        setTotalRecord(totalRecord + 1);
      }
      refreshTestList(testAfterFilter);
    }
  }, [appContext.eventObject]);

  useEffect(() => {
    const prevSortingState = sortingFilterInLC.get();
    prevSortingState["positivetest"] = { filter, timeFilter, sortBy, sortDescending };
    sortingFilterInLC.save(prevSortingState);
    if (filteredTests.length === 0) return;
    changeSort(
      sortBy,
      sortDescending,
      filteredTests,
      setFilteredTests,
      appContext.getSiteName,
      //appContext.getLabName,
      appContext.getClientName,
      appContext.parseResult
    );
  }, [sortBy, sortDescending]);

  useEffect(() => {
    setSites(appContext.sites);
    setLabs(appContext.labs);
  }, [appContext.sites, appContext.labs]);

  // useEffect(() => {
  //   fetchFromApi({ ...filter }, { ...timeFilter }, 1);
  // }, [filter, timeFilter]);

  useEffect(() => {
    fetchFromApi({ ...filter }, { ...timeFilter }, 1, sortBy, sortDescending);
  }, [filter, timeFilter, sortBy, sortDescending]);

  useEffect(() => {
    if (location.state) {
      setShowFilter(true);
      const filterObj = { ...filter };
      if (location.state.term) Object.assign(filterObj, { [location.state.term]: location.state.name });
      if (location.state.showId) Object.assign(filterObj, { ["ShowID"]: location.state.showId });

      setFilter(filterObj);
      setTriggerFilter(true);
      setTimeout(() => setShowFilter(), 1);
    }
  }, [location.state]);

  useEffect(() => {
    if (pageNo > 1) {
      loadTestFromPG(Object.keys(filter).length === 0 ? { id: 1 } : filter, timeFilter, pageNo);
    }
  }, [pageNo]);

  // const fetchFromApi = useCallback(
  //   debounce((filter, timeFilter, page) => {
  //     loadTestFromPG(filter, timeFilter, page);
  //   }, 800),
  //   []
  // );
  const fetchFromApi = useCallback(
    debounce((filter, timeFilter, page, sortBy, sortDescending) => {
      loadTestFromPG(filter, timeFilter, page, sortBy, sortDescending);
    }, 800),
    []
  );

  const loadTestFromPG = async (params, dateFilter, page) => {
    setLoading(true);
    const filterParam = params;
    Object.assign(filterParam, {
      limit: 1000,
      page: pageNo,
      status: "Processed",
      result: "Positive",
      siteID: siteID || "E",
    });

    if (dateFilter) {
      const { start, end } = dateFilter;
      if (start)
        Object.assign(filterParam, { startDate: moment(start.format("YYYY-MM-DD")).startOf("day").toISOString() });
      if (end) Object.assign(filterParam, { endDate: moment(end.format("YYYY-MM-DD")).endOf("day").toISOString() });
    }
    const pgList = await API.getTestListFromPG(filterParam);
    setLoading(false);
    const ttlTests = tests.concat(pgList.rows.map((t) => formatTest(t)));
    if (pageNo === 1) {
      setTotalRecord(pgList.total);
    }
    setTests(ttlTests);
    refreshTestList(ttlTests);
  };

  const refreshTestList = (list) => {
    if (sortBy) {
      setFilteredTests(
        sortTestList(
          sortBy,
          sortDescending,
          list,
          appContext.getSiteName,
          appContext.getLabName,
          appContext.getClientName,
          appContext.parseResult
        )
      );
    } else {
      setFilteredTests(list);
    }
  };

  const handleCheckboxChange = (e, test) => {
    const filteredList = checkboxes.filter((c) => c !== test.id);
    if (e.target.checked) {
      filteredList.push(test.id);
    }

    setCheckboxes(filteredList);
    setSelectedTest(test);
  };

  const handleChangePage = (page, direction) => {
    setCurrentPage(page);
    const currentRecord = filteredTests.length;
    if (direction === "next" && page === totalPages && totalRecord > 0 && currentRecord < totalRecord) {
      setPageNo(pageNo + 1);
    }
  };

  const handleSaveFilter = () => {
    appContext.setTestFilter({ location: "positivetest", filter, timeFilter });
  };

  const TableRow = ({ test }) => {
    return (
      <tr>
        <td className="ellipsis" style={{ textAlign: "left" }}>
          {/* <Form.Check>
            <Form.Check.Input
              disabled={test.quarantinedStart}
              type="checkbox"
              value={selectedTest.id}
              checked={selectedTest.id === test.id}
              onChange={(e) => (e.target.checked ? setSelectedTest(test) : setSelectedTest(""))}
            ></Form.Check.Input>
          </Form.Check> */}
          <Form.Check className="me-2">
            <Form.Check.Input
              type="checkbox"
              value={test.id}
              checked={checkboxes.indexOf(test.id) !== -1}
              onChange={(e) => handleCheckboxChange(e, test)}
            ></Form.Check.Input>
          </Form.Check>
        </td>
        <td className="table-column ellipsis" title={formatUTCDate(test.createdAt)}>
          {formatUTCDate(test.createdAt)}
        </td>
        <td className="table-column ellipsis" title={test.firstName}>
          {test.firstName}
        </td>
        <td className="table-column ellipsis" title={test.lastName}>
          {test.lastName}
        </td>
        <td className="table-column ellipsis" title={test.dob}>
          {test.dob}
        </td>

        <td
          className="table-column ellipsis"
          style={{ textDecoration: "underline", color: "#A82632" }}
          onMouseOver={(e) => {
            e.target.style.cursor = "pointer";
            e.target.style.textDecoration = "none";
          }}
          onMouseLeave={(e) => {
            e.target.style.textDecoration = "underline";
          }}
          onClick={() => {
            test.phoneNumber && window.open(`tel:${test.phoneNumber}`);
          }}
          title={test.phoneNumber && formatPhoneNumber(test.phoneNumber)}
        >
          {test.phoneNumber && formatPhoneNumber(test.phoneNumber)}
        </td>
        <td
          className="table-column ellipsis"
          style={{
            textDecoration: "underline",
            color: "#A82632",
          }}
          onMouseOver={(e) => {
            e.target.style.cursor = "pointer";
            e.target.style.textDecoration = "underline";
          }}
          onClick={() => test?.email && window.open(`mailto:${test.email}`)}
          title={test?.email}
        >
          {test?.email}
        </td>
        <td className="table-column ellipsis" style={{ textAlign: "center" }}>
          {appContext.getSiteName(test.siteID)}
        </td>
        <td className="table-column ellipsis" style={{ textAlign: "center" }}>
          {test.status}
        </td>
        <td className="table-column ellipsis" style={{ textAlign: "center" }}>
          <span
            onMouseOver={(e) => {
              e.target.style.cursor = "pointer";
              e.target.style.textDecoration = "none";
            }}
            onMouseLeave={(e) => {
              e.target.style.textDecoration = "underline";
            }}
            onClick={() => displayPdf(test)}
            style={{
              color:
                test.result?.toLowerCase() === "negative" || test.result?.toLowerCase() === "pass" ? "green" : "red",
              textDecoration: "underline",
            }}
          >
            {appContext.parseResult(test)}
          </span>
        </td>
        <td className="table-column ellipsis" style={{ textAlign: "center" }}>
          {parseBooleanValue(test.isFalsePositive)}
        </td>
        <td className="table-column ellipsis" style={{ textAlign: "center" }} title={test.sequenceNo}>
          {test.sequenceNo}
        </td>
        <td className="table-column ellipsis" style={{ textAlign: "center" }}>
          {test.test_type || "-"}
        </td>
        <td className="table-column ellipsis" style={{ textAlign: "center" }}>
          <Link
            style={{ color: "#42cef5" }}
            to={{
              pathname: "/site/test/" + test.siteID + "/" + test.id,
              state: { type: "siteTotalTests", test: test },
            }}
            onClick={handleSaveFilter}
          >
            View
          </Link>
        </td>
      </tr>
    );
  };

  // const handleSaveQuarantine = async (obj) => {
  //   try {
  //     await API.updateQuarantineData(selectedTest.id, obj.startDate, obj.endDate);
  //     appContext.showSuccessMessage(`Sequence ${selectedTest.sequenceNo} enabled quarantine successfully`);
  //     window.location.reload();
  //   } catch (err) {
  //     appContext.showErrorMessage(err.message);
  //   }
  // };

  const handleSaveQuarantine = async (obj) => {
    try {
      const model = await api.getRecordById(selectedTest.id, "test");

      const params = [
        {
          id: selectedTest.id,
          quarantinedStart: obj.startDate,
          quarantinedEnd: obj.endDate,
          _version: model[0]?._version,
        },
      ];
      setTimeout(async () => {
        await GQAPI.releaseTest(params);
      }, 500);

      appContext.showSuccessMessage(`Sequence ${selectedTest.sequenceNo} enabled quarantine successfully`);
      // window.location.reload();
    } catch (err) {
      appContext.showErrorMessage(err.message);
    }

    setTimeout(async () => {
      await API.updateQuarantineData(selectedTest.id, obj.startDate, obj.endDate);
    }, 500);
  };

  return showPdf ? (
    <PDFLayout setShowPdf={setShowPdf} testPdf={testPdf} testsLab={testsLab} />
  ) : (
    <>
      <Container fluid style={{ flex: 1 }}>
        <Row>
          <Col md="12">
            <Card className="strpied-tabled-with-hover">
              <Card.Header>
                <Card.Title
                  as="h4"
                  style={{
                    marginBottom: 10,
                    fontWeight: "bold",
                    color: "#565656",
                  }}
                >
                  Positive Tests ({filteredTests.length})
                </Card.Title>
                <div className="buttonHeader">
                  {/* <Button className="headerButton" onClick={() => setShowFilter(!showFilter)}>
                    Filter
                  </Button> */}
                  <Icon
                    handleClick={() => setShowFilter(!showFilter)}
                    title={"Filter"}
                    label={"Filter"}
                    iconType={"filter"}
                  />
                  {/* Date Range */}
                  <FilterSelect setTimeFilter={setTimeFilter} />
                  {/* <Icon
                        handleClick={async () => {
                          if (filteredTests && filteredTests.length > 0) {
                            setAllSelected(!allSelected);
                            toggleCheckboxes(!allSelected);
                          }
                        }}
                        title={!allSelected ? "Select All" : "Deselect All"}
                        label={!allSelected ? "Select All" : "Deselect All"}
                        iconType={"selectAllIcon"}
                      /> */}
                  {showFilter && filter[0] != "" && (
                    <>
                      <ExportToExcel
                        items={filteredTests}
                        selectedItem={selectedItem}
                        appContext={appContext}
                        //filter={filter}
                        filter={{ ...filter, result: "Positive", siteID: siteID }}
                        timeFilter={timeFilter}
                        title="Positive Tests"
                        sortBy={sortBy}
                      />
                      <TestPDFLayout
                        tests={filteredTests}
                        selectedItem={selectedItem}
                        filter={filter}
                        //filter={{ ...filter, result: "Positive", siteID: siteID , test_type:["PCR"] }}
                        timeFilter={timeFilter}
                        title="Positive Tests"
                        appContext={appContext}
                        sortBy={sortBy}
                      />
                    </>
                  )}
                  <Icon
                    handleClick={() => setOpenQuaratined(true)}
                    title={"Enable Quarantined"}
                    label={"Enable Quarantined"}
                    iconType={"qr_Icon"}
                  />

                  {/* <Button
                    className="headerButton"
                    disabled={selectedTest ? false : true}
                    onClick={() => setOpenQuaratined(true)}
                  >
                    Enable Quarantined
                  </Button> */}
                </div>
                {showFilter && (
                  <Filter
                    filterTerms={searchTerms}
                    setFilter={setFilter}
                    filter={filter}
                    triggerFilter={triggerFilter}
                    setTriggerFilter={setTriggerFilter}
                  />
                )}
                <MFPagination
                  timeFilter={timeFilter}
                  currentPage={currentPage}
                  handleChangePage={handleChangePage}
                  totalPages={totalPages}
                  showSelectedRecord
                  totalSelected={checkboxes.length}
                />
              </Card.Header>

              <Card.Body className="table-full-width px-0 desktop-noScroll">
                <div className="table-responsive pendingReleaseTable">
                  <Table responsive className="table-hover table-striped">
                    <thead ref={componentRef}>
                      <tr>
                        <th></th>
                        <HeaderItem
                          width={tdWidth}
                          ItemKey="createdAt"
                          title="Date Tested"
                          flipSort={flipSort}
                          sortBy={sortBy}
                          sortDescending={sortDescending}
                        />
                        <HeaderItem
                          width={tdWidth}
                          ItemKey="firstName"
                          title="First Name"
                          flipSort={flipSort}
                          sortBy={sortBy}
                          sortDescending={sortDescending}
                        />
                        <HeaderItem
                          width={tdWidth}
                          ItemKey="lastName"
                          title="Last Name"
                          flipSort={flipSort}
                          sortBy={sortBy}
                          sortDescending={sortDescending}
                        />
                        <HeaderItem
                          width={tdWidth}
                          ItemKey="dob"
                          title="DoB"
                          flipSort={flipSort}
                          sortBy={sortBy}
                          sortDescending={sortDescending}
                        />
                        <HeaderItem
                          width={tdWidth}
                          ItemKey="phoneNumber"
                          title="Phone"
                          flipSort={flipSort}
                          sortBy={sortBy}
                          sortDescending={sortDescending}
                        />

                        <HeaderItem
                          width={tdWidth}
                          ItemKey="email"
                          title="Email"
                          flipSort={flipSort}
                          sortBy={sortBy}
                          sortDescending={sortDescending}
                        />

                        <HeaderItem
                          width={tdWidth}
                          ItemKey="siteID"
                          title="Show"
                          flipSort={flipSort}
                          sortBy={sortBy}
                          sortDescending={sortDescending}
                          aligned="centered"
                        />

                        <HeaderItem
                          width={tdWidth}
                          ItemKey="status"
                          title="Status"
                          flipSort={flipSort}
                          sortBy={sortBy}
                          sortDescending={sortDescending}
                          aligned="centered"
                        />

                        <HeaderItem
                          width={tdWidth}
                          ItemKey="result"
                          title="Result"
                          flipSort={flipSort}
                          sortBy={sortBy}
                          sortDescending={sortDescending}
                          aligned="centered"
                        />
                        <HeaderItem
                          width={tdWidth}
                          ItemKey="isFalsePositive"
                          title="False Positive"
                          flipSort={flipSort}
                          sortBy={sortBy}
                          sortDescending={sortDescending}
                          aligned="centered"
                        />
                        <HeaderItem
                          width={tdWidth}
                          ItemKey="sequenceNo"
                          title="Sequence"
                          flipSort={flipSort}
                          sortBy={sortBy}
                          sortDescending={sortDescending}
                          aligned="centered"
                        />

                        <HeaderItem
                          width={tdWidth}
                          ItemKey="test_type"
                          title="Test Type"
                          flipSort={flipSort}
                          sortBy={sortBy}
                          sortDescending={sortDescending}
                          aligned="centered"
                        />
                        <th
                          className="border-0 centered text-grey"
                          style={{ fontWeight: "bold", width: "80px", minWidth: "80px", maxWidth: "80px" }}
                        >
                          Actions
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {testsToMap &&
                        testsToMap.map((test) => {
                          return <TableRow key={test.id} test={test} />;
                        })}
                    </tbody>
                  </Table>
                </div>
              </Card.Body>
            </Card>
          </Col>
          {openQuarantined && (
            <QuarantineModal
              test={selectedTest}
              handleSave={handleSaveQuarantine}
              handleClose={() => setOpenQuaratined(false)}
            />
          )}
        </Row>
      </Container>
      {loading && <Loader />}
    </>
  );
};

export default PositiveTestsApiNew;
