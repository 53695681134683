import React, { useEffect, useRef, useState } from "react";
import logoImage from "../../assets/img/safecamp_BRAND.1-nobg.png";
import html2canvas from "html2canvas";
import QRCode from "qrcode.react";
import Default_Profile from "../../assets/img/default_profile.png";
import { Button } from "react-bootstrap";
import { isValidEmail } from "utils";
// import QrCodeDownloadIcon from "../../assets/img/qr-download.svg";
import QrCodeDownloadIcon from "../../assets/img/qr-download.svg";
import { qrResponseJOSN } from "utils";

const QRCodeView = (props) => {
  const { handleProcced, user, profileImage } = props;
  console.log("new user from props: ", user);
  const downloadRef = useRef();
  const [error, setError] = useState("");
  const [newUser, setNewUser] = useState(user);
  const [qrRes, setQrRes] = useState();

  useEffect(() => {
    setNewUser(user);
    setQrRes(qrResponseJOSN(user, user.id, "PR"));
  }, [user]);

  const downloadQRCode = async () => {
    const element = downloadRef.current;
    await html2canvas(element, { allowTaint: true, useCORS: true, logging: true }).then((canvas) => {
      const imgData = canvas.toDataURL("image/png");
      const link = document.createElement("a");
      if (typeof link.download === "string") {
        link.href = imgData;
        link.download = "image.jpg";
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      } else {
        window.open(imgData);
      }
    });
  };

  const showErrorMessage = (message) => {
    setError(message);
    setTimeout(() => setError(""), 2000);
  };

  const handleSubmit = () => {
    if (!newUser.phone_number?.trim() && !newUser.email?.trim()) {
      showErrorMessage("Phone or Email is Required");
      return;
    }
    if (!newUser.phone_number) {
      showErrorMessage("Phone is required");
      return;
    }

    if (newUser.email?.trim() && !isValidEmail(newUser.email)) {
      showErrorMessage("Invalid Email");
      return;
    }
    let obj = {
      ...newUser,
      reorder: true,
      phone: newUser.phone_number,
    };
    handleProcced(obj);
  };

  return (
    <div className="codeScannerWrapper">
      <div className="registered-successful">
        <h3>Thank you for Registering</h3>
        <p className="mb-0">Please present this QR code at Event Check In</p>
        <div className="registered-inner" ref={downloadRef}>
          <h4 className="my-0 card-name">{`${newUser.first} ${newUser.last}`}</h4>
          <div className="justify-content-center align-items-center">
            <div className="qrWrapper">
              <img
                crossOrigin="true"
                src={
                  profileImage === Default_Profile
                    ? Default_Profile
                    : profileImage instanceof Object
                    ? URL.createObjectURL(profileImage)
                    : profileImage
                }
                alt="user icon"
                className="userIcon"
              />
              <QRCode
                className="qrCode"
                id="qr-gen"
                value={JSON.stringify(qrRes || {})}
                size={270}
                level={"L"}
                fgColor="#008000"
                includeMargin={true}
              />
            </div>
          </div>
        </div>
        {error && (
          <div className="error-message">
            <h6>{error}</h6>
          </div>
        )}

        <div className="d-flex align-items-center justify-content-between">
          <div className="text-right">
            <img src={QrCodeDownloadIcon} onClick={downloadQRCode} alt="qr-code-download" width="35" />
          </div>
          <Button className="rounded-pill btn btn-secondary" variant="secondary" onClick={handleSubmit}>
            Order Test Kit
          </Button>
          <Button
            className="rounded-pill btn btn-secondary"
            href={`safecampmd://app/${newUser.first || ""}/${newUser.last || ""}/${newUser.email || ""}/${
              newUser.phone_number || ""
            }`}
            variant="secondary"
          >
            Add to Wallet
          </Button>
        </div>
      </div>
    </div>
  );
};

export default QRCodeView;
