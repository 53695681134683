const TDNameWithDetail = (props) => {
  const { cssClass, title, label } = props;
  return (
    <td className="ellipsis w-TwelvePercent">
      {title || label ? (
        <div className="d-flex flex-column w-100">
          <span className="fs-6 secondary-gray text-ellipsis" title={title}>
            {title}
          </span>
          <p className={`mb-0 fs-14 text-primary-black text-ellipsis ${cssClass}`} title={label}>
            {label}
          </p>
        </div>
      ) : (
        <span className="fs-6 secondary-gray">No details</span>
      )}
    </td>
  );
};
export default TDNameWithDetail;
