import moment from "moment";
import { useEffect, useMemo, useState } from "react";
import { Button, Modal } from "react-bootstrap";
import Select from "react-select";
const EditTestDetailsModal = (props) => {
  const { testDetails, users, handleSave, handleClose } = props;
  const [test, setTest] = useState(testDetails);

  // const [client, setClient] = useState("");
  // const [site, setSite] = useState("");
  const [error, setError] = useState("");
  const [confirmText, setConfirmText] = useState("");

  useEffect(() => {
    setTimeout(() => {
      setError("");
    }, 4000);
  }, [error]);

  const onHandleSave = () => {
    if (!test) return;
    const obj = { ...test };
    if (!obj.tester_name.trim()) {
      setError("Please Select Tester");
      return;
    }
    if (!obj.createdAt) {
      setError("Invalid Date Collected");
      return;
    }
    if (obj.createdAt && !moment(obj.createdAt, "YYYY-MM-DDTHH:mm", true).isValid()) {
      setError("Invalid Date Collected");
      return false;
    }
    if (obj.createdAt) {
      Object.assign(obj, { createdAt: moment(obj.createdAt).utc().toISOString() });
    }
    handleSave(obj);
    handleClose();
  };

  // const getSiteOptions = useMemo(() => {
  //   if (!client) return [];
  //   return sites
  //     .filter((s) => s.clientID === client.value)
  //     .map((l) => {
  //       return { value: l.id, label: l.name };
  //     });
  // }, [client]);

  const usersData = useMemo(() => {
    return users
      .filter((u) => u.roles?.includes("Tester") || u.roles?.includes("Admin"))
      .map((u) => {
        return {
          value: u.userID,
          label: `${u.firstName} ${u.lastName}`,
          phone_number: u.phone_number?.replace(/ /g, ""),
        };
      });
  }, [users]);

  const getSelectedTester = () => {
    if (users.length > 0) {
      const tester = users.find((u) => u.userID === test.createdBy);
      if (tester) {
        return {
          value: tester.userID,
          label: `${tester.firstName} ${tester.lastName}`,
          phone_number: tester.phone_number,
        };
      }
    }
    return "";
  };

  return (
    <>
      <Modal
        show
        animation={true}
        onHide={() => {
          handleClose();
        }}
        style={{ paddingLeft: "0" }}
        centered
      >
        <Modal.Header closeButton className="testEdit-Modal-Header">
          <Modal.Title className="my-0" id="contained-modal-title-vcenter">
            Test Details
          </Modal.Title>
        </Modal.Header>
        <Modal.Body
          style={{
            paddingTop: 0,
          }}
        >
          {/* <div className="form-group-wrapper">
            <label className="modalLineHeaders">Stamp By </label>
            <Select
              className="w-100 siteSelector"
              options={users.map((u) => {
                return { value: u.userID, label: `${u.firstName} ${u.lastName}` };
              })}
              blurInputOnSelect={true}
              defaultValue={null}
              menuPlacement="auto"
              placeholder="Select User"
              // onChange={(e) => setClient(e)}
            />
          </div> */}
          {/* <div className="form-group-wrapper">
            <label className="modalLineHeaders"> Barcode: </label>
            <input
              className="modalInput"
              placeholder="Barcode"
              style={{
                width: "100%",
              }}
              value={test?.barcode}
              onChange={(e) => {
                setTest({
                  ...test,
                  barcode: e.target.value,
                });
              }}
            />
          </div> */}
          {/* <div className="form-group-wrapper">
            <label className="modalLineHeaders">Test Type </label>
            <Select
              className="w-100 siteSelector"
              options={externalTypesOptions}
              blurInputOnSelect={true}
              defaultValue={null}
              menuPlacement="auto"
              placeholder="Test Type"
              // onChange={(e) => handleTestType(e)}
            />
          </div> */}
          <div className="form-group-wrapper dateWrapper">
            <label className="modalLineHeaders"> Tester: </label>

            <Select
              className="w-100 siteSelector"
              options={usersData}
              blurInputOnSelect={true}
              defaultValue={null}
              menuPlacement="auto"
              value={getSelectedTester()}
              placeholder="Select Tester"
              onChange={(e) =>
                setTest({ ...test, createdBy: e.value, tester_name: e.label, testerPhone: e.phone_number })
              }
            />
          </div>
          <div className="form-group-wrapper dateWrapper">
            <label className="modalLineHeaders"> Date Collected: </label>
            <input
              className="modalInput"
              type="datetime-local"
              value={test?.createdAt}
              style={{ width: "100%", marginBottom: "10px" }}
              onChange={(e) => setTest({ ...test, createdAt: e.target.value })}
              max={moment().format("YYYY-MM-DDTHH:mm")}
            />
          </div>
          <div className="form-group-wrapper">
            <label className="modalLineHeaders">White Glove:</label>
            <div className="d-flex flex-basis-1 max-w-100 pl-3">
              <div className="radioBox-wrapper">
                <input
                  className="radioButton"
                  type="radio"
                  checked={test.employee_demographics?.whiteGlove}
                  name="whiteGlove"
                  onChange={(e) =>
                    setTest({
                      ...test,
                      employee_demographics: { ...test.employee_demographics, whiteGlove: e.target.checked && true },
                    })
                  }
                />
                <span className="radioButton">Yes</span>
              </div>
              <div className="radioBox-wrapper">
                <input
                  className="radioButton"
                  type="radio"
                  checked={!test.employee_demographics?.whiteGlove}
                  name="whiteGlove"
                  onChange={(e) =>
                    setTest({
                      ...test,
                      employee_demographics: { ...test.employee_demographics, whiteGlove: e.target.checked && false },
                    })
                  }
                />
                <span className="radioButton">No</span>
              </div>
            </div>
          </div>
          {error.length > 0 && <strong style={{ color: "Red" }}>{error}</strong>}
        </Modal.Body>
        <Modal.Footer>
          <Button
            style={{ marginBottom: 10 }}
            variant="secondary"
            className="modalButtons"
            onClick={() => {
              handleClose();
            }}
          >
            Close
          </Button>
          <Button style={{ marginBottom: 10 }} variant="primary" className="modalButtons" onClick={onHandleSave}>
            Save
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default EditTestDetailsModal;
