import { stringConstant } from "constant";
import React from "react";
import { Table } from "react-bootstrap";

const TestSubmitResponse = (props) => {
  const { title, data } = props;
  if (data.length === 0) return <></>;

  return (
    <>
      <h6>
        {title} - Total Test ( {data.reduce((ttl, { tests }) => ttl + tests, 0)} )
      </h6>
      <Table className="table-hover table-striped">
        <thead>
          <tr>
            <th
              className={`border-0 centered`}
              style={{
                color: "grey",
                fontWeight: "bold",
              }}
            >
              <div className="d-flex justify-content-start align-items-center">
                <span>Lab</span>
              </div>
            </th>
            <th
              className={`border-0 centered`}
              style={{
                color: "grey",
                fontWeight: "bold",
              }}
            >
              <div className="d-flex justify-content-start align-items-center">
                <span>{stringConstant.show}</span>
              </div>
            </th>
            <th
              className={`border-0 centered`}
              style={{
                color: "grey",
                fontWeight: "bold",
              }}
            >
              <div>
                <span># of Test</span>
              </div>
            </th>
          </tr>
        </thead>
        <tbody>
          {data.map((msg, index) => {
            return (
              <tr key={index}>
                <td>{msg.lab.name}</td>
                <td>{msg.show}</td>
                <td style={{ textAlign: "center" }}>{msg.tests}</td>
              </tr>
            );
          })}
        </tbody>
      </Table>
    </>
  );
};

export default TestSubmitResponse;
