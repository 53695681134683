import { useEffect, useRef, useState } from "react";

const useResizeObserver = (ref) => {
  const [dimensions, setDimensions] = useState(null);
  const observerRef = useRef(null);

  useEffect(() => {
    const observer = new ResizeObserver((entries) => {
      const entry = entries[0];
      setDimensions(entry.contentRect);
    });

    observerRef.current = observer;

    if (ref.current) {
      observer.observe(ref.current);
    }

    return () => {
      observer.disconnect();
    };
  }, [ref]);

  return dimensions;
};

export default useResizeObserver;
