export const INSURANCES = "insurances";

export const INSURANCES_FETCH = `${INSURANCES}Fetch`;

export const INSURANCES_FETCH_ALL = `${INSURANCES}FetchAll`;

export const INSURANCES_CREATE = `${INSURANCES}Create`;

export const INSURANCES_UPDATE = `${INSURANCES}Update`;

export const INSURANCES_DELETE = `${INSURANCES}Delete`;
