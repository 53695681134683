import React, { useState, useEffect, useMemo } from "react";
import { Document, Page, Text, View, StyleSheet, Image, Font } from "@react-pdf/renderer";
import {
  getDemoGraphics,
  getAge,
  formatDateOfBirthDOB,
  formatDateMDY,
  formatPDFName,
  toTitleCase,
  isLabImageExist,
} from "utils";
import { TEST_TYPE_DISCLAIMER_KEY } from "constant";
import { logoImage } from "constant";
import qrImage from "assets/img/patient_portal.png";
import ArialRegular from "assets/fonts/arial.ttf";
import ArialBold from "assets/fonts/arial_bold.ttf";
import { formatTimeFromDate, formatAddress } from "utils";
import { RESULTS_COLOR } from "constant";
import api from "api";
import { formatPhone } from "utils";
import moment from "moment";
import { isResultInRange } from "utils";
import { analyzeResultValue } from "utils";
import { parseTestResult } from "utils";
import { showAlphaResult } from "utils";
import { showTestResult } from "utils";
import { infectionResultBasedUponRange } from "utils";
import { parseTestUnit } from "utils";
import { generateTagLineQualitativeWithPanleTests } from "utils";

Font.register({
  family: "Arial",
  fonts: [
    { src: ArialRegular, fontWeight: "normal" },
    { src: ArialBold, fontWeight: "bold" },
  ],
});

const parseSrNoObjectRecviedDate = (test) => {
  try {
    const arr = JSON.parse(test.sr_no);
    let dateOfReceive = arr[0]?.date;
    return dateOfReceive;
  } catch (error) {
    return moment(test.createdAt).toISOString();
  }
};

const BiolabPdf = ({ test, testsLab, parseTestResultForPDF, employeeID, site }) => {
  const [clientInfo, setClientInfo] = useState();
  let demos = getDemoGraphics(test);
  const [disclaimerText, setDisclaimerText] = useState("");

  const { resultDetails, employee_demographics, testType } = test;

  const isQualtative = testType?.typeOfTest === "Qualitative" ? true : false;

  const { testTypeID } = employee_demographics;

  const fetchDisclaimer = async () => {
    try {
      const result = await api.getTestTypesByID(testTypeID);
      setDisclaimerText(result?.custom_notes || "");
    } catch (error) {
      console.error("Error fetching disclaimer:", error);
    }
  };

  useEffect(() => {
    fetchDisclaimer();
  }, [testTypeID]);

  useEffect(() => {
    if (test?.clientID) {
      getClientByID(test);
    }
  }, [test]);

  const getClientByID = async (test) => {
    let clientModal = await api.getClientById(test?.clientID);
    setClientInfo(clientModal);
  };

  const today = new Date();

  const time =
    today.getHours() > 12
      ? `${today.getHours() - 12}:${today.getMinutes()} PM`
      : `${today.getHours()}:${today.getMinutes()} AM`;

  const getLabDisclaimer = useMemo(() => {
    if (testsLab) {
      const disclaimerKey = TEST_TYPE_DISCLAIMER_KEY[test.test_type.replace(/(:|\s+)/g, "_")] || "testing_notes";
      const notes = testsLab[disclaimerKey];
      if (notes) return notes;
    }
    return "N/A";
  }, [test, testsLab]);

  const testTypeLabel = () => {
    const { test_type } = test;
    if (test_type === "RapidFlu" || test_type === "Rapid Flu") return "2022 - INFLUENZA A/B - Accula";
    if (test_type === "PCR") return "COVID-19 MOLECULAR RT-PCR";
    return test_type;
  };
  const baseTestTypeLabel = () => {
    const { test_type } = test;
    if (test_type === "RapidFlu" || test_type === "Rapid Flu") return "2022 - INFLUENZA A/B - Accula";
    if (test_type === "PCR") `2019-nCoV`;
    return "";
  };

  console.log("TEst", test);
  return (
    <Document title={`${demos.firstName} ${demos.lastName}`}>
      <Page size="A4" style={styles.page}>
        <View style={{ ...styles.flexRow, flex: 0.8 }}>
          <Image
            style={{ width: "220px", opacity: 1 }}
            // src={isLabImageExist(testsLab) ? require(`assets/img/${testsLab?.id}.png`).default : logoImage}
            src={logoImage}
          />
          <View style={{ ...styles.flexRow }}>
            <View style={{ display: "flex", alignItems: "flex-start", fontSize: 11 }}>
              <Text style={{ textTransform: "uppercase" }}>{testsLab?.street || ""}</Text>
              <Text style={{ textTransform: "uppercase" }}>{testsLab?.city_state_zip || ""}</Text>
              <Text>Tel: {formatPhone(testsLab?.phone_number) || ""}</Text>
              <Text>Fax: {formatPhone(testsLab?.fax_number)}</Text>
            </View>
            <Image src={qrImage} style={styles.qrCode} />
          </View>
        </View>
        <View style={{ border: "1px solid black", position: "relative", flex: 5 }}>
          <View style={{ ...styles.flexRow, lineHeight: 1.5 }}>
            <View style={{ width: "50%", padding: "15px 20px", borderRight: 1, borderColor: "black", fontSize: 10 }}>
              <View style={{ display: "flex", flexDirection: "row" }}>
                <Text>Acc:</Text>
                <View style={{ marginLeft: 5 }}>
                  <Text style={{ ...styles.textUppercase, ...styles.bold, color: "blue" }}>
                    {site?.name || clientInfo?.name || ""}
                  </Text>
                  <Text style={{ textTransform: "uppercase" }}>{site?.street || clientInfo?.street || ""}</Text>
                  <Text style={{ textTransform: "uppercase" }}>
                    {site ? formatAddress(site) : formatAddress(clientInfo)}
                  </Text>
                  <Text>{site ? formatPhone(site?.phone_number || "") : formatPhone(clientInfo?.phone_number)}</Text>
                </View>
              </View>
              <View style={{ display: "flex", marginTop: 10, alignItems: "center", flexDirection: "row" }}>
                <Text>Phys:</Text>
                <Text style={{ ...styles.textUppercase, ...styles.bold, marginLeft: 5 }}>
                  {site?.setting?.lab_director || testsLab?.lab_director || "-"}
                </Text>
              </View>
              <View style={{ display: "flex", alignItems: "center", flexDirection: "row" }}>
                <Text>CLIA#</Text>
                <Text style={{ ...styles.textUppercase, ...styles.bold, marginLeft: 5 }}>
                  {testsLab?.clia_number || "-"}
                </Text>
              </View>
            </View>
            <View style={{ width: "50%", ...styles.flexRow, fontSize: 10, justifyContent: "space-between" }}>
              <View style={{ flex: 2, width: "70%", padding: 10, paddingLeft: 20 }}>
                <View style={styles.flexRow}>
                  <Text style={{ minWidth: 60 }}>Patient:</Text>
                  <Text style={{ color: "blue", fontWeight: "bold" }}>{formatPDFName(demos)}</Text>
                </View>
                <View style={styles.flexRow}>
                  <Text style={{ minWidth: 60 }}>DOB:</Text>
                  <Text>{formatDateOfBirthDOB(demos.dob)}</Text>
                </View>
                <View style={styles.flexRow}>
                  <Text style={{ minWidth: 60 }}>Phone:</Text>
                  <Text>{formatPhone(test.phoneNumber)}</Text>
                </View>
                <View style={styles.flexRow}>
                  <Text style={{ minWidth: 60 }}>ID#:</Text>
                  <Text>{demos.idNumber || test.empID || ""}</Text>
                </View>
                <View style={styles.flexRow}>
                  <Text style={{ minWidth: 60 }}>Route#:</Text>
                  <Text>-</Text>
                </View>
                <View style={styles.flexRow}>
                  <Text style={{ minWidth: 60 }}>Fasting:</Text>
                  <Text>{demos?.fasting ? "Yes" : "No"}</Text>
                </View>
              </View>
              <View style={{ flex: 1.2, width: "30%", padding: 10, paddingRight: 20 }}>
                <View style={styles.flexRow}>
                  <Text style={{ minWidth: 60 }}>&nbsp;</Text>
                  <Text>&nbsp;</Text>
                </View>
                <View style={styles.flexRow}>
                  <Text style={{ minWidth: 60 }}>Age:</Text>
                  <Text>{`${getAge(demos.dob)} Years`}</Text>
                </View>
                <View style={styles.flexRow}>
                  <Text style={{ minWidth: 60 }}>Sex:</Text>
                  <Text>{demos?.sex}</Text>
                </View>
                <View style={styles.flexRow}>
                  <Text style={{ minWidth: 60 }}>Room#:</Text>
                  <Text>{demos?.street2}</Text>
                </View>
                <View style={styles.flexRow}>
                  <Text style={{ minWidth: 60 }}>Page:</Text>
                  <Text>1</Text>
                </View>
              </View>
            </View>
          </View>
          <View style={{ ...styles.hr }} />
          <View style={{ ...styles.flexRow, fontSize: 10, flexDirection: "row", fontSize: 10, lineHeight: 1.5 }}>
            <View style={{ width: "25%", padding: 10, paddingBottom: 10, paddingTop: 5, flex: 1 }}>
              <View style={styles.flexRow}>
                <Text style={{ minWidth: 60 }}>Acc:</Text>
                <Text style={{ ...styles.bold }}>{test.barcode}</Text>
              </View>
              <View style={styles.flexRow}>
                <Text style={{ minWidth: 60 }}>Chart#:</Text>
                <Text>-</Text>
              </View>
              <View style={styles.flexRow}>
                <Text style={{ minWidth: 60 }}>First reported on:</Text>
                <Text style={{ paddingLeft: 10 }}></Text>
              </View>
            </View>
            <View style={{ width: "25%", padding: 10, paddingBottom: 10, paddingTop: 5, flex: 1 }}>
              <View style={styles.flexRow}>
                <Text style={{ minWidth: 60 }}>Coll. Date:</Text>
                <Text style={{ paddingLeft: 10 }}>{formatDateMDY(test.createdAt)}</Text>
              </View>
              <View style={styles.flexRow}>
                <Text style={{ minWidth: 60 }}>Coll. Time:</Text>
                <Text style={{ paddingLeft: 10 }}>{formatTimeFromDate(test.createdAt)}</Text>
              </View>
              <View style={styles.flexRow}>
                <Text style={{ minWidth: 60 }}>{formatDateMDY(test.resultDateTime)}</Text>
              </View>
            </View>
            <View style={{ width: "25%", padding: 10, paddingBottom: 10, paddingTop: 5, flex: 1 }}>
              <View style={styles.flexRow}>
                <Text style={{ minWidth: 60 }}>Recv. Date:</Text>
                <Text style={{ paddingLeft: 10 }}>{formatDateMDY(parseSrNoObjectRecviedDate(test))}</Text>
              </View>
              <View style={styles.flexRow}>
                <Text style={{ minWidth: 60 }}>Recv. Time:</Text>
                <Text style={{ paddingLeft: 10 }}>{formatTimeFromDate(parseSrNoObjectRecviedDate(test))}</Text>
              </View>
              <View style={styles.flexRow}>
                <Text style={{ minWidth: 60 }}>Final Report date:</Text>
                <Text style={{ paddingLeft: 10 }}></Text>
              </View>
            </View>

            <View style={{ width: "25%", padding: 10, paddingBottom: 10, paddingTop: 5, flex: 1 }}>
              <View style={styles.flexRow}>
                <Text style={{ minWidth: 60 }}>Print Date:</Text>
                <Text style={{ paddingLeft: 10 }}>{formatDateMDY(test.resultDateTime)}</Text>
              </View>
              <View style={styles.flexRow}>
                <Text style={{ minWidth: 60 }}>Print Time:</Text>
                <Text style={{ paddingLeft: 10 }}>{formatTimeFromDate(test.resultDateTime)}</Text>
              </View>
              <View style={styles.flexRow}>
                <Text style={{ minWidth: 60 }}>{formatDateMDY(test.resultDateTime)}</Text>
              </View>
            </View>
          </View>
          {resultDetails && (
            <View
              style={{
                ...styles.flexRow,
                fontSize: 10,
                flexDirection: "row",
                fontSize: 10,
                border: 1,
                backgroundColor: "lightgray",
                marginBottom: "5px",
              }}
            >
              <View style={{ width: "100%", padding: 10, paddingBottom: 5, paddingTop: 5, flex: 1 }}>
                <View style={styles.flexRow}>
                  <Text style={{ minWidth: 60, fontWeight: "bold", fontSize: "12px" }}>{test.test_type || ""}</Text>
                  <Text style={{ paddingLeft: 10 }}></Text>
                </View>
              </View>
            </View>
          )}
          <View style={{ ...styles.flexRow, border: 1, backgroundColor: "lightgray" }}>
            <View style={{ flex: 1.4, padding: 5, borderRight: 1, borderColor: "black" }}>
              <Text style={{ ...styles.bold, textAlign: "center" }}>Test Name</Text>
            </View>
            <View style={{ flex: 1, padding: 5, borderRight: 1, borderColor: "black" }}>
              <Text style={{ ...styles.bold, textAlign: "center" }}>Result</Text>
            </View>
            {/* <View style={{ flex: 1, padding: 5, borderRight: 1, borderColor: "black" }}>
              <Text style={{ ...styles.bold, textAlign: "center" }}>Out of Range</Text>
            </View> */}
            <View style={{ flex: 1, padding: 5, borderRight: 1, borderColor: "black" }}>
              <Text style={{ ...styles.bold, textAlign: "center" }}>Reference Range</Text>
              {/* <Text style={{ ...styles.bold, textAlign: "center" }}>Normal Range</Text> */}
            </View>
            <View style={{ flex: 0.5, padding: 5 }}>
              <Text style={{ ...styles.bold, textAlign: "center" }}>Units</Text>
            </View>
          </View>
          {resultDetails ? (
            resultDetails.map((item, index) => {
              const { color, label, fontWeight, orginalResult, resRange } = analyzeResultValue(
                { minValue: item.minValue || item.minValueM, maxValue: item.maxValue || item.maxValueM },
                item.testResult,
                item.refInterval,
                item.ranges
              );

              const unitToShow = parseTestUnit(item.units);

              return (
                <>
                  <View
                    style={{ ...styles.flexRow, ...styles.bold, paddingHorizontal: 10, fontSize: 10, marginTop: "3px" }}
                    key={index}
                  >
                    <Text style={{ flex: 1.4, textAlign: "left" }}>
                      {toTitleCase(item?.label) || toTitleCase(item?.name) || ""}
                    </Text>
                    <Text
                      style={{
                        ...styles.bold,
                        flex: 1,
                        // textAlign: "center",
                        textTransform: "uppercase",
                        color:
                          item?.resultType === "alpha" && showAlphaResult(item, resRange) !== "Not Detected"
                            ? "red"
                            : color,
                      }}
                    >
                      {/* {isResultInRange(item.refInterval, item.testResult)
                      ? `${parseTestResult(item.testResult)}   ${label}`
                      : ""} */}
                      {/* {`${parseTestResult(item.testResult)}   ${label}`} */}

                      {isQualtative
                        ? showTestResult(item, isQualtative)
                        : `${parseTestResult(item.testResult)}   ${label}`}
                    </Text>
                    {/* <Text
                    style={{
                      ...styles.bold, 
                      flex: 1,
                      textAlign: "center",
                      textTransform: "uppercase",
                      color,
                    }}
                  >
                    {!isResultInRange(item.refInterval, item.testResult)
                      ? `${parseTestResult(item.testResult)}   ${label}`
                      : ""}
                  </Text> */}
                    <Text style={{ flex: 1, textAlign: "center", textTransform: "uppercase" }}>
                      {item.resultType === "alpha" ? "Not Detected" : item.refInterval}
                    </Text>
                    <Text style={{ flex: 0.5, textAlign: "center" }}>{unitToShow}</Text>
                  </View>
                  {isQualtative && parseTestResult(item.testResult) === "Detected" && (
                    <View
                      style={{
                        fontSize: 8,
                        marginTop: "5px",
                        marginLeft: "40px",
                      }}
                      key={index}
                    >
                      <Text style={{ textTransform: "uppercase" }}>
                        {`Calculated Colony Forming Units per mL ${unitToShow} ${generateTagLineQualitativeWithPanleTests(
                          { panel: item }
                        )}`}
                      </Text>
                      {}
                      <Text style={{ textTransform: "uppercase" }}>
                        {`Probability of Infection: ${infectionResultBasedUponRange({
                          resultValue: item.resultValue,
                          ranges: item?.ranges?.[0] ?? "",
                        })}`}
                      </Text>
                    </View>
                  )}
                </>
              );
            })
          ) : (
            <>
              <Text style={{ padding: "5px 10px", fontSize: 10, fontWeight: "bold" }}>Report Status: FINAL</Text>
              <Text style={{ padding: "0px 10px", fontSize: 10, fontWeight: "bold" }}>
                Source: {toTitleCase(demos?.swabtype || "Oral")}
              </Text>

              {demos.comment && (
                <View style={{ flexDirection: "row", paddingHorizontal: 10 }}>
                  <Text style={{ fontSize: 10, flex: 1, fontWeight: "bold" }}>Comment: </Text>
                  <Text style={{ fontSize: 10, flex: 6 }}>{demos.comment}</Text>
                </View>
              )}

              <Text style={{ ...styles.textUppercase, ...styles.bold, color: "blue", padding: "5px 10px" }}>
                {baseTestTypeLabel()}
              </Text>

              <View style={{ ...styles.flexRow, ...styles.bold, paddingHorizontal: 10, fontSize: 10 }}>
                <Text style={{ flex: 1.4, textAlign: "left" }}>{testTypeLabel()}</Text>

                <Text
                  style={{
                    ...styles.bold,
                    color: RESULTS_COLOR[test.result?.toLowerCase()],
                    flex: 1,
                    textAlign: "center",
                    textTransform: "uppercase",
                  }}
                >
                  {parseTestResultForPDF(test)}
                </Text>
                <Text style={{ flex: 1, textAlign: "center", textTransform: "uppercase" }}>Not Detected</Text>
                <Text style={{ flex: 0.5, textAlign: "center" }}>{testType?.units || ""}</Text>
              </View>
            </>
          )}
          <View style={{ marginLeft: "120px", marginTop: 15 }}>
            {testsLab?.invalid_notes && test.result && test.result.toLowerCase() === "invalid" && (
              <Text
                style={{
                  ...styles.infoText,
                  fontFamily: "Helvetica-Bold",
                  paddingHorizontal: 40,
                  marginBottom: "5px",
                  fontSize: 10,
                }}
              >
                {testsLab?.invalid_notes}
              </Text>
            )}
            {testsLab?.indeterminate_notes && test.result && test.result.toLowerCase() === "indeterminate" && (
              <Text
                style={{
                  ...styles.infoText,
                  fontFamily: "Helvetica-Bold",
                  paddingHorizontal: 40,
                  marginBottom: "5px",
                  fontSize: 10,
                }}
              >
                {testsLab?.indeterminate_notes}
              </Text>
            )}
            <Text style={{ ...styles.infoText, paddingHorizontal: 40, fontSize: 9 }}>
              {disclaimerText || getLabDisclaimer}
            </Text>
          </View>
          {/* {test.result?.toLowerCase() !== "negative" && test.result !== "Not Detected" && !resultDetails && (
            <>
              <Text
                style={{
                  padding: 10,
                  color: "blue",
                  ...styles.bold,
                  fontSize: 11,
                }}
              >
                **********************************************OUT OF RANGE
                SUMMARY*******************************************
              </Text>
              <View style={{ ...styles.flexRow, paddingHorizontal: 10, fontSize: 10 }}>
                <Text style={{ flex: 1.4, textAlign: "center" }}>{testTypeLabel()}</Text>
                <Text
                  style={{
                    ...styles.bold,
                    color: RESULTS_COLOR[test.result?.toLowerCase()],
                    flex: 1,
                    textAlign: "center",
                    textTransform: "uppercase",
                  }}
                >
                  {test.result?.toLowerCase() === "negative" && parseTestResultForPDF(test)}
                </Text>
                <Text
                  style={{
                    ...styles.bold,
                    color: RESULTS_COLOR[test.result?.toLowerCase()],
                    flex: 1,
                    textAlign: "center",
                    textTransform: "uppercase",
                  }}
                >
                  {test.result?.toLowerCase() !== "negative" && parseTestResultForPDF(test)}
                </Text>
                <Text style={{ flex: 1, textAlign: "center", textTransform: "uppercase" }}>Not Detected</Text>
                <Text style={{ flex: 0.5, textAlign: "center" }}></Text>
              </View>
            </> */}

          <View style={styles.container}>
            <View style={styles.line} />
            <Text style={styles.text}>end of report</Text>
            <View style={styles.line} />
          </View>
          <View
            style={{
              position: "absolute",
              bottom: "10",
              left: "10",
              width: "100%",
            }}
            fixed
          >
            <View style={{ flexDirection: "row", alignItems: "center", fontSize: 11 }}>
              <Text>Laboratory Director: </Text>
              <Text style={{ ...styles.bold, textTransform: "uppercase" }}>{testsLab?.lab_director || ""}</Text>
            </View>
          </View>
        </View>
      </Page>
    </Document>
  );
};

const styles = StyleSheet.create({
  page: {
    flex: 1,
    backgroundColor: "white",
    paddingHorizontal: 20,
    paddingTop: 20,
    paddingBottom: 20,
    fontSize: 12,
    fontFamily: "Arial", // Use the Arial font for the entire document
  },
  qrCode: {
    width: 70,
    marginTop: 5,
    marginLeft: 15,
  },
  bold: {
    fontWeight: "bold",
  },
  flexRow: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
  },
  flexRow1: {
    display: "flex",
    marginLeft: "20px",
  },

  flexCol: {
    display: "flex",
    flexDirection: "column",
  },
  infoText: {
    fontSize: 11,
  },
  infoTextRightBorder: {
    fontSize: 11,
    fontStyle: "bold",
  },
  italicText: {
    fontSize: 11,
    fontStyle: "italic",
  },
  pageNumbers: {
    position: "absolute",
    fontSize: 9,
    bottom: 15,
    right: 22,
  },
  patientInfo: {
    flexDirection: "row",
    justifyContent: "space-between",
  },
  infoHeader: {
    flexDirection: "row",
    justifyContent: "space-between",
    borderRightWidth: 1,
    borderRightColor: "black",
    borderLeftWidth: 1,
    borderLeftColor: "black",
  },
  infoCol: {
    flexDirection: "column",
    justifyContent: "space-around",
  },
  hr: {
    borderBottomColor: "black",
    borderBottomWidth: 1,
    width: "100%",
  },
  hrTwo: {
    borderBottomColor: "black",
    borderBottomWidth: 2,
    width: "100%",
  },
  address: {
    // marginLeft: 50,
    marginVertical: 20,
  },
  borderBottom: {
    borderBottom: 1,
    borderColor: "black",
  },
  waterMark: {
    opacity: 0.05,
    fontSize: "70px",
    color: "black",
    background: "#ccc",
    position: "absolute",
    cursor: "default",
    top: "45%",
    left: "30%",
    transform: "Translate(-50%, -50%)",
  },
  container: {
    width: "60%",
    marginHorizontal: "auto",
    marginTop: 5,
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
  },
  line: {
    borderBottomWidth: 1,
    borderBottomColor: "black",
    display: "inline-block",
    width: "40%",
    flex: 1,
  },
  text: {
    textTransform: "uppercase",
    textAlign: "center",
    fontSize: 11,
    flex: 1,
  },
  textUppercase: {
    textTransform: "uppercase",
  },
});

export default BiolabPdf;
