import moment from "moment";
import React from "react";
import user from "assets/img/default_profile.png";
import { formatPhoneNumberIntl } from "react-phone-number-input";
import { Form } from "react-bootstrap";
import dotIcons from "assets/img/dots.png";

const EventBar = (props) => {
  const {
    emp,
    handleUpdateSchedule,
    childNode,
    isSelected,
    scheduled,
    handleEditUser,
    date,
    onChangeSelection,
    handleMoreAction,
  } = props;

  const handleUpdateClick = (emp) => {
    const isValid = moment(date).isSameOrAfter(moment().format("YYYY-MM-DD"));
    if (isValid) handleUpdateSchedule(emp);
  };

  return (
    <>
      <div className="event-bar mb-2" key={emp.id}>
        <span
          className="event-bar-left"
          style={{
            backgroundColor: `#88ccff`,
          }}
        />
        <span
          style={{
            backgroundColor: `#88ccff`,
            padding: "6px 20px",
            paddingRight: "30px",
          }}
          className="event"
          ref={childNode}
        >
          <div
            className="d-flex justify-content-between"
            style={{ position: "relative", alignItems: "center", lineHeight: "2" }}
          >
            <div style={{ flex: "0 0 98%" }} className="d-flex justify-content-start align-items-center w-100">
              <div style={{ marginTop: "10px", position: "relative", left: "-10px" }}>
                <Form.Check>
                  <Form.Check.Input
                    type="checkbox"
                    checked={isSelected}
                    value={emp.id}
                    onChange={(e) => onChangeSelection(e, emp)}
                  />
                </Form.Check>
              </div>
              {/* <div className="eventIcon eventUserIcon">
                <img src={user} className="header-btn-img" />
              </div> */}
              <div className="d-flex flex-column" style={{ width: "55%" }}>
                <span
                  className="eventName event-name"
                  title={`${emp.firstName} ${emp.lastName}`}
                  onClick={() => handleEditUser(emp)}
                >{`${emp.lastName}, ${emp.firstName}`}</span>
                <span
                  className="eventName ellipsis"
                  title={`DOB: ${emp.dob || ""}`}
                  style={{ fontSize: "small", marginTop: "-10px", minWidth: "100%" }}
                >
                  {`DOB: ${emp.dob || ""}`}
                </span>
                <span
                  className="eventName ellipsis"
                  title={`Room# ${emp.street2 || ""}`}
                  style={{ fontSize: "small", marginTop: "-10px", minWidth: "100%" }}
                >
                  {`Room# ${emp.street2 || ""}`}
                </span>
              </div>
            </div>
            <div className="eventIcon">
              {/* <img src={dotIcons} alt="more icon" title="more" onClick={() => handleMoreAction(emp)} /> */}
              {/* <div className="header-btns">
                <i
                  onClick={() => handleUpdateClick(emp)}
                  className={scheduled ? "fa fa-minus-circle" : "fa fa-plus-circle"}
                />
              </div> */}
            </div>
          </div>
        </span>
      </div>
    </>
  );
};

export default EventBar;
