import CptList from "components/CPT/CptList";
import { CPT_CODE_SEARCH_TYPE } from "constant";
import React, { useState, memo } from "react";
import { Button, Modal } from "react-bootstrap";
import ProcedureCodeDetailView from "../ProcedureCode/ProcedureCodeDetailView";

const TestCptSelectorModal = ({ handleClose, cptData, saveCptData }) => {
  const [cptList, setCptList] = useState(cptData || []);

  const handleCptCode = (e) => {
    const cptObj = e.cptCodes[0];
    const isAlreadyExist = cptList.some((item) => item.code === cptObj.code);
    if (!isAlreadyExist) {
      setCptList([...cptList, { ...cptObj, unit: "1", maxUnit: "1" }]);
    }
  };

  const removeCptCode = (index) => {
    setCptList((prevItems) => {
      const newItems = [...prevItems];
      newItems.splice(index, 1);
      return newItems;
    });
  };

  const saveCpt = () => {
    saveCptData({ cptList });
    handleClose();
  };

  const handelUpdateCpt = ({ keyName, value, index }) => {
    setCptList((prevCptList) => prevCptList.map((item, i) => (i === index ? { ...item, [keyName]: value } : item)));
  };

  return (
    <Modal
      show
      className="second-modal"
      onHide={() => handleClose()}
      animation={true}
      centered
      size={"1xl"}
      backdrop="static"
    >
      <Modal.Header closeButton>
        <Modal.Title className="my-0 h4" id="contained-modal-title-vcenter">
          Linked Cpt
        </Modal.Title>
      </Modal.Header>
      <Modal.Body style={{ paddingTop: 0 }}>
        <h4
          className="test-component-title"
          style={{
            margin: "0px",
            borderTopLeftRadius: "5px",
            borderTopRightRadius: "5px",
            padding: "10px 10px 0 10px",
          }}
        >
          Linked CPT Code {cptList.length > 0 ? `(${cptList.length})` : ""}
        </h4>
        <div style={{ border: "1px solid gray", marginTop: "5px", borderRadius: "5px" }}>
          <div style={{ padding: "10px" }}>
            <div className="w-50">
              <CptList
                item={{}}
                setItem={handleCptCode}
                title="Lookup Procedure Code"
                searchtype={CPT_CODE_SEARCH_TYPE.CPT}
                groupWrapper="form-group-wrapper margin-bottom-1"
                showLabelOnTop={true}
                showInput={false}
              />
            </div>
            {cptList && cptList.length > 0 && (
              <ProcedureCodeDetailView
                cptCodeList={cptList}
                handelDeleteCpt={removeCptCode}
                handelUpdateCpt={handelUpdateCpt}
              />
            )}
          </div>
        </div>
      </Modal.Body>

      <Modal.Footer>
        <Button variant="secondary" className="modalButtons" onClick={() => handleClose()}>
          Close
        </Button>

        <Button variant="primary" className="modalButtons" onClick={saveCpt}>
          Save
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default memo(TestCptSelectorModal);
