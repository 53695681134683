import MainTable from "components/Table/MainTable";
import { CREATE_TEST_GENERIC_PERSONALIZE } from "constants/personalization";
import React from "react";
import { Button, Modal } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { setGenericPathogenModalData } from "store/features/genericTestsView/genericModalSlice";
import { renderSourcesCell } from "utils";

const GenericPathogenView = ({ data }) => {
  const customRenderTD = (item, row, index) => {
    if (item.itemKey === "sources") {
      return renderSourcesCell(item, row);
    }
  };
  const dispatch = useDispatch();

  const handleClose = () => {
    dispatch(
      setGenericPathogenModalData({
        showGenericPathogenModal: false,
        genericPathogenData: [],
      })
    );
  };

  return (
    <Modal
      show
      // style={{ transform: "translate(0, -30%)" }}
      animation={true}
      onHide={() => handleClose()}
      centered
      size={"xl"}
      backdrop="static"
    >
      <Modal.Header closeButton>
        <Modal.Title className="my-0 h4" id="contained-modal-title-vcenter">
          Pathogen Details
        </Modal.Title>
      </Modal.Header>
      <Modal.Body style={{ paddingTop: 0, lineHeight: 0 }}></Modal.Body>

      <div style={{ padding: "20px" }}>
        <div style={{ background: "#f2f0f0", paddingLeft: "4px", paddingRight: "4px", marginTop: "10px" }}>
          <MainTable
            columns={CREATE_TEST_GENERIC_PERSONALIZE}
            rows={data}
            customColumnCellRenderer={customRenderTD}
            widthToSkip={155}
          />
        </div>
      </div>

      <Modal.Footer>
        <Button variant="secondary" className="modalButtons" onClick={() => handleClose()}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default GenericPathogenView;
