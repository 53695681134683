import { async } from "@firebase/util";
import api from "api";
import Loader from "components/Loader/Loader";
import { stringConstant } from "constant";
import React, { useState, useEffect } from "react";
import { Button, Form, Modal } from "react-bootstrap";
import { isValidAccessionNo } from "utils";
import gqApi from "views/GraphQL/gqApi";

const EditAccessionNoModal = (props) => {
  const { handleClose, test, appContext } = props;
  const [error, setError] = useState("");
  const [newTestCreate, setTestCreate] = useState({ ...test });
  const [confirmText, setConfirmText] = useState("");
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setTimeout(() => {
      setError("");
    }, 4000);
  }, [error]);

  const onHandleSave = async () => {
    if (newTestCreate.barcode === test.barcode) {
      setError("Please change Accession No. and ");
      return;
    }
    if (newTestCreate.barcode && newTestCreate.barcode.length !== 10 && isValidAccessionNo(newTestCreate.barcode)) {
      setError("Accession No. is Invalid e.g. (YYMMDD0001)");
      return;
    }

    if (confirmText !== "confirm") {
      setError("Please enter confirm in input");
      return;
    }
    const res = await gqApi.validateAccessionNo(newTestCreate);
    if (!res) {
      setError(`Accession No. ${newTestCreate.barcode} is already assigned. Please use the other`);
      return;
    }

    try {
      setLoading(true);
      await api.updateBarcodeNumber(newTestCreate, appContext);
      setLoading(false);
      handleClose("success");
    } catch (err) {
      console.log("Error:-", err);
      setLoading(false);
      handleClose(err);
    }
  };

  return (
    <>
      <Modal
        show
        animation={true}
        onHide={() => {
          handleClose();
        }}
        style={{ paddingLeft: "0" }}
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title className="my-0" id="contained-modal-title-vcenter">
            Change Accession Number
          </Modal.Title>
        </Modal.Header>
        <Modal.Body
          style={{
            paddingTop: 0,
          }}
        >
          <div className="form-group-wrapper">
            <label className="modalLineHeaders">Accession Number: </label>
            <div className="merged-input-container">
              <input
                className="merged-input"
                style={{ width: "22%" }}
                readOnly
                value={newTestCreate?.barcode?.slice(0, 6)}
              />
              <input
                className="merged-input"
                style={{ width: "78%" }}
                placeholder={stringConstant.barcode}
                value={newTestCreate?.barcode.slice(6)}
                maxLength={"4"}
                onChange={(e) => {
                  setTestCreate({
                    ...newTestCreate,
                    barcode: `${newTestCreate?.barcode?.slice(0, 6)}${e.target.value.replace(/[^0-9]/g, "")}`,
                  });
                }}
              />
            </div>
          </div>
          <div className="form-group-wrapper">
            <label className="modalLineHeaders">Type confirm: </label>
            <input
              value={confirmText}
              className="modalInput"
              placeholder="type confirm"
              style={{
                width: "100%",
              }}
              onChange={(e) => setConfirmText(e.target.value)}
            />
          </div>

          {error.length > 0 && <strong style={{ color: "Red" }}>{error}</strong>}
        </Modal.Body>
        <Modal.Footer>
          <Button
            style={{ marginBottom: 10 }}
            variant="secondary"
            className="modalButtons"
            onClick={() => {
              handleClose();
            }}
          >
            Close
          </Button>
          <Button
            style={{ marginBottom: 10 }}
            variant="primary"
            className="modalButtons"
            onClick={onHandleSave}
            disabled={newTestCreate.barcode === test.barcode}
          >
            Save
          </Button>
        </Modal.Footer>
        {loading && <Loader />}
      </Modal>
    </>
  );
};

export default EditAccessionNoModal;
