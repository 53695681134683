import React, { useState, useContext, useEffect } from "react";
import { Button, Modal } from "react-bootstrap";
import Select from "react-select";
import { HEALTH_AND_SAFTEY, newRoleOptions } from "../../constant";
import { AppContext } from "context/app-context";
import { formatQuaratineDate } from "../../utils";
import ErrorMessage from "components/Message/ErrorMessage"
import moment from "moment";

const QuarantineModal = (props) => {
  const { test, handleSave, handleClose } = props;
  const [startDate, setStartDate] = useState(test?.quarantinedStart);
  const [endDate, setEndDate] = useState(test?.quarantinedEnd);
  const [error, setError] = useState("");

  const onHandleSave = () => {
    if (!startDate) {
      setError("Please select dates");
      return;
    }

    if (endDate && !startDate) {
      setError("Please select start date");
      return;
    }

    if (startDate && endDate) {
      if (moment(endDate).isBefore(moment(startDate))) {
        setError("End date should be after start date");
        return;
      }
    }

    handleSave({ startDate, endDate });
    
    handleClose();
  };

  return (
    <>
      <Modal
        show
        animation={true}
        onHide={() => {
          handleClose();
        }}
        style={{ paddingLeft: "0" }}
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title className="my-0" id="contained-modal-title-vcenter">
            Quarantine Info
          </Modal.Title>
        </Modal.Header>
        <Modal.Body
          style={{
            paddingTop: 0,
          }}
        >
          <div className="form-group-wrapper">
            <label className="modalLineHeaders">Start Date: </label>
            <input
              className="modalInput"
              type="date"
              value={formatQuaratineDate(startDate)}
              placeholder="Start Date"
              style={{ width: "100%", marginBottom: "10px" }}
              onChange={(e) => setStartDate(e.target.value)}
            />
          </div>
          <div className="form-group-wrapper">
            <label className="modalLineHeaders">End Date: </label>
            <input
              className="modalInput"
              type="date"
              value={formatQuaratineDate(endDate)}
              placeholder="End Date"
              style={{ width: "100%", marginBottom: "10px" }}
              onChange={(e) => setEndDate(e.target.value)}
            />
          </div>
          {error && (
          <ErrorMessage error={error} handleChange={()=>setError('')}/>
        )}
        </Modal.Body>
        <Modal.Footer>
          <Button
            style={{ marginBottom: 10 }}
            variant="secondary"
            className="modalButtons"
            onClick={() => {
              handleClose();
            }}
          >
            Close
          </Button>
          <Button style={{ marginBottom: 10 }} variant="primary" className="modalButtons" onClick={onHandleSave}>
            Save
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default QuarantineModal;
