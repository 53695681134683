import { Button, Card, Col, Modal, Row, Table } from "react-bootstrap";
import { useState, useEffect } from "react";
import { draggablePersonalizationLocalStorage, getValidName } from "../../utils";
import api from "../../api";
import { useDispatch, useSelector } from "react-redux";
import { setMessage } from "store/features/general/generalAction";

import FileInput from "components/Input/FileInput";
import ErrorMessage from "components/Message/ErrorMessage";
import Loader from "components/Loader/Loader";
import { authSelector, selectLoginUser } from "store/features/authentication/authenticationSelectors";
import { setTestForResult } from "store/features/resultsTests/resultsTestSlice";
import { MESSAGE_MODES } from "../../constant";

const ResultUploadModal = (props) => {
  const { test, appContext } = props;
  const [loading, setLoading] = useState(false);
  const [file, setFile] = useState("");
  const [fileType, setFileType] = useState("");
  const [fileSize, setFileSize] = useState("");
  const [selectedFileName, setSelectedFileName] = useState("");
  const [error, setError] = useState("");
  const [externalID, setExternalID] = useState(test.referenceID || "");
  
  const dispatch = useDispatch();

  const handleCancel = () => {
    setFile("");
    setFileType("");
    setSelectedFileName("");
    setFileSize("");
  };


  const handleUploadDocument = async () => {
    try {

      if(!file){
        setError("Result file is required");
        return;
      }

      if(!externalID){
        setError("External reference Id is required");
        return;
      }

      setLoading(true);
      let fileName = `${test.sequenceNo}.pdf`;
      if (file) {
        fileName = await api.saveResultFile(fileName, file);
      }

      if (fileName) {
        const resultObject = {
          id: test.id,
          referenceID: externalID.toUpperCase(),
          resultPDF: fileName,
        };
        const response = await api.assingTestResult(resultObject);

        setLoading(false);
        if (response.status) {
          dispatch(setMessage("Result Uploaded Successfully", MESSAGE_MODES.success));
          dispatch(setTestForResult(null));
        } else {
          dispatch(setMessage("Result upload fail", MESSAGE_MODES.error));
        }
      } else {
        dispatch(setMessage("Result upload fail", MESSAGE_MODES.error));
      }

      return null;
    } catch (err) {
      setLoading(false);
      console.log("Error createDocumentAsync:-", err);
    }
  };

  const handleClose = () => {
    dispatch(setTestForResult(null));
  };

  return (
    <Modal show backdrop="static" animation={true} onHide={() => handleClose()} centered size={"md"} keyboard={false}>
      {loading && <Loader />}
      <Modal.Header closeButton>
        <Modal.Title className="my-0" id="contained-modal-title-vcenter">
          Result Upload: {`Barcode : ${test.barcode}`}
        </Modal.Title>{" "}
      </Modal.Header>

      <Modal.Body className="table-full-width desktop-noScroll">
        <div className="row">
          <div className="col-sm-12 testAdmin createClientsWrapper">
            <div className="form-group-wrapper">
              <label className="modalLineHeaders">External Reference ID:  <span className="fw-bold fs-6 maroon">*</span> </label>
              <input
                className="modalInput"
                placeholder="External Reference ID"
                value={externalID}
                onChange={(e) => setExternalID(e.target.value)}
              />
            </div>
            <div className="form-group-wrapper">
              <label className="modalLineHeaders">Result File: <span className="fw-bold fs-6 maroon">*</span>  </label>
              <FileInput
                setFileType={setFileType}
                setFile={setFile}
                setSelectedFileName={setSelectedFileName}
                selectedFileName={selectedFileName}
                setFileSize={setFileSize}
              />
            </div>
          </div>
        </div>
      </Modal.Body>
      <div className="px-4 my-2" style={{ height: "2vh" }}>
        {error && <ErrorMessage error={error} handleChange={() => setError("")} />}
      </div>
      <Modal.Footer className="my-2 d-flex justify-content-between">
        <Button variant="primary" className="modalButtons headerButton btn-fill" onClick={() => handleClose()}>
          Cancel
        </Button>
        <Button variant="secondary" className="modalButtons headerButton btn-fill" onClick={handleUploadDocument}>
          Upload
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default ResultUploadModal;
