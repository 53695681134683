export const SALES_PERSON = "sale_person";
export const SELECTED_SALE_PERSON = "selected_sale_person";
export const DELETE_SALE_PERSON = "delete_sale_person";
export const SELECTED_CLIENT_ID = "selected_client_id";
export const FETCH_SALES_PERSONS = "fetch_sales_persons";
export const SET_SHOW_SALES_PERSON_MODAL = "set_show_sales_person_modal";
export const UPDATE_SALES_PERSON_IN_LIST = "update_sales_person_in_list";
export const SET_SHOW_SALES_PERSON_ASSIGN_MODAL = "set_show_sales_person_assign_modal";
export const UNLINK_SALES_PERSON = "unlink_sales_person";
export const SALE_REPORT_FILTERS = "sales_report_filter";
