import React from 'react';

const VideoPlayer = ({ videoId }) => {
  const embedUrl = `https://www.youtube.com/embed/${videoId}`;

  return (
    <div className="video-container">
      <iframe
        src={embedUrl}
        title="YouTube Video Player"
        frameBorder="0"
        allowFullScreen
        className="video-iframe"
      ></iframe>
    </div>
  );
};

export default VideoPlayer;
