import React, { useEffect } from "react";
import Lightbox from "react-awesome-lightbox";
import "react-awesome-lightbox/build/style.css";

const FullImageView = (props) => {
  const { image, handleClose } = props;
  if (image) {
    return <Lightbox image={image} allowRotate={false} onClose={handleClose} />;
  }

  return <></>;
};

export default FullImageView;
