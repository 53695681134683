import { useRef } from "react";
import API from "api";
import { Modal, Button, Card } from "react-bootstrap";
import ErrorMessage from "components/Message/ErrorMessage";
import ProviderSearchRecord from "./ProviderSearchRecord";
import { useEffect, useState } from "react";
import Select from "react-select";
import { NPI_TYPE_OPTIONS } from "constant";
import InputField from "components/InputField/InputField";
import { formatPhoneNumber } from "utils";
import SwitchToggle from "components/SwitchToggle/SwitchToggle";
import api from "api";
import Loader from "components/Loader/Loader";

const ProviderModal = (props) => {
  const { show, user, handleClose, readOnly, cssClass, providers, appContext } = props;

  const [newProvider, setNewProvider] = useState(user);
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const [recordData, setRecordData] = useState([]);
  const [selectSearchData, setSelectSearchData] = useState(null);
  const childNode = useRef();

  useEffect(() => {
    setNewProvider(user);
  }, [user]);

  const handleUpdateAddress = (val, flag) => {
    if (!flag) {
      setNewProvider({ ...newProvider, street: val });
    } else {
      const addressObj = {
        street: val.street,
        country: val.country,
        city: val.city,
        zip: val.zipcode,
        state: val.state,
        isoCode: val.isoCode,
      };
      setNewProvider({ ...newProvider, ...addressObj });
    }
  };

  const handleSave = async (user) => {
    try {
      if (user.isNew) {
        const res = await api.newProviderInfo(user);
        if (res && !res.error) {
          appContext.showSuccessMessage("Provider Created Successfully");
          handleClose(res);
        } else {
          appContext.showErrorMessage(res?.error || "Provider not Created");
        }
      } else {
        const res = await updateProvider(user);
        if (res && !res.error) {
          appContext.showSuccessMessage("Provider Updated Successfully");
          handleClose(res);
        } else {
          appContext.showErrorMessage(res?.error || "Provider not Updated");
        }
      }
      setLoading(false);
    } catch (error) {
      console.log("ERROR:User-", error);
      appContext.showErrorMessage(error.message);
      setLoading(false);
    }
  };

  const populate = (record) => {
    setSelectSearchData(null);
    setRecordData([]);

    const { basic, addresses, taxonomies } = record;

    // Find the LOCATION address if available
    const locationAddress = addresses?.find((address) => address.address_purpose === "LOCATION") || addresses[0];

    // Determine the primary taxonomy
    const primaryTaxonomy = taxonomies.find((taxonomy) => taxonomy.primary) || taxonomies[0];

    // Format phone numbers if they exist
    const formattedPhoneNumber = locationAddress?.telephone_number
      ? formatPhoneNumber(`+1${locationAddress.telephone_number}`)
      : "";

    const formattedFaxNumber = locationAddress?.fax_number ? formatPhoneNumber(`+1${locationAddress.fax_number}`) : "";

    // Create the user object
    const obj = {
      ...newProvider,
      firstName: basic.first_name,
      middleName: basic.middle_name,
      lastName: basic.last_name,
      street: locationAddress?.address_1,
      city: locationAddress?.city,
      zip: locationAddress?.postal_code,
      state: locationAddress?.state,
      phoneNumber: formattedPhoneNumber,
      fax: formattedFaxNumber,
      taxid: primaryTaxonomy?.code,
      npi: record.number,
      npiType: record.enumeration_type,
      speciality: primaryTaxonomy?.desc,
      isFinder: true,
    };

    setNewProvider(obj);
  };

  const searchProvider = async (provider) => {
    let record = [];
    setLoading(true);
    try {
      record = await API.searchProviderAPI(provider);
      setLoading(false);
    } catch (err) {
      console.log(err.message);
      setLoading(false);
    }

    if (record?.length === 0) {
      setError("records Not Found!");
      return;
    }

    if (record && record.length === 1) {
      populate(record[0]);
    }
    if (record && record.length > 1) {
      setRecordData(record);
    }
  };

  const handleFindProvider = (newProvider) => {
    const keys = ["firstName", "lastName", "npi"];
    if (keys.some((e) => newProvider[e])) {
      searchProvider(newProvider);
    } else {
      appContext.showErrorMessage("Please Fill Provider Name or NPI");
    }
  };

  const onHandleSave = () => {
    let roles = [];
    const objToSave = { ...newProvider };
    if (!newProvider.firstName || !newProvider.firstName.trim()) {
      setError("First Name is required");
      return;
    }

    Object.assign(objToSave, {
      name: `${newProvider.firstName.trim()} ${newProvider?.middleName?.trim() || ""} ${newProvider?.lastName.trim()}`,
    });

    if (!newProvider.npi || !newProvider.npi.trim()) {
      setError("NPI is required");
      return;
    }

    // Find Existing NPI Number
    const findProvider = providers.find((f) => f.npi === newProvider.npi);

    if (findProvider && (newProvider.isNew || (!newProvider.isNew && newProvider.id !== user.id))) {
      setError("NPI is already exist");
      return;
    }

    handleSave(objToSave);
  };

  return (
    <>
      <Modal
        show={show}
        backdrop="static"
        animation={true}
        onHide={() => {
          setNewProvider("");
          handleClose();
        }}
        style={{ paddingLeft: "0" }}
        className={cssClass ?? ""}
        size={"lg"}
        keyboard={false}
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title className="my-0" id="contained-modal-title-vcenter">
            {newProvider.isNew ? "Create Provider" : "Update Provider"}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body
          style={{
            paddingTop: 0,
          }}
        >
          <div className={"d-flex w-100 align-items-center justify-content-around"}>
            <InputField
              type="text"
              groupWrapper={"form-group-wrapper w-50"}
              inputStyle="modalInput"
              labelStyle="text-capitalize"
              label="NPI:"
              value={newProvider.npi}
              index="npi"
              maxLength={20}
              placeholder="Search By NPI"
              handleChange={(e) => setNewProvider({ ...newProvider, npi: e.target.value })}
              required
            />

            <Button
              style={{ marginBottom: 20 }}
              variant="secondary"
              className="modalButtons  align-middle headerButton btn-fill"
              onClick={() => handleFindProvider(newProvider)}
            >
              Search
            </Button>
          </div>
          <div className="row">
            <div className="testAdmin createClientsWrapper allowShrink resubmissionFormWrapper">
              <div className={" crew-profile-row4 crew-profile-again"}>
                <InputField
                  type="text"
                  inputStyle="modalInput w-100"
                  labelStyle="text-capitalize"
                  label="First Name"
                  value={newProvider.firstName}
                  index="firstName"
                  maxLength={30}
                  placeholder="First Name"
                  handleChange={(e) => setNewProvider({ ...newProvider, firstName: e.target.value })}
                />

                <InputField
                  type="text"
                  inputStyle="modalInput w-100"
                  labelStyle="text-capitalize"
                  label="Middle Name"
                  value={newProvider.middleName}
                  index="middleName"
                  maxLength={30}
                  placeholder="Middle Name"
                  handleChange={(e) => setNewProvider({ ...newProvider, middleName: e.target.value })}
                />

                <InputField
                  type="text"
                  inputStyle="modalInput w-100"
                  labelStyle="text-capitalize"
                  label="Last Name"
                  value={newProvider.lastName}
                  index="lastName"
                  maxLength={30}
                  placeholder="Last Name"
                  handleChange={(e) => setNewProvider({ ...newProvider, lastName: e.target.value })}
                />
              </div>
            </div>
            <div className="testAdmin createClientsWrapper allowShrink resubmissionFormWrapper">
              <div className={" crew-profile-row4 crew-profile-again"}>
                <InputField
                  type="address"
                  inputStyle="modalInput w-100"
                  labelStyle="text-capitalize"
                  label="Address"
                  value={newProvider.street}
                  index="street"
                  maxLength={65}
                  placeholder="Address"
                  handleChange={(val, flag) => handleUpdateAddress(val, flag)}
                />

                <InputField
                  type="text"
                  inputStyle="modalInput w-100"
                  labelStyle="text-capitalize"
                  label="City"
                  value={newProvider.city}
                  index="city"
                  maxLength={20}
                  placeholder="City"
                  handleChange={(e) => setNewProvider({ ...newProvider, city: e.target.value })}
                />
                <InputField
                  type="text"
                  inputStyle="modalInput"
                  labelStyle="text-capitalize"
                  label="State"
                  value={newProvider.state}
                  index="state"
                  maxLength={20}
                  placeholder="State"
                  handleChange={(e) => setNewProvider({ ...newProvider, state: e.target.value })}
                />
              </div>
            </div>

            <div className="testAdmin createClientsWrapper allowShrink resubmissionFormWrapper">
              <div className={" crew-profile-row4 crew-profile-again"}>
                <InputField
                  type="text"
                  inputStyle="modalInput"
                  labelStyle="text-capitalize"
                  label="Zip Code"
                  value={newProvider.zip}
                  index="zip"
                  maxLength={20}
                  placeholder="Zip Code"
                  handleChange={(e) => setNewProvider({ ...newProvider, zip: e.target.value })}
                />

                <InputField
                  type="phone"
                  labelStyle="text-capitalize"
                  label="Phone Number"
                  value={newProvider.phoneNumber}
                  index="phoneNumber"
                  maxLength={20}
                  placeholder="Enter Phone"
                  handleChange={(e) => setNewProvider({ ...newProvider, phoneNumber: e })}
                />

                <InputField
                  type="phone"
                  labelStyle="text-capitalize"
                  label="Fax"
                  value={newProvider.fax}
                  index="fax"
                  maxLength={20}
                  placeholder="Enter Fax"
                  handleChange={(e) => setNewProvider({ ...newProvider, fax: e })}
                />
              </div>
            </div>

            <div className="testAdmin createClientsWrapper allowShrink resubmissionFormWrapper">
              <div className={" crew-profile-row4 crew-profile-again"}>
                <InputField
                  type="dropDown"
                  options={NPI_TYPE_OPTIONS}
                  inputStyle=""
                  labelStyle="text-capitalize"
                  label="NPI Type:"
                  isClearable={true}
                  value={NPI_TYPE_OPTIONS.find((f) => f.value === newProvider.npiType) || ""}
                  index="npiType"
                  placeholder="NPI"
                  handleChange={(e) => setNewProvider({ ...newProvider, npiType: e?.value })}
                />

                <InputField
                  type="text"
                  inputStyle="modalInput"
                  labelStyle="text-capitalize"
                  label="Taxonomy ID:"
                  value={newProvider.taxid}
                  index="taxid"
                  maxLength={20}
                  placeholder="Taxonomy ID"
                  handleChange={(e) => setNewProvider({ ...newProvider, taxid: e.target.value })}
                />
                <InputField
                  type="text"
                  label="Speciality:"
                  inputStyle="modalInput"
                  labelStyle="text-capitalize"
                  value={newProvider.speciality || ""}
                  placeholder="Enter Speciality"
                  index="specialitypi"
                  handleChange={(e) => setNewProvider({ ...newProvider, speciality: e.target.value })}
                />
              </div>
            </div>

            <div
              className="d-flex align-items-center column-gap-10 profile-
                          detail mb-3"
            >
              <span className="section-title">Is Active: </span>
              <SwitchToggle
                checked={newProvider.isActive}
                handleChange={(e) =>
                  setNewProvider({
                    ...newProvider,
                    isActive: e.target.checked,
                  })
                }
              />
            </div>
          </div>
        </Modal.Body>
        <div className="px-4" style={{ minHeight: "3vh" }}>
          {error && <ErrorMessage error={error} handleChange={() => setError("")} />}
        </div>
        <Modal.Footer>
          <Button
            style={{ marginBottom: 10 }}
            variant="primary"
            className="modalButtons headerButton btn-fill"
            onClick={() => {
              handleClose();
            }}
          >
            Close
          </Button>

          {!readOnly && (!newProvider.isNew || newProvider.isFinder) && (
            <Button
              style={{ marginBottom: 10 }}
              variant="secondary"
              className="modalButtons headerButton btn-fill"
              onClick={onHandleSave}
            >
              {newProvider.isNew ? "Create" : "Update"}
            </Button>
          )}
        </Modal.Footer>
      </Modal>
      {recordData.length > 1 && (
        <ProviderSearchRecord
          data={recordData}
          handleSelect={populate}
          handleClose={() => {
            setRecordData([]), setSelectSearchData(null);
          }}
          setSelectSearchData={setSelectSearchData}
          selectSearchdata={selectSearchData}
        />
      )}
      {loading && <Loader />}
    </>
  );
};

export default ProviderModal;
