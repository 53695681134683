import MainTable from "components/Table/MainTable";
import { Button, Modal } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { showLocationTestModalSelectors } from "store/features/locations/locationsSelectors";
import { setLocationTestModal } from "store/features/locations/locationsSlice";
import TestTypeSelector from "views/TestTypeSetting/TestPanels/TestTypeSelector";
import { CREATE_TEST_PANEL_PERSONALIZE } from "constants/personalization";
import { TABLE_QUICK_TOOLS } from "constant";
import api from "api";
import { useEffect, useState } from "react";
import { formattedString } from "utils";
import { TYPE_TO_SHOW } from "constant";
import { updateLabClient } from "store/features/locations/locationsSlice";

const LCTestModal = (props) => {
  const { handleTest, rowData } = props;
  const dispatch = useDispatch();
  const [panelsList, setPanelsList] = useState([]);
  const [testTypesList, setTestTypesList] = useState([]);
  const [testPanelList, setTestPanelList] = useState([]);

  const [rowTestsData, setRowTestsData] = useState([]);

  const handleClose = () => {
    dispatch(setLocationTestModal(false));
  };

  const getList = async () => {
    const apiData = await api.getTestTypes();
    setTestTypesList(apiData);
  };

  useEffect(() => {
    getList();
  }, []);

  const showLocationTestModal = useSelector(showLocationTestModalSelectors);

  const customRenderTD = (item, row) => {
    if (item.itemKey === "type") {
      return <td key={`type_${item.id}`}>{TYPE_TO_SHOW[row.type]}</td>;
    }
    if (item.itemKey === "name") {
      return <td key={`name_${item.id}`}>{formattedString(row?.name) || ""}</td>;
    }
  };

  const handleDelete = (row) => {
    setPanelsList((prevPanelsList) => prevPanelsList.filter((panel) => panel.id !== row.id));
  };

  const handleTestSelect = (test) => {
    setPanelsList((previousItems) => {
      const updatedList = new Map(previousItems.map((item) => [item.id, item]));
      updatedList.set(test.id, test);
      return Array.from(updatedList.values());
    });
  };

  // Function to compare the name fields of two arrays using a loop
  const compareFn = (oldList = [], newList = []) => {
    if (oldList.length !== newList.length) return true; // Length mismatch means lists have changed

    // Sort both lists by name for proper comparison
    const sortedOldList = oldList?.sort((a, b) => a.name.localeCompare(b.name));
    const sortedNewList = newList?.sort((a, b) => a.name.localeCompare(b.name));

    // Compare each name field in both arrays
    for (let i = 0; i < sortedOldList.length; i++) {
      if (sortedOldList[i].name !== sortedNewList[i].name) {
        return true; // Return true if any name differs
      }
    }

    return false; // Return false if no differences are found
  };

  const handleConfirm = () => {
    if (!rowData.isNew) {
      if (compareFn(rowTestsData, panelsList)) {
        dispatch(
          updateLabClient({
            ...rowData,
            setting: { ...(rowData.setting || {}), tests: panelsList?.map((item) => item.id) },
          })
        );
      }
    }
    handleTest(panelsList?.map((item) => item.id));
    handleClose();
  };

  useEffect(() => {
    const testIds = rowData?.setting?.tests;
    const allOptions = [...(testPanelList || []), ...(testTypesList || [])];
    // Filter allOptions to only include items that have an id present in the ids array
    const filteredOptions = allOptions?.filter(({ id }) => testIds?.includes(id));
    if (filteredOptions?.length > 0) {
      setRowTestsData(filteredOptions);
      setPanelsList(filteredOptions);
    }
  }, [rowData.setting, testPanelList, testTypesList, showLocationTestModal]);

  return (
    <>
      <Modal
        show={showLocationTestModal}
        backdrop="static"
        animation={true}
        onHide={() => handleClose()}
        centered
        size={"lg"}
        className="second-modal"
      >
        <Modal.Header closeButton>
          <Modal.Title className="my-0" id="contained-modal-title-vcenter">
            Offered Panel
          </Modal.Title>
        </Modal.Header>

        <Modal.Body style={{ paddingTop: 0 }}>
          <div style={{ marginTop: "5px", paddingLeft: "20px", paddingRight: "20px", width: "100%" }}>
            <TestTypeSelector
              label="Select Panel"
              onChange={handleTestSelect}
              testTypeList={testTypesList}
              testPanelList={testPanelList}
              value={""}
            />
          </div>
          <div style={{ padding: "20px" }}>
            <div
              style={{
                maxHeight: "300px",
                background: "#f2f0f0",
                paddingLeft: "4px",
                paddingRight: "4px",
                marginTop: "10px",
                overflowY: "scroll",
              }}
            >
              <MainTable
                columns={CREATE_TEST_PANEL_PERSONALIZE}
                rows={panelsList}
                customColumnCellRenderer={customRenderTD}
                widthToSkip={155}
                tools={[TABLE_QUICK_TOOLS.delete]}
                handleCellClick={(key, row) => {
                  if (key === TABLE_QUICK_TOOLS.delete) {
                    handleDelete(row);
                  }
                }}
              />
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer className="d-flex justify-content-between">
          <Button variant="primary" className="headerButton btn-fill" onClick={() => handleClose()}>
            Cancel
          </Button>
          <Button variant="primary" className="headerButton btn-fill" onClick={() => handleConfirm()}>
            Confirm
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default LCTestModal;
