import React from "react";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.css";

const TabPills = (props) => {
  const { tabs, handleTabClick } = props;

  return (
    <Tabs>
      <TabList>
        {tabs?.map((item, index) => (
          <Tab key={index} onClick={() => handleTabClick && handleTabClick(item)}>
            {item.title}
          </Tab>
        ))}
      </TabList>
      {tabs?.map((item, index) => (
        <TabPanel key={index}>{item.component}</TabPanel>
      ))}
    </Tabs>
  );
};
export default TabPills;
