import SwitchToggle from "components/SwitchToggle/SwitchToggle";
import React from "react";
import TestTypeInput from "./TestTypeInput";

const ResultDisclaimerSwitch = ({
  handleChange,
  checked = false,
  disabled = false,
  placeholder,
  label,
  inlineLabel,
  noBorder,
  required = false
}) => {
  return (
    <div
      className={`test-component-element ${
        inlineLabel &&
        `d-flex justify-content-between switch-wrapper border-b-1 align-items-center ${noBorder && "border-0"}`
      }`}
      style={{ position: "relative" }}
    >
      {label && !inlineLabel && <TestTypeInput label={label} placeholder={placeholder} disabled={true} required={required} />}

      {inlineLabel && (
        <p className="testInlineLabel mb-0">
          {label}
          {required && <span style={{ color: "red" }}>*</span>}
        </p>
      )}

      <div className={`leading-0 ${!inlineLabel && "notInlineLabel"}`}>
        <SwitchToggle checked={checked} handleChange={handleChange} disabled={disabled} />
      </div>
    </div>
  );
};

export default ResultDisclaimerSwitch;
