import moment from "moment";
import React, { useState, useRef } from "react";
import { Table, Modal } from "react-bootstrap";

const TestLogsDetailView = (props) => {
  const { item, columns, values, cssClass, changeObject, msg } = props;

  const onlyShowDateLabels = ["Test Date", "Dob"];

  const [showDetailModal, setShowDetailModal] = useState(false);

  const childNode = useRef();

  const TableRow = ({ rowItem, index }) => {
    const formatResult = (result, label) => {
      if (typeof result === "boolean") {
        return result ? "Yes" : "No";
      }

      if (typeof result === "string" && result.includes("-") && moment(result, moment.ISO_8601, true).isValid()) {
        if (onlyShowDateLabels.includes(label)) {
          return moment(result).format("MM-DD-YYYY");
        }
        return moment(result).format("MM-DD-YYYY hh:mm A");
      }

      return result || "";
    };

    return (
      <tr key={index}>
        <td className="popOver-centered border-0">{rowItem.name}</td>
        <td className="popOver-centered border-0">{formatResult(rowItem.oldResult, rowItem.name)}</td>
        <td className="popOver-centered border-0">{formatResult(rowItem.newResult, rowItem.name)}</td>
      </tr>
    );
  };

  const showSingleLogMessage = (msgObj) => {
    const msg = msgObj[0];
    return `${msg.name} changed ${msg.oldResult} to ${msg.newResult}`;
  };

  return (
    <>
      <td
        className={cssClass}
        ref={childNode}
        onClick={() => setShowDetailModal(true)}
        style={{
          cursor: "pointer",
          display: "flex",
          width: "100%",
          justifyContent: "center",
        }}
      >
        {!values || (Array.isArray(values) && values.length === 0) ? msg.slug : "View Details"}
      </td>
      {Array.isArray(values) && values.length > 0 && showDetailModal && (
        <Modal
          show
          animation={true}
          size="lg"
          onHide={() => setShowDetailModal(false)}
          centered
          scrollable
          className="searchList seperateModal"
        >
          <Modal.Header closeButton>
            <Modal.Title className="my-0" id="contained-modal-title-vcenter">
              Detail Logs
            </Modal.Title>
          </Modal.Header>
          <Modal.Body style={{ padding: "0px" }}>
            <div className="form-group-wrapper d-block">
              <div className="table-responsive overFlow-y-hidden">
                <Table className="table-hover table-striped">
                  <thead>
                    <tr>
                      <th className="popOver-centered border-0">Label</th>
                      <th className="popOver-centered border-0">Before</th>
                      <th className="popOver-centered border-0">After</th>
                    </tr>
                  </thead>
                  <tbody>
                    {Array.isArray(values) &&
                      values.map((val, i) => {
                        return <TableRow key={i} index={i} rowItem={val} />;
                      })}
                  </tbody>
                </Table>
              </div>
            </div>
          </Modal.Body>
        </Modal>
      )}
    </>
  );
};

export default TestLogsDetailView;
