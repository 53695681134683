import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { MESSAGE_MODES, INSURANCE_PROVIDER } from "constant";
import { userCompanyID } from "utils";
import insuranceServices from "../../../services/InsuranceService";

import { setMessage } from "../general/generalAction";
import { setLoader, showMessage } from "../general/generalSlice";
import {
  INSURANCES,
  INSURANCES_CREATE,
  INSURANCES_DELETE,
  INSURANCES_FETCH,
  INSURANCES_UPDATE,
  INSURANCES_FETCH_ALL,
} from "./insuranceConstant";

export const fetchInsurancesAsync = createAsyncThunk(INSURANCES_FETCH, async (param, { dispatch }) => {
  const apiData = await insuranceServices.fetchInsurances(param);
  return { apiData, param };
});

export const fetchPayerAsync = createAsyncThunk(INSURANCES_FETCH_ALL, async (param, { dispatch }) => {
  return await insuranceServices.fetchCompletePayerList(param);
});

export const createInsuranceAsync = createAsyncThunk(INSURANCES_CREATE, async (param, { dispatch, getState }) => {
  const loginUser = getState().auth.user;
  dispatch(setLoader(true));
  const response = await insuranceServices.createInsurances({
    ...param,
  });
  dispatch(setLoader(false));

  if (response && response?.Item) {
    dispatch(setMessage("Payer Added Sucessfully", MESSAGE_MODES.success));
    return { ...response?.Item, Name: response?.Item.name };
  } else {
    dispatch(setMessage(`${response?.error || "Payer Not Created"}`, MESSAGE_MODES.error));
  }
  return null;
});

export const updateInsuranceAsync = createAsyncThunk(INSURANCES_UPDATE, async (param, { dispatch, getState }) => {
  const loginUser = getState().auth.user;
  dispatch(setLoader(true));

  const response = await insuranceServices.updateInsurances({
    ...param,
  });
  dispatch(setLoader(false));

  if (response && (response.status || response?.Item)) {
    dispatch(setMessage("Payer Updated Sucessfully", MESSAGE_MODES.success));
    return param;
  } else {
    dispatch(setMessage(`${response?.error || "Payer Not Updated"}`, MESSAGE_MODES.error));
  }
  return null;
});

export const deleteInsuranceAsync = createAsyncThunk(INSURANCES_DELETE, async (defaultSetting) => {
  // const response = await api.updateCliItem(defaultSetting); // Your API call logic here
  return {};
});

const insuranceSlice = createSlice({
  name: INSURANCES,
  initialState: {
    payerList: [],
    insurancesList: [],
    filteredInsurances: [],
    openInsuranceModal: false,
    selectedInsurance: null,
    openInsruModal: false,
    totalInsurancesRecords: 0,
  },
  reducers: {
    setInsurnaceList: (state, action) => {
      state.insurancesList = action.payload;
    },
    setFilteredInsurnaceList: (state, action) => {
      state.filteredInsurances = action.payload;
    },
    setOpenInsuranceModal: (state, action) => {
      state.openInsuranceModal = action.payload;
    },
    setSelectedInsurance: (state, action) => {
      state.selectedInsurance = action.payload;
    },
    setOpenInsruModal: (state, action) => {
      state.openInsruModal = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchInsurancesAsync.fulfilled, (state, action) => {
        const { apiData = {}, param = {} } = action.payload || {};
        const { rows: data = [], total = 0 } = apiData;

        if (param?.page === 1) {
          state.insurancesList = data;
          state.filteredInsurances = data;
        } else {
          state.insurancesList = [...state.insurancesList, ...data];
          state.filteredInsurances = [...state.filteredInsurances, ...data];
        }

        state.totalInsurancesRecords = total;
      })
      .addCase(fetchPayerAsync.fulfilled, (state, action) => {
        state.payerList = action.payload?.rows || INSURANCE_PROVIDER;
      })
      .addCase(createInsuranceAsync.fulfilled, (state, action) => {
        if (action.payload) {
          state.insurancesList.unshift(action.payload);
          state.filteredInsurances.unshift(action.payload);
        }
      })
      .addCase(updateInsuranceAsync.fulfilled, (state, action) => {
        const updateItem = action.payload;
        if (updateItem) {
          const existingclientIndex = state.insurancesList.findIndex((code) => code.id === updateItem.id);
          if (existingclientIndex !== -1) {
            state.insurancesList[existingclientIndex] = updateItem;
          }
          const existingIndex = state.filteredInsurances.findIndex((code) => code.id === updateItem.id);
          if (existingIndex !== -1) {
            state.filteredInsurances[existingIndex] = updateItem;
          }
        }
      });
  },
});

export const {
  setInsurnaceList,
  setFilteredInsurnaceList,
  setOpenInsuranceModal,
  setSelectedInsurance,
  setOpenInsruModal,
} = insuranceSlice.actions;

export default insuranceSlice.reducer;
