import React, { useRef } from "react";
import logoImage from "assets/img/co_lab-1.png";
import moment from "moment";
import QrCodeDownloadIcon from "../../../assets/img/qr-download.svg";
import html2canvas from "html2canvas";
import LogoText from "components/LogoText";

const Certificate = ({ data }) => {
  const downloadRef = useRef();
  const downloadCerificate = async () => {
    const element = downloadRef.current;
    await html2canvas(element, { allowTaint: true, useCORS: true, logging: true }).then((canvas) => {
      const imgData = canvas.toDataURL("image/png");
      const link = document.createElement("a");
      if (typeof link.download === "string") {
        link.href = imgData;
        link.download = `${data.firstName}_${data.lastName}_hipaacertificate.jpg`;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      } else {
        window.open(imgData);
      }
    });
  };
  const styles = `
    @font-face {
      font-family: "Calibri";
      src: url("./fonts/Calibri.eot");
      /* ... Calibri font definitions ... */
    }

    @font-face {
      font-family: "Calibri-bold";
      src: url("./fonts/Calibri-Bold.eot");
      /* ... Calibri-Bold font definitions ... */
    }

    @font-face {
      font-family: "Georgia";
      src: url("./fonts/Georgia.woff2") format("woff2"),
        url("./fonts/Georgia.woff") format("woff"),
        url("./fonts/Georgia.ttf") format("truetype");
      /* ... Georgia font definitions ... */
    }

    body {
      font-family: "Calibri", sans-serif;
      font-size: 22px;
      margin: 0;
      padding: 0;
      overflow-x: hidden;
    }

    .certificate-container {
      border: 10px solid #333;
      padding: 50px 1rem 50px;
      width: 900px;
      margin: auto;
    }

    .logo-container {
      width: 200px;
      height: 100px;
      text-align: center;
      vertical-align: middle;
      margin: auto;
    }

    .logo-image {
      width: 100%;
      object-fit: cover;
    }

    .certificate-details {
      text-align: center;
    }

    .certificate-title {
      font-family: 'Georgia', sans-serif;
      font-size: 70px;
      font-weight: 500;
      margin-top: 0;
      color: #bf8f00;
      margin-bottom: 20px;
    }

    .certificate-text {
      font-size: 22px;
      line-height: 1.5;
      color: #767171;
      text-align: center;
      padding-inline: 50px;
    }

    .certificate-name {
      font-size: 26px;
      font-weight: bold;
      font-family: 'Calibri-bold', sans-serif;
    }

    .certificate-date {
      margin-top: 40px;
      display: inline-block;
      text-align: center;
      width: 100%;
      font-size: 22px;
      color: #767171;
    }
  `;

  return (
    <>
      <style>{styles}</style>
      <section className="certificate-container" ref={downloadRef}>
        <div className="logo-container">
          <img src={logoImage} className="logo-image" alt="logo" />
        </div>
        <div className="certificate-details">
          <h1 className="certificate-title">
            HIPPAA <br />
            Certificate of Completion
          </h1>
          <p className="certificate-text">
            This Certificate acknowledges that
            <span className="certificate-name mx-1">{`${data.firstName} ${data.lastName}`}</span>
            has successfully completed the course HIPAA Rules and compliance, set forth by Collaboration, Inc.
          </p>
          <span className="certificate-date">{moment().format("MMMM D, YYYY")}</span>
        </div>
      </section>
      <div className="mt-4 text-center">
        <button  className="text-uppercase w-20 btn" onClick={downloadCerificate}>
          Download Certificate
        </button>
      </div>
    </>
  );
};

export default Certificate;
