import React, { useRef, useState } from "react";

// Import your custom file icon SVG
import customFileIcon from "assets/img/file-icon.svg";
import { formatFileSize } from "../../utils";

const FileInput = (props) => {
  const { setFile, setFileType, setSelectedFileName, selectedFileName, setFileSize, acceptableFiles } = props;
  
  const fileInputRef = useRef();

  const handleDragOver = (e) => {
    e.preventDefault();
  };

  const handleDrop = (e) => {
    e.preventDefault();

    if (e.dataTransfer.files.length > 0) {
      const file = e.dataTransfer.files[0];
      const fileType = file.type;

      if (setFile) setFile(file);
      if (setFileType) setFileType(fileType);
      if (setSelectedFileName) setSelectedFileName(file.name);
      if (setFileSize) setFileSize(formatFileSize(file.size));
    }
  };

  const openFileDialog = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  const onChangeFile = async (event) => {
    if (event.currentTarget.files && event.currentTarget.files.length > 0) {
      const file = event.currentTarget.files[0];
      const fileType = file.type;

      if (setFile) setFile(file);
      if (setFileType) setFileType(fileType);
      if (setSelectedFileName) setSelectedFileName(file.name); // Store the selected file name
      if (setFileSize) setFileSize(formatFileSize(file.size));
    }
  };

  return (
    <div className="d-flex flex-column align-items-center" onDragOver={handleDragOver} onDrop={handleDrop}>
      <div className="d-flex flex-column align-items-center file-upload-area" onClick={openFileDialog}>
        {selectedFileName ? (
          <div className="mt-2">
            <img src={customFileIcon} alt="File Icon" className="file-icon" />
            {selectedFileName}
          </div>
        ) : (
          <p className="mt-2 mb-0">Drag & Drop or Click to Select a File</p>
        )}
      </div>

      <input
        ref={fileInputRef}
        type="file"
        accept={acceptableFiles ? acceptableFiles : ".pdf, .jpg, .jpeg, .png, .gif, .doc, .docx"}
        onChange={onChangeFile}
        style={{ display: "none" }}
      />
    </div>
  );
};

export default FileInput;
