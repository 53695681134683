import React, { useState, useContext, useEffect } from "react";
import api from "api";
import { Button, Modal } from "react-bootstrap";
import Select from "react-select";
import { Bounce } from "react-activity";
import "react-activity/dist/Bounce.css";
import {
  HEALTH_AND_SAFTEY,
  newRoleOptions,
  USER_ROLE_TYPE,
  crewRoleOptions,
  newRoleOptionsWhiteLabel,
  CONFIG,
  LAB_ADMIN_ROLE,
} from "../../constant";
import { AppContext } from "context/app-context";
import { formatPhoneNumber, isValidPhone, isValidPhoneWithCode, isValidEmail } from "../../utils";
import ErrorMessage from "components/Message/ErrorMessage";
import PhoneNoInput from "components/Phone/PhoneNoInput";
import Loader from "../Loader/Loader";

const MFUserModal = (props) => {
  const { show, user, handleSave, handleClose, resetCall, handleConfirmUser, message, isCrewCall } = props;
  const [newUser, setNewUser] = useState(user);
  const [error, setError] = useState("");
  const [selectedRole, setSelectedRole] = useState([]);
  useEffect(() => {
    if (!user.isNew) handleEditRole();
  }, [user]);

  useEffect(() => {
    if (CONFIG.isWhiteLabel && user.isNew) {
      setSelectedRole("");
    }
  }, []);

  useEffect(() => {
    if (message) setError(message);
  }, [message]);
  const appContext = useContext(AppContext);
  const sites = appContext.sites;
  const labs = appContext.labs;
  const clients = appContext.clients;
  const [loading, setLoading] = useState(false);
  const handleRoleChange = (selectedOption) => {
    setSelectedRole(selectedOption);
  };

  const handleEditRole = async () => {
    try {
      const userData = { ...user };
      const userRoles = JSON.parse(userData.roles);

      let selectedRoleData = [];
      userRoles.forEach((element) => {
        const role = element.role === "Employers" ? HEALTH_AND_SAFTEY : role;
        let roleObj = newRoleOptions.find((obj) => obj.value === role);
        if (roleObj) {
          selectedRoleData.push(roleObj);
        }
      });
      setSelectedRole(selectedRoleData);
      setNewUser({ ...userData });
    } catch (err) {
      console.log("Errr", err);
    }
  };

  console.log("User", newUser);

  const hasRole = (role) => {
    return selectedRole.some((r) => r.value === role);
  };

  const handleMultipleRoles = () => {
    let mainRoleArray = [];

    if (selectedRole.length === 0) {
      setError("Select Role");
      return false;
    }

    for (const singleRole of selectedRole) {
      let role = singleRole.value;
      let roleObj = {};

      if (role === "Lab" || role === HEALTH_AND_SAFTEY || role === "Client") {
        if (role === "Lab") {
          if (!newUser.lab) {
            setError("Select lab also");
            return false;
          }
          roleObj = {
            role: role,
            relatedId: newUser.lab.id,
            labID: newUser.lab.id,
            clientID: newUser.client?.id,
            title: newUser.lab.name,
            isWhiteLabelLab: newUser.lab.appPackage ? true : false,
          };
          mainRoleArray.push(roleObj);
        }

        if (role === HEALTH_AND_SAFTEY) {
          if (!newUser.show) {
            setError("Select show also");
            return false;
          }
          roleObj = {
            role: 'Employers',
            labID: newUser.lab.id,
            clientID: newUser.client.id,
            relatedId: newUser.show.id,
            siteID: newUser.show.id,
            title: newUser.show.name,
          };
          mainRoleArray.push(roleObj);
        }

        if (role === "Client") {
          if (!newUser.client) {
            setError("Select client also");
            return false;
          }
          roleObj = {
            role: role,
            labID: newUser.lab?.id,
            clientID: newUser.client.id,
            relatedId: newUser.client.id,
            title: newUser.client.name,
          };
          mainRoleArray.push(roleObj);
        }
      } else {
        mainRoleArray.push({ role: role });
      }
    }
    return mainRoleArray;
  };

  const getNote = () => {
    let noteArr = [];
    let note = "";
    if (newUser.lab) noteArr.push(newUser.lab.label);
    if (hasRole("Admin")) noteArr.push("System");
    if (hasRole("Tester")) noteArr.push("Tester");

    if (noteArr.length > 0) {
      if (noteArr.length == "1") note = noteArr[0];
      else note = noteArr.join(",");
    }
    return note;
  };

  const getRoleValue = () => {
    let role = "";
    if (hasRole("Admin")) {
      role = "Admin";
    } else if (hasRole("Lab")) {
      role = "Lab";
    } else if (hasRole(HEALTH_AND_SAFTEY)) {
      role = HEALTH_AND_SAFTEY;
    }
    return role;
  };

  const onHandleSave = async () => {
    let mainRoleData = handleMultipleRoles();
    if (!mainRoleData) return;
    if (!newUser.firstName.trim()) {
      setError("First name is required");
      return;
    }

    if (!newUser.lastName.trim()) {
      setError("Last name is required");
      return;
    }

    if (!newUser.email.trim()) {
      setError("Email  is required");
      return;
    }
    if (!isValidEmail(newUser.email)) {
      setError("Email is invaild");
      return;
    }

    if (!newUser.phone) {
      setError("Phone number is required");
      return;
    }

    if (!isValidPhoneWithCode(newUser.phone)) {
      setError("Invalid Phone Number");
      return;
    }

    if (newUser.isNew && !newUser.password) {
      setError("Password is requried");
      return;
    }

    if (newUser.isNew) {
      const isValidPassword = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[^a-zA-Z0-9])(?!.*\s).{8,}$/.test(newUser.password);

      if (!isValidPassword) {
        setError(
          "Password must contains at least one lowercase letter, one uppercase letter, one numeric digit, and one special character"
        );
        return;
      }
    }

    if(!newUser?.lab?.value) {
      setError("Select Lab");
      return;
    }

    if(!newUser?.client?.value) {
      setError("Select Client");
      return;
    }

    const systemUser = hasRole("Admin") ? 0 : 1;
    let note = getNote();
    let role = getRoleValue();

    let finalUser = {
      ...newUser,
      roles: mainRoleData.length > 0 ? JSON.stringify(mainRoleData) : "",
      note,
      role,
      systemUser,
    };
    
    handleSave(finalUser);
    handleClose();
  };
  // console.log("selectedRole", selectedRole);
  const handlePhoneinput = (e) => {
    setNewUser({
      ...newUser,
      phone: e,
    });
  };

  const handleLabObj = () => {
    return {
      label: appContext.userLab.name,
      value: appContext.userLab.id,
      id: appContext.userLab.id,
      name: appContext.userLab.name,
      appPackage: appContext.userLab.whiteLabelPackage,
    };
  };

  const handleAdditionalFields = (roles) => {
    let obj = {};
    for (const role of roles) {
      if (role.role === HEALTH_AND_SAFTEY) {
        const site = getUserSiteName(role?.relatedId);
        if (site) {
          let show = { label: site.name, value: site.id, id: site.id, name: site.name };
          obj = { ...obj, show: show };
        }
      }

      if (role.role === USER_ROLE_TYPE.Lab) {
        const lab = getUserLabName(role?.relatedId);
        if (lab) {
          let labData = {
            label: lab.name,
            value: lab.id,
            id: lab.id,
            name: lab.name,
            appPackage: lab.whiteLabelPackage,
          };
          obj = { ...obj, lab: labData };
        }
      }

      if (role.role === USER_ROLE_TYPE.Client) {
        const client = getUserClientName(role?.relatedId);
        if (client) {
          let clientData = { label: client.name, value: client.id, id: client.id, name: client.name };
          obj = { ...obj, client: clientData };
        }
      }
    }
    return obj;
  };

  const getUserLabName = (labId) => {
    const filterLab = appContext.labs.filter((lab) => lab.id === labId);
    if (filterLab.length > 0) {
      return filterLab[0];
    }
  };

  const getUserClientName = (clientId) => {
    return appContext.clients.find((client) => clientId === client.id);
  };

  const getUserSiteName = (userId) => {
    if (userId) {
      const filterSite = appContext.sites.filter((s) => s.id === userId);
      if (filterSite.length > 0) {
        return filterSite[0];
      }
    }
  };

  const roleSelectionCSS = () => {
    return selectedRole.some((r) => r.value === "Employer") || selectedRole.some((r) => r.value === "Lab")
      ? "activeEmploye"
      : "";
  };

  return (
    <>
      <Modal
        show={show}
        animation={true}
        onHide={() => {
          setNewUser("");
          handleClose();
        }}
        style={{ paddingLeft: "0" }}
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title className="my-0" id="contained-modal-title-vcenter">
            {newUser.isNew ? "Create User" : "Update User"}
          </Modal.Title>
        </Modal.Header>
        {!loading ? (
          <Modal.Body
            style={{
              paddingTop: 0,
            }}
          >
            <div className="form-group-wrapper">
              <label className="modalLineHeaders">First Name: </label>
              <input
                value={newUser.firstName}
                className="modalInput"
                placeholder="First Name"
                style={{
                  width: "100%",
                }}
                onChange={(e) => setNewUser({ ...newUser, firstName: e.target.value })}
              />
            </div>
            <div className="form-group-wrapper">
              <label className="modalLineHeaders">Last Name: </label>
              <input
                value={newUser.lastName}
                className="modalInput"
                placeholder="Last Name"
                onChange={(e) => setNewUser({ ...newUser, lastName: e.target.value })}
              />
            </div>
            <div className="form-group-wrapper">
              <label className="modalLineHeaders">Email: </label>
              <input
                value={newUser.email}
                className="modalInput"
                placeholder="Email"
                style={{
                  width: "100%",
                }}
                onChange={(e) => setNewUser({ ...newUser, email: e.target.value.trim().toLowerCase() })}
              />
            </div>
            <div className="form-group-wrapper">
              <label className="modalLineHeaders">Phone #: </label>
              {newUser.isNew ? (
                <PhoneNoInput handleChange={handlePhoneinput} value={newUser?.phone} disabled={!newUser.isNew} />
              ) : (
                <input
                  value={formatPhoneNumber(newUser.phone)}
                  className="modalInput w-100"
                  disabled={true}
                  placeholder="Phone"
                />
              )}
            </div>
            <div className="form-group-wrapper">
              {newUser.isNew && (
                <>
                  <label className="modalLineHeaders">Password: </label>

                  <input
                    type="password"
                    className="modalInput"
                    value={newUser.password}
                    placeholder="Password"
                    style={{
                      width: "100%",
                    }}
                    onChange={(e) => setNewUser({ ...newUser, password: e.target.value })}
                  />
                </>
              )}
            </div>
            <div className={`form-group-wrapper  ${roleSelectionCSS()}`}>
              <label className="modalLineHeaders">Role:</label>
              <Select
                options={CONFIG.isWhiteLabel ? newRoleOptionsWhiteLabel : newRoleOptions}
                blurInputOnSelect={true}
                defaultValue={null}
                isMulti
                menuPlacement="auto"
                placeholder="Select a Role"
                onChange={handleRoleChange}
                className="w-100 roleSelector"
                value={selectedRole}
              />
            </div>
            <div className={`form-group-wrapper  ${roleSelectionCSS()}`}>
              {selectedRole.some((r) => r.value === HEALTH_AND_SAFTEY || r.value === "Employers") && (
                <>
                  <label className="modalLineHeaders">Select Show:</label>
                  <Select
                    className="w-100 siteSelector"
                    options={sites.map((site) => {
                      return { label: site.name, value: site.id, id: site.id, name: site.name };
                    })}
                    value={newUser.show}
                    blurInputOnSelect={true}
                    defaultValue={null}
                    menuPlacement="auto"
                    placeholder="Select a Show"
                    onChange={(e) => setNewUser({ ...newUser, show: e })}
                  />
                </>
              )}
            </div>
            <div className={`form-group-wrapper ${roleSelectionCSS()}`}>
              <label className="modalLineHeaders">Select Lab:</label>
              <Select
                className="w-100  siteSelector"
                options={labs.map((lab) => {
                  return {
                    label: lab.name,
                    value: lab.id,
                    id: lab.id,
                    name: lab.name,
                    appPackage: lab.whiteLabelPackage,
                  };
                })}
                blurInputOnSelect={true}
                defaultValue={null}
                value={newUser.lab}
                menuPlacement="auto"
                placeholder="Select a Lab"
                // onChange={(e) => (console.log(e), setNewUser({ ...newUser, lab: e }))}
                onChange={(e) => setNewUser({ ...newUser, lab: e })}
              />
            </div>
            <div className={`form-group-wrapper  ${roleSelectionCSS()}`}>
              <label className="modalLineHeaders">Select Client:</label>
              <Select
                className="w-100 siteSelector"
                options={clients.map((client) => {
                  return { label: client.name, value: client.id, id: client.id, name: client.name };
                })}
                value={newUser.client}
                blurInputOnSelect={true}
                defaultValue={null}
                menuPlacement="auto"
                placeholder="Select a Client"
                onChange={(e) => setNewUser({ ...newUser, client: e })}
              />
            </div>

            {newUser?.role?.split(",").length === 3 &&
              LAB_ADMIN_ROLE.every((e) => newUser?.role?.includes(e)) &&
              newUser.lab && (
                <div className="form-group-wrapper">
                  <label className="modalLineHeaders">Lab Admin: </label>
                  <input
                    type="checkbox"
                    className="flex-basis-0"
                    checked={newUser.labAdmin}
                    onChange={(e) => setNewUser({ ...newUser, labAdmin: e.target.checked ? newUser.lab.id : null })}
                  />
                </div>
              )}

            {!newUser.isNew && (
              <div className="form-group-wrapper">
                <label className="modalLineHeaders"> </label>
                <label className="modalLineHeaders linkedText" onClick={() => (handleClose(), resetCall(true))}>
                  Reset Password
                </label>
              </div>
            )}
            {error && <ErrorMessage error={error} handleChange={() => setError("")} />}
          </Modal.Body>
        ) : (
          <Loader />
        )}

        <Modal.Footer>
          <Button
            style={{ marginBottom: 10 }}
            variant="secondary"
            className="modalButtons"
            onClick={() => {
              handleClose();
            }}
          >
            Close
          </Button>
          <Button style={{ marginBottom: 10 }} variant="primary" className="modalButtons" onClick={onHandleSave}>
            {newUser.isNew ? "Create" : "Update"}
          </Button>
          {newUser.status === "UNCONFIRMED" && (
            <Button
              style={{ marginBottom: 10 }}
              variant="primary"
              className="modalButtons"
              onClick={() => (handleConfirmUser(newUser), handleClose())}
            >
              Confirm User
            </Button>
          )}
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default MFUserModal;
